import React, { Component } from "react";
import BreadCrumbs from "../../BreadCrumbs";
import { Link } from "react-router-dom";
import axios from 'axios';
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import ReactTable from 'react-table';
import $ from "jquery";
import _ from 'underscore';

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

const data = [];


let context="null";

//defined all the columns and fetched the data for every cell.
const columns = [
  {
    Header: props => <span className="form-textHead cell-head">Client ID</span>,
    accessor: 'customerId',
    Cell: props => <span className='form-text cell'>{props.value}</span>,
    minWidth:170,
    filterMethod: (filter, row) =>
    { return row.customerId.toLowerCase().includes(filter.value.toLowerCase()) },
  },
  {

    Header: props => <span className="form-textHead cell-head">Company Name</span>,
    accessor: d => {return {mail:d.email,id:d.id,name:d.companyName}},
    filterMethod: (filter, row) =>
             { return row.companyName.name ? row.companyName.name.toLowerCase().includes(filter.value.toLowerCase()) : '' },
    id:"companyName",
    Cell: props => 
    <Link className="link" to={"./edit-controls/"+props.value.id}>{props.value.name}</Link>,
    minWidth:180
    },
    {
      Header: props => <span className="form-textHead cell-head">Customer Email</span>,
      accessor: 'customerEmail',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:240
    },
    {
      Header: props => <span className="form-textHead cell-head">Customer Mode</span>,
      accessor: 'customerMode',
      Cell: props => <span className='form-text cell'>{props.value!=undefined ? props.value : 'Online'}</span>,
      minWidth:240
    },
    {
      Header: props => <span className="form-textHead cell-head">Customer Type</span>,
      accessor: 'customerType',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:170
    },
     {
      Header: props => <span className="form-textHead cell-head">Company Display Name</span>,
      filterMethod: (filter, row) =>
      {debugger;   return row.companyDisplayName.toLowerCase().includes(filter.value.toLowerCase()) },
      accessor: 'companyDisplayName',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:220
    },
    
    {
      Header: props => <span className="form-textHead cell-head">Primary Contact</span>,
      accessor: 'primaryContact',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:170
     
    },{
      Header: props => <span className="form-textHead cell-head">Customer Contact</span>,
      accessor: 'customerContact',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:150
    },{
      Header: props => <span className="form-textHead cell-head">Website</span>,
      accessor: 'website',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:240
    },

]
export default class ViewAccessControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      menu1:[
       



 ]
 
    };
    context = this;
  }
  componentDidMount(){

  this.fetchQueries(this,this.props.source);}


  toggleOpen = id => {
    var list  =this.state.menu1;
    var index = _.findIndex(list,function(entry){return entry.id==id});
    
    list[index].status =  (list[index].status == "open" || list[index].status==undefined) ? "close" : 'open';
    
    var newList = JSON.parse(JSON.stringify( list ));
    context.setState({menu1:newList});
    context.updateStatus(context,id,list[index].status);
    
    
    
      };

  
  fetchQueries(that,source){

    axios.get(apiBaseUrl2+"businus/customer/list/"+localStorage.spId)
    .then(function (response) {
      console.log(response.data);
      that.setState({menu1:response.data});
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }

  

  render() {
    return (
      <div>
       
      
      <div className="Div">
        <div className="row breadCrumb-row">
      <BreadCrumbs/>
      </div>
            <div className="row">
          
              <Row>
                <Col>
                  <div className="card">

                  <ReactTable className="myTable" resolveData={data => data.map(row => row)}  data={this.state.menu1} columns={columns} filterable />
                  


                    
                       
                      
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          </div>
    );
  }
}
