import React, { Component } from "react";
import CsvDownloader from 'react-csv-downloader'
import BreadCrumbs from '../../BreadCrumbs';
import { Link } from "react-router-dom";
import axios from 'axios';
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import ReactTable from 'react-table';
import $ from "jquery";
import _ from 'underscore';

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

const data = [];


let context="null";

//defined all the columns and fetched the data for every cell.
const columns = [

  
{
  Header: props => <span className="form-textHead cell-head"> Department Name</span>,
  accessor: 'title',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:240
},
{
  Header: props => <span className="form-textHead cell-head">Description</span>,
  accessor: 'description',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:170
} ,{
  Header: props => <span className="form-textHead cell-head">Date of Creation</span>,
  accessor: d => {return {date:d.date,id:d.id}},
  filterMethod: (filter, row) =>
           { return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase()) }
                   
                ,
  id:"teamMembers",
  Cell: props => 
  new Date(props.value.date).toDateString(),
  minWidth:180
 
},
{
  Header: props => <span className="form-textHead cell-head">Time of Creation</span>,
  accessor: d => {return {date:d.date,id:d.id}},
  filterMethod: (filter, row) =>
           { return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase()) }
                   
                ,
  id:"teamMembers",
  Cell: props => 
  new Date(props.value.date).toLocaleTimeString()
  ,
  minWidth:280
 
},
{/*{

  Header: props => <span className="form-textHead cell-head">Edit</span>,
  accessor: d => {return {firstName:d.firstName,lastName:d.lastName,id:d.id}},
  filterMethod: (filter, row) =>
           { return row.name.firstName.toLowerCase().includes(filter.value.toLowerCase()) }
                   
                ,
  id:"name",
  Cell: props => 
  <Link className="link" to={"./edit-department/"+props.value.id}><button className="buttonYellow">Edit</button></Link>,
  minWidth:180
  }*/},
  {

    Header: props => <span className="form-textHead cell-head">Delete</span>,
    accessor: d => {return {firstName:d.firstName,lastName:d.lastName,id:d.id}},
    filterMethod: (filter, row) =>
             { return row.name.firstName.toLowerCase().includes(filter.value.toLowerCase()) }
                     
                  ,
    id:"name",
    Cell: props => 
    <Link className="link" to={"./edit-department/"+props.value.id}><button className="buttonRed">Delete</button></Link>,
    minWidth:180
    },
]
export default class viewDepartments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      menu1:[
       



 ]
 
    };
    context = this;
  }
  componentDidMount(){

  this.fetchQueries(this,this.props.source);}


  toggleOpen = id => {
    var list  =this.state.menu1;
    var index = _.findIndex(list,function(entry){return entry.id==id});
    
    list[index].status =  (list[index].status == "open" || list[index].status==undefined) ? "close" : 'open';
    
    var newList = JSON.parse(JSON.stringify( list ));
    context.setState({menu1:newList});
    context.updateStatus(context,id,list[index].status);
    
    
    
      };

  
  fetchQueries(that,source){

    axios.get(apiBaseUrl2+"businus/employee/props/list/Department/"+localStorage.spId)
    .then(function (response) {
      console.log(response.data);
      that.setState({menu1:response.data});
      that.generateExcelData(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }

  
  generateExcelData = data => {
    
    let excelData = [];
    _.each(data, function(d) {
  
      
      let row = {
        title: d.title,
        description: d.description,
        type: d.type,
        by: d.by,       
        date: new Date(d.date).toDateString(),
        time: new Date(d.date).toLocaleTimeString()
     
      
      }

       
      excelData.push(row);
      console.log(excelData);
    });
    this.setState({ excelData });
  };
  

  render() {
    return (
      <div>
       
      
      <div className="Div">
        <div className="row breadCrumb-row">
        <div className="col-md-10"><BreadCrumbs/></div>
     
      <div className="col-md-2">
            <div className=" download-button">
      <CsvDownloader 
      text="Download in .csv format"
      datas={this.state.excelData}
      filename="departments"
      separator=","
      wrapColumnChar=""
    
      /></div>
      </div>
      </div>
            <div className="row">
          
              <Row>
                <Col>
                  <div className="card">

                  <ReactTable className="myTable" resolveData={data => data.map(row => row)}  data={this.state.menu1} columns={columns} filterable />
                  


                    
                       
                      
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          </div>
    );
  }
}
