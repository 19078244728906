import React, { Component } from "react";
import "../../style/panel.css";
import { Link } from "react-router-dom";
import $ from "jquery";
import UniquePage from "./pageAskanexpert";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

const data = [];
export default class Sidebar extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []
     
    };
    
  }
  componentDidMount(){
    this.handleLine();
  }
  handleLine(){

    $(function(){
      $("#tab1").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
        $("#section").toggle();
      });
      });
      $(function(){
      $("#c2").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
        $("#d2").toggle();
      });
      });
      $(function(){
        $("#c3").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d3").toggle();
        });
        });
      $(function(){
        $("#c4").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
            $("#d4").toggle();
          });
          });
      $(function(){
         $("#c5").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d5").toggle();
            });
            });
      $(function(){
          $("#c6").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d6").toggle();
              });
              });
      $(function(){
          $("#c7").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d7").toggle();
                });
                });
      $(function(){
          $("#c8").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d8").toggle();
                  });
                  });  
  
  }
  
  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
    
            <div className="sidebar">
              <div id="sidebar1">
              <div className="sidebar-head">Legal</div>
               
                {/*<div className="sidebar-sectionName">
    Products</div>*/}
                <div className="oneTab">
                <div className="sidebar-elementhead"><Link className="sidebar-link" to="/">Home</Link></div>
                <div className="sidebar-element" id="c1">  <Link className="sidebar-link" to="/legal/home"><i className="fa fa-bar-chart" />Dashboard<i className="fa fa-angle-right " /></Link></div>
                  <div className="sidebar-subelement" id="d1"><i className="fa fa-envelope" />View<i className="fa fa-angle-right " /></div>
                </div>
                <div className="oneTab">
                  <div className="sidebar-element" id="c2">
                    <i className="fa fa-hand-pointer" />
                    Lets Get Started
                    <i className="fa fa-angle-down " />
                  </div>
                  <div className="sidebar-subelement" id="d2">
                  <Link className="sidebar-link" to="/legal/homepage/lets-get-started">
                    <i className="fa fa-e" />
                    Submissions
                    <i className="fa fa-angle-right " />
                    </Link>
                  </div>
                </div>
                <div className="oneTab">
                  <div className="sidebar-element" id="c3">
                
                    <i className="fa fa-user" />
                    Subscribers
                    <i className="fa fa-angle-down " />
                 
                  </div>
                  <div className="sidebar-subelement" id="d3">
                  <Link className="sidebar-link" to="/legal/homepage/subscribers">
                    <i className="fa fa-e" />
                    Submissions
                    <i className="fa fa-angle-right " />
                    </Link>
                  </div>
                </div>
                <div className="oneTab">
                  <div className="sidebar-element" id="c4">
                    <i className="fa fa-check" />
                    Post a Job <i className="fa fa-angle-down " />
                  </div>
                  <div className="sidebar-subelement" id="d4">
                  <Link  className="sidebar-link" to="/legal/homepage/post-a-job">
                    <i className="fa fa-e" />
                    Submissions
                    <i className="fa fa-angle-right " />
                    </Link>
                  </div>
                </div>
                <div className="oneTab">
                  <div className="sidebar-element" id="c5">
                 
                    <i className="fa fa-question" />
                    Ask an Expert
                    <i className="fa fa-angle-down " />
                   
                  </div>
                  <div className="sidebar-subelement" id="d5">
                  <Link className="sidebar-link" to="/legal/homepage/ask-an-expert">
                    <i className="fa fa-e" />
                    Submissions
                    <i className="fa fa-angle-right " />
                    </Link>
                  </div>
                </div>
                <div className="oneTab">
                  <div className="sidebar-element" id="c6">
                    <i className="fa fa-paper-plane" />
                    Request a Callback
                    <i className="fa fa-angle-down " />
                  </div>
                  <div className="sidebar-subelement" id="d6">
                  <Link  className="sidebar-link" to="/legal/homepage/request-a-callback">
                    <i className="fa fa-e" />
                    Submissions
                    <i className="fa fa-angle-right " />
                    </Link>
                  </div>
                </div>
                <div className="oneTab">
                  <div className="sidebar-element" id="c7">
                    <i className="fa fa-phone" />
                    Contact us
                    <i className="fa fa-angle-down " />
                  </div>
                  <div className="sidebar-subelement" id="d7">
                  <Link className="sidebar-link" to="/legal/homepage/contact-us">
                    <i className="fa fa-e" />
                    Submissions
                    <i className="fa fa-angle-right " />
                    </Link>
                  </div>
                </div>
                <div className="oneTab">
                  <div className="sidebar-element" id="c8">
                    <i className="fa fa-handshake" />
                    Work with us
                    <i className="fa fa-angle-down " />
                  </div>
                  <div className="sidebar-subelement" id="d8">
                  <Link className="sidebar-link" to="/legal/homepage/work-with-us">
                    <i className="fa fa-e" />
                    Submissions
                    <i className="fa fa-angle-right " />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
         
    );
  }
}
