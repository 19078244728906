import React, { Component } from "react";
import ReactTable from 'react-table';




export default class ReactTableUI extends Component {




  render() {
    return (

  <ReactTable 
    className="myTable" style={{width:'auto',height:'100%'}}
                      resolveData={data => data.map(row => row)}
                      data={this.props.data} 
                      columns={this.props.columns}
                      showPagination={false}
                      minRows={0}
                      manualPagination={true}
                      autoResetPage={false}
                      defaultPageSize={1000}
                      sortable />

    );
  }
}
