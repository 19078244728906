import React, { Component } from "react";

import { Link } from "react-router-dom";
import BreadCrumbs from "../../BreadCrumbs";
import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { ChatFeed, Message } from 'react-chat-ui'


import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from 'underscore';
let context = null;
const data = [];
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               :'0px ',
    position               :'Absolute',
    zIndex                 :'30000'     ,
    backgroundColor         :'none',  
  }
};
 
export default class editAssignmentUpdateTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assignmentTitle:data.assignmentTitle,
      startDate:data.startDate,
      endDate:data.endDate,
      assignmentOverview:data.assignmentOverview,
      priority:data.priority,
      clients:[],
      entry:{}
     
    };
    context = this;
    this.openModal = this.openModal.bind(this);
   
    this.closeModal = this.closeModal.bind(this);
  }

  changeSliderState(orderNumber){

    this.setState({sliderState:orderNumber});

}
  //function for changing the status button on click to open or close
  handleOnChange = (e) => {
  debugger;
    var value = e.target.value;
  
    var formName = e.target.name;

    this.setState({entry:{...this.state.entry,[formName]:value}});



    console.log(this.state);
  }
  
  
  openModal(type) {
    this.changeDocType(type);
    this.setState({modalIsOpen: true});
  }
 
  
 
  closeModal() {
    this.setState({modalIsOpen: false});
  }
 

  componentDidMount() {
    const id = this.props.match.params.id;

    this.fetchQueryById(this, id);
    this.fetchThreadById(this, id);
    this.fetchClients(this);
    this.markAsRead(this,id);
  }


  markAsRead(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/read/" +
          id
      )
      .then(function(response) {
       
       
      })
      .catch(function(error) {
        console.log(error);
      });
  }


  //fetching the data by id which this file recieved from the list

 
  fetchQueryById(that, id) {
    axios
      .get(
        apiBaseUrl1 +"businusquery/getTemplateById/" + id
      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  sendMail() {
     var data = context.state.entry;
    axios
      .post(
        apiBaseUrl1 +"businus/template/update/"+data.id,
        {
          
          "title":data.title,
          "name":data.name,
          "description":data.description,
          templateId:data.templateId,
          "checklists":"NA",
          update:data.update,
          document:data.document
         
        })
      .then(function(response) {
        debugger;
      alert("Success!");
    //  context.fetchThreadById(context,query.id);
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  
  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });



          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  fetchThreadById(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/listmail/" +
          id
      )
      .then(function(response) {
        debugger;
        console.log(response.data);
        var threads = response.data=="" ? [] : _.sortBy(response.data,'date').reverse();
        that.setState({ thread: threads});

        var documents = _.where(threads,{docType:"Document"});
        var proposals = _.where(threads,{docType:"Proposal"});
        var mails = _.where(threads,{docType:"Mail"});
        that.setState({documents});
        that.setState({proposals});
        that.setState({mails});
        
      
      })
      .catch(function(error) {
        console.log(error);
      });
  }


  fetchClients(that,source){

    axios.get(apiBaseUrl2+"businus/customer/list/"+localStorage.spId)
    .then(function (response) {
      console.log(response.data);
      that.setState({clients:response.data});
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }


  getFiles(files) {
    this.setState({ selectedFile: files[0] });

  }

  getFormattedDate(millis){

    return new Date(millis).toDateString();


  }

  toggleOpen = status => {
    if (status == undefined || status == "open") {
      status = "close";
    } else {
      status = "open";
    }
    //(this.setState({ entry: [...this.state.entry,{status:status} ] });)
    var newEntry = this.state.entry;
    newEntry.status = status;
    this.setState({ entry: newEntry });
    context.updateStatus(context, this.state.entry.id, status);
  };
  
  changeDocType(type){

    this.setState({docType:type});
  }


  
  setMailPageUrl(){
  var url = window.location.href.replace(window.location.origin,"");
  localStorage.setItem("MAILURL",url);


  }




  //updating the status button
  
  render() {
    const {sliderState} = this.state;
    const active = " active-button"
    const deactive = "";
    return (
      <div className="Div">
      <div className="row breadcrumb-row">
           <div className="col-md-10">
             <BreadCrumbs />
           </div>
           </div>
  {/* showing all the details of a single item from the table at the backend*/}
  <div className="row">
  <div className="dedicated-page" style={{padding:'0px'}}>
    <div className="row-resource-head">
                  <h2 className="resource-head">Add Update Template </h2>
                  </div>
      <div className="row ">
        <div className="col-md-12 info-column account-col2">
          <div id="field">
         
            <div className="row">
                      <div className="col-md-8">

                       

                        <div className="form-group">
                          <label className="control-label col-md-4">
                            <span
                              id="ember295"
                              className="popovercontainer text-dashed-underline ember-view"
                              data-original-title
                              title
                            >
                            Update Template Name
                      </span>{" "}
                          </label>
                          <div
                            className="inline-fields col-md-8"
                            style={{ padding: 0 }}
                          >

                            <div className="col-md-8">
                              <input

                                autofocus
                                id="ember299"
                                onChange={this.handleOnChange}
                                name="name"
                                value={this.state.entry.name}
                                className="form-control ember-text-field ember-view"
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                        


                       

                        <div className="form-group">
                          <label className="control-label col-md-4">

                            Update
                    </label>
                          <div className="col-md-6">
                            <textarea
                              id="ember301"
                              onChange={this.handleOnChange}
                              name="update"
                              value={this.state.entry.update}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            ></textarea>
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-4">

                            Document
                    </label>
                          <div className="col-md-6">
               
 <FileBase64
multiple={true}
onDone={this.getFiles.bind(this)}   /></div>

<div className="col-md-5">

<button className="btn btn-primary attachment" onClick={() => this.fileUploadHandler(this, "document")}
value="Select a File">
<i className="fa fa-paperclip" aria-hidden="true"></i>Attachment
</button>
                          </div>
                        </div>

                      </div>
                    </div>

           
           

            <div className="text-left">
              <button type="button" onClick={this.sendMail} className="button1">
                {" "}
                Submit{" "}
              </button>
            </div>
            {/* this says that if editing state is on then the Function saveAccountDetails will be called */}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
      
     
    );
  }
}

