import React, { Component } from "react";

import { Link } from "react-router-dom";
import $ from "jquery";
import _ from 'underscore';
import UniquePage from "./addClients";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import {getPrivilegeByName} from '../../privilegeUtils';
const data = [];
export default class SidebarClient extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: [],
      privilege:{}
     
    };
    
  }
  componentDidMount(){
    var privileges =  JSON.parse(localStorage.getItem('privileges'));
    var privilege = _.findWhere(privileges,{module:"Client"});

    this.setState({privilege:privilege});

    this.handleLine();
   // this.setState({title:this.props.match.params.productType});
  }
  handleLine(){

    $(function(){
      $("#tab1").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
        $("#section").toggle();
      });
      });
      $(function(){
      $("#c2").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
        $("#d2").toggle();
      });
      });
      $(function(){
        $("#c3").click(function(e){
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d3").toggle();
        });
        });
      $(function(){
        $("#c4").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
            $("#d4").toggle();
          });
          });
      $(function(){
         $("#c5").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d5").toggle();
            });
            });
      $(function(){
          $("#c6").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d6").toggle();
              });
              });
      $(function(){
          $("#c7").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d7").toggle();
                });
                });
      $(function(){
          $("#c8").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d8").toggle();
                  });
                  });  
  
  }
  
  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
    
            <div className="sidebar">
              <div id="sidebar1">
                <div className="sidebar-head">Client Master</div>
                {/*<div className="sidebar-sectionName">
    Products</div>*/}
                <div className="oneTab">
                <div className="sidebar-elementhead"><Link className="sidebar-link-head" to="/home">Home</Link></div>
                <div className="sidebar-element" id="c1"><Link className="sidebar-link" to={"/masters/client"}> <i className="fa fa-bar-chart" />Dashboard<i className="fa fa-angle-right " /> </Link></div>
                  <div className="sidebar-subelement" id="d1"><i className="fa fa-envelope" />View<i className="fa fa-angle-right " /></div>
                </div>
                <div className="oneTab">
                  <div className="sidebar-element" id="c3">
                    <i className="fa fa-users" />
                    Clients
                    <i className="fa fa-angle-down " />
                  </div>
                  <div className="sidebar-subelement" id="d3">
                  {getPrivilegeByName('Client').canAdd == true ?   
                   <div>
                    <Link className="sidebar-link" to={"/masters/client/add-clients"}>
                    <i className="fa fa-envelope" />
                    Add Client
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div> : '' }
                     <div>
                    <Link className="sidebar-link" to={"/masters/client/view-clients"}>
                      <i className="fa fa-envelope" />
                    View Client
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div> 
                  </div>
                </div>
               
               
             
              
               
              </div>
            </div>
         
    );
  }
}
