import React, { Component } from "react";
import ReactTable from "react-table";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
/* import '../react-table.css' */
import axios from "axios";

export default class ProfessionalBookingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allBookingData: [],
      email: localStorage.getItem("email"),
    };
  }

  componentWillMount() {
    this.fetchAllData();
  }

  fetchAllData() {
    let allBookingData = [];
    axios({
      url: "https://8cop2jp1c8.execute-api.us-east-1.amazonaws.com/dev/businus/booking/listBooking",
      method: "GET",
      crossDomain: true,
      response: "JSON",
    }).then((response) => {
      for (var i = 0; i < response.data.length; i++) {
        if (response.data[i].professionalEmail === this.state.email) {
          allBookingData.push(response.data[i]);
        }
      }
      this.setState({ allBookingData: allBookingData });
    });
  }

  render() {
    const columns = [
      {
        Header: (props) => (
          <span className="form-textHead cell-head">Booking Id</span>
        ),
        accessor: "bookingId",
        filterMethod: (filter, row) => {
          debugger;
          return row.source.toLowerCase().includes(filter.value.toLowerCase());
        },

        Cell: (props) => <span className="form-text-cell">{props.value}</span>,
        minWidth: 150,
      },
      {
        Header: (props) => (
          <span className="form-textHead cell-head">Name</span>
        ),
        accessor: (d) => {
          return { name: d.customerDetail.name, id: d.id };
        },
        filterMethod: (filter, row) => {
          debugger;
          return row.name.name.toLowerCase().includes(filter.value.toLowerCase());
        },
        id: "name",
        Cell: (props) => (
          <Link
            className="link form-text-cell"
            to={"/professional-booking-page/" + props.value.id}
          >
            {props.value.name}{" "}
          </Link>
        ),
        minWidth: 180,
      },
      {
        Header: (props) => (
          <span className="form-textHead cell-head">Email</span>
        ),
        filterMethod: (filter, row) => {
          debugger;
          return row.source.toLowerCase().includes(filter.value.toLowerCase());
        },
        accessor: "customerDetail.email",
        Cell: (props) => <span className="form-text-cell">{props.value}</span>,
        minWidth: 220,
      },
      {
        Header: (props) => (
          <span className="form-textHead cell-head">Mobile</span>
        ),
        filterMethod: (filter, row) => {
          debugger;
          return row.source.toLowerCase().includes(filter.value.toLowerCase());
        },
        accessor: "customerDetail.number",
        Cell: (props) => <span className="form-text-cell">{props.value}</span>,
        minWidth: 220,
      },
      {
        Header: (props) => (
          <span className="form-textHead cell-head">Booking Date</span>
        ),
        accessor: "customerBookingDetail.date",
        filterMethod: (filter, row) => {
          debugger;
          return row.source.toLowerCase().includes(filter.value.toLowerCase());
        },
        Cell: (props) => <span className="form-text-cell">{props.value}</span>,
        minWidth: 240,
      },
      // {
      //   Header: props => <span className="form-textHead cell-head">booking Time</span>,
      //   accessor: 'customerBookingDetail.time',
      //   filterMethod: (filter, row) =>
      //   {debugger;   return row.source.toLowerCase().includes(filter.value.toLowerCase()) },
      //   Cell: props => <span className='form-text-cell'>{props.value}</span>,
      //   minWidth:170
      // },
      {
        Header: (props) => (
          <span className="form-textHead cell-head">Time Of Creation</span>
        ),
        accessor: "date",
        filterMethod: (filter, row) => {
          debugger;
          return row.source.toLowerCase().includes(filter.value.toLowerCase());
        },
        Cell: (props) => new Date(props.value).toLocaleTimeString(),
        minWidth: 100,
      },
      {
        Header: (props) => (
          <span className="form-textHead cell-head">Date of Creation</span>
        ),
        accessor: "date",
        filterMethod: (filter, row) => {
          debugger;
          return row.source.toLowerCase().includes(filter.value.toLowerCase());
        },
        Cell: (props) => new Date(props.value).toDateString(),
        minWidth: 150,
      },
      {
        Header: (props) => (
          <span className="form-textHead cell-head">Created By</span>
        ),
        accessor: "createdBy",
        filterMethod: (filter, row) => {
          debugger;
          return row.source.toLowerCase().includes(filter.value.toLowerCase());
        },
        Cell: (props) => (
          <span className="form-text-cell"> {props.value} </span>
        ),
        minWidth: 240,
      },
    ];
    return (
      <div className="container" style={{ marginTop: "4%" }}>
        <div className="row">
          <Row>
            <Col>
              <div className="card">
                <ReactTable
                  className="myTable"
                  resolveData={(data) => data.map((row) => row)}
                  data={this.state.allBookingData}
                  columns={columns}
                  filterable
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
