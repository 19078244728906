import React from 'react';
import 'react-quill/dist/quill.snow.css'; // ES6
import axios from 'axios';
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditorJs from 'react-editor-js';
import {EditorjsTool} from '../Editor/tool'
import {v1 as uuidv1} from 'uuid';
import _ from 'underscore';
import $ from 'jquery'
import Image from "@editorjs/image";
import CompanyLawSidebar from './CompanyLawSidebar';

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

class MyComponent extends React.Component {
  constructor(props) {
    super(props)

    // var allText = 'Hi write something';
    this.state = {
      // text: allText,
      pageHeading:'',
      lastUrl:'',
      resdata: [],
      type:[],
      lawlist:[],
      filterData:[],
      law:'',
      u : 0,
      c : 0,
      open:false,
      setOpen:false,
      lawvalue:'',
      headings : "",
      'subHeading':"",
      index:0,
      allCategory:[],
      catDes:'',
    }
    this.handleChange = this.handleChange.bind(this);
    this.fetch_all_acts = this.fetch_all_acts.bind(this);
    this.fetch_from_dynamo = this.fetch_from_dynamo.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    this.save_to_dynamo = this.save_to_dynamo.bind(this);
    this.setLaw = this.setLaw.bind(this);
    this.setSection = this.setSection.bind(this);
    // this.setSectionDes = this.setSectionDes.bind(this);
    this.incrementu = this.incrementu.bind(this);
    this.addUrl = this.addUrl.bind(this);
    this.addContent = this.addContent.bind(this);
    this.incrementc = this.incrementc.bind(this);
    this.handleChangeContent = this.handleChangeContent.bind(this)
    this.handleChanges =this.handleChanges.bind(this)
    this.setInitialResourceHandler = this.setInitialResourceHandler.bind(this)
  }


  componentDidMount(){
    var lastUrl = window.location.pathname.split('/').pop()
    this.setType(lastUrl)
  }

  async setType(lastUrl){
    var page=''
    if(lastUrl==='circular-notification'){
      page='Circular Notification'
    }
    else if(lastUrl==='case-law'){
      page="Case Law"
    }
    else if(lastUrl==='acts'){
      page='Acts'
    }
    else if(lastUrl==='rules'){
      page='Rules'
    }
    this.setState({lastUrl:lastUrl,pageHeading:page})
    await this.fetchCategory(page)
    this.fetch_all_acts(page)

  }

  setInitialResourceHandler(){
    const that = this
    $(document).ready(function(){
      $("#secDes").blur(function(){
        let resource = document.getElementById('sec').value
        let resourceDes = document.getElementById('secDes').value
        
        for(var i=0;i<that.state.filterData.length;i++){
          if(that.state.filterData[i].section===resource){
            if(that.state.filterData[i].sectionDes===resourceDes){
              that.setState({open:true})
              break
            }
          }
        }
      })
    })
  }

  fetchCategory(page){
    let allCategory=[]
    return axios({
      url: 'https://3j0okb7n5h.execute-api.us-east-1.amazonaws.com/dev/businus/legalCategory/list',
      method: 'GET',
      crossDomain: true,
      responseType: 'json', // important
    }).then((response) => {
      for(var i=0;i<response.data.length;i++){
        if(response.data[i].legalType==='CompanyLaw')
          if(response.data[i].type===page){
            
          allCategory.push(response.data[i])
        }
      }
      this.setState({allCategory:allCategory})
     
   });
  }

  fetch_all_acts(page){
    this.fetch_from_dynamo().then(data => {
      this.setState({ resdata:  data});
      this.setLaw(page);
    })
  }


  fetch_from_dynamo(){
    return axios({
      url: 'https://tj1cb28koh.execute-api.us-east-1.amazonaws.com/dev/businus/article/list',
    
      method: 'GET',
      crossDomain: true,
      responseType: 'json', // important
    }).then((response) => {
      return response.data;

   });
  }

  handleChange(e) {
    let target = e.target;
    let name = target.name;
  
    this.setState({[name]:target.value});
  }


  handleChangeContent(value) {
    this.setState({ text: value })
  }

  // handleChange(value) {
  //   this.setState({ text: value })
  //   // document.getElementById('save').innerHTML = 'Add Act';
  // }

  uploadByFile(file) {

    let fd = new FormData();
    fd.append('file',file);
  }

validation(content){
  let flag = true

  if(this.state.law===''){
    flag=false
    alert("Please enter the category heading")
  }
  else if(document.getElementById('sec').value.trim()===''){
    flag=false
    alert("Please enter the section heading")
  }
  else if(document.getElementById('heading').value.trim()===''){
    flag=false
    alert("Please enter the heading")
  }
  else if(content.blocks.length===0){
    flag=false
    alert('Please enter the content')
  }
  return(flag)
}

 async save_to_dynamo(){

    var external = [];
    var internal = [];
    var sel = "";
    for (var i = 0; i < this.state.u; i++) {
      external[i] = {
        src : document.getElementById('src_' + i).value,
        url : document.getElementById('url_' + i).value,
        name : document.getElementById('srcName_' + i).value,
      }
    }

    for (i = 0; i < this.state.c; i++) {
      sel = document.getElementById('head_' + i);
      internal[i] = {
        heading: sel.options[sel.selectedIndex].text,
        id: sel.options[sel.selectedIndex].id,
      }
    }

    var more = {
      external : external,
      internal : internal
    }

    const content = await this.editorInstance.save()

    let validData = this.validation(content)
    
    var act = {
      legalType: "CompanyLaw",
      type: this.state.type,
      law: this.state.law,
      lawDes: this.state.law,
      section: document.getElementById('sec').value.trim(),
      sectionDes: document.getElementById('secDes').value.trim(),
      heading: document.getElementById('heading').value.trim(),
      subHeading: document.getElementById('subHeading').value.trim(),
      content:content,
      more:more,
      index:this.state.filterData.length,
      seo:this.state.seo,
      slug:this.state.slug,
      metaDescription:this.state.metaDescription
    };
   

    if(validData){
      console.log("act :", act);
      axios({
        url: 'https://tj1cb28koh.execute-api.us-east-1.amazonaws.com/dev/businus/article/add',
        method: 'POST',
        data: act,
      }).then((response) => {
        console.log('resonse_data_dynamo :', response.data)
        if(response.status===200){
          alert("Company Law Resource Added");
          window.location.reload()
        }
     });
    }

  }

  setLaw(type){
    // document.getElementById("law").value = "";
    document.getElementById("sec").value = "";
    // var e = document.getElementById("type");
    // var type = e.options[e.selectedIndex].text;


    var lawlist = document.getElementById("lawlist");
    var acts = this.state.allCategory;
    var laws = [];
    var disLaw = [];
    var opts = "";

    for (var i = 0; i < acts.length; i++) {
      if(acts[i].legalType==="CompanyLaw"){
      if (acts[i].type===type) {
        laws.push(acts[i]);
        if (!disLaw.includes(acts[i].law)) {
          disLaw.push(acts[i].law);
          opts += "<option>" + acts[i].law + "</option>";
        }
      }
    }
    }
    // lawlist.innerHTML = opts;
    this.setState({
      lawlist: laws,
      type:type
    })
  }


  // setSection(){
  //   var law = document.getElementById("law").value;
  //   var acts = this.state.allCategory;
  //   var sec = '';
  //   var lawDes = '';
  //   var optDes ='';
  //   var lawDescription = []
  //   for (var i = 0; i < acts.length; i++) {
  //     if (acts[i].law==law) {
  //       sec=acts[i].id;
  //       lawDes = acts[i].lawDes;
  //       if(!lawDescription.includes(acts[i].lawDes)){
  //         lawDescription.push(acts[i].lawDes)
  //         optDes+= "<option>"+ acts[i].lawDes+"</option>";
  //         document.getElementById("lawDes").value=acts[i].lawDes
  //       }
  //     }
  //   }
  //   document.getElementById("lawDeslist").innerHTML = optDes;
  //   this.setState({
  //     law: sec,
  //     lawvalue: law
  //   })
  // }

    setSection(e){
      let id =e.target.value
      let filterData = _.find(this.state.allCategory,function(category){
        return category.id===id;
      })
        this.setState({law:id,catDes:filterData.lawDes})
        this.filterResources(id)
    }

    filterResources(id){
      let filterResourcesTemp=[]

      for(var i=0;i<this.state.resdata.length;i++){
        if(this.state.resdata[i].law===id){
          filterResourcesTemp.push(this.state.resdata[i])
        }
      }
      this.setState({filterData:filterResourcesTemp})    }

  // setSectionDes(){
  //   var sec = document.getElementById("sec").value;
  //   var secs = this.state.law;
  //   var data = this.state.resdata;
  //   var secDes = document.getElementById('secDes').value;
  //   var lawDes = document.getElementById('lawDes').value 
  //   var temp_data = []


  //   for (var i = 0; i < data.length; i++) {
  //       if(data[i].law === secs){
  //         temp_data.push(data[i])
          
  //         if(data[i].section === sec){
  //             document.getElementById("secDes").value=data[i].sectionDes
  //             this.setState({open: true});      
  //         }
  //         secDes = secs[i].sectionDes;
  //       }

  //   }
  //   this.setState({filterData:temp_data})
  // }

  incrementu() {
    // increments variable for no. of urls in MORE

    var inc = this.state.u + 1;
    this.setState({
      u : inc
    });
  }

  incrementc() {
    // increments variable for no. of website content in MORE

    var inc = this.state.c + 1;
    this.setState({
      c : inc
    });
  }

  addUrl(){
    // adds a URL entry in MORE
    
    var out_div = document.createElement('div');
    var spn = document.createElement('span');
    var src = document.createElement("INPUT");
    var url = document.createElement("INPUT");
    var srcName = document.createElement("INPUT");
    var br = document.createElement("br");
    this.incrementu();
    var i = this.state.u;

    out_div.setAttribute("class","input-group mb-3");
    out_div.style.width = '80%';
    // out_div.setAttribute("id", "urldiv_" + i);

    spn.setAttribute("type", "text");
    spn.setAttribute("class", "input-group-addon");
    spn.style.width = '20%';
    spn.innerHTML = "Source Url";
    // spn.setAttribute("id", "lat_" + i);

    src.setAttribute("type", "text");
    src.setAttribute("class", "form-control");
    src.setAttribute("placeholder", "Source");
    src.setAttribute("id", "src_" + i);

    url.setAttribute("type", "text");
    url.setAttribute("class", "form-control");
    url.setAttribute("placeholder", "Url");
    url.setAttribute("id", "url_" + i);

    srcName.setAttribute("type", "text");
    srcName.setAttribute("class", "form-control");
    srcName.setAttribute("placeholder", "Name");
    srcName.setAttribute("id", "srcName_" + i);

    out_div.appendChild(spn);
    out_div.appendChild(src);
    out_div.appendChild(url);
    out_div.appendChild(srcName);
    document.getElementById("more").appendChild(out_div);
    document.getElementById("more").appendChild(br);

  }

  addContent(){
    // adds a WEBSITE CONTENT entry in MORE

    var out_div = document.createElement('div');
    var spn = document.createElement('span');
    var src = document.createElement("select");
    var br = document.createElement("br");
    this.incrementc();
    var i = this.state.c;

    out_div.setAttribute("class","input-group mb-3");
    out_div.style.width = '80%';
    out_div.setAttribute("id", "web_" + i);

    spn.setAttribute("type", "text");
    spn.setAttribute("class", "input-group-addon");
    spn.style.width = '20%';
    spn.innerHTML = "Heading";

    // src.setAttribute("type", "text");
    src.setAttribute("class", "form-control");
    // src.setAttribute("placeholder", "Heading");
    src.setAttribute("id", "head_" + i);
    src.innerHTML = this.state.headings;

    out_div.appendChild(spn);
    out_div.appendChild(src);
    document.getElementById("more").appendChild(out_div);
    document.getElementById("more").appendChild(br);

  }


handleClose = () => {
  this.setState({open: !this.state.open})
}

handleRedirect(that){
  window.location.replace('/view-company-law/'+that.state.lastUrl)
}

handleChanges(e){
  let target = e.target;
  let name = target.name;

  this.setState({[name]:target.value});
}

  render() {
    const {open} = this.state
    return (
      <div>
        <div className="row">
          <div className="col-md-2"> <CompanyLawSidebar/></div>
        <div className="col-md-10">
      <div className = "container-fluid overflow-auto" style={{margin: "10px", height:'90vh'}}>
        <div style={{margin: "10px"}}><h3>Add {this.state.pageHeading} Resources: </h3></div>
        <div className="container-fluid" style={{}}>


          <div class="well well-sm">

            <div class="input-group mb-3" style={{width:"80%"}}>
              <span type="text" class="input-group-addon" id="" style={{width:"20%"}}>Category</span>
              {/* <input type="text" class="form-control" onChange={this.setSection} list="lawlist" id="law" placeholder="Heading" autocomplete="off" required></input> */}
              <select className='form-control' name='category' onChange={e=>this.setSection(e)}>
              <option value=''> Select the Category </option>
              {
              this.state.allCategory.map((cat)=>(
                <option value={cat.id}> {cat.law} </option>
              ))}
              </select>
              <input disabled={true} type="text" class="form-control" value={this.state.catDes} id="lawDes" autoComplete='off' required></input>
            </div><br/>

            <div class="input-group mb-3" style={{width:"80%"}}>
              <span type="text" class="input-group-addon" id="" style={{width:"20%"}}>Resources</span>
              <input type="text" class="form-control" list='sectionlist' id="sec" onChange={e=>this.setInitialResourceHandler(e)} placeholder="Heading" autocomplete="off" required></input>
              <input type="text" class="form-control" id="secDes" onChange={e=>this.setInitialResourceHandler(e)} placeholder="Description" autocomplete="off" required></input>
              <datalist id="sectionlist">

              </datalist>
            </div>
        </div><br/>

          <div class="well well-sm">
            <div class="input-group mb-3" style={{width:"80%"}}>
              <span type="text" class="input-group-addon" id="" placeholder="Title" style={{width:"20%"}}>Heading</span>
              <input type="text" class="form-control" id="heading" placeholder="" autocomplete="off" style={{width:this.state.inpWidth}} required></input>
            </div><br/>

            <div class="input-group mb-3" style={{width:"80%"}}>
              <span type="text" class="input-group-addon" id="" placeholder="Title" style={{width:"20%"}}>Sub Heading</span>
              <input type="text" class="form-control" id="subHeading" autocomplete="off" placeholder="" style={{width:this.state.inpWidth}} required></input>
            </div><br/>

            <div class="input-group" style={{width:"100%"}}>
              <span type="text" class="input-group-addon" id="" placeholder="Content" style={{"width":"16%"}}>Content</span>
              
              <div style={{border:"1px solid #ededed" ,marginRight:'3%'}}>
              <div style={{height:'40%',paddingBottom:"0"}}>
                
                <EditorJs 
                tools={{image:  {
                  class: Image,
                  config: {
                   uploader:{
                   uploadByFile: async function(file){
                      console.log(file);
                      const id = uuidv1()
                      const imageURL='https://businus-files-live.s3.amazonaws.com/image/'+id+'.png';
                      
                     let fileBase = await toBase64(file);
                     let result = await axios.post(
                      "https://tj1cb28koh.execute-api.us-east-1.amazonaws.com/dev/businus/uploadEditorImage",
               {
                   image:fileBase,
                   imageName:fileBase.name,
                   id:id,
                   imageURL:imageURL
               })

               return{
                success:1,
                file:{
                  url:imageURL
                }
                 }
                 
                      
                    },
                    
                   }
                  }
                },...EditorjsTool}} 
                instanceRef={instance=>this.editorInstance=instance}
                ></EditorJs>
                </div>
              </div>


             
            </div><br/>

            
          </div><br/>

          <div className='well well-sm'>
          <h5> Add SEO </h5>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="SEO"span style={{width:"20%"}}>SEO</span>
            <input type="text" class="form-control" name="seo" value={this.state.seo} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Slug"span style={{width:"20%"}}>Slug</span>
            <input type="text" class="form-control" name="slug" value={this.state.slug} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Meta Description"span style={{width:"20%"}}>Meta Description</span>
            <input type="text" class="form-control" name="metaDescription" value={this.state.metaDescription} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>

          </div>
          

          <div class="well well-sm" id='more'>
            <h5>Add More :- </h5>

            <button type='button' class="btn btn-default" style={{margin:'10px'}} id = '' onClick={this.addUrl}>Add Url</button>

           
          </div>
          <div  style={{width : "80%", position:"relative", height:"90px"}}>
            <button type='button' class="btn btn-primary khub-submit-button" style={{margin:'10px'}} id = 'save' onClick={this.save_to_dynamo}>Add Act</button>
          </div>
        </div>

        <Dialog 
        open={open}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
          <DialogTitle id="alert-dialog-slide-title">{"This content already exist"}</DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            This section is already exist. Do you want to update the existing data or continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.handleRedirect(this)} color="primary">
            Update
          </Button>
          <Button onClick={this.handleClose} color="primary">
            Continue
          </Button>
        </DialogActions>
        </Dialog>

        </div>
        </div>
      </div>
      </div>
    )
  }
}
export default MyComponent
