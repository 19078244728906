import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import {getPrivilegeByName} from '../../privilegeUtils';
const data = [];
export default class SidebarMail extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []
     
    };
    
  }
  componentDidMount(){
    this.handleLine();
   // this.setState({title:this.props.match.params.productType});
  }
  handleLine(){

    $(function(){
      $("#tab1").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
        $("#section").toggle();
      });
      });
      $(function(){
      $("#c2").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
        $("#d2").toggle();
      });
      });
      $(function(){
        $("#c3").click(function(e){
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d3").toggle();
        });
        });
      $(function(){
        $("#c4").click(function(e){
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
            $("#d4").toggle();
          });
          });
      $(function(){
         $("#c5").click(function(e){
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d5").toggle();
            });
            });
      $(function(){
          $("#c6").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d6").toggle();
              });
              });
      $(function(){
          $("#c7").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d7").toggle();
                });
                });
      $(function(){
          $("#c8").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d8").toggle();
                  });
                  });  
      $(function(){
          $("#c10").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d10").toggle();
                  });
                  });              
  
  }
  
  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
    
            <div className="sidebar">
              <div id="sidebar1">
              <div className="sidebar-head">Client-Communication</div>
             
                {/*<div className="sidebar-sectionName">
    Products</div>*/}
                <div className="oneTab">
                <div className="sidebar-elementhead"><Link className="sidebar-link" to="/">Home</Link></div>
                <div className="sidebar-element" id="c1"> <Link className="sidebar-link" to={"/communication-master"}><i className="fa fa-bar-chart" />Dashboard<i className="fa fa-angle-right " /></Link></div>
                  <div className="sidebar-subelement" id="d1"><i className="fa fa-envelope" />View<i className="fa fa-angle-right " /></div>
                </div>
                <div className="oneTab">
                  <div className="sidebar-element" id="c3">
                    <i className="fa fa-envelope" />
                    Messages
                    <i className="fa fa-angle-down " />
                  </div>
                  <div className="sidebar-subelement" id="d3">
                  
                  <div><Link className="sidebar-link" to={"/communication-master/send/messages"} style={{marginLeft:'12px'}}>
                    <i className="fa fa-plus" />
                    New Message
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                    
                    <div>
                    <Link className="sidebar-link" to={"/communication-master/view/recieved/messages"} style={{marginLeft:'12px'}}>
                      <i className="fa fa-download" />
                    Recieved Messages
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                    <div>
                    <Link className="sidebar-link" to={"/communication-master/view/sent/messages"} style={{marginLeft:'12px'}}>
                      <i className="fa fa-paper-plane" />
                    Sent Messages
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                  </div>
                </div>
               
                <div className="oneTab">
                  <div className="sidebar-element" id="c4">
                    <i className="fa fa-file" />
                    Proposals
                    <i className="fa fa-angle-down " />
                  </div>
                  <div className="sidebar-subelement" id="d4">
                 
                  <div><Link className="sidebar-link" to={"/communication-master/send/proposal"}>
                    <i className="fa fa-plus" />
                    Send Proposal
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                  
                    <div>
                    <Link className="sidebar-link" to={"/communication-master/view/proposal"}>
                      <i className="fa fa-eye" />
                    View Proposal
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                  </div>
                </div>
               
               
                <div className="oneTab">
                  <div className="sidebar-element" id="c5">
                    <i className="fa fa-folder" />
                    Documents
                    <i className="fa fa-angle-down " />
                  </div>
                  <div className="sidebar-subelement" id="d5">
                  
                  <div><Link className="sidebar-link" to={"/communication-master/send/document"}>
                    <i className="fa fa-plus" />
                    Send Documents
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                    
                    <div>
                    <Link className="sidebar-link" to={"/communication-master/view/document"}>
                      <i className="fa fa-eye" />
                    View Documents
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                  </div>
                </div>
              

                <div className="oneTab">
                  <div className="sidebar-element" id="c6">
                    <i className="fa fa-bell" />
                    Updates
                    <i className="fa fa-angle-down " />
                  </div>
                  <div className="sidebar-subelement" id="d6">
                 
                  <div><Link className="sidebar-link" to={"/communication-master/send/update"}>
                    <i className="fa fa-plus" />
                  Send Updates
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                    
                    <div>
                    <Link className="sidebar-link" to={"/communication-master/view/update"}>
                      <i className="fa fa-eye" />
                    Views Updates
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                  </div>
                </div>

                <div className="oneTab">
                  <div className="sidebar-element" id="c8">
                 
                    <i className="fa fa-list-alt" />
                    Templates
                    <i className="fa fa-angle-down " />
                   
                  </div>
                
                   <div className="sidebar-subelement" id="d8">
                    
                    {getPrivilegeByName('Assignment').canAdd == true ?
                    <div>
                    <Link className="sidebar-link" to={"/communication-master/template/add"}>
                    <i className="fa fa-plus" />
                    Create Templates
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>:''}
                    <div>
                    <Link className="sidebar-link" to={"/communication-master/template/view"}>
                    <i className="fa fa-eye" />
                    View  Templates
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                  </div>
                </div>
              
  
              </div>
            </div>
         
    );
  }
}
