import axios from 'axios';
import React, { Component } from "react";
import { apiBaseUrl5, apiBaseUrl6, apiWebzyUrl } from '../apiUtils';
import "../../style/panel.css";
import { Link } from "react-router-dom";
import _ from 'underscore';

const data = [];
let context  = null;
export default class WebzyDashboard extends Component {

  constructor(props){
    super();
    this.state={
      isLoading:true,
      sites:[],
      profile:'',
      userName:'',
      isDropdownOpen:false,
      firstTime:"",
      
    }
    context=this;
  };

componentDidMount(){
  this.fetchWebsites(this);
}
  

fetchWebsites(that) {

  axios.get(apiWebzyUrl+ "businuswebsite/list/"+localStorage.getItem("employeeId")+"/emp")
    .then(function (response) {
      console.log(response.data);
      that.setState({ sites: _.sortBy(response.data, "date") ,isLoading:false});
    })
    .catch(function (error) {
      console.log(error);
      
    });

}

  render() {
    return (
      <div>

      
          <div className="mainScreen">
      <div className="container">
      
     
     <div className="row">
       <div className="col-md-12">
      <div className="tab-row1">
   
      <div className="row">
      <div className="col-md-2">
     <div className="tab text-center">
    
     <Link to="/webzy/add-assigns"><i  className="tab-image1 fa fa-sitemap" id="tab1" 
                 style={{fontSize:'44px',paddingTop:'20px'}} 
               
                />
        <h2 className="tab-text">
       Assign/Create Pages
          </h2></Link>
     </div>
     </div> 
      {this.state.sites.map((site,i)=>{

return (<div className="col-md-2">
<div className="tab text-center">
<Link to={"/webzy/page/"+site.publishId}>
<i class={"tab-image"+((i+1)%4)+" fa fa-tv"} style={{fontSize:'44px',paddingTop:'20px'}} />
   <h2 className="tab-text">{site.name}</h2></Link>
</div>
</div>)

})}

    
    
        
         </div>


         </div>

         </div>
         
        

     </div>
    
       </div>
     </div>
     </div>
    
    
    );
  }
}
