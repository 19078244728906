import React, { Component } from "react";
import "../../style/panel.css";
import { Link } from "react-router-dom";
import KnowledgeHubSidebar from './KnowledgeHubSidebar'
import axios from "axios";
import ReactTable from 'react-table';
import BreadCrumbs from "../../components/BreadCrumbs";
import { domainUrl } from "../../components/apiUtils";

export default class ListLegalBookmark extends Component{
    constructor(props) {
        super(props)
        this.state={
            bookmarkList:[]
        }
        this.fetch_all_bookmark = this.fetch_all_bookmark.bind(this)
    }

    componentDidMount(){
        let url = window.location.href.split('/')

        this.fetch_all_bookmark(url[url.length-2])
    }
    
    fetch_all_bookmark=(learningCategory)=>{
        let temp_res=[]
        return axios({
            url: ("https://tj1cb28koh.execute-api.us-east-1.amazonaws.com/dev/businus/bookmark/list"),
            method: 'GET',
            crossDomain: true,
            responseType: 'json', // important
          }).then((response)=>{
            
              for(var i=0;response.data.length;i++){
                  if(response.data[i]!==undefined){
                    if(response.data[i].learningCategory==='legal-finance'){
                        temp_res.push(response.data[i])
                    }
                  }
                  else{
                      break
                  }

              }
              
              this.setState({bookmarkList:temp_res})
          })
    
    }

render(){
    const columns = [
        {
          Header: <div className="form-textHead cell-head">Bookmark Id</div>,
          accessor: d=>{return{id:d.id,bookmarkId:d.bookmarkId,learningCategory:d.learningCategory,blogId:d.blogId}},
          id:'bookmarkId',
          filterMethod: (filter, row) => {   return row.catagory.toLowerCase().includes(filter.value.toLowerCase()) },
          Cell: props => <span className='form-text cell'><a target='_blank' href={domainUrl+props.value.learningCategory+'/resources/'+props.value.blogId}>{props.value.bookmarkId}</a></span>,
          minWidth:120
        },
         {
          Header:<div className="form-textHead cell-head"> Heading</div>,
          accessor: d=>{return{id:d.id,title:d.title,learningCategory:d.learningCategory,blogId:d.blogId}},
          id:'id',
          Cell: props => <span className='form-text cell'><a target='_blank' href={domainUrl+props.value.learningCategory+'/resources/'+props.value.blogId}>{props.value.title}</a></span>,
          minWidth:220
        },
        {
            Header:<div className="form-textHead cell-head">User Name</div>,
            accessor: 'by',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:150
        },
        {
            Header:<div className="form-textHead cell-head">Email</div>,
            accessor: 'email',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:150
        },
        {
          Header: props => <span className="form-textHead cell-head"> Date</span>,
          accessor: 'date',
          filterMethod: (filter, row) =>
                   {   return row.source.toLowerCase().includes(filter.value.toLowerCase()) },
          Cell: props => 
          new Date(props.value).toDateString(),
          minWidth:180
        },{
          Header: props => <span className="form-textHead cell-head"> Time</span>,
          accessor: 'date',
          filterMethod: (filter, row) =>
                   {   return row.source.toLowerCase().includes(filter.value.toLowerCase()) },
          Cell: props => 
          new Date(props.value).toLocaleTimeString(),
          minWidth:180
        }];
return(
    <div className="row">
    <div className="col-md-2 col-lg-2">
        <KnowledgeHubSidebar />
    </div>

    <div className="col-md-10 col-lg-10">
    <div className = "Div" >
       <div className="row breadcrumb-row">
          <div className="col-md-10" style={{marginTop:'10px'}}><BreadCrumbs/></div></div>
        <div className="row" style={{}}>
          <ReactTable data={this.state.bookmarkList} columns={columns} style={{height:'85vh',overflow:'scroll'}} filterable/>
        </div></div>
    </div>


    </div>
)
}
}