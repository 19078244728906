import React from 'react';
import parser from 'html-react-parser';


function ReadOnly({ data,headers}) {
    let index =-1
  const renderBlock = ({type,data}) => {
    let content = ""
    switch (type) {
      case "header":  
        index+=1
              
          
        const element =  React.createElement(
          `h${data.level}`,
          {
            className: 'ce-header',
          },
          data.text
        )  
        content =    (<div id={'header'+index} style={{height: "fit-content"}}>
            {element}
          </div>)
      break;
      case "list":
        content = (<ul className={`"cdx-block" "cdx-list" "cdx-list--${data.style}"`}>
          {data.items.map(item =>  <li class="cdx-list__item">{item}</li>)}
        </ul>)
      break;
      case "embed":
          content = (<div className="cdx-block embed-tool">
          <iframe className="embed-tool__content" style={{width: "fit-100%"}} height="320" frameborder="0" width="580"
            allowfullscreen src={data.embed}></iframe>
          <div style={{'text-align': "center", 'margin-top': "5px"}}>{data.caption}</div>
          </div> )
      break;
      case "code":
        content = (<div class="cdx-block ce-code">
            <span style={{'text-align': "right", 'margin-bottom': "5px"}}>{data.language}</span>
            <pre className="ce-code__textarea cdx-input prettyprint"><code className="lang-js">{data.code+""}</code></pre>
          </div>)
      break;
      case "image":
          content = (
              data.file!=undefined?
            <div className="cdx-block image-tool image-tool--filled">
              <div className="image-tool__image">
                <img className="image-tool__image-picture" src={data.file.url}></img>
                <div style={{'text-align': "right", 'margin-bottom': "5px"}}>{data.caption}</div>
              </div>
            </div>:''
          )
      break;
      default:
        content = (<div className="ce-paragraph cdx-block"> {parser(`${data.text}`)} </div>)
        break;
    }
    return (<div className="ce-block">
        {/* <nav className='navbar navbar-inverse navbar-fixed-top'>
            <ul className='nav navbar-nav'>
                {headers.map((header,index) =>(
                    <li><a href={'#header'+index}>{header}</a></li>
                ))}
            </ul>
        </nav> */}
    <div className="ce-block__content">
      {content}
    </div>
  </div>)
  }

  return (
    data!==undefined? data.blocks.map((block)=> renderBlock(block)):""
  );
}

export default ReadOnly;