import $ from "jquery";
import React, { Component } from "react";
import { Route } from 'react-router-dom';
import "../../style/panel.css";

import AddAssigns from './addAssigns';
import AssignPage from './assignPage';
import editAssigned from './editAssigned';
import EditAssigns from './editassigns';
import ListAssigns from './listAssigns';
import SidebarWebzy from './SidebarWebzy';
import AddTemplate from './addTemplate';
import ListTemplates from './listTemplates';
import WebzyDashboard from './webzyDashboard';
import ReAssign from './ReAssign';






const data = [];
export default class WebzyMainapp extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []
     
    };
    
  }
  componentDidMount(){
    this.handleLine();
    
    //this.setState({title:this.props.match.params.productType});
  }
  handleLine(){
    
   
    const tab1= this.refs.tab1;
    const sidebar1= this.refs.sidebar1;
    const section= this.refs.section;
   
    
    $(function(){
      $("#tab1").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
        $("#section").toggle();
      });
      });
     
  }
  
  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
      <div className="mainDiv" >
       
       <div className="row">
         <div className="col-md-2">
         <SidebarWebzy title={this.state.title}/>
         </div>
         <div className="col-md-10">
         
      
<div className="section">
<Route  exact path = "/webzy/view-assigns" component = {ListAssigns} />
<Route  exact path = "/webzy/add-assigns" component = {AddAssigns} />
<Route  exact path = "/webzy/add-template" component = {AddTemplate} />
<Route  exact path = "/webzy/view-templates" component = {ListTemplates} />
<Route  exact path = "/webzy/re-assigns/:id" component = {ReAssign} />
<Route  exact path = "/webzy/view-assigns/list/:id" component = {AssignPage} />



{/* <Route  exact path = "/webzy/view-assigns/edit/:id" component = {editAssigned} /> */}
<Route  exact path = "/webzy/edit-assigns" component = {EditAssigns} />





<Route  exact path = "/webzy/dashboard" component = {WebzyDashboard} />


</div>

         </div>
       </div>
      
     </div>

    );
  }
}
