import React, { Component } from "react";
import "../../style/panel.css";
import { Link } from "react-router-dom";
import WealthSidebar from "./WealthSidebar";

export default class WealthDashboard extends Component{
    render(){
        return(
            <div className="row">
                <div className="col-md-2">
            <WealthSidebar />
          </div>
          <div className="col-md-10">
                <div className="mainScreenKhub">
                <div className="container">
                    <div className="row">
                    <div className="col-md-6">
      <div className="tab-row1">
      <h2 className="tab-row-head">Article</h2>
      <div className="row">

      <div className="col-md-4">
     <div className="tab text-center">
     <Link to="/knowledgeHub/businus-school/wealth/article">
     <img className="tab-image1" id="tab1" 
                  style={{ marginLeft: "-3px" }}
                  src={require("../../images/home.png")}
                />
        <h2 className="tab-text-main"> Article </h2></Link>
     </div>
     </div> 
        
         </div>


         </div>

         </div>

         <div className="col-md-6 col-lg-6">
                <div className="tab-row1">
                    <div className="tab-row-head"> Banner </div>
                </div>
                <div className="row">
                <div className='col-md-4 col-lg-4'>
                    <div className="tab text-center">
                    <Link to="/knowledgeHub/businus-school/wealth/addBanner">    
                <img className="tab-image1" id="tab1"
                            style={{ marginLeft: "-3px" }}
                            src={require("../../images/N6.png")}
                            />
                    <h2 className="tab-text-main">Add Banner</h2></Link>
                </div>
                                </div>
                </div>
            </div>

            <div className="col-md-6 col-lg-6">
                <div className="tab-row1">
                    <div className="tab-row-head"> Bookmarks </div>
                </div>
                <div className="row">
                <div className='col-md-4 col-lg-4'>
                    <div className="tab text-center">
                    <Link to="/knowledgeHub/businus-school/stories-insights/view-bookmarks">    
                <img className="tab-image1" id="tab1"
                            style={{ marginLeft: "-3px" }}
                            src={require("../../images/N6.png")}
                            />
                    <h2 className="tab-text-main">View Bookmarks</h2></Link>
                </div>
                                </div>
                </div>
            </div>


            </div>
                </div>
                </div>
            </div></div>
        )
    }
}