import React, { Component } from "react";
import AddDoc from "./AddDoc";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill"; // ES6
import $ from "jquery";
import axios from "axios";
import BreadCrumbs from "../../BreadCrumbs";
import { apiBaseUrl1 } from "../../apiUtils";
import { apiBaseUrl2 } from "../../apiUtils";
import FileBase64 from "react-file-base64";
import Modal from "react-modal";
import { ChatFeed, Message } from "react-chat-ui";
import { Multiselect } from "multiselect-react-dropdown";
import { ToastsStore } from "react-toasts";
import Select from "react-select";
import {validate} from '../../common/Validation';
import AddComment from "./AddComment.js";
import ReactTableUI from "./ReactTableUI";
import WorkflowComponent from "./WorkflowComponent";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import _ from "underscore";
let context = null;
const data = [];
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px ",
    position: "Absolute",
    zIndex: "30000",
    backgroundColor: "none",
  },
};

export default class AddWorkflow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workflowId: "",
      workflowName: "",
      currentRow: 0,
      message: "null",
      workflowOverview: "",
      customColumnTypes: [
        { label: "Select Type", value: "null" },
        { label: "Text", value: "text" },
        { label: "Numbers Type", value: "number" },
        { label: "Date", value: "date" },
        { label: "Description", value: "textarea" },
        { label: "File Uploader", value: "file" },
      ],
      workflow: [
        {
          isEdit: true,
          index: 0,
          attachment: [],
          url: [],
          doc: [],
          comment: [],
          checklist: "",
        },
      ],
      workFlowList: [],
      columnName: "",
      isSubmitClicked: false,
      superSliderState: "additional",
      sliderState: 1,
      clients: [],
      organisations:[],
      contactPersonsClients: [],
      contactPersons: [],
      employees: [],
    };
    context = this;

    this.closeModal = this.closeModal.bind(this);
  }

  openWorkflowModal = () => {
    this.setState({ modalWorkflowIsOpen: true });
  };

  saveToWorkflowList = (workflow, index) => {
    let workFlowList = this.state.workFlowList;

    workFlowList[index] = workflow;

    this.setState({ workFlowList: workFlowList });
    ToastsStore.success("Workflow Saved to Workflow List");

    /* UNCOMMENT IF WANT TO SAVE WORKFLOW CHANGES WITHOUT SUBMIT */
    /* ToastsStore.warning('Auto save in progress...');
    this.sendMail();
 */
  };

  handleOnClientChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;

    var selectedClient = _.findWhere(this.state.clients,{id:e.target.value});
    var organisations =selectedClient.assignedOrganisation;
    debugger;
    if(organisations!="0" && organisations!==undefined){
      organisations.unshift({name:"Select Organisation",organisationId:""})
      
      console.log(organisations)
    }
    
    
    var contactPersons = selectedClient.contactPersons;
    
    contactPersons.push({email:selectedClient.customerEmail,name:selectedClient.primaryContact});
   // state['contactPersons'] = contactPersons;
    //state['subjectUpdate'] = selectedTemplate.update;


    contactPersons =   _.map(contactPersons, function (member,key) {


      member =   {
          value:member.id + key,
        label: member.name +" : "+ member.email,
        email:member.email
      };

      return member;




    });
    state['contactPersons'] = contactPersons;

    this.setState(state);

    this.setState({organisations});
    console.log(this.state);

  }
  fetchEmployees(that, source) {
    axios
      .get(apiBaseUrl2 + "businus/employee/list/" + localStorage.spId)
      .then(function (response) {
        console.log(response.data);

        let allEmp = response.data;
        if (localStorage.userRole != "Admin") {
          allEmp = _.filter(allEmp, function (emp) {
            return emp.role != "Admin";
          });
        }

        let contactPersons = [];

        _.each(allEmp, function (member) {
          contactPersons.push({
            value: member.id,
            label:
              member.employeeId +
              " " +
              member.firstName +
              " " +
              member.lastName,
            name:
              member.employeeId +
              " " +
              member.firstName +
              " " +
              member.lastName,
            id: member.id,
            email: member.email,
          });
        });

        that.setState({ employees: contactPersons });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  onSelect = (selectedOption) => {
    this.setState({ sharedTo: selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  onRemove(optionList, removedItem) {
    context.setState({ sharedTo: optionList });
  }

  fetchClients(that, source) {
    axios
      .get(apiBaseUrl2 + "businus/customer/list/" + localStorage.spId)
      .then(function (response) {
        console.log(response.data);
        const assignmentData = that.props.assignmentData;

        that.setState({ clients: response.data }, () => {
          if(assignmentData){
            that.handleOnClientChange({ target: { value: assignmentData.selectedClient.id } });
            that.setState({selectedClient:assignmentData.selectedClient.id});
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  onSelectClients = (selectedOption) => {
    this.setState({ sharedToClient: selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  onRemoveClients(optionList, removedItem) {
    context.setState({ sharedToClient: optionList });
  }

  addWorkflowInList = () => {
    let columnName = this.state.columnName;

    let newWorkflow = {
      name: columnName,
    };

    var workflowColumns = this.state.workFlowList;
    workflowColumns.push(newWorkflow);
    this.setState({ workFlowList: workflowColumns });
    this.setState({ modalWorkflowIsOpen: false });
  };

  closeModal() {
    this.setState({ modalIsOpen: false });
    this.setState({ docModalIsOpen: false });
    this.setState({ modalWorkflowIsOpen: false });
    this.setState({ commentModalOpen: false });
  }

  componentDidMount() {
    this.fetchEmployees(this);
    this.fetchClients(this);
    //  this.fetchEmployees(this);
    //this.fetchQueryById(this, id);
    //this.fetchThreadById(this, id);
    //this.markAsRead(this, id);
  }

  handleOnChange = (e) => {
    debugger;
    var value = e.target.value;

    var formName = e.target.name;

    this.setState({ [formName]: value });

    console.log(this.state);
  };



  fetchQueryById(that, id) {
    axios
      .get(apiBaseUrl2 + "businus/customer/list/" + localStorage.spId)
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  sendMail() {
    let data = context.state;
    if(validate('required', data.workflowName,data.workflowOverview, data.selectedClient, data.selectedOrganisation, data.sharedTo )){

      alert
      ("Please fill all the required fields");
      return;
    }


    context.setState({ isSubmitClicked: true });

    if (context.props.mode == "component") {
      data.assignmentId = context.props.assignmentData.assignmentId;
      data.queryId = context.props.assignmentData.id;
      data.sharedTo = _.union(context.props.assignmentData.team, [
        context.props.assignmentData.teamLead,
      ]);
    }
    var selectedOrganisation= context.state.organisations[context.state.selectedOrganisation];
    var selectedClient= _.findWhere(context.state.clients,{id:context.state.selectedClient});
    let sharedTo = data.sharedTo;
    sharedTo = _.union(sharedTo, context.state.sharedToClient);

    axios
      .post(apiBaseUrl1 + "businus/workflow/add", {
        workflowId: context.state.workflowId,
        workflowName: context.state.workflowName,
        workflowOverview: context.state.workflowOverview,
        workFlowList: context.state.workFlowList,
        by: localStorage.userName,
        sharedTo: sharedTo,
        selectedClient: selectedClient,
        selectedOrganisation: selectedOrganisation,
        spId: localStorage.spId,
        assignmentId: data.assignmentId || 0,
        queryId: data.queryId || 0,
      })
      .then(function (response) {
        ToastsStore.success("Workflow Added");
        context.setState({ isSubmitClicked: false });
        context.setState({
          workflowName: "",
          workflowOverview: "",
        });
        context.setState({ subject: "" });
        context.setState({ message: "" });
        if (context.props.onSaveHandler) {
          context.props.onSaveHandler(response);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    localStorage.setItem("MAILURL", url);
  }

  render() {
    const { sliderState } = this.state;
    const { superSliderState, entry } = this.state;
    const active = " active-button";
    const deactive = "";
    return (
      <div className="Div">
        {this.props.mode != "component" ? (
          <div className="row breadcrumb-row">
            <div className="col-md-10">
              <BreadCrumbs />
            </div>
            <div className="col-md-2"></div>
          </div>
        ) : (
          ""
        )}
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
          <div className="dedicated-page" style={{ padding: "0px" }}>
            <div className="row-resource-head">
              <h2 className="resource-head">Add Workflow </h2>
            </div>
            <div className="row ">
              <div className="col-md-12 info-column account-col2">
                <div id="field">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="form-group">
                          <label className="control-label col-md-3">
                            Workflow Name
                          </label>
                          <div className="col-md-7">
                            <input
                              name="workflowName"
                              onChange={this.handleOnChange}
                              value={this.state.workflowName}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-3">
                            Workflow Overview
                          </label>
                          <div className="col-md-7">
                            <textarea
                              name="workflowOverview"
                              onChange={this.handleOnChange}
                              value={this.state.workflowOverview}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>

                        <span>
                        {this.props.mode!='component' ?    <div className="form-group">
                            <label className="control-label col-md-3">
                              Client:
                            </label>
                            <div className="col-md-7">
                              <select
                                name="selectedClient"
                                onChange={this.handleOnClientChange}
                                value={this.state.selectedClient}
                                className="form-control ember-text-field ember-view"
                                type="text"
                              >
                                <option value="">Select Client</option>
                                {this.state.clients.map(function (client, i) {
                                  return (
                                    <option value={client.id}>
                                      {client.customerId} {client.companyName}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div> : ''}

                          <div className="form-group">
                        <label className="control-label col-md-3">Organisation:</label>
                        <div className="col-md-7">
                          <select
                            name="selectedOrganisation"
                             onChange={this.handleOnChange}
                            value={this.state.selectedOrganisation}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >

                            {this.state.organisations.map(function (organisation, i) {

                              return (<option value={i}> {organisation.organisationId+" "+organisation.name}</option>)
                            })}

                          </select>
                        </div>
                      </div>
                              

                          <div className="form-group">
                            <label className="control-label col-md-3">
                              Shared To (Client Contacts)
                            </label>
                            <div className="col-md-7">
                              {this.state.contactPersons.length > 0 ? (
                                <Select
                                  value={this.state.sharedToClient}
                                  onChange={this.onSelectClients}
                                  options={this.state.contactPersons}
                                  isMulti={true}
                                />
                              ) : (
                                <input
                                  name="subject"
                                  disabled="true"
                                  value="Select Client Above"
                                  className="form-control ember-text-field ember-view"
                                  type="text"
                                />
                              )}
                            </div>
                          </div>
                          {this.props.mode!='component' ?   <div className="form-group">
                            <label className="control-label col-md-3">
                              Shared To (Team):
                            </label>
                            <div className="col-md-7">
                              {this.state.employees.length > 0 ? (
                                <Select
                                  value={this.state.sharedTo}
                                  onChange={this.onSelect}
                                  options={this.state.employees}
                                  isMulti={true}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div> : ''}
                        </span>
                      </div>
                    </div>

                    <div className="text-left">
                      <button
                        type="button"
                        onClick={this.sendMail}
                        disabled={this.state.isSubmitClicked}
                        className="button1"
                      >
                        {" "}
                        {this.state.isSubmitClicked
                          ? "Please Wait..."
                          : "Create"}
                      </button>
                    </div>
                    {/* this says that if editing state is on then the Function saveAccountDetails will be called */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
