import React,{Component} from 'react';
import axios from 'axios';
import BookingDetail from './BookingDetail';
import './profile.css';

export default class BookingFinal extends Component{
    constructor(props){
        super(props)
        this.state={
            id:localStorage.getItem('adminId'),
            email:localStorage.getItem('email'),
            date:'',
            time:'',
            personalDetail:[],
            experience:[],
            imageDetail:[],
            bookingDetail:[],
            feeDetail:this.props.feeDetail,
            connfirmBooking:false,
            changeDate:false,
            customerBookingDetail:[],
            professionalEmail:''
        }
        this.changeBookingDate = this.changeBookingDate.bind(this)
        this.handleDetail = this.handleDetail.bind(this)
        this.fetchProfileById = this.fetchProfileById.bind(this)
    }
    
    componentWillMount(){
        // let parameter = new URLSearchParams(window.location.search)
        // let date = parameter.get('day') 
        // let time = parameter.get('slot')

        // this.setState({date:date,time:time})

       this.fetchProfileById(this,this.state.id)
    }

    fetchProfileById(that,id){
        axios({
              url:'https://8cop2jp1c8.execute-api.us-east-1.amazonaws.com/dev/businus/profile/getProfileById/'+id,
              method:'GET',
              crossDomain:true,
              response:'JSON',
            }).then((response)=>{
                console.log('response',response.data)
                that.setState({
                    personalDetail:response.data.personalDetail.personalDetail,
                    experience:response.data.personalDetail.experience,
                    imageDetail:response.data.personalDetail.personalDetail.profileDetail,
                    bookingDetail:response.data.bookingDetail,
                    feeDetail:response.data.feeDetail,
                    professionalEmail:response.data.email
                  })
            })
      }

    handleDetail(e){
        let name = e.target.name
        let value = e.target.value
        this.setState({[name]:value})
    }

    changeBookingDate(connfirmBooking,customerBookingDetail,date,time){
              this.setState({
                connfirmBooking:connfirmBooking,
                customerBookingDetail:customerBookingDetail,
                date:date,
                time:time
            })
    }

    confirmBooking(){
        let cond = true
        if(this.state.date===''){
            alert("Please Select the date")
            cond = false
        }
        if(this.state.time===""){
            alert("please Select the time")
            cond = false
        }
        if(this.state.clientName===""){
            alert("Please Enter the name")
            cond = false
        }
        if(this.state.clientNumber===""){
            alert("Please Enter the number")
            cond = false
        }
        if(this.state.clientEmail===""){
            alert("Please Enter the email")
            cond = false
        }
        if(cond){
            let data = {
                customerBookingDetail:this.state.customerBookingDetail,
                customerDetail:{
                    name:this.state.clientName,
                    number:this.state.clientNumber,
                    email:this.state.clientEmail,
                },
                serviceProviderId:this.state.id,
                createdBy:this.state.email,
                professionalDetail:this.state.personalDetail,
                professionalEmail:this.state.professionalEmail
            }
            axios({
                url:'https://8cop2jp1c8.execute-api.us-east-1.amazonaws.com/dev/businus/booking/bookByCustomer',
                method:'POST',
                data:data,
              }).then((response)=>{
                 console.log(response.data)
                 if(response.status){
                     alert('Your data is saved')
                     window.location.reload()
                 }
              })
        }
        
    }

    render(){
        return(
            <div style={{background:'#f5f5f5',height:'100vh'}}>
            <div className='booking-container'>
                <div className='row'>
                    <div className='col-md-12 col-lg-12 col-sm-12'>
                        <div className='col-md-8 col-lg-8 col-sm-12' style={{background:'white',padding:'2%',marginRight:'2%',width:'72%'}}>
                            <div className="row">
                                <div className='col-md-4 col-lg-4 col-sm-4' style={{background:'#fafafa'}}>
                                    <div>
                                        <h6 className='booking-lawyer-d'> Lawyer Details <a className='float-r' href="/profile"> Change </a> </h6>
                                        <div> 
                                            <h5 className='font-b'> {this.state.personalDetail.name} </h5>
                                            <p style={{fontSize:"13px"}}> {this.state.personalDetail.experience} years experience </p> 
                                        </div>
                                    </div>
                                    {this.state.connfirmBooking?
                                    <div>
                                        <h6 className='booking-lawyer-d'> Consult Details <a className='float-r' onClick={()=>this.changeBookingDate(false,[],"","")}> Change </a> </h6>
                                        <div>
                                            <h5 className='font-b'> {this.state.date} </h5>
                                            <p style={{fontSize:'13px'}}> {this.state.time} </p>
                                        </div>
                                    </div>:""}
                                </div>
                                <div className='col-md-8 col-lg-8 col-sm-8'>
                                    <div>
                                        {this.state.connfirmBooking?
                                            <div>
                                            <h3 style={{marginTop:'0',marginLeft:"16px"}}> Add Details </h3>
                                            <div className='col-md-10 col-lg-10 col-sm-10'>
                                                <input className='form-control-booking ember-text-field ember-view' placeholder='Name' name='clientName' value={this.state.clientName} onChange={this.handleDetail}/>
                                                <input className='form-control-booking ember-text-field ember-view' placeholder='Email' name='clientEmail' value={this.state.clientEmail} onChange={this.handleDetail} />
                                                <input className='form-control-booking ember-text-field ember-view' placeholder='Mobile Number' name='clientNumber' value={this.state.clientNumber} onChange={this.handleDetail} />
                                            </div></div>:
                                            <div>
                                                <BookingDetail booking={this.state.bookingDetail} confirmBooking={this.changeBookingDate} /> 
                                            </div>
                                        }
                                        
                                    </div>
                                    {this.state.connfirmBooking?
                                        <div style={{marginLeft:'15px'}}> <button className='book-consultancy' onClick={()=>this.confirmBooking()}> Book Consultancy </button> </div>
                                        :""
                                    }
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-md-1 col-lg-1'></div> */}
                        <div className='col-md-3 col-lg-3 col-sm-12 confirm-booking-box'>
                            <div className='confirm-booking-box-h'> <h4> Confirm Your Booking </h4> </div>
                            <div>
                                <div style={{borderBottom:'1px solid #d6d5d5'}}> 
                                    <div className='row'>
                                        <div className='col-md-1 col-lg-1 col-sm-1'></div>
                                        <h5 className='col-md-6 col-lg-6 col-sm-6'> Online Consultation / Telephonic </h5>
                                        {this.state.feeDetail!==undefined?
                                            this.state.feeDetail.onlineConsultation.onlineConsultation === ''?
                                            <h5 className='col-md-4 col-lg-4 col-sm-4 '> NA </h5>: 
                                            this.state.feeDetail.onlineConsultation.freeOnlineConsultation?
                                            <h5 className='col-md-4 col-lg-4 col-sm-4 '> Free Consultation </h5>: 
                                            <h5 className='col-md-4 col-lg-4 col-sm-4 '> $ {this.state.feeDetail.onlineConsultation.onlineFee} </h5>:<h5 className='col-md-4 col-lg-4 col-sm-4 '> NA </h5>}
                                    </div>
                                    <div className='row'>
                                     <div className='col-md-1 col-lg-1 col-sm-1'></div>
                                        <h5 className='col-md-6 col-lg-6 col-sm-6'> Inperson Consultation </h5>
                                        {this.state.feeDetail!==undefined?
                                            this.state.feeDetail.InpersonConsultation.InpersonConsultation === ''?
                                            <h5 className='col-md-4 col-lg-4 col-sm-4 '> NA </h5>: 
                                            this.state.feeDetail.InpersonConsultation.freeInPersonConsultation?
                                            <h5 className='col-md-4 col-lg-4 col-sm-4 '> Free Consultation </h5>: 
                                            <h5 className='col-md-4 col-lg-4 col-sm-4 '> $ {this.state.feeDetail.InpersonConsultation.InPersonfee} </h5>:<h5 className='col-md-4 col-lg-4 col-sm-4 '> NA </h5>}
                                    </div>
                                    {/* <div className='row'>
                                            <div className='col-md-1 col-lg-1 col-sm-1'></div>
                                            <h5 className='col-md-7 col-lg-7 col-sm-7'>GST</h5>
                                            <h5 className='col-md-3 col-lg-3 col-sm-3 float-r'> $162</h5>
                                        </div>  */}
                                 </div>
                                {/* <div className='row'>
                                    <div className='col-md-1 col-lg-1 col-sm-1'></div>
                                    <h5 className='col-md-7 col-lg-7 col-sm-7'> Total </h5>
                                    <h5 className='col-md-3 col-lg-3 col-sm-3'> $1062 </h5>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}