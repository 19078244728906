import React, { Component } from "react";

import { Link } from "react-router-dom";
import ReactQuill from "react-quill"; // ES6
import $ from "jquery";
import BreadCrumbs from "../../BreadCrumbs";
import axios from "axios";
import { apiBaseUrl1, apiBaseUrl3 } from "../../apiUtils";
import { apiBaseUrl2 } from "../../apiUtils";
import FileBase64 from "react-file-base64";
import SendDocResource from "./sendDocResource";
import Modal from "react-modal";
import { ChatFeed, Message } from "react-chat-ui";
import Select from "react-select";
import {validate} from '../../common/Validation';
import SubmitButton from "../../common/SubmitButton";
import CreatableSelect from "react-select/creatable";

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import _, { templateSettings } from "underscore";
import AddDoc from "../workflowMaster/AddDoc";
let context = null;
const data = [];
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px ",
    position: "Absolute",
    zIndex: "30000",
    backgroundColor: "none",
  },
};

export default class sendMail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content:null,
      description: null,
      assignments: [],
      templates: [],
      clients: [],
      organisations:[],
      contactPersonsClients: [],
      contactPersons: [],
      tags: [],
      employees: [],
      isSubmitting: false,
      url: "",
      attachmentAssignment: "",
    };
    context = this;
  }

  changeSliderState(orderNumber) {
    this.setState({ sliderState: orderNumber });
  }

  handleOnTemplateChange = (e) => {
    var value = e.target.value;

    var selectedTemplate = this.state.templates[value];
    var formName = e.target.name;

    this.setState({ ["selectedTemplate"]: selectedTemplate.title });
    this.setState({ ["currentTemplate"]: value });
    this.setState({ name: selectedTemplate.name });
    this.setState({ url: selectedTemplate.url });
    this.setState({ name: selectedTemplate.name });
    this.setState({ description: selectedTemplate.update || "" });
    this.setState({ content: selectedTemplate.update || "" });
    this.setState({ attachment: selectedTemplate.document });
    this.setState({ attachmentName: selectedTemplate.attachmentName });
    /*  this.setState({ 'sharedTo': selectedTemplate.sharedTo });
    this.setState({ 'attachmentAssignment': selectedTemplate.hasAssignment });
    this.setState({ 'selectedAssignment': selectedTemplate.selectedAssignment }); */
  };

  //function for changing the status button on click to open or close
  handleOnChange = (e) => {
    debugger;
    var value = e.target.value;

    var formName = e.target.name;

    this.setState({ [formName]: value });

    console.log(this.state);
  };

  handleOnAttachment = (e) => {
    var value = e.target.value;

    var formName = e.target.name;

    this.setState({ [formName]: value });

    console.log(this.state);
  };

  //setting tag values

  handleChange = (newValue, actionMeta) => {
    this.setState({ tags: newValue });
  };


  getFiles(files) {
    this.setState({ selectedFile: files[0] });
  }

  fetchAssignments(that, source) {
    axios
      .get(
        apiBaseUrl1 +
          "businus/project/list/" +
          localStorage.getItem("employeeId")
      )
      .then(function (response) {
        console.log(response.data);
        that.setState({ assignments: _.sortBy(response.data, "projectId") });
        that.generateExcelData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fileUploadHandler = (that, docType) => {
    that.setState({ loading: true });
    debugger;
    axios
      .post(apiBaseUrl2 + "businusquery/uploadAttachment", {
        uploadImage: that.state.selectedFile.base64,

        imageName: that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({
            [docType]:
              "https://businus-files-live.s3.amazonaws.com/mailAttachments/" +
              that.state.selectedFile.name,
          });

          //that.successNotification("Uploaded Succesfully");
        } else {
          //   that.errorNotification("Some Error Has Occured");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  componentDidMount() {
    debugger;
    const id = this.props.match.params.id;
    let mailType = this.props.match.params.docType;

    mailType = mailType.charAt(0).toUpperCase() + mailType.slice(1);
    if (mailType == "Update") {
      this.setState({ isUpdate: true });
    } else {
      this.setState({ isUpdate: false });
    }

    this.fetchClients(this);
    // this.fetchTemplates(this);
    this.fetchTemplates(this, mailType);
    this.setState({ docType: mailType });
    //this.fetchAssignments(this);
    this.fetchEmployees(this);
    //this.fetchQueryById(this, id);
    //this.fetchThreadById(this, id);
    //this.markAsRead(this, id);
  }

  fetchClients(that, source) {
    axios
      .get(apiBaseUrl2 + "businus/customer/list/" + localStorage.spId)
      .then(function (response) {
        console.log(response.data);
        that.setState({ clients: _.sortBy(response.data, "customerId") });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fetchTemplates(that, type) {
    axios
      .get(apiBaseUrl1 + "businus/template/list/resources/" + localStorage.spId)
      .then(function (response) {
        console.log(response.data);
        console.log(type);
        var templates = response.data;

        templates = _.where(templates, { templateType: type });
        templates.unshift({ name: "custom", description: "" });
        templates.unshift({ name: "Select a template", description: "" });
        that.setState({ templates: templates });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props != nextProps) {
      const id = nextProps.match.params.id;
      let mailType = nextProps.match.params.docType;

      mailType = mailType.charAt(0).toUpperCase() + mailType.slice(1);
      if (mailType == "Update") {
        this.setState({ isUpdate: true });
      } else {
        this.setState({ isUpdate: false });
      }

      this.setState({ docType: mailType });
      // this.fetchClients(this);
      this.fetchTemplates(this, mailType);
    }
  }

  fileUploadHandler = (that, docType) => {
    that.setState({ loading: true });
    debugger;
    axios
      .post(apiBaseUrl2 + "businusquery/uploadAttachment", {
        uploadImage: that.state.selectedFile.base64,

        imageName: that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({
            [docType]:
              "https://businus-files-live.s3.amazonaws.com/mailAttachments/" +
              that.state.selectedFile.name,
          });

          //that.successNotification("Uploaded Succesfully");
        } else {
          //   that.errorNotification("Some Error Has Occured");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getFiles(files) {
    this.setState({ selectedFile: files[0] });
  }

  getFormattedDate(millis) {
    return new Date(millis).toDateString();
  }

  changeDocType(type) {
    this.setState({ docType: type });
  }

  isValidHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  sendMail() {
    var query = context.state;

  
  if(validate('required', query.selectedClient, query.selectedOrganisation, query.sharedTo )){

    alert
    ("Please fill all the required fields");
    return;
  }

    var mailType = context.state.docType;
    mailType = mailType.charAt(0).toUpperCase() + mailType.slice(1);
    context.setState({ isSubmitting: true });
    debugger;
    var selectedOrganisation= context.state.organisations[data.selectedOrganisation];
    let hasAssignment = query.attachmentAssignment;
    let sharedTo = context.state.sharedTo;
    if (!hasAssignment) {

      debugger;
      sharedTo = _.union(sharedTo, context.state.sharedToClient);
    }

    /*for checking the validation of url*/
    if (query.docType == "Url") {
      if (!context.isValidHttpUrl(query.url)) {
        alert("Please enter a valid Url (eg: http://google.com)");
        context.setState({ isSubmitting: "done" });
        return;
      } else {
      }
    }

    axios
      .post(apiBaseUrl3 + "businus/workflowDoc/add", {
        name: query.name,
        url: query.url,
        title: query.title,
        subTitle: query.subTitle,
        description: query.description,
        content: query.content,
        attachment: context.state.attachment,
        attachmentName: context.state.attachmentName,
        from: "admin",
        organisation:selectedOrganisation,
        docType: mailType,
        by: localStorage.userName,
        date: new Date().getTime(),
        sharedTo: sharedTo,
        selectedClient:query.selectedClient,
        tags:query.tags,
        hasAssignment: query.attachmentAssignment,
        selectedAssignment: query.selectedAssignment,
        spId: localStorage.spId,
      })
      .then(function (response) {
        debugger;
        alert("SENT");

        context.setState({ name: "" });
        context.setState({ description: "" });
        context.setState({ isSubmitting: "done" });
      })
      .catch(function (error) {
        console.log(error);
        context.setState({ isSubmitting: "done" });
      });
  }

  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    localStorage.setItem("MAILURL", url);
  }

  handleQuillChange = (value) => {
    {this.state.docType=="Document" ?  this.setState({ content: value }) 
    :  this.setState({ description: value }) }
  
   
    // document.getElementById('save').innerHTML = 'Add Act';
  };
  onSelect = (selectedOption) => {
    this.setState({ sharedTo: selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  onRemove(optionList, removedItem) {
    context.setState({ sharedTo: optionList });
  }

  onSelectClients = (selectedOption) => {
    debugger;
    this.setState({ sharedToClient: selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  onRemoveClients(optionList, removedItem) {
    context.setState({ sharedToClient: optionList });
  }

  handleOnClientChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;

    var selectedClient = _.findWhere(this.state.clients,{id:e.target.value});
    var organisations =selectedClient.assignedOrganisation;
    debugger;
    if(organisations!="0" && organisations!==undefined){
      organisations.unshift({name:"Select Organisation",organisationId:""})
      
      console.log(organisations)
    }
    
    
    var contactPersons = selectedClient.contactPersons;
    
    contactPersons.push({email:selectedClient.customerEmail,name:selectedClient.primaryContact});
   // state['contactPersons'] = contactPersons;
    //state['subjectUpdate'] = selectedTemplate.update;


    contactPersons =   _.map(contactPersons, function (member,key) {


      member =   {
          value:member.id + key,
        label: member.name +" : "+ member.email,
        email:member.email
      };

      return member;




    });
    state['contactPersons'] = contactPersons;

    this.setState(state);

    this.setState({organisations});
    console.log(this.state);

  }

  handleOnAssignChange = (e) => {
    debugger;
    const state = this.state;
    state[e.target.name] = e.target.value;

    var selectedAssignment = _.findWhere(this.state.assignments, {
      id: e.target.value,
    });
    let teamLead = selectedAssignment.teamLead;
    let team = selectedAssignment.team;
    let client = selectedAssignment.selectedClient;
    let contactPersons = [];
    contactPersons.push({
      label: "Team Lead :" + teamLead.name,
      value: teamLead.id,
      ...teamLead,
      type: "teamlead",
    });
    contactPersons.push({
      label: "Client :" + client.name,
      value: client.id,
      ...client,
      type: "client",
    });

    _.each(team, function (d) {
      contactPersons.push(d);
    });
    // state['contactPersons'] = contactPersons;
    //state['subjectUpdate'] = selectedTemplate.update;

    state["contactPersons"] = contactPersons;

    this.setState(state);

    console.log(this.state);
  };

  fetchEmployees(that, source) {
    axios
      .get(apiBaseUrl2 + "businus/employee/list/" + localStorage.spId)
      .then(function (response) {
        console.log(response.data);

        let allEmp = response.data;
        if (localStorage.userRole != "Admin") {
          allEmp = _.filter(allEmp, function (emp) {
            return emp.role != "Admin";
          });
        }

        let contactPersons = [];

        _.each(allEmp, function (member) {
          contactPersons.push({
            value: member.id,
            label:
              member.employeeId +
              " " +
              member.firstName +
              " " +
              member.lastName,
            name:
              member.employeeId +
              " " +
              member.firstName +
              " " +
              member.lastName,
            id: member.id,
            email: member.email,
          });
        });

        that.setState({ employees: contactPersons });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  
  //updating the status button

  render() {
    const { sliderState } = this.state;
    const { superSliderState, entry } = this.state;
    const active = " active-button";
    const deactive = "";
    return (
      <div className="Div">
        <div className="row breadcrumb1-row" style={{ marginLeft: "2px" }}>
          <div className="col-md-12">
            <BreadCrumbs />
          </div>
        </div>
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
          <div className="dedicated-page ">
            <div className="row ">
              <div className="col-md-12 info-column account-col2">
               
                  <div id="field">
                    <div className="row-resource-head">
                      <h2 className="resource-head">
                        {" "}
                        Add {this.state.docType}{" "}
                      </h2>
                    </div>
                    <div className="container content-row ">
                      <div className="row ">
                        <div className="col-md-8">
                          <div className="form-group">
                            <label className="control-label col-md-3">
                              Template
                            </label>
                            <div className="col-md-7">
                              <select
                                name="currentTemplate"
                                onChange={this.handleOnTemplateChange}
                                value={this.state.currentTemplate}
                                className="form-control ember-text-field ember-view"
                                type="text"
                              >
                                {this.state.templates.map(function (
                                  template,
                                  i
                                ) {
                                  return (
                                    <option value={i}>
                                      {template.templateId}{" "}
                                      {template.title || template.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="control-label col-md-3">
                              Name:
                            </label>
                            <div className="col-md-7">
                              <input
                                name="name"
                                onChange={this.handleOnChange}
                                value={this.state.name}
                                className="form-control ember-text-field ember-view"
                                type="text"
                              />
                            </div>
                          </div>

                          {this.state.docType == "Url" ? 
                            <div>
                            <div className="form-group">
                              <label className="control-label col-md-3">
                                Url:
                              </label>
                              <div className="col-md-7">
                                <input
                                  name="url"
                                  onChange={this.handleOnChange}
                                  value={this.state.url}
                                  className="form-control ember-text-field ember-view"
                                  type="text"
                                />
                              </div>
                              {!this.isValidHttpUrl(this.state.url) ? (
                                <small style={{ color: "red" }}>
                                  Please enter valid url (Eg:http://google.com)
                                </small>)
                                 :""}
                                </div>
<div className="form-group">
<label className="control-label col-md-3">
  Description:
</label>
<div className="col-md-7">
  <ReactQuill
    value={this.state.description}
    name="description"
    onChange={this.handleQuillChange}
    style={{ height: "200px" }}
  />
  <br />
</div>
</div>
</div>
                               
                           
                           : (
                            ""
                          )}


{this.state.docType == "Document" ? (
  <div>
                            <div className="form-group">
                              <label className="control-label col-md-3">
                                Title:
                              </label>
                              <div className="col-md-7">
                                <input
                                  name="title"
                                  onChange={this.handleOnChange}
                                  value={this.state.title}
                                  className="form-control ember-text-field ember-view"
                                  type="text"
                                />
                              </div>
                           
                            </div>
                            <div className="form-group">
                              <label className="control-label col-md-3">
                                Sub Title:
                              </label>
                              <div className="col-md-7">
                                <input
                                  name="subTitle"
                                  onChange={this.handleOnChange}
                                  value={this.state.subTitle}
                                  className="form-control ember-text-field ember-view"
                                  type="text"
                                />
                              </div>
                           
                            </div>

                            <div className="form-group">
                              <label className="control-label col-md-3">
                                Content:
                              </label>
                              <div className="col-md-7">
                              <ReactQuill
                                value={this.state.content}
                                name="content"
                                onChange={this.handleQuillChange}
                                style={{ height: "200px" }}
                              />
                              </div>
                           
                            </div>

                        </div>    
                          ) : (
                            ""
                          )}



                         
                        </div>
                      </div>
                      {this.state.docType == "Attachment" ? 
                        <div>
                        <div className="row" style={{ marginTop: "50px" }}>
                          <div className="options-row">
                            <div className="col-md-2">
                              <label className="control-label col-md-3">
                                Attachment:
                              </label>
                            </div>
                            <div className="col-md-4 upload-box">
                              {" "}
                              <FileBase64
                                multiple={false}
                                onDone={this.getFiles.bind(this)}
                              />
                            </div>

                            <div className="col-md-5">
                              <button
                                className="btn btn-primary attachment"
                                onClick={() =>
                                  this.fileUploadHandler(this, "attachment")
                                }
                                value="Select a File"
                              >
                                <i
                                  className="fa fa-paperclip"
                                  aria-hidden="true"
                                ></i>
                                Attachment
                              </button>
                            </div>
                          </div>
                        </div>

<div className="form-group">
<label className="control-label col-md-3">
  Description:
</label>
<div className="col-md-7">
  <ReactQuill
    value={this.state.description}
    name="description"
    onChange={this.handleQuillChange}
    style={{ height: "200px" }}
  />
  <br />
</div>
</div>
</div>
                       : 
                        "" }
                    </div>

{/*
                    <div
                      style={{ marginTop: "15px", marginLeft: "24px" }}
                      className="form-group"
                    >
                      <label className="control-label col-md-4">
                        <span
                          className="popovercontainer text-dashed-underline ember-view"
                          data-original-title
                          title
                        >
                          {" "}
                          Do you want to attach an Assignment* :{" "}
                        </span>{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="radio-inline">
                          <label className="cursor-poiner">
                            <input
                              name="attachmentAssignment"
                              type="radio"
                              value={"Y"}
                              onChange={this.handleOnAttachment}
                              defaultValue={"Y"}
                            />
                            Yes
                          </label>
                        </div>
                        <div className="radio-inline">
                          <label className="cursor-poiner">
                            <input
                              name="attachmentAssignment"
                              type="radio"
                              value={"N"}
                              onChange={this.handleOnAttachment}
                              defaultValue={"N"}
                            />
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                      */}

                    <span>
                      <div className="form-group">
                        <label className="control-label col-md-3">
                          Client:
                        </label>
                        <div className="col-md-7">
                          <select
                            name="selectedClient"
                            onChange={this.handleOnClientChange}
                            value={this.state.selectedClient}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >
                            <option value="">Select Client</option>
                            {this.state.clients.map(function (client, i) {
                              return (
                                <option value={client.id}>
                                  {client.customerId} {client.companyName}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="control-label col-md-3">Organisation:</label>
                        <div className="col-md-7">
                          <select
                            name="selectedOrganisation"
                             onChange={this.handleOnChange}
                            value={this.state.selectedOrganisation}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >

                            {this.state.organisations.map(function (organisation, i) {

                              return (<option value={i}> {organisation.organisationId+" "+organisation.name}</option>)
                            })}

                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="control-label col-md-3">
                          Shared To (Client Contacts)
                        </label>
                        <div className="col-md-7">
                          {this.state.contactPersons.length > 0 ? (
                            <Select
                              value={this.state.sharedToClient}
                              onChange={this.onSelectClients}
                              options={this.state.contactPersons}
                              isMulti={true}
                            />
                          ) : (
                            <input
                              name="subject"
                              disabled="true"
                              value="Select Client Above"
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="control-label col-md-3">
                          Shared To (Team):
                        </label>
                        <div className="col-md-7">
                          {this.state.employees.length > 0 ? (
                            <Select
                              value={this.state.sharedTo}
                              onChange={this.onSelect}
                              options={this.state.employees}
                              isMulti={true}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </span>

                    <div className="form-group">
                              <label className="control-label col-md-3">
                                Tags
                              </label>
                              <div className="col-md-7">
                                <CreatableSelect
                                  isMulti
                                  onChange={this.handleChange}
                                  options={this.state.tags}
                                  value={this.state.tags}
                                />
                              </div>
                            </div>


                    <div className="text-left">
                      <SubmitButton
                        clickHandler={this.sendMail}
                        isSubmitting={this.state.isSubmitting}
                      />
                    </div>
                    {/* this says that if editing state is on then the Function saveAccountDetails will be called */}
                  </div>
            
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
