import React, { Component } from "react";
import "../../../style/panel.css";
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import { Link } from "react-router-dom";

import $ from "jquery";


import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import listLetsgetstarted from './viewAssignment';




import BreadCrumbs from '../../BreadCrumbs';

import addAssignment from './addAssignment';
import viewAssignment from './viewAssignment';
import pageAssignment from './pageAssignment';
import editAssignment from './editAssignment';
import PageResourceAssignment from './pageResourceAssignment';

import addAssignmentTemplate from './addAssignmentTemplate';
import viewAssignmentTemplate from './viewAssignmentTemplate';
import editAssignmentTemplate from './editAssignmentTemplate';
import editAssignmentUpdateTemplate from './editAssignmentUpdateTemplate';
import dedicatedMailPage from '../../dedicatedmailpage';
//import home from '../../home';



import addAssignmentUpdateTemplate from './addAssignmentUpdateTemplate';
import viewAssignmentUpdateTemplate from './viewAssignmentUpdateTemplate';
import pageUpdateTemplate from './pageUpdateTemplate';
import pageAssignmentTemplate from './pageAssignmentTemplate';
import Dashboard from './Dashboard';




import SidebarAssignment from './SidebarAssignment';
import Assingmentdedicatedmailpage from "./Assingmentdedicatedmailpage";
import pageChecklistWrapper from "./pageChecklistWrapper";



const data = [];
export default class AssignmentMainapp extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []

    };

  }
  componentDidMount() {
    this.handleLine();
    //this.setState({title:this.props.match.params.productType});
  }
  handleLine() {


    const tab1 = this.refs.tab1;
    const sidebar1 = this.refs.sidebar1;
    const section = this.refs.section;


    $(function () {
      $("#tab1").click(function () {
        $("#section").toggle();
      });
    });

  }

  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
      <div className="mainDiv" >

        <div className="row">
          <div className="col-md-2">
            <SidebarAssignment title={this.state.title} />
          </div>
          <div className="col-md-10">


            <div className="section">
            {/* <Route exact path="/assignment/home" component={Home} /> */}
       

              <Route exact path="/assignment-master/assignment/view-assignment/:id" component={pageAssignment} />
              <Route exact path="/assignment-master/assignment/edit-assignment/:id" component={editAssignment} />
              <Route exact path="/assignment-master/assignment/add-assignment" component={addAssignment} />
              <Route exact path="/assignment-master/assignment/view-assignment" component={viewAssignment} />
              <Route exact path = "/assignment-master/assignment/view-assignment/:docType/:id" component = {PageResourceAssignment} />
              <Route exact path = "/assignment-master/assignment/view-assignment/checklist/page/:id" component = {pageChecklistWrapper} />
              <Route exact path="/assignment-master/assignment/view-assignmentTemplate/:id" component={pageAssignmentTemplate} />
              <Route exact path="/assignment-master/assignment/edit-assignmentTemplate/:id" component={editAssignmentTemplate} />
              <Route exact path="/assignment-master/assignment/add-assignmentTemplate" component={addAssignmentTemplate} />
              <Route exact path="/assignment-master/assignment/view-assignmentTemplate" component={viewAssignmentTemplate} />
  
              <Route exact path="/assignment-master/assignment/view-updateTemplate/:id" component={pageUpdateTemplate} />
              <Route exact path="/assignment-master/assignment/view-template/:id" component={pageAssignmentTemplate} />
              <Route exact path="/assignment-master/assignment/edit-template/:id" component={editAssignmentTemplate} />
              <Route exact path="/assignment-master/assignment/edit-updateTemplate/:id" component={editAssignmentUpdateTemplate} />
              <Route exact path="/assignment-master/assignment/add-updateTemplate" component={addAssignmentUpdateTemplate} />
              <Route exact path="/assignment-master/assignment/view-updateTemplate" component={viewAssignmentUpdateTemplate} />
              <Route exact path="/assignment-master/assignment-mail/:id" component={Assingmentdedicatedmailpage} />
 
              <Route exact path="/assignment-master/assignment/edit-assignment"><Redirect to="/assignment/view-assignment" /></Route>
              <Route exact path="/assignment-master/assignment" component={Dashboard} />
            </div>

          </div>
        </div>

      </div>

    );
  }
}
