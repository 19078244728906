import React,{Component} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import './profile.css'
import { all } from 'underscore';

export default class BookingDetail extends Component{
    constructor(props){
        super(props)
        this.state={
            bookingDetail:[],
            feeDetail:[],
            dateArray:[],
            currentBookingDetail:[],
            customerBookingDetail:[],
            bookingDate:'',
            bookedSlot:[],
            allBookingData:[
                // {date:'1 Mar Mon',bookingSlot:{morning:'7:00 AM',afternoon:'',evening:""}},
                // {date:'1 Mar Mon',bookingSlot:{morning:'7:30 AM',afternoon:'',evening:""}},
                // {date:'1 Mar Mon',bookingSlot:{morning:'9:30 AM',afternoon:'',evening:""}},
                // {date:'2 Mar Tue',bookingSlot:{morning:'7:00 AM',afternoon:'',evening:""}},
                // {date:'2 Mar Tue',bookingSlot:{morning:'10:00 AM',afternoon:'',evening:""}},
                // {date:'3 Mar Wed',bookingSlot:{morning:'8:00 AM',afternoon:'',evening:""}},
                // {date:'1 Mar Mon',bookingSlot:{morning:'',afternoon:'1:30 PM',evening:""}},
                // {date:'1 Mar Mon',bookingSlot:{morning:'',afternoon:'2:00 PM',evening:""}},
                // {date:'1 Mar Mon',bookingSlot:{morning:'',afternoon:'',evening:"4:30 PM"}},
                // {date:'1 Mar Mon',bookingSlot:{morning:'',afternoon:'',evening:"5:00 PM"}},
                // {date:'1 Mar Mon',bookingSlot:{morning:'',afternoon:'',evening:"5:30 PM"}},
                // {date:'4 Mar Thurs',bookingSlot:{morning:'7:00 AM',afternoon:'',evening:""}},
                // {date:'4 Mar Thurs',bookingSlot:{morning:'7:30 AM',afternoon:'',evening:""}},
                // {date:'4 Mar Thurs',bookingSlot:{morning:'8:00 AM',afternoon:'',evening:""}},
                // {date:'4 Mar Thurs',bookingSlot:{morning:'8:30 AM',afternoon:'',evening:""}},
                // {date:'4 Mar Thurs',bookingSlot:{morning:'9:00 AM',afternoon:'',evening:""}},
                // {date:'4 Mar Thurs',bookingSlot:{morning:'9:30 AM',afternoon:'',evening:""}},
                // {date:'4 Mar Thurs',bookingSlot:{morning:'10:00 AM',afternoon:'',evening:""}},
                // {date:'4 Mar Thurs',bookingSlot:{morning:'10:30 AM',afternoon:'',evening:""}},
                // {date:'4 Mar Thurs',bookingSlot:{morning:'',afternoon:'12:00 PM',evening:""}},
                // {date:'4 Mar Thurs',bookingSlot:{morning:'',afternoon:'12:30 PM',evening:""}},
                // {date:'4 Mar Thurs',bookingSlot:{morning:'',afternoon:'1:00 PM',evening:""}},
            ],
            id:localStorage.getItem('adminId')
        }
        this.handleBookingDate = this.handleBookingDate.bind(this)
        this.generateBookingDate = this.generateBookingDate.bind(this)
        this.handleMeetingTime = this.handleMeetingTime.bind(this)
    }

    componentWillMount(){
        this.fetchProfileById(this,this.state.id)
        this.fetchAllBookingDetail(this,this.state.id)
    }

    async fetchProfileById(that,id){
        await axios({
              url:'https://8cop2jp1c8.execute-api.us-east-1.amazonaws.com/dev/businus/profile/getProfileById/'+id,
              method:'GET',
              crossDomain:true,
              response:'JSON',
            }).then((response)=>{
                console.log('response',response.data.bookingDetail)
                that.setState({
                    bookingDetail:response.data.bookingDetail,
                    feeDetail:response.data.feeDetail
                  })
            })
            this.generateBookingDate()
      }

    fetchAllBookingDetail(that,id){
        let allBookingData = []

        axios({
            url:'https://8cop2jp1c8.execute-api.us-east-1.amazonaws.com/dev/businus/booking/listBooking',
            method:'GET',
            crossDomain:true,
            response:'JSON',
          }).then((response)=>{
              for(var i=0; i<response.data.length; i++){
                  if(response.data[i].serviceProviderId===id){
                      allBookingData.push(response.data[i].customerBookingDetail)
                  }
              }
                that.setState({allBookingData:allBookingData}) 
          })
    }

    generateBookingDate(){
        let today = new Date()
        let index = today.getDay()
        let dateArray = this.state.dateArray

        let bookingDay=''
        let months=["January","February","March","April","May","June","July","August","September","October","November","December"]
        let days = ["Sun","Mon","Tue","Wed","Thurs","Fri","Sat"]

        for(var i=1; i<8; i++){
            bookingDay=today.getDate()+" "+months[today.getMonth()].slice(0,3)+" "+days[index]
            dateArray.push({key:days[index],day:bookingDay})
            today = this.handleBookingDate(today)
            index = today.getDay()
        }
    
        this.setState({dateArray:dateArray})

        this.availableBookingDate(days)
    }   
    
    handleBookingDate(today){
        let tomorrow =  new Date(today.getTime()+86400000)
        return tomorrow
    }

    availableBookingDate(days){
    let flag = true

    for(var j=0;j<days.length;j++){
        for(var i=0;i<this.state.bookingDetail.length; i++){ 
            if(this.state.bookingDetail[i].day===days[j]){
                flag = false
            }
        }
        if(flag){
            document.getElementById(days[j]).style.textDecorationLine='line-through';
            document.getElementById(days[j]).disabled = true;
        }
        flag = true
    }
}

    dateSelected(date,key){
        for(var i=0;i<this.state.dateArray.length;i++){
            if(key===this.state.dateArray[i].key){
                document.getElementById(this.state.dateArray[i].key).classList.add('user-booking-day-active')
            }
            else{
                document.getElementById(this.state.dateArray[i].key).classList.remove('user-booking-day-active')
            }
        }
        this.setState({bookingDate:date})
        this.filterTiming(key,date)
    }

    filterTiming(key,currentDate){
        let currentBookingDetail=[]
        for(var i=0; i<this.state.bookingDetail.length;i++){
            if(key === this.state.bookingDetail[i].day){
                currentBookingDetail = this.state.bookingDetail[i]
                break 
            }
        }

        
        let allBookingData = this.state.allBookingData
        let bookedSlot = [{morning:[],afternoon:[],evening:[]}]

        if(allBookingData.length>0){

        let morning =[]
        let afternoon = []
        let evening =[]

        for(var i =0;i<allBookingData.length;i++){
            if(allBookingData[i].date===currentDate){
                if(allBookingData[i].bookingSlot.morning!==""){
                    morning.push(allBookingData[i].bookingSlot.morning)
                }
                else if(allBookingData[i].bookingSlot.afternoon!==""){
                    afternoon.push(allBookingData[i].bookingSlot.afternoon)
                }
                else if(allBookingData[i].bookingSlot.evening!==""){
                    evening.push(allBookingData[i].bookingSlot.evening)
                }
            }
        }

        
        filterAvailableTime('morning',morning,currentBookingDetail.booking.morning)
        filterAvailableTime('afternoon',afternoon,currentBookingDetail.booking.afternoon)
        filterAvailableTime('evening',evening,currentBookingDetail.booking.evening)

        function filterAvailableTime(day,dayArray,currentDayArray){
            let tempTimeArray =[]
            for(var i=0;i<dayArray.length;i++){
                for(var j=0;j<currentDayArray.length;j++){
                    if(dayArray[i]===currentDayArray[j]){
                        tempTimeArray.push(currentDayArray[j])
                    }
                }    
            }
            if(day==='morning'){
                bookedSlot[0].morning.push(tempTimeArray)
            }
            else if(day === 'afternoon'){
                bookedSlot[0].afternoon.push(tempTimeArray)
            }
            else if(day === 'evening'){
                bookedSlot[0].evening.push(tempTimeArray)
            }
        }
    }
        console.log('available',bookedSlot)

        this.setState({currentBookingDetail:currentBookingDetail})
        this.availableBookingTime(currentBookingDetail,bookedSlot)
    }

    handleMeetingTime(day,time){
        console.log('I am called')
        let customerBookingDetail = {
            date:this.state.bookingDate,
            bookingSlot:{
                morning:day==='morning'?time:'',
                afternoon:day==='afternoon'?time:'',
                evening:day==='evening'?time:'',
            }
        }
        this.setState({customerBookingDetail:customerBookingDetail})
        this.props.confirmBooking(true,customerBookingDetail,this.state.bookingDate,time)
    }

    availableBookingTime(currentBookingDetail,bookedSlot){
        // console.log('available',currentBookingDetail.booking.afternoon)
        
        let morningId=['7:00 AM','7:30 AM','8:00 AM','8:30 AM','9:00 AM','9:30 AM','10:00 AM','10:30 AM','11:00 AM','11:30 AM']
        let afternoonId=['12:00 PM','12:30 PM','1:00 PM','1:30 PM','2:00 PM','2:30 PM','3:00 PM','3:30 PM']
        let eveningId=['4:00 PM','4:30 PM','5:00 PM','5:30 PM','6:00 PM','6:30 PM','7:00 PM','7:30 PM','8:00 PM','8:30 PM']

        let morning = currentBookingDetail.booking.morning
        let afternoon = currentBookingDetail.booking.afternoon
        let evening = currentBookingDetail.booking.evening

        filterAvailableTime(morning,morningId)
        filterAvailableTime(afternoon,afternoonId)
        filterAvailableTime(evening,eveningId)

        function filterAvailableTime(dayArray,arrayID){
            if(dayArray.length>0){
            for(var j=0; j<arrayID.length;j++){
                for(var i=0; i<dayArray.length; i++){
                    if(dayArray[i]===arrayID[j]){
                        document.getElementById(arrayID[j]).style.textDecorationLine='none';
                        document.getElementById(arrayID[j]).disabled = false;
                        break
                    }
                    else{
                    document.getElementById(arrayID[j]).style.textDecorationLine='line-through';
                    document.getElementById(arrayID[j]).disabled = true;
                    }
                }
            }
        }
        else{
            for(var i=0; i<arrayID.length; i++){
                document.getElementById(arrayID[i]).style.textDecorationLine='line-through';
                document.getElementById(arrayID[i]).disabled = true
            }
        }
    }

    let morningBookedTime = bookedSlot[0].morning
    let afternoonBookedTime = bookedSlot[0].afternoon
    let eveningBookedTime = bookedSlot[0].evening

    if(morningBookedTime.length>0){
        filterBookedTime(morningBookedTime[0])
    }
    if(afternoonBookedTime.length>0){
        filterBookedTime(afternoonBookedTime[0])
    }
    if(eveningBookedTime.length>0){
        filterBookedTime(eveningBookedTime[0])
    }

    function filterBookedTime(dayArray){
        for(var i=0; i<dayArray.length; i++){
            document.getElementById(dayArray[i]).style.textDecorationLine='line-through';
            document.getElementById(dayArray[i]).disabled = true;
        }
    }

    }

    timeSelected(url){
        window.location.replace(url)
    }

    render(){
        return(
            <div>
                <h3> Availability </h3>
                <ul className='booking-outer-date'>
                {this.state.dateArray.map((date,index)=>(
                    <li className='booking-inner-date'>
                    <button id={date.key} className='user-booking-day' onClick={()=>this.dateSelected(date.day,date.key)}>{date.day}</button></li>
                ))}</ul>

                <div>
                    <h4>Morning</h4>
                    <div className='booking-outer-time'>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('morning','7:00 AM')} id='7:00 AM' className='booking-timing'>7:00 AM</button></div>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('morning','7:30 AM')} id='7:30 AM' className='booking-timing'>7:30 AM</button></div>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('morning','8:00 AM')} id='8:00 AM' className='booking-timing'>8:00 AM</button></div>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('morning','8:30 AM')} id='8:30 AM' className='booking-timing'>8:30 AM</button></div>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('morning','9:00 AM')} id='9:00 AM' className='booking-timing'>9:00 AM</button></div>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('morning','9:30 AM')} id='9:30 AM' className='booking-timing'>9:30 AM</button></div>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('morning','10:00 AM')} id='10:00 AM' className='booking-timing'>10:00 AM</button></div>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('morning','10:30 AM')} id='10:30 AM' className='booking-timing'>10:30 AM</button></div>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('morning','11:00 AM')} id='11:00 AM' className='booking-timing'>11:00 AM</button></div>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('morning','11:30 AM')} id='11:30 AM' className='booking-timing'>11:30 AM</button></div>
                    </div>
                </div>

                <div>
                    <h4> Afternoon </h4>
                    <div className='booking-outer-time'>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('afternoon','12:00 PM')} id='12:00 PM' className='booking-timing'>12:00 PM</button></div>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('afternoon','12:30 PM')} id='12:30 PM' className='booking-timing'>12:30 PM</button></div>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('afternoon','1:00 PM')} id='1:00 PM' className='booking-timing'>1:00 PM</button></div>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('afternoon','1:30 PM')} id='1:30 PM' className='booking-timing'>1:30 PM</button></div>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('afternoon','2:00 PM')} id='2:00 PM' className='booking-timing'>2:00 PM</button></div>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('afternoon','2:30 PM')} id='2:30 PM' className='booking-timing'>2:30 PM</button></div>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('afternoon','3:00 PM')} id='3:00 PM' className='booking-timing'>3:00 PM</button></div>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('afternoon','3:30 PM')} id='3:30 PM' className='booking-timing'>3:30 PM</button></div>
                    </div>
                </div>

                <div>
                    <h4> Evening </h4>
                    <div className='booking-outer-time'>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('evening','4:00 PM')} id='4:00 PM' className='booking-timing'>4:00 PM</button></div>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('evening','4:30 PM')} id='4:30 PM' className='booking-timing'>4:30 PM</button></div>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('evening','5:00 PM')} id='5:00 PM' className='booking-timing'>5:00 PM</button></div>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('evening','5:30 PM')} id='5:30 PM' className='booking-timing'>5:30 PM</button></div>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('evening','6:00 PM')} id='6:00 PM' className='booking-timing'>6:00 PM</button></div>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('evening','6:30 PM')} id='6:30 PM' className='booking-timing'>6:30 PM</button></div>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('evening','7:00 PM')} id='7:00 PM' className='booking-timing'>7:00 PM</button></div>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('evening','7:30 PM')} id='7:30 PM' className='booking-timing'>7:30 PM</button></div>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('evening','8:00 PM')} id='8:00 PM' className='booking-timing'>8:00 PM</button></div>
                        <div className='booking-inner-time'><button onClick={()=>this.handleMeetingTime('evening','8:30 PM')} id='8:30 PM' className='booking-timing'>8:30 PM</button></div>
                    </div>
                </div>

            {/* {this.state.bookingDetail.morningTimeArray!==undefined?
                <div>
                    <h4>Morning</h4>
                    <div className='row'>
                    {this.state.bookingDetail.morningTimeArray.map((time)=>(
                        <div className='col-md-2 col-lg-2 col-sm-4'>
                            <button id={time} className='user-booking-time' onClick={()=>this.timeSelected('/profile/booking/'+this.state.id+'/?day='+this.state.bookingDate+'&slot='+time )}>{time}</button>
                        </div>
                    ))}
                    </div>
                </div>:""}

                {this.state.bookingDetail.afternoonTimeArray!==undefined?
                <div>
                    <h4> Afternoon </h4>
                    <div className='row'>
                    {this.state.bookingDetail.afternoonTimeArray.map((time)=>(
                        <div className='col-md-2 col-lg-2 col-sm-4'>
                            <button id={time} className='user-booking-time' onClick={()=>this.timeSelected('/profile/booking/'+this.state.id+'/?day='+this.state.bookingDate+'&slot='+time )}> {time} </button>
                        </div>
                    ))}</div>
                </div>:""}

                {this.state.bookingDetail.eveningTimeArray!==undefined?
                <div>
                    <h4> Evening </h4>
                    <div className='row'>
                    {this.state.bookingDetail.eveningTimeArray.map((time)=>(
                        <div className='col-md-2 col-lg-2 col-sm-4'>
                            <button id={time} className='user-booking-time' onClick={()=>this.timeSelected('/profile/booking/'+this.state.id+'/?day='+this.state.bookingDate+'&slot='+time )}> {time} </button>
                        </div>
                    ))}</div>
                </div>:""} */}
            </div>
        )
    }
}