import React, { Component } from "react";
import EditorJs from "react-editor-js";
import { EditorjsTool } from "../Editor/tool";
import { v1 as uuidv1 } from "uuid";
import Image from "@editorjs/image";
import FileBase64 from "react-file-base64";
import "./profile.css";
import axios from "axios";
import PreviewProfile from "./previewProfile";

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      personalDetail: this.props.data.personalDetail,
      name: this.props.data.personalDetail.name,
      experience: this.props.data.personalDetail.experience,
      location: this.props.data.personalDetail.location,
      language: this.props.data.personalDetail.language,
      practise: this.props.data.personalDetail.practise,
      profileDetail: this.props.data.personalDetail.profileDetail,
      oldId: this.props.data.personalDetail.profileDetail.profileImageId,
      profileImageUrl:
        this.props.data.personalDetail.profileDetail.profileImageUrl,

      bio: this.props.data.bio,

      education: this.props.data.education,
      isEducation: 1,

      school10: this.props.data.education.highSchool.school,
      courseType10: this.props.data.education.highSchool.courseType,
      isFullTime10: "courseType-active",
      isPartTime10: "courseType-active",
      passingYear10: this.props.data.education.highSchool.passingYear,
      mark10: this.props.data.education.highSchool.mark,
      markType10: this.props.data.education.highSchool.markType,

      school12: this.props.data.education.interSchool.school,
      courseType12: this.props.data.education.interSchool.courseType,
      isFullTime12: "courseType-active",
      isPartTime12: "courseType-active",
      passingYear12: this.props.data.education.interSchool.passingYear,
      mark12: this.props.data.education.interSchool.mark,
      markType12: this.props.data.education.interSchool.markType,

      graduationCollege: this.props.data.education.graduation.graduationCollege,
      courseTypeGraduation:
        this.props.data.education.graduation.courseTypeGraduation,
      isFullTimeGraduation: "courseType-active",
      isPartTimeGraduation: "courseType-active",
      graduationPassingYear:
        this.props.data.education.graduation.graduationPassingYear,
      graduationMark: this.props.data.education.graduation.graduationMark,
      graduationMarkType:
        this.props.data.education.graduation.graduationMarkType,

      postCollege: this.props.data.education.postGraduation.postCollege,
      courseTypePostGraduation:
        this.props.data.education.postGraduation.courseTypePostGraduation,
      isFullTimePG: "courseType-active",
      isPartTimePG: "courseType-active",
      postPassingYear: this.props.data.education.postGraduation.postPassingYear,
      postMark: this.props.data.education.postGraduation.postMark,
      postMarkType: this.props.data.education.postGraduation.postMarkType,

      experienceArray: this.props.data.experience,
      experienceIndex: this.props.data.experience.length,
      skillArray:
        this.props.data.skill === undefined ? [] : this.props.data.skill,
      award: this.props.data.award,
      awardIndex: this.props.data.award.length,

      isEdit: true,

      productDetail: this.props.productDetail,
      productDetailArray: [],

      bookingDetail: this.props.bookingDetail,
      morningTimeArray: [],
      afternoonTimeArray: [],
      eveningTimeArray: [],
      bookingDayIndex: 1,
      bookingDayArray: [],

      feeDetail: this.props.feeDetail,
      checkFee: true,
      onlineConsultation:
        this.props.feeDetail.onlineConsultation.onlineConsultation,
      onlineFee: this.props.feeDetail.onlineConsultation.onlineFee,
      freeOnlineConsultation:
        this.props.feeDetail.onlineConsultation.freeOnlineConsultation,
      consultationOnlineFeeDisabled:
        this.props.feeDetail.onlineConsultation.freeOnlineConsultation,
      freeOnlineConsultationDisabled: true,

      InpersonConsultation:
        this.props.feeDetail.InpersonConsultation.InpersonConsultation,
      InPersonfee: this.props.feeDetail.InpersonConsultation.InPersonfee,
      freeInPersonConsultation:
        this.props.feeDetail.InpersonConsultation.freeInPersonConsultation,
      consultationInPersonFeeDisabled:
        this.props.feeDetail.InpersonConsultation.freeInPersonConsultation,
      freeInPersonConsultationDisabled: true,

      consultationChecked: false,

      validProfile: this.props.validProfile,
      profileId: this.props.profileId,
    };
    this.handleEducation = this.handleEducation.bind(this);
    this.handlePersonalDetail = this.handlePersonalDetail.bind(this);
    this.handleBio = this.handleBio.bind(this);
    this.handleExperience = this.handleExperience.bind(this);
    this.initialvalueExperience = this.initialvalueExperience.bind(this);
    this.handleCourseType = this.handleCourseType.bind(this);
    this.getProductFiles = this.getProductFiles.bind(this);
    this.handleMeetingTime = this.handleMeetingTime.bind(this);
    this.deleteBookingTime = this.deleteBookingTime.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFreeOnlineConsultantChange =
      this.handleFreeOnlineConsultantChange.bind(this);
    this.handleFreeInPersonConsultantChange =
      this.handleFreeInPersonConsultantChange.bind(this);
    this.handleTab = this.handleTab.bind(this);
    this.checkTabActive = this.checkTabActive.bind(this);
  }

  componentWillMount() {
    console.log("component Edit", this.state.postCollege);

    this.checkEducation();
    this.initialvalueEducation();
  }

  componentDidMount() {
    // this.initialvalueExperience(this.state.experienceArray)
    // this.initialvalueAward(this.state.award)
    this.initialValueProduct(this.state.productDetail);
    this.initialFeeDetail(this.state.feeDetail);
    if (this.state.bookingDetail[0] !== undefined) {
      this.initialValueBooking(this.state.bookingDetail[0].booking.morning);
      this.initialValueBooking(this.state.bookingDetail[0].booking.afternoon);
      this.initialValueBooking(this.state.bookingDetail[0].booking.evening);
    }

    this.checkTabActive(this);

    if (this.state.bookingDetail[0] !== undefined) {
      document
        .getElementById(this.state.bookingDetail[0].day)
        .classList.add("currentActiveBooking");

      let bookingDayArray = [];
      bookingDayArray.push(this.state.bookingDetail[0].day);
      for (var i = 1; i < this.state.bookingDetail.length; i++) {
        this.initialValueBookingDay(this.state.bookingDetail[i].day);
        bookingDayArray.push(this.state.bookingDetail[i].day);
      }
      this.setState({ bookingDayArray: bookingDayArray });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.tabContent !== prevState.tabContent) {
      if (this.state.tabContent === 1) {
        this.initialvalueExperience(this.state.experienceArray);
      } else if (this.state.tabContent === 3) {
        this.initialvalueAward(this.state.award);
      }
    }
  }

  initialvalueEducation() {
    if (this.state.courseType10 === "Full Time") {
      this.setState({ isPartTime10: "" });
    }
    if (this.state.courseType10 === "Part Time") {
      this.setState({ isFullTime10: "" });
    }
    if (this.state.courseType12 === "Full Time") {
      this.setState({ isPartTime12: "" });
    }
    if (this.state.courseType12 === "Part Time") {
      this.setState({ isFullTime12: "" });
    }
    if (this.state.courseTypeGraduation === "Full Time") {
      this.setState({ isPartTimeGraduation: "" });
    }
    if (this.state.courseTypeGraduation === "Part Time") {
      this.setState({ isFullTimeGraduation: "" });
    }
    if (this.state.courseTypePostGraduation === "Full Time") {
      this.setState({ isPartTimePG: "" });
    }
    if (this.state.courseTypePostGraduation === "Part Time") {
      this.setState({ isFullTimePG: "" });
    }
  }

  initialvalueExperience(exp) {
    console.log("exp 1", exp);

    // if(this.state.tabContent===1){
    console.log("exp 2", exp);
    for (var i = 0; i < exp.length; i++) {
      console.log("exp 3", exp[i]);
      document.getElementById("title_" + (i + 1)).value = exp[i].title;
      document.getElementById("employementType_" + (i + 1)).value =
        exp[i].employementType;
      document.getElementById("companyName_" + (i + 1)).value =
        exp[i].companyName;
      document.getElementById("location_" + (i + 1)).value = exp[i].location;
      document.getElementById("startDate_" + (i + 1)).value = exp[i].startDate;
      document.getElementById("endDate_" + (i + 1)).value = exp[i].endDate;
      document.getElementById("description_" + (i + 1)).value =
        exp[i].description;
    }
    // }
  }

  initialvalueAward(award) {
    for (var i = 0; i < award.length; i++) {
      document.getElementById("award_" + (i + 1)).value = award[i].award;
    }
  }

  initialValueProduct(prod) {
    for (var i = 0; i < prod.length; i++) {
      document.getElementById("serviceName_" + i).value = prod[i].serviceName;
      document.getElementById("serviceDescription_" + i).value =
        prod[i].serviceDescription;
    }
  }

  initialValueBooking(booking) {
    for (var i = 0; i < booking.length; i++) {
      document.getElementById(booking[i]).classList.add("active-timing");
    }
  }

  initialValueBookingDay(bookingDay) {
    document.getElementById(bookingDay).classList.add("active-booking");
  }

  initialFeeDetail(feeDetail) {
    let checkFee = true;
    if (feeDetail.onlineConsultation.onlineConsultation === "") {
      if (feeDetail.InpersonConsultation.InpersonConsultation === "") {
        checkFee = false;
      }
    }

    if (checkFee) {
      if (
        feeDetail.InpersonConsultation.InpersonConsultation ===
        "Inperson Consultation"
      ) {
        document.getElementById("inperson_c").classList.add("consult-active");
        document.getElementById("onlineFee").innerHTML =
          feeDetail.InpersonConsultation.InPersonfee;
        this.setState({
          consultationInPersonFeeDisabled: false,
          freeInPersonConsultationDisabled: false,
        });
      }
      if (
        feeDetail.onlineConsultation.onlineConsultation ===
        "Online Consultation / Telephonic"
      ) {
        document.getElementById("online_c").classList.add("consult-active");
        document.getElementById("InPersonfee").innerHTML =
          feeDetail.onlineConsultation.onlineFee;
        this.setState({
          consultationOnlineFeeDisabled: false,
          freeOnlineConsultationDisabled: false,
        });
      }

      if (feeDetail.freeConsultation) {
        this.setState({ consultationChecked: true });
      }
    }

    this.setState({ checkFee: checkFee });
  }

  checkEducation() {
    let temp = 1;
    if (this.state.school10 === "") {
      if (this.state.school12 === "") {
        if (this.state.graduationCollege === "") {
          if (this.state.postCollege === "") {
            temp = 0;
          }
        }
      }
    }
    this.setState({ isEducation: temp });
  }

  uploadByFile(file) {
    let fd = new FormData();
    fd.append("file", file);
  }

  addPersonalDetail() {
    const that = this.state;
    let personalDetail = {
      name: that.name,
      experience: that.experience,
      practise: that.practise,
      location: that.location,
      language: that.language,
      profileDetail: that.profileDetail,
    };
    this.setState({ personalDetail: personalDetail });
  }

  async handleBio() {
    if (this.editorInstance !== undefined) {
      const content = await this.editorInstance.save();
      this.setState({ bio: content });
    }
  }

  addEducation() {
    const that = this.state;

    let education = {
      highSchool: {
        school: that.school10,
        courseType: that.courseType10,
        passingYear: that.passingYear10,
        mark: that.mark10,
        markType: that.markType10,
      },
      interSchool: {
        school: that.school12,
        courseType: that.courseType12,
        passingYear: that.passingYear12,
        mark: that.mark12,
        markType: that.markType12,
      },
      graduation: {
        graduationCollege: that.graduationCollege,
        courseTypeGraduation: that.courseTypeGraduation,
        graduationPassingYear: that.graduationPassingYear,
        graduationMark: that.graduationMark,
        graduationMarkType: that.graduationMarkType,
      },
      postGraduation: {
        postCollege: that.postCollege,
        courseTypePostGraduation: that.courseTypePostGraduation,
        postPassingYear: that.postPassingYear,
        postMark: that.postMark,
        postMarkType: that.postMarkType,
      },
    };
    this.setState({ education: education });
  }

  addExperience(isTitle) {
    let index = this.state.experienceIndex + 1;

    var out_div = document.createElement("div");
    out_div.setAttribute("id", "experience-show-" + index);

    var in_div_T = document.createElement("div");
    var in_div_EMP = document.createElement("div");
    var in_div_C = document.createElement("div");
    var in_div_L = document.createElement("div");
    var in_div_S = document.createElement("div");
    var in_div_E = document.createElement("div");
    var in_div_D = document.createElement("div");

    var text_div_T = document.createElement("div");
    var text_div_EMP = document.createElement("div");
    var text_div_C = document.createElement("div");
    var text_div_L = document.createElement("div");
    var text_div_S = document.createElement("div");
    var text_div_E = document.createElement("div");
    var text_div_D = document.createElement("div");

    var label_T = document.createElement("label");
    var label_EMP = document.createElement("label");
    var label_C = document.createElement("label");
    var label_L = document.createElement("label");
    var label_S = document.createElement("label");
    var label_E = document.createElement("label");
    var label_D = document.createElement("label");

    var title = document.createElement("input");
    var employmentType = document.createElement("input");
    var companyName = document.createElement("input");
    var location = document.createElement("input");
    var startDate = document.createElement("input");
    var endDate = document.createElement("input");
    var description = document.createElement("input");
    var br = document.createElement("br");

    out_div.setAttribute("class", "experience-d ");
    // out_div.style.width = '80%'
    in_div_T.setAttribute("class", "form-group");
    in_div_EMP.setAttribute("class", "form-group");
    in_div_C.setAttribute("class", "form-group");
    in_div_L.setAttribute("class", "form-group");
    in_div_S.setAttribute("class", "form-group");
    in_div_E.setAttribute("class", "form-group");
    in_div_D.setAttribute("class", "form-group");

    text_div_T.setAttribute("class", "col-md-6 col-lg-6");
    text_div_EMP.setAttribute("class", "col-md-6 col-lg-6");
    text_div_C.setAttribute("class", "col-md-6 col-lg-6");
    text_div_L.setAttribute("class", "col-md-6 col-lg-6");
    text_div_S.setAttribute("class", "col-md-6 col-lg-6");
    text_div_E.setAttribute("class", "col-md-6 col-lg-6");
    text_div_D.setAttribute("class", "col-md-6 col-lg-6");

    label_T.setAttribute("class", "control-label col-md-4 col-lg-4");
    label_EMP.setAttribute("class", "control-label col-md-4 col-lg-4");
    label_C.setAttribute("class", "control-label col-md-4 col-lg-4");
    label_L.setAttribute("class", "control-label col-md-4 col-lg-4");
    label_S.setAttribute("class", "control-label col-md-4 col-lg-4");
    label_E.setAttribute("class", "control-label col-md-4 col-lg-4");
    label_D.setAttribute("class", "control-label col-md-4 col-lg-4");

    // out_div.appendChild(in_div);

    label_T.innerHTML = "Title";
    title.setAttribute("class", "form-control ember-text-field ember-view");
    title.setAttribute("type", "text");
    title.setAttribute("placeholder", "Title");
    title.setAttribute("id", "title_" + index);
    text_div_T.appendChild(title);
    in_div_T.appendChild(label_T);
    in_div_T.appendChild(text_div_T);
    out_div.appendChild(in_div_T);

    label_EMP.innerHTML = "Employement Type";
    employmentType.setAttribute(
      "class",
      "form-control ember-text-field ember-view"
    );
    employmentType.setAttribute("type", "text");
    employmentType.setAttribute("placeholder", "Employement Type");
    employmentType.setAttribute("id", "employementType_" + index);
    text_div_EMP.appendChild(employmentType);
    in_div_EMP.appendChild(label_EMP);
    in_div_EMP.appendChild(text_div_EMP);
    out_div.appendChild(in_div_EMP);

    label_C.innerHTML = "Company Name";
    companyName.setAttribute(
      "class",
      "form-control ember-text-field ember-view"
    );
    companyName.setAttribute("type", "text");
    companyName.setAttribute("placeholder", "Company Name");
    companyName.setAttribute("id", "companyName_" + index);
    text_div_C.appendChild(companyName);
    in_div_C.appendChild(label_C);
    in_div_C.appendChild(text_div_C);
    out_div.appendChild(in_div_C);

    label_L.innerHTML = "Location";
    location.setAttribute("class", "form-control ember-text-field ember-view");
    location.setAttribute("type", "text");
    location.setAttribute("placeholder", "Location");
    location.setAttribute("id", "location_" + index);
    text_div_L.appendChild(location);
    in_div_L.appendChild(label_L);
    in_div_L.appendChild(text_div_L);
    out_div.appendChild(in_div_L);

    label_S.innerHTML = "Start Date";
    // startDate.setAttribute("class","form-control ember-text-field ember-view");
    startDate.setAttribute("type", "date");
    startDate.setAttribute("placeholder", "Start Date");
    startDate.setAttribute("id", "startDate_" + index);
    text_div_S.appendChild(startDate);
    in_div_S.appendChild(label_S);
    in_div_S.appendChild(text_div_S);
    out_div.appendChild(in_div_S);

    label_E.innerHTML = "End Date";
    // endDate.setAttribute("class","form-control ember-text-field ember-view");
    endDate.setAttribute("type", "date");
    endDate.setAttribute("placeholder", "End Date");
    endDate.setAttribute("id", "endDate_" + index);
    text_div_E.appendChild(endDate);
    in_div_E.appendChild(label_E);
    in_div_E.appendChild(text_div_E);
    out_div.appendChild(in_div_E);

    label_D.innerHTML = "Description";
    description.setAttribute(
      "class",
      "form-control ember-text-field ember-view"
    );
    description.setAttribute("type", "text");
    description.setAttribute("placeholder", "Description");
    description.setAttribute("id", "description_" + index);
    text_div_D.appendChild(description);
    in_div_D.appendChild(label_D);
    in_div_D.appendChild(text_div_D);
    out_div.appendChild(in_div_D);

    if (isTitle === 3) {
      document.getElementById("experience").appendChild(out_div);
      document.getElementById("experience").appendChild(br);
    }

    this.setState({ experienceIndex: index, addExperience: true });
  }

  addExperienceArray() {
    let experienceArray = [];

    // if(this.state.addExperience){
    for (var i = 1; i < this.state.experienceArray.length + 1; i++) {
      experienceArray.unshift({
        title: document.getElementById("title_" + i).value,
        employementType: document.getElementById("employementType_" + i).value,
        companyName: document.getElementById("companyName_" + i).value,
        location: document.getElementById("location_" + i).value,
        startDate: document.getElementById("startDate_" + i).value,
        endDate: document.getElementById("endDate_" + i).value,
        description: document.getElementById("description_" + i).value,
      });
    }
    this.setState({ experienceArray: experienceArray });
    //    this.initialvalueExperience(experienceArray)
    // }
  }

  addAward() {
    let index = this.state.awardIndex + 1;

    var out_div = document.createElement("div");
    var in_div = document.createElement("div");
    var text_div = document.createElement("div");
    var label = document.createElement("label");
    var award = document.createElement("input");
    var br = document.createElement("br");

    out_div.setAttribute("id", "award-show-" + index);
    label.innerHTML = "Award " + index;
    award.setAttribute("class", "form-control ember-text-field ember-view");
    award.setAttribute("type", "text");
    award.setAttribute("placeholder", "Award " + index);
    award.setAttribute("id", "award_" + index);
    text_div.appendChild(award);
    in_div.appendChild(label);
    in_div.appendChild(text_div);
    out_div.appendChild(in_div);

    document.getElementById("award").appendChild(out_div);
    document.getElementById("award").appendChild(br);

    this.setState({ awardIndex: index, addAward: true });
  }

  addAwardArray() {
    let award = [];
    // if(this.state.addAward){
    for (var i = 1; i < this.state.award.length + 1; i++) {
      award.unshift({
        award: document.getElementById("award_" + i).value,
      });
    }
    this.setState({ award: award });
    // }
  }

  addProductDetail() {
    let productDetailArray = this.state.productDetailArray;
    let productDetail = this.state.productDetail;
    for (var i = 0; i < productDetail.length; i++) {
      productDetailArray.unshift({
        serviceImageUrl: document.getElementById("productImage_" + i).src,
        serviceName: document.getElementById("serviceName_" + i).value,
        serviceDescription: document.getElementById("serviceDescription_" + i)
          .value,
      });
    }
    this.setState({ productDetailArray: productDetailArray });
  }

  async submitProfile() {
    function wait() {
      // this.addPersonalDetail()
      // this.handleBio()
      // this.addExperienceArray()
      // this.addEducation()
      // this.addAwardArray()
    }
    await this.addPersonalDetail();
    await this.handleBio();
    if (this.state.tabContent === 1) {
      await this.addExperienceArray();
    }
    await this.addEducation();
    if (this.state.tabContent === 3) {
      await this.addAwardArray();
    }
    await this.addProductDetail();
    await this.addFeeAndBookDay(this);

    let data = {
      personalDetail: this.state.personalDetail,
      bio: this.state.bio,
      education: this.state.education,
      experience: this.state.experienceArray,
      skill: this.state.skillArray,
      award: this.state.award,
    };

    this.setState({ data: data, isEdit: false });
  }

  handleEducation(e) {
    let target = e.target;
    let name = target.name;

    this.setState({ [name]: target.value });
  }

  addEducation() {
    const that = this.state;
    let education = {
      highSchool: {
        school: that.school10,
        courseType: that.coureType10,
        passingYear: that.passingYear10,
        mark: that.mark10,
        markType: that.markType10,
      },
      interSchool: {
        school: that.school12,
        courseType: that.coureType12,
        passingYear: that.passingYear12,
        mark: that.mark12,
        markType: that.markType12,
      },
      graduation: {
        graduationCollege: that.graduationCollege,
        courseTypeGraduation: that.coureTypeGraduation,
        graduationPassingYear: that.graduationPassingYear,
        graduationMark: that.graduationMark,
        graduationMarkType: that.graduationMarkType,
      },
      postGraduation: {
        postCollege: that.postCollege,
        courseTypePostGraduation: that.coureTypePostGraduation,
        postPassingYear: that.postPassingYear,
        postMark: that.postMark,
        postMarkType: that.postMarkType,
      },
    };
    this.setState({ education: education });
  }

  handleCourseType(id, index) {
    document.getElementById(id).classList.add("courseType-active");

    switch (index) {
      case 0:
        this.setState({ coureType10: "Full Time" });
        document
          .getElementById("partTime10")
          .classList.remove("courseType-active");
        break;
      case 1:
        this.setState({ coureType10: "Part Time" });
        document
          .getElementById("fullTime10")
          .classList.remove("courseType-active");
        break;
      case 2:
        this.setState({ coureType12: "Full Time" });
        document
          .getElementById("partTime12")
          .classList.remove("courseType-active");
        break;
      case 3:
        this.setState({ coureType12: "Part Time" });
        document
          .getElementById("fullTime12")
          .classList.remove("courseType-active");
        break;
      case 4:
        this.setState({ coureTypeGraduation: "Full Time" });
        document
          .getElementById("partTimeGraduation")
          .classList.remove("courseType-active");
        break;
      case 5:
        this.setState({ coureTypeGraduation: "Part Time" });
        document
          .getElementById("fullTimeGraduation")
          .classList.remove("courseType-active");
        break;
      case 6:
        this.setState({ coureTypePostGraduation: "Full Time" });
        document
          .getElementById("partTimePostGraduation")
          .classList.remove("courseType-active");
        break;
      case 7:
        this.setState({ coureTypePostGraduation: "Part Time" });
        document
          .getElementById("fullTimePostGraduation")
          .classList.remove("courseType-active");
        break;
    }
  }

  handlePersonalDetail(e) {
    let target = e.target;
    let name = target.name;

    this.setState({ [name]: target.value });
  }

  // handlePersonalDetail(e){
  //     let target = e.target
  //     let name = target.name

  //     document.getElementById([name]).value=target.value
  // }

  handleExperience(e) {
    let target = e.target;
    let name = target.name;
    document.getElementById([name]).value = target.value;
    // this.setState({[name]:target.value})
  }

  setSkill(e) {
    this.setState({ skill: e });
  }

  handleDelete(key) {
    let array = this.state.skillArray;
    for (var i = 0; i < array.length; i++) {
      if (array[i].key === key) {
        array.splice(i, 1);
      }
    }
    this.setState({ skillArray: array });
  }

  addSkill(skill) {
    let array = this.state.skillArray;
    array.unshift({
      key: array.length + 1,
      skill: skill,
    });
    this.setState({ skillArray: array, skill: "" });
  }

  getProductFiles(i) {
    var image = document.getElementById("productFile_" + i).files[0];
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = function () {
      var fileBase = reader.result;
      this.fileUploadHandler(
        this,
        fileBase,
        image.name,
        "productImageId",
        "productImage_" + i
      );
    }.bind(this);
  }

  getFiles(files) {
    this.setState({ selectedFile: files[0] });
    this.fileUploadHandler(
      this,
      files[0].base64,
      files[0].name,
      "profileImageId",
      "profileImageUrl"
    );
  }

  async fileUploadHandler(that, selectedFile, name, imageId, imageUrl) {
    const id = uuidv1();

    that.setState({ loading: true });

    await axios
      .post(
        "https://8cop2jp1c8.execute-api.us-east-1.amazonaws.com/dev/businus/upload/profileImage",
        {
          image: selectedFile,
          id: id,
        }
      )
      .then(function (response) {
        if (response.status == 200) {
          if (imageId === "profileImageId") {
            let profileDetail = {
              profileImageId: id,
              profileImageName: name,
              profileImageUrl:
                "https://businus-files-live.s3.amazonaws.com/profile/" + id + ".png",
            };
            that.setState({
              successupload: true,
              profileDetail: profileDetail,
              [imageUrl]:
                "https://businus-files-live.s3.amazonaws.com/profile/" + id + ".png",
            });
          }
          if (imageId === "productImageId") {
            document.getElementById(imageUrl).src =
              "https://businus-files-live.s3.amazonaws.com/profile/" + id + ".png";
          }
        } else {
          //   that.errorNotification("Some Error Has Occured");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  resetMeetingTime() {
    let morningId = [
      "7:00 AM",
      "7:30 AM",
      "8:00 AM",
      "8:30 AM",
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
    ];
    let afternoonId = [
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
    ];
    let eveningId = [
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
      "5:30 PM",
      "6:00 PM",
      "6:30 PM",
      "7:00 PM",
      "7:30 PM",
      "8:00 PM",
      "8:30 PM",
    ];

    for (var i = 0; i < morningId.length; i++) {
      document.getElementById(morningId[i]).classList.remove("active-timing");
    }

    for (var i = 0; i < afternoonId.length; i++) {
      document.getElementById(afternoonId[i]).classList.remove("active-timing");
    }

    for (var i = 0; i < eveningId.length; i++) {
      document.getElementById(eveningId[i]).classList.remove("active-timing");
    }
  }

  handleChange(e) {
    let target = e.target;
    let name = target.name;
    this.setState({ [name]: target.value });
  }

  handleFreeOnlineConsultantChange(e) {
    if (this.state.onlineFee !== "") {
      this.setState({ onlineFee: "" });
    }
    this.setState({
      freeOnlineConsultation: !this.state.freeOnlineConsultation,
      consultationOnlineFeeDisabled: !this.state.consultationOnlineFeeDisabled,
    });
  }

  handleFreeInPersonConsultantChange(e) {
    if (this.state.InPersonfee !== "") {
      this.setState({ InPersonfee: "" });
    }
    this.setState({
      freeInPersonConsultation: !this.state.freeInPersonConsultation,
      consultationInPersonFeeDisabled:
        !this.state.consultationInPersonFeeDisabled,
    });
  }

  addBookingDay(bookingDayValue) {
    let bookingDetail = this.state.bookingDetail;
    let bookingIndex = this.state.bookingDayIndex;
    let dayInArray = true;

    let bookingJSON = {
      morning: [],
      afternoon: [],
      evening: [],
    };

    if (bookingDetail.length > 0) {
      for (var i = 0; i < bookingDetail.length; i++) {
        if (bookingDayValue === bookingDetail[i].day) {
          dayInArray = false;
          bookingIndex = i + 1;
          break;
        }
      }
    }

    if (dayInArray) {
      this.resetMeetingTime(bookingIndex);
      bookingIndex = this.state.bookingDetail.length + 1;
      bookingDetail.splice(bookingIndex, 0, {
        day: bookingDayValue,
        booking: bookingJSON,
      });

      // switch(bookingIndex){
      //     case 1:
      //       bookingDetail.splice(0,0,{day:"Mon",booking:bookingJSON})
      //       break
      //     case 2:
      //       bookingDetail.splice(1,0,{day:"Tue",booking:bookingJSON})
      //       break
      //     case 3:
      //       bookingDetail.splice(2,0,{day:"Wed",booking:bookingJSON})
      //       break
      //     case 4:
      //       bookingDetail.splice(3,0,{day:"Thurs",booking:bookingJSON})
      //       break
      //     case 5:
      //       bookingDetail.splice(4,0,{day:"Fri",booking:bookingJSON})
      //       break
      //     case 6:
      //       bookingDetail.splice(5,0,{day:"Sat",booking:bookingJSON})
      //       break
      //     case 7:
      //       bookingDetail.splice(6,0,{day:"Sun",booking:bookingJSON})
      //       break
      //   }
    } else {
      this.navigateBookingDays(bookingDetail, bookingIndex);
    }

    for (var i = 0; i < bookingDetail.length; i++) {
      if (bookingDayValue === bookingDetail[i].day) {
        document
          .getElementById(bookingDetail[i].day)
          .classList.remove("active-booking");
        document
          .getElementById(bookingDetail[i].day)
          .classList.add("currentActiveBooking");
      } else {
        document
          .getElementById(bookingDetail[i].day)
          .classList.remove("currentActiveBooking");
        document
          .getElementById(bookingDetail[i].day)
          .classList.add("active-booking");
      }
    }

    this.setState({
      bookingDayIndex: bookingIndex,
      bookingDetail: bookingDetail,
    });
  }

  deleteBookingTime(timeArray, time) {
    for (var i = 0; i < timeArray.length; i++) {
      if (timeArray[i] === time) {
        timeArray.splice(i, 1);
        return timeArray;
      }
    }
  }

  setMeetingTime(arrayName, time) {
    let elem = document.getElementById(time);
    if (elem.classList.contains("active-timing")) {
      elem.classList.remove("active-timing");
      return (arrayName = this.deleteBookingTime(arrayName, time));
    } else {
      elem.classList.add("active-timing");
      return arrayName.push(time);
    }
  }

  handleMeetingTime(event, time) {
    if (event === "morning") {
      let elem = document.getElementById(time);
      if (elem.classList.contains("active-timing")) {
        elem.classList.remove("active-timing");
        this.deleteBookingTime(this, time, "morning");
      } else {
        elem.classList.add("active-timing");
        this.addBookingDetails(this, time, "morning");
      }
    } else if (event === "afternoon") {
      let elem = document.getElementById(time);
      if (elem.classList.contains("active-timing")) {
        elem.classList.remove("active-timing");
        this.deleteBookingTime(this, time, "afternoon");
      } else {
        elem.classList.add("active-timing");
        this.addBookingDetails(this, time, "afternoon");
      }
    } else {
      let elem = document.getElementById(time);
      if (elem.classList.contains("active-timing")) {
        elem.classList.remove("active-timing");
        this.deleteBookingTime(this, time, "evening");
      } else {
        elem.classList.add("active-timing");
        this.addBookingDetails(this, time, "evening");
      }
    }
  }

  addBookingDetails(that, time, timeSlot) {
    let booking = that.state.bookingDayIndex;
    let bookingDetail = that.state.bookingDetail;

    switch (booking) {
      case 1:
        if (timeSlot === "morning") {
          bookingDetail[0].booking.morning.push(time);
        } else if (timeSlot === "afternoon") {
          bookingDetail[0].booking.afternoon.push(time);
        } else {
          bookingDetail[0].booking.evening.push(time);
        }
        break;

      case 2:
        if (timeSlot === "morning") {
          bookingDetail[1].booking.morning.push(time);
        } else if (timeSlot === "afternoon") {
          bookingDetail[1].booking.afternoon.push(time);
        } else {
          bookingDetail[1].booking.evening.push(time);
        }
        break;

      case 3:
        if (timeSlot === "morning") {
          bookingDetail[2].booking.morning.push(time);
        } else if (timeSlot === "afternoon") {
          bookingDetail[2].booking.afternoon.push(time);
        } else {
          bookingDetail[2].booking.evening.push(time);
        }
        break;

      case 4:
        if (timeSlot === "morning") {
          bookingDetail[3].booking.morning.push(time);
        } else if (timeSlot === "afternoon") {
          bookingDetail[3].booking.afternoon.push(time);
        } else {
          bookingDetail[3].booking.evening.push(time);
        }
        break;

      case 5:
        if (timeSlot === "morning") {
          bookingDetail[4].booking.morning.push(time);
        } else if (timeSlot === "afternoon") {
          bookingDetail[4].booking.afternoon.push(time);
        } else {
          bookingDetail[4].booking.evening.push(time);
        }
        break;

      case 6:
        if (timeSlot === "morning") {
          bookingDetail[5].booking.morning.push(time);
        } else if (timeSlot === "afternoon") {
          bookingDetail[5].booking.afternoon.push(time);
        } else {
          bookingDetail[5].booking.evening.push(time);
        }
        break;

      case 7:
        if (timeSlot === "morning") {
          bookingDetail[6].booking.morning.push(time);
        } else if (timeSlot === "afternoon") {
          bookingDetail[6].booking.afternoon.push(time);
        } else {
          bookingDetail[6].booking.evening.push(time);
        }
        break;
    }
    console.log("booking Detial", this.state.bookingDetail);
    this.setState({ bookingDetail: bookingDetail });
  }

  deleteBookingTime(that, time, timeSlot) {
    let bookingDetail = that.state.bookingDetail;
    let booking = that.state.bookingDayIndex;

    switch (booking) {
      case 1:
        if (timeSlot === "morning") {
          let tempBooking = bookingDetail[0].booking.morning;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else if (timeSlot === "afternoon") {
          let tempBooking = bookingDetail[0].booking.afternoon;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else {
          let tempBooking = bookingDetail[0].booking.evening;
          this.iterateDeleteBookingTIme(tempBooking, time);
        }
        break;

      case 2:
        if (timeSlot === "morning") {
          let tempBooking = bookingDetail[1].booking.morning;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else if (timeSlot === "afternoon") {
          let tempBooking = bookingDetail[1].booking.afternoon;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else {
          let tempBooking = bookingDetail[1].booking.evening;
          this.iterateDeleteBookingTIme(tempBooking, time);
        }
        break;

      case 3:
        if (timeSlot === "morning") {
          let tempBooking = bookingDetail[2].booking.morning;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else if (timeSlot === "afternoon") {
          let tempBooking = bookingDetail[2].booking.afternoon;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else {
          let tempBooking = bookingDetail[2].booking.evening;
          this.iterateDeleteBookingTIme(tempBooking, time);
        }
        break;

      case 4:
        if (timeSlot === "morning") {
          let tempBooking = bookingDetail[3].booking.morning;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else if (timeSlot === "afternoon") {
          let tempBooking = bookingDetail[3].booking.afternoon;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else {
          let tempBooking = bookingDetail[3].booking.evening;
          this.iterateDeleteBookingTIme(tempBooking, time);
        }
        break;

      case 5:
        if (timeSlot === "morning") {
          let tempBooking = bookingDetail[4].booking.morning;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else if (timeSlot === "afternoon") {
          let tempBooking = bookingDetail[4].booking.afternoon;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else {
          let tempBooking = bookingDetail[4].booking.evening;
          this.iterateDeleteBookingTIme(tempBooking, time);
        }
        break;

      case 6:
        if (timeSlot === "morning") {
          let tempBooking = bookingDetail[5].booking.morning;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else if (timeSlot === "afternoon") {
          let tempBooking = bookingDetail[5].booking.afternoon;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else {
          let tempBooking = bookingDetail[5].booking.evening;
          this.iterateDeleteBookingTIme(tempBooking, time);
        }
        break;

      case 7:
        if (timeSlot === "morning") {
          let tempBooking = bookingDetail[6].booking.morning;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else if (timeSlot === "afternoon") {
          let tempBooking = bookingDetail[6].booking.afternoon;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else {
          let tempBooking = bookingDetail[6].booking.evening;
          this.iterateDeleteBookingTIme(tempBooking, time);
        }
        break;
    }
  }

  iterateDeleteBookingTIme(bookingSlotDetail, time) {
    for (var i = 0; i < bookingSlotDetail.length; i++) {
      if (bookingSlotDetail[i] === time) {
        bookingSlotDetail.splice(i, 1);
      }
    }
  }

  navigateBookingDays(bookingDetail, bookingDayIndex) {
    this.resetMeetingTime();

    let currentBookingDetail = [];
    switch (bookingDayIndex) {
      case 1:
        currentBookingDetail = bookingDetail[0].booking;
        break;
      case 2:
        currentBookingDetail = bookingDetail[1].booking;
        break;
      case 3:
        currentBookingDetail = bookingDetail[2].booking;
        break;
      case 4:
        currentBookingDetail = bookingDetail[3].booking;
        break;
      case 5:
        currentBookingDetail = bookingDetail[4].booking;
        break;
      case 6:
        currentBookingDetail = bookingDetail[5].booking;
        break;
      case 7:
        currentBookingDetail = bookingDetail[6].booking;
        break;
    }

    let currentMorningTiming = currentBookingDetail.morning;
    let currentAfternoonTiming = currentBookingDetail.afternoon;
    let currentEveningTiming = currentBookingDetail.evening;

    activeCurrentTiming(currentMorningTiming);
    activeCurrentTiming(currentAfternoonTiming);
    activeCurrentTiming(currentEveningTiming);

    function activeCurrentTiming(timingArray) {
      for (var i = 0; i < timingArray.length; i++) {
        document.getElementById(timingArray[i]).classList.add("active-timing");
      }
    }
  }

  setConsultationType(index) {
    if (index === 0) {
      if (
        document.getElementById("online_c").classList.contains("consult-active")
      ) {
        document.getElementById("online_c").classList.remove("consult-active");
        this.setState({
          onlineConsultation: "",
          consultationOnlineFeeDisabled: true,
          freeOnlineConsultationDisabled: true,
        });
      } else {
        document.getElementById("online_c").classList.add("consult-active");
        this.setState({
          onlineConsultation: "Online Consultation / Telephonic",
          freeOnlineConsultationDisabled: false,
          consultationOnlineFeeDisabled: false,
        });
      }

      this.setState({ consultationType: "Online Consultation / Telephonic" });
    } else {
      if (
        document
          .getElementById("inperson_c")
          .classList.contains("consult-active")
      ) {
        document
          .getElementById("inperson_c")
          .classList.remove("consult-active");
        this.setState({
          InpersonConsultation: "",
          consultationInPersonFeeDisabled: true,
          freeInPersonConsultationDisabled: true,
        });
      } else {
        document.getElementById("inperson_c").classList.add("consult-active");
        this.setState({
          InpersonConsultation: "Inperson Consultation",
          consultationInPersonFeeDisabled: false,
          freeInPersonConsultationDisabled: true,
        });
      }
    }
  }

  addFeeAndBookDay(that) {
    let feeDetail = {
      onlineConsultation: {
        onlineConsultation: that.state.onlineConsultation,
        onlineFee: that.state.onlineFee,
        freeOnlineConsultation: that.state.freeOnlineConsultation,
      },
      InpersonConsultation: {
        InpersonConsultation: that.state.InpersonConsultation,
        InPersonfee: that.state.InPersonfee,
        freeInPersonConsultation: that.state.freeInPersonConsultation,
      },
    };
    that.setState({ feeDetail: feeDetail });
  }

  checkTabActive(that) {
    let isEducation = that.state.isEducation;
    let index = 3;
    if (isEducation) {
      index = 0;
    } else {
      if (that.state.experience.length !== 0) {
        index = 1;
      } else {
        if (that.state.skill.length !== 0) {
          index = 2;
        }
      }
    }
    this.handleTab(index);
  }

  handleTab(index) {
    let elem1 = document.getElementById(this.state.tabContent);
    if (elem1 !== null) {
      elem1.classList.remove("tab-d-active");
    }

    let elem2 = document.getElementById(index);
    if (elem2 !== null) {
      elem2.classList.add("tab-d-active");
    }

    if (this.state.tabContent === 1) {
      this.addExperienceArray();
    } else if (this.state.tabContent === 3) {
      this.addAwardArray();
    }

    this.setState({ tabContent: index });
  }

  render() {
    const { tabContent } = this.state;
    return (
      <div>
        {this.state.isEdit ? (
          <div className="user-profile-container">
            <div
              className="user-background-image"
              style={{
                background:
                  this.state.profileDetail.coverImageId !== undefined
                    ? "url(" + this.state.profileDetail.coverImageUrl + ")"
                    : "#2978d057",
              }}
            ></div>
            <div className="user-detail"></div>
            {this.state.name !== undefined ? (
              <div className="row spacing-user-detail">
                <div className="col-md-3 col-lg-3 col-sm-12">
                  {this.state.profileDetail.profileImageId === undefined ? (
                    <img
                      className="user-profile-image"
                      src={
                        "https://businus-files-live.s3.amazonaws.com/default-image/user-profile.jpg"
                      }
                    />
                  ) : (
                    <img
                      className="user-profile-image"
                      src={this.state.profileImageUrl}
                    />
                  )}
                  <FileBase64
                    multiple={true}
                    onDone={this.getFiles.bind(this)}
                  />

                  {/* <img className='user-profile-image' src={this.state.profileImageUrl}/> */}
                </div>
                <div className="user-header-name ml-1">
                  <input
                    type="text"
                    className="form-control ember-text-field ember-view"
                    onChange={this.handlePersonalDetail}
                    name="name"
                    placeholder="Please Enter your name"
                    value={this.state.name}
                  />
                  {/* {this.state.bookingDetail!==undefined? <button type='submit' onClick={()=>this.bookingPreview(true)} className='userConsult'>  Consult </button>:""} */}
                </div>
                <div className="col-md-5 col-lg-5 col-sm-6">
                  <tr>
                    <td className="user-inf-t"> Experience : </td>
                    <td className="user-inf-d">
                      <input
                        type="text"
                        className="form-control ember-text-field ember-view"
                        onChange={this.handlePersonalDetail}
                        name="experience"
                        placeholder="Please Enter your experience"
                        value={this.state.experience}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="user-inf-t"> Practise & Area : </td>
                    <td className="user-inf-d">
                      <input
                        type="text"
                        className="form-control ember-text-field ember-view"
                        onChange={this.handlePersonalDetail}
                        name="practise"
                        placeholder="Enter practise & Area"
                        value={this.state.practise}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="user-inf-t"> Location : </td>
                    <td className="user-inf-d ">
                      <input
                        type="text"
                        className="form-control ember-text-field ember-view"
                        onChange={this.handlePersonalDetail}
                        name="location"
                        placeholder="Enter location"
                        value={this.state.location}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="user-inf-t"> Languages : </td>
                    <td className="user-inf-d ">
                      <input
                        type="text"
                        className="form-control ember-text-field ember-view"
                        onChange={this.handlePersonalDetail}
                        name="language"
                        placeholder="Enter Language"
                        value={this.state.language}
                      />
                    </td>
                  </tr>
                </div>
                {/* <p className='col-md-4 col-lg-4 col-sm-6 user-inf-a'>
                     {this.state.bio.blocks!==undefined?
                     <div>
                        
                         <p> {this.state.breifBio} <span><a href="#bio">Read more</a></span> </p></div>
                     :""}
                    </p> */}
              </div>
            ) : (
              ""
            )}

            <div style={{ margin: "0 2%" }}>
              <div className="tab-content">
                <div className="row">
                  <ul className="profile-tab-c col-md-3">
                    {this.state.checkAbout ? (
                      <li id="tab_0" className="profile-tab active">
                        <a href="#bio"> About </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {this.state.productDetail !== undefined ? (
                      <li id="tab_2" className="profile-tab ">
                        <a href="#product"> Services </a>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>

                {this.state.bio.blocks !== undefined ? (
                  <div>
                    <div className="tab-heading-m " id="bio">
                      <div className="tab-heading px-4 w-12 py-1">About</div>
                    </div>

                    <div className="row about">
                      {this.state.bio.blocks !== undefined ? (
                        <EditorJs
                          data={this.state.bio}
                          instanceRef={(instance) =>
                            (this.editorInstance = instance)
                          }
                          tools={{
                            image: {
                              class: Image,
                              inlineToolbar: true,
                              config: {
                                uploader: {
                                  uploadByFile: async function (file) {
                                    console.log(file);
                                    const id = uuidv1();
                                    const imageURL =
                                      "https://businus-files-live.s3.amazonaws.com/image/" +
                                      id +
                                      ".png";

                                    let fileBase = await toBase64(file);
                                    let result = await axios.post(
                                      "https://tj1cb28koh.execute-api.us-east-1.amazonaws.com/dev/businus/uploadEditorImage",
                                      {
                                        image: fileBase,
                                        imageName: fileBase.name,
                                        id: id,
                                        imageURL: imageURL,
                                      }
                                    );

                                    return {
                                      success: 1,
                                      file: {
                                        url: imageURL,
                                      },
                                    };
                                  },
                                },
                              },
                            },
                            ...EditorjsTool,
                          }}
                        ></EditorJs>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="maintab">
                <div className="tab-ul">
                  {this.state.isEducation ? (
                    <button
                      id={0}
                      onClick={() => this.handleTab(0)}
                      className="tab-dropdown"
                    >
                      Education
                    </button>
                  ) : (
                    ""
                  )}

                  {this.state.experienceArray !== undefined ? (
                    <button
                      id={1}
                      onClick={() => this.handleTab(1)}
                      className="tab-dropdown"
                    >
                      Experience
                    </button>
                  ) : (
                    ""
                  )}

                  {this.state.skillArray !== undefined ? (
                    <button
                      id={2}
                      onClick={() => this.handleTab(2)}
                      className="tab-dropdown"
                    >
                      Skills
                    </button>
                  ) : (
                    ""
                  )}

                  {this.state.award !== undefined ? (
                    <button
                      id={3}
                      onClick={() => this.handleTab(3)}
                      className="tab-dropdown"
                    >
                      Award List
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="maintab-content" style={{ marginLeft: "2%" }}>
                {tabContent === 0 ? (
                  this.state.isEducation ? (
                    <div>
                      {this.state.postCollege !== undefined ? (
                        <ul style={{ listStyle: "none" }}>
                          <h5 className="maintab-content-t">
                            Post Graduation :
                          </h5>
                          <li className="maintab-content-st">
                            {" "}
                            College Name :{" "}
                            <input
                              type="text"
                              className="form-control ember-text-field ember-view"
                              name="postCollege"
                              value={this.state.postCollege}
                              onChange={this.handleEducation}
                              placeholder="Enter college name"
                            ></input>{" "}
                          </li>
                          <li className="maintab-content-st">
                            {" "}
                            Marks :{" "}
                            <input
                              type="text"
                              className="form-control ember-text-field ember-view"
                              name="postMark"
                              value={this.state.postMark}
                              onChange={this.handleEducation}
                              placeholder="Enter marks"
                            ></input>{" "}
                          </li>
                          <li className="maintab-content-st">
                            {" "}
                            Course :
                            <button
                              className={
                                "edit-courseType " + this.state.isFullTimePG
                              }
                              value="Full Time"
                              name="courseTypePostGraduation"
                              id="fullTimePostGraduation"
                              onClick={() =>
                                this.handleCourseType(
                                  "fullTimePostGraduation",
                                  6
                                )
                              }
                              onChange={this.handleEducation}
                            >
                              Full Time
                            </button>
                            <button
                              className={
                                "edit-courseType " + this.state.isPartTimePG
                              }
                              value="Part Time"
                              name="courseTypePostGraduation"
                              id="partTimePostGraduation"
                              onClick={() =>
                                this.handleCourseType(
                                  "partTimePostGraduation",
                                  7
                                )
                              }
                              onChange={this.handleEducation}
                            >
                              Part Time
                            </button>
                          </li>
                          <li className="maintab-content-st">
                            {" "}
                            Passing Year :{" "}
                            <input
                              type="date"
                              name="postPassingYear"
                              value={this.state.postPassingYear}
                              onChange={this.handleEducation}
                            ></input>{" "}
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}

                      {this.state.graduationCollege !== undefined ? (
                        <ul style={{ listStyle: "none" }}>
                          <h5 className="maintab-content-t">Graduation : </h5>
                          <li className="maintab-content-st">
                            {" "}
                            College Name :{" "}
                            <input
                              type="text"
                              className="form-control ember-text-field ember-view"
                              name="graduationCollege"
                              value={this.state.graduationCollege}
                              onChange={this.handleEducation}
                              placeholder="Enter College Name"
                            ></input>{" "}
                          </li>
                          <li className="maintab-content-st">
                            {" "}
                            Marks :{" "}
                            <input
                              type="text"
                              className="form-control ember-text-field ember-view"
                              name="graduationMark"
                              value={this.state.graduationMark}
                              onChange={this.handleEducation}
                              placeholder="Enter marks"
                            ></input>{" "}
                            <br />
                          </li>
                          <li className="maintab-content-st">
                            {" "}
                            Course :
                            <button
                              className={
                                "edit-courseType " +
                                this.state.isFullTimeGraduation
                              }
                              value="Full Time"
                              name="courseTypeGraduation"
                              id="fullTimeGraduation"
                              onClick={() =>
                                this.handleCourseType("fullTimeGraduation", 4)
                              }
                              onChange={this.handleEducation}
                            >
                              Full Time
                            </button>
                            <button
                              className={
                                "edit-courseType " +
                                this.state.isPartTimeGraduation
                              }
                              value="Part Time"
                              name="courseTypeGraduation"
                              id="partTimeGraduation"
                              onClick={() =>
                                this.handleCourseType("partTimeGraduation", 5)
                              }
                              onChange={this.handleEducation}
                            >
                              Part Time
                            </button>
                          </li>
                          <li className="maintab-content-st">
                            {" "}
                            Passing Year :{" "}
                            <input
                              type="date"
                              name="graduationPassingYear"
                              value={this.state.graduationPassingYear}
                              onChange={this.handleEducation}
                            ></input>{" "}
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}

                      {this.state.school12 !== undefined ? (
                        <ul style={{ listStyle: "none" }}>
                          <h5 className="maintab-content-t">12th School :</h5>

                          <li className="maintab-content-st">
                            {" "}
                            College Name :{" "}
                            <input
                              type="text"
                              className="form-control ember-text-field ember-view"
                              name="school12"
                              value={this.state.school12}
                              onChange={this.handleEducation}
                              placeholder="Enter school name"
                            ></input>{" "}
                          </li>
                          <li className="maintab-content-st">
                            {" "}
                            Marks:{" "}
                            <input
                              type="text"
                              className="form-control ember-text-field ember-view"
                              name="mark12"
                              value={this.state.mark12}
                              onChange={this.handleEducation}
                              placeholder="Enter marks"
                            ></input>
                          </li>
                          <li className="maintab-content-st">
                            {" "}
                            Course :
                            <button
                              className={
                                "edit-courseType " + this.state.isFullTime12
                              }
                              value="Full Time"
                              name="courseType12"
                              id="fullTime12"
                              onClick={() =>
                                this.handleCourseType("fullTime12", 2)
                              }
                              onChange={this.handleEducation}
                            >
                              Full Time
                            </button>
                            <button
                              className={
                                "edit-courseType " + this.state.isPartTime12
                              }
                              value="Part Time"
                              name="courseType12"
                              id="partTime12"
                              onClick={() =>
                                this.handleCourseType("partTime12", 3)
                              }
                              onChange={this.handleEducation}
                            >
                              Part Time
                            </button>
                          </li>
                          <li className="maintab-content-st">
                            {" "}
                            Passing Year :{" "}
                            <input
                              type="date"
                              name="passingYear12"
                              value={this.state.passingYear12}
                              onChange={this.handleEducation}
                            ></input>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}

                      {this.state.school10 !== undefined ? (
                        <ul style={{ listStyle: "none" }}>
                          <h5 className="maintab-content-t">10th School :</h5>
                          <li className="maintab-content-st">
                            {" "}
                            College Name :{" "}
                            <input
                              type="text"
                              className="form-control ember-text-field ember-view"
                              onChange={this.handleEducation}
                              placeholder="Enter School"
                              name="school10"
                              value={this.state.school10}
                            />{" "}
                          </li>
                          <li className="maintab-content-st">
                            {" "}
                            Marks :{" "}
                            <input
                              type="text"
                              className="form-control ember-text-field ember-view"
                              onChange={this.handleEducation}
                              placeholder="Enter Marks"
                              name="mark10"
                              value={this.state.mark10}
                            />
                          </li>
                          <li className="maintab-content-st">
                            {" "}
                            Course :
                            <button
                              className={
                                "edit-courseType " + this.state.isFullTime10
                              }
                              value="Full Time"
                              name="courseType10"
                              id="fullTime10"
                              onClick={() =>
                                this.handleCourseType("fullTime10", 0)
                              }
                              onChange={this.handleEducation}
                            >
                              Full Time
                            </button>
                            <button
                              className={
                                "edit-courseType " + this.state.isPartTime10
                              }
                              value="Part Time"
                              name="courseType10"
                              id="partTime10"
                              onClick={() =>
                                this.handleCourseType("partTime10", 1)
                              }
                              onChange={this.handleEducation}
                            >
                              Part Time
                            </button>
                          </li>
                          <li className="maintab-content-st">
                            {" "}
                            Passing Year :{" "}
                            <input
                              type="date"
                              onChange={this.handleEducation}
                              name="passingYear10"
                              value={this.state.passingYear10}
                            />
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )
                ) : tabContent === 1 ? (
                  this.state.experienceArray.length !== 0 ? (
                    <ul style={{ listStyle: "none" }}>
                      {this.state.experienceArray.map((exp, index) => (
                        <div>
                          <h5 className="maintab-content-t">
                            Experience {index + 1} :
                          </h5>
                          <li className="maintab-content-st">
                            {" "}
                            Title :{" "}
                            <input
                              type="text"
                              className="form-control ember-text-field ember-view"
                              onChange={this.handleExperience}
                              id={"title_" + (index + 1)}
                              name={"title_" + (index + 1)}
                              placeholder="Enter Title"
                            ></input>{" "}
                          </li>
                          <li className="maintab-content-st">
                            {" "}
                            Employement Type :{" "}
                            <input
                              type="text"
                              className="form-control ember-text-field ember-view"
                              onChange={this.handleExperience}
                              id={"employementType_" + (index + 1)}
                              name={"employementType_" + (index + 1)}
                              placeholder="Enter Employement Type"
                            ></input>{" "}
                          </li>
                          <li className="maintab-content-st">
                            {" "}
                            Company Name :{" "}
                            <input
                              type="text"
                              className="form-control ember-text-field ember-view"
                              onChange={this.handleExperience}
                              id={"companyName_" + (index + 1)}
                              name={"companyName_" + (index + 1)}
                              placeholder="Enter company name"
                            ></input>{" "}
                          </li>
                          <li className="maintab-content-st">
                            {" "}
                            Location :{" "}
                            <input
                              type="text"
                              className="form-control ember-text-field ember-view"
                              onChange={this.handleExperience}
                              id={"location_" + (index + 1)}
                              name={"location_" + (index + 1)}
                              placeholder="Enter location"
                            ></input>{" "}
                          </li>
                          <li className="maintab-content-st">
                            {" "}
                            Start Date :{" "}
                            <input
                              type="date"
                              className="form-control ember-text-field ember-view"
                              onChange={this.handleExperience}
                              id={"startDate_" + (index + 1)}
                              name={"startDate_" + (index + 1)}
                              placeholder="Enter your name"
                            ></input>{" "}
                            & End Date:{" "}
                            <input
                              type="date"
                              className="form-control ember-text-field ember-view"
                              onChange={this.handleExperience}
                              id={"endDate_" + (index + 1)}
                              name={"endDate_" + (index + 1)}
                              placeholder="Enter your name"
                            ></input>{" "}
                          </li>
                          <li className="maintab-content-st">
                            {" "}
                            Description :{" "}
                            <input
                              type="text"
                              className="form-control ember-text-field ember-view"
                              onChange={this.handleExperience}
                              id={"description_" + (index + 1)}
                              name={"description_" + (index + 1)}
                              placeholder="Enter description"
                            ></input>
                          </li>
                        </div>
                      ))}
                    </ul>
                  ) : (
                    ""
                  )
                ) : tabContent === 2 ? (
                  this.state.skillArray !== undefined ? (
                    <div>
                      <h5 className="maintab-content-t">Skills :</h5>
                      <textarea
                        name="skill"
                        className="form-control1"
                        placeholder="Enter Your Skills"
                        onChange={(event) => this.setSkill(event.target.value)}
                        value={this.state.skill}
                      ></textarea>

                      <div className="col-md-6 col-lg-6 col-sm-12 adjust-preview-p">
                        <div className="form-group">
                          <div className="col-md-6 control-label">
                            {this.state.skillArray !== []
                              ? this.state.skillArray.map((skill) => (
                                  <div className="chip">
                                    {skill.skill}
                                    <span
                                      className="closeChip"
                                      onClick={() =>
                                        this.handleDelete(skill.key)
                                      }
                                    >
                                      &times;
                                    </span>
                                  </div>
                                ))
                              : ""}
                          </div>
                        </div>
                        <button
                          className="skill-button"
                          onClick={() => this.addSkill(this.state.skill)}
                        >
                          Add Skills
                        </button>
                      </div>

                      {/* {this.state.skill.map((skill)=>(
                                        <li className='maintab-content-st'> {skill.skill} </li>
                                ))} */}
                    </div>
                  ) : (
                    ""
                  )
                ) : tabContent === 3 ? (
                  this.state.award !== undefined ? (
                    <div>
                      <h5 className="maintab-content-t">Award :</h5>
                      <ul style={{ listStyle: "none" }}>
                        {this.state.award.map((award, index) => (
                          <li className="maintab-content-st">
                            {" "}
                            <input
                              type="text"
                              className="form-control ember-text-field ember-view"
                              id={"award_" + (index + 1)}
                              name={"award_" + (index + 1)}
                              placeholder="Enter Awards"
                            ></input>{" "}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* {this.state.productDetail!==undefined?
            
            <div style={{margin:"2%"}}>
             <div style={{marginBottom:'2%'}} className='tab-heading-client-c ' id="product">
                 <div className='tab-heading px-4 w-12 py-1'>Services</div>
             </div>
             <div className='row'>
                     {this.state.productDetail.map((prod,index)=>(
                         <div className='col-md-3 col-lg-3 col-sm-12'>
                         <div className='all-profile-img-container'>
                         <img className='profileImageUrl' id={'productImage_'+index} src={prod.serviceImageUrl}/>
                        <input id={'productFile_'+index} type='file' multiple={true} accept='image/*' onChange={()=>this.getProductFiles(index)}/><br/>
                             <div className='product-hover-container'>
                                 <h5> <input type="text" id={'serviceName_'+index} className="form-control ember-text-field ember-view" name={"serviceName_"+index} placeholder='Enter the services name' ></input> </h5>
                             </div>
                         </div>
                         <div>
                             <h4> <input type="text" id={'serviceName_'+index} className="form-control ember-text-field ember-view" name={"serviceName_"+index} placeholder='Enter the services name' ></input> </h4>
                         </div>
                     </div>
                     ))}
                 </div>
            </div>:''
         } */}

            {this.state.productDetail.length !== 0 ? (
              <div>
                <div className="preview-boxes row">
                  <h3 className="adjust-preview-p"> Your Services </h3>
                  {this.state.productDetail.map((prod, index) => (
                    <div className="row">
                      <div className="col-md-4 col-lg-4 col-sm-12">
                        <img
                          className="profileImageUrl"
                          id={"productImage_" + index}
                          src={prod.serviceImageUrl}
                        />
                        <input
                          id={"productFile_" + index}
                          type="file"
                          multiple={true}
                          accept="image/*"
                          onChange={() => this.getProductFiles(index)}
                        />
                      </div>
                      <div className="col-md-8 col-lg-8 col-sm-12">
                        <div className="form-group">
                          <label className="control-label col-md-4 col-lg-4 col-sm-4">
                            {" "}
                            Service Name
                          </label>
                          <div className="col-md-6 col-lg-6 col-sm-8">
                            <input
                              type="text"
                              id={"serviceName_" + index}
                              className="form-control ember-text-field ember-view"
                              name={"serviceName_" + index}
                              placeholder="Enter the services name"
                            ></input>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4 col-lg-4 col-sm-4">
                            {" "}
                            Services Description
                          </label>
                          <div className="col-md-6 col-lg-6 col-sm-8">
                            <input
                              type="text"
                              id={"serviceDescription_" + index}
                              className="form-control ember-text-field ember-view"
                              name={"serviceDescription_" + index}
                              placeholder="Enter more about your services"
                            ></input>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}

            {/* {this.state.bookingDetail.length!==0?
                <div className="preview-boxes row">
                    <h3 className='adjust-preview-p'> Booking Slots </h3>
                    <h4> How Many Days a Week? </h4>
                    <div>
                        <button onClick={()=>this.addBookingDay('Mon')} id='Mon' className='booking-day'> Mon </button>
                        <button onClick={()=>this.addBookingDay('Tue')} id='Tue' className='booking-day'> Tue </button>
                        <button onClick={()=>this.addBookingDay('Wed')} id='Wed' className='booking-day'> Wed </button>
                        <button onClick={()=>this.addBookingDay('Thurs')} id='Thurs' className='booking-day'> Thurs </button>
                        <button onClick={()=>this.addBookingDay('Fri')} id='Fri' className='booking-day'> Fri </button>
                        <button onClick={()=>this.addBookingDay('Sat')} id='Sat' className='booking-day'> Sat </button>
                        <button onClick={()=>this.addBookingDay('Sun')} id='Sun' className='booking-day'> Sun </button>
                    </div>
                        <div>
                            <label style={{marginLeft:'1%'}}> Morning Slots</label>
                            <div className='row'>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('morning','7:00 AM')} id='7:00 AM' className='booking-timing'>7:00 AM</button></div>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('morning','7:30 AM')} id='7:30 AM' className='booking-timing'>7:30 AM</button></div>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('morning','8:00 AM')} id='8:00 AM' className='booking-timing'>8:00 AM</button></div>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('morning','8:30 AM')} id='8:30 AM' className='booking-timing'>8:30 AM</button></div>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('morning','9:00 AM')} id='9:00 AM' className='booking-timing'>9:00 AM</button></div>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('morning','9:30 AM')} id='9:30 AM' className='booking-timing'>9:30 AM</button></div>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('morning','10:00 AM')} id='10:00 AM' className='booking-timing'>10:00 AM</button></div>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('morning','10:30 AM')} id='10:30 AM' className='booking-timing'>10:30 AM</button></div>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('morning','11:00 AM')} id='11:00 AM' className='booking-timing'>11:00 AM</button></div>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('morning','11:30 AM')} id='11:30 AM' className='booking-timing'>11:30 AM</button></div>
                            </div>
                        </div>
                        <div>
                           <label style={{marginLeft:'1%'}}> Afternoon </label>
                           <div className='row'>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('afternoon','12:00 PM')} id='12:00 PM'  className='booking-timing'>12:00 PM</button></div>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('afternoon','12:30 PM')} id='12:30 PM'  className='booking-timing'>12:30 PM</button></div>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('afternoon','1:00 PM')} id='1:00 PM'  className='booking-timing'>1:00 PM</button></div>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('afternoon','1:30 PM')} id='1:30 PM'  className='booking-timing'>1:30 PM</button></div>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('afternoon','2:00 PM')} id='2:00 PM'  className='booking-timing'>2:00 PM</button></div>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('afternoon','2:30 PM')} id='2:30 PM'  className='booking-timing'>2:30 PM</button></div>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('afternoon','3:00 PM')} id='3:00 PM'  className='booking-timing'>3:00 PM</button></div>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('afternoon','3:30 PM')} id='3:30 PM'  className='booking-timing'>3:30 PM</button></div>
                           </div>
                        </div>
                        <div>
                           <label style={{marginLeft:'1%'}}> Evening </label>
                           <div className='row'>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('evening','4:00 PM')} id='4:00 PM' className='booking-timing'>4:00 PM</button></div>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('evening','4:30 PM')} id='4:30 PM' className='booking-timing'>4:30 PM</button></div>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('evening','5:00 PM')} id='5:00 PM' className='booking-timing'>5:00 PM</button></div>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('evening','5:30 PM')} id='5:30 PM' className='booking-timing'>5:30 PM</button></div>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('evening','6:00 PM')} id='6:00 PM' className='booking-timing'>6:00 PM</button></div>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('evening','6:30 PM')} id='6:30 PM' className='booking-timing'>6:30 PM</button></div>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('evening','7:00 PM')} id='7:00 PM' className='booking-timing'>7:00 PM</button></div>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('evening','7:30 PM')} id='7:30 PM' className='booking-timing'>7:30 PM</button></div>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('evening','8:00 PM')} id='8:00 PM' className='booking-timing'>8:00 PM</button></div>
                                <div className='col-md-2 col-lg-2 col-sm-3'><button onClick={()=>this.handleMeetingTime('evening','8:30 PM')} id='8:30 PM' className='booking-timing'>8:30 PM</button></div>
                           </div>
                        </div>
                </div>:""
                } */}

            {/* {this.state.checkFee?
                    <div className="preview-boxes row">
                        <h3 className="adjust-preview-p">Fee Details</h3>
                        <div>
                        <div className='form-group bt-fields'>
                            <label className='control-label col-md-4 col-lg-4 col-sm-4'> Select Your preferred consultation type </label>
                            <div className='col-md-6 col-lg-6 col-sm-8'>
                                <button className="consultationType" id='online_c' onClick={()=>this.setConsultationType(0)}> Online Consultation / Telephonic </button>
                                <button className="consultationType" id="inperson_c" onClick={()=>this.setConsultationType(1)}> Inperson Consultation </button>
                            </div>
                        </div>
                        <div className='form-group bt-fields'>
                            <label className='control-label col-md-4 col-lg-4 col-sm-4'>Online Consultaion Fee</label>
                            <div className='col-md-6 col-lg-6 col-sm-8'>
                                <input type="number" disabled={this.state.consultationOnlineFeeDisabled} className="form-control ember-text-field ember-view" placeholder="Enter your per hour fee" name="onlineFee" id='onlineFee' value={this.state.onlineFee} onChange={this.handleChange} />
                            </div>
                        </div>
            
                        <div className='control-label col-md-4 col-lg-4 col-sm-4'></div>
                        <div className='col-md-8 col-lg-8 col-sm-8'>
                            <input checked={this.state.freeOnlineConsultation} type="checkbox" id="freeOnlineConsultation" name="freeOnlineConsultation" value={!this.state.freeOnlineConsultation} onChange={this.handleFreeOnlineConsultantChange} />
                            <label style={{marginLeft:'2%'}}> Select this if you want to provide free online consultation </label>
                        </div>

                        <div className='form-group bt-fields'>
                            <label className='control-label col-md-4 col-lg-4 col-sm-4'>Inperson Consultaion Fee</label>
                            <div className='col-md-6 col-lg-6 col-sm-8'>
                                <input type="number" disabled={this.state.consultationInPersonFeeDisabled} className="form-control ember-text-field ember-view" placeholder="Enter your per hour fee" name="InPersonfee" id='InPersonfee' value={this.state.InPersonfee} onChange={this.handleChange} />
                            </div>
                        </div>
            
                        <div className='control-label col-md-4 col-lg-4 col-sm-4'></div>
                        <div className='col-md-8 col-lg-8 col-sm-8'>
                            <input checked={this.state.freeInPersonConsultation} type="checkbox" id="freeInPersonConsultation" name="freeInPersonConsultation" value={!this.state.freeInPersonConsultation} onChange={this.handleFreeInPersonConsultantChange} />
                            <label style={{marginLeft:'2%'}}> Select this if you want to provide free inperson consultation </label>
                        </div>
                        </div>
                    </div>
                :""} */}
            <button
              className="right-float nxt-button"
              onClick={() => this.submitProfile()}
            >
              Save Changes
            </button>

            {/* <button className="right-float nxt-button" onClick={()=>this.submitProfile()}>Submit</button> */}
          </div>
        ) : (
          <PreviewProfile
            profileId={this.state.profileId}
            validProfile={this.state.validProfile}
            data={this.state.data}
            productDetail={this.state.productDetailArray}
            bookingDetail={this.state.bookingDetail}
            feeDetail={this.state.feeDetail}
          />
        )}
      </div>
    );
  }
}
