import React, { Component } from "react";
import { Link } from "react-router-dom";
import GSTSidebar from "./GSTSidebar";

export default class GSTDashboard extends Component {

  
  render() {
    return (
      <div>
      <div className="row">
          <div className='row-md-2 col-lg-2'> <GSTSidebar/>  </div>
      <div className="col-md-10 col-lg-10">
      <div className="mainScreenKhub">
      <div className="container">
          <div className="row">
          <div className="col-md-6">
<div className="tab-row1">
<h2 className="tab-row-head">Case Law Category</h2>
<div className="row">

<div className="col-md-4">
<div className="tab text-center">
<Link to="/knowledgeHub/add-gst/category/case-law">
<img className="tab-image1" id="tab1" 
        style={{ marginLeft: "-3px" }}
        src={require("../../images/home.png")}
      />
<h2 className="tab-text-main"> Add Case Law Category </h2></Link>
</div>
</div> 
<div className="col-md-1"></div>
<div className="col-md-4">
<div className="tab text-center">
<Link to="/knowledgeHub/view-gst/category/case-law">
<img className="tab-image1" id="tab1" 
        style={{ marginLeft: "-3px" }}
        src={require("../../images/home.png")}
      />
<h2 className="tab-text-main"> View Case Law Category </h2></Link>
</div>
</div> 

</div>


</div>

</div>

<div className="col-md-6">
<div className="tab-row1">
<h2 className="tab-row-head">Case Law Resources</h2>
<div className="row">


<div className="col-md-4">

<div className="tab text-center">
<Link to="/knowledgeHub/add-gst/resources/case-law">  
<img className="tab-image2" id="tab1"
        style={{ marginLeft: "-3px" }}
        src={require("../../images/N5.png")}
      />
 <h2 className="tab-text-main">Add Case Law Resources</h2></Link>
</div>
</div> 
<div className="col-md-1"></div>

<div className="col-md-4">
<div className="tab text-center">
<Link to="/knowledgeHub/view-gst/resources/case-law">    
<img className="tab-image1" id="tab1" 
        style={{ marginLeft: "-3px" }}
        src={require("../../images/N6.png")}
      />
 <h2 className="tab-text-main">View Case Law Resources</h2></Link>
</div>
</div> 

</div>


</div>
</div>

<div className="col-md-6">
<div className="tab-row1">
<h2 className="tab-row-head">Acts Category</h2>
<div className="row">

<div className="col-md-4">
<div className="tab text-center">
<Link to="/knowledgeHub/add-gst/category/acts">
<img className="tab-image1" id="tab1" 
        style={{ marginLeft: "-3px" }}
        src={require("../../images/home.png")}
      />
<h2 className="tab-text-main"> Add Acts Category </h2></Link>
</div>
</div> 
<div className="col-md-1"></div>
<div className="col-md-4">
<div className="tab text-center">
<Link to="/knowledgeHub/view-gst/category/acts">
<img className="tab-image1" id="tab1" 
        style={{ marginLeft: "-3px" }}
        src={require("../../images/home.png")}
      />
<h2 className="tab-text-main"> View Acts Category </h2></Link>
</div>
</div> 

</div>


</div>

</div>

<div className="col-md-6">
<div className="tab-row1">
<h2 className="tab-row-head">Acts Resources</h2>
<div className="row">


<div className="col-md-4">

<div className="tab text-center">
<Link to="/knowledgeHub/add-gst/resources/acts">  
<img className="tab-image2" id="tab1"
        style={{ marginLeft: "-3px" }}
        src={require("../../images/N5.png")}
      />
 <h2 className="tab-text-main">Add Acts Resources</h2></Link>
</div>
</div> 
<div className="col-md-1"></div>

<div className="col-md-4">
<div className="tab text-center">
<Link to="/knowledgeHub/view-gst/resources/acts">    
<img className="tab-image1" id="tab1" 
        style={{ marginLeft: "-3px" }}
        src={require("../../images/N6.png")}
      />
 <h2 className="tab-text-main">View Acts Resources</h2></Link>
</div>
</div> 

</div>


</div>
</div>

<div className="col-md-6">
<div className="tab-row1">
<h2 className="tab-row-head">Rules Category</h2>
<div className="row">

<div className="col-md-4">
<div className="tab text-center">
<Link to="/knowledgeHub/add-gst/category/rules">
<img className="tab-image1" id="tab1" 
        style={{ marginLeft: "-3px" }}
        src={require("../../images/home.png")}
      />
<h2 className="tab-text-main"> Add Rules Category </h2></Link>
</div>
</div> 
<div className="col-md-1"></div>
<div className="col-md-4">
<div className="tab text-center">
<Link to="/knowledgeHub/view-gst/category/rules">
<img className="tab-image1" id="tab1" 
        style={{ marginLeft: "-3px" }}
        src={require("../../images/home.png")}
      />
<h2 className="tab-text-main"> View Rules Category </h2></Link>
</div>
</div> 

</div>


</div>

</div>

<div className="col-md-6">
<div className="tab-row1">
<h2 className="tab-row-head">Rules Resources</h2>
<div className="row">


<div className="col-md-4">

<div className="tab text-center">
<Link to="/knowledgeHub/add-gst/resources/rules">  
<img className="tab-image2" id="tab1"
        style={{ marginLeft: "-3px" }}
        src={require("../../images/N5.png")}
      />
 <h2 className="tab-text-main">Add Rules Resources</h2></Link>
</div>
</div> 
<div className="col-md-1"></div>

<div className="col-md-4">
<div className="tab text-center">
<Link to="/knowledgeHub/view-gst/resources/rules">    
<img className="tab-image1" id="tab1" 
        style={{ marginLeft: "-3px" }}
        src={require("../../images/N6.png")}
      />
 <h2 className="tab-text-main">View Rules Resources</h2></Link>
</div>
</div> 

</div>


</div>
</div>

<div className="col-md-6">
<div className="tab-row1">
<h2 className="tab-row-head">Circular & Notification Category</h2>
<div className="row">

<div className="col-md-4">
<div className="tab text-center">
<Link to="/knowledgeHub/add-gst/category/circular-notification">
<img className="tab-image1" id="tab1" 
        style={{ marginLeft: "-3px" }}
        src={require("../../images/home.png")}
      />
<h2 className="tab-text-main"> Add Circular & Notification Category </h2></Link>
</div>
</div> 
<div className="col-md-1"></div>
<div className="col-md-4">
<div className="tab text-center">
<Link to="/knowledgeHub/view-gst/category/circular-notification">
<img className="tab-image1" id="tab1" 
        style={{ marginLeft: "-3px" }}
        src={require("../../images/home.png")}
      />
<h2 className="tab-text-main"> View Circular & Notification Category </h2></Link>
</div>
</div> 

</div>


</div>

</div>

<div className="col-md-6">
<div className="tab-row1">
<h2 className="tab-row-head">Circular & Notification Resources</h2>
<div className="row">


<div className="col-md-4">

<div className="tab text-center">
<Link to="/knowledgeHub/add-gst/resources/circular-notification">  
<img className="tab-image2" id="tab1"
        style={{ marginLeft: "-3px" }}
        src={require("../../images/N5.png")}
      />
 <h2 className="tab-text-main">Add Circular & Notification Resources</h2></Link>
</div>
</div> 
<div className="col-md-1"></div>

<div className="col-md-4">
<div className="tab text-center">
<Link to="/knowledgeHub/view-gst/resources/circular-notification">    
<img className="tab-image1" id="tab1" 
        style={{ marginLeft: "-3px" }}
        src={require("../../images/N6.png")}
      />
 <h2 className="tab-text-main">View Circular & Notification Resources</h2></Link>
</div>
</div> 

</div>


</div>
</div>
          </div>
      </div>
      </div></div>
      </div>
  </div>
          
       
    );
  }
}
