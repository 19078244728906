import React from 'react';
import axios from 'axios';
import ReactTable from 'react-table';
import BreadCrumbs from '../../components/BreadCrumbs';
import GuideBlogSidebar from './GuideBlogSidebar';
import { Link } from "react-router-dom";

export default class ListGuideChapter extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            resdata:[],
            learningType:'',
        }
    }

    componentDidMount(){
      let url = window.location.href.split('/')
      let index = url.length-2
      this.setState({learningType:url[index]})
        this.fetchAllChapter(url[index])
    }

    fetchAllChapter(learningType){
      let id=localStorage.getItem('guideBlogFilterId')
      let temp_data=[]
        return axios({       
            url: 'https://q7ay5k66xi.execute-api.us-east-1.amazonaws.com/dev/businus/guideChapter/list/'+id,
            method: 'GET',
            crossDomain: true,
            responseType: 'json', 
          }).then((response) => {
              for(var i=0;i<response.data.length;i++){
                if(response.data[i].guideId===id && response.data[i].learningType===learningType){
                    temp_data.push(response.data[i])
                }
              }
          this.setState({resdata:temp_data})
         });
    }

    deleteCategory(id){
        if (window.confirm("Are you sure you want to delete this chapter ?")) {
            axios({
              url: 'https://q7ay5k66xi.execute-api.us-east-1.amazonaws.com/dev/businus/getGuideChapterById/' + id,
              method: 'Delete',
            }).then((response) => {
            if(response.status===200){
                alert("Blog Deleted");
                window.location.reload()
            }
            });
      
          }
    }
    
    editGuideChapter(id){
      window.location.replace('/knowledgeHub/businus-school/'+this.state.learningType+'/edit-guides-chapter/'+id)
    }

    render(){
        const columns = [{
            Header: <div className="form-textHead cell-head">Actions</div>,
            accessor: d => { return {id:d.id,}},
            id:"id",
            Cell: props =>  <div className='' style={{}}>
                <button style={{margin: "3px"}} className="btn btn-primary"><span className="fas fa-edit" onClick={()=>this.editGuideChapter(props.value.id)}></span></button>
                <button style={{margin: "3px"}} className="btn btn-danger" onClick={e=>this.deleteCategory(props.value.id,)}><span className="fas fa-trash"></span></button>
            </div>,
      
            minWidth:150
          },
          {
            Header: <div className="form-textHead cell-head">Guide Blog Id</div>,
            accessor: 'guideChapterId',
            filterMethod: (filter, row) => {   return row.catagory.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:120
          },
           {
            Header:<div className="form-textHead cell-head"> Chapter</div>,
            accessor: 'chapter',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:220
          },
          {
            Header: props => <span className="form-textHead cell-head"> Date</span>,
            accessor: 'date',
            filterMethod: (filter, row) =>
                     {   return row.source.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => 
            new Date(props.value).toDateString(),
            minWidth:180
          },
          {
            Header: props => <span className="form-textHead cell-head"> Time</span>,
            accessor: 'date',
            filterMethod: (filter, row) =>
                     {   return row.source.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => 
            new Date(props.value).toLocaleTimeString(),
            minWidth:180
          }
        ]
        return(
          <div>
            <div className="row">
              <div className="col-md-2 col-lg-2"><GuideBlogSidebar learningType={this.state.learningType}/></div>
              <div className="col-md-10 col-lg-10">
            <div className = "container-fluid overflow-auto" style={{margin: "10px"}}>
            <div className="row breadcrumb-row">
          <div className="col-md-10" style={{marginTop:'10px'}}><BreadCrumbs/></div></div>
        <div className="container-fluid " style={{}}>
          <ReactTable data={this.state.resdata} columns={columns} style={{height:'85vh',overflow:'scroll'}} filterable/>
                

        </div>
        </div>
        </div>
      </div>
        </div>
        )
    }
}