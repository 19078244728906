import React, {Component} from 'react';
import axios from 'axios'
import './profile.css';
import EditorJs from 'react-editor-js';
import ReadOnly from './profileReadOnly';

export default class AboutUser extends Component{
    constructor(props){
        super(props)
        this.state={
            personalDetail:[],
            bio:[],
            imageDetail:[],
            isBio:false,
        }
        this.fetchProfileById = this.fetchProfileById.bind(this)
    }
    
    componentWillMount(){
        let id = this.props.match.params.id
        this.fetchProfileById(id)
    }
    fetchProfileById(id){
        axios({
            url:'https://8cop2jp1c8.execute-api.us-east-1.amazonaws.com/dev/businus/profile/getProfileById/'+id,
            method:'GET',
            crossDomain:true,
            response:'JSON',
          }).then((response)=>{
              console.log("response",response.data.personalDetail.personalDetail.profileDetail.profileImageUrl)
            this.setState({
                personalDetail:response.data.personalDetail.personalDetail,
                imageDetail:response.data.personalDetail.personalDetail.profileDetail,
                bio:response.data.personalDetail.bio,
                isBio:true
            })
         } )
    }
    render(){
        return(
            <div className='user-profile-container'>
                <div className='user-background-image'></div>
                <div className='user-detail'>
                <div className="row spacing-user-detail">
                        <div className="col-md-3 col-lg-3 col-sm-12">
                            <img className='user-profile-image' src={this.state.imageDetail.profileImageUrl}/>
                        </div>
                        <div className="user-header-name ml-1"> {this.state.personalDetail.name} </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                                <tr>
                                    <td className="about-user-inf-t"> Experience : </td>
                                    <td className="user-inf-d"> {this.state.personalDetail.experience} </td>
                                </tr>
                                <tr>
                                    <td className="about-user-inf-t"> Practise & Area : </td>
                                    <td className="user-inf-d"> {this.state.personalDetail.practise}</td>
                                </tr>
                                <tr>
                                    <td className="about-user-inf-t"> Location : </td>
                                    <td className="user-inf-d "> {this.state.personalDetail.location}  </td>
                                </tr>
                                <tr>
                                    <td className="about-user-inf-t"> Languages : </td>
                                    <td className="user-inf-d "> {this.state.personalDetail.language} </td>
                                </tr>
                                
                        </div>
                    </div>

                    <div className='about-user-container-d'>
                        <div className='about-user-title-d'>
                            About
                        </div>
                        <div className='about-user-content-d'>
                            {console.log('render data',this.state.bio)}
                            {this.state.isBio?
                            <ReadOnly data={this.state.bio}/> :""}
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}