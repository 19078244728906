import React, { Component } from "react";
import { Link } from "react-router-dom";
import BreadCrumbs from "../../BreadCrumbs";
import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { ChatFeed, Message } from 'react-chat-ui'
import {Multiselect} from 'multiselect-react-dropdown';
import {getPrivilegeByName} from '../../privilegeUtils';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from 'underscore';
let context = null;
const data = [];
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               :'0px ',
    position               :'Absolute',
    zIndex                 :'30000'     ,
    backgroundColor         :'none',  
  }
};
 
export default class pageClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
   thread:[],
      sliderState:1,
      
      module: '',
      opened: [],
      subject: "",
      message: "",
      priority:"",
      attachment: "NA",
      thread: [],
      sendTo: "",
      documents: [],
      privilege:{},
      proposals: [],
      sliderState:1,
      superSliderState:'additional',
      mails: [],
      entry: {
        checklists : [],
        contactPersons : [],
        bankDetails : {},
        attributes:[],
      },
      assignmentDocuments:[],
      assignmentComments:[],
      issues:[],
      expenses:[],
      progressLogs:[],
      modalIsOpen: false,
      docType: "MAIL",
      sliderState: 1,//sta//state for storing the response from the backend,
      
    };
    context = this;
    this.openModal = this.openModal.bind(this);
   
    this.closeModal = this.closeModal.bind(this);
  }

  changeSuperSliderState(name) {

    this.setState({ superSliderState: name });
    if(name==='additional'){
      this.setState({ sliderState: 1 });
    }
    else{
      this.setState({ sliderState: 13 });
    }
  

  }
  

  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile,
        "isMultiple":true,
        "imageName": that.state.selectedFile,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile[0].name });
          var images = [];
          _.each(that.state.selectedFile,function(file){

              images.push("https://businus-files-live.s3.amazonaws.com/mailAttachments/" + file.name);

          })
         
          that.setState({ [docType]: images});
          



          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  



  getFiles(files) {
    this.setState({ selectedFile: files });

  }

  changeSliderState(orderNumber){

    this.setState({sliderState:orderNumber});

}
  //function for changing the status button on click to open or close
  onChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);


    console.log(this.state);
  }
  
  
  openModal(type) {
    this.changeDocType(type);
    this.setState({modalIsOpen: true});
  }
 
  
 
  closeModal() {
    this.setState({modalIsOpen: false});
  }
 

  componentDidMount() {

    var privileges =  JSON.parse(localStorage.getItem('privileges'));
    var privilege = _.findWhere(privileges,{module:"Client"});

    this.setState({privilege:privilege});

    const id = this.props.match.params.id;

    this.fetchQueryById(this, id);
   // this.fetchThreadById(this, id);
    //this.markAsRead(this,id);
  }


  markAsRead(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/read/" +
          id
      )
      .then(function(response) {
       
       
      })
      .catch(function(error) {
        console.log(error);
      });
  }


  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/getClientById/" +
          id
      )
      .then(function(response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

 
  delete = (id) => {
    var result= window.confirm("Are you sure you want to delete?");
    if (result==true){
    axios
      .delete(
        apiBaseUrl2+"businusquery/getClientById/" +
          id
      )
      .then(function(response) {
        debugger;
        console.log(response.data);
        alert("Client has been deleted");
       // that.setState({ entry: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  }
}
  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });



          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  fetchThreadById(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/listmail/" +
          id
      )
      .then(function(response) {
        debugger;
        console.log(response.data);
        var threads = response.data=="" ? [] : _.sortBy(response.data,'date').reverse();
        that.setState({ thread: threads});

        var documents = _.where(threads,{docType:"Document"});
        var proposals = _.where(threads,{docType:"Proposal"});
        var mails = _.where(threads,{docType:"Mail"});
        that.setState({documents});
        that.setState({proposals});
        that.setState({mails});
        
      
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  getFiles(files) {
    this.setState({ selectedFile: files[0] });

  }

  getFormattedDate(millis){

    return new Date(millis).toDateString();


  }

  toggleOpen = status => {
    if (status == undefined || status == "open") {
      status = "close";
    } else {
      status = "open";
    }
    //(this.setState({ entry: [...this.state.entry,{status:status} ] });)
    var newEntry = this.state.entry;
    newEntry.status = status;
    this.setState({ entry: newEntry });
    context.updateStatus(context, this.state.entry.id, status);
  };
  
  changeDocType(type){

    this.setState({docType:type});
  }

  sendMail() {
     var query = context.state.entry;
    axios
      .post(
        apiBaseUrl2+"businusquery/send",
        {
          
      "name": query.name,
      "email": query.email,
      "message":context.state.message,
      "company":query.company, 
      "toe":query.toe,
      "subject":context.state.subject,
      "attachment":context.state.attachment,
      "attachmentName":context.state.attachmentName,
      "queryId":query.id,
      "from":"client",
      "recordType":"MAIL",
      "docType":context.state.docType,
      "senderEmail":context.state.senderEmail
        })
      .then(function(response) {
        debugger;
      alert("SENT");
      context.fetchThreadById(context,query.id);
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  setMailPageUrl(){
  var url = window.location.href.replace(window.location.origin,"");
  localStorage.setItem("MAILURL",url);


  }


  changeSliderState(orderNumber){

    this.setState({sliderState:orderNumber});

}


  //updating the status button
  
  render() {
    const {sliderState,entry,superSliderState} = this.state;
    const active = " active-button"
    const deactive = "";
    return (
      <div className="Div">
        <div className="row breadcrumb-row">
        <BreadCrumbs /> 
     <h4> {entry.parentId!="0" ?  <button className="download-button-wrapper" style={{marginLeft:'40px'}}> <Link  className="pull-right" to={"/assignment/view-assignment/"+entry.parentId}><i className="fa fa-plus"></i>Created From</Link></button> : ''}
     {getPrivilegeByName('Client').canEdit == true ? <button className="download-button-wrapper" style={{marginLeft:'20px',marginRight:'20px'}}> <Link style={{marginRight:'50px'}} className="pull-right" to={"/masters/client/edit-client/"+entry.id}><i className="fa fa-edit"></i>Edit</Link></button>:''}
        {getPrivilegeByName('Client').canDelete == true ?   
      <button className="download-button-wrapper">  <div style={{marginRight:'50px'}} className=" pull-right" onClick={()=>this.delete(entry.id)}><i className="fa fa-trash"></i>Delete</div></button>:''}
       </h4>
     
       </div>
{/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
          <div className="dedicated-page">
            <div className="header-div ">
              <div
                className="bar"
                style={{ borderTop: "#1E5B81", color: "blue" }}
              />
              <div className="row">
                <div className="col-md-10">

                <div className="form-group">
                    <label className="control-label col-md-4">
                      Client ID
                    </label>
                    <div className="col-md-6">
                    <h4 className="detail-fields">{this.state.entry.customerId}</h4>
                    </div>
                  </div>
                <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                      
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        {" "}
                        Mode* :{" "}
                      </span>{" "}
                    </label>
                    <div className="col-md-8">
                      <div className="radio-inline">
                        <label className="cursor-poiner">
                          <input
                            name="mode"
                          type="radio"
                          value="offline"
                          checked={this.state.entry.mode=="offline"}
                           
                            defaultValue="offline"
                          />
                          Offline{" "}
                        </label>
                      </div>
                      <div className="radio-inline">
                        <label className="cursor-poiner">
                          {" "}
                          <input
                            name="mode"
                          
                            value="online"
                          checked={this.state.entry.mode=="online"}
                            type="radio"
                            defaultValue="online"
                          />
                          Online{" "}
                        </label>
                      </div>
                      <div className="radio-inline">
                        <label className="cursor-poiner">
                          {" "}
                          <input
                            name="mode"
                          
                            value="both"
                          checked={this.state.entry.mode=="both"}
                            type="radio"
                            defaultValue="both"
                          />
                          Both{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Company Name :
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{this.state.entry.companyName}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Customer Type :
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{this.state.entry.customerType}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Customer Email :
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{this.state.entry.customerEmail}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Primary Contact:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{this.state.entry.primaryContact}</h4>
                                </div>
                              </div>


                              <div className="form-group">
                        <label className="control-label col-md-4">Assigned Organisation</label>
                        <div className="col-md-6">
                         
                        <h4 className="detail-fields">{entry.assignedOrganisation!=undefined && typeof entry.assignedOrganisation == 'object'? entry.assignedOrganisation.map((org)=>{

return (<span>{org.name} , <br/></span>)

}) : ''
}</h4></div></div>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Customer Contact :
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{this.state.entry.customerContact}</h4>
                                </div>
                              </div>

                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Website:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{this.state.entry.website}</h4>
                                </div>
                              </div>



                
                  
</div>


             

            
                
              </div>
            
              <div className="row " style={{marginTop:'40px'}}>
     <div className={superSliderState == 'additional' ? "col-md-2 active1 superoptions" : "col-md-2 superoptions"} >
       <div className=" " onClick={() => this.changeSuperSliderState('additional')} >Additional Info</div>
     </div>

     <div className={superSliderState == 'access' ? "col-md-2 active1 superoptions" : "col-md-2 superoptions"} >
       <div className="" onClick={() => this.changeSuperSliderState('access')}>Access Controls</div>
     </div>
   

   </div>


{superSliderState==='additional' ? 

   <div className="row " style={{borderTop:'2px solid #4985BD', backgroundColor:'#f7f7f7'}}>
     <div className={sliderState == 1 ? "col-md-2 active2 " : "col-md-2"} >
       <div className="options8 " onClick={() => this.changeSliderState(1)} ><i className="fa fa-home optionsIcon" style={{fontSize:'12px'}}></i>Address</div>
     </div>
     <div className={sliderState == 2 ? "col-md-3 active2 " : "col-md-3"} >
       <div className="options8" onClick={() => this.changeSliderState(2)}><i className="fa fa-envelope optionsIcon" style={{fontSize:'12px'}}></i>Customer Email</div>
     </div>
     <div className={sliderState == 3 ? "col-md-3 active2 " : "col-md-3"} >
       <div className="options8" onClick={() => this.changeSliderState(3)}><i className="fa fa-university optionsIcon" style={{fontSize:'12px'}}></i> Bank Details</div>
     </div>
     <div className={sliderState == 4 ? "col-md-2 active2 " : "col-md-2"} >
       <div className="options8" onClick={() => this.changeSliderState(4)}><i className="fa fa-address-card optionsIcon" style={{fontSize:'12px'}}></i> Attributes</div>
     </div>
     <div className={sliderState == 5 ? "col-md-2 active2 " : "col-md-2"} >
       <div className="options8" onClick={() => this.changeSliderState(5)}><i className="fa fa-users optionsIcon" style={{fontSize:'12px'}}></i> Employees</div>
     </div>
   </div>

   : superSliderState==='access' ?

   <div className="row " style={{borderTop:'2px solid #4985BD', backgroundColor:'#f7f7f7'}}>
     <div className={sliderState == 1 ? "col-md-2 active2 " : "col-md-2"} >
       <div className="options8 " onClick={() => this.changeSliderState(1)} ><i className="fa fa-home optionsIcon" style={{fontSize:'12px'}}></i>Access Control</div>
     </div>
     
   </div>

   :''
}

{/*--------------------------------------------------------1-------------------------------*/}




            {sliderState==1 ?

            <div className="container row">
              <div className="col-md-6">
                <div className="row" style={{marginBottom:'-20px'}}>
                  <label className="control-label add-header col-md-12">
                    Billing Address
                  </label>
                </div>
                <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Address :
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{this.state.entry.billingAddress}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Attention :
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{this.state.entry.billingAttention}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    City :
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{this.state.entry.billingCity}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Country :
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{this.state.entry.billingCountry}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Zip Code :
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{this.state.entry.billingZipCode}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                  <label className="control-label col-md-4">Phone :</label>
                  <div className="col-md-7">
                    <input
                      id="ember335"
                      name="shippingPhone"
                      onChange={this.handleOnChange}
                      value={this.state.entry.billingPhone}
                      className="form-control ember-text-field ember-view"
                      type="text"
                    />{" "}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="row" style={{marginBottom:'-20px'}}>
                  <label className="control-label add-header col-md-12">
                    Shipping Address
                  </label>
                </div>
                <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Address :
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{this.state.entry.shippingAddress}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Attention :
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{this.state.entry.shippingAttention}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    City :
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{this.state.entry.shippingCity}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Country :
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{this.state.entry.shippingCountry}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Zip Code :
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{this.state.entry.shippingZipCode}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                  <label className="control-label col-md-4">Phone :</label>
                  <div className="col-md-7">
                    <input
                      id="ember335"
                      name="shippingPhone"
                      onChange={this.handleOnChange}
                      value={this.state.entry.shippingPhone}
                      className="form-control ember-text-field ember-view"
                      type="text"
                    />{" "}
                  </div>
                </div>
               
              </div>
              </div>
           
            :''}

{/*--------------------------------------------------------2-------------------------------*/}
{sliderState==2 ? 
<div>
  <div className="row" style={{marginTop:'20px'}}>
    <div className="col-md-12">
    <div className="row" style={{marginBottom:'-20px'}}>
      <label className="control-label add-header col-md-12">
        Contact Persons*
      </label>
    </div>
    <ul className="proposals">
                
                {this.state.entry.contactPersons.map((thred, index) => (
                  thred.attachment != "NA" ? 
                 <li>
                   <div className={thred.type  +' row doc-head-row'}>
                 <div className="col-md-6">
                 <div className="table-item text-center">
                 <div  onClick={this.fetchMailById} className="timeline-elements" href="https://www.totoprayogo.com/#">{thred.salutation} {thred.name}</div>
                 </div>
                 </div>
                 <div className="col-md-3">
                 <div className="table-item text-center">
                {thred.email}
                 </div>
                 </div>
                 <div className="col-md-3">
                 <div className="table-item text-center">
                 {thred.phone}
                 </div>
                   </div>
                   </div>
                   </li>:''
        ))}
               </ul></div></div></div> :''}

{/*--------------------------------------------------------3-------------------------------*/}


{sliderState==3 ?

<div className="container row">
  <div className="col-md-12">
    <div className="row" style={{marginBottom:'-20px'}}>
      <label className="control-label add-header col-md-12">
        Banking Details
      </label>
    </div>
    <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Bank Name :
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{this.state.entry.bankDetails.bankName}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Branch Name
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{this.state.entry.bankDetails.branchName}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                   Account No :
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{this.state.entry.bankDetails.accountNo}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Account Type :
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{this.state.entry.bankDetails.accountType}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    IFSC Code:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{this.state.entry.bankDetails.ifscCode}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    MICR Code:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{this.state.entry.bankDetails.micrCode}</h4>
                                </div>
                              </div>
               
              </div>
    
   
    

  
    
  


  
  
</div>
:''}
{sliderState == 4 ?
<div>
  <div className="row" style={{marginTop:'20px'}}>
    <div className="col-md-12">
    <div className="row" style={{marginBottom:'-20px'}}>
      <label className="control-label add-header col-md-12">
        Attributes
      </label>
    </div>
  <div className="text-right" style={{marginRight:'40px'}}>
 {/* <button className="add-button" onClick={this.addNewAttribute} > <i className="fa fa-plus "></i></button> */}
  </div>
              <table className="add-customer-table" style={{width:"100%"}}>
  <tr>
    <th className="table-head">Attributes</th>
    <th className="table-head">Attribute Value</th>
  
    <th className="table-head">Attribute Attachment </th>
   
  </tr>
  {this.state.entry.attributes!=undefined ? this.state.entry.attributes.map((attribute, i) => (
         
         <tr>
            <td className="table-item">
             {attribute.name}
       </td>
         <td className="table-item">{attribute.value}</td>
         <td className="table-item"> 
         <a href={attribute.attachment} download className='form-text cell'>Download</a> </td>
         
         </tr>
         
        
         )) : ''}
 
 
</table></div></div></div> :''}





{ sliderState== 5 ? <div className="container row">
  <div className="col-md-12">
    <div className="row" style={{marginBottom:'-20px'}}>
      <label className="control-label add-header col-md-12">
        Team
      </label>
    </div>

    <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Team Lead
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.teamLead!=undefined ? entry.teamLead.name : 'NA'} </h4>
                                </div>
                              </div>
  
    <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Team:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields"><h2 className="head-mail">
      <ul>
                  {entry.team!=undefined ? entry.team.map((member)=>{

                    return (<li className="team-li">{member.name}</li>)

                  }) : 'NA'

                  }
                  </ul></h2></h4>
                                </div>
                              </div>
    

    </div> 
    </div> 
    : ''}


{superSliderState== 'access' ?

<div className="row">
   <h4 className="account-head">Masters Access</h4>
   <div className="col-md-8">
   
     <table className="add-customer-table" style={{ width: "100%" }}>
       <tr>
         <th className="table-head">Module Name</th>
         <th className="table-head">Add</th>
   
         <th className="table-head">Edit </th>
         <th className="table-head">View </th>
         <th className="table-head">Delete </th>
   
   
       </tr>
   
       {this.state.entry.modules.map((module, i) => (
   
         <tr>
   
           <td className="table-item">
             {module.module}
           </td>
           <td className="table-item"><input 
           disabled={true} type="checkbox" name="canAdd" value={this.state.entry.modules[i].canAdd} checked={this.state.entry.modules[i].canAdd}  /></td>
           <td className="table-item"><input 
           disabled={true} type="checkbox" name="canEdit" value={this.state.entry.modules[i].canEdit} checked={this.state.entry.modules[i].canEdit}  /></td>
           <td className="table-item"><input 
            type="checkbox" name="canView"
            disabled={true}
             value={this.state.entry.modules[i].canView} checked={this.state.entry.modules[i].canView}  /></td>
           <td className="table-item"><input 
           disabled={true} type="checkbox" name="canDelete" value={this.state.entry.modules[i].canDelete} checked={this.state.entry.modules[i].canDelete}  /></td>
         </tr>
   
   
       ))}
   
   
   
     </table></div></div>
   
   
:''}



            </div>
            

         


          </div>
          
        </div>


        
      </div>
    );
  }
}

