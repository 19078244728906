import React, { Component } from "react";
import "../../../style/panel.css";
import {BrowserRouter, Route} from 'react-router-dom';
import { Link } from "react-router-dom";
import $ from "jquery";

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";


import BreadCrumbs from '../../BreadCrumbs';

import sendMail  from './sendMail';
import pageMail  from './pageMail';
import SidebarMail  from './SidebarMail';
import viewMail  from './viewMail';
import viewSentMail  from './viewSentMail';
import viewTemplate  from './viewTemplate';
import addTemplate  from './addTemplate';
import pageTemplate  from './pageTemplate';
import editTemplate  from './editTemplate';
import Dashboard from './Dashboard';
import viewCommunicationTemplate from './viewCommunicationTemplate';
import addComunicationTemplate from './addComunicationTemplate';

const data = [];
export default class communicationMainapp extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []
     
    };
    
  }
  componentDidMount(){
    this.handleLine();
    //this.setState({title:this.props.match.params.productType});
  }
  handleLine(){
    
   
    const tab1= this.refs.tab1;
    const sidebar1= this.refs.sidebar1;
    const section= this.refs.section;
   
    
    $(function(){
      $("#tab1").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
        $("#section").toggle();
      });
      });
     
  }
  
  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
      <div className="mainDiv" >
       
       <div className="row">
         <div className="col-md-2">
         <SidebarMail title={this.state.title}/>
         </div>
         <div className="col-md-10">
         
      
<div className="section">

<Route exact path = "/communication-master/send/:mailType" component = {sendMail} />
<Route exact path = "/communication-master/view/page/:mailType/:id" component = {pageMail} />
<Route  exact path = "/communication-master/view/:mode/:mailType" component = {viewMail} />
<Route  exact path = "/communication-master/view/:mailType" component = {viewSentMail} />
<Route  exact path = "/communication-master/template/view" component = {viewTemplate} />
<Route  exact path = "/communication-master/template/add" component = {addTemplate} />
<Route  exact path = "/communication-master/template/edit/:id" component = {editTemplate} />
<Route  exact path = "/communication-master/template/page/:id" component = {pageTemplate} />


<Route  exact path = "/communication-master/view/template" component = {viewCommunicationTemplate} />
<Route  exact path = "/communication-master/add/template" component = {addComunicationTemplate} />

<Route  exact path = "/communication-master" component = {Dashboard} />


</div>

         </div>
       </div>
      
     </div>

    );
  }
}
