import React, { useEffect, useState } from "react";
import clients from "./client.json";
import teams from "./teams.json";
import axios from "axios";
import BreadCrumbs from "../BreadCrumbs";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { v4 as uuidv4 } from "uuid";
import Alert from "./Alert";
export default function AddSmartDoc() {
  const [file, setFile] = useState(null);
  const [alert, setAlert] = useState(null);
  const [smartDocData, setSmartDocData] = useState({
    docName: null,
    docOverview: null,
    selectedClient: null,
    sharedToClient: [],
    sharedToTeams: [],
    fileURL: null,
    attributes: null,
    tags: null,
    sections: [],
    template: false,
  });
  const encodeFileBase64 = (file) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var Base64 = reader.result;
        setFile(Base64);
      };
      reader.onerror = (error) => {
        console.log("error: ", error);
      };
    }
  };
  useEffect(() => {
    if (file != null) {
      sendFileToS3();
      setFile(null);
    }
  }, [file]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setSmartDocData((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const onMultiSelect = (event, name) => {
    setSmartDocData((prev) => {
      return { ...prev, [name]: event };
    });
  };
  const resetInputs = () => {
    setSmartDocData(() => {
      return {
        smartDocId: null,
        docName: "",
        docOverview: "",
        selectedClient: "",
        sharedToClient: [],
        sharedToTeams: [],
        fileURL: null,
        attributes: null,
        tags: null,
        template: false,
        sections: [],
      };
    });
  };

  const sendData = async (event) => {
    event.preventDefault();
    try {
      var { data } = await axios({
        url: " https://vdz44wruzh.execute-api.us-east-1.amazonaws.com/live/businusquery/SmartDoc",
        method: "POST",
        data: {...smartDocData,
        spId: localStorage.spId,
        },
      });
      setAlert({ msg: "Data sent Sucessfull!!", type: "alert-success" });
      resetInputs();
    } catch (err) {
      setAlert({ msg: "Something went wrong!!", type: "alert-danger" });
    }
  };

  const sendFileToS3 = async () => {
    //write a code to convert file object to base64 string
    const dataToSend = {
      base64String: file.split("base64,")[1],
      filename: uuidv4() + ".docx",
    };

    // Sendfile to S3
    try {
      var { data } = await axios({
        url: "https://dhyvnotx46.execute-api.us-east-1.amazonaws.com/live/smart-doc/upload",
        method: "POST",
        data: dataToSend,
      });
      setSmartDocData((prev) => {
        return { ...prev, fileURL: data.Location };
      });
      getAttributesOfFile(data.Location);
    } catch (err) {}
  };

  const getAttributesOfFile = async (fileURL) => {
    const { data } = await axios
      .get(`https://vdz44wruzh.execute-api.us-east-1.amazonaws.com/live/businusquery/getDocAttributes/`, {
        params: {
          docURL: fileURL,
        },
      })
      .catch((err) => {});
    setSmartDocData((prev) => {
      return { ...prev, attributes: data };
    });
  };

  return (
    <div className="Div">
      <div className="row breadcrumb-row">
        <div className="col-md-10">
          <BreadCrumbs />
        </div>
        <div className="col-md-2"></div>
      </div>
      <div className="row">
        <div className="dedicated-page" style={{ padding: "0px" }}>
          <div className="row-resource-head">
            <h2 className="resource-head">Add SmartDoc</h2>
          </div>

          <div className="row ">
            {alert && <Alert msg={alert.msg} type={alert.type} />}

            <form onSubmit={sendData}>
              <div className="col-md-12 info-column account-col2">
                <div id="field">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="form-group">
                          <label className="control-label col-md-3">
                            Add Word File:
                          </label>
                          <div className="col-md-7">
                            <input
                              onChange={(event) => {
                                encodeFileBase64(event.target.files[0]);
                              }}
                              class="form-control form-control-sm"
                              id="myfile"
                              type="file"
                              
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-3">
                            Doc Name
                          </label>
                          <div className="col-md-7">
                            <input
                              name="docName"
                              onChange={handleOnChange}
                              value={smartDocData.docName}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-3">
                            Doc Overview
                          </label>
                          <div className="col-md-7">
                            <input
                              name="docOverview"
                              onChange={handleOnChange}
                              value={smartDocData.docOverview}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>

                        <span>
                          <div className="form-group">
                            <label className="control-label col-md-3">
                              Client:
                            </label>
                            <div className="col-md-7">
                              <select
                                name="selectedClient"
                                onChange={handleOnChange}
                                value={smartDocData.selectedClient}
                                className="form-control ember-text-field ember-view"
                                type="text"
                              >
                                <option value="">Select Client</option>
                                {clients.map((client, i) => {
                                  return (
                                    <option value={client.id}>
                                      {client.customerId} {client.companyName}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="control-label col-md-3">
                              Shared To (Client Contacts)
                            </label>
                            <div className="col-md-7">
                              {smartDocData.selectedClient ? (
                                clients.map((client) => {
                                  if (
                                    client.id === smartDocData.selectedClient
                                  ) {
                                    return (
                                      <Select
                                        value={smartDocData.sharedToClient}
                                        onChange={(event) => {
                                          onMultiSelect(
                                            event,
                                            "sharedToClient"
                                          );
                                        }}
                                        options={client.primaryContact}
                                        isMulti={true}
                                      />
                                    );
                                  }
                                })
                              ) : (
                                <input
                                  name="subject"
                                  disabled="true"
                                  value="Select Client Above"
                                  className="form-control ember-text-field ember-view"
                                  type="text"
                                />
                              )}
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="control-label col-md-3">
                              Shared With (Team):
                            </label>
                            <div className="col-md-7">
                              {teams ? (
                                <Select
                                  value={smartDocData.sharedToTeams}
                                  onChange={(event) => {
                                    onMultiSelect(event, "sharedToTeams");
                                  }}
                                  options={teams}
                                  isMulti={true}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="control-label col-md-3">
                              Tags
                            </label>
                            <div className="col-md-7">
                              <CreatableSelect
                                isMulti
                                onChange={(event) => {
                                  onMultiSelect(event, "tags");
                                }}
                                // options={this.state.tags}
                                value={smartDocData.tags}
                              />
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>

                    <div className="text-left">
                      <button
                        type="submit"
                        // disabled={this.state.isSubmitClicked}
                        className="button1"
                      >
                        Submit
                        {/* {this.state.isSubmitClicked ? "Please Wait..." : "Create"} */}
                      </button>
                    </div>
                    {/* this says that if editing state is on then the Function saveAccountDetails will be called */}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
