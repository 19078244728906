import React, { Component } from "react";
import "../../../style/panel.css";
import {BrowserRouter, Route} from 'react-router-dom';
import { Link } from "react-router-dom";
import $ from "jquery";
import updateDepartment from "./updateDepartment";
import updateDesignation from "./updateDesignation";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";


import BreadCrumbs from '../../BreadCrumbs';
import Dashboard  from './Dashboard';
import addEmployee  from './addEmployee';
import pageEmployee  from './pageEmployee';
import viewEmployee  from './viewEmployee';
import SidebarEmployee from './SidebarEmployee';
import addDepartment from "./addDepartment";
import viewDepartments from "./viewDepartments";
import addDesignation from "./addDesignation";
import viewDesignations from "./viewDesignations";
import updateEmployee from "./updateEmployee";


const data = [];
export default class EmployeeMainapp extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []
     
    };
    
  }
  componentDidMount(){
    this.handleLine();
    //this.setState({title:this.props.match.params.productType});
  }
  handleLine(){
    
   
    const tab1= this.refs.tab1;
    const sidebar1= this.refs.sidebar1;
    const section= this.refs.section;
   
    
    $(function(){
      $("#tab1").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
        $("#section").toggle();
      });
      });
     
  }
  
  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
      <div className="mainDiv" >
       
       <div className="row">
         <div className="col-md-2">
         <SidebarEmployee title={this.state.title}/>
         </div>
         <div className="col-md-10">
         
      
<div className="section">

<Route exact path = "/masters/employee/add-employee" component = {addEmployee} />

<Route exact path = "/masters/employee/view-employee" component = {viewEmployee} />

<Route  exact path = "/masters/employee/view-employee/:id" component = {pageEmployee} />
<Route  exact path = "/masters/employee/edit-employee/:id" component = {updateEmployee} />

<Route  exact path = "/masters/employee/edit-designation/:id" component = {updateDesignation} />

<Route exact path = "/masters/employee/add-department" component = {addDepartment} />
<Route exact path = "/masters/employee/edit-department/:id" component = {updateDepartment} />
<Route exact path = "/masters/employee/view-department" component = {viewDepartments} />

<Route exact path = "/masters/employee/add-designation" component = {addDesignation} />

<Route exact path = "/masters/employee/view-designation" component = {viewDesignations} />
<Route exact path = "/masters/employee" component = {Dashboard} />

</div>

         </div>
       </div>
      
     </div>

    );
  }
}
