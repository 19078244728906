import React, { Component } from "react";
import "../../style/panel.css";
import { Link } from "react-router-dom";
import $ from "jquery";



const data = [];
export default class ToolsPage extends Component {
  constructor(props) {
    super(props);
   
    this.state = {
     
    };
  }
  componentDidMount() {
   
  }

  componentWillReceiveProps(newProps) {
   
  }

  toggleState(com) {
    this.setState({
      pageName: com,
    });
  }

  

  render() {
    return (
      <div>
        <div className="mainScreen">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="tab-row1">
                  <h2 className="tab-row-head">Tools</h2>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="tab text-center">
                        <Link to="/tools/calculator">
                          <i
                            className="	fa fa-users tab-image1"
                            style={{ fontSize: "40px" }}
                          ></i>
                          <h2 className="tab-text-main">Calculators</h2>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
