import React, {Component} from 'react';
import axios from 'axios';

export default class ProfessionalBookingDedicated extends Component{
    constructor(props){
        super(props)
        this.state={
            bookingDetail:[],
            bookingTime:'',
            bookingDate:"",
            customerDetail:[]
        }
        this.fetchBookingById = this.fetchBookingById.bind(this)
    }

    componentWillMount(){
        let id = this.props.match.params.id
        this.fetchBookingById(this,id)
    }

    fetchBookingById(that,id){
        let time = ''
        return axios({
            url: "https://8cop2jp1c8.execute-api.us-east-1.amazonaws.com/dev/businus/booking/getBookingById/"+id,
            method: 'GET',
            crossDomain: true,
            responseType: 'json',
          }).then((response)=>{
              console.log('response',response.data.customerDetail.name)
              if(response.data.customerBookingDetail.bookingSlot.morning!==""){
                time = response.data.customerBookingDetail.bookingSlot.morning
              }
              else if(response.data.customerBookingDetail.bookingSlot.afternoon!==""){
                  time = response.data.customerBookingDetail.bookingSlot.afternoon
              }
              else if(response.data.customerBookingDetail.bookingSlot.evening!==""){
                  time = response.data.customerBookingDetail.bookingSlot.evening
              }
              that.setState({
                  bookingDetail:response.data,
                  bookingTime:time,
                  bookingDate:response.data.customerBookingDetail.date,
                  customerDetail:response.data.customerDetail,
                  createdBy:response.data.createdBy
                })
          })
    }

    render(){
        const {customerDetail} = this.state
        return(
            <div>
                <div className='container' style={{marginTop:'2%'}}>
                    <div className="row profile-card-dedicatedPage">
                        <div className='col-md-6 col-lg-6 col-sm-12' style={{paddingBottom:'2%'}}>
                            <h3 style={{color:'#0069ff'}}> {customerDetail.name} </h3>
                            <div>
                                <h4 className='col-md-6 col-lg-6 col-sm-6 font-15'> Email : </h4>
                                <h4 className='col-md-6 col-lg-6 col-sm-6 font-15'> {customerDetail.email} </h4>
                            </div>
                            <div>
                                <h4 className='col-md-6 col-lg-6 col-sm-6 font-15'> Contact : </h4>
                                <h4 className='col-md-6 col-lg-6 col-sm-6 font-15'> {customerDetail.number} </h4>
                            </div>
                            <div>
                                <h4 className='col-md-6 col-lg-6 col-sm-6 font-15'> Booking Date : </h4>
                                <h4 className='col-md-6 col-lg-6 col-sm-6 font-15'> {this.state.bookingDate} </h4>
                            </div> 
                            <div>
                                <h4 className='col-md-6 col-lg-6 col-sm-6 font-15'> Booking Time : </h4>
                                <h4 className='col-md-6 col-lg-6 col-sm-6 font-15'> {this.state.bookingTime} </h4>
                            </div>
                            <div>
                                <h4 className='col-md-6 col-lg-6 col-sm-6 font-15'> Created By : </h4>
                                <h4 className='col-md-6 col-lg-6 col-sm-6 font-15'> {this.state.createdBy} </h4>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}