import $ from "jquery";
import React, { Component } from "react";
import { Route } from 'react-router-dom';
import "../../../style/panel.css";
import addTasks from "./addTask";
import addTaskCategory from "./addTaskCategory";

import Dashboard from './Dashboard';
import editTasks from "./editTask";
import pageTask from "./pageTask";
import pageTaskCategory from "./pageTaskCategory";

import SidebarTask from "./SidebarTask";
import viewTask from "./viewTask";
import viewTaskCategory from "./viewTaskCategory";

















const data = [];
export default class TaskMainApp extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []

    };

  }
  componentDidMount() {
    this.handleLine();
    //this.setState({title:this.props.match.params.productType});
  }
  handleLine() {


    const tab1 = this.refs.tab1;
    const sidebar1 = this.refs.sidebar1;
    const section = this.refs.section;


    $(function () {
      $("#tab1").click(function () {
        $("#section").toggle();
      });
    });

  }

  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
      <div className="mainDiv" >

        <div className="row">
          <div className="col-md-2">
            <SidebarTask title={this.state.title} />
          </div>
          <div className="col-md-10">


            <div className="section">
            {/* <Route exact path="/tasks/home" component={Home} /> */}
            <Route exact path="/tasks" component={Dashboard} />

            <Route exact path="/tasks/view-tasks/:id" component={pageTask} />
              <Route exact path="/tasks/edit-tasks/:id" component={editTasks} />
              <Route exact path="/tasks/add-tasks" component={addTasks} />
              <Route exact path="/tasks/view-tasks" component={viewTask} />


            <Route exact path="/tasks/view-tasksCategory/:id" component={pageTaskCategory} />
            <Route exact path="/tasks/add-tasksCategory" component={addTaskCategory} />
            <Route exact path="/tasks/view-tasksCategory" component={viewTaskCategory} />

           
  
         {/*      <Route exact path="/tasks/view-tasks/:id" component={pagetasks} />
              <Route exact path="/tasks/edit-tasks/:id" component={edittasks} />
              <Route exact path="/tasks/add-tasks" component={addtasks} />
              <Route exact path="/tasks/view-tasks" component={viewtasks} />
              <Route exact path = "/tasks/view-tasks/:docType/:id" component = {PageResourcetasks} />
              <Route exact path = "/tasks/view-tasks/checklist/page/:id" component = {pageChecklistWrapper} />
              <Route exact path="/tasks/view-tasksTemplate/:id" component={pagetasksTemplate} />
              <Route exact path="/tasks/add-tasksTemplate" component={addtasksTemplate} />
              <Route exact path="/tasks/view-tasksTemplate" component={viewtasksTemplate} />
  
              <Route exact path="/tasks/view-updateTemplate/:id" component={pageUpdateTemplate} />
              <Route exact path="/tasks/view-template/:id" component={pagetasksTemplate} />
              <Route exact path="/tasks/edit-template/:id" component={edittasksTemplate} />
              <Route exact path="/tasks/edit-updateTemplate/:id" component={edittasksUpdateTemplate} />
              <Route exact path="/tasks/add-updateTemplate" component={addtasksUpdateTemplate} />
              <Route exact path="/tasks/view-updateTemplate" component={viewtasksUpdateTemplate} />
              <Route exact path="/tasks-mail/:id" component={Assingmentdedicatedmailpage} />
  */}


            </div>

          </div>
        </div>

      </div>

    );
  }
}
