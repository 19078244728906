import axios from "axios";
import React, { Component } from "react";
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { Link } from "react-router-dom";
import { ToastsStore } from 'react-toasts';
import { apiBaseUrl2 } from '../../apiUtils';
import ReactQuill from 'react-quill'; // ES6
import { apiBaseUrl1, apiBaseUrl3 } from '../../apiUtils';
import AddDoc from './AddDoc';
import _ from 'underscore';
import $ from 'jquery';
import ResourceUserCommunication from '../../common/ResourcesUserCommunication';

let context = null;
const data = [];
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    minHeight:'250px',
    transform: 'translate(-50%, -50%)',
    padding: '0px ',
    position: 'Absolute',
    zIndex: '30000',
    backgroundColor: 'none',
    width: '30%'
  }
};

const customStylesDoc = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px ',
    position: 'Absolute',
    zIndex: '30000',
    backgroundColor: 'none',
    width: '70%',
    height: '70vh'
  }
};

export default class WorkflowSharedForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thread: [],
      timeline: [],
      deleteIndex: 0,
      whichColumn: '',
      description:null,
      sliderState: 1,
      currentRow: 0,
      superSliderState: 'additional',
      mail: {
        contactPersons: [],
      },
      entry: {
        workFlowList: [],
      },
      workflowColumns: [],
      workflowRow: {
        isEdit: true,
        attachment: [],
        url: [],
        doc: [],
        comment: [],
        checklist: ""
      },
      isEdit: false
    };
    context = this;

    setTimeout(()=>{
      $("#applicationHeader").hide();
    },0)
  
  }


componentWillReceiveProps(){
  setTimeout(()=>{
    $("#applicationHeader").hide();
  },0)
}
  componentDidMount() {
    setTimeout(()=>{
      $("#applicationHeader").hide();
    },0)
    const id = this.props.match.params.id;
    const workflowTableName = this.props.match.params.workflowName;
    const workflowTableIndex = this.props.match.params.workflowIndex;
    const workflowTableRow = this.props.match.params.row;
    this.setState({ workflowTableName, workflowTableIndex, workflowTableRow, id });
    this.fetchQueryById(this, id,workflowTableIndex);
    // this.fetchThreadById(this, id);
    //this.markAsRead(this,id);
  }




  addRessource(query, type) {

    var doctype = type.charAt(0).toUpperCase() + type.slice(1);
    axios
      .post(
        apiBaseUrl3 + 'businus/workflowDoc/add',
        {

          name: query.urlTitle || query.name,
          url: query.urlTxt,
          description: "",
          "attachment": query.attachment,
          "attachmentName": query.name,
          "from": "admin",
          "docType": doctype,               
          "by": localStorage.userName,
          workflowId: context.state.id,
          workflowName: context.state.workflowTableName,
          date: new Date().getTime()
        })
      .then(function (response) {
        debugger;
        // alert("SENT");

        let workflowRow = context.state.workflowRow;
        query.id = response.data.id;
        workflowRow[type].push(query);

        context.setState({ urlTxt: '', urlTitle: '', workflowRow: workflowRow, whichModal: 'none' });

        context.generateTimeline(workflowRow);
        context.sendMail();


      })
      .catch(function (error) {
        console.log(error);
      });
  }



  fetchQueryById(that, id,workflowTableIndex) {
    axios
      .get(

        apiBaseUrl1 + "businus/getWorkflowForSharedForm/" +
        id+"/"+workflowTableIndex
      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        
        that.setState({ workflowColumns:response.data.workflowColumns });
      //  that.getWorkflowRowDetails(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  delete = (id) => {
    var result = window.confirm("Are you sure you want to delete?");
    if (result == true) {
      axios
        .delete(
          apiBaseUrl1 + "businusquery/getOrganisationById/" +
          id
        )
        .then(function (response) {
          debugger;
          console.log(response.data);
          alert('Organisation has been deleted');
          // that.setState({ entry: response.data });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }




  sendMail = (index) => {
    debugger;
    ToastsStore.info('Submitting...');
    var entry = context.state.entry;
    const workflowTableIndex = this.props.match.params.workflowIndex;
    const workflowColumns  = this.state.workflowColumns;
    //const workflowTableRow = this.props.match.params.row;
    
      let row = this.state.workflowRow;
      
     
   let requiredCols =   _.where(workflowColumns,{isRequired:"Required"});
     
   requiredCols = _.chain(requiredCols)
   .map(function(item){ return item.id })
   .uniq()
   .value();


   let isInvalidForm = false;

   _.each(requiredCols,function(req){

            if(!row[req] || row[req]==""){

                isInvalidForm = true;

              }
             
  
  });


  if(isInvalidForm){
    alert("All Fields with * are required")
    return ;
  }

     
    


    axios
      .post(
        apiBaseUrl1 + "businus/submitWorkflowForm/" +context.state.id + "/" + context.state.workflowTableIndex ,
        {
        ...row
        })
      .then(function (response) {
        debugger;
        ToastsStore.success('Form Submitted');
        
        //context.fetchThreadById(context, entry.id);

       setTimeout(()=>{window.location.reload()},500);

      })
      .catch(function (error) {
        //toastr.error(error);
        console.log(error);
      });
  }



  saveResourceToList = (resources)=>{


    let workflowRow = this.state.workflowRow;
    workflowRow = {
      ...workflowRow,
      ...resources
    }

    this.setState({workflowRow});
    this.sendMail();




  }

  saveToWorkflowList = (workflow, index) => {

    let entry = this.state.entry;

    entry.workFlowList[index] = workflow;


    this.setState({ entry: entry });
    ToastsStore.success('Workflow Saved to Workflow List');
  }


  /* getWorkflowRowDetails = (Workflow) => {
    const workflowTableIndex = this.props.match.params.workflowIndex;
    const workflowTableRow = this.props.match.params.row;

    let workflowColumns = Workflow.workFlowList[workflowTableIndex].workflowColumns;
    //let workflowRow = Workflow.workFlowList[workflowTableIndex].workflow[workflowTableRow];

    this.setState({ workflowColumns });
    /* this.generateTimeline(workflowRow); 








  } */


  changeSliderState(orderNumber) {

    this.setState({ sliderState: orderNumber });

  }


  openModal = (whichModal) => {


    this.setState({ whichModal: whichModal });



  }

  getFiles(files) {
    this.setState({ selectedFile: files[0] });

  }

  closeModal = () => {
    this.setState({ whichModal: 'none' });
  }


  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2 + "businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });




          let obj = {
            name: that.state.selectedFile.name,
            attachment: "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name,
            by: localStorage.userName,
            description:that.state.description,
            date: new Date().getTime(), type: "ATTACHMENT"
          }



          that.setState({   description:''});



          context.addRessource(obj, 'attachment')


          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }


  fileUploadHandlerForCustom = (that, columnName) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2 + "businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {

          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });

          let workflowRow = that.state.workflowRow;
          workflowRow[columnName] = "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name;

          that.setState({ workflowRow: workflowRow });
          ToastsStore.success('File Uploaded');
          //that.sendMail();

          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getFormattedDate(millis) {

    return new Date(millis).toDateString();


  }
  getFormattedTime(millis) {

    return new Date(millis).toLocaleTimeString();


  }
  deleteRow = (index) => {
    var result = window.confirm("Are you sure you want to delete?");
    if (result == true) {

    
    var entry = context.state.entry;
    var workflowTableIndex = this.props.match.params.workflowIndex;
    let workflowTableRow = this.props.match.params.row;
    debugger
    workflowTableRow = parseInt(workflowTableRow);
     workflowTableIndex = parseInt(workflowTableIndex);
    entry.workFlowList[workflowTableIndex].workflow.splice(workflowTableRow,1)
    

    axios
      .post(
        apiBaseUrl1 + "businus/workflow/update/" + entry.id,
        {
          workFlowList: entry.workFlowList
        })
      .then(function (response) {
        debugger;
        ToastsStore.success('Workflow Row Deleted');
        //context.fetchThreadById(context, entry.id);

      })
      .catch(function (error) {
        //toastr.error(error);
        console.log(error);
      });

    }
  }

  addComment = (comment) => {



    let workflowRow = this.state.workflowRow;
    workflowRow.comment.push({
      comment: comment,
      by: localStorage.userName,
      date: new Date().getTime(),
      type: "COMMENT"
    });

    this.setState({ commentTxt: '', workflowRow: workflowRow, whichModal: 'none' });

    this.generateTimeline(workflowRow);

    this.sendMail();


  }

  addUrl = (urlTxt, urlTitle) => {





    let obj = {
      urlTxt: urlTxt,
      urlTitle: urlTitle,
      description:this.state.description,
      by: localStorage.userName,
      date: new Date().getTime(),
      type: "URL"
    };


    /// workflowRow.url.push();


    this.addRessource(obj, 'url')
    this.setState({description:''});


  }


  generateTimeline = (workflowRow) => {

    let timeline = [];


    $.merge(timeline, workflowRow.attachment);
    $.merge(timeline, workflowRow.doc);
    $.merge(timeline, workflowRow.comment);
    $.merge(timeline, workflowRow.url);



    timeline = _.sortBy(timeline, (t) => {


      return -1 * t.date;

    })


    this.setState({ timeline });





  }


  assignDocToWorkflow = (doc) => {

    let workflowRow = this.state.workflowRow;
    workflowRow.doc.push({
      workflowDocId: doc.workflowDocId,
      id: doc.id,
      name: doc.heading,
      by: localStorage.userName,
      type: "DOC",
      date: new Date().getTime()
    });

    this.setState({
      urlTxt: '',
      urlTitle: '',
      workflowRow: workflowRow,
      whichModal: 'none'
    });

    this.generateTimeline(workflowRow);
    this.sendMail();
  }

  handleOnChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }


  handleOnChangeEdit = (e) => {
    let workflowRow = this.state.workflowRow;
    workflowRow[e.target.name] = e.target.value;


    this.setState({ workflowRow });
  }



  saveForm = () =>{


  }

  deleteItem = (deleteAt, whichCol) => {
    var result = window.confirm("Are you sure you want to delete?");
    if (result == true) {

    let workflowRow = this.state.workflowRow;

    let columnData = workflowRow[whichCol];


    columnData.splice(deleteAt, 1);

    workflowRow[whichCol] = columnData;

    ToastsStore.warning('Deleting ' + whichCol + '..., Auto save in progress');
    this.setState({ workflowRow });
    this.setState({ whichModal: 'none' });
    this.sendMail();
    this.generateTimeline(workflowRow);
    }
  }

  beforeDelete = (deleteIndex, whichColumn) => {

    this.setState({ whichModal: 'deleteModal', deleteIndex, whichColumn });

  }


  getCellUi(wfName, wfType) {

    let wf = { name: wfName, type: wfType }

    return <span>

      {(wf.type == "text" || wf.type == "url" || wf.type == "number" || wf.type == "date") ?

        <input
          className="workflow-input"
          name={wf.name}
          value={this.state.workflowRow[wf.name]}
          onChange={this.handleOnChangeEdit}
        
          type={wf.type}
        />

        :

        wf.type == "file" ?

          <div >
            <div className=" workflow-input1">

              <div className="col-md-7 upload-box " > <FileBase64
                multiple={true}
                onDone={this.getFiles.bind(this)} /></div>

              <div className="col-md-4">

                <button className="btn btn-primary attachment" onClick={() => this.fileUploadHandlerForCustom(this, wf.name)}
                  value="Select a File">
                  <i className="fa fa-paperclip" aria-hidden="true"></i>
                </button>


                <a className="btn btn-primary attachment" href={this.state.workflowRow[wf.name]} download="download" >
                  <i className="fa fa-download" aria-hidden="true"></i></a>

              </div>
            </div>
          </div>
          :
          wf.type == "textarea" ?


            <textarea
            className="workflow-input"
              name={wf.name}
              style={{ width: '100%' }}
              value={this.state.workflowRow[wf.name]}
              onChange={this.handleOnChangeEdit}
              onBlur={this.sendMail}
            />

            : ''}
    </span>








  }

  
componentWillUnmount(){
$("#applicationHeader").show()
}


  render() {
    const { sliderState, entry, superSliderState, workflowTableName, workflowTableIndex, workflowTableRow, workflowRow, workflowColumns } = this.state;
    const active = " active-button"
    const deactive = "";


    return (
      

 
       <div className="container">
         <div className="row"> 
         <div className="navbar-header">
             
             <img id="1" className="navbar-brand"
               style={{     margin: '20px 0px',width:"180px",height:"65px" }}
               src={require("../../../images/teamDashboard.png")}
             />
            
 
           
          
          
         </div>

         </div>
         
        <div className="row card-shadow">
        <div>
    <div className="row-resource-head card-shadow">
                  <h2 className="resource-head">{this.state.entry.workflowId} : {workflowTableName} </h2>
                  </div>
            <div className="">
              <div  className="row ">


                <div className="col-md-10">
              
                  <div className="form-group">
                  
                    <div className="col-md-12">
                      <p className="bottom-border"
                     
                      >{this.state.entry.workflowOverview}</p>
                    </div>
                  </div>

                  {workflowColumns.map((wc, i) => {
              return wc.type!="action" ? <div className="form-group" >
                    <label className="control-label col-md-3">
                      {wc.label} {wc.isRequired=='Required' ? <span style={{color:'red'}}>*</span> : ''}
                            </label>
                    <div className="col-md-7">
                      {this.getCellUi(wc.name, wc.type,wc.isRequired)}
                    </div>
                  </div> :''
                }
                )}

           
     

                 
                </div>
       
              </div>
           

         


              <div className="text-center"><button type="button"  onClick={this.sendMail}  style={{float:'right'}} className="button1"> Submit </button></div>
                   


         





            </div>



          



       
          </div>







        </div>

    





        </div>
    );
  }
}

