import React, { Component } from "react";

import { Link } from "react-router-dom";

import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { ChatFeed, Message } from 'react-chat-ui'


import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from 'underscore';
let context = null;
const data = [];
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px ',
    position: 'Absolute',
    zIndex: '30000',
    backgroundColor: 'none',
  }
};

export default class updateDepartment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workEx: [],
      education:[],
      dependents:[],
      gender:"male",
      entry:{},
    };
    context = this;
    
  }


  handleOnChange = (e) => {
    debugger;
    var value = e.target.value;

    var formName = e.target.name;

    this.setState({ [formName]: value });



    console.log(this.state);
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.fetchQueryById(this, id);
    
   
  }



  sendMail() {
    var data = context.state.entry;
    axios
      .post(
        apiBaseUrl2+"businus/getEmployeePropsById/"+data.id,
        {

          "title": data.title,
          description:data.description

        })
      .then(function (response) {
        debugger;
        alert("Success!");
        //  context.fetchThreadById(context,query.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    localStorage.setItem("MAILURL", url);


  }

  fetchQueryById(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/getEmployeePropsById/"+id

      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }



  //updating the status button

  render() {
    const { sliderState } = this.state;
    const active = " active-button"
    const deactive = "";
    return (
      <div className="dedicated-div">
        <div className="row breadCrumb1-row">
          New Client
            </div>
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
          <div className="dedicated-page">
            <div className="row ">
              <div className="col-md-12 info-column account-col2">
                <div id="field">
                  <h2 className="account-head"> Update Department</h2>
                  <div className="container">
                    <div className="row">
                      <h4  className="account-head" > Basic info</h4>
                      <div className="col-md-8">


                    


                        <div className="form-group">
                          <label className="control-label col-md-4">
                           Department Title
                          </label>
                          <div className="col-md-6">
                            <input
                              id="ember301"
                              onChange={this.handleOnChange}
                              name="title"
                              value={this.state.entry.title}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">
                           Description
                          </label>
                          <div className="col-md-6">
                            <input
                              id="ember301"
                              onChange={this.handleOnChange}
                              name="description"
                              value={this.state.entry.description}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>

                       



                      </div>
                    </div>






                    


                    
                    
                  


                  

                        
                  </div>

                  <div className="text-left">
                    <button type="button" onClick={this.sendMail} className="button1">
                      {" "}
                      Submit{" "}
                    </button>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    );
  }
}

