import React, { Component } from "react";
import axios from "axios";
import "./profile.css";
import EditorJs from "react-editor-js";
import ReadOnly from "./profileReadOnly";
import { Link } from "react-router-dom";
import BreifReadOnly from "./breifReadOnly";
import GroupClientReview from "../ClientReview/GroupClientReview";
import AddClientReview from "../ClientReview/AddClientReview";
import BookingDetail from "./BookingDetail";
export default class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      tabContent: 0,
      profile: [],
      personalDetail: [],
      award: [],
      education: [],
      postEducation: [],
      graduationEducation: [],
      interEducation: [],
      highEducation: [],
      experience: [],
      skill: [],
      imageDetail: [],
      bio: [],
      breifBio: "",
      isEducation: true,
      isNextPage: false,
      coverImageUrl: "",
      serviceDetail: [],
      bookingDetail: [],
      profileImageUrl:
        "https://businus-files-live.s3.amazonaws.com/default-image/user-profile.jpg",
    };
    this.handleTab = this.handleTab.bind(this);
    this.fetchProfileById = this.fetchProfileById.bind(this);
    this.checkTabActive = this.checkTabActive.bind(this);
    this.breifBio = this.breifBio.bind(this);
    this.checkAbout = this.checkAbout.bind(this);
  }

  componentWillMount() {
    let id = this.props.match.params.id;
    localStorage.setItem("adminId", id);
    this.setState({ id: id });
    this.fetchProfileById(this, id);
  }

  // componentDidMount(){
  //     this.checkTabActive()

  // }

  async fetchProfileById(that, id) {
    await axios({
      url:
        "https://8cop2jp1c8.execute-api.us-east-1.amazonaws.com/dev/businus/profile/getProfileById/" +
        id,
      method: "GET",
      crossDomain: true,
      response: "JSON",
    }).then((response) => {
      //   console.log('response',response.data.personalDetail)
      let bio = response.data.personalDetail.bio;
      that.setState({
        bio: bio,
        isNextPage: bio !== undefined ? (bio.length > 8 ? true : false) : false,
        profile: response.data.personalDetail,
        personalDetail: response.data.personalDetail.personalDetail,
        award: response.data.personalDetail.award,
        education: response.data.personalDetail.education,
        postEducation: response.data.personalDetail.education.postGraduation,
        graduationEducation: response.data.personalDetail.education.graduation,
        interEducation: response.data.personalDetail.education.interSchool,
        highEducation: response.data.personalDetail.education.highSchool,
        skill: response.data.personalDetail.skill,
        experience: response.data.personalDetail.experience,
        imageDetail: response.data.personalDetail.personalDetail.profileDetail,
        serviceDetail: response.data.serviceDetail,
        bookingDetail: response.data.bookingDetail,
        profileImageUrl:
          response.data.personalDetail.personalDetail.profileDetail
            .profileImageId !== undefined
            ? response.data.personalDetail.personalDetail.profileDetail
                .profileImageUrl
            : this.state.profileImageUrl,
      });
    });
    this.breifBio(this);
    this.checkEducation(this);
    this.checkTabActive(this);
  }

  checkEducation(that) {
    let temp = true;
    if (that.state.highEducation.school === "") {
      if (that.state.interEducation.school === "") {
        if (that.state.graduationEducation.graduationCollege === "") {
          if (that.state.postEducation.postCollege === "") {
            temp = false;
          }
        }
      }
    }

    that.setState({ isEducation: temp });
    this.checkAbout(this, temp);
  }

  checkTabActive(that) {
    let isEducation = that.state.isEducation;
    let index = 3;
    if (isEducation) {
      index = 0;
    } else {
      if (that.state.experience.length !== 0) {
        index = 1;
      } else {
        if (that.state.skill.length !== 0) {
          index = 2;
        }
      }
    }
    this.handleTab(index);
    console.log("index", that.state.isEducation);
  }

  checkAbout(that, examCheck) {
    let checkAbout = true;
    if (that.state.bio.blocks === undefined) {
      if (examCheck) {
        if (that.state.experience.length === 0) {
          if (that.state.skill.length === 0) {
            if (that.state.award.length === 0) {
              checkAbout = false;
            }
          }
        }
      }
    }
    if (!checkAbout) {
      this.activeChange(1);
    }
    that.setState({ checkAbout: checkAbout });
  }

  breifBio(that) {
    let bio = this.state.bio;
    let allParagraphValue = "";

    if (bio.blocks !== undefined) {
      for (var i = 0; i < bio.blocks.length; i++) {
        if (bio.blocks[i].type === "paragraph") {
          allParagraphValue += bio.blocks[i].data.text;
          allParagraphValue += " ";
        }
      }
    }

    let breifBio = "";
    for (var i = 0; i < 350; i++) {
      if (allParagraphValue[i] !== undefined) {
        breifBio += allParagraphValue[i];
      } else {
        break;
      }
    }
    that.setState({ breifBio: breifBio });
  }

  handleTab(index) {
    let elem1 = document.getElementById(this.state.tabContent);
    if (elem1 !== null) {
      elem1.classList.remove("tab-d-active");
    }

    let elem2 = document.getElementById(index);
    if (elem2 !== null) {
      elem2.classList.add("tab-d-active");
    }

    this.setState({ tabContent: index });
  }

  activeChange(index) {
    for (var i = 0; i < 4; i++) {
      if (index !== i) {
        document.getElementById("tab_" + index).classList.remove("active");
      } else {
        document.getElementById("tab_" + index).classList.add("active");
      }
    }
  }

  render() {
    const { tabContent } = this.state;
    return (
      <div className="user-profile-container">
        <div
          className="user-background-image"
          style={{
            background:
              this.state.imageDetail.coverImageId !== undefined
                ? "url(" + this.state.imageDetail.coverImageUrl + ")"
                : "#2978d057",
          }}
        ></div>
        <div className="user-detail">
          {this.state.personalDetail.name !== undefined ? (
            <div className="row spacing-user-detail">
              <div className="col-md-3 col-lg-3 col-sm-12">
                <img
                  className="user-profile-image"
                  src={this.state.profileImageUrl}
                />
              </div>
              <div className="user-header-name ml-1">
                {this.state.personalDetail.name}
                {this.state.bookingDetail !== undefined ? (
                  <button type="submit" className="userConsult">
                    {" "}
                    <Link
                      to={"/consult/booking/" + this.state.id}
                      style={{ color: "white" }}
                    >
                      {" "}
                      Consult{" "}
                    </Link>
                  </button>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-5 col-lg-5 col-sm-6">
                <tr>
                  <td className="user-inf-t"> Experience : </td>
                  <td className="user-inf-d">
                    {" "}
                    {this.state.personalDetail.experience}{" "}
                  </td>
                </tr>
                <tr>
                  <td className="user-inf-t"> Practise & Area : </td>
                  <td className="user-inf-d">
                    {" "}
                    {this.state.personalDetail.practise}
                  </td>
                </tr>
                <tr>
                  <td className="user-inf-t"> Location : </td>
                  <td className="user-inf-d ">
                    {" "}
                    {this.state.personalDetail.location}{" "}
                  </td>
                </tr>
                <tr>
                  <td className="user-inf-t"> Languages : </td>
                  <td className="user-inf-d ">
                    {" "}
                    {this.state.personalDetail.language}{" "}
                  </td>
                </tr>
              </div>
              <p className="col-md-4 col-lg-4 col-sm-6 user-inf-a">
                {this.state.profile.bio.blocks !== undefined ? (
                  <div>
                    {/* <BreifReadOnly data={this.state.profile.bio}/> */}
                    <p>
                      {" "}
                      {this.state.breifBio}{" "}
                      <span>
                        <a href="#bio">Read more</a>
                      </span>{" "}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </p>
            </div>
          ) : (
            ""
          )}

          <div style={{ margin: "0 2%" }}>
            <div className="tab-content">
              <div className="row">
                <ul className="profile-tab-c col-md-3">
                  {this.state.checkAbout ? (
                    <li id="tab_0" className="profile-tab active">
                      <a href="#bio" onClick={() => this.activeChange(0)}>
                        {" "}
                        About{" "}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {/* </div>
                    <div className="profile-tab-c col-md-3"> */}
                  <li id="tab_1" className="profile-tab ">
                    <a
                      href="#clientReview"
                      onClick={() => this.activeChange(1)}
                    >
                      {" "}
                      Client Review{" "}
                    </a>
                  </li>
                  {this.state.serviceDetail !== undefined ? (
                    <li id="tab_2" className="profile-tab ">
                      <a href="#product" onClick={() => this.activeChange(2)}>
                        {" "}
                        Services{" "}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}

                  {/* <li id='tab_3' className='profile-tab '>
                           <a href="#booking" onClick={()=>this.activeChange(3)}> Booking </a>                            
                        </li> */}
                </ul>
              </div>
              {this.state.bio.blocks !== undefined ? (
                <div>
                  <div className="tab-heading-m " id="bio">
                    <div className="tab-heading px-4 w-12 py-1">About</div>
                  </div>

                  <div className="row about">
                    {this.state.profile.bio.blocks !== undefined ? (
                      <ReadOnly data={this.state.profile.bio} />
                    ) : (
                      ""
                    )}
                    {this.state.isNextPage ? (
                      <Link
                        style={{ float: "right" }}
                        to={"/about/user/" + this.state.id}
                      >
                        Read More
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="maintab">
              <div className="tab-ul">
                {this.state.isEducation ? (
                  <button
                    id={0}
                    onClick={() => this.handleTab(0)}
                    className="tab-dropdown"
                  >
                    Education
                  </button>
                ) : (
                  ""
                )}

                {this.state.experience.length !== 0 ? (
                  <button
                    id={1}
                    onClick={() => this.handleTab(1)}
                    className="tab-dropdown"
                  >
                    Experience
                  </button>
                ) : (
                  ""
                )}

                {this.state.skill.length !== 0 ? (
                  <button
                    id={2}
                    onClick={() => this.handleTab(2)}
                    className="tab-dropdown"
                  >
                    Skills
                  </button>
                ) : (
                  ""
                )}

                {this.state.award.length !== 0 ? (
                  <button
                    id={3}
                    onClick={() => this.handleTab(3)}
                    className="tab-dropdown"
                  >
                    Award List
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="maintab-content" style={{ marginLeft: "2%" }}>
              {tabContent === 0 ? (
                this.state.isEducation ? (
                  <div>
                    {this.state.postEducation.postCollege !== undefined ? (
                      <div>
                        <h5 className="maintab-content-t">Post Graduation :</h5>
                        <li className="maintab-content-st">
                          {" "}
                          College Name : {
                            this.state.postEducation.postCollege
                          }{" "}
                        </li>
                        <li className="maintab-content-st">
                          {" "}
                          Marks in {
                            this.state.postEducation.postMarkType
                          } is {this.state.postEducation.postMark}
                        </li>
                        <li className="maintab-content-st">
                          {" "}
                          Course :{" "}
                          {this.state.postEducation.courseTypePostGraduation}
                        </li>
                        <li className="maintab-content-st">
                          {" "}
                          Passing Year :{" "}
                          {this.state.postEducation.postPassingYear}
                        </li>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.graduationEducation.graduationCollege !==
                    undefined ? (
                      <div>
                        <h5 className="maintab-content-t">Graduation :</h5>
                        <li className="maintab-content-st">
                          {" "}
                          College Name :{" "}
                          {
                            this.state.graduationEducation.graduationCollege
                          }{" "}
                        </li>
                        <li className="maintab-content-st">
                          {" "}
                          Marks in{" "}
                          {
                            this.state.graduationEducation.graduationMarkType
                          } is {this.state.graduationEducation.graduationMark}
                        </li>
                        <li className="maintab-content-st">
                          {" "}
                          Course :{" "}
                          {this.state.graduationEducation.courseTypeGraduation}
                        </li>
                        <li className="maintab-content-st">
                          {" "}
                          Passing Year :{" "}
                          {this.state.graduationEducation.graduationPassingYear}
                        </li>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.interEducation.school !== undefined ? (
                      <div>
                        <h5 className="maintab-content-t">12th School :</h5>
                        <li className="maintab-content-st">
                          {" "}
                          College Name : {this.state.interEducation.school}{" "}
                        </li>
                        <li className="maintab-content-st">
                          {" "}
                          Marks in {this.state.interEducation.markType} is{" "}
                          {this.state.interEducation.mark}
                        </li>
                        <li className="maintab-content-st">
                          {" "}
                          Course : {this.state.interEducation.courseType}
                        </li>
                        <li className="maintab-content-st">
                          {" "}
                          Passing Year : {this.state.interEducation.passingYear}
                        </li>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.highEducation.school !== undefined ? (
                      <div>
                        <h5 className="maintab-content-t">10th School :</h5>
                        <li className="maintab-content-st">
                          {" "}
                          College Name : {this.state.highEducation.school}{" "}
                        </li>
                        <li className="maintab-content-st">
                          {" "}
                          Marks in {this.state.highEducation.markType} is{" "}
                          {this.state.highEducation.mark}
                        </li>
                        <li className="maintab-content-st">
                          {" "}
                          Course : {this.state.highEducation.courseType}
                        </li>
                        <li className="maintab-content-st">
                          {" "}
                          Passing Year : {this.state.highEducation.passingYear}
                        </li>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )
              ) : tabContent === 1 ? (
                this.state.experience.length !== 0 ? (
                  <div>
                    {this.state.experience.map((exp, index) => (
                      <div>
                        <h5 className="maintab-content-t">
                          Experience {index + 1} :
                        </h5>
                        <li className="maintab-content-st">
                          {" "}
                          Title : {exp.title}{" "}
                        </li>
                        <li className="maintab-content-st">
                          {" "}
                          Employement Type : {exp.employementType}{" "}
                        </li>
                        <li className="maintab-content-st">
                          {" "}
                          Company Name : {exp.companyName}{" "}
                        </li>
                        <li className="maintab-content-st">
                          {" "}
                          Location : {exp.location}{" "}
                        </li>
                        <li className="maintab-content-st">
                          {" "}
                          Start Date : {exp.startDate} & End Date: {exp.endDate}{" "}
                        </li>
                        <li className="maintab-content-st">
                          {" "}
                          Description {exp.description}
                        </li>
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )
              ) : tabContent === 2 ? (
                this.state.skill.length !== 0 ? (
                  <div>
                    <h5 className="maintab-content-t">Skills :</h5>
                    {this.state.skill.map((skill) => (
                      <li className="maintab-content-st"> {skill.skill} </li>
                    ))}
                  </div>
                ) : (
                  ""
                )
              ) : tabContent === 3 ? (
                this.state.award.length !== 0 ? (
                  <div>
                    <h5 className="maintab-content-t">Award :</h5>
                    {this.state.award.map((award) => (
                      <li className="maintab-content-st"> {award.award} </li>
                    ))}
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>
          <div style={{ margin: "2%" }}>
            <div className="tab-heading-client-c " id="clientReview">
              <div className="tab-heading px-4 w-12 py-1">Client Comment</div>
            </div>
            <AddClientReview profileId={this.state.id} />
          </div>
          {this.state.serviceDetail !== undefined ? (
            <div style={{ margin: "2%" }}>
              <div
                style={{ marginBottom: "2%" }}
                className="tab-heading-client-c "
                id="product"
              >
                <div className="tab-heading px-4 w-12 py-1">Services</div>
              </div>
              <div className="row">
                {this.state.serviceDetail.map((prod, index) => (
                  <div className="col-md-3 col-lg-3 col-sm-12">
                    <div className="all-profile-img-container">
                      <img
                        style={{ width: "20%", height: "40vh" }}
                        src={prod.serviceImageUrl}
                      />{" "}
                      <br />
                      <div className="product-hover-container">
                        <h5> {prod.serviceDescription} </h5>
                      </div>
                    </div>
                    <div>
                      <h4> {prod.serviceName} </h4>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            ""
          )}

          {/* {this.state.bookingDetail!==undefined?
                <div style={{margin:'2%'}}>
                    <div className='tab-heading-client-c' id="booking">
                        <div className='tab-heading px-4 w-12 py-1'>Booking</div>
                    </div>
                    <BookingDetail booking={this.state.bookingDetail} id={this.state.id} />
                </div>:""
            } */}
        </div>
      </div>
    );
  }
}
