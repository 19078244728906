import React, { Component } from 'react';
import reactCSS from 'reactcss'
import '../style/agency.css';

import { SketchPicker } from 'react-color';
import '../animations.css';



/**
 * ProductsSectionModal
 * It is the code for adding section of products  in the template .
 * @module ProductsSectionModal
 */
class ProductSectionModal extends Component {
  constructor(props){
    super(); 
    this.state ={
     title:"",
    products:""

    };
    this.saveProductSection = this.saveProductSection.bind(this);
   
    
   
    
   }
   
   componentDidMount(){



   }

   /**
 * onchange()
 * This segment of code allows state transition .
 * @function onchange()
 */

  onChange = (e) => {
    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);


    console.log(this.state);
  }


 /**
 * saveProductSection()
 * This segment of code opens style modal.
 * @function saveProductSection()
 */
  saveProductSection(){
     
      this.props.saveProductSection(this.state);
      this.props.toggleProductSectionModal();
    
    
   }

   



   
    render(){
     

    return(



<div className="modal-wrapper">
<div className="container">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <form>
              <div id="contact-form" className="form-container" data-form-container style={{color: 'rgb(46, 125, 50)', background:'white'}}>
              <div className="row modal-back-color ">
                <div className="closebtn" onClick={this.props.toggleStyleModal}><h5 style={{marginLeft:'729px', fontSize:'15px',fontWeight:'500'}}>X</h5></div>
                  <div className="form-title text-left" >
                    <h4 className="modal-head" > Add Product Section </h4>
                  </div>
                </div>
                <div className="input-container">
                <div className="row">
                    <div className="col-md-12">
                    <form id="field">
       
       <div className="row modalrow">
      <div className="col-md-3">

 
         Title :</div><div className="col-md-9 "> <input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.title} name="title"/><br/></div></div>
         <div className="row modalrow">
      <div className="col-md-3">    No. of Cards :</div><div className="col-md-9 "> <input className="fieldbox"  onChange={this.onChange} type="number" value={this.state.products} name="products"/><br/></div></div>
                    
                {/* <button type="button"   className="imagebutton"> Save </button>*/}
                   </form>
                      </div>
                      </div>
                  

                  <div className="row submit-row">
                    <div className="col-md-2"></div><div className="col-md-4">
                    <button type="button" onClick={()=>this.saveProductSection()} className="btn btn-block savebutton submit-form valid">Save</button>
                    </div>
                    <div className="col-md-4">
                    <button type="button" onClick={()=>this.props.toggleProductSectionModal()} className="btn btn-block cancelbutton submit-form valid">Cancel</button>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div></div>
      </div>
    );
  

    }
}

export default ProductSectionModal;