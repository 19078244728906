import React, { Component } from "react";
import { Link } from "react-router-dom";
import BreadCrumbs from "../../BreadCrumbs";
import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { ChatFeed, Message } from 'react-chat-ui'
import { validate } from '../../common/Validation';
import { Multiselect } from 'multiselect-react-dropdown';

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from 'underscore';
import { grey } from "ansi-colors";
let context = null;
const data = [];
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px ',
    position: 'Absolute',
    zIndex: '30000',
    backgroundColor: 'none',
  }
};

export default class EditAccessControlEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableModules: [{ name: "Select Module", onlyCanView: false },
      { name: "Client-Communication", onlyCanView: false },
      { name: "Team-Communication", onlyCanView: false },
      { name: "Workflow Master", onlyCanView: false },
      { name: "Resources Master", onlyCanView: false },
      { name: "Client", onlyCanView: false },
      { name: "Assignment", onlyCanView: false },
      { name: "Employee", onlyCanView: false },
      { name: "Knowledge Hub", onlyCanView: false },
      { name: "Access Control", onlyCanView: false },
        , { name: "Organisation", onlyCanView: false },
        , { name: "Tax+", onlyCanView: true },
        , { name: "Windup+", onlyCanView: true }
        , { name: "Comply+", onlyCanView: true }
        , { name: "Protect+", onlyCanView: true }
        , { name: "Incorporate+", onlyCanView: true }
        , { name: "Business Protection", onlyCanView: true }
        , { name: "Business Registration", onlyCanView: true }
        , { name: "Human Resource", onlyCanView: true }
        , { name: "Intellectual Property", onlyCanView: true }
        , { name: "Service Contract", onlyCanView: true }
        , { name: "Corporate", onlyCanView: true }
        , { name: "Startup", onlyCanView: true }
        , { name: "Social", onlyCanView: true }
        , { name: "Webzy", onlyCanView: true },
        , { name: "Issues", onlyCanView: true },
        , { name: "Logs", onlyCanView: true },
        , { name: "Tasks", onlyCanView: true },
        , { name: "professionals", onlyCanView: true },
        , { name: "appointments", onlyCanView: true },
      ],
      module: '',
      customerId: data.customerId,
      companyName: data.companyName,
      companyDisplayName: data.companyDisplayName,
      customerEmail: data.customerEmail,
      customerType: data.customerType,
      primaryContact: data.primaryContact,
      customerContact: data.customerContact,
      website: data.website,
      billingAddress: data.billingAddress,
      billingCountry: data.billingCountry,
      billingAttention: data.billingAttention,
      billingCity: data.billingCity,
      billingPhone: data.billingPhone,
      billingZipCode: data.billingZipCode,
      shippingAddress: data.shippingAddress,
      shippingCountry: data.shippingCountry,
      shippingAttention: data.shippingAttention,
      shippingCity: data.shippingCity,
      shippingPhone: data.shippingPhone,
      shippingZipCode: data.shippingZipCode,
      contactPersons: data.contactPersons,
      sliderState: 1,
      canCustomise: false,
      role: '',
      selectedModules: '',
      superSliderState: 'additional',
      bankName: data.bankName,
      branchName: data.branchName,
      accountNo: data.accountNo,
      accountType: data.accountType,
      ifscCode: data.ifscCode,
      micrCode: data.micrCode,
      entry: {
        modules: [{
          module: "",
          canEdit: false,
          canAdd: false,
          canView: false,
          canDelete: false
        }]
      },
      bankDetails: {},
      contactPersons: [{

        salutation: "",
        name: "",
        email: "",
        phone: ""

      }],
      employees: [],
      attributes: [],
      employees: []
    };
    context = this;

  }

  changeSliderState(orderNumber) {

    this.setState({ sliderState: orderNumber });

  }


  //function for changing the status button on click to open or close
  handleOnChange = (e) => {
    debugger;
    var value = e.target.value;

    var formName = e.target.name;

    this.setState({ [formName]: value });

    if (formName == 'role' && value == "customise") {
      this.setState({ canCustomise: true })
    }
    if (formName == 'role' && value == "admin") {
      this.setState({ canCustomise: false })
    }
    if (formName == 'role' && value == "employee") {
      this.setState({ canCustomise: false })
    }



    console.log(this.state);
  }

  onSelect(optionsList, selectedItem) {

    context.setState({ selectedModules: optionsList });

    console.log(optionsList, selectedItem);
  }

  onRemove(optionList, removedItem) {
    context.setState({ modules: optionList });
  }

  //function for changing the status button on click to open or close


  handleOnChangePerson = (e, i) => {
    var entry = this.state.entry;
    var modules = entry.modules;

    modules[i][e.target.name] = e.target.value;
    modules[i].onlyCanView = this.getPrivilegesByModuleName(e.target.value);
    entry.modules = modules;


    this.setState({ entry: entry });




  }


  checkIfModuleIsSelected = (currentModule, entryModules) => {

    if (_.findWhere(entryModules, { module: currentModule }) != undefined && currentModule!="Select Module") {

      return true;

    }
    else {
      return false;
    }





  }

  getPrivilegesByModuleName = (name) => {


    const { availableModules } = this.state;

    return _.findWhere(availableModules, { name: name }).onlyCanView;



  }

  handleOnChangeCheckbox = (e, i) => {

    var entry = this.state.entry;
    var modules = entry.modules;

    modules[i][e.target.name] = e.target.checked;

    entry.modules = modules;
    this.setState({ entry: entry });




  }







  componentDidMount() {

    const id = this.props.match.params.id;
    this.fetchQueryById(id)

  }





  //fetching the data by id which this file recieved from the list

  changeSuperSliderState(name) {

    this.setState({ superSliderState: name });
    if (name === 'additional') {
      this.setState({ sliderState: 1 });
    }
    else {
      this.setState({ sliderState: 6 });
    }


  }








  addNewContact = (stateName) => {


    var newRow = {}



    if (stateName == "modules") {

      newRow = {
        module: "",
        canEdit: false,
        canAdd: false,
        canView: false,
        canDelete: false

      }

    }


    var entry = this.state.entry;


    var list = entry[stateName];
    list.push(newRow);
    entry.modules = list;
    this.setState({ entry: entry });

    this.handleOnChangePerson({
      target: { name: "module", value: this.state.availableModules[0].name }
    }, list.length-1, "modules")


  }

  fetchQueryById(id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/getEmployeeById/" +
        id
      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        context.setState({ entry: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }




  sendMail() {
    var data = context.state.entry;
    debugger;
    axios
      .post(
        apiBaseUrl2+"businus/employee/update/" + data.id,
        { 
          "accessControl":true,
          "modules": data.modules,
        })
      .then(function (response) {
        debugger;
        alert("Success!");
        //  context.fetchThreadById(context,query.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    localStorage.setItem("MAILURL", url);


  }

  onSelect(optionsList, selectedItem) {

    context.setState({ team: optionsList });

    console.log(optionsList, selectedItem);
  }

  onRemove(optionList, removedItem) {
    context.setState({ team: optionList });
  }





  //updating the status button

  render() {
    const { sliderState } = this.state;
    const { superSliderState, entry } = this.state;
    const active = " active-button"
    const deactive = "";
    return (
      <div className="dedicated-div">
        <div className="row breadCrumb1-row">
          Employee Access Controls
        </div>
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
          <div className="dedicated-page" style={{ padding: '30px' }}>
            <div className="row ">
              <div className="container">

                <h3 className="account-head" > Basic info </h3>
                <div className="col-md-8">


                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                        id="ember295"
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        Employee ID :
                          </span>{" "}
                    </label>
                    <div className="col-md-6">
                      <h4 className="detail-fields">{entry.employeeId}</h4>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                        id="ember295"
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        Employee Name :
                          </span>{" "}
                    </label>
                    <div className="col-md-6">
                      <h4 className="detail-fields"> {entry.firstName} {entry.lastName}</h4>
                    </div>
                  </div>


                  <div className="form-group">
                    <label className="control-label col-md-4">
                      {" "}
                      Email ID :{" "}
                    </label>
                    <div className="col-md-6">
                      <h4 className="detail-fields">{entry.email}</h4>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="control-label col-md-4">
                      {" "}
                      Nick Name :{" "}
                    </label>
                    <div className="col-md-6">
                      <h4 className="detail-fields">{entry.nickName}</h4>
                    </div>
                  </div>



                </div>
              </div>
              <div className="row">
                <h4 className="account-head">Masters Access<button className="add-button" onClick={() => this.addNewContact('modules')} > <i className="fa fa-plus "></i></button></h4>
                <div className="col-md-8">

                  <table className="add-customer-table" style={{ width: "100%" }}>
                    <tr>
                      <th className="table-head">Module Name</th>
                      <th className="table-head">Add</th>

                      <th className="table-head">Edit </th>
                      <th className="table-head">View </th>
                      <th className="table-head">Delete </th>


                    </tr>

                    {this.state.entry.modules.map((module, i) => (

                      <tr>

                        <td className="table-item"><select onChange={(e) => this.handleOnChangePerson(e, i, "modules")} name="module" value={module.module}>
                          {this.state.availableModules.map((am) => {

                            return <option value={am.name} disabled={this.checkIfModuleIsSelected(
                              am.name, this.state.entry.modules
                            )}>{am.name}</option>


                          })}

                        </select>
                        </td>
                        <td className="table-item"><input
                          disabled={this.state.entry.modules[i].onlyCanView} type="checkbox" name="canAdd" value={this.state.entry.modules[i].canAdd} checked={this.state.entry.modules[i].canAdd} onChange={(e) => this.handleOnChangeCheckbox(e, i, "modules")} /></td>
                        <td className="table-item"><input
                          disabled={this.state.entry.modules[i].onlyCanView} type="checkbox" name="canEdit" value={this.state.entry.modules[i].canEdit} checked={this.state.entry.modules[i].canEdit} onChange={(e) => this.handleOnChangeCheckbox(e, i, "modules")} /></td>
                        <td className="table-item"><input
                          type="checkbox" name="canView" value={this.state.entry.modules[i].canView} checked={this.state.entry.modules[i].canView} onChange={(e) => this.handleOnChangeCheckbox(e, i, "modules")} /></td>
                        <td className="table-item"><input
                          disabled={this.state.entry.modules[i].onlyCanView} type="checkbox" name="canDelete" value={this.state.entry.modules[i].canDelete} checked={this.state.entry.modules[i].canDelete} onChange={(e) => this.handleOnChangeCheckbox(e, i, "modules")} /></td>
                      </tr>


                    ))}



                  </table></div></div>




            </div>
            <div className="text-left">
              <button type="button" onClick={this.sendMail} className="button1">
                {" "}
                Submit{" "}
              </button>
            </div>
          </div>

        </div>

      </div>


    );
  }
}

