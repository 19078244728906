import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";

export default class AppointmentConsultantSidebar extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []
     
    };
    
  }
  componentDidMount(){
    this.handleLine();
  }
  handleLine(){

    $(function(){
      $("#tab1").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
        $("#section").toggle();
      });
      });
      $(function(){
        $("#c2").click(function(e){
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d2").toggle();
          $("#d10").toggle();
      });
      });
      $(function(){
        $("#c3").click(function(e){
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d3").toggle();
          $("#d10").toggle();
        });
        });
      $(function(){
        $("#c4").click(function(e){
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d4").toggle();
          $("#d10").toggle();
          });
          });
      $(function(){
         $("#c5").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d5").toggle();
            });
            });
      $(function(){
          $("#c6").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d6").toggle();
              });
              });
      $(function(){
          $("#c7").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d7").toggle();
                });
                });
      $(function(){
          $("#c8").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d8").toggle();
                  });
                  });  
  
  }
  
  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
    
            <div className="sidebar">
              <div id="sidebar1">
                <div className="sidebar-elementhead"><Link className="sidebar-link-head" to="/home">Home</Link></div>

                <div className="oneTab">
                <div className="sidebar-element" id="c1"><Link  to={"/appointment-consultant"}> <i className="fa fa-bar-chart" />Dashboard<i className="fa fa-angle-right " /></Link></div>
                  <div className="sidebar-subelement" id="d1"><i className="fa fa-envelope" />View<i className="fa fa-angle-right " /></div>
                </div>
                <div className="oneTab">
                  <div className="sidebar-element" id="c3">
                    <i className="fa fa-th-list green" />
                        Appointment
                    <i className="fa fa-angle-down " />
                  </div>
                
                  <div className="sidebar-subelement" id="d3">
                   <div><Link className="sidebar-link" to={"/appointment-consultant/add-appointment"} style={{marginLeft:'1px'}}>
                    <i className="fa fa-plus" />
                        Add Appointment
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                    <div>
                    <Link className="sidebar-link" to={"/appointment-consultant/view-appointment"}>
                      <i className="fa fa-eye" />
                        View Appointment
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                  </div>
                </div>
               
               
                 <div className="oneTab">
                  <div className="sidebar-element" id="c2">
                 
                    <i className="fa fa-list-alt green" />
                    Consultant
                    <i className="fa fa-angle-down " />
                   
                  </div>
                 <div className="sidebar-subelement" id="d2">
               
                    <div>
                  <Link className="sidebar-link" to={"/appointment-consultant/add-consultant"}>
                    <i className="fa fa-plus" />
                        Add Consultant 
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div> 
                    <div>
                  <Link className="sidebar-link" to={"/appointment-consultant/view-consultant"}>
                    <i className="fa fa-eye" />
                        View Consultant
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                   </div>
                   
                </div>
                
              
                <div className="oneTab">
                  <div className="sidebar-element" id="c4">
                 
                    <i className="fa fa-list-alt green" />
                    Type Master
                    <i className="fa fa-angle-down " />
                   
                  </div>
                 <div className="sidebar-subelement" id="d4">
               
                    <div>
                  <Link className="sidebar-link" to={"/appointment-consultant/add-typeMaster"}>
                    <i className="fa fa-plus" />
                        Add Type Master 
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div> 
                    <div>
                  <Link className="sidebar-link" to={"/appointment-consultant/view-typeMaster"}>
                    <i className="fa fa-eye" />
                        View Type Master
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                   </div>
                   
                </div>


                <div className="oneTab">
                  <div className="sidebar-element" id="c5">
                 
                    <i className="fa fa-list-alt green" />
                    Status Master
                    <i className="fa fa-angle-down " />
                   
                  </div>
                 <div className="sidebar-subelement" id="d5">
               
                    <div>
                  <Link className="sidebar-link" to={"/appointment-consultant/add-statusMaster"}>
                    <i className="fa fa-plus" />
                        Add Status Master 
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div> 
                    <div>
                  <Link className="sidebar-link" to={"/appointment-consultant/view-statusMaster"}>
                    <i className="fa fa-eye" />
                        View Status Master
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                   </div>
                   
                </div>
               
              </div>
            </div>
         
    );
  }
}
