import React from 'react';
import axios from 'axios';
import ReactTable from 'react-table';
import WealthBlogSidebar from './WealthBlogSidebar';
import BreadCrumbs from '../../components/BreadCrumbs';
import { Link } from 'react-router-dom';
import { apiBaseUrl20 } from '../../components/apiUtils';

export default class ListWealthBlogCategory extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            category:"",
            categoryArray:[],
            id:""
        }
        
    }

    componentDidMount(){
        this.fetchAllCategory()
    }

    fetchAllCategory(){
        let temp_data=[]
        return axios({       
            url: apiBaseUrl20+'blogCategory/list',
            method: 'GET',
            crossDomain: true,
            responseType: 'json', 
          }).then((response) => { 
              for(var i=0;i<response.data.length;i++){
                  if(response.data[i].learningCategory==='stories-insights'){
                      temp_data.push(response.data[i])
                  }
              }  
            this.setState({categoryArray:temp_data})  
         });  
    }

    editCategory(id){
        
        for(var i=0; i<this.state.categoryArray.length; i++){
            if(this.state.categoryArray[i].id===id){
                document.getElementById("blogCategory").value= this.state.categoryArray[i].category
            }
        }
        this.setState({id:id})
    }

    updateCategory(id){
        let category=document.getElementById("blogCategory").value
        axios({
            url: (apiBaseUrl20+'blogCategory/update/' + id),
            method: 'POST',
            data: {
                category:category,
            }
          }).then((response) => {
            if(response.status===200){
              alert("Blog Category Updated");
              window.location.reload()
            }
 
          });
    }

    deleteCategory(id){
        if (window.confirm("Are you sure you want to delete this category ?")) {
            axios({
              url: (apiBaseUrl20+'blogCategory/get/' + id),
              method: 'Delete',
            }).then((response) => {
            if(response.status===200){
                alert("Blog Deleted");              
                this.fetchAllCategory();
            }
            });
      
          }
    }

    render(){
        const columns = [
          {
            Header: <div className="form-textHead cell-head">Blog Category Id</div>,
            accessor: 'blogId',
            filterMethod: (filter, row) => {   return row.catagory.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:120
          },
           {
            Header:<div className="form-textHead cell-head"> Category</div>,
            accessor: d=>{return {id:d.id,category:d.category}}, 
            id:'category',
            Cell: props => <span className='form-text cell'><Link to={'/knowledgeHub/view-blogCategory/'+props.value.id}>{props.value.category}</Link></span>,
            minWidth:220
          },{
            Header: props => <span className="form-textHead cell-head"> Date</span>,
            accessor: 'date',
            filterMethod: (filter, row) =>
                     {   return row.source.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => 
            new Date(props.value).toDateString(),
            minWidth:180
          },{
            Header: props => <span className="form-textHead cell-head"> Time</span>,
            accessor: 'date',
            filterMethod: (filter, row) =>
                     {   return row.source.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => 
            new Date(props.value).toLocaleTimeString(),
            minWidth:180
          }]
        return(
          <div>
            <div className="row">
              <div className="col-md-2 col-lg-2"><WealthBlogSidebar/></div>
              <div className="col-md-10 col-lg-10">
            <div className = "container-fluid overflow-auto" style={{margin: "10px"}}>
            <div className="row breadcrumb-row">
          <div className="col-md-10" style={{marginTop:'10px'}}><BreadCrumbs/></div></div>
        <div className="container-fluid " style={{}}>
          <ReactTable data={this.state.categoryArray} columns={columns} style={{height:'85vh',overflow:'scroll'}} filterable/>
        </div>
        
        <div>
          <div id="myModal" className="modal fade" role="dialog">
            <div className="modal-dialog" style={{width:'1100px', marginTop: '100px'}}>

              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal">&times;</button>
                  <h4 className="modal-title">Update Wealth Category</h4>
                </div>
                <div className="modal-body">

                  <div className="container-fluid "style={{margin: "30px",}}>

                    <div class="input-group wth-100" >
                      <span type="text" class="input-group-addon" style={{width:"20%"}}>Category</span>
                      <input type="text" class="form-control" id="blogCategory" placeholder="Enter Category" required></input>
                    </div><br/>
            </div>

                </div>
                <div className="modal-footer">

                  <button type='button' className="btn btn-primary" id = 'save' onClick={()=>this.updateCategory()}>Update</button>
                  <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                </div>
              </div>

            </div>
          </div>
        </div>
        </div>
        </div>
      </div>
        </div>
        )
    }
}