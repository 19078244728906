import React, { Component } from "react";
import "../../style/panel.css";
import { Link } from "react-router-dom";
import $ from "jquery";

export default class Khub_HomepageSidebar extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []

    };

  }
  componentDidMount(){
    this.handleLine();
  }
  handleLine(){
      $(function(){
        $("#h_slider").click(function(){
          $("#h_opt").toggle();
        });
      });
      $(function(){
        $("#v_slider").click(function(){
          $("#v_opt").toggle();
        });
      });
      $(function(){
        $("#stayUpdated").click(function(){
          $("#v_stayUpdated").toggle();
        });
      });
  }

  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (

            <div className="sidebar">
              <div id="sidebar1">
                <div className="sidebar-elementhead"><Link className="sidebar-link" to="/knowledgeHub">Home</Link></div>
                <div className="oneTab">
                <div className="sidebar-element" id="c1"><Link className="sidebar-link" to="/knowledgeHub/businus-school/legal"> <i class="fa fa-bar-chart" />Dashboard<i className="fa fa-angle-right " /></Link></div>
                
                </div>
                <div className="oneTab">
                  <h2 className="sidebar-element" id='h_slider'>
                  <i class="fa fa-envelope" />
                    Horizontal Slider
                  <i className="fa fa-angle-down "/>
                  </h2>
                  <div className="sidebar-subelement" id="h_opt">
                  <Link className="sidebar-link" to="/knowledgeHub/businus-school/homepage/add-horizontal-slider">
                    <i class="fa fa-envelope" />
                    Add
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                  <Link className="sidebar-link" to="/knowledgeHub/businus-school/homepage/view-horizontal-slider">
                    <i class="fa fa-envelope" />
                    View 
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                </div>

                <h2 className="sidebar-element" id='v_slider'>
                  <i class="fa fa-envelope" />
                    Vertical Slider
                  <i className="fa fa-angle-down "/>
                  </h2>

                  <div className="sidebar-subelement" id="v_opt">
                  <Link className="sidebar-link" to="/knowledgeHub/businus-school/homepage/add-vertical-slider">
                    <i class="fa fa-envelope" />
                    Add
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                  <Link className="sidebar-link" to="/knowledgeHub/businus-school/homepage/view-vertical-slider">
                    <i class="fa fa-envelope" />
                    View 
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                </div>

                {/* <h2 className="sidebar-element" id='stayUpdated'>
                  <i class="fa fa-envelope" />
                    Stay Updated
                  <i className="fa fa-angle-down "/>
                  </h2>

                  <div className="sidebar-subelement" id="v_stayUpdated">
                  <Link className="sidebar-link" to="/knowledgeHub/businus-school/homepage/view-stay-updated">
                    <i class="fa fa-envelope" />
                    View 
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                </div>                 */}
                 
                </div>

              </div>
            </div>

    );
  }
}
