import React, { Component } from "react";
import "../../../style/panel.css";
import {BrowserRouter, Route} from 'react-router-dom';
import { Link } from "react-router-dom";
import $ from "jquery";

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";


import Askanexpert from './viewClients';

import BreadCrumbs from '../../BreadCrumbs';

import Home from '../../home';


import pageClient from "./pageClient";



import AddClient from "./addClients";
import AddClientsByButton from "./addClientsByButton";


import viewClients from "./viewClients";
import EditClients from "./editClients";


import SidebarClient from './SidebarClient';
import Dashboard from './Dashboard';
import dedicatedMailPage from '../../dedicatedmailpage';


const data = [];
export default class clientMainapp extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []
     
    };
    
  }
  componentDidMount(){
    this.handleLine();
    //this.setState({title:this.props.match.params.productType});
  }
  handleLine(){
    
   
    const tab1= this.refs.tab1;
    const sidebar1= this.refs.sidebar1;
    const section= this.refs.section;
   
    
    $(function(){
      $("#tab1").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
        $("#section").toggle();
      });
      });
     
  }
  
  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
      <div className="mainDiv" >
       
       <div className="row">
         <div className="col-md-2">
         <SidebarClient title={this.state.title}/>
         </div>
         <div className="col-md-10">
         
      
<div className="section">
<Route  exact path = "/masters/client/" component = {Dashboard} />
<Route exact path = "/masters/client/add-clients" component = {AddClient} />
<Route exact path = "/masters/client/add-clients/:id" component = {AddClientsByButton} />
<Route  exact path = "/masters/client/view-clients" component = {viewClients} />
<Route  exact path = "/masters/client/view-clients/:id" component = {pageClient} />
<Route  exact path = "/masters/client/dashboard" component = {Dashboard} />
<Route exact path="/client-mail/:id" component={dedicatedMailPage} />
<Route exact path="/masters/client/edit-client/:id" component={EditClients} />



</div>

         </div>
       </div>
      
     </div>

    );
  }
}
