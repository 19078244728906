import React, {Component} from 'react'
import Moment from 'react-moment'
import AddClientReview from './AddClientReview'

export default class GroupClientReview extends Component{
    render(){
        return(
            <div className='group-client-review'>
            <div className='client-review-title'> {this.props.title} </div>
                <span style={{paddingBottom:'1%'}}>
                <div className='review-star-outer'>
                    <div className='review-star-inner' style={{width:this.props.ratingPercentage}}></div>
                    </div></span>
                    <Moment className='commentTime' fromNow>{this.props.time}</Moment>
                    <div className='client-review-c'>
                        {this.props.comment}
                    </div>
                    <div><img class="commentPic" src='https://alphrade-images.s3.amazonaws.com/default-image/cover-design.jpg'/>
                    <span className="client-review-name">
                        {this.props.name}</span>
                </div>                    
            </div>
        )
    }
}