import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route,Redirect } from "react-router-dom";
import "./index.css";
import Home from "./components/home";
import MainApp3 from "./components/Products/mainApp3";
import MainApp1 from "./components/Consultings/mainApp1";
import * as serviceWorker from "./serviceWorker";
import { Auth } from 'aws-amplify';

import MainWebsite from "./webzyApp/components/MainWebsite";
import InnerWebsitePage from "./webzyApp/components/InnerWebsitePage";
import MainPage from "./webzyApp/components/MainPageEditing";
import WebzyMainapp from "./components/Webzy/webzyMainapp";
import WebzyDashboard from "./components/Webzy/webzyDashboard";
import "./style/panel.css";
import MainApp from "./components/Home/mainApp";
import MainApp2 from "./components/Docs/mainApp2";
import $ from "jquery";
import Header from "./components/header";
import AuthScreen from "./components/Auth";
import professionalsMainapp from "./components/Professionals/professionalsMainapp";
import Main from "./components/main";
import statusMainapp from "./components/Masters/paymentMaster/statusMainapp";
import workflowMainapp from "./components/Masters/workflowMaster/workflowMainapp";
import spMainApp from "./components/Masters/serviceProviders/spMainapp";
import profileHomepage from "./components/Professionals/Profile/profileHomepage";
import clientMainapp from "./components/Masters/clientMaster/clientMainapp";
import EmployeeMainapp from "./components/Masters/employeeMaster/EmployeeMainapp";
import OrganisationMainapp from "./components/Masters/organisationMaster/OrganisationMainapp";
import AddUserProfile from "./components/Professionals/Profile/addUserProfile";
import professionalBookingList from "./components/Professionals/Booking/ProfessionalBookingList";
import ProfessionalBookingDedicated from "./components/Professionals/Booking/ProfessionalBookingDediacted";
import Amplify from "aws-amplify";
import config from "./awsconfig";


import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import axios from "axios";
import Masters from "./components/masters";
import CommunicationMainapp from "./components/Masters/communicationMaster/communicationMainapp";
import TeamCommunicationMainapp from "./components/Masters/teamCommunication/teamCommunicationMainapp";
import resourcesMainapp from "./components/Masters/Resources/resourcesMainapp";
import KnowledgehubMainApp from "./knowledgeHub/Common/KnowledgeHub";
import AssignmentMainapp from "./components/Masters/Assignment/AssignmentMainapp";
import accessControlMainapp from "./components/Masters/Access-control/accessControlMainapp";
import IssueMainapp from "./components/Masters/IssueMaster/IssueMainapp";
import TaskMainApp from "./components/Masters/taskMaster/TaskMainapp";
import LogsMainapp from "./components/Masters/LogsMaster/LogsMainapp";
import AppointmentConsultantMainApp from "./components/Appointment/AppointmentConsultantMainapp";

import SendMail from "./components/Masters/communicationMaster/sendMail";
import TeamSharedForm from "./components/Masters/teamCommunication/teamSharedForm";
import WorkflowSharedForm from "./components/Masters/workflowMaster/WorkflowSharedForm";
import AssignmentSharedForm from "./components/Masters/Assignment/assignmentSharedForm";
import AssignmentScreen from "./components/assignmentScreen";

import AccountMainapp from "./components/Account-info/AccountMainapp";
import ClientSharedForm from "./components/Masters/communicationMaster/clientSharedForm";
import ToolsMainapp from "./components/Tools/toolsMainapp";
import ToolsPage from "./components/Tools/toolsPage";
import CalculatorMainapp from "./components/Tools/Calculator/calculatorMainapp";
import CalendarMainapp from "./components/Calendar/calendarMainapp";
import AddSmartDoc from "./components/Smart_Docs/addSmartDoc";
import PageDoc from "./components/Smart_Docs/pageDoc";
import ViewSmartDoc from "./components/Smart_Docs/viewSmartDoc";
import SmartDocDashboard from "./components/Smart_Docs/smartDocDashboard";
import SmartDocTemplates from "./components/Smart_Docs/smartDocTemplates";
import SmartDocMainapp from "./components/Smart_Docs/smartDocMainapp";
axios.interceptors.request.use(
  function (request) {
    console.log("request intercepted");

    
    request.headers["Authorization"] = localStorage.token;

    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);





Amplify.configure({
  Auth: {
    oauth: config.cognito.oauth,
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "testApiCall",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
});

$(function () {
  $("#tab1").click(function (e) {
    $(e.currentTarget).parent().toggleClass("sidebar-secondary");
    $(e.currentTarget).find("i:first").toggleClass("sidebar-back-arrow");
    $("#section").toggle();
  });
});
$(function () {
  $("#c2").click(function (e) {
    $(e.currentTarget).parent().toggleClass("sidebar-secondary");
    $(e.currentTarget).find("i:first").toggleClass("sidebar-back-arrow");
    $("#d2").toggle();
  });
});
$(function () {
  $("#c3").click(function (e) {
    $(e.currentTarget).parent().toggleClass("sidebar-secondary");
    $(e.currentTarget).find("i:first").toggleClass("sidebar-back-arrow");
    $("#d3").toggle();
  });
});
$(function () {
  $("#c4").click(function (e) {
    $(e.currentTarget).parent().toggleClass("sidebar-secondary");
    $(e.currentTarget).find("i:first").toggleClass("sidebar-back-arrow");
    $("#d4").toggle();
  });
});
$(function () {
  $("#c5").click(function (e) {
    $(e.currentTarget).parent().toggleClass("sidebar-secondary");
    $(e.currentTarget).find("i:first").toggleClass("sidebar-back-arrow");
    $("#d5").toggle();
  });
});
$(function () {
  $("#c6").click(function (e) {
    $(e.currentTarget).parent().toggleClass("sidebar-secondary");
    $(e.currentTarget).find("i:first").toggleClass("sidebar-back-arrow");
    $("#d6").toggle();
  });
});
$(function () {
  $("#c7").click(function (e) {
    $(e.currentTarget).parent().toggleClass("sidebar-secondary");
    $(e.currentTarget).find("i:first").toggleClass("sidebar-back-arrow");
    $("#d7").toggle();
  });
});
$(function () {
  $("#c8").click(function (e) {
    $(e.currentTarget).parent().toggleClass("sidebar-secondary");
    $(e.currentTarget).find("i:first").toggleClass("sidebar-back-arrow");
    $("#d8").toggle();
  });
});
$(function () {
  $("#c9").click(function (e) {
    $(e.currentTarget).parent().toggleClass("sidebar-secondary");
    $(e.currentTarget).find("i:first").toggleClass("sidebar-back-arrow");
    $("#d9").toggle();
  });
});
$(function () {
  $("#c10").click(function (e) {
    $(e.currentTarget).parent().toggleClass("sidebar-secondary");
    $(e.currentTarget).find("i:first").toggleClass("sidebar-back-arrow");
    $("#d10").toggle();
  });
});


async function refreshToken(){
  // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
  const session = await Auth.currentSession().catch((err)=>{



  });
console.log(session.getAccessToken().getJwtToken());
const token=session.getAccessToken().getJwtToken()
  localStorage.setItem("token", token) ;
  console.log("token refreshed");
};

window.lastActivity = Date.now();
 document.addEventListener('click', function(){
    window.lastActivity = Date.now();
    console.log("user clicked at :", window.lastActivity);

}) 
var TIMEOUT = 1500000 //25 mins
var activityChecker = setInterval(check, 180000); //3mins

/* function createAlert(){
if(TIMEOUT==10000){
  var text="";
  if (confirm("Continue the session!") == true) {
    text = "You pressed OK! ";
  } else {
    text = "You canceled!";
  }
}} */

function check() {
  console.log("check function started")
    var currentTime = Date.now();
    console.log("current time:", currentTime)
    if(localStorage.isLoggedIn=="YES" ){


    
    if (currentTime - window.lastActivity > TIMEOUT  ) {
      
    
        var text="";
        if (window.confirm("Please press OK to continue the session else the session will expire soon!" ) == true) {
          text = "You pressed OK! ";
          refreshToken();
          console.log("refreshing Token")
        } else {

          
          text = "You canceled!";
       localStorage.clear();
          alert("session has expired")
          window.location.replace("/") 
        }
    


  
        // do something useful, for example logout or whatever
    }
    else {
     

    }
}}

ReactDOM.render(
  <div>
    <ToastsContainer
      store={ToastsStore}
      position={ToastsContainerPosition.TOP_CENTER}
    />
    <BrowserRouter>
    <Route exact path = "/clientUserform/send/:mailType" component = {ClientSharedForm} />
    <Route exact path = "/teamUserform/send/:mailType" component = {TeamSharedForm} />
    <Route exact path = "/userforms/:id/:workflowName/:workflowIndex" component = {WorkflowSharedForm} />
    <Route exact path = "/assignment-master/userforms/assignment" component = {AssignmentSharedForm} />
    <AuthScreen />
      {localStorage.isLoggedIn == "YES" ? (
        <span>
           <Route exact path="/smartDocs" component={SmartDocMainapp} />
           <Route exact path="/smartdoc/add" component={SmartDocMainapp} />
              {/* SMARTDOC ID : ID,  SMARTDOC VERSION : SDV  */}
              <Route exact path="/smartDoc/view/:id/" component={SmartDocMainapp} />
              <Route exact path="/smartDoc/view" component={SmartDocMainapp} />
              <Route exact path="/smartdoc" component={SmartDocMainapp} />
              <Route
                exact
                path="/smartdoc/templates"
                component={SmartDocMainapp}/>
          <Route exact path="/home" component={Main} />
          <Route exact path="/" component={Main} />
          <Route exact path="/webzy/page/:appId" component={MainWebsite} />
          <Route
            exact
            path="/webzy/subpage/:appId"
            component={InnerWebsitePage}
          />
          <Route exact path="/webzy/editor/:appId" component={MainPage} />
          <Route
            exact
            path="/legal/products/client/add-clients/:id"
            component={MainApp3}
          />
          <Route
            exact
            path="/legal/consultings/client/add-clients/:id"
            component={MainApp1}
          />
       
          <Route
            exact
            path="/legal/docs/client/add-clients/:id"
            component={MainApp2}
          />
           <Route exact path="/edit-calImages" component={CalendarMainapp} />
             <Route exact path="/calendar" component={CalendarMainapp} />
           <Route exact path="/tools" component={ToolsPage} />
           <Route exact path="/tools/calculator" component={CalculatorMainapp}/>
           <Route exact path="/tools/calculator/calc1" component={CalculatorMainapp}/>
            <Route exact path="/tools/calculator/calc2" component={CalculatorMainapp}/>
            <Route exact path="/tools/calculator/days-calc" component={CalculatorMainapp}/>
          <Route exact path="/masters" component={Masters} />
         
          <Route path="/masters/client" component={clientMainapp} />

          <Route path="/masters/employee" component={EmployeeMainapp} />

          <Route path="/masters/organisation" component={OrganisationMainapp} />
          <Route exact path="/legal" component={Home} />
          <Route path="/legal/home" component={MainApp} />
          <Route path="/legal/homepage" component={MainApp} />
          <Route path="/service-providers" component={spMainApp} />
          <Route exact path="/webzy/view-assigns" component={WebzyMainapp} />
          <Route exact path="/webzy/view-templates" component={WebzyMainapp} />
          <Route exact path="/webzy/add-template" component={WebzyMainapp} />
          <Route exact path="/webzy/add-assigns" component={WebzyMainapp} />
          <Route exact path="/webzy/edit-assigns" component={WebzyMainapp} />
          <Route exact path="/webzy/re-assigns/:id" component={WebzyMainapp} />
          <Route exact path="/webzy/" component={WebzyMainapp} />
          <Route  path="/workflow" component={workflowMainapp} />
          <Route path="/knowledgeHub" component={KnowledgehubMainApp} />
          <Route  path="/assignment-master/assignment" component={AssignmentMainapp} />
          <Route  path="/assignment-master/status" component={statusMainapp} />
          <Route exact path="/assignment-master" component={AssignmentScreen} />
         
          <Route path="/access-control" component={accessControlMainapp} />
          <Route path="/resources" component={resourcesMainapp} />
          <Route path="/issues" component={IssueMainapp} />
          <Route path="/logs" component={LogsMainapp} />

          <Route path="/tasks" component={TaskMainApp} />
          <Route
            path="/communication-master"
            component={CommunicationMainapp}
          />
          <Route
            path="/team-communication"
            component={TeamCommunicationMainapp}
          />
          {/* <Route
            exact
            path="/professionals/profile-homepage"
            component={profileHomepage}
          ></Route>
          <Route
            exact
            path="/professionals/profile/mybooking/list"
            component={professionalBookingList}
          ></Route>
          <Route
            exact
            path="/professionals/professional-booking-page/:id"
            component={ProfessionalBookingDedicated}
          ></Route>

          <Route
            exact
            path="/professionals/add-profile"
            component={AddUserProfile}
          ></Route> */}
          <Route path="/professionals" component={professionalsMainapp} />
          <Route path="/legal/products/:productType" component={MainApp3} />
          <Route path='/appointment-consultant' component={AppointmentConsultantMainApp}/>
          <Route
            path="/legal/consultings/:consultingType"
            component={MainApp1}
          />
          <Route exact path="/legal/products"><Redirect to="/legal" /></Route>
          <Route exact path="/legal/consulting"><Redirect to="/legal" /></Route>
          <Route exact path="/legal/docs"><Redirect to="/legal" /></Route>

          <Route path="/legal/docs/:docType" component={MainApp2} />
          <Route path="/account-info" component={AccountMainapp} />
         
        </span>
      ) : (
        ""
      )}
    </BrowserRouter>
  </div>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
