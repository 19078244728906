import React, { Component } from "react";

import BreadCrumbs from '../BreadCrumbs';
import { Link } from "react-router-dom";
import axios from 'axios';
import { apiBaseUrl1,apiBaseUrl3, apiBaseUrl5, apiWebzyUrl } from '../apiUtils';
import { apiBaseUrl2 } from '../apiUtils';
import CsvDownloader from 'react-csv-downloader'
import ReactTable from 'react-table';
import $ from "jquery";
import _ from 'underscore';

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

const data = [];




let context = "null";

const columnsForExcel = [
  {
    id: 'assignmentId',
    displayName : "Assingment ID",

  },
  {
    displayName : "Name",

    id: "name"
  },
  {
    displayName : "Status",
    id: "status",
   
  },
  {
    displayName : "Payment Status",
    id: "paymentStatus",
   
  },
  {
    displayName : "Start Date",
    id: "startDate",
   
  },
  {
    displayName : "End Date",
    id: "endDate",
 
  },

  {
    displayName : "Overview",
    id: "assignmentOverview",
   
  },

  {
    displayName : "Priority",
    id: "priority",
   
  },
  {
    displayName : "Client",
    id: "selectedClient",
   
  },
  {
    displayName : "Organisation",
    id: "selectedOrganisation",

  },
  {
    displayName : "Team Lead",
    id: "teamLead",
 
  },
  {
    displayName : "Team",
   
    id: "teamMembers",
   
  },
  {
    displayName : "Template",
   
    id: "template",
   
  },
  {
    displayName : "Recurrance Frequency",
   
    id: "recurranceFrequency",
   
  },
  {
    displayName : "Priority",
   
    id: "priority",
   
  },
  {
    displayName : "By",
   
    id: "by",
   
  },
  {
   
    id: "date",
    displayName : "Date",
  },
  {
    displayName : "Time",
    id: "time",
  
  }
];
//defined all the columns and fetched the data for every cell.
const columnsForDoc = [  

  {
    Header: props => <span className="form-textHead cell-head">Actions</span>,
    accessor: 'by',
    Cell: props => <span className='form-text cell'><button className="webzy-delete-website">Delete Website</button></span>,
    minWidth: 170

  },
  {

    Header: props => <span className="form-textHead cell-head">ID</span>,
    accessor: 'workflowDocId',
    Cell: props =>
      <span>{props.value}</span>,
    minWidth: 180
  },

  {Header: props => <span className="form-textHead cell-head">Name</span>,
  accessor: d => {return {workflowDocId:d.workflowDocId,id:d.id,name:d.heading}},
  filterMethod: (filter, row) =>
           { return row.name.name.toLowerCase().includes(filter.value.toLowerCase()) }
                   
                ,
              
  id:"name",
  Cell: props => 
  <Link className="link" to={"./doc/"+ props.value.id}><span>{props.value.name}</span></Link>,
  minWidth:180
  
},{Header: props => <span className="form-textHead cell-head">Assigned To</span>,
accessor: d => {return {name:d.parentName, id:d.parentId, type:d.parentType, assignedId:d.parentNameId}},
filterMethod: (filter, row) =>
         { return row.name.name.toLowerCase().includes(filter.value.toLowerCase()) }
                 
              ,
            
id:"assignedTo",
Cell: props => 
props.value.name ? 
<Link className="link" to={"./doc/"+ props.value.id}><span>{props.value.assignedId}</span></Link> : 'NA',
minWidth:180

},
  {
    Header: props => <span className="form-textHead cell-head">Description</span>,
    accessor: 'content',
    Cell: props => <span className='form-text cell' dangerouslySetInnerHTML={{ __html: props.value }}></span>,
    minWidth: 240
  },
  {
    Header: props => <span className="form-textHead cell-head">Sub Heading</span>,
    accessor: 'subHeading',
    Cell: props =><span className='form-text cell'>{props.value}</span>,
    minWidth: 240
  },
  {
    Header: props => <span className="form-textHead cell-head">Author</span>,
    accessor: 'authorName',
    Cell: props =><span className='form-text cell'>{props.value}</span>,
    minWidth: 240
  },
  {
    Header: props => <span className="form-textHead cell-head">By</span>,
    accessor: 'by',
    Cell: props => <span className='form-text cell'>{props.value}</span>,
    minWidth: 170

  },
  {
    Header: props => <span className="form-textHead cell-head">Date of Creation</span>,
    accessor: d => { return { date: d.date, id: d.id } },
    filterMethod: (filter, row) => { return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase()) }

    ,
    id: "teamMembers",
    Cell: props =>
      new Date(props.value.date).toDateString(),
    minWidth: 180

  },
  {
    Header: props => <span className="form-textHead cell-head">Time of Creation</span>,
    accessor: d => { return { date: d.date, id: d.id } },
    filterMethod: (filter, row) => { return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase()) }

    ,
    id: "teamMembers",
    Cell: props =>
      new Date(props.value.date).toLocaleTimeString()
    ,
    minWidth: 280

  },

  




]
const columnsForAttachment = [

  {

    Header: props => <span className="form-textHead cell-head">Attachment Id</span>,
    accessor: 'workflowDocId',
    Cell: props =>
      <span>{props.value}</span>,
    minWidth: 180
  },

  {Header: props => <span className="form-textHead cell-head">Name</span>,
  accessor: d => {return {workflowDocId:d.workflowDocId,id:d.id,name:d.name}},
  filterMethod: (filter, row) =>
           { return row.name.name.toLowerCase().includes(filter.value.toLowerCase()) }
                   
                ,
              
  id:"name",
  Cell: props => 
  <Link className="link" to={"./attachment/"+ props.value.id}><span>{props.value.name}</span></Link>,
  minWidth:180
  
},{Header: props => <span className="form-textHead cell-head">Assigned To</span>,
accessor: d => {return {name:d.parentName, id:d.parentId, type:d.parentType, assignedId:d.parentNameId}},
filterMethod: (filter, row) =>
         { return row.name.name.toLowerCase().includes(filter.value.toLowerCase()) }
                 
              ,
            
id:"assignedTo",
Cell: props => 
props.value.name ? 
<Link className="link" to={"./doc/"+ props.value.id}><span>{props.value.assignedId}</span></Link> : 'NA',
minWidth:180

},
  {
    Header: props => <span className="form-textHead cell-head">description</span>,
    accessor: 'description',
    Cell: props => <span className='form-text cell' dangerouslySetInnerHTML={{ __html: props.value }}></span>,
    minWidth: 240
  },
  {
    Header: props => <span className="form-textHead cell-head">Attachment</span>,
    accessor: 'attachment',
    Cell: props => props.value !== "NA" ? <a href={props.value} target="_blank">Download</a> : props.value,
    minWidth: 240
  },
  {
    Header: props => <span className="form-textHead cell-head">By</span>,
    accessor: 'by',
    Cell: props => <span className='form-text cell'>{props.value}</span>,
    minWidth: 170

  },
  {
    Header: props => <span className="form-textHead cell-head">Date of Creation</span>,
    accessor: d => { return { date: d.date, id: d.id } },
    filterMethod: (filter, row) => { return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase()) }

    ,
    id: "teamMembers",
    Cell: props =>
      new Date(props.value.date).toDateString(),
    minWidth: 180

  },
  {
    Header: props => <span className="form-textHead cell-head">Time of Creation</span>,
    accessor: d => { return { date: d.date, id: d.id } },
    filterMethod: (filter, row) => { return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase()) }

    ,
    id: "teamMembers",
    Cell: props =>
      new Date(props.value.date).toLocaleTimeString()
    ,
    minWidth: 280

  },

  


]




const columnsForUrl = [
  
  {
    Header: props => <span className="form-textHead cell-head">Actions</span>,
    id:"dele",
    accessor: d => {return {workflowDocId:d.workflowDocId,id:d.appId,docId:d.id,name:d.name}},
    Cell: props => <span className='form-text cell'><button className="webzy-delete-website"  onClick={()=>context.delete(props.value.docId)} ><i class="fa fa-trash"></i>Delete</button></span>,
    minWidth: 170

  },

  {

    Header: props => <span className="form-textHead cell-head">ID</span>,
    accessor: 'webId',
    Cell: props =>
      <span>{props.value}</span>,
    minWidth: 180
  },

  {Header: props => <span className="form-textHead cell-head">Name</span>,
  accessor: d => {return {workflowDocId:d.workflowDocId,id:d.appId,docId:d.id,name:d.name}},
  filterMethod: (filter, row) =>
           { return row.name.name.toLowerCase().includes(filter.value.toLowerCase()) }
                   
                ,
              
  id:"name",
  Cell: props => 
  <span><Link className="link" to={"/webzy/re-assigns/"+ props.value.docId}><span>{props.value.name}</span></Link>
 <Link className="link" to={"./editor/"+ props.value.id}> <i className="fa fa-external-link-alt"></i>
 </Link>
  </span>
  ,
  minWidth:180
  
},
  {
    Header: props => <span className="form-textHead cell-head">By</span>,
    accessor: 'by',
    Cell: props => <span className='form-text cell'>{props.value}</span>,
    minWidth: 170

  },
  {
    Header: props => <span className="form-textHead cell-head">Date of Creation</span>,
    accessor: d => { return { date: d.date, id: d.id } },
    filterMethod: (filter, row) => { return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase()) }

    ,
    id: "teamMembers",
    Cell: props =>
      new Date(props.value.date).toDateString(),
    minWidth: 180

  },
  {
    Header: props => <span className="form-textHead cell-head">Time of Creation</span>,
    accessor: d => { return { date: d.date, id: d.id } },
    filterMethod: (filter, row) => { return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase()) }

    ,
    id: "teamMembers",
    Cell: props =>
      new Date(props.value.date).toLocaleTimeString()
    ,
    minWidth: 280

  },

  

]
export default class ListTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      menu1: [




      ]

    };
    context = this;
  }
  
componentDidMount(){
  this.fetchQueries(this);
}
  


fetchQueries(that) {

    axios.get(apiWebzyUrl + "businuswebsite/list/all/all")
      .then(function (response) {
        console.log(response.data);
        let allData=response.data;
        var templates = _.where(allData,{type:"Template"});
         
        that.setState({ templateList: _.sortBy(templates, "date") });
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  delete(id) {

    axios.delete(apiWebzyUrl + "businuswebsite/getWebsiteById/"+id)
      .then(function (response) {
       context.fetchQueries();
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  render() {
    return (
      <div>


        <div className="Div">
          <div className="row breadcrumb-row">
            <div className="col-md-10"><BreadCrumbs /></div>
            <div className="col-md-2">
              <div className="download-button">
                <CsvDownloader  text="Download in .csv format" datas={this.state.menu1} filename="organisation" /></div>
            </div>
          </div>
          <div className="row">

            <Row>
              <Col>
                <div className="card">

                  <ReactTable
                    
                  className="myTable" resolveData={data => data.map(row => row)} data={this.state.templateList}
                    columns={ columnsForUrl } filterable />






                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}
