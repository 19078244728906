import React from 'react';
import {Link} from "react-router-dom";

export default class RearrangeDashboardGST extends React.Component{


    render(){
        return(
            <div>
                <div className="row">
                    <div className="col-md-3 col-lg-3 col-sm-12">
                        <div className="tab">
                            <Link to="/knowledgeHub/arrange/column1/gst/case-law">Rearrange Column one</Link>    
                        </div>
                    </div>

                    <div className="col-md-3 col-lg-3 col-sm-12">
                        <div className="tab">
                        <Link to="/arrange/column2/gst/case-law">Rearrange Column two</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}