import React from 'react';
import axios from 'axios';
import ReactTable from 'react-table';
import BreadCrumbs from '../../components/BreadCrumbs';
import GuideBlogSidebar from './GuideBlogSidebar';


export default class ListGuideBlog extends React.Component{
    constructor(props){
        super(props)
        this.state={
            resdata:[],
            learningType:''
        }
    }

    componentDidMount(){
      let url = window.location.href.split('/')
      let index = url.length-2
      this.setState({learningType:url[index]})
        this.fetchAllBlog(url[index])
    }

    fetchAllBlog(learningType){
        let id=localStorage.getItem('guideBlogFilterId')
        let temp_data=[]
          return axios({       
              url: 'https://q7ay5k66xi.execute-api.us-east-1.amazonaws.com/dev/businus/guideBlog/list/'+id,
              method: 'GET',
              crossDomain: true,
              responseType: 'json', 
            }).then((response) => {
                for(var i=0;i<response.data.length;i++){
                  if(response.data[i].guideId===id && response.data[i].learningType===learningType){
                      temp_data.push(response.data[i])
                  }
                }
            this.setState({resdata:temp_data})
           });
    }

    
    deleteGuideBlog(id){
        if (window.confirm("Are you sure you want to delete this category ?")) {
            axios({
              url: 'https://q7ay5k66xi.execute-api.us-east-1.amazonaws.com/dev/businus/getGuideBlogById/{id}' + id,
              method: 'Delete',
            }).then((response) => {
            if(response.status===200){
                alert("Blog Deleted");              
                this.fetchAllChapter();
            }
            });
      
          }
    }
    editGuideBlog(id){
      window.location.replace('/knowledgeHub/businus-school/'+this.state.learningType+'/edit-guides-blog/'+id)
    }

    render(){
        const columns = [{
            Header: <div className="form-textHead cell-head">Actions</div>,
            accessor: d => { return {id:d.id,}},
            id:"id",
            Cell: props =>  <div className='' style={{}}>
              <button type="button" style={{margin: "3px"}} className="btn btn-primary" onClick={()=>this.editGuideBlog(props.value.id)}><span className="fas fa-edit"></span></button>
              <button type="button" style={{margin: "3px"}} className="btn btn-danger" onClick={e=>this.deleteGuideBlog(props.value.id,)}><span className="fas fa-trash"></span></button>
              </div>,
      
            minWidth:150
          },
          {
            Header: <div className="form-textHead cell-head"> Guide Blog Id</div>,
            accessor: 'guideBlogId',
            filterMethod: (filter, row) => {debugger;   return row.catagory.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:120
          },
           {
            Header:<div className="form-textHead cell-head"> Heading</div>,
            accessor: 'heading',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:220
          }, {
            Header: <div className="form-textHead cell-head">Sub Heading</div>,
            accessor: 'subHeading',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:220
          }, {
            Header: <div className="form-textHead cell-head">Content</div>,
            accessor: 'truncateContent',
            Cell: props => <span className='form-text cell' dangerouslySetInnerHTML={{__html:props.value}}></span>,
            minWidth:320
          },{
            Header:<div className="form-textHead cell-head">Author Description</div>,
            accessor: 'authorDescription',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:220    
          }, {
            Header:<div className="form-textHead cell-head">Author Image Credit</div>,
            accessor: 'authorImageCredit',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:100
          }, {
            Header:<div className="form-textHead cell-head">Author Name</div>,
            accessor: 'authorName',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:150
          }, {
            Header:<div className="form-textHead cell-head">Blog Image Credit</div>,
            accessor: 'blogImageCredit',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:220
          },{
            Header:<div className="form-textHead cell-head">Read Time</div>,
            accessor: 'readTime',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:100
          }, {
            Header:<div className="form-textHead cell-head">Source</div>,
            accessor: 'source',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:100
          },
          {
            Header:<div className="form-textHead cell-head">Source URL</div>,
            accessor: 'sourceUrl',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:100
          },
          {
            Header:<div className="form-textHead cell-head">Tags</div>,
            accessor: 'tags',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:100
          }, {
            Header: <div className="form-textHead cell-head">SEO</div>,
            accessor: 'seo',
            filterMethod: (filter, row) => {debugger;   return row.catagory.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:200
          }, {
            Header: <div className="form-textHead cell-head">Slug</div>,
            accessor: 'slug',
            filterMethod: (filter, row) => {debugger;   return row.catagory.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:220
          }, {
            Header: <div className="form-textHead cell-head">Meta Description</div>,
            accessor: 'metaDescription',
            filterMethod: (filter, row) => {debugger;   return row.catagory.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:220
          },{
            Header: props => <span className="form-textHead cell-head"> Date</span>,
            accessor: 'date',
            filterMethod: (filter, row) =>
                     {   return row.source.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => 
            new Date(props.value).toDateString(),
            minWidth:180
          },{
            Header: props => <span className="form-textHead cell-head"> Time</span>,
            accessor: 'date',
            filterMethod: (filter, row) =>
                     {   return row.source.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => 
            new Date(props.value).toLocaleTimeString(),
            minWidth:180
          }];      
        return(
            <div>
            <div className="row">
              <div className="col-md-2 col-lg-2"><GuideBlogSidebar learningType={this.state.learningType} /></div>
              <div className="col-md-10 col-lg-10">
            <div className = "container-fluid overflow-auto" style={{margin: "10px"}}>
            <div className="row breadcrumb-row">
          <div className="col-md-10" style={{marginTop:'10px'}}><BreadCrumbs/></div></div>
        <div className="container-fluid ">
          <ReactTable data={this.state.resdata} columns={columns} style={{height:'85vh',overflow:'scroll'}} filterable/>
                

        </div>
        </div>
        </div>
      </div>
            </div>
        )
    }
}