import React, { Component } from "react";

import BreadCrumbs from '../../BreadCrumbs';
import { Link } from "react-router-dom";
import axios from 'axios';
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import CsvDownloader from 'react-csv-downloader'
import ReactTable from 'react-table';
import $ from "jquery";
import _ from 'underscore';

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

const data = [];

const columnsForExcel = [
  {
    id: 'assignmentId',
    displayName : "Assingment ID",

  },
  {
    displayName : "Name",

    id: "name"
  },
  {
    displayName : "Status",
    id: "status",
   
  },
  {
    displayName : "Payment Status",
    id: "paymentStatus",
   
  },
  {
    displayName : "Start Date",
    id: "startDate",
   
  },
  {
    displayName : "End Date",
    id: "endDate",
 
  },

  {
    displayName : "Overview",
    id: "assignmentOverview",
   
  },

  {
    displayName : "Priority",
    id: "priority",
   
  },
  {
    displayName : "Client",
    id: "selectedClient",
   
  },
  {
    displayName : "Organisation",
    id: "selectedOrganisation",

  },
  {
    displayName : "Team Lead",
    id: "teamLead",
 
  },
  {
    displayName : "Team",
   
    id: "teamMembers",
   
  },
  {
    displayName : "Template",
   
    id: "template",
   
  },
  {
    displayName : "Recurrance Frequency",
   
    id: "recurranceFrequency",
   
  },
  {
    displayName : "Priority",
   
    id: "priority",
   
  },
  {
    displayName : "By",
   
    id: "by",
   
  },
  {
   
    id: "date",
    displayName : "Date",
  },
  {
    displayName : "Time",
    id: "time",
  
  }
];
let context="null";

//defined all the columns and fetched the data for every cell.
const columns = [
  
{
  Header: props => <span className="form-textHead cell-head">ID</span>,
  accessor: 'spId',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  filterMethod: (filter, row) =>
  { return row.spId.toLowerCase().includes(filter.value.toLowerCase()) },       
  minWidth:120
},
{

  Header: props => <span className="form-textHead cell-head">Service Provider Name</span>,
  accessor: d => {return {name:d.spName,id:d.id}},
  filterMethod: (filter, row) =>
           { return row.spName.name.includes(filter.value.toLowerCase()) },            
  id:"spName",
  Cell: props => 
  <Link className="link" to={"./view/"+props.value.id}><span>{props.value.name}</span></Link>,
  minWidth:180
  },


{
  Header: props => <span className="form-textHead cell-head">Service Provider Email</span>,
  accessor: 'spEmail',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:240
},
{
  Header: props => <span className="form-textHead cell-head">Service Provider Phone</span>,
  accessor: 'spPhone',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:240
},
{
  Header: props => <span className="form-textHead cell-head">User Limit</span>,
  accessor: 'spUserLimit',
  Cell: props => <span className='form-text cell'>{props.value ? props.value : "No Limit"}</span>,
  minWidth:240
},

{
  Header: props => <span className="form-textHead cell-head">By</span>,
  accessor: 'by',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:170
 
},{
  Header: (props) => (
    <span className="form-textHead cell-head">Date of Creation</span>
  ),
  accessor: (d) => {
    return { date: d.date, id: d.id };
  },
  filterMethod: (filter, row) => {
    return new Date(row.date.date).toDateString().toLowerCase().includes(filter.value.toLowerCase());
  },

  id: "date",
  Cell: (props) => new Date(props.value.date).toDateString(),
  minWidth: 180,
},
{
  Header: (props) => (
    <span className="form-textHead cell-head">Time of Creation</span>
  ),
  accessor: (d) => {
    return { date: d.date, id: d.id };
  },
  filterMethod: (filter, row) => {
    return new Date(row.date.date).toLocaleTimeString().toLowerCase().includes(filter.value.toLowerCase());
  },

  id: "time",
  Cell: (props) => new Date(props.value.date).toLocaleTimeString(),
  minWidth: 280,
},



]
export default class viewSP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      menu1:[
       



 ]
 
    };
    context = this;
  }
  componentDidMount(){

  this.fetchQueries(this,this.props.source);}


  toggleOpen = id => {
    var list  =this.state.menu1;
    var index = _.findIndex(list,function(entry){return entry.id==id});
    
    list[index].status =  (list[index].status == "open" || list[index].status==undefined) ? "close" : 'open';
    
    var newList = JSON.parse(JSON.stringify( list ));
    context.setState({menu1:newList});
    context.updateStatus(context,id,list[index].status);
    
    
    
      };

  
  fetchQueries(that,source){

    axios.get(apiBaseUrl1+"businus/serviceProvider/list")
    .then(function (response) {
      console.log(response.data);
      that.setState({menu1:_.sortBy(response.data,"date")});
      that.setState({menu2:_.filter(that.state.menu1,function(sp){
        return sp.spEmail!='businusdotcom@gmail.com';
      })});

     
      that.generateExcelData(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }

  
  generateExcelData = data => {
    
    let excelData = [];
    _.each(data, function(d) {
  
      
      let row = {

      attributes: d.attributes,
      bankDetails: d.bankDetails,
      contactPersons: context.getCommaSeparated(d.contactPersons,'name'),
      logo:d.logo,
      organisationDisplayName: d.organisationDisplayName,
      organisationEmail: d.organisationEmail,
      organisationId: d.organisationId,
      organisationMode: d.organisationMode,
      organisationName: d.organisationName,
      organisationPhone: d.organisationPhone,
      organisationType: d.organisationType,
      primaryContact: d.primaryContact,
      salutation:d.salutation,
      team: context.getCommaSeparated(d.team,'name'),
      website: d.website,
       by: d.by,       
        date: new Date(d.date).toDateString(),
        time: new Date(d.date).toLocaleTimeString()
     
      
      }

       
      excelData.push(row);
      console.log(excelData);
    });
    this.setState({ excelData });
  };
  
  getCommaSeparated=(teamMember,name)=>{
    console.log(teamMember)
   let names= _.map(teamMember, function(d){
   return d[name];
   })
   
   return names.join(";")
   }
  

  render() {
    return (
      <div>
       
      
      <div className="Div">
      <div className="row breadcrumb-row">
          <div className="col-md-10"><BreadCrumbs/></div>
          <div className="col-md-2">
            <div className=" download-button">
      <CsvDownloader text="Download in .csv format" datas={this.state.excelData} filename="organisation" /></div>
      </div>
      </div>
            <div className="row">
          
              <Row>
                <Col>
                  <div className="card">

                  <ReactTable className="myTable" resolveData={data => data.map(row => row)}  data={this.state.menu2} columns={columns} filterable />
                  


                    
                       
                      
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          </div>
    );
  }
}
