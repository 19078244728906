import React, { useState } from "react";
export default function View({ sdURL }) {
  const [fileURL, setFileURL] = useState(null);
  setTimeout(() => {
    setFileURL(sdURL);
  }, 1000);
  return (
    <div
      className="container w-100 d-flex flex-column justify-content-center align-items-center view-section"
      style={{ minHeight: "400px" }}
    >
      {sdURL != " " && (
        <>
          <div className="w-100 d-flex justify-content-end">
            <a href={sdURL} download="valueplus.docx">
              <button
                type="button"
                className="btn my-4 mx-5 btn-outline-success btn-lg response-btn"
              >
                Download Document
              </button>
            </a>
          </div>
        </>
      )}
      {fileURL != " " ? (
        <>
          <iframe
            title="docx"
            src={`https://docs.google.com/gview?url=${sdURL}&embedded=true&embedded=true`}
            style={{ width: "90vw", height: "100vh" }}
          />
        </>
      ) : (
        <h2 className="text-center m-5">
          PLEASE SUBMIT THE RESPOND SECTION FIRST
        </h2>
      )}
    </div>
  );
}
