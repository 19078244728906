import $ from "jquery";
import React, { Component } from "react";
import { Route } from 'react-router-dom';
import "../../style/panel.css";
import AddAppointment from "./Appointment/addAppointment";
import EditAppointment from "./Appointment/editAppointment";
import PageAppointment from "./Appointment/pageAppointment";
import ViewAppointment from "./Appointment/viewAppointment";
import AppointmentConsultDashoard from "./AppointmentConsltantDashboard";
import AppointmentConsultantSidebar from "./AppointmentConsultantSidear";
import AddConsultant from "./Consultant/addConsultant";
import EditConsultant from "./Consultant/editConsultant";
import ListConsultant from "./Consultant/listConsultant";
import PageConsultant from "./Consultant/pageConsultant";
import AddStatusMaster from "./StatusMaster/addStatusMaster";
import EditStatusMaster from "./StatusMaster/editStatusMaster";
import PageStatusMaster from "./StatusMaster/pageStatusMaster";
import ViewStatusMaster from "./StatusMaster/viewStatusMaster";
import AddTypeMaster from "./TypeMaster/addTypeMaster";
import EditTypeMaster from "./TypeMaster/editTypeMaster";
import PageTypeMaster from "./TypeMaster/pageTypeMaster";
import ViewTypeMaster from "./TypeMaster/viewTypeMaster";


export default class AppointmentConsultantMainApp extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []

    };

  }
  componentDidMount() {
    this.handleLine();
  }
  handleLine() {

    $(function () {
      $("#tab1").click(function () {
        $("#section").toggle();
      });
    });

  }

  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
      <div className="mainDiv" >

        <div className="row">
          <div className="col-md-2">
            <AppointmentConsultantSidebar />
          </div>
          <div className="col-md-10">


            <div className="section">
            <Route exact path="/appointment-consultant" component={AppointmentConsultDashoard} />

            <Route exact path="/appointment-consultant/add-consultant" component={AddConsultant} />
            <Route exact path="/appointment-consultant/view-consultant" component={ListConsultant} />
            <Route exact path="/appointment-consultant/view-consultant/:id" component={PageConsultant} />
            <Route exact path="/appointment-consultant/edit-consultant/:id" component={EditConsultant} />

            <Route exact path="/appointment-consultant/add-appointment" component={AddAppointment} />
            <Route exact path="/appointment-consultant/view-appointment" component={ViewAppointment} />
            <Route exact path="/appointment-consultant/view-appointment/:id" component={PageAppointment} />
            <Route exact path="/appointment-consultant/edit-appointment/:id" component={EditAppointment} />



            <Route exact path="/appointment-consultant/add-typeMaster" component={AddTypeMaster} />
            <Route exact path="/appointment-consultant/view-typeMaster" component={ViewTypeMaster} />
            <Route exact path="/appointment-consultant/view-typeMaster/:id" component={PageTypeMaster} />
            <Route exact path="/appointment-consultant/edit-typeMaster/:id" component={EditTypeMaster} />

            <Route exact path="/appointment-consultant/add-statusMaster" component={AddStatusMaster} />
            <Route exact path="/appointment-consultant/view-statusMaster" component={ViewStatusMaster} />
            <Route exact path="/appointment-consultant/view-statusMaster/:id" component={PageStatusMaster} /> 
            <Route exact path="/appointment-consultant/edit-statusMaster/:id" component={EditStatusMaster} />      

              {/* <Route exact path="/tasks/add-tasks" component={addTasks} />
              <Route exact path="/tasks/view-tasks" component={viewTask} />


            <Route exact path="/tasks/view-tasksCategory/:id" component={pageTaskCategory} />
            <Route exact path="/tasks/add-tasksCategory" component={addTaskCategory} />
            <Route exact path="/tasks/view-tasksCategory" component={viewTaskCategory} /> */}



            </div>

          </div>
        </div>

      </div>

    );
  }
}
