import React, {Component} from 'react';
import "../css/consultant.css";
import EditProfit from '../../Professionals/Profile/editProfile'
import axios from 'axios'
import ReadOnly from '../../Professionals/Profile/profileReadOnly';
import PreviewConsultantBooking from './previewConsultantBooking';



export default class PreviewConsultant extends Component{
    constructor(props){
        super(props)
        this.state={
            email:localStorage.getItem('email'),
            isSubmit:true,
            isEdit:false,
            data:this.props.data,
            personalDetail:this.props.data.personalDetail,
            highSchool:this.props.data.education.highSchool,
            interSchool:this.props.data.education.interSchool,
            graduation:this.props.data.education.graduation,
            postGraduation:this.props.data.education.postGraduation,
            experience:this.props.data.experience,
            skill:this.props.data.skill,
            award:this.props.data.award,
            imageDetail:this.props.data.personalDetail.profileDetail,
            education:this.props.data.education,
            bio:this.props.data.bio,
            isEducation:true,
            productDetail:this.props.productDetail,
            bookingDetail:this.props.bookingDetail,
            feeDetail:this.props.feeDetail,
            validProfile:this.props.validProfile,
            profileId:this.props.profileId,
            memberId:this.props.memberId,
            breifBio:"",
            checkAbout:true,
            tabContent:0,
            isBookingDetail:false,
        }
        this.breifBio = this.breifBio.bind(this)
        this.checkAbout = this.checkAbout.bind(this)
        this.handleTab = this.handleTab.bind(this)
        this.checkTabActive = this.checkTabActive.bind(this)
        this.bookingPreview = this.bookingPreview.bind(this)
    }

    async submitProfile(){
        
        if(this.state.validProfile){
            this.updateProfile()
        }
        else{
            if(this.state.isSubmit){
                let data = {
                    personalDetail:this.props.data,
                    serviceDetail:this.props.productDetail,
                    bookingDetail:this.props.bookingDetail,
                    feeDetail:this.props.feeDetail,
                    memberId:this.props.memberId,
                    email:this.state.email,
                    isMemberExist:this.props.isMemberExist,
                    by:localStorage.userName,
                }
                axios({
                    url:'https://q07uwo7htc.execute-api.us-east-1.amazonaws.com/dev/businus/consultant/add',
                    method:'POST',
                    data:data,
                  }).then((response)=>{
                     console.log(response.data)
                     if(response.status){
                            alert('Your data is saved')
                            window.location.replace("/appointment-consultant/add-consultant")
                     }
                  })
                  this.setState({data:data,isSubmit:false})
            }
            else{
                alert("Your response is already submitted")
            }
        }
        
        
    }

    updateProfile(){
        if(this.state.isSubmit){
            let data={
                personalDetail:this.props.data,
                serviceDetail:this.props.productDetail,
                bookingDetail:this.props.bookingDetail,
                feeDetail:this.props.feeDetail,
                email:this.props.email
            }
            axios({
                url:"https://q07uwo7htc.execute-api.us-east-1.amazonaws.com/dev/businus/updateConsultant/"+this.props.profileId,
                method:'POST',
                data:data,
            }).then((response)=>{
                if(response.status){
                    alert('Your data is updated')
                    window.location.replace("/appointment-consultant/add-consultant")
                }
            })
            this.setState({isSubmit:false})
        }
        else{
            alert('Your response is already submitted')
        }
    }

    componentWillMount(){
        this.eliminateDays(this.state.bookingDetail)
        this.breifBio(this)
        this.checkEducation(this)
        this.checkTabActive(this)
    }

    handleEdit(){
        this.setState({isEdit:true})
    }

    eliminateDays(bookingDetail){
        let flag = false
        let index = 0
        if(bookingDetail.length>0){
            for(var i=0;i<bookingDetail.length;i++){
                if(bookingDetail[i].booking.morning.length===0){
                    if(bookingDetail[i].booking.afternoon.length===0){
                        if(bookingDetail[i].booking.evening.length===0){
                            flag=true
                            index=i
                        }
                    }
                }
            }
        }

        if(flag){
            bookingDetail.splice(index,1)
        }
        this.setState({bookingDetail:bookingDetail})
    }

    breifBio(that){
        let bio = this.state.bio
        let allParagraphValue = ''

        if(bio.blocks!==undefined){
            for(var i=0; i<bio.blocks.length; i++){
                if(bio.blocks[i].type==='paragraph'){
                   allParagraphValue +=bio.blocks[i].data.text
                   allParagraphValue+=' '
                }
            }
        }

        let breifBio=''
        for(var i=0;i<350;i++){
            if(allParagraphValue[i]!==undefined){
                breifBio+=allParagraphValue[i]                
            }
            else{
                break
            }
        }
        that.setState({breifBio:breifBio})
    }

    checkEducation(that){
        let temp=true
        if(that.state.highSchool.school===""){
            if(that.state.interSchool.school===""){
                if(that.state.graduation.graduationCollege===""){
                    if(that.state.postGraduation.postCollege===""){
                        temp=false
                    }
                }
            }
        }

        that.setState({isEducation:temp})
        this.checkAbout(this,temp)
    }

    checkAbout(that,examCheck){
        let checkAbout = true
        if(that.state.bio.blocks===undefined){
            if(examCheck){
                if(that.state.experience.length===0){
                    if(that.state.skill.length===0){
                        if(that.state.award.length===0){
                            checkAbout = false
                        }
                    }
                }
            }
        }
        if(!checkAbout){
            this.activeChange(1)
        }
        that.setState({checkAbout:checkAbout})
    }

    checkTabActive(that){
        let isEducation= that.state.isEducation
        let index=3
        if(isEducation){
            index=0
        }
        else{
            if(that.state.experience.length!==0){
                index=1
            }
            else{
                if(that.state.skill.length!==0){
                    index=2
                }
            }
           
        }
        this.handleTab(index)
    }

    handleTab(index){
        let elem1=document.getElementById(this.state.tabContent)
        if(elem1!==null){
            elem1.classList.remove('tab-d-active')
        }

        let elem2 =document.getElementById(index)
        if(elem2!==null){
            elem2.classList.add('tab-d-active')
        }

        this.setState({tabContent:index})
    }

    bookingPreview(isBooking){
        this.setState({isBookingDetail:isBooking})
    }

    render(){
        const {tabContent} = this.state
        return(
            <div>
                
                {this.state.isBookingDetail?
                    this.state.bookingDetail.length!==0?
                        <div>
                            <PreviewConsultantBooking bookingPreview={this.bookingPreview} personalDetail={this.state.personalDetail} bookingDetail={this.state.bookingDetail} feeDetail={this.state.feeDetail} />
                        </div>
                        :"" 
                        :
                    
                this.state.isEdit?
                    <EditProfit profileId={this.state.profileId} validProfile={this.state.validProfile} data={this.state.data} productDetail={this.state.productDetail}  bookingDetail={this.state.bookingDetail} feeDetail={this.state.feeDetail} />:
                    <div> 
            <div className="user-profile-container">
                <button className='user-profile-edit' onClick={()=>this.handleEdit()}>
                <i class="fas fa-edit"></i> <span>   Edit </span>
                </button>
               <div className='user-background-image' style={{background:(this.state.imageDetail.coverImageId!==undefined?"url("+this.state.imageDetail.coverImageUrl+")":"#2978d057")}}></div> 
                <div className="user-detail">

                    {this.state.personalDetail.name!==undefined?
                        
            <div className="row spacing-user-detail">
                   <div className="col-md-3 col-lg-3 col-sm-12">
                       {this.state.imageDetail.profileImageId!==undefined?
                            <img className='user-profile-image' src={this.state.imageDetail.profileImageUrl}/>:
                            <img className='user-profile-image' src={"https://businus-files-live.s3.amazonaws.com/default-image/user-profile.jpg"}/>
                        }
                       
                   </div>
                   <div className="user-header-name ml-1"> 
                        {this.state.personalDetail.name}
                        {this.state.bookingDetail!==undefined? <button type='submit' onClick={()=>this.bookingPreview(true)} className='userConsult'>  Consult </button>:""}
                    </div>
                   <div className='col-md-5 col-lg-5 col-sm-6'>
                           <tr>
                               <td className="user-inf-t"> Experience : </td>
                               <td className="user-inf-d"> {this.state.personalDetail.experience} </td>
                           </tr>
                           <tr>
                               <td className="user-inf-t"> Practise & Area : </td>
                               <td className="user-inf-d"> {this.state.personalDetail.practise}</td>
                           </tr>
                           <tr>
                               <td className="user-inf-t"> Location : </td>
                               <td className="user-inf-d "> {this.state.personalDetail.location}  </td>
                           </tr>
                           <tr>
                               <td className="user-inf-t"> Languages : </td>
                               <td className="user-inf-d "> {this.state.personalDetail.language} </td>
                           </tr>
                           
                   </div>
                   <p className='col-md-4 col-lg-4 col-sm-6 user-inf-a'>
                    {this.state.bio.blocks!==undefined?
                    <div>
                        {/* <BreifReadOnly data={this.state.profile.bio}/> */}
                        <p> {this.state.breifBio} <span><a href="#bio">Read more</a></span> </p></div>
                    :""}
                   </p>
               </div>:""  
                }

                <div style={{margin:"0 2%"}}>
                    <div className='tab-content'>
                    <div className='row'>
                    <ul className="profile-tab-c col-md-3"> 
                    {this.state.checkAbout?
                        <li id='tab_0' className='profile-tab active'>
                           <a href="#bio"> About </a>                           
                        </li>:""}
                        <li id='tab_1' className='profile-tab '>
                           <a href="#clientReview"> Client Review </a>                            
                        </li>
                        {this.state.serviceDetail!==undefined?
                            <li id='tab_2' className='profile-tab '>
                                <a href="#product"> Services </a>                            
                            </li>
                        :""}
                    </ul>
                    </div>
                    {this.state.bio.blocks!==undefined?
                    <div>
                        <div className='tab-heading-m ' id='bio'>
                            <div className='tab-heading px-4 w-12 py-1'>About</div>
                    </div>
                            
                            <div className='row about' >
                                {this.state.bio.blocks!==undefined?
                                <ReadOnly data={this.state.bio}/>
                                    :""
                                    }
                            </div>
                        </div>:""
                    }
                    </div>

                    <div className='maintab'>
                            <div className='tab-ul'>
                             {this.state.isEducation?
                                <button id={0} onClick={()=>this.handleTab(0)} className='tab-dropdown'>
                                    Education
                                </button>:"" }   
                                
                            {this.state.experience.length!==0?
                            <button id={1} onClick={()=>this.handleTab(1)} className='tab-dropdown'>
                                Experience
                            </button>:""}
                            
                            {this.state.skill.length!==0?
                            <button id={2} onClick={()=>this.handleTab(2)} className='tab-dropdown'>
                                Skills
                            </button>:''}
                            
                            {this.state.award.length!==0?
                            <button id={3} onClick={()=>this.handleTab(3)} className='tab-dropdown'>
                                Award List
                            </button>:""
                            }
                            </div>
                        </div>

                        <div className='maintab-content' style={{marginLeft:"2%"}}>
                            {tabContent===0?
                                this.state.isEducation?
                                <div>
                                    {this.state.postGraduation.postCollege!==undefined?
                                    <div>
                                    <h5 className="maintab-content-t">Post Graduation :</h5>
                                    <li className='maintab-content-st'> College Name : {this.state.postGraduation.postCollege} </li>
                                    <li className='maintab-content-st'> Marks in {this.state.postGraduation.postMarkType} is {this.state.postGraduation.postMark}</li>
                                    <li className='maintab-content-st'> Course : {this.state.postGraduation.courseTypePostGraduation}</li>
                                    <li className='maintab-content-st'> Passing Year : {this.state.postGraduation.postPassingYear}</li>
                                    </div>:""}

                                    {this.state.graduation.graduationCollege!==undefined?
                                    <div>
                                    <h5 className="maintab-content-t">Graduation :</h5>
                                    <li className='maintab-content-st'> College Name : {this.state.graduation.graduationCollege} </li>
                                    <li className='maintab-content-st'> Marks in {this.state.graduation.graduationMarkType} is {this.state.graduation.graduationMark}</li>
                                    <li className='maintab-content-st'> Course : {this.state.graduation.courseTypeGraduation}</li>
                                    <li className='maintab-content-st'> Passing Year : {this.state.graduation.graduationPassingYear}</li></div>:""}
                                    
                                    {this.state.interSchool.school!==undefined?
                                    <div>
                                    <h5 className="maintab-content-t">12th School :</h5>
                                    <li className='maintab-content-st'> College Name : {this.state.interSchool.school} </li>
                                    <li className='maintab-content-st'> Marks in {this.state.interSchool.markType} is {this.state.interSchool.mark}</li>
                                    <li className='maintab-content-st'> Course : {this.state.interSchool.courseType}</li>
                                    <li className='maintab-content-st'> Passing Year : {this.state.interSchool.passingYear}</li></div>:""}

                                    {this.state.highSchool.school!==undefined?
                                    <div>
                                    <h5 className="maintab-content-t">10th School :</h5>
                                    <li className='maintab-content-st'> College Name : {this.state.highSchool.school} </li>
                                    <li className='maintab-content-st'> Marks in {this.state.highSchool.markType} is {this.state.highSchool.mark}</li>
                                    <li className='maintab-content-st'> Course : {this.state.highSchool.courseType}</li>
                                    <li className='maintab-content-st'> Passing Year : {this.state.highSchool.passingYear}</li></div>:""}
                                    
                                    

                                </div>:"":
                            tabContent===1?
                            this.state.experience.length!==0?
                            <div>
                                {this.state.experience.map((exp,index)=>(
                                    <div>
                                        <h5 className="maintab-content-t">Experience {index+1} :</h5>
                                        <li className='maintab-content-st'> Title : {exp.title} </li>
                                        <li className='maintab-content-st'> Employement Type : {exp.employementType} </li>
                                        <li className='maintab-content-st'> Company Name : {exp.companyName} </li>
                                        <li className='maintab-content-st'> Location : {exp.location} </li>
                                        <li className='maintab-content-st'> Start Date : {exp.startDate} & End Date: {exp.endDate} </li>
                                        <li className='maintab-content-st'> Description {exp.description}</li>
                                    </div>
                                ))}
                            </div>:"":
                            tabContent===2?
                            this.state.skill.length!==0?
                            <div>
                                <h5 className="maintab-content-t">Skills :</h5>
                                {this.state.skill.map((skill)=>(
                                        <li className='maintab-content-st'> {skill.skill} </li>
                                ))}
                            </div>:"":
                            tabContent===3?
                            this.state.award.length!==0?
                            <div>
                                <h5 className="maintab-content-t">Award :</h5>
                                {this.state.award.map((award)=>(
                                    <li className='maintab-content-st'> {award.award} </li>
                                ))}

                            </div>:"":""
                            }
                        </div>

                </div>

                {this.state.productDetail.length!==0?
            
            <div style={{margin:"2%"}}>
             <div style={{marginBottom:'2%'}} className='tab-heading-client-c ' id="product">
                 <div className='tab-heading px-4 w-12 py-1'>Services</div>
             </div>
             <div className='row'>
                     {this.state.productDetail.map((prod,index)=>(
                         <div className='col-md-3 col-lg-3 col-sm-12'>
                         <div className='all-profile-img-container'>
                             <img style={{width:'20%',height:'40vh'}} src={prod.serviceImageUrl} /> <br/>
                             <div className='product-hover-container'>
                                 <h5> {prod.serviceDescription} </h5>
                             </div>
                         </div>
                         <div>
                             <h4> {prod.serviceName} </h4>
                         </div>
                     </div>
                     ))}
                 </div>
            </div>:''
         }
                    
                     
                    <button className="right-float nxt-button" onClick={()=>this.submitProfile()}>Submit</button>
                    </div>
</div>

</div>}

            </div>
        )
    }
}