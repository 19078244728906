import React from 'react';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReactTable from 'react-table';
import _ from "underscore";

class DragTrComponent extends React.Component {

    render() {
      const { children = null, rowInfo } = this.props;
      if (rowInfo) {
        // debugger;
        const { original, index } = rowInfo;
        const { id } = original;
        return (   
       
          <Draggable key={id} index={index} draggableId={id}>
            {(draggableProvided, draggableSnapshot) => (
              <div
                ref={draggableProvided.innerRef}
                {...draggableProvided.draggableProps}
                {...draggableProvided.dragHandleProps}
              >
                <ReactTable.defaultProps.TrComponent style={{ width: '100%' }}>
                  {children}
                </ReactTable.defaultProps.TrComponent>
              </div>
            )}
          </Draggable>
        )
        
            
      }
       else
        return (
          <ReactTable.defaultProps.TrComponent  >
            {children}
          </ReactTable.defaultProps.TrComponent>
        );
    }
  }
  
  class DropTbodyComponent extends React.Component {
    render() {
      const { children = null } = this.props;
  
      return (
        <Droppable droppableId="droppable">
          {(droppableProvided, droppableSnapshot) => (
            <div ref={droppableProvided.innerRef}>
              <ReactTable.defaultProps.TbodyComponent  >
                {children}
              </ReactTable.defaultProps.TbodyComponent>
            </div>
          )}
        </Droppable>
      );
    }
  }

class ColumnOneGST extends React.Component{
    constructor(props){
        super(props)
        this.state={
            actList:[]
        }
    }
    componentDidMount(){
        var lastUrl = window.location.pathname.split("/").pop()
        var page=''
        if(lastUrl==='circular-notification'){
            page='Circular Notification'
        }
        else if(lastUrl==='case-law'){
            page="Case Law"
        }
        else if(lastUrl==='acts'){
            page='Acts'
        }
        else if(lastUrl==='rules'){
            page='Rules'
        }

        this.fetchAct(page)
    }

    fetchAct(lastUrl){
        let actList=[]
        return axios({
            url: 'https://tj1cb28koh.execute-api.us-east-1.amazonaws.com/dev/businus/article/list',
            method: 'GET',
            crossDomain: true,
            responseType: 'json', // important
          }).then((response)=>{
              
            for(var i=0;i<response.data.length;i++){
                if(response.data[i].legalType==="GST"){
                    if(response.data[i].type===lastUrl){
                        actList.push(response.data[i])
                    }
                }
            }
            actList = _.sortBy(actList,"columnOneIndex")
            this.setState({actList:actList})
          })
    }

    updateIndex(Array,columnOneIndex){
        var act={
          id:Array.id,
          legalType: "GST",
          type: Array.type,
          law: Array.law,
          lawDes: Array.lawDes,
          section: Array.section,
          sectionDes: Array.sectionDes,
          heading: Array.heading,
          subHeading: Array.subHeading,
          content: Array.content,
          more: Array.more,
          columnOneIndex:columnOneIndex,
          columnSecondIndex:Array.columnSecondIndex,
          seo:Array.seo,
          slug:Array.slug,
          metaDescription:Array.metaDescription
        }
        console.log('update_index',act)
        axios({
          url: 'https://tj1cb28koh.execute-api.us-east-1.amazonaws.com/dev/businus/article/update/' + Array.id,
          method: 'POST',
          data: act,
        }).then((response) => {
          console.log('resonse_update_act :', response.data);
        });
      }

    handleDragEnd = result => {
        if (!result.destination) {
          return;
        }
    
        else{
          const actList = reorder(
            this.state.actList,
            result.source.index,
            result.destination.index
          );
          var temp =[]
          let source = result.source.index
          let destination = result.destination.index
    
          for(var i =0; i< this.state.actList.length;i++){
            if(this.state.actList[i].columnOneIndex===source){
              temp= this.state.actList[i]
             
            }
          }
    
          if(source<destination){
            for(var i =0; i< this.state.actList.length;i++){      
              for(var j=source+1;j<destination+1;j++){
                if(j===this.state.actList[i].columnOneIndex){
                  this.updateIndex(this.state.actList[i],j-1)
                }           
              }
            }
          }
          
          if(destination<source){
            for(var i =this.state.actList.length-1; i>=0 ;i--){  
              for(var j=source-1;j>destination-1;j--){         
    
                if(j===this.state.actList[i].columnOneIndex){
                  this.updateIndex(this.state.actList[i],j+1)
                }           
              }
            }
          }
         
          this.updateIndex(temp,destination)
          
          this.setState({
            actList
          });
        }
    
      };

    getTrProps = (state, rowInfo) => {
        return { rowInfo };
    };

    render(){
        const columns = [{
            Header: <div className="form-textHead cell-head">Article Id</div>,
            accessor: 'articleId',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:150,
          }, {
            Header: <div className="form-textHead cell-head">Type</div>,
            accessor: 'type',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:100,
          }, {
            Header: <div className="form-textHead cell-head">Law</div>,
            accessor: 'law',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:100,
          }, {
            Header: <div className="form-textHead cell-head">Law Description</div>,
            accessor: 'lawDes',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:200,
          },{
            Header: <div className="form-textHead cell-head">Section</div>,
            accessor: 'section',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:100,
          },{
            Header: <div className="form-textHead cell-head">Section Description</div>,
            accessor: 'sectionDes',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:200,
          },{
            Header: <div className="form-textHead cell-head">Heading</div>,
            accessor: 'heading',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:100,
          },{
            Header: <div className="form-textHead cell-head">Sub Heading</div>,
            accessor: 'subHeading',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:100,
          },
          {
            Header: <div className="form-textHead cell-head">SEO</div>,
            accessor: 'seo',
            filterMethod: (filter, row) => {debugger;   return row.catagory.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:200
          },{
            Header: <div className="form-textHead cell-head">Slug</div>,
            accessor: 'slug',
            filterMethod: (filter, row) => {debugger;   return row.catagory.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:220
          }, {
            Header: <div className="form-textHead cell-head">Meta Description</div>,
            accessor: 'metaDescription',
            filterMethod: (filter, row) => {debugger;   return row.catagory.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:220
          },];
        return(
            <div>
                <div className="container-fluid " style={{}}>
                <DragDropContext onDragEnd={this.handleDragEnd}> 
                <ReactTable 
                    TrComponent={DragTrComponent}
                    TbodyComponent={DropTbodyComponent}
                    getTrProps={this.getTrProps} 
                    data={this.state.actList} 
                    columns={columns} 
                    style={{height:'85vh',overflow:'scroll'}} filterable/>
                </DragDropContext>
                </div>
            </div>
        )
    }

}
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
}
export default ColumnOneGST