import $ from "jquery";
import React, { Component } from "react";
import { Route } from "react-router-dom";
import "../../style/panel.css";
import profileHomepage from "./Profile/profileHomepage";
import AddUserProfile from "./Profile/addUserProfile";
import UserProfile from "./Profile/userProfile";
import aboutUser from "./Profile/aboutUser";
import BookingFinal from "./Profile/BookingFinal";
import ProfessionalBookingList from "./Booking/ProfessionalBookingList";
import ProfessionalBookingDediacted from "./Booking/ProfessionalBookingDediacted";
import ProfessionalSidebar from "./Profile/ProfessionalSidebar";
const data = [];
export default class ProfessionalsMainApp extends Component {
  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: [],
    };
  }
  componentDidMount() {
    this.handleLine();
    //this.setState({title:this.props.match.params.productType});
  }
  handleLine() {
    const tab1 = this.refs.tab1;
    const sidebar1 = this.refs.sidebar1;
    const section = this.refs.section;

    $(function () {
      $("#tab1").click(function () {
        $("#section").toggle();
      });
    });
  }

  toggleOpen = (id) => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter((i) => i !== id)] });
    }
  };

  render() {
    return (
      <div className="mainDiv">
        <div className="row">
          <div className="col-md-2">
            <ProfessionalSidebar title={this.state.title} />
          </div>
          <div className="col-md-10">
            {/* all routes are defined below. They've been called in index.js as well*/}
            <div className="section">
              {/* <Route exact path="/tasks/home" component={Home} /> */}
              <Route
                exact
                path="/professionals/profile-homepage"
                component={profileHomepage}
              ></Route>
              <Route
                exact
                path="/professionals/profile/mybooking/list"
                component={ProfessionalBookingList}
              ></Route>
              <Route
                exact
                path="/professionals/professional-booking-page/:id"
                component={ProfessionalBookingDediacted}
              ></Route>

              <Route
                exact
                path="/professionals/profile"
                component={AddUserProfile}
              ></Route>
              <Route
                exact
                path="/professionals/add-profile"
                component={AddUserProfile}
              ></Route>
              <Route
                exact
                path="/professionals/profile/:id"
                component={UserProfile}
              ></Route>
              <Route
                exact
                path="/professionals/about/user/:id"
                component={aboutUser}
              ></Route>
              <Route
                exact
                path="/professionals/consult/booking/:id"
                component={BookingFinal}
              ></Route>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
