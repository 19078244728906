import React, { Component } from "react";
import { Link } from "react-router-dom";
import BreadCrumbs from "../../BreadCrumbs";
import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { ChatFeed, Message } from 'react-chat-ui'
import {validate} from '../../common/Validation';
import {Multiselect} from 'multiselect-react-dropdown';

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from 'underscore';
import { grey } from "ansi-colors";
let context = null;
const data = [];
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               :'0px ',
    position               :'Absolute',
    zIndex                 :'30000'     ,
    backgroundColor         :'none',  
  }
};
 
export default class AddClientsByButton extends Component {
  constructor(props) {
    super(props);
    this.state = {

      customerId:data.customerId,
      companyName:data.companyName,
      companyDisplayName:data.companyDisplayName,
      customerEmail:data.customerEmail,
      customerType:data.customerType,
      customerMode:data.customerMode,
      primaryContact:data.primaryContact,
      customerContact:data.customerContact,
      website:data.website,
      billingAddress:data.billingAddress,
      billingCountry:data.billingCountry,
      billingAttention:data.billingAttention,
      billingCity:data.billingCity,
      billingPhone:data.billingPhone,
      billingZipCode:data.billingZipCode,
      shippingAddress:data.shippingAddress,
      shippingCountry:data.shippingCountry,
      shippingAttention:data.shippingAttention,
      shippingCity:data.shippingCity,
      shippingPhone:data.shippingPhone,
      shippingZipCode:data.shippingZipCode,
      contactPersons:data.contactPersons,
      sliderState:1,
      canCustomise:false,
      role:'',
      selectedModules:'',
      superSliderState:'additional',
      bankName:data.bankName,
      branchName:data.branchName,
      accountNo:data.accountNo,
      accountType:data.accountType,
      ifscCode:data.ifscCode,
      micrCode:data.micrCode,
      modules:[{name:'legal',id:1},{name:'a',id:2},{name:'b',id:3}],
      bankDetails:{},
      contactPersons:[{
        
      salutation:"",
      name:"",
      email:"",
      phone:""
    
      }],
      employees:[],
      attributes:[],
      employees:[]
    };
    context = this;
    
  }

  changeSliderState(orderNumber){

    this.setState({sliderState:orderNumber});

}


fetchQueryById(that, id) {
  axios
    .get(
      apiBaseUrl5+"businusquery/querybyId/" +
        id
    )
    .then(function(response) {
      debugger;
      
      var data = response.data;
      that.setState({
        
         
          "companyName":data.company,
          "companyDisplayName":data.company,
          "customerEmail":data.email,
          /* "customerType":data.customerType, */
         /*  "customerMode":data.customerMode, */
          "salutation":data.title,
          "customerName":data.name,
          "customerContact":data.phone,
        /*   "website":data.website, */
         /*  "billingAddress":data.billingAddress, */
          "billingCountry":"India",
         /*  "billingAttention":data.billingAttention, */
          "billingCity":data.cityName,
          "billingState":data.state,
          "billingPhone":data.phone,
        /*   "billingZipCode":data.billingZipCode, */
         /*  "shippingAddress":data.shippingAddress, */
          "shippingCountry":"India",
        /*   "shippingAttention":data.shippingAttention, */
          "shippingCity":data.cityName,
          "shippingState":data.state,
          "shippingPhone":data.phone,
  /*         "shippingZipCode":data.shippingZipCode, */
        /*   "contactPersons":data.contactPersons,
          "bankDetails":data.bankDetails,
          "team":data.team,
          "attributes":data.attributes,
          "attributeVal":data.attributeVal,
          "attributeAttachment":data.attributeAttachment */

        
      })
     



    })
    .catch(function(error) {
      console.log(error);
    });
}
  //function for changing the status button on click to open or close
  handleOnChange = (e) => {
  debugger;
    var value = e.target.value;
  
    var formName = e.target.name;

      this.setState({[formName]:value});

      if(formName=='role' && value=="customise")
      {this.setState({canCustomise:true})
      }
      if(formName=='role' && value=="admin")
      {this.setState({canCustomise:false})
      }
      if(formName=='role' && value=="employee")
      {this.setState({canCustomise:false})
      }
      


    console.log(this.state);
  }

  onSelect(optionsList, selectedItem) {

    context.setState({selectedModules:optionsList});

    console.log(optionsList,selectedItem);
}

onRemove(optionList, removedItem) {
  context.setState({modules:optionList});
}

    //function for changing the status button on click to open or close
    handleOnBankDetailsChange = (e) => {
      debugger;
        var value = e.target.value;
      
        var formName = e.target.name;
    
          this.setState({bankDetails:
            {...this.state.bankDetails,[formName]:value}});
    
    
    
        console.log(this.state);
      }
  
  handleOnChangePerson = (e,i)=>{
    
    var contactPersons = this.state.contactPersons;

    contactPersons[i][e.target.name] = e.target.value;

    this.setState({contactPersons:contactPersons});




  }


  fetchEmployees(that,source){

    axios.get(apiBaseUrl2+"businus/employee/list/"+localStorage.spId)
    .then(function (response) {
      debugger;
      console.log(response.data);
      var allEmployees = response.data;

      var teamLeads  = _.where(allEmployees,{title:'Team Lead'});
      var teamMembers  = _.filter(allEmployees,function(emp){
        return emp.title!='Team Lead';
      });


      var teamMembersMultiSelectData = []

      _.each(teamMembers,function(member){

        
        teamMembersMultiSelectData.push({
          name:member.firstName + member.lastName,
          id:member.id
        })





      });


      that.setState({teamMembers:teamMembersMultiSelectData,teamLeads:teamLeads});
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }


  addNewContact = () =>{

    var newPerson = {
      salutation:"",
      name:"",
      email:"",
      phone:""
    }

    var contactPersons = this.state.contactPersons;
    contactPersons.push(newPerson);
    this.setState({contactPersons:contactPersons});
 


  }
  
 

  componentDidMount() {

    const id = this.props.match.params.id;
    this.fetchQueryById(this,id);
    this.fetchEmployees(this);
  }


 


  //fetching the data by id which this file recieved from the list

  changeSuperSliderState(name) {

    this.setState({ superSliderState: name });
    if(name==='additional'){
      this.setState({ sliderState: 1 });
    }
    else{
      this.setState({ sliderState: 6 });
    }
  

  }
  
  
  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });



          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  getFiles(files) {
    this.setState({ selectedFile: files[0] });

  }

  getFormattedDate(millis){

    return new Date(millis).toDateString();


  }

  
  
  changeDocType(type){

    this.setState({docType:type});
  }


  

  sendMail() {
     var data = context.state;
     
     if(validate('required',data.companyName,data.companyDisplayName)){

      alert
      ("required fields");
      return;
    }
    if(validate("email",data.customerEmail)){
      alert("email not valid");
      return;
    }
     if(validate("mobile",data.customerContact)){

      alert("phone number not valid");
      return;
    } 

  



    axios
      .post(
        apiBaseUrl2+"businus/customer/add",
        {
          "customerId":data.customerId,
          "companyName":data.companyName,
          "companyDisplayName":data.companyDisplayName,
          "customerEmail":data.customerEmail,
          "customerType":data.customerType,
          "customerMode":data.customerMode,
          "salutation":data.salutation,
          "primaryContact":data.customerName,
          "customerContact":data.customerContact,
          "website":data.website,
          "billingAddress":data.billingAddress,
          "billingCountry":data.billingCountry,
          "billingAttention":data.billingAttention,
          "billingCity":data.billingCity,
          "billingState":data.billingState,
          "billingPhone":data.billingPhone,
          "billingZipCode":data.billingZipCode,
          "shippingAddress":data.shippingAddress,
          "shippingCountry":data.shippingCountry,
          "shippingAttention":data.shippingAttention,
          "shippingCity":data.shippingCity,
          "shippingState":data.shippingState,
          "shippingPhone":data.shippingPhone,
          "shippingZipCode":data.shippingZipCode,
          "contactPersons":data.contactPersons,
          "bankDetails":data.bankDetails,
          "team":data.team,
          "attributes":data.attributes,
          "attributeVal":data.attributeVal,
          "attributeAttachment":data.attributeAttachment,
          "by":localStorage.userName 
  
        })
      .then(function(response) {
        debugger;
      alert("Success!");
    //  context.fetchThreadById(context,query.id);
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  setMailPageUrl(){
  var url = window.location.href.replace(window.location.origin,"");
  localStorage.setItem("MAILURL",url);


  }

  onSelect(optionsList, selectedItem) {

    context.setState({team:optionsList});

    console.log(optionsList,selectedItem);
}

onRemove(optionList, removedItem) {
  context.setState({team:optionList});
}





  //updating the status button
  
  render() {
    const {sliderState} = this.state;
    const {superSliderState, entry} = this.state;    
    const active = " active-button"
    const deactive = "";
    return (
      <div className="dedicated-div">
   <div className="row breadCrumb1-row">
         Add Client
        </div>
  {/* showing all the details of a single item from the table at the backend*/}
  <div className="row">
    <div className="dedicated-page" style={{padding:'30px'}}>
      <div className="row ">
      <h4  className="account-head" > Basic info</h4>
        <div className="col-md-12 info-column account-col2">
          <div id="field">
            
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                      
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        {" "}
                        Customer Mode* :{" "}
                      </span>{" "}
                    </label>
                    <div className="col-md-8">
                      <div className="radio-inline">
                        <label className="cursor-poiner">
                          <input
                            name="customerMode"
                          
                           
                            type="radio"
                            onChange={this.handleOnChange}
                            defaultValue="Online"
                          />
                          Online :{" "}
                        </label>
                      </div>
                      <div className="radio-inline">
                        <label className="cursor-poiner">
                          {" "}
                          <input
                            name="customerMode"
                            onChange={this.handleOnChange}
                           
                            type="radio"
                            defaultValue="Offline"
                          />
                          Offline :{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                  </div>
                <div className="col-md-8">
                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                      
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        {" "}
                        Customer Type* :{" "}
                      </span>{" "}
                    </label>
                    <div className="col-md-8">
                      <div className="radio-inline">
                        <label className="cursor-poiner">
                          <input
                            name="customerType"
                          
                           
                            type="radio"
                            onChange={this.handleOnChange}
                            defaultValue="business"
                          />
                          Business :{" "}
                        </label>
                      </div>
                      <div className="radio-inline">
                        <label className="cursor-poiner">
                          {" "}
                          <input
                            name="customerType"
                            onChange={this.handleOnChange}
                           
                            type="radio"
                            defaultValue="individual"
                          />
                          Individual :{" "}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                        id="ember295"
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        {" "}
                        Primary Contact* :{" "}
                      </span>{" "}
                    </label>
                    <div
                      className="inline-fields col-md-8"
                      style={{ padding: 0 }}
                    >
                      <div className="col-md-2">
                        <select
                          placeholder="Salutation"
                          autofocus
                          onChange={this.handleOnChange}
                          name="salutation"
                        value={this.state.salutation}
                          className="form-control select-form"
                        >
                          <option>Mr.</option>
                          <option>Mrs</option>
                          <option>Ms</option>
                        </select>
                      </div>

                      <div className="col-md-8">
                        <input
                          placeholder="Name"
                          autofocus
                          id="ember299"
                          onChange={this.handleOnChange}
                          name="customerName"
                        value={this.state.customerName}
                          className="form-control ember-text-field ember-view"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  

                  <div className="form-group">
                    <label className="control-label col-md-4">
                      {" "}
                      Company Name* :{" "}
                    </label>
                    <div className="col-md-6">
                      <input
                        id="ember301"
                        onChange={this.handleOnChange}
                        name="companyName"
                      value={this.state.companyName}
                        className="form-control ember-text-field ember-view"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label col-md-4">
                      {" "}
                      Company Display Name* :{" "}
                    </label>
                    <div className="col-md-6">
                      <input
                        id="ember301"
                        onChange={this.handleOnChange}
                        name="companyDisplayName"
                      value={this.state.companyDisplayName}
                        className="form-control ember-text-field ember-view"
                        type="text"
                      />
                    </div>
                  </div>

             {/*      <div className="form-group">
                    <label className="control-label col-md-4">
                      Customer Display Name
                    </label>
                    <div className="col-md-6">
                      <input
                          onChange={this.handleOnChange}
                          name="customerDisplayName"
                        value={this.state.customerDisplayName}
                        className="form-control ember-text-field ember-view"
                        type="text"
                      />
                    </div>
                  </div> */}

                  <div className="form-group">
                    <label className="control-label col-md-4">
                      Customer Email* :
                    </label>
                    <div className="col-md-6">
                      <input
                        name="customerEmail"
                        onChange={this.handleOnChange}
                        value={this.state.customerEmail}
                        className="form-control ember-text-field ember-view"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="control-label col-md-4">
                      Customer Phone* :
                    </label>
                    <div className="col-md-6">
                      <input
                        name="customerContact"
                        onChange={this.handleOnChange}
                        value={this.state.customerContact}
                        className="form-control ember-text-field ember-view"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="control-label col-md-4">Website* :</label>
                    <div className="col-md-6">
                      <input
                        name="website"
                        onChange={this.handleOnChange}
                        value={this.state.website}
                        className="form-control ember-text-field ember-view"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          
    
     



    
     <div className="row " style={{marginTop:'40px'}}>
     <div className={superSliderState == 'additional' ? "col-md-2 active1 superoptions" : "col-md-2 superoptions"} >
       <div className=" " onClick={() => this.changeSuperSliderState('additional')} >Additional Info</div>
     </div>

     
     

   </div>


{superSliderState==='additional' ? 

   <div className="row " style={{borderTop:'2px solid #4985BD', backgroundColor:'#f7f7f7'}}>
     <div className={sliderState == 1 ? "col-md-2 active2 " : "col-md-2"} >
       <div className="options8 " onClick={() => this.changeSliderState(1)} ><i className="fa fa-home optionsIcon" style={{fontSize:'12px'}}></i>Address</div>
     </div>
     <div className={sliderState == 2 ? "col-md-3 active2 " : "col-md-3"} >
       <div className="options8" onClick={() => this.changeSliderState(2)}><i className="fa fa-envelope optionsIcon" style={{fontSize:'12px'}}></i>Customer Email</div>
     </div>
     <div className={sliderState == 3 ? "col-md-3 active2 " : "col-md-3"} >
       <div className="options8" onClick={() => this.changeSliderState(3)}><i className="fa fa-university optionsIcon" style={{fontSize:'12px'}}></i> Bank Details</div>
     </div>
     <div className={sliderState == 4 ? "col-md-2 active2 " : "col-md-2"} >
       <div className="options8" onClick={() => this.changeSliderState(4)}><i className="fa fa-address-card optionsIcon" style={{fontSize:'12px'}}></i> Attributes</div>
     </div>
     <div className={sliderState == 5 ? "col-md-2 active2 " : "col-md-2"} >
       <div className="options8" onClick={() => this.changeSliderState(5)}><i className="fa fa-users optionsIcon" style={{fontSize:'12px'}}></i> Employees</div>
     </div>
   </div>

   
:''}

{/*--------------------------------------------------------1-------------------------------*/}




            {sliderState==1 ?

            <div className="container row">
              <div className="col-md-6">
                <div className="row" style={{marginBottom:'-20px'}}>
                  <label className="control-label add-header col-md-12">
                    Billing Address
                  </label>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-4">Attention :</label>
                  <div className="col-md-7">
                    <input
                      name="billingAttention"
                      onChange={this.handleOnChange}
                      value={this.state.billingAttention}
                      className="form-control ember-text-field ember-view"
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-4">Country :</label>
                  <div className="col-md-7">
                    <input
                      name="billingCountry"
                      onChange={this.handleOnChange}
                      value={this.state.billingCountry}
                      className="form-control ember-text-field ember-view"
                      type="text"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-md-4">Address* :</label>
                  <div className="col-md-7">
                    <textarea
                      placeholder="Billing Address"
                      name="billingAddress"
                      onChange={this.handleOnChange}
                      value={this.state.billingAddress}
                      id="ember330"
                      className="form-control ember-text-area ember-view"
                      defaultValue={""}
                    />
                  </div>
                </div>

                <div className="form-group" style={{marginTop:'36px'}}>
                  <label className="control-label col-md-4">City* :</label>
                  <div className="col-md-7">
                    <input
                      id="ember332"
                      name="billingCity"
                      onChange={this.handleOnChange}
                      value={this.state.billingCity}
                      className="form-control ember-text-field ember-view"
                      type="text"
                    />{" "}
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-4">State :</label>
                  <div className="col-md-7">
                    <input
                      id="ember333"
                      className="form-control ember-text-field ember-view"
                      name="billingState"
                      onChange={this.handleOnChange}
                      value={this.state.billingState}
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-4">Zip Code* :</label>
                  <div className="col-md-7">
                    <input
                      id="ember334"
                      name="billingZipCode"
                      onChange={this.handleOnChange}
                      value={this.state.billingZipCode}
                      className="form-control ember-text-field ember-view"
                      type="text"
                    />{" "}
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-4">Phone* :</label>
                  <div className="col-md-7">
                    <input
                      id="ember335"
                      name="billingPhone"
                      onChange={this.handleOnChange}
                      value={this.state.billingPhone}
                      className="form-control ember-text-field ember-view"
                      type="text"
                    />{" "}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="row" style={{marginBottom:'-20px'}}>
                  <label className="control-label add-header col-md-12">
                    Shipping Address
                  </label>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-4">Attention :</label>
                  <div className="col-md-7">
                    <input
                      name="shippingAttention"
                      onChange={this.handleOnChange}
                      value={this.state.shippingAttention}
                      className="form-control ember-text-field ember-view"
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-4">Country :</label>
                  <div className="col-md-7">
                    <input
                      name="shippingCountry"
                      onChange={this.handleOnChange}
                      value={this.state.shippingCountry}
                      className="form-control ember-text-field ember-view"
                      type="text"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-md-4">Address :</label>
                  <div className="col-md-7">
                    <textarea
                      placeholder="Shipping Address"
                      name="shippingAddress"
                      onChange={this.handleOnChange}
                      value={this.state.shippingAddress}
                      id="ember330"
                      className="form-control ember-text-area ember-view"
                      defaultValue={""}
                    />
                  </div>
                </div>

                <div className="form-group" style={{marginTop:'36px'}}>
                  <label className="control-label col-md-4">City:</label>
                  <div className="col-md-7">
                    <input
                      id="ember332"
                      name="shippingCity"
                      onChange={this.handleOnChange}
                      value={this.state.shippingCity}
                      className="form-control ember-text-field ember-view"
                      type="text"
                    />{" "}
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-4">State :</label>
                  <div className="col-md-7">
                    <input
                      id="ember333"
                      className="form-control ember-text-field ember-view"
                      name="shippingState"
                      onChange={this.handleOnChange}
                      value={this.state.shippingState}
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-4">Zip Code :</label>
                  <div className="col-md-7">
                    <input
                      id="ember334"
                      name="shippingZipCode"
                      onChange={this.handleOnChange}
                      value={this.state.shippingZipCode}
                      className="form-control ember-text-field ember-view"
                      type="text"
                    />{" "}
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-4">Phone :</label>
                  <div className="col-md-7">
                    <input
                      id="ember335"
                      name="shippingPhone"
                      onChange={this.handleOnChange}
                      value={this.state.shippingPhone}
                      className="form-control ember-text-field ember-view"
                      type="text"
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
            :''}

{/*--------------------------------------------------------2-------------------------------*/}
{sliderState==2 ? 
<div>
  <div className="row" style={{marginTop:'20px'}}>
    <div className="col-md-12">
    <div className="row" style={{marginBottom:'-20px'}}>
      <label className="control-label add-header col-md-12">
        Contact Persons*
      </label>
    </div>
  <div className="text-right" style={{marginRight:'40px'}}>
 <button className="add-button" onClick={this.addNewContact} > <i className="fa fa-plus "></i></button>
  </div>
              <table className="add-customer-table" style={{width:"100%"}}>
  <tr>
    <th className="table-head">Salutation</th>
    <th className="table-head">Name</th>
  
    <th className="table-head">Email Address </th>
    <th className="table-head">Work Phone</th>
  </tr>
  {this.state.contactPersons.map((person, i) => (
         
         <tr>
            <td className="table-item">
              <select className="form-control select-form" name="salutation" placeholder="salutation" onChange={(e) => this.handleOnChangePerson(e,i)} value={this.state.contactPersons[i].salutation}>  
         <option>Mr.</option>
         <option>Mrs</option>
         <option>Ms</option>
       </select>
       </td>
         <td className="table-item"><input name="name" onChange={(e) => this.handleOnChangePerson(e,i)} 
         value={this.state.contactPersons[i].name}/> </td>
         <td className="table-item"><input name="email" onChange={(e) => this.handleOnChangePerson(e,i)} value={this.state.contactPersons[i].email}/> </td>
         <td className="table-item"><input name="phone" onChange={(e) => this.handleOnChangePerson(e,i)} value={this.state.contactPersons[i].phone}/> </td>
         </tr>
         
        
         ))}
 
 
</table></div></div></div> :''}

{/*--------------------------------------------------------3-------------------------------*/}


{sliderState==3 ?

<div className="container row">
  <div className="col-md-12">
    <div className="row" style={{marginBottom:'-20px'}}>
      <label className="control-label add-header col-md-12">
        Banking Details
      </label>
    </div>
    <div className="form-group">
      <label className="control-label col-md-4">Bank Name* :</label>
      <div className="col-md-8">
        <input
          name="bankName"
          onChange={this.handleOnBankDetailsChange}
          value={this.state.bankDetails.bankName}
          className="form-control ember-text-field ember-view"
          type="text"
        />
      </div>
    </div>
    <div className="form-group">
      <label className="control-label col-md-4">Branch Name*:</label>
      <div className="col-md-8">
        <input
          name="branchName"
          onChange={this.handleOnBankDetailsChange}
          value={this.state.bankDetails.branchName}
          className="form-control ember-text-field ember-view"
          type="text"
        />
      </div>
    </div>

    <div className="form-group">
      <label className="control-label col-md-4">Account No*:</label>
      <div className="col-md-8">
        <input
         name="accountNo"
          onChange={this.handleOnBankDetailsChange}
          value={this.state.bankDetails.accountNo}
          id="ember330"
          className="form-control ember-text-field ember-view"
          type="text"
        />
      </div>
    </div>

    <div className="form-group" >
      <label className="control-label col-md-4">Account Type:</label>
      <div className="col-md-6">
       
         <select
          name="accountType"
          onChange={this.handleOnBankDetailsChange}
          value={this.state.bankDetails.accountType}
          className="form-control ember-text-field ember-view"
          type="text"
        >
          <option value="pan">Select One</option>
         <option value="pan">Savings</option>
  <option value="passport">Current</option>
  <option value="aadhar">Credit Card A/C</option>
  <option value="voterid">Loans</option>
  <option value="other">Overdraft</option>
  <option value="other">Deposit</option>
</select>
      </div>
    </div>
    <div className="form-group">
      <label className="control-label col-md-4">IFSC Code* :</label>
      <div className="col-md-8">
        <input
          id="ember333"
          className="form-control ember-text-field ember-view"
          name="ifscCode"
          onChange={this.handleOnBankDetailsChange}
          value={this.state.bankDetails.ifscCode}
          type="text"
        />
      </div>
    </div>
    <div className="form-group">
      <label className="control-label col-md-4">MICR Code :</label>
      <div className="col-md-8">
        <input
          id="ember334"
          name="micrCode"
          onChange={this.handleOnBankDetailsChange}
          value={this.state.bankDetails.micrCode}
          className="form-control ember-text-field ember-view"
          type="text"
        />{" "}
      </div>
    </div>
  
  </div>

  
  
</div>
:''}


{ sliderState== 4 ? <div className="container row">
  <div className="col-md-12">
    <div className="row" style={{marginBottom:'-20px'}}>
      <label className="control-label add-header col-md-12">
        Attributes
      </label>
    </div>

    <div className="form-group">
      <label className="control-label col-md-4">Attributes* :</label>
      <div className="col-md-7">
      <select
          name="attributes"
          onChange={this.handleOnChange}
          value={this.state.attributes}
          className="form-control ember-text-field ember-view"
          type="text"
        >
         <option value="pan">PAN</option>
 
  <option value="aadhar">Aadhar Card Number</option>
  <option value="aadhar">Authorised Capital</option>
  <option value="aadhar">Category</option>
  <option value="aadhar">CIN no</option>
  <option value="aadhar">DIN no</option>
  <option value="aadhar">Excise No</option>
  <option value="aadhar">GST No</option>
  <option value="aadhar">Incorporation Date</option>
  <option value="aadhar">Paid Up Capital</option>
  <option value="voterid">PAN</option>
  <option value="voterid">Pt No.(EC)</option>
  <option value="voterid">Pt No.(RC)</option>
  <option value="voterid">Service Tax No.</option>
  <option value="voterid">Shop Act Expiry Date.</option>
  <option value="voterid">Shop Act No.</option>
  <option value="voterid">Shop Act Reg Date.</option>
  <option value="voterid">TAN No</option>
  <option value="voterid">VAT No.</option>
  <option value="voterid">With Effect From (W.E.F)</option>
 
</select>
      </div>
    </div>

    <div className="form-group">
      <label className="control-label col-md-4">Attribute Value:</label>
      <div className="col-md-8">
        <input
          
          name="attributeVal"
          onChange={this.handleOnChange}
          value={this.state.attributeVal}
          className="form-control ember-text-field ember-view"
          type="text"
        />{" "}
      </div>
    </div>
    <div className="form-group">
                          <label className="control-label col-md-4">

                            Attribute Attachment
                    </label>
                          <div className="col-md-6">
               
 <FileBase64
multiple={true}
onDone={this.getFiles.bind(this)}   /></div>

<div className="col-md-5">

<button className="btn btn-primary attachment" onClick={() => this.fileUploadHandler(this, "attributeAttachment")}
value="Select a File">
<i className="fa fa-paperclip" aria-hidden="true"></i>Attachment
</button>
                          </div>
                        </div>

    </div> 
    </div> 
    : ''}



{ sliderState== 5 ? <div className="container row">
  <div className="col-md-12">
    <div className="row" style={{marginBottom:'-20px'}}>
      <label className="control-label add-header col-md-12">
        Attributes
      </label>
    </div>

    <div className="form-group">
                    <label className="control-label col-md-4">Team*:</label>
                    <div className="col-md-6">

                   {this.state.teamMembers.length > 0 ?  
                   <Multiselect
                        options={this.state.teamMembers} 
                        onSelect={this.onSelect} 
                        placeholder='Select One or More'
                        onRemove={this.onRemove} 
                        displayValue="name" 
                    />
: ''}
                      
                    </div>
                  </div>

    </div> 
    </div> 
    : ''}



{ sliderState==6 ?   
<div>
  <div className="row">
 <div className="col-md-6">
 
 <div className="form-group">
                    <label className="control-label col-md-4">Module Access:</label>
                    <div className="col-md-6">

                   {this.state.modules.length > 0 ?  
                   <Multiselect
                        options={this.state.modules} 
                        onSelect={this.onSelect} 
                        selectedItem={this.state.selectedItem}
                       onRemove={this.onRemove} 
                        displayValue="name" 
                    />
: ''}
                      
                    </div>
                  </div>

   </div>

   <div className="col-md-6">
 
   <div className="form-group">
      <label className="control-label col-md-4">Role* :</label>
      <div className="col-md-7">
      <select
          name="role"
          onChange={this.handleOnChange}
          value={this.state.role}
          className="form-control ember-text-field ember-view"
          type="text"
        >
         <option value="admin">Admin</option>
  <option value="employee">Employee</option>
  <option value="customise">Customise</option>
  
</select>
      </div>
    </div>

   </div>

   {this.state.canCustomise==true ? 
   <table className="add-module-table" style={{width:"84%", marginTop:'9%', marginLeft:'5%'}}>
   <tr>
     <th className="table-head" style={{backgroundColor:'#e8eaed',width:"44%"}}>Selected Modules</th>
     <th className="table-head" style={{backgroundColor:'#fafac0'}}>View</th>
   
     <th className="table-head" style={{backgroundColor:'#d0fac0'}}>Add </th>
     <th className="table-head" style={{backgroundColor:'#e0dbff'}}>Update</th>
     <th className="table-head" style={{backgroundColor:'#fac6c0'}}>Delete</th>
   </tr>
   
          
          <tr>
             <td className="table-item">
               <select className="form-control select-form" name="salutation" placeholder="salutation"  value={this.state.selectedItem}>  
          {}
        </select>
        </td>
          <td className="table-item" style={{width:'80px'}}><input name="name" value="CAN_VIEW" type="checkbox"/> </td>
          <td className="table-item"><input name="name" value="CAN_VIEW" type="checkbox"/> </td>
          <td className="table-item"><input name="name" value="CAN_VIEW" type="checkbox"/> </td>
          <td className="table-item"><input name="name" value="CAN_VIEW" type="checkbox"/> </td>
          
          
        
          </tr>
          
         
  
  
 </table>
     :''}
    </div>

    </div>
    :''}



            <div className="text-left">
              <button type="button" onClick={this.sendMail} className="button1">
                {" "}
                Submit{" "}
              </button>
            </div>
            {/* this says that if editing state is on then the Function saveAccountDetails will be called */}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
      
     
    );
  }
}

