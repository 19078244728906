import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import _ from 'underscore';
let context = null;


export default class PageStatusMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entry:[]
    };
    context = this;
  }


  componentDidMount() {
    const id = this.props.match.params.id;
    this.setState({id});
    this.fetchQueryById(this, id)
  }

  fetchQueryById(that, id) {
    axios
      .get("https://q07uwo7htc.execute-api.us-east-1.amazonaws.com/dev/businus/getStatusMasterById/" + id)
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    const { entry } = this.state;

    return (
      <div className="dedicated-div">
        <div className="row breadCrumb1-row">
          <h4>{entry.title}  <Link style={{marginRight:'50px'}} className="pull-right" style={{color:'#fff',marginRight:'3%'}} to={"/appointment-consultant/edit-statusMaster/"+entry.id}><i className="fa fa-edit"></i>EDIT</Link>
          </h4>
        </div>
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
          <div className="dedicated-page">
            <div className="header-div2 ">
              <div
                className="bar"
                style={{ borderTop: "#1E5B81", color: "blue" }}
              />
              <div className="row">
                <div className="col-md-5">
                </div>
              <div className="content-div">
                  <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                   Name :
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.title!=undefined ? entry.title : 'Not available'}</h4>
                                </div>
                              </div>



                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                  Description 
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.description!=undefined ? entry.description : 'Not available'}</h4>
                                </div>
                              </div>
                </div>
                <div className="col-md-2">
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

