import React, { Component } from 'react';

import '../style/agency.css';
import reactCSS from 'reactcss'


import { SketchPicker } from 'react-color';

import '../animations.css';
import axios from 'axios';

import {apiBaseUrl,baseFilePath} from './apiUtils';


import FileBase64 from 'react-file-base64';
let context = null;



/**
 * Add Product Modal
 * It is the modal which takes in the data of new product cards .
 * @module Modal
 */
class Modal extends Component {
  constructor(props){
    super(); 
    this.state ={
      
      name:'',
      icon:'',
      content:'',
      backgroundColor:'',
      color:'',
      descriptionColor:'',
      fileName:"",
      files:[],

    };
    context= this;
    this.saveProduct = this.saveProduct.bind(this);
    this.uploadApi = this.uploadApi.bind(this);
    this.hideModal = this.hideModal.bind(this);
   
    
   }
   
   componentDidMount(){
var product = this.props.product;
    this.setState({name:product.name});
    this.setState({icon:product.icon});
    this.setState({content:product.content});
    this.setState({backgroundColor:product.styles.backgroundColor});
    this.setState({descriptionColor:product.styles.descriptionColor});
    this.setState({color:product.styles.color});


   }

   uploadApi(){
    var data = {image:this.state.files.base64,fileName:this.state.files.name}
    axios.post(apiBaseUrl+"/uploadImage",data)
    .then(function (response) {
  
      context.setState({icon:response.data.Location});
     
    })
    .catch(function (error) {
      console.log(error);
    });
  

   }


   toggleBannerImageModal(){

    var status = !this.state.toggleBannerImageModal;
    this.setState({toggleBannerImageModal:status});
    

  }

   /**
 * getFiles()
 * Temporary stores the image to be uploaded. We pass the files.
 * @function getFiles()
 */
   getFiles(files){
    this.setState({ files: files })
    this.uploadApi();
  }
  onChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);


    console.log(this.state);
  }



   saveProduct(index){
     
      this.props.saveProduct(index,this.state);
      this.props.hideModal(index);
    
    
   }

   hideModal(key){

    this.props.hideModal(key);
   }

   
   toggleColorPicker = (colorPicker) => {
    if(colorPicker=="bg"){
     this.setState({ displayBgColorPicker: !this.state.displayBgColorPicker })
    }
    else if(colorPicker=='color'){
      this.setState({ displayColorPicker: !this.state.displayColorPicker })
 
     }
    else{
     this.setState({ displayDesColorPicker: !this.state.displayDesColorPicker })

    }
   
 };
 handleChangeComplete = (color) => {
  this.setState({ backgroundColor: color.hex });
};
handleChangeColorComplete = (color) => {
  this.setState({ color: color.hex });
};
handleChangeDesColorComplete = (color) => {
  this.setState({ descriptionColor: color.hex });
};
handleClose = () => {
  this.setState({ displayColorPicker: false });
  this.setState({ displayBgColorPicker: false });
  this.setState({ displayDesColorPicker: false });
};

   
    render(){
      const styles = reactCSS({
        'default': {
          color: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `${this.state.backgroundColor}`,
          },
          color2: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `${this.state.color}`,
          },
          color3: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `${this.state.descriptionColor}`,
          },
          swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
          },
          popover: {
            position: 'absolute',
            zIndex: '2',
          },
          cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          },
        },
      });
  
    return(
this.state.hideModal ? <div></div>  : 


<div className="modal-wrapper">
<div className="container">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <form>
              <div id="contact-form" className="form-container" data-form-container style={{color: 'rgb(46, 125, 50)', background:'white'}}>
              <div className="row modal-back-color">
                <div style={{'marginLeft': '38px'}} onClick={()=> this.props.hideModal(this.props.keyindex)}><h5 className="close-button" style={{marginLeft:'630px'}}>X</h5></div>
                  <div className="form-title text-center">
                    <h5 className="modal-head" > Edit Product Card </h5>
                  </div>
                  
                </div>
                <div className="input-container">
                <div className="row">
                    <div className="col-md-12">
                    <form id="field">
                    <div className="row">
                    <div className="col-md-6">
                    <div className="row modal-row">
           <div className="col-md-3">
                    <br/> Head Color: </div> <div className="col-md-9 modalrow"><div style={ styles.swatch} onClick={ ()=>this.toggleColorPicker('color') }>
          <div style={ styles.color2 } />
        </div>
        { this.state.displayColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ this.handleClose }/>
          <SketchPicker  color={this.state.color} onChangeComplete={ this.handleChangeColorComplete }/><br/>
        </div> : null }
        </div></div>
        <div className="row modal-row">
           <div className="col-md-3">
                    <br/> Description Color: </div> <div className="col-md-9 modalrow"><div style={ styles.swatch} onClick={ ()=>this.toggleColorPicker('textcolor') }>
          <div style={ styles.color3 } />
        </div>
        { this.state.displayDesColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ this.handleClose }/>
          <SketchPicker  color={this.state.descriptionColor} onChangeComplete={ this.handleChangeDesColorComplete }/><br/>
        </div> : null }
        </div></div>
        <div className="row modal-row">
                    <div className="col-md-3">
    Background Color: </div> <div className="col-md-9 "> <div style={ styles.swatch } onClick={ ()=>this.toggleColorPicker('bg') }>
          <div style={ styles.color } />
        </div>
        { this.state.displayBgColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ this.handleClose }/>
          <SketchPicker  color={this.state.backgroundColor} onChangeComplete={ this.handleChangeComplete }/><br/>
           </div> : null }
           </div>
           </div>
           </div> <div className="col-md-6">

                    <div className="row modal-row">
                    <div className="col-md-3">
                     Image Url:</div> <div className="col-md-9"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.icon} name="icon"/><br/></div></div>
                     <FileBase64
        multiple={ false }
        onDone={ this.getFiles.bind(this) } /><div className="row modal-row">
                    <div className="col-md-3">
                     Head:</div> <div className="col-md-9"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.name} name="name"/><br/></div></div>
                     <div className="row modal-row">
                    <div className="col-md-3">
                     Content:</div> <div className="col-md-9"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.content} name="content"/><br/></div></div>
                    
                     </div>
                     </div>
  
                {/* <button type="button"   className="imagebutton"> Save </button>*/}
                   </form>
                      </div>
                      </div>
                  
                  <div className="row submit-row">
                    <div className="col-md-2"></div><div className="col-md-4">
                    <button type="button" onClick={()=>this.saveProduct(this.props.keyindex)} className="btn btn-block savebutton submit-form valid">Save</button>
                    </div>
                    <div className="col-md-4">
                    <button type="button" onClick={()=> this.props.hideModal(this.props.keyindex)} className="btn btn-block cancelbutton submit-form valid">Cancel</button>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div></div>
      </div>
    );
  

    }
}

export default Modal;