import React, { Component } from "react";
import axios from 'axios';
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import { Link } from "react-router-dom";
import $ from "jquery";
import BreadCrumbs from "../../BreadCrumbs";
import 'react-table/react-table.css'
import _ from 'underscore';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

//defining the context initially assigning it a null value

let context = null;

//defining the heads of the table, the accessors 



export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
    counters:{},
    sendQueryCounter:{},
    count:'',
 
    };

    context = this;
  }
  componentDidMount(){
    localStorage.setItem("email","gautammegha2@gmail.com");
  this.countQueries();

}


  //function for toggling the status button

  


  //function for fetching queries from backend 
  
  countQueries(){

    axios.get(apiBaseUrl1+ "businus/workflow/list/"+localStorage.spId)
    .then(function (response) {
      console.log(response.data);
      context.setState({count:response.data.length});
      context.setState({counters:response.data.queries});
      context.setState({sendQueryCounter:response.data.category});
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }

  //function for changing the status button on click

  


  render() {
      const {counters,sendQueryCounter} = this.state;
    return (
      <div >
      
      <div className="row breadcrumb1-row" style={{marginLeft:'0px'}} >
      <div className="col-md-12"><BreadCrumbs/></div>
    
  </div>
      <div className="DashboardDiv" style={{paddingLeft:'14px',overflowY:'scroll'}} >
        
      
      <div className="row ">
      <h2 className="dashboard-head">Dashboard</h2>
      </div>
    
     
          
            <div className="row">
        <div className="col-md-6">
          <Link to="/workflow/view">
          <div className="card-counter primary" >
            <i className="fa fa-ticket" />
            <span className="count-numbers">{this.state.count}</span>
            <span className="count-name">Workflow Counter</span>
          </div>
          </Link>
        </div>
        <div className="col-md-6">
          <Link to="/workflow/add">
          <div className="card-counter primary" >
            <i className="fa fa-plus" />
            
            <span className="count-name">Add workflow</span>
          </div>
          </Link>
        </div>
       
       
        
        </div>

       
        
     

        
        </div>

    
            </div>
          
       
          
       
    );
  }
}
