import React, { Component } from "react";
import BreadCrumbs from "../../BreadCrumbs";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiBaseUrl1, apiBaseUrl5 } from "../../apiUtils";
import { apiBaseUrl2 } from "../../apiUtils";
import CsvDownloader from "react-csv-downloader";
import ReactTable from "react-table";
import $ from "jquery";
import _ from "underscore";

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";

const data = [];

let context = "null";
let deleteStatus = ()=>{};

//defined all the columns and fetched the data for every cell.
const columns = [
  {
    Header: (props) => (
      <span className="form-textHead cell-head">Status Name</span>
    ),
    accessor: (d) => {
      return { mail: d.email, id: d.id, name: d.paymentStatus };
    },
    filterMethod: (filter, row) => {
      return row.companyName.name.toLowerCase().includes(filter.value.toLowerCase());
    },

    id: "companyName",
    Cell: (props) => (
      <span>
        {props.value.name}
      </span>
    ),
    minWidth: 180,
  },
  {
    Header: (props) => (
      <span className="form-textHead cell-head">Status Color</span>
    ),
    accessor: (d) => {
      return { mail: d.email, id: d.id, name: d.paymentStatus, color: d.paymentStatusColor };
    },
    filterMethod: (filter, row) => {
      return row.companyName.name.toLowerCase().includes(filter.value.toLowerCase());
    },

    id: "color",
    Cell: (props) => (
     <span style={{display:'block',height:25,width:25,borderRadius:100,background:props.value.color}}></span>
    ),
    minWidth: 180,
  },
  {
    Header: props => <span className="form-textHead cell-head text-center">Actions</span>,
    accessor: d => {return {isRegistered:d.isRegistered,id:d.id}},
    filterMethod: (filter, row) =>
             { return row.name.name.toLowerCase().includes(filter.value.toLowerCase()) }
                     
                  ,
    id:"registered",
    Cell: (props) =>  (<div className="text-center">
  
  <button className="deleteUser" style={{backgroundColor:'red'}} onClick={()=>deleteStatus(props.value.id)}>Delete</button></div>),
    minWidth:180
  },
  {
    Header: props => <span className="form-textHead cell-head">By</span>,
    accessor: 'by',
    Cell: props => <span className='form-text cell'>{props.value}</span>,
    minWidth:170
   
  },{
    Header: (props) => (
      <span className="form-textHead cell-head">Date of Creation</span>
    ),
    accessor: (d) => {
      return { date: d.date, id: d.id };
    },
    filterMethod: (filter, row) => {
      return new Date(row.date.date).toDateString().toLowerCase().includes(filter.value.toLowerCase());
    },
  
    id: "date",
    Cell: (props) => new Date(props.value.date).toDateString(),
    minWidth: 180,
  },
  {
    Header: (props) => (
      <span className="form-textHead cell-head">Time of Creation</span>
    ),
    accessor: (d) => {
      return { date: d.date, id: d.id };
    },
    filterMethod: (filter, row) => {
      return new Date(row.date.date).toLocaleTimeString().toLowerCase().includes(filter.value.toLowerCase());
    },
  
    id: "time",
    Cell: (props) => new Date(props.value.date).toLocaleTimeString(),
    minWidth: 280,
  },
];
export default class viewPaymentStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      menu1: [],
    };
    context = this;
    deleteStatus = this.delete;
  }
  componentDidMount() {
    this.fetchQueries(this, this.props.source);
  }

  toggleOpen = (id) => {
    var list = this.state.menu1;
    var index = _.findIndex(list, function (entry) {
      return entry.id == id;
    });

    list[index].status =
      list[index].status == "open" || list[index].status == undefined
        ? "close"
        : "open";

    var newList = JSON.parse(JSON.stringify(list));
    context.setState({ menu1: newList });
    context.updateStatus(context, id, list[index].status);
  };

  fetchQueries(that, source) {
    axios
      .get(apiBaseUrl1 + "businus/status/list/payment/"+localStorage.spId)
      .then(function (response) {
        console.log(response.data);
        that.setState({ menu1: _.sortBy(response.data, "customerId") });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  delete = (id) => {
    var result = window.confirm("Are you sure you want to delete?");
    if (result == true) {
      axios
        .delete(
          apiBaseUrl1 + "businusquery/deleteStatusById/" +
          id
        )
        .then(function (response) {
          debugger;
          console.log(response.data);
          alert('Organisation has been deleted');
          // that.setState({ entry: response.data });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }


  

  render() {
    return (
      <div>
        <div className="Div">
          <div className="row breadcrumb-row">
            <div className="col-md-10">
              <BreadCrumbs />
            </div>
            <div className="col-md-2">
              <div className=" download-button">
                <CsvDownloader
                  text="Download in .csv format"
                  datas={this.state.menu1}
                  filename="clients"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <Row>
              <Col>
                <div className="card">
                  <ReactTable
                    className="myTable"
                    resolveData={(data) => data.map((row) => row)}
                    data={this.state.menu1}
                    columns={columns}
                    filterable
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}
