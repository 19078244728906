import React, {Component} from 'react';
import '../css/consultant.css';

export default class PreviewConsultantBooking extends Component{
    constructor(props){
        super(props)
        this.state={
            personalDetail:this.props.personalDetail,
            bookingDetail:this.props.bookingDetail,
            feeDetail:this.props.feeDetail,
            checkFee:true,
            dateArray:[],
            allBookingData:[],
            currentBookingDetail:[],
            bookingDate:''
        }
        this.handleBookingDate = this.handleBookingDate.bind(this)
        this.generateBookingDate = this.generateBookingDate.bind(this)
    }

    componentWillMount(){
        let checkFee=true
        if(this.state.feeDetail.onlineConsultation.onlineConsultation===''){
            if(this.state.feeDetail.InpersonConsultation.InpersonConsultation===''){
                checkFee=false
            }
        }
        this.setState({checkFee:checkFee})
        this.generateBookingDate()
    }

    async generateBookingDate(){
        let today = new Date()
        let index = today.getDay()
        let dateArray = this.state.dateArray

        let bookingDay=''
        let months=["January","February","March","April","May","June","July","August","September","October","November","December"]
        let days = ["Sun","Mon","Tue","Wed","Thurs","Fri","Sat"]

        for(var i=1; i<8; i++){
            bookingDay=today.getDate()+" "+months[today.getMonth()].slice(0,3)+" "+days[index]
            dateArray.push({key:days[index],day:bookingDay})
            today = this.handleBookingDate(today)
            index = today.getDay()
        }
    
        await this.setState({dateArray:dateArray})

        this.availableBookingDate(days)
    }

    handleBookingDate(today){
        let tomorrow =  new Date(today.getTime()+86400000)
        return tomorrow
    }

    availableBookingDate(days){
        let flag = true
    
        for(var j=0;j<days.length;j++){
            for(var i=0;i<this.state.bookingDetail.length; i++){ 
                if(this.state.bookingDetail[i].day===days[j]){
                    flag = false
                }
            }
            if(flag){
                document.getElementById(days[j]).style.textDecorationLine='line-through';
                document.getElementById(days[j]).disabled = true;
            }
            flag = true
        }
    }

    dateSelected(date,key){
        for(var i=0;i<this.state.dateArray.length;i++){
            if(key===this.state.dateArray[i].key){
                document.getElementById(this.state.dateArray[i].key).classList.add('user-booking-day-active')
            }
            else{
                document.getElementById(this.state.dateArray[i].key).classList.remove('user-booking-day-active')
            }
        }
        this.setState({bookingDate:date})
        this.filterTiming(key,date)
    }

    filterTiming(key,currentDate){
        let currentBookingDetail=[]
        for(var i=0; i<this.state.bookingDetail.length;i++){
            if(key === this.state.bookingDetail[i].day){
                currentBookingDetail = this.state.bookingDetail[i]
                break 
            }
        }

        
        let allBookingData = this.state.allBookingData
        let bookedSlot = [{morning:[],afternoon:[],evening:[]}]

        if(allBookingData.length>0){

        let morning =[]
        let afternoon = []
        let evening =[]

        for(var i =0;i<allBookingData.length;i++){
            if(allBookingData[i].date===currentDate){
                if(allBookingData[i].bookingSlot.morning!==""){
                    morning.push(allBookingData[i].bookingSlot.morning)
                }
                else if(allBookingData[i].bookingSlot.afternoon!==""){
                    afternoon.push(allBookingData[i].bookingSlot.afternoon)
                }
                else if(allBookingData[i].bookingSlot.evening!==""){
                    evening.push(allBookingData[i].bookingSlot.evening)
                }
            }
        }

        
        filterAvailableTime('morning',morning,currentBookingDetail.booking.morning)
        filterAvailableTime('afternoon',afternoon,currentBookingDetail.booking.afternoon)
        filterAvailableTime('evening',evening,currentBookingDetail.booking.evening)

        function filterAvailableTime(day,dayArray,currentDayArray){
            let tempTimeArray =[]
            for(var i=0;i<dayArray.length;i++){
                for(var j=0;j<currentDayArray.length;j++){
                    if(dayArray[i]===currentDayArray[j]){
                        tempTimeArray.push(currentDayArray[j])
                    }
                }    
            }
            if(day==='morning'){
                bookedSlot[0].morning.push(tempTimeArray)
            }
            else if(day === 'afternoon'){
                bookedSlot[0].afternoon.push(tempTimeArray)
            }
            else if(day === 'evening'){
                bookedSlot[0].evening.push(tempTimeArray)
            }
        }
    }
        console.log('available',bookedSlot)

        this.setState({currentBookingDetail:currentBookingDetail})
        this.availableBookingTime(currentBookingDetail,bookedSlot)
    }

    availableBookingTime(currentBookingDetail,bookedSlot){
        
        let morningId=['7:00 AM','7:30 AM','8:00 AM','8:30 AM','9:00 AM','9:30 AM','10:00 AM','10:30 AM','11:00 AM','11:30 AM']
        let afternoonId=['12:00 PM','12:30 PM','1:00 PM','1:30 PM','2:00 PM','2:30 PM','3:00 PM','3:30 PM']
        let eveningId=['4:00 PM','4:30 PM','5:00 PM','5:30 PM','6:00 PM','6:30 PM','7:00 PM','7:30 PM','8:00 PM','8:30 PM']

        let morning = currentBookingDetail.booking.morning
        let afternoon = currentBookingDetail.booking.afternoon
        let evening = currentBookingDetail.booking.evening

        filterAvailableTime(morning,morningId)
        filterAvailableTime(afternoon,afternoonId)
        filterAvailableTime(evening,eveningId)

        function filterAvailableTime(dayArray,arrayID){
            if(dayArray.length>0){
            for(var j=0; j<arrayID.length;j++){
                for(var i=0; i<dayArray.length; i++){
                    if(dayArray[i]===arrayID[j]){
                        document.getElementById(arrayID[j]).style.textDecorationLine='none';
                        document.getElementById(arrayID[j]).disabled = false;
                        break
                    }
                    else{
                    document.getElementById(arrayID[j]).style.textDecorationLine='line-through';
                    document.getElementById(arrayID[j]).disabled = true;
                    }
                }
            }
        }
        else{
            for(var i=0; i<arrayID.length; i++){
                document.getElementById(arrayID[i]).style.textDecorationLine='line-through';
                document.getElementById(arrayID[i]).disabled = true
            }
        }
    }

    let morningBookedTime = bookedSlot[0].morning
    let afternoonBookedTime = bookedSlot[0].afternoon
    let eveningBookedTime = bookedSlot[0].evening

    if(morningBookedTime.length>0){
        filterBookedTime(morningBookedTime[0])
    }
    if(afternoonBookedTime.length>0){
        filterBookedTime(afternoonBookedTime[0])
    }
    if(eveningBookedTime.length>0){
        filterBookedTime(eveningBookedTime[0])
    }

    function filterBookedTime(dayArray){
        for(var i=0; i<dayArray.length; i++){
            document.getElementById(dayArray[i]).style.textDecorationLine='line-through';
            document.getElementById(dayArray[i]).disabled = true;
        }
    }

    }

    render(){
        return(
            <div style={{background:'#f5f5f5',height:'100vh'}}>
            <div className='booking-container'>
                <div className='row'>
                    <div className='col-md-12 col-lg-12 col-sm-12'>
                        <div className='col-md-8 col-lg-8 col-sm-12' style={{background:'white',padding:'2%',marginRight:'2%',width:'72%'}}>
                            <div className="row">
                                <div className='col-md-4 col-lg-4 col-sm-4' style={{background:'#fafafa'}}>
                                    <div>
                                        <h6 className='booking-lawyer-d'> Lawyer Details <a className='float-r' href="/profile"> Change </a> </h6>
                                        <div> 
                                            <h5 className='font-b'> {this.state.personalDetail.name} </h5>
                                            <p style={{fontSize:"13px"}}> {this.state.personalDetail.experience} years experience </p> 
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-8 col-lg-8 col-sm-8'>
                                    <div>
                                        <div>
                                            <h3> Availability </h3>
                                            <ul className='booking-outer-date'>
                                            {this.state.dateArray.map((date,index)=>(
                                                <li className='booking-inner-date'>
                                                <button id={date.key} className='user-booking-day' onClick={()=>this.dateSelected(date.day,date.key)}>{date.day}</button></li>
                                            ))}
                                            </ul>

                                            <div>
                                                <h4>Morning</h4>
                                                <div className='booking-outer-time'>
                                                    <div className='booking-inner-time'><button id='7:00 AM' className='booking-timing'>7:00 AM</button></div>
                                                    <div className='booking-inner-time'><button id='7:30 AM' className='booking-timing'>7:30 AM</button></div>
                                                    <div className='booking-inner-time'><button id='8:00 AM' className='booking-timing'>8:00 AM</button></div>
                                                    <div className='booking-inner-time'><button id='8:30 AM' className='booking-timing'>8:30 AM</button></div>
                                                    <div className='booking-inner-time'><button id='9:00 AM' className='booking-timing'>9:00 AM</button></div>
                                                    <div className='booking-inner-time'><button id='9:30 AM' className='booking-timing'>9:30 AM</button></div>
                                                    <div className='booking-inner-time'><button id='10:00 AM' className='booking-timing'>10:00 AM</button></div>
                                                    <div className='booking-inner-time'><button id='10:30 AM' className='booking-timing'>10:30 AM</button></div>
                                                    <div className='booking-inner-time'><button id='11:00 AM' className='booking-timing'>11:00 AM</button></div>
                                                    <div className='booking-inner-time'><button id='11:30 AM' className='booking-timing'>11:30 AM</button></div>
                                                </div>
                                            </div>

                                            <div>
                                            <h4> Afternoon </h4>
                                            <div className='booking-outer-time'>
                                                <div className='booking-inner-time'><button id='12:00 PM' className='booking-timing'>12:00 PM</button></div>
                                                <div className='booking-inner-time'><button id='12:30 PM' className='booking-timing'>12:30 PM</button></div>
                                                <div className='booking-inner-time'><button id='1:00 PM' className='booking-timing'>1:00 PM</button></div>
                                                <div className='booking-inner-time'><button id='1:30 PM' className='booking-timing'>1:30 PM</button></div>
                                                <div className='booking-inner-time'><button id='2:00 PM' className='booking-timing'>2:00 PM</button></div>
                                                <div className='booking-inner-time'><button id='2:30 PM' className='booking-timing'>2:30 PM</button></div>
                                                <div className='booking-inner-time'><button id='3:00 PM' className='booking-timing'>3:00 PM</button></div>
                                                <div className='booking-inner-time'><button id='3:30 PM' className='booking-timing'>3:30 PM</button></div>
                                            </div>
                                        </div>

                                        <div>
                                            <h4> Evening </h4>
                                            <div className='booking-outer-time'>
                                                <div className='booking-inner-time'><button id='4:00 PM' className='booking-timing'>4:00 PM</button></div>
                                                <div className='booking-inner-time'><button id='4:30 PM' className='booking-timing'>4:30 PM</button></div>
                                                <div className='booking-inner-time'><button id='5:00 PM' className='booking-timing'>5:00 PM</button></div>
                                                <div className='booking-inner-time'><button id='5:30 PM' className='booking-timing'>5:30 PM</button></div>
                                                <div className='booking-inner-time'><button id='6:00 PM' className='booking-timing'>6:00 PM</button></div>
                                                <div className='booking-inner-time'><button id='6:30 PM' className='booking-timing'>6:30 PM</button></div>
                                                <div className='booking-inner-time'><button id='7:00 PM' className='booking-timing'>7:00 PM</button></div>
                                                <div className='booking-inner-time'><button id='7:30 PM' className='booking-timing'>7:30 PM</button></div>
                                                <div className='booking-inner-time'><button id='8:00 PM' className='booking-timing'>8:00 PM</button></div>
                                                <div className='booking-inner-time'><button id='8:30 PM' className='booking-timing'>8:30 PM</button></div>
                                            </div>
                                        </div>
                                            {/* <BookingDetail booking={this.state.bookingDetail} confirmBooking={this.changeBookingDate} />  */}
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-md-1 col-lg-1'></div> */}
                        <div className='col-md-3 col-lg-3 col-sm-12 confirm-booking-box'>
                            <div className='confirm-booking-box-h'> <h4> Confirm Your Booking </h4> </div>
                            <div>
                                <div style={{borderBottom:'1px solid #d6d5d5'}}> 
                                    <div className='row'>
                                        <div className='col-md-1 col-lg-1 col-sm-1'></div>
                                        <h5 className='col-md-6 col-lg-6 col-sm-6'> Online Consultation / Telephonic </h5>
                                        {this.state.feeDetail!==undefined?
                                            this.state.feeDetail.onlineConsultation.onlineConsultation === ''?
                                            <h5 className='col-md-4 col-lg-4 col-sm-4 '> NA </h5>: 
                                            this.state.feeDetail.onlineConsultation.freeOnlineConsultation?
                                            <h5 className='col-md-4 col-lg-4 col-sm-4 '> Free Consultation </h5>: 
                                            <h5 className='col-md-4 col-lg-4 col-sm-4 '> $ {this.state.feeDetail.onlineConsultation.onlineFee} </h5>:<h5 className='col-md-4 col-lg-4 col-sm-4 '> NA </h5>}
                                    </div>
                                    <div className='row'>
                                     <div className='col-md-1 col-lg-1 col-sm-1'></div>
                                        <h5 className='col-md-6 col-lg-6 col-sm-6'> Inperson Consultation </h5>
                                        {this.state.feeDetail!==undefined?
                                            this.state.feeDetail.InpersonConsultation.InpersonConsultation === ''?
                                            <h5 className='col-md-4 col-lg-4 col-sm-4 '> NA </h5>: 
                                            this.state.feeDetail.InpersonConsultation.freeInPersonConsultation?
                                            <h5 className='col-md-4 col-lg-4 col-sm-4 '> Free Consultation </h5>: 
                                            <h5 className='col-md-4 col-lg-4 col-sm-4 '> $ {this.state.feeDetail.InpersonConsultation.InPersonfee} </h5>:<h5 className='col-md-4 col-lg-4 col-sm-4 '> NA </h5>}
                                    </div>
                                    {/* <div className='row'>
                                            <div className='col-md-1 col-lg-1 col-sm-1'></div>
                                            <h5 className='col-md-7 col-lg-7 col-sm-7'>GST</h5>
                                            <h5 className='col-md-3 col-lg-3 col-sm-3 float-r'> $162</h5>
                                        </div>  */}
                                 </div>
                                {/* <div className='row'>
                                    <div className='col-md-1 col-lg-1 col-sm-1'></div>
                                    <h5 className='col-md-7 col-lg-7 col-sm-7'> Total </h5>
                                    <h5 className='col-md-3 col-lg-3 col-sm-3'> $1062 </h5>
                                </div> */}
                            </div>
                        </div>
                        
                    </div>
                    <div className='col-md-12 col-lg-12 col-sm-12'>
                        <button onClick={()=>this.props.bookingPreview(false)} className="copySubmitBtn"> Back </button>    
                    </div>
                </div>
            </div>
            </div>
        )
    }
}