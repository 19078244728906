import React, { useState } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
function Build({ attributes, sections, smartDocId, smartDocFunction }) {
  let arr = [];
  sections.map((item) => {
    for (let x of item.fields) {
      arr.push(x);
    }
  });

  // console.log(JSON.stringify(arr));
  const [sectionName, setsectionName] = useState({
    name: "",
  });
  const [fields, setFields] = useState([]);
  const [openSections, setOpenSections] = useState([]);
  const [editSection, setEditSection] = useState({
    sectionName: "",
    sectionId: null,
  });

  const checkBox = (event) => {
    const { value } = event.target;
    if (!fields.includes(value)) {
      setFields((prev) => {
        return [...prev, value];
      });
    } else {
      setFields(fields.filter((item) => item !== value));
    }
  };
  const setSection = (value, sectionId) => {
    console.log(value, sectionId);
    setEditSection({ sectionName: value, sectionId });
    setsectionName(() => {
      return { name: value };
    });
    if (editSection.sectionName == value) {
      setEditSection({ sectionName: "", sectionId: null });
      setsectionName(() => {
        return { name: "" };
      });
    }
  };

  const openSection = (secNum) => {
    if (!openSections.includes(secNum))
      setOpenSections((prev) => {
        return [...prev, secNum];
      });
    if (openSections.includes(secNum)) {
      setOpenSections(openSections.filter((item) => item !== secNum));
    }
  };

  const setSectionData = (event) => {
    const { value } = event.target;
    setsectionName(() => {
      return { name: value };
    });
  };

  const submitSection = async (e) => {
    var sec = sections;
    console.log("sections: " + sections);
    sec.push({
      sectionId: "111",
      sectionName: sectionName.name,
      fields,
    });
    console.log(sections, fields);
    smartDocFunction((prev) => {
      return { ...prev, sections: sec };
    });

    try {
      var { data } = await axios({
        url: `/api/smartdoc/${smartDocId}/addSection/`,
        method: "POST",
        data: {
          sectionId: uuidv4(),
          sectionName: sectionName.name,
          fields,
        },
      });
      console.log("Data sent sucessfully!!!" + data);
    } catch (err) {
      console.log("ERROR in sending data" + err);
    }
    setFields([]);
    setsectionName({ name: "" });
  };

  const removeField = async (e, fieldName, sectionId) => {
    for (let x of sections) {
      if (x.sectionId === sectionId && x.fields.length === 1) {
        try {
          var { data } = await axios({
            url: `/api/smartdoc/${smartDocId}/${sectionId}/build/removeSection`,
            method: "POST",
          });
          smartDocFunction((prev) => {
            return { ...prev, sections: data.data };
          });
        } catch (err) {
          console.log("ERROR in sending data" + err);
        }
        continue;
      }
      if (x.sectionId === sectionId && x.fields.length != 1) {
        try {
          var { data } = await axios({
            url: `/api/smartdoc/${smartDocId}/${sectionId}/build/removeField`,
            method: "POST",
            data: { fieldName },
          });
          console.log("Data sent sucessfully!!!" + JSON.stringify(data.data));
          smartDocFunction((prev) => {
            return { ...prev, sections: data.data };
          });
        } catch (err) {
          console.log("ERROR in sending data" + err);
        }
      }
    }
  };
  const addField = async (e) => {
    try {
      var { data } = await axios({
        url: `/api/smartdoc/${smartDocId}/${editSection.sectionId}/build/addField`,
        method: "POST",
        data: { fields },
      });
      smartDocFunction((prev) => {
        return { ...prev, sections: data.data };
      });
      setFields([]);
      setEditSection({
        sectionName: "",
        sectionId: null,
      });
      setsectionName({ name: "" });
    } catch (err) {
      console.log("ERROR in sending data" + err);
    }
  };

  return (
    <div className="px-5 build-section" style={{ minHeight: "400px" }}>
      <div className="d-flex">
        <div className="col-6 d-flex flex-column">
          <div className="form-group pb-0">
            <div className="col-md-8">
              <input
                name="sectionName"
                value={sectionName.name}
                className="form-control ember-text-field ember-view"
                type="text"
                placeholder="Section Name"
                style={{ outline: "1px solid lightgrey" }}
                onChange={setSectionData}
              />
            </div>
            {editSection.sectionId != null ? (
              <button
                className="control-label col-md-4 btn btn-lg section-bg"
                onClick={(event) => {
                  addField(event);
                }}
              >
                Save
              </button>
            ) : (
              <button
                className="control-label col-md-4 btn btn-lg section-bg"
                onClick={submitSection}
              >
                Add Section
              </button>
            )}
          </div>
          <div className="col-md-8">
            {attributes
              .filter((item) => !arr.includes(item))
              .map((item) => {
                return (
                  <div
                    className="form-check check d-flex align-items-center "
                    key={item}
                  >
                    <input
                      className="m-2 p-2 form-check-input"
                      type="checkbox"
                      value={item}
                      onClick={checkBox}
                    />
                    <label
                      className="form-check-label control-label "
                      style={{ fontSize: "1.5rem" }}
                    >
                      {item}
                    </label>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="col-6 build-right">
          {/* {JSON.stringify(sections)} */}

          <div className="dropdown pt-4 my-3 d-flex flex-column col-md-12">
            {sections.map((item, i) => {
              return (
                <>
                  <div
                    key={i}
                    className={
                      openSections.includes(i)
                        ? "w-100 d-flex section-active mt-2"
                        : "w-100 d-flex  mt-2"
                    }
                  >
                    <button
                      type="button"
                      className="btn w-90 section-bg dropdown-toggle"
                      style={{ color: "white", borderRadius: "0px" }}
                      onClick={() => {
                        openSection(i);
                      }}
                    >
                      {item.sectionName}
                    </button>
                    <div
                      className={
                        editSection.sectionId != null &&
                        editSection.sectionId === item.sectionId
                          ? "section-active w-10 d-flex justify-content-center align-items-center"
                          : "edit-section d-flex justify-content-center align-items-center"
                      }
                      onClick={() => {
                        setSection(item.sectionName, item.sectionId);
                      }}
                    >
                      {editSection.sectionId != null &&
                      editSection.sectionId === item.sectionId ? (
                        <>
                          <i className="fas fa-times" />

                          {/* <i className="fa fa-trash" /> */}
                        </>
                      ) : (
                        <>
                          <i class="fa fa-edit" />
                        </>
                      )}
                    </div>
                  </div>
                  <div className={openSections.includes(i) ? "show" : "d-none"}>
                    {item.fields.map((eItem) => {
                      return (
                        <>
                          <div
                            className="w-100 m-3 d-flex justify-content-between align-items-center"
                            href="/"
                            key={eItem}
                          >
                            <h4>{eItem}</h4>

                            <i
                              className=" fa fa-trash delete-field"
                              onClick={(event) => {
                                removeField(event, eItem, item.sectionId);
                              }}
                            />
                          </div>
                          <div className="dropdown-divider"></div>
                        </>
                      );
                    })}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Build;
