import axios from "axios";
import React, { Component } from "react";
import FileBase64 from 'react-file-base64';
import ReactQuill from 'react-quill'; // ES6
import Select from 'react-select';
import _ from 'underscore';
import { apiBaseUrl1, apiBaseUrl2, apiBaseUrl3, apiBaseUrl5, apiBaseUrl6, apiWebzyUrl } from '../apiUtils';
import SubmitButton from '../common/SubmitButton';
import BreadCrumbs from '../BreadCrumbs';


let context = null;
const data = [];
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px ',
    position: 'Absolute',
    zIndex: '30000',
    backgroundColor: 'none',
  }
};

export default class ReAssign extends Component {
  constructor(props) {
    super(props);
    this.state = {
   description:null,
   entry:{},
   assignments:[],
   clients:[],
   contactPersonsClients:[],
   contactPersons:[],
   employees:[],
   isSubmitting:false,
   url:'',
   sharedToClient:[],
   websites:[],
    };
    context = this;

  }

  changeSliderState(orderNumber) {

    this.setState({ sliderState: orderNumber });

  }
  //function for changing the status button on click to open or close
  handleOnChange = (e) => {
    debugger;
    var value = e.target.value;

    var formName = e.target.name;

    this.setState({ [formName]: value });



    console.log(this.state);
  }


  handleOnAttachment= (e) => {

    var value = e.target.value;

    var formName = e.target.name;

    this.setState({ [formName]: value });



    console.log(this.state);
  }

  getFiles(files) {
    this.setState({ selectedFile: files[0] });

  }



  fetchAssignments(that,source){

    axios
    .get(
     apiBaseUrl1+"businus/project/list/" +
        localStorage.getItem("employeeId")
    )
    .then(function(response) {
      console.log(response.data);
      that.setState({ assignments: _.sortBy(response.data, "projectId") });
      that.generateExcelData(response.data);
    })
    .catch(function(error) {
      console.log(error);
    });
  }

  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });



          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }


  componentDidMount() {
    const id = this.props.match.params.id;
    
    this.fetchClients(this);
    this.fetchQueryById(this,id);
     this.fetchEmployees(this);
  }


  fetchClients(that,source){

    axios.get(apiBaseUrl2+"businus/customer/list/"+localStorage.spId)
    .then(function (response) {
      console.log(response.data);
      that.setState({clients:_.sortBy(response.data,"customerId")});
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }
/* 
  
  componentWillReceiveProps(nextProps) {

    if(this.props!=nextProps){
    const id = nextProps.match.params.id;
    let mailType = this.props.match.params.docType;
   
    mailType = mailType.charAt(0).toUpperCase() + mailType.slice(1);
    if(mailType=="Update"){
      this.setState({isUpdate:true});
    }else{
      this.setState({isUpdate:false});
    }

    this.setState({docType:mailType});
   // this.fetchClients(this);
    //this.fetchTemplates(this);
  }

  }
 */


fetchQueryById(that, id) {
  axios
    .get(
      apiWebzyUrl +"businusquery/getWebsiteById/" + id
    )
    .then(function (response) {
      debugger;
      console.log(response.data);
      that.setState({ entry: response.data });
      

      that.setState({
            selectedClient:response.data.selectedClient
      })

      let sharedTo = response.data.sharedTo;

      that.setState({
        sharedTo:_.where(sharedTo,{type:"EMP"})   //for employee value
      })


      let sharedToClient = _.filter(response.data.sharedTo,function(d){

        return d.type!="EMP";

      });
      that.setState({
        sharedToClient:sharedToClient//for client value
        
      })
      that.setState({name:response.data.name})

      that.handleOnDefaultClientChange(response.data.selectedClient);




    
     
    })
    .catch(function (error) {
      console.log(error);
    });
}


  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });



          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
 

  getFiles(files) {
    this.setState({ selectedFile: files[0] });

  }

  getFormattedDate(millis) {

    return new Date(millis).toDateString();


  }

 
  changeDocType(type) {

    this.setState({ docType: type });
  }



  
sendMail =() => {
  var query = context.state;
 
 
  context.setState({isSubmitting:true})

 
  let sharedTo = context.state.sharedTo;
  

  sharedTo = _.union(sharedTo,context.state.sharedToClient);

  

  axios
    .post(
      apiWebzyUrl+'businusquery/updateWebsiteById/'+this.state.entry.id,
      {

        selectedClient:query.selectedClient,
        date: new Date().getTime(),
        sharedTo: sharedTo,
        name:query.name,
        
      })
    .then(function (response) {
      debugger;
      alert("Updated");
     
  
      context.setState({isSubmitting:'done'})
    })
    .catch(function (error) {
      console.log(error);
      context.setState({isSubmitting:'done'})
    });
}

  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    localStorage.setItem("MAILURL", url);


  }

  createUUID(){
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
}


  handleQuillChange=(value)=> {
    this.setState({ description: value })
    // document.getElementById('save').innerHTML = 'Add Act';
  }
  onSelect = selectedOption => {
    this.setState(
      { sharedTo:selectedOption },
      () => console.log(`Option selected:`, this.state.selectedOption)
    );
  };
  
  onRemove(optionList, removedItem) {
  context.setState({sharedTo:optionList});
  }
  


  onSelectClients = selectedOption => {
    this.setState(
      { sharedToClient:selectedOption },
      () => console.log(`Option selected:`, this.state.selectedOption)
    );
  };
  
  onRemoveClients(optionList, removedItem) {
  context.setState({sharedToClient:optionList});
  }
  
  

  handleOnAssignChange = (e) =>{
    debugger;
    const state = this.state
    state[e.target.name] = e.target.value;

    var selectedAssignment= _.findWhere(this.state.assignments,{id:e.target.value});
    let teamLead = selectedAssignment.teamLead;
    let team = selectedAssignment.team;
    let client = selectedAssignment.selectedClient;
    let contactPersons = [];
    contactPersons.push(
      {label:"Team Lead :"+teamLead.name,value:teamLead.id,...teamLead,type:'teamlead'});
      contactPersons.push(
        {label:"Client :"+client.name,value:client.id,...client,type:'client'});
        
        _.each(team,function(d){
          contactPersons.push(
            d);
        })
   // state['contactPersons'] = contactPersons;
    //state['subjectUpdate'] = selectedTemplate.update;



    state['contactPersons'] = contactPersons;


    this.setState(state);


    console.log(this.state);
  
  }


  fetchEmployees(that,source){

    axios.get(apiBaseUrl2+"businus/employee/list/"+localStorage.spId)
    .then(function (response) {
      console.log(response.data);

       let allEmp = response.data;
      if(localStorage.userRole!='Admin'){

        allEmp = _.filter(allEmp,function(emp){

          return emp.role!='Admin';
        });
      }
     
      let contactPersons =[];


      _.each(allEmp, function (member) {


        contactPersons.push({
          value:member.id,
            label: member.employeeId +" "+ member.firstName +" "+ member.lastName,
          name: member.employeeId +" "+ member.firstName +" "+  member.lastName,
          id: member.id,
          email:member.email,
          type:"EMP"
        })





      });
      
      that.setState({employees:contactPersons});
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }
  
  handleOnClientChange = (e) =>{
    debugger;
    const state = this.state
    state[e.target.name] = e.target.value;

    var selectedClient= _.findWhere(this.state.clients,{id:e.target.value});
    let primaryContact = {id:selectedClient.id,name:selectedClient.primaryContact,email:selectedClient.customerEmail,label:selectedClient.primaryContact};
    let contacts = selectedClient.contactPersons;
   
    let contactPersons = [];
    contactPersons.push(primaryContact);
  
      
        _.each(contacts,function(d){
          contactPersons.push({label:d.name,type:'contact',...d});
        })
   // state['contactPersons'] = contactPersons;
    //state['subjectUpdate'] = selectedTemplate.update;



    state['contactPersonsClients'] = contactPersons;


    this.setState(state);


    console.log(this.state);
  
  }


  handleOnDefaultClientChange = (id) =>{
    debugger;
    const state = this.state
    state["selectedClient"] = id;

    var selectedClient= _.findWhere(this.state.clients,{id:id});
    let primaryContact = {id:selectedClient.id,name:selectedClient.primaryContact,email:selectedClient.customerEmail,label:selectedClient.primaryContact};
    let contacts = selectedClient.contactPersons;
   
    let contactPersons = [];
    contactPersons.push(primaryContact);
  
      
        _.each(contacts,function(d){
          contactPersons.push({label:d.name,type:'contact',...d});
        })
   // state['contactPersons'] = contactPersons;
    //state['subjectUpdate'] = selectedTemplate.update;



    state['contactPersonsClients'] = contactPersons;


    this.setState(state);


    console.log(this.state);
  
  }
  




  preregister(){

    var psw = btoa("teams@123");
    var data = {
      "password":psw,
      "userInfo":{"phone":"8764738262","address":"abs noida"},
      "footer":{"twitter":"http://facebook.com","facebook":"http://facebook.com","linkedin":"http://facebook.com"}
      ,"sections":"not created",
      "email":localStorage.getItem("userEmail"),
      "name":context.state.name,
      "websiteInfo":{footer: {color: "#fff", backgroundColor: "red", height: "auto"},
      btnInfo: {btnBackgroundColor: "#ac2a18", btnColor: "#fff", btnLink: "services", btnText: "Tell Me More"},
      "name":context.state.name,
      "subTitle":"Website Tag Line",
      "bannerImageUrl":"https://wallpapertag.com/wallpaper/full/a/e/1/642877-cool-wallpaper-laptop-2560x1600-for-hd-1080p.jpg",
      "websiteTitle":"Website Title",
      "navStyles":{
      backgroundColor: "#bd10e0",
      backgroundImage: "url('no image')",
      color: "#fff",
      displayBgColorPicker: false,
      displayColorPicker: false,
      height: "50px",
      logo: "no image",
      margin: "auto",
      padding: "auto"}},
      websiteName:context.state.name}
    
    
      axios.post(apiBaseUrl6+"/initPublish",data)
      .then(function (response) {
    
    if(response.status==200){

      context.register(response.data.id);
    }
       
      })
      .catch(function (error) {
        alert("Some Error has occured");
      });
    
    
    }


    /**
 * register()
 * This segment is the code for generating publish id for register.
 * @function register()
 */
  register(publishId){
    var psw = btoa("teams@123");
    var data = {
      "password":psw,
      "userInfo":{"phone":"8764738262","address":"abs noida"},
      "footer":{"twitter":"http://facebook.com","facebook":"http://facebook.com","linkedin":"http://facebook.com"}
      ,"sections":"not created",
      "email":localStorage.getItem("userEmail"),
      "name":context.state.name,
      "websiteInfo":{footer: {color: "#fff", backgroundColor: "red", height: "auto"},
      btnInfo: {btnBackgroundColor: "#ac2a18", btnColor: "#fff", btnLink: "services", btnText: "Tell Me More"},
      "name":context.state.name,
      "subTitle":"Website Tag Line",
      "bannerImageUrl":"https://wallpapertag.com/wallpaper/full/a/e/1/642877-cool-wallpaper-laptop-2560x1600-for-hd-1080p.jpg",
      "websiteTitle":context.state.name,
      "navStyles":{
      backgroundColor: "#bd10e0",
      backgroundImage: "url('no image')",
      color: "#fff",
      displayBgColorPicker: false,
      displayColorPicker: false,
      height: "50px",
      logo: "no image",
      margin: "auto",
      padding: "auto"}},
      websiteName:context.state.name,
      publishId:publishId
    }
      axios.post(apiBaseUrl6,data)
      .then(function (response) {
    
    if(response.status==200){

     // alert("registered successfully");
     context.sendMail(publishId,response.data.id);
    }
       
      })
      .catch(function (error) {
        alert("Some Error has occured");
      });
    
    
    }




  //updating the status button

  render() {
    const { sliderState } = this.state;
    const { superSliderState, entry } = this.state;
    const active = " active-button"
    const deactive = "";
    return (
      <div className="Div">
      <div className="row breadcrumb-row">
       <div className="col-md-10"><BreadCrumbs/></div>
       <div className="col-md-2">
        
   </div>
   </div>
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
        <div className="dedicated-page" style={{padding:'0px'}}>
    <div className="row-resource-head">
                  <h2 className="resource-head">Edit {this.state.entry.name} </h2>
                  </div>
            <div className="row ">
              <div className="col-md-12 info-column account-col2">
               
                <div id="field">
                  <h2 > </h2>
                  
                 
                  

          




<span>

<div className="form-group">
                        <label className="control-label col-md-3">Website Name:</label>
                        <div className="col-md-7">
                        <input
                            name="name"
                            onChange={this.handleOnChange}
                            value={this.state.name}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          />
                        </div>
                      </div>
  
                <div className="form-group">
                        <label className="control-label col-md-3">Client:</label>
                        <div className="col-md-7">
                        <select
                            name="selectedClient"
                            onChange={this.handleOnClientChange}
                            value={this.state.selectedClient}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >
                <option value="">Assign Client</option>
                            {this.state.clients.map(function (client, i) {

                              return (<option value={client.id}>{client.customerId} {client.companyName}</option>)
                            })}

                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="control-label col-md-3">Shared To (Client Contacts)</label>
                        <div className="col-md-7">

                        {this.state.contactPersonsClients.length > 0 ?
                             <Select
                             value={this.state.sharedToClient}
                             onChange={this.onSelectClients}
                             options={this.state.contactPersonsClients}
                             isMulti={true}
                           />
                            :  <input
                            name="subject"
                            disabled="true"
                            value='Select Client Above'
                            className="form-control ember-text-field ember-view"
                            type="text"
                          />}

                        
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="control-label col-md-3">Assign To (Employee):</label>
                        <div className="col-md-7">

                        {this.state.employees.length > 0 ?
                             <Select
                             value={this.state.sharedTo}
                             onChange={this.onSelect}
                             options={this.state.employees}
                             isMulti={true}
                           />
                            :  ''}

                        
                        </div>
                      </div>
                      </span>
                     

                  
                


                 


                  <div className="text-left" >
                  <SubmitButton clickHandler={this.preregister}
                   isSubmitting={this.state.isSubmitting} /> 
                  </div>
                  {/* this says that if editing state is on then the Function saveAccountDetails will be called */}
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

