import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class ProfessionalSidebar extends Component {
  render() {
    return (
      <div>
        <div className="sidebar-all">
          <div className="sidebar-inner-all">
            <div>
              <h4 className="sidebar-p-home">
                {" "}
                <Link className="link-c" to="/">
                  {" "}
                  Home{" "}
                </Link>{" "}
              </h4>
            </div>

            <div style={{ paddingLeft: "8%" }}>
              <div>
                <h5>
                  {" "}
                  <Link className="link-c" to="/professionals/add-profile">
                    {" "}
                    Profile{" "}
                  </Link>{" "}
                  <i className="fa fa-angle-right" />{" "}
                </h5>
              </div>
              <div>
                <h5>
                  {" "}
                  <Link
                    className="link-c"
                    to="/professionals/profile/mybooking/list"
                  >
                    {" "}
                    My Booking{" "}
                  </Link>{" "}
                  <i className="fa fa-angle-right" />{" "}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
