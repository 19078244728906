import axios from 'axios';
import React from 'react';
import FileBase64 from 'react-file-base64';
import GuideSidebar from './GuideSidebar';
import {v1 as uuidv1} from 'uuid';
export default class EditGuide extends React.Component{
    constructor(){
        super()
        this.state={
            guideName:'',
            guideDescription:'',
            imageArray:[],
            tags:'',
            id:'',
            learningType:''
        }
        this.handleChanges  = this.handleChanges.bind(this)
        this.handleTags = this.handleTags.bind(this)
    }

    componentDidMount(){
        let url = window.location.href.split('/')
        let index = url.length
        let id = url[index-1]
        this.setState({id:id,learningType:url[index-3]})

        this.fetchGuideById(id)
    }

    fetchGuideById(id){
        axios({
            url:'https://q7ay5k66xi.execute-api.us-east-1.amazonaws.com/dev/businus/getGuideById/'+id,
            method:'get',
            crossDomain:true,
            responseType:"json",
        }).then((response)=>{
            this.setState({guide:response.data.guideName,guideDescription:response.data.guideDescription,imageArray:response.data.imageArray,tags:response.data.tags})
        })
    }

    handleChanges(e){
        let target = e.target;
        let name = target.name;
      
        this.setState({[name]:target.value});
    }

    handleTags(e){
        let target = e.target
        var tag = target.value.split(',')
        this.setState({tags: tag})  
    }

    getFiles(files){
        this.setState({selectedFile:files[0]});
      }
  
      fileUploadHandler = (that, docType,pos) => {
        const id = uuidv1()
        const imageURL = 'https://businus-files-live.s3.amazonaws.com/image/'+id+'.png';
        that.setState({ loading: true });       
        axios.post("https://tj1cb28koh.execute-api.us-east-1.amazonaws.com/dev/businus/uploadEditorImage",
          {
            "image": that.state.selectedFile.base64,
            "imageName": that.state.selectedFile.name,
            "id":id,
            "imageURL":imageURL
          })
          .then(function (response) {
            if (response.status == 200) {
              that.setState({ successupload: true });
              that.setState({ attachmentName: that.state.selectedFile.name });
              that.setState({ [docType]: imageURL });
    
              that.createImageArray(pos)
    
            }
    
          })
          .catch(function (error) {
            console.log(error);
          });
      }

    handleDelete(key,i){
        let imageArray= this.state.imageArray
    
        for(var i=0;i<imageArray.length;i++){
          if(imageArray[i].key===i){
              imageArray[i].images.splice(key,1)
          }
        }
        this.setState({imageArray:imageArray})
    }

    createImageArray(index){
        let imageArray =this.state.imageArray
        imageArray.push({
          imageURL:this.state.image,
          imageName:this.state.attachmentName,
          key:index
        })
        
        this.setState({imageArray:imageArray})
      }

    submitGuide(id){
        if(this.state.guide.trim()===''){
            alert('Please Enter the guide name')
        }

        let data={
            guideName:this.state.guide,
            guideDescription:this.state.guideDescription,
            tags:this.state.tags,
            imageArray:this.state.imageArray,
        }
        if(this.state.guide.trim()!==''){
            axios({
                url:'https://q7ay5k66xi.execute-api.us-east-1.amazonaws.com/dev/businus/updateGuide/'+id,
                method:'POST',
                data:data,
            }).then((response)=>{
                if(response.status===200){
                    alert('Guide Name Updated')
                    window.location.replace("/knowledgeHub/businus-school/"+this.state.learningType+"/view-guides")
                }
            })
        }
    }

    render(){
        return(
            <div className='row'>
                <div className='col-md-2 col-lg-2'> <GuideSidebar learningType={this.state.learningType} /> </div>
                <div className='col-md-10 col-lg-10'>
                    <div className='container-fluid overflow-auto' style={{margin:'10px',height:'90v'}}>
                    <div style={{margin: "10px"}}><h3>Guides : </h3></div>

                    <div className='contianer-fuild well well-sm'>
                        <div className='input-group' style={{width:'80%'}}>
                        <span type="text" class="input-group-addon" style={{width:"20%"}}>Guide Name</span>
                        <input type="text" class="form-control" id="guide" placeholder="Enter Guide Name" name="guide" value={this.state.guide} onChange={this.handleChanges}></input>
                    </div>
                    </div>

                    <div className='contianer-fuild well well-sm'>
                        <div className='input-group' style={{width:'80%'}}>
                        <span type="text" class="input-group-addon" style={{width:"20%"}}>Guide Description</span>
                        <input type="text" class="form-control" id="guideDescription" placeholder="Enter Guide Description" name="guideDescription" value={this.state.guideDescription} onChange={this.handleChanges}></input>
                    </div>
                    </div>

                    <div className='contianer-fuild well well-sm'>
                        <div className='input-group' style={{width:'80%'}}>
                        <span type="text" class="input-group-addon" style={{width:"20%"}}>Tags</span>
                        <input type="text" class="form-control" placeholder="Enter Guide Tags with ," name="tags" value={this.state.tags} onChange={this.handleTags}></input>
                    </div>
                    </div>

                    <div className="form-group">
                            <label className="control-label col-md-4" >Image</label>
                            <div className="col-md-6 col-lg-6" style={{marginBottom:'20px'}}>

                    <div className='col-md-6 col-lg-6'>
                    <FileBase64 id='colorImage_1' multiple={ true } onDone={ this.getFiles.bind(this) }/>
                    </div>

                    <div className='col-md-6 col-lg-6'> <button className="btn btn-primary attachment" type='button' value='Select a Image' onClick={()=>this.fileUploadHandler(this,'image',this.state.imageArray.length)}>
                        <i className="fa fa-paperclip" aria-hidden="true"></i>Upload
                        </button> </div>
              
                        <div className="imageBlock">
                            {this.state.imageArray.length>0?
                            this.state.imageArray.map((image,index)=>(
                                  <span className="chip">
                                    <img style={{width:'10%'}} src={image.imageURL} />
                                    <span className="closeChip" onClick={event=>this.handleDelete(image.key,index)}>&times;</span>
                                  </span>
                                ))
                                :""}
                    </div>
                    
                    </div>
                    </div>
                    </div>
                    <div ClassName="col-md-4" style={{width : "80%", position:"relative", height:"50px"}}>
                        <button type='button' class="btn btn-primary bottomright" style={{margin:'10px'}} id='save' onClick={()=>this.submitGuide(this.state.id)}>Submit Guide</button>
                    </div>
                </div>
            </div>
        )
    }
}