import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import _ from 'underscore';
import Khub_HomepageSidebar from "../Homepage/Khub_HomepageSidebar";
import { apiBaseUrl21 } from "../../components/apiUtils";
let context = null;


export default class PageStayUpdated extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entry:[],
    };
    context = this;
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.setState({id});
    this.fetchQueryById(this, id);
  }


  fetchQueryById(that, id) {
    axios
      .get(apiBaseUrl21+"stayUpdated/get/" + id)
      .then(function (response) {
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    const {entry} = this.state;

    return (
        <div className="row">
        <div className="col-md-2 col-lg-2"><Khub_HomepageSidebar/></div>
        <div className="col-md-10 col-lg-10">
      <div className="dedicated-div">
        <div className="row breadCrumb1-row">
          <h4>Stay Updated
          </h4>
        </div>
        <div className="row">
          <div className="dedicated-page">
            <div className="header-div2 ">
              <div
                className="bar"
                style={{ borderTop: "#1E5B81", color: "blue" }}
              />
              <div className="row">
                <div className="col-md-5">
                </div>
              <div className="content-div">
                  <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                   Name :
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.name!==undefined ? entry.email : 'Not available'}</h4>
                                </div>
                              </div>

                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                  Email 
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.email!==undefined ? entry.email : 'Not available'}</h4>
                                </div>
                              </div>
                </div>
                <div className="col-md-2">
                
                </div>
              </div>
            </div>
         </div>
        </div></div></div>
      </div>
    );
  }
}

