import React, {Component,} from "react";
import {Link} from 'react-router-dom';
// import './review.css'

export default class StarReview extends Component{
    constructor(props){
        super(props)
        this.state={
            rating:[],
            ratingMean:0,
            fiveStarPercentage:0,
            fourStarPercentage:0,
            threeStarPercentage:0,
            twoStarPercentage:0,
            oneStarPercentage:0
        }
    }


    componentWillReceiveProps(nextProps){
        var rating = nextProps.rating
        if(rating.length!==0){
            
        console.log("rating",rating)
            let total = rating.total
            let ratingMean = rating.ratingSum/rating.total
            
            let fiveStarPercentage = (rating.five/total)*100
            let fourStarPercentage = (rating.four/total)*100
            let threeStarPercentage = (rating.third/total)*100
            let twoStarPercentage = (rating.two/total)*100
            let oneStarPercentage = (rating.one/total)*100
    
            this.setState({
                rating:rating,
                ratingMean:ratingMean,
                fiveStarPercentage:Math.round(fiveStarPercentage),
                fourStarPercentage:Math.round(fourStarPercentage),
                threeStarPercentage:Math.round(threeStarPercentage),
                twoStarPercentage:Math.round(twoStarPercentage),
                oneStarPercentage:Math.round(oneStarPercentage)
            })
        }


        if(nextProps.rating!== this.props.rating){
            this.handleRating(rating.ratingAverage)
        }
    }

    handleRating(ratingValue){
            const starPercentage = ratingValue*100;
            const starPercentageRound = Math.round(starPercentage/10)*10 +"%"
            

            document.querySelector(".star-inner").style.width=starPercentageRound
    }

    render(){
        return(
            <div>
                <div>
                    <h3> Client Rating </h3>
                    <div className="star-outer ">
                        <div className="star-inner "></div> 
                    </div>
                    <span> {this.state.ratingMean} out of 5 </span>
                </div>

                <div>
                    <p style={{fontSize:'15px',color:'#717171'}}>{this.state.rating.total} Global Rating</p>
                    <table>
                        <tbody> 
                            <tr>
                                <td className="columnStyle">
                                    5 star
                                </td>
                                <td>
                                    <div className="progress-rating">
                                        <div className="progress-bar bg-warning" role="progressbar" aria-valuenow={this.state.fiveStarPercentage} aria-valuemin="0" aria-valuemax="100" style={{width:this.state.fiveStarPercentage+"%"}}></div>
                                    </div>  
                                </td>    
                                <td className="columnStyle">
                                    {this.state.fiveStarPercentage>0?this.state.fiveStarPercentage:0} %
                                </td>
                            </tr>
                        
                            <tr>
                                <td className="columnStyle">
                                    4 star
                                </td>
                                <td>
                                    <div className="progress-rating">
                                        <div className="progress-bar bg-warning" role="progressbar" aria-valuenow={this.state.fourStarPercentage} aria-valuemin="0" aria-valuemax="100" style={{width:this.state.fourStarPercentage+"%"}}></div>
                                    </div>  
                                </td>    
                                <td className="columnStyle">
                                    {this.state.fourStarPercentage>0?this.state.fourStarPercentage:0} %
                                </td>
                            </tr>

                            <tr>
                                <td className="columnStyle">
                                    3 star
                                </td>
                                <td>
                                    <div className="progress-rating">
                                        <div className="progress-bar bg-warning" role="progressbar" aria-valuenow={this.state.threeStarPercentage} aria-valuemin="0" aria-valuemax="100" style={{width:this.state.threeStarPercentage+"%"}}></div>
                                    </div>  
                                </td>    
                                <td className="columnStyle">
                                    {this.state.threeStarPercentage>0?this.state.threeStarPercentage:0} %
                                </td>
                            </tr>

                            <tr>
                                <td className="columnStyle">
                                    2 star
                                </td>
                                <td>
                                    <div className="progress-rating">
                                        <div className="progress-bar bg-warning" role="progressbar" aria-valuenow={this.state.twoStarPercentage} aria-valuemin="0" aria-valuemax="100" style={{width:this.state.twoStarPercentage+"%"}}></div>
                                    </div>  
                                </td>    
                                <td className="columnStyle">
                                    {this.state.twoStarPercentage>0?this.state.twoStarPercentage:0} %
                                </td>
                            </tr>

                            <tr>
                                <td className="columnStyle">
                                    1 star
                                </td>
                                <td>
                                    <div className="progress-rating">
                                        <div className="progress-bar bg-warning" role="progressbar" aria-valuenow={this.state.oneStarPercentage} aria-valuemin="0" aria-valuemax="100" style={{width:this.state.oneStarPercentage+"%"}}></div>
                                    </div>  
                                </td>    
                                <td className="columnStyle">
                                    {this.state.oneStarPercentage>0?this.state.oneStarPercentage:0} %
                                </td>
                            </tr>
                  
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}