import React, { useEffect, useState } from "react";
import axios from "axios";
import BreadCrumbs from "../BreadCrumbs";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { v4 as uuidv4 } from "uuid";
import Alert from "./Alert";
import Build from "./smartDocComponents/build";
import { useParams } from "react-router-dom";

export default function SmartDocTemplates() {
  const [file, setFile] = useState(null);
  const [alert, setAlert] = useState(null);
  const [attributes, setAttributes] = useState(null);
  const [id, setID] = useState(null);
  const [smartDocData, setSmartDocData] = useState({
    docName: null,
    docOverview: null,
    selectedClient: null,
    sharedToClient: [],
    sharedToTeams: [],
    fileURL: null,
    attributes: null,
    tags: null,
    sections: [],
    template: true,
  });
  const encodeFileBase64 = (file) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var Base64 = reader.result;
        setFile(Base64);
      };
      reader.onerror = (error) => {
        console.log("error: ", error);
      };
    }
  };
  useEffect(() => {
    if (file != null) {
      sendFileToS3();
      setFile(null);
    }
  }, [file]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setSmartDocData((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const onMultiSelect = (event, name) => {
    setSmartDocData((prev) => {
      return { ...prev, [name]: event };
    });
  };
  const resetInputs = () => {
    setSmartDocData(() => {
      return {
        smartDocId: null,
        docName: "",
        docOverview: "",
        fileURL: null,
        attributes: null,
        tags: null,
        template: true,
        sections: [],
      };
    });
  };

  const sendData = async (event) => {
    event.preventDefault();
    try {
      var { data } = await axios({
        url: "/api/addSmartDoc",
        method: "POST",
        data: smartDocData,
      });
      setAlert({ msg: "Data sent Sucessfull!!", type: "alert-success" });
      // resetInputs();
      setID(data);
    } catch (err) {
      setAlert({ msg: "Something went wrong!!", type: "alert-danger" });
    }
  };

  const sendFileToS3 = async () => {
    //write a code to convert file object to base64 string
    const dataToSend = {
      base64String: file.split("base64,")[1],
      filename: uuidv4() + ".docx",
    };

    // Sendfile to S3
    try {
      var { data } = await axios({
        url: "https://dhyvnotx46.execute-api.us-east-1.amazonaws.com/live/smart-doc/upload",
        method: "POST",
        data: dataToSend,
      });
      setSmartDocData((prev) => {
        return { ...prev, fileURL: data.Location };
      });
      getAttributesOfFile(data.Location);
    } catch (err) {}
  };

  const getAttributesOfFile = async (fileURL) => {
    const { data } = await axios
      .get(`/api/attributesOfDocx/`, {
        params: {
          docURL: fileURL,
        },
      })
      .catch((err) => {});
    setSmartDocData((prev) => {
      return { ...prev, attributes: data };
    });
    console.log("DATA ATTR: " + JSON.stringify(data));
    setAttributes(data);
  };

  useEffect(() => {
    const getAllDocs = async () => {
      const { data } = await axios
        .get("/api/allDocTemplate")
        .catch((err) => {});

      var smartDocId = "SDT00" + parseInt(data.length + 1);
      setSmartDocData((prev) => {
        return { ...prev, smartDocId: smartDocId };
      });
    };
    getAllDocs();
  }, []);
  // let { id } = useParams();

  return (
    <>
      <div className="Div">
        <div className="row breadcrumb-row">
          <div className="col-md-10">
            <BreadCrumbs />
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row">
          <div className="dedicated-page" style={{ padding: "0px" }}>
            <div className="row-resource-head">
              <h2 className="resource-head">Create SmartDoc Template</h2>
            </div>

            <div className="row ">
              {alert && <Alert msg={alert.msg} type={alert.type} />}

              <form onSubmit={sendData}>
                <div className="col-md-12 info-column account-col2">
                  <div id="field">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-8">
                          <div className="form-group">
                            <label className="control-label col-md-3">
                              Add Word File:
                            </label>
                            <div className="col-md-7">
                              <input
                                onChange={(event) => {
                                  encodeFileBase64(event.target.files[0]);
                                }}
                                class="form-control form-control-sm"
                                id="myfile"
                                type="file"
                                accept=".docx"
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="control-label col-md-3">
                              Doc Name
                            </label>
                            <div className="col-md-7">
                              <input
                                name="docName"
                                onChange={handleOnChange}
                                value={smartDocData.docName}
                                className="form-control ember-text-field ember-view"
                                type="text"
                              />
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="control-label col-md-3">
                              Doc Overview
                            </label>
                            <div className="col-md-7">
                              <input
                                name="docOverview"
                                onChange={handleOnChange}
                                value={smartDocData.docOverview}
                                className="form-control ember-text-field ember-view"
                                type="text"
                              />
                            </div>
                          </div>

                          <span>
                            <div className="form-group">
                              <label className="control-label col-md-3">
                                Tags
                              </label>
                              <div className="col-md-7">
                                <CreatableSelect
                                  isMulti
                                  onChange={(event) => {
                                    onMultiSelect(event, "tags");
                                  }}
                                  value={smartDocData.tags}
                                />
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>

                      <div className="text-left">
                        <button type="submit" className="button1">
                          Submit
                          {/* {this.state.isSubmitClicked ? "Please Wait..." : "Create"} */}
                        </button>
                      </div>
                      {/* this says that if editing state is on then the Function saveAccountDetails will be called */}
                    </div>
                  </div>
                </div>
              </form>
              {attributes && id && (
                <Build
                  attributes={attributes}
                  sections={smartDocData.sections}
                  smartDocId={id}
                  smartDocFunction={setSmartDocData}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* BUILD */}
    </>
  );
}
