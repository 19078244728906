import React, { Component } from "react";
import "../../style/panel.css";
import axios from 'axios';
import FileBase64 from 'react-file-base64';
import {v1 as uuidv1} from 'uuid';
import KnowledgeHubSidebar from './KnowledgeHubSidebar';
import { apiBaseUrl20, apiBaseUrl21, s3BucketUrl } from "../../components/apiUtils";

export default class Khub_LegalBanner extends Component{
    constructor(props){
        super(props)
        this.state={
            bannerType:'legal-finance',
            bannerImageUrl:"",
            bannerExist:false,
            id:''
        }
    }
    componentDidMount(){
        this.fetchQueries(this)
    }

    fetchQueries(that){
        axios.get(apiBaseUrl21+'banner/list')
        .then(function (response){
            for(var i=0;i<response.data.length;i++){
                if(response.data[i].bannerType===that.state.bannerType){
                    that.setState({bannerImageUrl:response.data[i].bannerImageUrl,bannerExist:true,id:response.data[i].id})
                    break
                }
            }
            
        }).catch(function (error){
            console.log(error);
        })
    }
    
    getBanner(files){
        this.setState({banner:files[0]});
        this.fileUploadHandler(this,'bannerImageUrl')
    }

    fileUploadHandler = (that, docType) => {
        const id = uuidv1()
        const imageURL=s3BucketUrl+id+'.png';
        that.setState({ loading: true });
        axios.post(apiBaseUrl20+"uploadEditorImage",
          {
            "image": that.state.banner.base64,
            "imageName": that.state.banner.name,
            "id":id,
            "imageURL":imageURL
          })
          .then(function (response) {
            if (response.status === 200) {
              that.setState({ successupload: true });
              that.setState({ bannerImageName: that.state.banner.name });
              that.setState({ [docType]: imageURL });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }

    submitChanges(){
        let API=''
        if(this.state.bannerExist){
            API=apiBaseUrl21+"banner/update/"+this.state.id
        }
        else{
            API=apiBaseUrl21+"banner/add"
        }

            axios({
                url:API,
                method:"POST",
                data:{
                    bannerType:this.state.bannerType,
                    bannerImageUrl:this.state.bannerImageUrl
                },
            }).then(function (response){
                if(response.status===200){
                    alert("Legal & Finance Banner has been added")
                    window.location.reload()
                }
            })
    }
    render(){
        return(
        <div>
            <div className="row">
                <div className='col-md-2 col-lg-2'><KnowledgeHubSidebar/></div>
                <div className="col-md-10 col-lg-10">
            <div className="container-fluid overflow-auto" style={{margin: "10px", height:'90vh',}}>
                <div style={{margin: "10px"}}><h3>Add Legal & Finance Banner : </h3></div>

                <div className="form-group">
                <label className="control-label col-md-4" >Upload Banner Image</label>
                <div className="col-md-6"  >
                <FileBase64 multiple={ true } onDone={ this.getBanner.bind(this) }/></div>
                
                </div>
                <div className="col-md-4 col-lg-4"/>
                <div className="col-md-6 col-lg-6">
                {console.log('render banner--',this.state.bannerImageUrl)}
                {this.state.bannerImageUrl!==''?
                <img style={{width:'200px',height:'150px'}} src={this.state.bannerImageUrl} />
                :""
                }</div>
                <div ClassName="col-md-4" style={{width : "80%", position:"relative", height:"50px"}}>
                    <button type='button' class="btn btn-primary bottomright" style={{margin:'10px'}} id='save' onClick={()=>this.submitChanges()}>Add Banner</button>
                </div>
            </div></div>
        </div>
        </div>
        )
    }
}