import React, { Component } from 'react';
import reactCSS from 'reactcss'
import '../style/agency.css';

import { SketchPicker } from 'react-color';
import '../animations.css';


/**
 * Contact Section component 
 * this is the code  which adds contact section on the website.
 * @module Contact-Section-Modal
 */
class ContactSectionModal extends Component {
  constructor(props){
    super(); 
    this.state ={
     title:"",
    products:"0"

    };
    this.saveQuerySection = this.saveContactSection.bind(this);
   
    
   
    
   }
   
   componentDidMount(){



   }

  onChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);


    console.log(this.state);
  }


/**
 * saveContactSection
 * This is the segment of code used to add contact section.
 * @function saveContactSection()
 */
  saveContactSection(){
     
      this.props.saveContactSection(this.state);
      this.props.toggleContactSectionModal();
       
    
   }

   



   
    render(){
     

    return(



<div className="modal-wrapper">
<div className="container">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <form>
              <div id="contact-form" className="form-container" data-form-container style={{color: 'rgb(46, 125, 50)', background:'white'}}>
              <div className="row modal-back-color ">
                <div className="closebtn" onClick={this.props.toggleStyleModal}><h5 style={{marginLeft:'490px', fontSize:'15px',fontWeight:'500'}}>X</h5></div>
                  <div className="form-title text-left" >
                    <h4 className="modal-head" > Edit Contact Section </h4>
                  </div>
                </div>
                <div className="input-container">
                <div className="row">
                    <div className="col-md-12">
                    <form id="field">
       
       <div className="row modalrow">
      <div className="col-md-3">

 
         Title :</div><div className="col-md-9 "> <input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.title} name="title"/><br/></div></div>
      
                    
                {/* <button type="button"   className="imagebutton"> Save </button>*/}
                   </form>
                      </div>
                      </div>
                 
                  <div className="row submit-row">
                    <div className="col-md-2"></div><div className="col-md-4">
                    <button type="button" onClick={()=>this.saveContactSection()} className="btn btn-block savebutton submit-form valid">Save</button>
                    </div>
                    <div className="col-md-4">
                    <button type="button" onClick={()=> this.props.toggleContactSectionModal()}  className="btn btn-block cancelbutton submit-form valid">Cancel</button>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div></div>
      </div>
    );
  

    }
}

export default ContactSectionModal;