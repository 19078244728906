import React, { Component } from "react";
import "../style/panel.css";
import "../style/breadcrumbs.css";
import { Link } from "react-router-dom";
import $ from "jquery";
import axios from 'axios';
import { apiBaseUrl1,apiBaseUrl5 } from './apiUtils';
import { apiBaseUrl2 } from './apiUtils';
import _ from 'underscore';
import BreadCrumb from "./BreadCrumbs";

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
let context = null;
const data = [];
export default class Header extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: [],
      image:'',
      name:'',
     
    };
    context = this;
    
    
  }
  componentDidMount(){
    this.handleLine();
    this.fetchfields();
  }
  handleLine(){
    
    const c1= this.refs.c1;
    const tab1= this.refs.tab1;
    const sidebar1= this.refs.sidebar1;
    const section= this.refs.section;
    const c2= this.refs.c2;
    const c3= this.refs.c3;
    const c4= this.refs.c4;
    const c5= this.refs.c5;
    const c6= this.refs.c6;
    const c7= this.refs.c7;
    const c8= this.refs.c8;
    const d1= this.refs.d1;
    const d2= this.refs.d2;
    const d3= this.refs.d3;
    const d4= this.refs.d4;
    const d5= this.refs.d5;
    const d6= this.refs.d6;
    const d7= this.refs.d7;
    const d8= this.refs.d8;
    
    $(function(){
      $("#tab1").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
        $("#section").toggle();
      });
      });
      $(function(){
      $("#c2").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
        $("#d2").toggle();
      });
      });
      $(function(){
        $("#c3").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d3").toggle();
        });
        });
      $(function(){
        $("#c4").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
            $("#d4").toggle();
          });
          });
      $(function(){
         $("#c5").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d5").toggle();
            });
            });
      $(function(){
          $("#c6").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d6").toggle();
              });
              });
      $(function(){
          $("#c7").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d7").toggle();
                });
                });
      $(function(){
          $("#c8").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d8").toggle();
                  });
                  });  
  
  }
  
  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  //assigning the value of isLoggedIn to null
  logout(){

    localStorage.setItem("isLoggedIn",null);
  }


  //fetch data for the respective fields
  fetchfields(){
 
   
    context.setState({name:localStorage.getItem("userName")});
   
    context.setState({image:localStorage.profile});

  
  }


  render() {
    return (
      <nav id="applicationHeader" className="navbar  ">
          <div className="container-fluid">
            <div className="navbar-header">
              <a  href="/home">
                <img id="1" className="navbar-brand"
                  style={{ marginLeft: "-3px",width:"180px",height:"65px" }}
                  src={require("../images/teamDashboard.png")}
                />
                <h6 className="subtext">Admin Panel</h6>
              </a>
              
             
             
            </div>
           
            <ul className="nav navbar-nav navbar-right">
            <li className="nav-item">
                <a href="/home">Home</a>
              </li>
            <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="product-toggle" role="button" data-toggle="dropdown">
          <img  id="tab1" style={{ marginLeft: "-3px",height:'30px',marginTop:'-5px' }} src={this.state.image}/>&nbsp;{this.state.name}&nbsp;&nbsp;</a>
          <div className="dropdown-menu dropdown-menu-center dropdown-content" arealabelledby="product-toggle" >
                <ul>
             <li className="dropdown-item navmain-item" >  <a className="dropdown-item navmain-item" href= "/account-info" >Account</a> </li> 
             <li className="dropdown-item navmain-item" >  <a className="dropdown-item navmain-item" href= "/account-info/password-settings" >Settings</a> </li> 
             <li className="dropdown-item navmain-item">  <a  className="dropdown-item navmain-item" onClick={this.logout} href= "/" >Logout</a></li> 
                </ul>
                </div>
        </li>
      
              
            </ul> 
           
          </div>
         
        </nav>

    );
  }
}
