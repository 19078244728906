import React from 'react';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import axios from 'axios';
import { apiBaseUrl3 } from '../apiUtils';
import $ from "jquery";
class AddDoc extends React.Component {
  constructor(props) {
    super(props)

    var allText = 'Hi write something';
    this.state = {
      text: allText,
      inpWidth:'100%',
      encodedBlogImage: "",
      encodedAuthorImage: "",
      u : 0,
      c : 0,
      headings : "",
     }
    this.handleChange = this.handleChange.bind(this);
    // this.handleFileChosen = this.handleFileChosen.bind(this)
    // this.upload_file = this.upload_file.bind(this)
    this.save_to_dynamo = this.save_to_dynamo.bind(this);
    this.encodeImage = this.encodeImage.bind(this);
    this.incrementu = this.incrementu.bind(this);
    this.addUrl = this.addUrl.bind(this);
    this.addContent = this.addContent.bind(this);
    this.incrementc = this.incrementc.bind(this);
  }


  componentDidMount(){
  }

 
  handleChange(value) {
    this.setState({ text: value })
    // document.getElementById('save').innerHTML = 'Save';
  }
// template-engine-images.s3.amazonaws.com/files/quill.txt


  // handleFileChosen(file) {
  //   let fileReader;
  //   fileReader = new FileReader();
  //   fileReader.readAsText(file);
  //   fileReader.onloadend = function(e){
  //     var content = 'text';
  //     content = fileReader.result;
  //     console.log('handleFileChosen : ', content);
  //     this.setState({ text: content })
  //     // this.handleChange(content)
  //   }.bind(this);
  // };


  // upload_file(){
  //   var text = this.state.text;
  //   var encodedText =  btoa(text);
  //   console.log('encodedText :', encodedText);
  //
  //   axios({
  //     url: 'https://gwqzi2xcl8.execute-api.us-east-1.amazonaws.com/dev/uploadFiletos3', //your url
  //     method: 'POST',
  //     data:{file:encodedText, fileName:'quill.txt'}
  //   }).then((response) => {
  //     // this.handleFileChosen(response.data);
  //     console.log('resonse_data_upload :', response.data)
  //     document.getElementById('save').innerHTML = 'Saved <span className="fas fa-check-circle"></span>';
  //  });
  //
  // }


  encodeImage(imageid){
    var image = document.getElementById(imageid).files[0];
    // console.log(image);
    // document.getElementById(imageid + "Label").className = "custom-file-label selected";
    // document.getElementById(imageid + "Label").innerHTML = image.name;
    var reader  = new FileReader();
    var encodedImage ="";
    reader.addEventListener("load", function (e) {
      encodedImage = e.target.result.replace(/data:image\/(png|jpeg|jpg);base64,/, '');
      if (imageid=="blogImage") {
        this.setState({ encodedBlogImage:  encodedImage});
        document.getElementById('blogImagePreview').src=e.target.result;
        document.getElementById('blogImagePreview').style.display="block";
      }else if (imageid=="authorImage") {
        this.setState({ encodedAuthorImage:  encodedImage});
        document.getElementById('authorImagePreview').src=e.target.result;
        document.getElementById('authorImagePreview').style.display="block";
      }
      // return encodedImage;
    }.bind(this), false);
    reader.readAsDataURL(image);
  }


  save_to_dynamo(){
    // console.log("encodedBlogImage  :", this.state.encodedBlogImage);
    // console.log("encodedAuthorImage  :", this.state.encodedAuthorImage);

    var external = [];
    var internal = [];
    var sel = "";
    for (var i = 0; i < this.state.u; i++) {
      external[i] = {
        src : document.getElementById('src_' + i).value,
        url : document.getElementById('url_' + i).value,
        name : document.getElementById('srcName_' + i).value,
      }
    }

    for (i = 0; i < this.state.c; i++) {
      sel = document.getElementById('head_' + i);
      internal[i] = {
        heading: sel.options[sel.selectedIndex].text,
        id: sel.options[sel.selectedIndex].id,
      }
    }

    var more = {
      external : external,
      internal : internal
    }


    var blg = {
      encodedblogImage: this.state.encodedBlogImage,
      encodedAuthorImage: this.state.encodedAuthorImage,
      heading: document.getElementById('heading').value,
      subHeading: document.getElementById('subHeading').value,
      datePosted: document.getElementById('datePosted').value,
      dateModified: "0000-00-00",
      authorName: document.getElementById('authorName').value,
      authorDescription: document.getElementById('authorDescription').value,
      postedBy: localStorage.userName,
      
      readTime: document.getElementById('readTime').value,
      source: document.getElementById('source').value,
      sourceUrl:document.getElementById('sourceurl').value,
      content: this.state.text,
      blogImageCredit: document.getElementById('blogImageCredit').value,
      catagory: document.getElementById('catagory').value,
      tags: document.getElementById('tags').value.split(","),
      authorImageCredit: document.getElementById('authorImageCredit').value,
      blogRating: "0",
      authorRating: "0",
      videoUrl:document.getElementById('video').value,
      by:localStorage.userName,
      docType:'Doc',
      // more:more

    };
    console.log("blog :",blg);

    axios({
      url: apiBaseUrl3+'businus/workflowDoc/add', //your url
      method: 'POST',
      data: blg,
    }).then((response) => {
      console.log('resonse_data_dynamo :', response.data);
      document.getElementById('save').innerHTML = 'Added <span className="fas fa-check-circle"></span>';
      document.getElementById('save').className = 'btn btn-success bottomright';
      this.props.AssignId(response.data);
      // alert("Blog Added");
   });
  }

  incrementu() {
    // increments variable for no. of urls in MORE

    var inc = this.state.u + 1;
    this.setState({
      u : inc
    });
  }

  incrementc() {
    // increments variable for no. of website content in MORE

    var inc = this.state.c + 1;
    this.setState({
      c : inc
    });
  }

  addUrl(){
    // adds a URL entry in MORE
    
    var out_div = document.createElement('div');
    var spn = document.createElement('span');
    var src = document.createElement("INPUT");
    var url = document.createElement("INPUT");
    var srcName = document.createElement("INPUT");
    var br = document.createElement("br");
    this.incrementu();
    var i = this.state.u;

    out_div.setAttribute("class","input-group mb-3");
    out_div.style.width = '80%';
    // out_div.setAttribute("id", "urldiv_" + i);

    spn.setAttribute("type", "text");
    spn.setAttribute("class", "input-group-addon");
    spn.style.width = '20%';
    spn.innerHTML = "Source Url";
    // spn.setAttribute("id", "lat_" + i);

    src.setAttribute("type", "text");
    src.setAttribute("class", "form-control");
    src.setAttribute("placeholder", "Source");
    src.setAttribute("id", "src_" + i);

    url.setAttribute("type", "text");
    url.setAttribute("class", "form-control");
    url.setAttribute("placeholder", "Url");
    url.setAttribute("id", "url_" + i);

    srcName.setAttribute("type", "text");
    srcName.setAttribute("class", "form-control");
    srcName.setAttribute("placeholder", "Name");
    srcName.setAttribute("id", "srcName_" + i);

    out_div.appendChild(spn);
    out_div.appendChild(src);
    out_div.appendChild(url);
    out_div.appendChild(srcName);
    document.getElementById("more").appendChild(out_div);
    document.getElementById("more").appendChild(br);

  }

  addContent(){
    // adds a WEBSITE CONTENT entry in MORE

    var out_div = document.createElement('div');
    var spn = document.createElement('span');
    var src = document.createElement("select");
    var br = document.createElement("br");
    this.incrementc();
    var i = this.state.c;

    out_div.setAttribute("class","input-group mb-3");
    out_div.style.width = '80%';
    out_div.setAttribute("id", "web_" + i);

    spn.setAttribute("type", "text");
    spn.setAttribute("class", "input-group-addon");
    spn.style.width = '20%';
    spn.innerHTML = "Heading";

    // src.setAttribute("type", "text");
    src.setAttribute("class", "form-control");
    // src.setAttribute("placeholder", "Heading");
    src.setAttribute("id", "head_" + i);
    src.innerHTML = this.state.headings;

    out_div.appendChild(spn);
    out_div.appendChild(src);
    document.getElementById("more").appendChild(out_div);
    document.getElementById("more").appendChild(br);

  }

  handleTweets(){
    var val= document.getElementById('tweets').value
    this.setState({text:val}) 
  }

  render() {
    return (
      <div className = "container-fluid overflow-hidden" style={{margin: "10px", height:'90vh',overflow:'scroll'}}>
        <div style={{margin: "10px"}}><h3>Add Doc : </h3></div>

          <div className="container-fluid well well-sm" style={{}}>

          <div className="input-group" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Title" style={{width:"20%"}}>Title</span>
            <input type="text" className="form-control" id="heading" placeholder="" style={{}} required></input>
          </div><br/>

          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Title" style={{width:"20%"}}>Subtitle</span>
            <input type="text" className="form-control" id="subHeading" placeholder="" style={{width:this.state.inpWidth}} required></input>
          </div><br/>

        <div className="input-group" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Content" style={{width:"20%"}}>Content</span>
            <ReactQuill
                              formats={[
                                "background",
                                "bold",
                                "color",
                                "font",
                                "code",
                                "italic",
                                "link",
                                "size",
                                "strike",
                                "script",
                                "underline",
                                "blockquote",
                                "header",
                                "indent",
                                "list",
                                "align",
                                "direction",
                                "code-block",
                                "formula",
                              ]} value={this.state.text} onChange={this.handleChange} />
            {/*<textarea type="text" rows="10" className="form-control" id="content" placeholder="" style={{width:this.state.inpWidth}} required></textarea>*/}
          </div><br/>

          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Content Source"span style={{width:"20%"}}>Content Source</span>
            <input type="text" className="form-control" id="source" placeholder="" style={{width:this.state.inpWidth}} required></input>
          </div><br/>

          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Content Source"span style={{width:"20%"}}> Source Url</span>
            <input type="text" className="form-control" id="sourceurl" placeholder="" style={{width:this.state.inpWidth}} required></input>
          </div><br/>

          {/*<img id="blogImagePreview" className="" src="#" alt="authorImage" style={{width: "16%"}}/>
          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Article Image" style={{width:"20%"}}>Article Image</span>
            <div className="form-control" style={{width:this.state.inpWidth}}>
              <input type="file" className="form-control" id="blogImage" name="filename" onChange={() => this.encodeImage('blogImage')}></input>
              <label className="custom-file-label" for="" id="blogImageLabel">Choose file ... only png</label>
            </div>
          </div><br/>*/}

          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Category" style={{width:"20%"}}>Category</span>
            {/*<input type="text" className="form-control" id="catagory" placeholder="" style={{width:this.state.inpWidth}} required></input>*/}
            <select className="form-control" id="catagory">
              <option>cat1</option>
              <option>cat2</option>
              <option>cat3</option>
              <option>cat4</option>
            </select>
          </div><br/>

          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Tags" style={{width:"20%"}}>Tags</span>
            <input type="text" className="form-control" id="tags" placeholder="break tags with ," style={{width:this.state.inpWidth}} required></input>
          </div><br/>

          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Post By" style={{width:"20%"}}>Post By</span>
            <input type="text" className="form-control" id="postedBy" placeholder="" style={{width:this.state.inpWidth}} required></input>
          </div><br/>

          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Date of Publishing" style={{width:"20%"}}>Date of Publishing</span>
            <input type="date" className="form-control" id="datePosted" placeholder="yyyy-mm-dd" min="2018-01-01" max="2030-12-31" style={{width:this.state.inpWidth}} required></input>
          </div><br/>

          {/*<div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="form-control input-group-text" id="" placeholder="Date Edited" style={{width:"20%"}}></span>
            <input type="text" className="form-control" id="dateModified" placeholder="yyyy-mm-dd" style={{width:this.state.inpWidth}} required></input>
          </div><br/>*/}

          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Read Time" style={{width:"20%"}}>Read Time</span>
            <input type="number" className="form-control" id="readTime" placeholder="minutes" style={{width:this.state.inpWidth}} required></input>
          </div><br/>

          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Author" style={{width:"20%"}}>Author</span>
            <input type="text" className="form-control" id="authorName" placeholder="" style={{width:this.state.inpWidth}} required></input>
          </div><br/>

          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Author Description" style={{width:"20%"}}>Author Description</span>
            <input type="text" className="form-control" id="authorDescription" placeholder="" style={{width:this.state.inpWidth}} required></input>
          </div><br/>

          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Author Image Credit" style={{width:"20%"}}>Author Image Credit</span>
            <input type="text" className="form-control" id="authorImageCredit" placeholder="" style={{width:this.state.inpWidth}} required></input>
          </div><br/>

          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Article Image Credit" style={{width:"20%"}}>Article Image Credit</span>
            <input type="text" className="form-control" id="blogImageCredit" placeholder="" style={{width:this.state.inpWidth}} required></input>
          </div><br/>

          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Article Image Credit" style={{width:"20%"}}>Video Url</span>
            <input type="text" className="form-control" id="video" placeholder="" style={{width:this.state.inpWidth}} required></input>
          </div><br/>
          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Article Image Credit" style={{width:"20%"}}>Tweets</span>
            <input type="text" className="form-control" id="tweets" placeholder="" style={{width:this.state.inpWidth}} required></input>
          </div><br/>
          <button onClick={()=>this.handleTweets()} >Insert</button><br/>

          <div className="well well-sm " id='more'>
            <h5>Add More :- </h5>

            <button type='button' className="btn btn-default" style={{margin:'10px'}} id = '' onClick={this.addUrl}>Add Url</button>

            {/* <button type='button' className="btn btn-default" style={{margin:'10px'}} id = '' onClick={this.addContent}>Add Website Content</button> */}
            

          </div>

        <div style={{overflow: "auto"}}>
            <div className="col-md-4" style={{width: "30%"}}>
              <div className="thumbnail">
                <img src="" alt="authorImagePreview" id="authorImagePreview" style={{width: "100%", display:"none"}}/>
                <div className="caption">
                  <span className=""> <p>Author Image</p>
                  <input type="file" className="form-control btn btn-default btn-file" id="authorImage" name="filename" onChange={() => this.encodeImage('authorImage')}></input>
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-4" style={{width: "30%"}}>
              <div className="thumbnail">
                <img src="" alt="blogImagePreview" id="blogImagePreview" style={{width: "100%", display:"none"}}/>
                <div className="caption">
                  <span className=""> <p>Article Image</p>
                  <input type="file" className="form-control btn btn-default btn-file" id="blogImage" name="filename" onChange={() => this.encodeImage('blogImage')}></input>
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/*<img id="authorImagePreview" className="" src="#" alt="authorImage" style={{width: "16%"}}/>
          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon " id="" style={{width:"20%"}}>
              <span type="text" id="" placeholder="Author Image" >Author Image</span>
            </span>
            <div className="form-control custom-file" style={{width:this.state.inpWidth}}>
              <input type="file" className="form-control" id="authorImage" name="filename" onChange={() => this.encodeImage('authorImage')}></input>
              <label className="custom-file-label" for="" id="authorImageLabel">Choose file ... only png</label>
            </div>
          </div><br/>*/}

          {/*<div className="input-group mb-3" style={{width:"80%"}}>
            <input type="text" className="input-group-addon" id="" placeholder="Blog Rating" style={{width:"20%"}}></input>
            <input type="text" className="form-control" id="blogRating" placeholder="*" style={{width:this.state.inpWidth}} required></input>
          </div><br/>*/}

          {/*<div className="input-group mb-3" style={{width:"80%"}}>
            <input type="text" className="input-group-addon" id="" placeholder="Author Rating" style={{width:"20%"}}></input>
            <input type="text" className="form-control" id="authorRating" placeholder="*" style={{width:this.state.inpWidth}} required></input>
          </div><br/>*/}
          <div ClassName="col-md-4" style={{width : "80%", position:"relative", height:"50px"}}>
            <button type='button' className="btn btn-primary bottomright" style={{margin:'10px'}} id = 'save' onClick={this.save_to_dynamo}>Add Doc</button>
          </div>
        </div>


      </div>
    )
  }
}
export default AddDoc
