import React, { Component } from "react";

import { Link } from "react-router-dom";
import BreadCrumbs from "../../BreadCrumbs";
import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1, apiBaseUrl5 } from "../../apiUtils";
import { apiBaseUrl2 } from "../../apiUtils";
import FileBase64 from "react-file-base64";
import Modal from "react-modal";
import { ChatFeed, Message } from "react-chat-ui";
import Select from "react-select";
import PageChecklistComponent from "./pageChecklistComponent";
import SubmitButton from "../../common/SubmitButton";
import DocResourceTemplate from "./DocResourceTemplate";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import _ from "underscore";
let context = null;
const data = [];
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px ",
    position: "Absolute",
    zIndex: "30000",
    backgroundColor: "none",
  },
};

export default class PageResourceTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assignmentTitle: data.assignmentTitle,
      startDate: data.startDate,
      endDate: data.endDate,
      assignmentOverview: data.assignmentOverview,
      entry: {
        checklists: [],
      },
      priority: data.priority,
      contactPersonsClients: [],
      contactPersons: [],
      employees: [],
      clients: [],
      attachmentAssignment: "N",
    };
    context = this;
    
  }

  


  componentDidMount() {
    const id = this.props.match.params.id;

    this.fetchQueryById(this, id);
   
  }

 

  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(
       apiBaseUrl1+"businusquery/getTemplateById/" + id
      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  



 


 

 

  saveToTemplate = (blog) => {
    this.sendMail(blog);
  };

  //updating the status button

  render() {
    const { sliderState } = this.state;
    const entry=this.state.entry;
    const active = " active-button";
    const deactive = "";
    return (
      <div className="dedicated-div">
        <div className="row breadcrumb1-row" style={{ marginLeft: "2px" }}>
          <div className="col-md-12">
            <BreadCrumbs />
          </div>
        </div>
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
          <div className="dedicated-page2">
            <div className="row ">
              <div className="col-md-12 info-column account-col2">
                <div id="field">
                  <h2 className="account-head"> Edit Template</h2>
                  <div className="row">
                    <div className="col-md-8">


                        <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                   Template Id:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.templateId!=undefined ? entry.templateId : 'Not available'}</h4>
                                </div>
                              </div>


                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                   Template Type:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.templateType!=undefined ? entry.templateType : 'Not available'}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                   Template Name:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.title!=undefined ? entry.title : 'Not available'}</h4>
                                </div>
                              </div>

                      {this.state.entry.templateType != "Document" ? (
                        <span>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                   Name:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.name!=undefined ? entry.name : 'Not available'}</h4>
                                </div>
                              </div>


                          {this.state.entry.templateType == "Url" ? (
                               <div className="form-group">
                               <label className="control-label col-md-4">
                                 <span
                                   id="ember295"
                                   className="popovercontainer text-dashed-underline ember-view"
                                   data-original-title
                                   title
                                 >
                                URL:
                         </span>{" "}
                               </label>
                               <div className="col-md-6">
                                 <h4 className="detail-fields">{entry.url!=undefined ? entry.url : 'Not available'}</h4>
                               </div>
                             </div>

                          ) : (
                            ""
                          )}

                          <div className="form-group">
                            <label className="control-label col-md-4">
                              {this.state.entry.templateType == "Checklist"
                                ? "Overview"
                                : "Description"}
                            </label>
                            <div className="col-md-6">
                              <textarea
                                id="ember301"
                                onChange={this.handleOnChange}
                                name="update"
                                value={this.state.entry.update}
                                className="form-control ember-text-field ember-view"
                                type="text"
                              ></textarea>
                            </div>
                          </div>

                          {this.state.type == "Attachment" ? (
                            <div className="form-group">
                              <label className="control-label col-md-4">
                                <span
                                  id="ember295"
                                  className="popovercontainer text-dashed-underline ember-view"
                                  data-original-title
                                  title
                                >
                                  Attachment:
                                </span>{" "}
                              </label>
                              <div
                                className="inline-fields col-md-8"
                                style={{ padding: 0 }}
                              >
                                <div className="col-md-12">
                                  <div className="col-md-8 upload-box">
                                    {" "}
                                    <FileBase64
                                      multiple={true}
                                      onDone={this.getFiles.bind(this)}
                                    />
                                  </div>

                                  <div className="col-md-4">
                                    <button
                                      className="btn btn-primary attachment"
                                      onClick={() =>
                                        this.fileUploadHandler(
                                          this,
                                          "attachment"
                                        )
                                      }
                                      value="Select a File"
                                    >
                                      <i
                                        className="fa fa-paperclip"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          {this.state.entry.templateType == "Checklist" ? (
                            <div>
                              <button
                                className="add-button"
                                onClick={() => this.openWorkflowModal()}
                                style={{ width: "220px" }}
                              >
                                {" "}
                                <i
                                  style={{ fontSize: "15px" }}
                                  className="fa fa-plus "
                                ></i>
                                Add a Checklist
                              </button>

                              {this.state.entry.checklists.map(
                                (checklist, index) => {
                                  return (
                                    <PageChecklistComponent
                                      checklistId={this.state.entry.id}
                                      checklist={checklist}
                                      checklistIndex={index}
                                      saveTochecklistList={
                                        this.saveToChecklistList
                                      }
                                    />
                                  );
                                }
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </span>
                      ) : (
                        <DocResourceTemplate
                          saveToTemplate={this.saveToTemplate}
                        />
                      )}
                    </div>
                  </div>

                  {/* < div style={{marginTop:'15px',marginLeft:'24px'}} className="form-group">
                    <label className="control-label col-md-4">
                      <span
                      
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        {" "}
                        Do you want to attach an Assignment* :{" "}
                      </span>{" "}
                    </label>
                    <div className="col-md-8">
                      <div className="radio-inline">
                        <label className="cursor-poiner">
                          <input
                            name="attachmentAssignment"
                            type="radio"
                            value={'Y'}
                            onChange={this.handleOnAttachment}
                            defaultValue={'Y'}
                          />
                        Yes
                        </label>
                      </div>
                      <div className="radio-inline">
                        <label className="cursor-poiner">
                          <input
                            name="attachmentAssignment"
                            type="radio"
                            value={'N'}
                            onChange={this.handleOnAttachment}
                            defaultValue={'N'}
                          />
                        No
                        </label>
                      </div>
                      
                      
                    </div>
                  </div>
            {this.state.attachmentAssignment=='Y' ? 
<span>
                  <div className="form-group">
                        <label className="control-label col-md-3">Assignment:</label>
                        <div className="col-md-7">
                        <select
                            name="selectedAssignment"
                            onChange={this.handleOnAssignChange}
                            value={this.state.selectedAssignment}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >
                <option value="">Select Assignment</option>
                            {this.state.assignments.map(function (client, i) {

                              return (<option value={client.id}>{client.assignmentId} {client.assignmentTitle}</option>)
                            })}

                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="control-label col-md-3">Shared To:</label>
                        <div className="col-md-7">

                        {this.state.contactPersons.length > 0 ?
                             <Select
                             value={this.state.sharedTo}
                             onChange={this.onSelect}
                             options={this.state.contactPersons}
                             isMulti={true}
                           />
                            :  <input
                            name="subject"
                            disabled="true"
                            value='Select Assignment Above'
                            className="form-control ember-text-field ember-view"
                            type="text"
                          />}

                        
                        </div>
                      </div>
                      </span>
                      :''}




    {this.state.attachmentAssignment=='N' ? 
<span>
                  <div className="form-group">
                        <label className="control-label col-md-3">Client:</label>
                        <div className="col-md-7">
                        <select
                            name="selectedClient"
                            onChange={this.handleOnClientChange}
                            value={this.state.selectedClient}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >
                <option value="">Select Client</option>
                            {this.state.clients.map(function (client, i) {

                              return (<option value={client.id}>{client.customerId} {client.companyName}</option>)
                            })}

                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="control-label col-md-3">Shared To (Client Contacts)</label>
                        <div className="col-md-7">

                        {this.state.contactPersonsClients.length > 0 ?
                             <Select
                             value={this.state.sharedToClient}
                             onChange={this.onSelectClients}
                             options={this.state.contactPersonsClients}
                             isMulti={true}
                           />
                            :  <input
                            name="subject"
                            disabled="true"
                            value='Select Client Above'
                            className="form-control ember-text-field ember-view"
                            type="text"
                          />}

                        
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="control-label col-md-3">Shared To (Team):</label>
                        <div className="col-md-7">

                        {this.state.employees.length > 0 ?
                             <Select
                             value={this.state.sharedTo}
                             onChange={this.onSelect}
                             options={this.state.employees}
                             isMulti={true}
                           />
                            :  ''}

                        
                        </div>
                      </div>
                      </span>
                      :''}
 */}

                  <Modal
                    isOpen={this.state.modalWorkflowIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <div className="cross-row text-right">
                      <div
                        style={{ marginRight: "-5px" }}
                        onClick={this.closeModal}
                      >
                        <i
                          style={{ fontSize: "25px", fontWeight: "400" }}
                          className="fa fa-close"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                    <div className="header2">Add Category name</div>

                    <div className="row content1-div  text-left">
                      <input
                        className="fieldbox1"
                        onChange={this.handleOnChangeCol}
                        type="text"
                        value={this.state.columnName}
                        name="columnName"
                        placeholder="Category Name"
                        style={{
                          fontSize: "17px",
                          fontFamily: "montserrat,sans-serif",
                        }}
                      />

                      <div className="text-center">
                        {" "}
                        <div
                          className="btn btn-primary send-button"
                          onClick={() => this.addWorkflowInList()}
                          placeholder="Attachment"
                        >
                          Submit
                        </div>
                      </div>
                    </div>
                  </Modal>

                  <div className="text-left">
                    {this.state.type == "Document" ? (
                      ""
                    ) : (
                      <SubmitButton
                        clickHandler={this.sendMail}
                        isSubmitting={this.state.isSubmitting}
                      />
                    )}
                  </div>
                  {/* this says that if editing state is on then the Function saveAccountDetails will be called */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
