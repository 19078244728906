import React from 'react';
import axios from 'axios';
import BlogSidebar from '../../Blog/BlogSidebar';
import { Link } from 'react-router-dom';
import ReadOnly from '../../Editor/json-to-html';
import { apiBaseUrl20 } from '../../../components/apiUtils';

export default class PageBlog extends React.Component{
    constructor(props){
        super(props)
        this.state={
            resData:[],
            externalMore:[]
        }
    }

    componentDidMount(){
        let id = this.props.match.params.id
        this.fetchBlogById(id)
    }

    fetchBlogById(id){
        return axios({
            url: (apiBaseUrl20+'blog/get/'+id),
            method: 'GET',
            crossDomain: true,
            responseType: 'json',
          }).then((response) => {
            this.setState({resData:response.data,externalMore:response.data.more.external});
         });
    }

    deleteBlogId(art_id){
      if (window.confirm("Are you sure you want to delete this article ?")) {

        axios({
          url: (apiBaseUrl20+'blog/get/' + art_id),
          method: 'Delete',
        }).then(() => {
          alert("Blog Deleted");
          window.location.replace('/knowledgeHub/view-article/')
        });
  
      }
    }

    openEditPage(id){
      sessionStorage.setItem('learningCategory',this.state.resData.learningCategory)
      window.location.replace('/knowledgeHub/edit-article/'+id)
    }

    render(){
        return(
            <div className='row'>
                <div className='col-md-2 col-lg-2'> <BlogSidebar/></div>
                <div className='col-md-10 col-lg-10'>
                    <div className='dedicated-div'>
                    <div className='row breadCrumb1-row'><h4>Blog : <div className='pull-right' style={{color:'#fff',marginRight:'3%',cursor:'pointer'}} onClick={()=>this.deleteBlogId(this.state.resData.id)}><i class="fas fa-trash-alt"></i> Delete </div><div className="pull-right" style={{color:'#fff',marginRight:'3%',cursor:'pointer'}} onClick={()=>this.openEditPage(this.state.resData.id)}><i className="fa fa-edit"></i>EDIT</div></h4></div>
                    
                    <div className='row'>
                    <div className='dedicated-page'>
                        <div className='header-div2'>
                            <div className='bar' style={{borderTop:"#1E5B81", color: "blue"}}>
                                <div className='row'>
                                <div className='col-md-5 col-lg-5'/>
                                
                                <div className='content-div'>
                                <div className="form-group col-md-12 col-lg-12">
                                <label className="control-label col-md-3">
                                    <span id="ember295" className="popovercontainer text-dashed-underline ember-view" data-original-title title>
                                        Title :
                                    </span>{" "}
                                </label>
                                <div className="col-md-9">
                                  <h4 className="detail-fields">{this.state.resData.heading}</h4>
                                </div>
                              </div>

                              <div className="form-group col-md-12 col-lg-12">
                                <label className="control-label col-md-3">
                                    <span id="ember295" className="popovercontainer text-dashed-underline ember-view" data-original-title title>
                                        Subtitle :
                                    </span>{" "}
                                </label>
                                <div className="col-md-9">
                                  <h4 className="detail-fields">{this.state.resData.subHeading}</h4>
                                </div>
                              </div>

                              <div className="form-group col-md-12 col-lg-12">
                                <label className="control-label col-md-3">
                                    <span id="ember295" className="popovercontainer text-dashed-underline ember-view" data-original-title title>
                                        Content :
                                    </span>{" "}
                                </label>
                                <div className="col-md-9">
                                  <h4 style={{height:'700px',overflowY:'scroll'}} className="detail-fields"> <ReadOnly data={this.state.resData.content}/></h4>
                                </div>
                              </div>

                              <div className="form-group col-md-12 col-lg-12">
                                <label className="control-label col-md-3">
                                    <span id="ember295" className="popovercontainer text-dashed-underline ember-view" data-original-title title>
                                        Content Summary :
                                    </span>{" "}
                                </label>
                                <div className="col-md-9">
                                  <h4 className="detail-fields">{this.state.resData.contentSummary!==undefined?this.state.resData.contentSummary:''}</h4>
                                </div>
                              </div>                              

                              <div className="form-group col-md-12 col-lg-12">
                                <label className="control-label col-md-3">
                                    <span id="ember295" className="popovercontainer text-dashed-underline ember-view" data-original-title title>
                                        Content Source :
                                    </span>{" "}
                                </label>
                                <div className="col-md-9">
                                  <h4 className="detail-fields">{this.state.resData.source}</h4>
                                </div>
                              </div>

                              <div className="form-group col-md-12 col-lg-12">
                                <label className="control-label col-md-3">
                                    <span id="ember295" className="popovercontainer text-dashed-underline ember-view" data-original-title title>
                                        Source Url :
                                    </span>{" "}
                                </label>
                                <div className="col-md-9">
                                  <h4 className="detail-fields">{this.state.resData.sourceUrl}</h4>
                                </div>
                              </div>
                              <div className="form-group col-md-12 col-lg-12">
                                <label className="control-label col-md-3">
                                    <span id="ember295" className="popovercontainer text-dashed-underline ember-view" data-original-title title>
                                        Tags :
                                    </span>{" "}
                                </label>
                                <div className="col-md-9">
                                  <h4 className="detail-fields">{this.state.resData.tags!==undefined && this.state.resData.tags.length!==0?this.state.resData.tags.map((tag)=>(
                                    <span>{tag}, </span>
                                  )):""}</h4>
                                </div>
                              </div>

                              <div className="form-group col-md-12 col-lg-12">
                                <label className="control-label col-md-3">
                                    <span id="ember295" className="popovercontainer text-dashed-underline ember-view" data-original-title title>
                                        Date of Publishing :
                                    </span>{" "}
                                </label>
                                <div className="col-md-9">
                                  <h4 className="detail-fields">{this.state.resData.datePosted}</h4>
                                </div>
                              </div>

                              <div className="form-group col-md-12 col-lg-12">
                                <label className="control-label col-md-3">
                                    <span id="ember295" className="popovercontainer text-dashed-underline ember-view" data-original-title title>
                                        Read Time :
                                    </span>{" "}
                                </label>
                                <div className="col-md-9">
                                  <h4 className="detail-fields">{this.state.resData.readTime}</h4>
                                </div>
                              </div>

                              <div className="form-group col-md-12 col-lg-12">
                                <label className="control-label col-md-3">
                                    <span id="ember295" className="popovercontainer text-dashed-underline ember-view" data-original-title title>
                                        Author :
                                    </span>{" "}
                                </label>
                                <div className="col-md-9">
                                  <h4 className="detail-fields">{this.state.resData.authorName}</h4>
                                </div>
                              </div>

                              <div className="form-group col-md-12 col-lg-12">
                                <label className="control-label col-md-3">
                                    <span id="ember295" className="popovercontainer text-dashed-underline ember-view" data-original-title title>
                                        Author Description :
                                    </span>{" "}
                                </label>
                                <div className="col-md-9">
                                  <h4 className="detail-fields">{this.state.resData.authorDescription}</h4>
                                </div>
                              </div>

                              <h5 className='col-md-12 col-lg-12 khub_blg_seo-c'>SEO</h5>
                              <div className="form-group col-md-12 col-lg-12">                                
                                <label className="control-label col-md-3">
                                    <span id="ember295" className="popovercontainer text-dashed-underline ember-view" data-original-title title>
                                        SEO :
                                    </span>{" "}
                                </label>
                                <div className="col-md-9">
                                  <h4 className="detail-fields">{this.state.resData.seo}</h4>
                                </div>
                              </div>

                              <div className="form-group col-md-12 col-lg-12">                                
                                <label className="control-label col-md-3">
                                    <span id="ember295" className="popovercontainer text-dashed-underline ember-view" data-original-title title>
                                        Slug :
                                    </span>{" "}
                                </label>
                                <div className="col-md-9">
                                  <h4 className="detail-fields">{this.state.resData.slug}</h4>
                                </div>
                              </div>

                              <div className="form-group col-md-12 col-lg-12">                                
                                <label className="control-label col-md-3">
                                    <span id="ember295" className="popovercontainer text-dashed-underline ember-view" data-original-title title>
                                      Meta Description :
                                    </span>{" "}
                                </label>
                                <div className="col-md-9">
                                  <h4 className="detail-fields">{this.state.resData.metaDescription}</h4>
                                </div>
                              </div>
                              
                              {this.state.externalMore.length>0?
                              <div>
                                
                                <h5 className='col-md-12 col-lg-12 khub_blg_seo-c'>More</h5>
                            {this.state.resData.more.external.map((ext)=>(
                                <div>
                                <div className="form-group col-md-12 col-lg-12">                                
                                  <label className="control-label col-md-3">
                                      <span id="ember295" className="popovercontainer text-dashed-underline ember-view" data-original-title title>
                                          Source URL :
                                      </span>{" "}
                                  </label>
                                  <div className="col-md-9">
                                    <h4 className="detail-fields">{ext.src}</h4>
                                  </div>
                                </div>

                                <div className="form-group col-md-12 col-lg-12">                                
                                  <label className="control-label col-md-3">
                                      <span id="ember295" className="popovercontainer text-dashed-underline ember-view" data-original-title title>
                                          URL :
                                      </span>{" "}
                                  </label>
                                  <div className="col-md-9">
                                    <h4 className="detail-fields">{ext.url}</h4>
                                  </div>
                                </div>

                                <div className="form-group col-md-12 col-lg-12">                                
                                  <label className="control-label col-md-3">
                                      <span id="ember295" className="popovercontainer text-dashed-underline ember-view" data-original-title title>
                                          Name :
                                      </span>{" "}
                                  </label>
                                  <div className="col-md-9">
                                    <h4 className="detail-fields">{ext.name}</h4>
                                  </div>
                                </div>
                                </div>))}
               
                             
                              </div>
                                :""
                              }

                  <div class="col-md-4" style={{width: "30%"}}>
                    <div class="thumbnail">
                      {this.state.resData.authorImageURL!==""?
                      <img src={this.state.resData.authorImageURL} alt="authorImagePreview" id="authorImagePreview" style={{width: "100%",}}/>
                      :""}
                    </div>
                  </div>

                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}