import React, { Component } from "react";

import BreadCrumbs from '../../BreadCrumbs';
import { Link } from "react-router-dom";
import axios from 'axios';
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import CsvDownloader from 'react-csv-downloader'
import ReactTable from 'react-table';
import $ from "jquery";
import _ from 'underscore';
import ReactExport from "react-export-excel";
import CreatableSelect from 'react-select/creatable';


import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

const data = [];


let context="null";

const columnsForExcel = [
  {
    id: 'workflowId',
    displayName : "workflow ID",

  },
  {
    displayName : "workflowName",

    id: "workflowName"
  },
  {
    displayName : "workflowOverview",
    id: "workflowOverview",
   
  },
  
 
  {
    displayName : "Date",
    id: "date",
    
  },
  {
    displayName : "Time",
    id: "time",
  
  }
];
const colformail = [{
  id:"subject",
  label:"subject"},
  {id:"message",
  label:"message"},
    {
      id:"attachment",
      label:"attachment"},
      {
        id:"from",
        label:"from"},
        {
          id:"mailId",
          label:"mailId"},
          {
            id:"client",
            label:"client"},
            {
              id:"assignmentId",
              label:"assignmentId"}]
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
//defined all the columns and fetched the data for every cell.
const columns = [

  
  {Header: props => <span className="form-textHead cell-head">Workflow Id</span>,
    accessor: d => {return {workflowId:d.workflowId,id:d.id}},
    filterMethod: (filter, row) =>
             { return row.name.name.toLowerCase().includes(filter.value.toLowerCase()) }
                     
                  ,
                
    id:"workflowId",
    Cell: props => 
    <Link className="link" to={"./view/"+ props.value.id}><span>{props.value.workflowId}</span></Link>,
    minWidth:180
    
  },
  {

    Header: props => <span className="form-textHead cell-head">Workflow Name</span>,
    accessor: d => {return {workflowName:d.workflowName,id:d.queryId}},
    filterMethod: (filter, row) =>
             { return row.name.name.toLowerCase().includes(filter.value.toLowerCase()) }
                     
                  ,
                
     id:"workflowName",
     Cell: props => 
    <span>{props.value.workflowName}</span>,
     minWidth:180
     },

    {
     Header: props => <span className="form-textHead cell-head">Workflow Overview</span>,
     accessor: 'workflowOverview',
     Cell: props => <span className='form-text cell'>{props.value}</span>,
     minWidth:240
    
    },
   
     
    {
      Header: props => (
        <span className="form-textHead cell-head">Date of Creation</span>
      ),
      accessor: d => {
        return { date: d.date, id: d.id };
      },
      filterMethod: (filter, row) => {
        return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase());
      },
  
      id: "date",
      Cell: props => new Date(props.value.date).toDateString(),
      minWidth: 180
    },
    {
      Header: props => (
        <span className="form-textHead cell-head">Time of Creation</span>
      ),
      accessor: d => {
        return { date: d.date, id: d.id };
      },
      filterMethod: (filter, row) => {
        return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase());
      },
  
      id: "time",
      Cell: props => new Date(props.value.date).toLocaleTimeString(),
      minWidth: 280
    }
  ];
export default class viewWorkflow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      menu1:[
       
      ],
tags:"",

 
 
    };
    context = this;
  }
  componentDidMount(){

 
  //mailType we get is "mail" but we want to capitalize first letter i.e Mail or Update or Document
  

this.fetchQueries(this);
 
}


componentWillReceiveProps(nextProps){


 
  

}


  toggleOpen = id => {
    var list  =this.state.menu1;
    var index = _.findIndex(list,function(entry){return entry.id==id});
    
    list[index].status =  (list[index].status == "open" || list[index].status==undefined) ? "close" : 'open';
    
    var newList = JSON.parse(JSON.stringify( list ));
    context.setState({menu1:newList});
    context.updateStatus(context,id,list[index].status);
    
    
    
      };

  
  fetchQueries(that,mailType){
    
    axios.get(apiBaseUrl1+ "businus/workflow/list/"+localStorage.spId)
    .then(function (response) {
      console.log(response.data);
      that.setState({menu1:_.sortBy(response.data,"date")});
      that.generateExcelData(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }
  generateExcelData = data => {
    
    let excelData = [];
    _.each(data, function(d) {
  
      
      let row = {
        workflowId: d.workflowId,
        workflowName: d.workflowId,
        workflowOverview: d.workflowOverview,
        workFlowList: this.getCommaSeparated(d.workFlowList,'name'),
        by: d.by,       
        date: new Date(d.date).toDateString(),
        time: new Date(d.date).toLocaleTimeString()
     
      
      }

       
      excelData.push(row);
      console.log(excelData);
    });
    this.setState({ excelData });
  };
  
  getCommaSeparated=(teamMember,name)=>{
    console.log(teamMember)
   let names= _.map(teamMember, function(d){
   return d[name];
   })
   
   return names.join(";")
   }
  

  render() {
    return (
      <div>
       
      
      <div className="Div">
      <div className="row breadcrumb-row">
          <div className="col-md-10"><BreadCrumbs/></div>
          <div className="col-md-2">
          <ExcelFile element={<button className="download-button-wrapper">Download Data in Excel format</button>}>
                <ExcelSheet data={this.state.excelData} name="WorkflowList">

                  {columnsForExcel.map(d=>  <ExcelColumn label={d.displayName} value={d.id}/>)}
                   
                   
                </ExcelSheet>

                <ExcelSheet data={this.state.mails} name="Mails"> 
                {colformail.map(d=>  <ExcelColumn label={d.label} value={d.id}/>)}
                   
              
                </ExcelSheet>
                
                
             
            </ExcelFile>
      </div>
      </div>
            <div className="row">
          
              <Row>
                <Col>
                  <div className="card">

                  <ReactTable className="myTable" resolveData={data => data.map(row => row)}  data={this.state.menu1} columns={columns} filterable />
                  


                    
                       
                      
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          </div>
    );
  }
}
