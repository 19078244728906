import React, { Component } from "react";
import "../../style/panel.css";
import { Link } from "react-router-dom";
import $ from "jquery";


const data = [];
export default class CompanyLawSidebar extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []

    };

  }
  componentDidMount(){
    this.handleLine();
  }
  handleLine(){
      $(function(){
      $("#companylaw").click(function(){
        $("#caselaw1").toggle();
      });
      });
      $(function(){
        $("#caselaw1").click(function(){
          $("#caseadd1").toggle();
        });
        });
      $(function(){
      $("#companylaw").click(function(){
        $("#act1").toggle();
      });
      });
      $(function(){
        $("#act1").click(function(){
          $("#actadd1").toggle();
        });
        });
      $(function(){
      $("#companylaw").click(function(){
        $("#rule1").toggle();
      });
      });
      $(function(){
        $("#rule1").click(function(){
          $("#ruleadd1").toggle();
        });
        });
      $(function(){
      $("#companylaw").click(function(){
        $("#circular1").toggle();
      });
      });
      $(function(){
        $("#circular1").click(function(){
          $("#circularadd1").toggle();
        });
        });
   
  }

  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (

            <div className="sidebar">
              <div id="sidebar1">
                <div className="sidebar-elementhead"><Link className="sidebar-link" to="/knowledgeHub">Home</Link></div>
                <div className="oneTab">
                <div className="sidebar-element" id="c1"><Link className="sidebar-link" to="/knowledgeHub/businus-school/legal"> <i class="fa fa-bar-chart" />Dashboard<i className="fa fa-angle-right " /></Link></div>
                {/* <div className="sidebar-subelement" ><i class="fa fa-envelope" />Company Law<i className="fa fa-angle-right " /></div> */}
                </div>
 
              <div className="oneTab">
                <div className="sidebar-element" id="companylaw">
                  <i class="fa fa-envelope" />
                    Company Law
                  <i className="fa fa-angle-down " />
                </div>
                <h2 className="sidebar-subelement" id='caselaw1'>
                  <i class="fa fa-envelope" />
                      Case Law
                  <i className="fa fa-angle-down "/>
                  </h2>
                <div className="sidebar-subelement2" id="caseadd1">
                  <Link className="sidebar-link" to="/knowledgeHub/add-company-law/category/case-law">
                    <i class="fa fa-envelope" />
                    Add Category
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                  <Link className="sidebar-link" to="/knowledgeHub/view-company-law/category/case-law">
                    <i class="fa fa-envelope" />
                    View Category
                    <i className="fa fa-angle-right " />
                  </Link><br/>

                  <Link className="sidebar-link" to="/knowledgeHub/add-company-law/resources/case-law">
                    <i class="fa fa-envelope" />
                    Add Resources
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                  <Link className="sidebar-link" to="/knowledgeHub/view-company-law/resources/case-law">
                    <i class="fa fa-envelope" />
                    View Resources
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                </div>

                  <h2 className="sidebar-subelement" id='act1'>
                  <i class="fa fa-envelope" />
                    Acts
                  <i className="fa fa-angle-down " />
                  </h2>
                  <div className="sidebar-subelement2" id="actadd1">
                  <Link className="sidebar-link" to="/knowledgeHub/add-company-law/category/acts">
                    <i class="fa fa-envelope" />
                      Add Category
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                  <Link className="sidebar-link" to="/knowledgeHub/view-company-law/category/acts">
                    <i class="fa fa-envelope" />
                      View Category
                    <i className="fa fa-angle-right " />
                  </Link>

                  <Link className="sidebar-link" to="/knowledgeHub/add-company-law/resources/acts">
                    <i class="fa fa-envelope" />
                    Add Resources
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                  <Link className="sidebar-link" to="/knowledgeHub/view-company-law/resources/acts">
                    <i class="fa fa-envelope" />
                    View Resources
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                </div>

                <h2 className="sidebar-subelement" id='rule1'>
                  <i class="fa fa-envelope" />
                    Rules
                  <i className="fa fa-angle-down " />
                  </h2>
                  <div className="sidebar-subelement2" id="ruleadd1">
                  <Link className="sidebar-link" to="/knowledgeHub/add-company-law/category/rules">
                    <i class="fa fa-envelope" />
                      Add Category
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                  <Link className="sidebar-link" to="/knowledgeHub/view-company-law/category/rules">
                    <i class="fa fa-envelope" />
                      View Category
                    <i className="fa fa-angle-right " />
                  </Link>

                  <Link className="sidebar-link" to="/knowledgeHub/add-company-law/resources/rules">
                    <i class="fa fa-envelope" />
                    Add Resources
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                  <Link className="sidebar-link" to="/knowledgeHub/view-company-law/resources/rules">
                    <i class="fa fa-envelope" />
                    View Resources
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                </div>

                <h2 className="sidebar-subelement" id='circular1'>
                  <i class="fa fa-envelope" />
                    Circular & Notification
                  <i className="fa fa-angle-down " />
                  </h2>
                  <div className="sidebar-subelement2" id="circularadd1">
                  <Link className="sidebar-link" to="/knowledgeHub/add-company-law/category/circular-notification">
                    <i class="fa fa-envelope" />
                      Add Category
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                  <Link className="sidebar-link" to="/knowledgeHub/view-company-law/category/circular-notification">
                    <i class="fa fa-envelope" />
                      View Category
                    <i className="fa fa-angle-right " />
                  </Link>

                  <Link className="sidebar-link" to="/knowledgeHub/add-company-law/resources/circular-notification">
                    <i class="fa fa-envelope" />
                    Add Resources
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                  <Link className="sidebar-link" to="/knowledgeHub/view-company-law/resources/circular-notification">
                    <i class="fa fa-envelope" />
                    View Resources
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                </div>
                </div>
              

              </div>
            </div>

    );
  }
}
