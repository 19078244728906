import React, { Component } from "react";
import "../../../style/panel.css";
import {BrowserRouter, Route} from 'react-router-dom';
import { Link } from "react-router-dom";
import $ from "jquery";

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";


import BreadCrumbs from '../../BreadCrumbs';

import addSP  from './addSP';
import editSP  from './editSP';
import pageSP  from './pageSP';
import viewSP  from './viewSP';
import viewSPaccess  from './viewSPaccess';
import SidebarSP from './SidebarSP';
import editAccessControl from './editAccessControl';
import Dashboard from './Dashboard';


const data = [];
export default class OrganisationMainapp extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []
     
    };
    
  }
  componentDidMount(){
    this.handleLine();
    //this.setState({title:this.props.match.params.productType});
  }
  handleLine(){
    
   
    const tab1= this.refs.tab1;
    const sidebar1= this.refs.sidebar1;
    const section= this.refs.section;
   
    
    $(function(){
      $("#tab1").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
        $("#section").toggle();
      });
      });
     
  }
  
  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
      <div className="mainDiv" >
       
       <div className="row">
         <div className="col-md-2">
         <SidebarSP title={this.state.title}/>
         </div>
         <div className="col-md-10">
         
      
<div className="section">

<Route exact path = "/service-providers/add" component = {addSP} />

<Route exact path = "/service-providers/view" component = {viewSP} />
<Route  exact path = "/service-providers/set-controls/view" component = {viewSPaccess} />
<Route  exact path = "/service-providers/view/:id" component = {pageSP} />
<Route  exact path = "/service-providers" component = {Dashboard} />
<Route  exact path = "/service-providers/edit/:id" component = {editSP} />
<Route  exact path = "/service-providers/set-controls/view/:id" component = {editAccessControl} />



</div>

         </div>
       </div>
      
     </div>

    );
  }
}
