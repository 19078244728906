import React, { Component } from "react";
import axios from "axios";

import { Link } from "react-router-dom";
import ReactTable from "react-table";
import $ from "jquery";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6
import "react-table/react-table.css";
import FileBase64 from "react-file-base64";
import Modal from "react-modal";
import { ChatFeed, Message } from "react-chat-ui";
import _ from "underscore";
import { Multiselect } from "multiselect-react-dropdown";
import { apiBaseUrl1, apiBaseUrl5, apiIssueMaster } from "../apiUtils";
import { apiBaseUrl2 } from "../apiUtils";
import ResourcesUserCommunication from "./ResourcesUserCommunication";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px ",
    position: "Absolute",
    zIndex: "30000",
    backgroundColor: "none",
  },
};

let context = null;

export default class MultiAttachments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attachment: "NA",
      attachments:[],
      attachmentPlaceholders: [{ attachment: "" }],
    };

    context = this;
  }

  componentDidMount() {
    // this.fetchTeamMails(this,"TEAM-MAIL");
  }

  fileUploadHandler = (that, docType) => {
    that.setState({ loading: true });
    
    let promises = [];

    _.each(that.state.selectedFile, function (selectedFile) {
      promises.push(
        axios.post(apiBaseUrl2 + "businusquery/uploadAttachment", {
          uploadImage: selectedFile.base64,
          imageName: selectedFile.name,
        })
      );
    });

    Promise.all(promises)
      .then((result) => {
        let attachments = that.state.attachments || [];

        _.each(result, function (response, key) {
          if (response.status == 200) {
            that.setState({ successupload: true });

            attachments.push({
              attachmentName: that.state.selectedFile[key].name,
              size:that.state.selectedFile[key].size,
              type:that.state.selectedFile[key].type,
              attachment:
                "https://businus-files-live.s3.amazonaws.com/mailAttachments/" +
                that.state.selectedFile[key].name,

            });
          }
        });

        that.setState({ attachments: attachments });
        that.saveAttachments(attachments);
      })
      .catch((error) => console.log(error));
  };

  saveAttachments = (attachments) => {
    this.props.saveAttachments(attachments);
  };
  addMoreAttachment = () => {
    let attachmentPlaceholders = this.state.attachmentPlaceholders;
    attachmentPlaceholders.push({ attachment: "" });
    this.setState({ attachmentPlaceholders });
  };

  removeAttachment = (i) => {
    let attachmentPlaceholders = this.state.attachmentPlaceholders;
    attachmentPlaceholders.splice(i,1);
    this.setState({ attachmentPlaceholders });
  };
  getFiles(files) {
    console.log(files);
    this.setState({ selectedFile: files });
    this.fileUploadHandler(this);
  }

  render() {
    return this.state.attachmentPlaceholders.map((attach, i) => {
      return (
        <div key={i} style={{ marginTop: "5px" }} className="row">
          <div className="col-md-8 upload-box">
            {" "}
            <FileBase64
              multiple={true}
              onDone={this.getFiles.bind(this)}
            />{" "}
            <div className="row">
              <div className="col-md-2">  <p style={{color:"blue", }}>{this.state.attachments[i] ? this.state.attachments[i].size : ''}</p></div>
              <div className="col-md-2"> <p style={{color:"blue",}}>{this.state.attachments[i] ? this.state.attachments[i].type : ''}</p></div>
            </div>
          
           
          </div>
          &nbsp;
          {i === 0 ? (
            <div className="col-md-3">
              <button
                className="btn btn-primary attachment"
                type="button"
                onClick={() => this.addMoreAttachment()}
                value="Select a File"
              >
                <i className="fa fa-plus" aria-hidden="true"></i>Add More
              </button>
            </div>
          ) : (
            <button
              onClick={() => {this.removeAttachment(i)}}
              style={{ marginTop: 5 }}
              className="btn btn-danger"
            >
              X
            </button>
          )}
        </div>
      );
    });
  }
}
