import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { apiBaseUrl2 } from "../../apiUtils";
export default function EditCalendarImages() {

  // make an array of default images with the following keys. You can change the key-value pairs as per your comfort.
const [calImages, setCalImages] = useState(

  [
    {
      name: "January",
      id: "1",
      imageUrl:
        "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
    },
    {
      name: "February",
      id: "2",
      imageUrl:
        "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
    },
    {
      name: "March",
      id: "3",
      imageUrl:
        "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
    },
    {
      name: "April",
      id: "4",
      imageUrl:
        "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
    },
    {
      name: "May",
      id: "5",
      imageUrl:
        "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
    },
    {
      name: "June",
      id: "6",
      imageUrl:
        "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
    },{
      name: "July",
      id: "7",
      imageUrl:
        "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
    },{
      name: "August",
      id: "8",
      imageUrl:
        "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
    },{
      name: "September",
      id: "9",
      imageUrl:
        "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
    },{
      name: "October",
      id: "10",
      imageUrl:
        "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
    },{
      name: "November",
      id: "11",
      imageUrl:
        "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
    },{
      name: "December",
      id: "12",
      imageUrl:
        "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
    }

  ]);




//for encoding our image file
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }




  const postCalImages=(calImages)=>{
    axios.post(apiBaseUrl2+"businus/employee/update/" + localStorage.userId, {calImages:calImages})
  .then((res)=>
  {
    console.log('success');
    localStorage.setItem("calendarImages", JSON.stringify(calImages));
  
  })
  .catch((err)=>
  {
    console.log("error occured")
  })

  }


  const uploadHandler = async (e, imageKey) => {
    debugger;
    
      const fileOut = e.target.files[0];
    const file = await getBase64(fileOut);

    axios
      .post(
        "https://uv2ucx62n8.execute-api.us-east-1.amazonaws.com/live/businusquery/uploadCalImages",
        { uploadImage: file, imageName: fileOut.name }
      )
      .then((response) => {

        if (response.status === 200) {
          let tempArray = calImages;
    
         let found= tempArray.find((item,i)=>{
          return item.id==imageKey;
        })
        
        found.imageUrl=response.data.Location;
        setCalImages([...tempArray]);
     
       if(calImages){
         postCalImages(calImages)
       }
      } 
      })
      .catch((err) => {
        console.log(err);
      });
  };
//import bootstrap css if you wish to use this code. Else style it the way you've styled the entire module.
  return (
    <div className="calImages-div">
      {calImages.map((item, i) => {
        return (
          <div className="row">  
           
              <img src={item.imageUrl} className="cal-images" alt="" />
        
          
              <input
                className="calImages-inputs"
                type="file"
                id="file"
                onChange={(e) => {
                  uploadHandler(e, item.id);
                }}
              />
           
          </div>
        );
      })}
    </div>
  );
}