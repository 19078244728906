import React, { Component } from 'react';

import '../style/agency.css';
import '../style/animate.css';
import StyleModal from './StyleModal';
import TimelineModal from './TimelineModal';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'


/**
 * Timeline
 * It is the code for adding timeline section in the template
 * @module Timeline
 */
class Timeline extends Component {
  constructor(props){
    super(); 
    this.state ={toggleStyleMode:false,hideModal:true,title:'hello',Products:[
      {image:"Product1"
,head:"Time", 
subhead:"Beginnings",
description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." 
,showImageBox:false, 
showHeadBox:false, 
showContentBox:false,
toggleEditMode:false,
styles:{backgroundColor:'#fff',
color:'#000'}},
{
image:"Product1"
,head:"Time1", 
subhead:"Beginnings",
description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." 
,showImageBox:false, 
showHeadBox:false, 
showContentBox:false,
toggleEditMode:false,
styles:{backgroundColor:'#fff',
color:'#000'}}
    ] , 
    showImageBox:false, newUrl:'',
     styles:{backgroundColor:'#fff',
     color:'#000'}
    
    
    };



    this.addProducts = this.addProducts.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
    this.removeLastProduct = this.removeLastProduct.bind(this);
   
    this.confirmDelete = this.confirmDelete.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.saveProduct = this.saveProduct.bind(this);
    this.saveStyle = this.saveStyle.bind(this);
    this.toggleStyleModal = this.toggleStyleModal.bind(this);
    
   }
   
componentDidMount(){
	var productCount = this.props.productCount;
	var title = this.props.title;
	var innerProducts = this.props.innerProducts;

	var products = [];

	if(innerProducts.length==0){
	for(var i=0;i<productCount;i++){

products.push({timelineImage:"https://png.pngtree.com/png-clipart/20201111/ourlarge/pngtree-make-a-mark-hand-drawn-calendar-clipart-png-image_2403256.jpg"
,head:"Timeline "+ (i+1), 
subhead:"Beginnings",
description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." 
,showImageBox:false, 
showHeadBox:false, 
showContentBox:false,
toggleEditMode:false,
styles:{backgroundColor:'#fff',
color:'#000'}});

}}
else{
products = innerProducts;
}
this.setState({title:this.props.title});
this.setState({cardStyles:this.props.productStyle});
this.setState({Products:products});
}


   addProducts(){
    var prod = this.state.Products;
    var productName = prod.length+1;
    const newProduct = {timelineImage:"https://png.pngtree.com/png-clipart/20201111/ourlarge/pngtree-make-a-mark-hand-drawn-calendar-clipart-png-image_2403256.jpg"
    ,head:"Timeline " + productName, 
    subhead:"Beginnings",
    description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." 
    ,showImageBox:false, 
    showHeadBox:false, 
    showContentBox:false,
    toggleEditMode:false,
    styles:{backgroundColor:'#fff',
    color:'#000',descriptionColor:'black'}};
    prod.push(newProduct);
    this.setState({Products:prod});
    
    this.saveProduct(prod.length-1,newProduct);
   }
 
 
   saveProduct(i,product){
    
     var products= this.state.Products;
     products.forEach(function(prod,keyindex){
     if (keyindex==i){
     
       prod.head=product.head;
       prod.image=product.image;
       prod.subhead=product.subhead;
       prod.description=product.description;
       prod.timelineImage=product.timelineImage;
       prod.styles.backgroundColor = product.backgroundColor;
       prod.styles.color = product.color;
       prod.styles.descriptionColor = product.descriptionColor;
       
     }
     
     } );
   
  

    this.setState({Products:products});  /* FOR INNER PRODUCTS*/
    
	  this.props.saveSectionProperties(
		this.props.sectionKey,
		this.state.title,
		this.state.styles,
		products
		);
     
     
    }
 
   onChange = (e) => {
 
     const state = this.state
     state[e.target.name] = e.target.value;
     this.setState(state);
 
 
     console.log(this.state);
   }
 

   /**
 * removeLastProducts()
 * This segment is the code for removing last product in the section.
 * @function removeLastProducts()
 */
   removeLastProduct(){
     var prod = this.state.Products;
     //var productName = prod.length+1;
     prod.pop();
     this.setState({Products:prod});
     
     
    }
 
    removeProduct(removeAt){
      
     var prod = this.state.Products;
     //var productName = prod.length+1;
     prod.splice(removeAt,1);
     this.setState({Products:prod});
     
     
    }

    confirmDelete(index){


      confirmAlert({
        title: 'Confirm to Delete',
        message: 'Are you sure you want to delete section.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => this.removeLastProduct(index)
          },
          {
            label: 'No',
            onClick: () => console.log("")
          }
        ]
      })
    }
    hideModal(i){
 
   
    
     var products= this.state.Products;
     products.forEach(function(prod,keyindex){     //prod is every eleme
     if (keyindex==i){
       prod.toggleEditMode= !prod.toggleEditMode; //hiding modal
       
     }
     
     } );
 
    this.setState({Products:products});
    
    }
 
    confirmDelete(index){


      confirmAlert({
        title: 'Confirm to Delete',
        message: 'Are you sure you want to delete section.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => this.removeProduct(index)
          },
          {
            label: 'No',
            onClick: () => console.log("")
          }
        ]
      })
    }
    toggleStyleModal(){
 
     var status = !this.state.toggleStyleMode;
     this.setState({toggleStyleMode:status});
     
 
    }
    saveStyle(newstyle){
 
     
     this.setState({styles:newstyle});
     this.setState({title:newstyle.title});
     this.props.saveSectionProperties(
       this.props.sectionKey,
       newstyle.title,
       newstyle,
       this.state.Products
       );
     
 
    }
    render(){
      const {styles}= this.state;
    return(



<div>
        
        {/* About */}
        <section style={{color:styles.color,
          backgroundColor:styles.backgroundColor,
          padding:'40px',
          backgroundImage:styles.backgroundImage!='no image' ? styles.backgroundImage : '',
          padding:styles.padding,
          margin:styles.margin
          }}  id={this.props.title}>
        {this.state.toggleStyleMode ?  <StyleModal saveProduct={this.saveStyle} title={this.state.title} toggleStyleModal={this.toggleStyleModal} saveStyle={this.saveStyle}  styleProp={this.state.styles!=undefined ? this.state.styles:{}}/> : '' }
          <div className="container">
            <div className="row head-row">
              <div className="col-lg-12 text-center">
                <h2 className="section-heading ">{this.props.title}<span className="editclass editsign" onClick={()=>this.toggleStyleModal()}><i className="fa editclass fa-edit"></i></span><span className="adddivsign editclass" onClick={this.addProducts}>+</span></h2>
                
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
       
                <ul className="timeline animatedParent cardd">
               
                { this.state.Products.map((prod,i) => 
                  <li style={{backgroundColor:prod.styles.backgroundColor}} className={(i%2)!=1 ? '' : 'timeline-inverted'}>       {prod.toggleEditMode ?  <TimelineModal saveProduct={this.saveProduct} hideModal={this.hideModal} product={prod} keyindex={i} /> : '' }
                     <div  className="timeline-image  ">
                    
                   <img className="rounded-circle  img-fluid" src={prod.timelineImage} alt />
                     
                    </div>
                    <div className="timeline-panel panel1 animated fadeInLeft">
                      <div className="timeline-heading "  style={{color:prod.styles.color}}>
                        <h4  className="animated fadeInLeft"><i onClick={()=>this.hideModal(i)}  className="fa editclass fa-edit"></i>{prod.head} &nbsp;<i onClick={()=>this.confirmDelete(i)} style={{fontSize:'14px'}} className="fa editclass cross">X</i></h4>
                        <h4 className="timeline-subheading " style={{color:prod.styles.color}} > {prod.subhead}</h4>
                      </div>
                      <div className="timeline-body animatedParent">
                        <p style={{color:prod.styles.descriptionColor}} className=" animated webzy-testimonials-description fadeInLeft" >{prod.description}</p>
                      </div>
                    </div>
                    
                  </li>
                   )}
                 
                 
                 
                 
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* Team */}
        </div>
    );
  

    }
}

export default Timeline;