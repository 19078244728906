import $ from "jquery";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../style/panel.css";
import { getPrivilegeByName } from "./privilegeUtils";



const data = [];
export default class Main extends Component {
  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.toggleState = this.toggleState.bind(this);
    this.state = {
      opened: [],
      pageName: "",
      isLogged: false,
    };
  }
  componentDidMount() {
    debugger;
    this.handleLine();
    if (localStorage.getItem("isLoggedIn") == "YES") {
      this.setState({ isLogged: true });
    } else {
      this.setState({ isLogged: false });
    }
  }

  componentWillReceiveProps(newProps) {
    if (localStorage.getItem("isLoggedIn") == "YES") {
      this.setState({ isLogged: true });
    } else {
      this.setState({ isLogged: false });
    }
  }

  toggleState(com) {
    this.setState({
      pageName: com,
    });
  }

  handleLine() {
    const c1 = this.refs.c1;
    const tab1 = this.refs.tab1;
    const sidebar1 = this.refs.sidebar1;
    const section = this.refs.section;
    const c2 = this.refs.c2;
    const c3 = this.refs.c3;
    const c4 = this.refs.c4;
    const c5 = this.refs.c5;
    const c6 = this.refs.c6;
    const c7 = this.refs.c7;
    const c8 = this.refs.c8;
    const d1 = this.refs.d1;
    const d2 = this.refs.d2;
    const d3 = this.refs.d3;
    const d4 = this.refs.d4;
    const d5 = this.refs.d5;
    const d6 = this.refs.d6;
    const d7 = this.refs.d7;
    const d8 = this.refs.d8;

    $(function () {
      $("#tab1").click(function (e) {
        $(e.currentTarget).parent().toggleClass("sidebar-secondary");
        $(e.currentTarget).find("i:first").toggleClass("sidebar-back-arrow");
        $("#section").toggle();
      });
    });
  }

  toggleOpen = (id) => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter((i) => i !== id)] });
    }
  };

  render() {
    return !this.state.isLogged ? (
      <div></div>
    ) : (
      <div className="mainScreen">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="tab-row1">
                <div className="row">
                  {localStorage.userId=='68a21760-b1ab-11ec-9848-4b5758ef29c7#sp' ?
                  <div className="col-md-4">
                    <div className="tab-main text-center">
                      <h4>
                        <i
                          className="fa fa-tasks tab-image3"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </h4>

                      <Link to="./service-providers">
                        {" "}
                        <h2 className="tab-text-main">Service Providers</h2>
                      </Link>
                    </div>
                  </div>
                  :''}
                  {getPrivilegeByName("Legal").canView == true ? (
                    <div className="col-md-4">
                      <div className="tab-main text-center">
                        <h4>
                          <i
                            className="fa fa fa-balance-scale tab-image1"
                            style={{ fontSize: "40px" }}
                          ></i>
                        </h4>

                        <Link to="./legal">
                          <h2 className="tab-text-main">Businus.Services</h2>
                        </Link>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {getPrivilegeByName("Knowledge Hub").canView == true ? (
                    <div className="col-md-4">
                      <div className="tab-main text-center">
                        <h4>
                          <i
                            className="fa fa-book tab-image"
                            style={{ fontSize: "40px" }}
                          ></i>
                        </h4>

                        <Link to="./knowledgeHub">
                          
                          <h2 className="tab-text-main">Businus.School</h2>
                        </Link>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
            


                
                  {localStorage.isSp=='true' 
                  || getPrivilegeByName("Master").canView == true?
                  <div className="col-md-4">
                    <div className="tab-main text-center">
                      <h4>
                        <i
                          className="fa fa-table tab-image2"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </h4>

                      <Link to="./masters">
                        {" "}
                        <h2 className="tab-text-main">Masters</h2>
                      </Link>
                    </div>
                  </div>
                  :''}
                  <div className="col-md-4">
                    <div className="tab-main text-center">
                      <h4>
                        <i
                          className="fa fa-tasks tab-image3"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </h4>


                      <Link to="./assignment-master">
                        
                        <h2 className="tab-text-main">Assignments</h2>
                      </Link>
                    </div>
                  </div> 

                  {getPrivilegeByName("Access Control").canView == true  || localStorage.isSp=='true' ?    <div className="col-md-4">
                    <div className="tab-main text-center">
                      <h4>
                        <i
                          className="fa fa-lock tab-image1"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </h4>

                      <Link to="./access-control">
                        {" "}
                        <h2 className="tab-text-main">Access Control</h2>
                      </Link>
                    </div>
                  </div> : ''}

                  {getPrivilegeByName("Client-Communication").canView == true ?  <div className="col-md-4">
                    <div className="tab-main text-center">
                      <h4>
                        <i
                          className="fa fa-user tab-image"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </h4>
                      <a href="./communication-master">
                        {" "}
                        <h2 className="tab-text-main">Client Communication</h2>
                      </a>
                    </div>
                  </div> : ''}

                  {getPrivilegeByName("Team-Communication").canView == true ?     <div className="col-md-4">
                    <div className="tab-main text-center">
                      <h4>
                        <i
                          className="fa fa-users tab-image2"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </h4>
                      <a href="./team-communication">
                        {" "}
                        <h2 className="tab-text-main">Team Communication</h2>
                      </a>
                    </div>
                  </div> : '' }
                  {getPrivilegeByName("Workflow Master").canView == true ?    <div className="col-md-4">
                    <div className="tab-main text-center">
                      <h4>
                        <i
                          className="	fa fa-th-list tab-image3"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </h4>
                      <Link to="./workflow">
                        <h2 className="tab-text-main">
                          Workflow
                          
                        </h2>
                      </Link>
                    </div>
                  </div> : ''}

                  {getPrivilegeByName("Resources Master").canView == true ?   <div className="col-md-4">
                    <div className="tab-main text-center">
                      <h4>
                        <i
                          className="	fa fa-file tab-image1"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </h4>
                      <Link to="./resources">
                        {" "}
                        <h2 className="tab-text-main">
                          Resources
                        
                        </h2>
                      </Link>
                    </div>
                  </div> : ''}

                  {getPrivilegeByName("Webzy").canView == true ?       <div className="col-md-4">
                    <div className="tab-main text-center">
                      <h4>
                        <i
                          className="fa fa-tv tab-image"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </h4>
                      <Link to="./webzy/view-assigns">
                        <h2 className="tab-text-main">Webzy</h2>
                      </Link>
                    </div>
                  </div> : '' }

                  {getPrivilegeByName("Issues").canView == true ?    <div className="col-md-4">
                    <div className="tab-main text-center">
                      <h4>
                        <i
                          className="fa fa-tag tab-image"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </h4>
                      <Link to="./issues">
                        {" "}
                        <h2 className="tab-text-main">Issue/Tickets</h2>
                      </Link>
                    </div>
                  </div> : ''}

                  {getPrivilegeByName("Tasks").canView == true ?   <div className="col-md-4">
                    <div className="tab-main text-center">
                      <h4>
                        <i
                          className="fa fa-list tab-image"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </h4>

                      <Link to="./tasks">
                        
                        <h2 className="tab-text-main">Tasks</h2>
                      </Link>
                    </div>
                  </div> : ''}
                   <div className="col-md-4">
                   {getPrivilegeByName("professionals").canView == true ?   <div className="col-md-4">
                      <div className="tab-main text-center">
                        <h4>
                          <i
                            className="fa fa fa-balance-scale tab-image1"
                            style={{ fontSize: "40px" }}
                          ></i>
                        </h4>

                        <Link to="./Professionals">
                          <h2 className="tab-text-main">Professionals</h2>
                        </Link>
                      </div>
                    </div>: ''}
                  </div>
                  {getPrivilegeByName("Logs").canView == true ?   
                  <div className="col-md-4">
                    <div className="tab-main text-center">
                      <h4>
                        <i
                          className="fa fa-clock tab-image1"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </h4>

                      <Link to="./logs">
                        
                        <h2 className="tab-text-main">Logs</h2>
                      </Link>
                    </div> 
                  </div>: ''}

                  {getPrivilegeByName("appointment").canView == true ? (  <div className="col-md-4">
                    <div className="tab-main text-center">
                      <h4>
                        <i
                          className="fa fa-calendar tab-image2"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </h4>

                      <Link to="./appointment-consultant">
                        
                        <h2 className="tab-text-main">Meetings</h2>
                      </Link>
                    </div> 
                  </div>): ''}

                  <div className="col-md-4">
            <div className="tab-main text-center">
                      <h4>
                        <i
                          className="fa fa-calendar tab-image2"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </h4>

                      <Link to="./calendar">
                        
                        <h2 className="tab-text-main">Calendar</h2>
                      </Link>
                    </div> 
                    <div className="tab-main text-center">
                      <h4>
                        <i
                          className="fa fa-calendar tab-image2"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </h4>

                      <Link to="./smartDocs">
                        
                        <h2 className="tab-text-main">Smart Docs</h2>
                      </Link>
                    </div> 
            </div>
            <div className="col-md-4">
            <div className="tab-main text-center">
                      <h4>
                        <i
                          className="fa fa-calendar tab-image2"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </h4>

                      <Link to="./tools">
                        
                        <h2 className="tab-text-main">Tools</h2>
                      </Link>
                    </div> 
            </div>
                  </div>
              </div>
            </div>
           
            <div className="col-md-2"></div>
          </div>
        </div>
      </div>
    );
  }
}
