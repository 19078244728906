import React, { Component } from "react";
import BreadCrumbs from "../../BreadCrumbs";
import axios from "axios";
import SubmitButton from '../../common/SubmitButton';
import _ from 'underscore';
let context = null;

export default class AddStatusMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {

      };
    context = this;

  }


  handleOnChange = (e) => {
    var value = e.target.value;
    var formName = e.target.name;
    this.setState({ [formName]: value });
  }

  sendMail() {
    var data = context.state;
    context.setState({isSubmitting:true})
    axios
      .post("https://q07uwo7htc.execute-api.us-east-1.amazonaws.com/dev/businus/statusMaster/add",
        {
          "title": data.title,
          "type":"STATUS",
          description:data.description,
          "by":localStorage.userName 

        })
      .then(function (response) {
        
        alert("Success!");
        context.setState({isSubmitting:'done'})
      })
      .catch(function (error) {
        console.log(error);
        context.setState({isSubmitting:'done'})
      });
  }


  render() {

    return (
      <div className="Div">
      <div className="row breadcrumb-row">
           <div className="col-md-10">
             <BreadCrumbs />
           </div>
           </div>
        <div className="row">
          <div className="dedicated-page">
          <div className="row-resource-head">
                  <h2 className="resource-head">Add a Status </h2>
                  </div>
            <div className="row ">
              <div className="col-md-12 info-column account-col2">
                <div id="field">
                  
                  <div className="container">
                    <div className="row">
                      <h4  className="account-head" > Basic info</h4>
                      <div className="col-md-8">
                        <div className="form-group">
                          <label className="control-label col-md-4">
                            Title
                          </label>
                          <div className="col-md-8">
                            <input
                              id="ember301"
                              onChange={this.handleOnChange}
                              name="title"
                              value={this.state.title}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">
                           Description
                          </label>
                          <div className="col-md-8">
                            <textarea
                              id="ember301"
                              onChange={this.handleOnChange}
                              name="description"
                              value={this.state.description}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="text-left">
                  <SubmitButton clickHandler={this.sendMail}
                   isSubmitting={this.state.isSubmitting} /> 
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    );
  }
}

