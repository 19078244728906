import React, { Component } from "react";
import "../../style/panel.css";
import {BrowserRouter, Route} from 'react-router-dom';
import { Link } from "react-router-dom";


import $ from "jquery";

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import listLetsgetstarted from './LetsGetStarted/listLetsgetstarted';
import Subscribers from './listSubscribers';
import Postajob from './listPostajob';
import Askanexpert from './listAskanexpert';
import Requestacallback from './listRequestacallback';
import Contactus from './listContactus';
import BreadCrumbs from '../BreadCrumbs';

import Home from '../home';
import Workwithus from './listWorkwithus';
import Header from "../header";
import pageAskanexpert from "./pageAskanexpert";
import pageContactus from "./pageContactus";
import pageLetsgetstarted from "./LetsGetStarted/pageLetsgetstarted";
import pagePostajob from "./pagePostajob";
import pageRequestacallback from "./pageRequestacallback";
import pageSubscribers from "./pageSubscribers";
import pageWorkwithus from "./pageWorkwithus";
import Sidebar from './Sidebar';
import dedicatedMailPage from "../dedicatedmailpage";
import Dashboard from "./Dashboard";
import AddClientsByButton from "./addClientsByButton-homepage";


const data = [];
export default class MainApp extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []
     
    };
    
  }
  componentDidMount(){
    this.handleLine();
    this.setState({title:this.props.match.params.productType});
  }
  handleLine(){
    
   
    const tab1= this.refs.tab1;
    const sidebar1= this.refs.sidebar1;
    const section= this.refs.section;
   
    
    $(function(){
      $("#tab1").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
        $("#section").toggle();
      });
      });
     
  }
  
  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
      <div className="mainDiv" >
       
       <div className="row">
         <div className="col-md-2">
         <Sidebar/>
         </div>
         <div className="col-md-10">
         
      
<div className="section">
<Route exact path = "/legal/homepage/ask-an-expert/:id" component = {pageAskanexpert} />
<Route exact path = "/legal/homepage/contact-us/:id" component = {pageContactus} />
<Route exact path = "/legal/homepage/lets-get-started/:id" component = {pageLetsgetstarted} />
<Route exact path = "/legal/homepage/post-a-job/:id" component = {pagePostajob} />
<Route exact path = "/legal/homepage/request-a-callback/:id" component = {pageRequestacallback} />
<Route exact path = "/legal/homepage/work-with-us/:id" component = {pageWorkwithus} />
<Route exact path = "/legal/homepage/subscribers/:id" component = {pageSubscribers} />
<Route  exact path = "/legal/homepage/lets-get-started" component = {listLetsgetstarted} />
<Route exact path = "/legal/homepage/subscribers" component = {Subscribers} />
<Route exact path = "/legal/homepage/post-a-job" component = {Postajob} />


<Route exact path = "/legal/homepage/ask-an-expert" component = {Askanexpert} />
<Route exact path = "/legal/homepage/contact-us" component = {Contactus} />
<Route exact path = "/legal/homepage/request-a-callback" component = {Requestacallback} />
<Route exact path = "/legal/homepage/work-with-us" component = {Workwithus} />
<Route exact path = "/legal/mail/:id" component = {dedicatedMailPage} />
<Route exact path = "/legal/home" component = {Dashboard} />
<Route exact path="/legal/homepage/:action/add-client/:id"
                component={AddClientsByButton}
              />


</div>

         </div>
       </div>
      
     </div>

    );
  }
}
