import Embed from '@editorjs/embed'
import Table from '@editorjs/table'
import Paragraph from '@editorjs/paragraph'
import List from '@editorjs/list'
// import Warning from '@editorjs/warning'
import Code from '@editorjs/code'
import LinkTool from '@editorjs/link'
import Image, { ImageTool } from '@editorjs/image'
import Raw from '@editorjs/raw'
import Header from '@editorjs/header'
import Marker from '@editorjs/marker'
import CheckList from '@editorjs/checklist'
import axios from 'axios'
// import Delimiter from '@editorjs/delimiter'
// import InlineCode from '@editorjs/inline-code'
// import SimpleImage from '@editorjs/simple-image'

export const EditorjsTool = {
  embed: {
      class: Embed,
      config: {
          services: {
              youtube: true,
              coub: true,
              twitter:true,
              codeopen: {
                  height: 400,
                  width: 600,
              }
          }
      }
  },
  paragraph: {
      class:Paragraph,
      inlineToolbar:true
    },
  list: {
      class:List,
      inlineToolbar:true
    },

  header: {
      class:Header,
      inlineToolbar:true
    },
  marker: {
      class: Marker,
      inlineToolbar:true,  },
}




