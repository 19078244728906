import React, { Component } from "react";
import "../style/panel.css";
import { Link } from "react-router-dom";
import $ from "jquery";

import _ from 'underscore';

/* import MainApp6 from "./Windup+/mainApp6";
import MainApp7 from "./Business-Protection/mainApp7";
import MainApp8 from "./Business-Registration/mainApp8";
import MainApp9 from "./Corporate/mainApp9";
import MainApp10 from "./Human-Resources/mainApp10";
import MainApp11 from "./Intellectual-Property/mainApp11";
import MainApp12 from "./Service-Contract/mainApp12";
import MainApp13 from "./Social/mainApp13";
import MainApp15 from "./Web-and-Internet/mainApp15";
import MainApp14 from "./Startup/mainApp14";
import KnowledgeHub from "./Knowledge-Hub/KnowledgeHub";*/
import {getPrivilegeByName} from './privilegeUtils';

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

const data = [];
export default class Home extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.toggleState= this.toggleState.bind(this);
    this.state = {
      opened: [],
      pageName: '',
      privilegeForAssignment : {},
      privilegeForClient : {},
      privilegeForEmployee :{},
      privilegeForOrganisation :{}


    };

  }
  componentDidMount(){


    this.handleLine();
    var url = window.location.href.split("/")[3];
    if (url==""){
      this.setState({
        pageName:''
      })
    }
   else if (url=="account"){
      this.setState({
        pageName:'account'
      })
    }
    else if(url=!"")
      this.setState({
        pageName:'common'
      })

  }

  componentWillReceiveProps(newProps){
    this.handleLine();
    var url = window.location.href.split("/")[3];
    if (url==""){
      this.setState({
        pageName:''
      })
    }
   else if (url=="account"){
      this.setState({
        pageName:'account'
      })
    }
    else if(url=!"")
      this.setState({
        pageName:'common'
      })

  }


  
  toggleState(com){
this.setState({
  pageName:com
})
}


  handleLine(){

    const c1= this.refs.c1;
    const tab1= this.refs.tab1;
    const sidebar1= this.refs.sidebar1;
    const section= this.refs.section;
    const c2= this.refs.c2;
    const c3= this.refs.c3;
    const c4= this.refs.c4;
    const c5= this.refs.c5;
    const c6= this.refs.c6;
    const c7= this.refs.c7;
    const c8= this.refs.c8;
    const d1= this.refs.d1;
    const d2= this.refs.d2;
    const d3= this.refs.d3;
    const d4= this.refs.d4;
    const d5= this.refs.d5;
    const d6= this.refs.d6;
    const d7= this.refs.d7;
    const d8= this.refs.d8;

    $(function(){
      $("#tab1").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
        $("#section").toggle();
      });
      });

  }

  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
      <div>

      
          <div className="mainScreen">
      <div className="container">
      
     <div className="row">
       <div className="col-md-6">
      <div className="tab-row1">
      <h2 className="tab-row-head">General</h2>
      <div className="row">

      {getPrivilegeByName('Home').canView==true ?   <div className="col-md-4">
     <div className="tab text-center">
     <Link to="/legal/home">
     <img className="tab-image1" id="tab1" 
                  style={{ marginLeft: "-3px" }}
                  src={require("../images/home.png")}
                />
        <h2 className="tab-text-main"> Home Page/General</h2></Link>
     </div>
     </div> : ''}
    
        
         </div>


         </div>

         </div>
         
         <div className="col-md-6">
         <div className="tab-row1">
     <h2 className="tab-row-head">Consultings</h2>
       <div className="row">


       {getPrivilegeByName('Corporate').canView==true ?  <div className="col-md-4">
        
         <div className="tab text-center">
         <Link to="/legal/consultings/corporate">  
     <img className="tab-image2" id="tab1" onClick={()=>this.toggleState('corporate')}
                  style={{ marginLeft: "-3px" }}
                  src={require("../images/N5.png")}
                />
           <h2 className="tab-text-main">Corporate</h2></Link>
     </div>
     </div> : ''}
         
     {getPrivilegeByName('Startup').canView==true ?   <div className="col-md-4">
         <div className="tab text-center">
         <Link to="/legal/consultings/startup">    
     <img className="tab-image1" id="tab1" onClick={()=>this.toggleState('startup')}
                  style={{ marginLeft: "-3px" }}
                  src={require("../images/N6.png")}
                />
           <h2 className="tab-text-main">Startup</h2></Link>
     </div>
         </div> : ''}
         {getPrivilegeByName('Social').canView==true ?    <div className="col-md-4">
         <div className="tab text-center">
         <Link to="/legal/consultings/social">   
     <img className="tab-image3" id="tab1" onClick={()=>this.toggleState('social')}
                  style={{ marginLeft: "-3px" }}
                  src={require("../images/N7.png")}
                />
           <h2 className="tab-text-main">Social</h2></Link>
     </div>
         </div> : ''}

         </div>


       </div>
     </div>

     </div>
    
      <div className="tab-row">
      <h2 className="tab-row-head">Products</h2>
      <div className="row">

      {getPrivilegeByName('Incorporate+').canView==true ?
     <div className="col-md-2">
     <div className="tab text-center">
     <Link to="/legal/products/incorporate+">    
     <img className="tab-image" id="tab1" onClick={()=>this.toggleState('windup')}
                  style={{ marginLeft: "-3px" }}
                  src={require("../images/N1.png")}
                />
           <h2 className="tab-text-main">Incorporate +</h2> </Link>
     </div>
     </div> : ''}
     {getPrivilegeByName('Protect+').canView==true ?  <div className="col-md-2">
     <div className="tab text-center">
     <Link to="/legal/products/protect+">    
     <img className="tab-image1" id="tab1" onClick={()=>this.toggleState('protect')}
                  style={{ marginLeft: "-3px" }}
                  src={require("../images/N2.png")}
                />
           <h2 className="tab-text-main"> Protect+</h2></Link>
     </div>
     </div> : ''}
     {getPrivilegeByName('Comply+').canView==true ? <div className="col-md-2">
     <div className="tab text-center">
     <Link to="/legal/products/comply+">    
     <img className="tab-image2" id="tab1" onClick={()=>this.toggleState('comply')}
                  style={{ marginLeft: "-3px" }}
                  src={require("../images/N3.png")}
                />
        <h2 className="tab-text-main"> Comply+</h2> </Link>
     </div>
     </div> : ''}
   {getPrivilegeByName('Tax+').canView==true ?   <div className="col-md-2">
     <div className="tab text-center">
     <Link to="/legal/products/tax+">    
     <img className="tab-image3" id="tab1" onClick={()=>this.toggleState('tax')}
                  style={{ marginLeft: "-3px" }}
                  src={require("../images/N4.png")}
                />
  <h2 className="tab-text-main">Tax+</h2></Link>
     </div>
     </div> :''}
     {getPrivilegeByName('Windup+').canView==true ?  <div className="col-md-2">
     <div className="tab text-center">
     <Link to="/legal/products/windup+">    
     <img className="tab-image" id="tab1" onClick={()=>this.toggleState('windup')}
                  style={{ marginLeft: "-3px" }}
                  src={require("../images/N15.png")}
                />
           <h2 className="tab-text-main">Windup+</h2></Link>
     </div>
     </div> : ''}

     </div>
     </div>
   

     <div className="tab-row1">
       <h2 className="tab-row-head">Docs</h2>
       <div className="row">

       {getPrivilegeByName('Business Registration').canView==true ?   <div className="col-md-2">
         <div className="tab text-center">
         <Link to="/legal/docs/business-registration">   
     <img className="tab-image2" id="tab1" onClick={()=>this.toggleState('business-registration')}
                  style={{ marginLeft: "-3px" }}
                  src={require("../images/registrationdoc.png")}
                />
           <h2 className="tab-text-main">Business Registration</h2></Link>
     </div>
         </div> : ''}
         {getPrivilegeByName('Human Resources').canView==true ?   <div className="col-md-2">
         <div className="tab text-center">
         <Link to="/legal/docs/human-resource">   
     <img className="tab-image3" id="tab1" onClick={()=>this.toggleState('human-resources')}
                  style={{ marginLeft: "-3px" }}
                  src={require("../images/humanresource.png")}
                />
           <h2 className="tab-text-main">Human Resources</h2></Link>
     </div> 
     </div> : ''}
         
     {getPrivilegeByName('Business Protection').canView==true ?    <div className="col-md-2">
         <div className="tab text-center">
         <Link to="/legal/docs/business-protection">  
     <img className="tab-image1" id="tab1" onClick={()=>this.toggleState('business-protection')}
                  style={{ marginLeft: "-3px" }}
                  src={require("../images/busipro.png")}
                />
           <h2 className="tab-text-main">Business Protection</h2></Link>
     </div>
         </div> : ''}
         {getPrivilegeByName('Intellectual Property').canView==true ?   <div className="col-md-2">
         <div className="tab text-center">
         <Link to="/legal/docs/intellectual-property"> 
     <img className="tab-image" id="tab1" onClick={()=>this.toggleState('intellectual-property')}
                  style={{ marginLeft: "-3px" }}
                  src={require("../images/intellect.png")}
                />
           <h2 className="tab-text-main">Intellectual Property</h2></Link>
     </div>
         </div> : ''}
         {getPrivilegeByName('Service Contract').canView==true ?    <div className="col-md-2">
         <div className="tab text-center">
         <Link to="/legal/docs/service-contract"> 
     <img className="tab-image3" id="tab1" onClick={()=>this.toggleState('service-contract')}
                  style={{ marginLeft: "-3px" }}
                  src={require("../images/serviceco.png")}
                />
           <h2 className="tab-text-main">Service Contract</h2></Link>
     </div>
         </div> : ''}
         {getPrivilegeByName('Web and Internet').canView==true ?    <div className="col-md-2">
         <div className="tab text-center">
         <Link to="/legal/docs/web-and-internet"> 
     <img className="tab-image2" id="tab1" onClick={()=>this.toggleState('web-and-internet')}
                  style={{ marginLeft: "-3px" }}
                  src={require("../images/webandinternet.png")}
                />
           <h2 className="tab-text-main">Web and Internet</h2></Link>
     </div>
         </div> : ''}
         </div>


       </div>
       </div>
     </div>
     </div>
    
    
    );
  }
}
