import React, { Component } from 'react';

import '../style/agency.css';


import '../animations.css';


/**
 * Social-modal
 * It is the code for showing social modal in the template
 * @module Social-Modal
 */


class SocialModal extends Component {
  constructor(props){
    super(); 
    this.state ={
      
       facebook:'',
      linkedin:'',
      twitter:''

    };
    this.saveSocialIcons = this.saveSocialIcons.bind(this);
   
   
   
    
   }
   
   componentDidMount(){
var social = this.props.socialIcon;
    this.setState({facebook:social.facebook});
    this.setState({twitter:social.twitter});
    this.setState({linkedin:social.linkedin});


   }

  onChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);


    console.log(this.state);
  }



   saveSocialIcons(){
     
      this.props.saveSocialIcons(this.state);
      this.props.toggleSocialIconModal();
    
    
   }

   hideModal(key){

    this.props.hideModal(key);
   }

   

   
    render(){
    return(



<div className="modal-wrapper">
<div className="container">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <form>
              <div id="contact-form" className="form-container" data-form-container style={{color: 'rgb(46, 125, 50)', background:'white'}}>
                <div className="row ">
                  <div className="form-title text-center">
                    <h2 className="modal-head text-center"> Edit Social Icons </h2>
                  </div>
                </div>
                <div className="input-container">
                <div className="row">
                    <div className="col-md-12">
                    <form id="field">
                    <div className="row modal-row">
                    <div className="col-md-3">
                     Twitter:</div> <div className="col-md-9"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.icon} name="twitter"/><br/></div></div>
                     <div className="row modal-row">
                    <div className="col-md-3">
                     Facebook</div> <div className="col-md-9"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.name} name="facebook"/><br/></div></div>
                     <div className="row modal-row">
                    <div className="col-md-3">
                     LinkedIn:</div> <div className="col-md-9"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.content} name="LinkedIn"/><br/></div></div>
                    
  
                {/* <button type="button"   className="imagebutton"> Save </button>*/}
                   </form>
                      </div>
                      </div>
                  <div className="row submit-row">
                    <button type="button" onClick={()=>this.saveSocialIcons(this.props.keyindex)} className="btn btn-block submit-form valid">Save</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div></div>
      </div>
    );
  

    }
}

export default SocialModal;