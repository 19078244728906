import React, { Component } from "react";
import axios from "axios";
import { apiBaseUrl1, apiBaseUrl5, apiTaskMaster } from "../../apiUtils";
import { apiBaseUrl2 } from "../../apiUtils";
import { Link } from "react-router-dom";
import $ from "jquery";
import BreadCrumbs from "../../BreadCrumbs";
import "react-table/react-table.css";
import _ from "underscore";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";

//defining the context initially assigning it a null value

let context = null;

//defining the heads of the table, the accessors

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      counters: {},
      count: "",
      sendQueryCounter: {},
    };

    context = this;
  }
  componentDidMount() {
    localStorage.setItem("email", "gautammegha2@gmail.com");
    this.CountQueries(this);
  }

  //function for toggling the status button

  //function for fetching queries from backend

  CountQueries(that, source) {
    axios
      .get(apiTaskMaster + "businusquery/task-list/" + localStorage.spId)
      .then(function (response) {
        console.log(response.data);
        that.setState({ count: response.data.length });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //function for changing the status button on click

  render() {
    const { counters, sendQueryCounter } = this.state;
    return (
      <div>
        <div className="row breadcrumb1-row" style={{ marginLeft: "0px" }}>
          <div className="col-md-12">
            <BreadCrumbs />
          </div>
        </div>
        <div className="DashboardDiv" style={{ marginLeft: "20px" }}>
          <div className="row ">
            <h2 className="dashboard-head">Dashboard</h2>
          </div>

          <div className="row">
            <div className="col-md-6">
              <Link to="/tasks/view-tasks">
                <div className="card-counter primary">
                  <i className="fa fa-ticket" />
                  <span className="count-numbers">{this.state.count}</span>
                  <span className="count-name">Tasks </span>
                </div>
              </Link>
            </div>
            <div className="col-md-6">
              <Link to="/tasks/add-tasks">
                <div className="card-counter primary">
                  <i className="fa fa-plus" />

                  <span className="count-name">Add Task</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
