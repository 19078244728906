import React, { Component } from 'react';
import reactCSS from 'reactcss'
import '../style/agency.css';

import { SketchPicker } from 'react-color';
import '../animations.css';
import axios from 'axios';

import {apiBaseUrl,baseFilePath} from './apiUtils';


import FileBase64 from 'react-file-base64';
import '../animations.css';
let context = null;

/**
 * Navbar Modal
 * It is the modal which takes in the data and style of navbar .
 * @module NavbarModal
 */
class NavbarModal extends Component {
  constructor(props){
    super(); 
    this.state ={
      backgroundColor:'',
      height:'',
      padding:'',
      logo:'',
      margin:'',
      color:'' ,
      secNavBgColor:'',
      secNavHeight:'100px',
      secNavTextColor:'',
      secNavTextHighlightColor:'',
      backgroundImage:'',
      displayColorPicker:false,
      displayBgColorPicker:false,
      displayHighlightColorPicker:false,
      displaySecNavTextColorPicker:false,
      displaySecNavTextHighlightColorPicker:false,
      displaySecNavBgColorPicker:false,
     
      files:[],
      fileName:"",
      highlightColor:"",

    };
   
    context = this;
    this.uploadApi = this.uploadApi.bind(this);
    this.saveStyle = this.saveStyle.bind(this);
   
    this.hideModal = this.hideModal.bind(this);
   
    
   }
   
   componentDidMount(){
    var style = this.props.styleProp;
    this.setState({backgroundColor:style.backgroundColor});  //incoming data being set in the state
    this.setState({padding:style.padding});
    this.setState({margin:style.margin});
    this.setState({color:style.color});

    this.setState({logo:style.logo});
    this.setState({highlightColor:style.highlightColor});
    this.setState({height:style.height});
    
    this.setState({secNavHeight:style.secNavHeight})
    this.setState({secNavBgColor:style.secNavBgColor})
    this.setState({secNavTextColor:style.secNavTextColor})
    this.setState({secNavTextHighlightColor:style.secNavTextHighlightColor})



   }

  onChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);


    console.log(this.state);
  }


    /**
 * uploadApi()
 * This is the api for uploading logo image to the server.
 * @function uploadApi()
 */
  uploadApi(){
    var data = {image:this.state.files.base64,fileName:this.state.files.name}
    axios.post(apiBaseUrl+"/uploadImage",data)
    .then(function (response) {
  
      context.setState({logo:response.data.Location});
     
    })
    .catch(function (error) {
      console.log(error);
    });
  

   }

  /**
 * uploadApi()
 * This is the api for temporarily storing image for uploading.
 * @function uploadApi()
 */

   getFiles(files){
    this.setState({ files: files })
    this.uploadApi();
  }
  
  /**
 * saveStyle()
 * This is the api for saving the style and elements of navbar.
 * @function saveStyle()
 */


  saveStyle(index){
    var context=this;
     var backgroundImage="url('"+this.state.backgroundImage+"')";
     this.setState({backgroundImage:backgroundImage});
     setTimeout(function(){

      var savingContent = context.state; //all state values are being stored in SavingContent
      delete savingContent.files ; //delete temp variable.
      delete savingContent.fileName ;
      context.props.saveStyle(savingContent);
      context.props.toggleStyleModal();
     }, 50);
    
   }


   /**
 * saveStyle()
 * This is the api for saving the style and elements of navbar.
 * @function saveStyle()
 */

   toggleColorPicker = (colorPicker) => {
     if(colorPicker=="bg"){
      this.setState({ displayBgColorPicker: !this.state.displayBgColorPicker })
     }
     else if(colorPicker=="highlight"){
      this.setState({ displayHighlightColorPicker: !this.state.displayHighlightColorPicker })
     }
     else if(colorPicker=="secNavBgColor"){
      this.setState({ displaySecNavBgColorPicker: !this.state.displaySecNavBgColorPicker })
     }
     else if(colorPicker=="secNavTextColor"){
      this.setState({ displaySecNavTextColorPicker: !this.state.displaySecNavTextColorPicker })
     }
     else if(colorPicker=="secNavTextHighlightColor"){
      this.setState({ displaySecNavTextHighlightColorPicker: !this.state.displaySecNavTextHighlightColorPicker })
     }
     else{
      this.setState({ displayColorPicker: !this.state.displayColorPicker })

     }
    
  };

  /**
 * hideModal()
 * This is segment hides the navbar modal.
 * @function hideModal()
 */

   hideModal(key){

    this.props.hideModal(key);
   }

   handleChangeComplete = (color) => {
    this.setState({ backgroundColor: color.hex });
  
  };

  handleSecNavBgChangeComplete = (color) => {
   
    this.setState({ secNavBgColor: color.hex });
  };
  handleSecNavTextColorChangeComplete = (color) => {
   
    this.setState({ secNavTextColor: color.hex });
  };
  handleSecNavTextHighlightColorChangeComplete = (color) => {
   
    this.setState({ secNavTextHighlightColor: color.hex });
  };
  handleChangeColorComplete = (color) => {
    this.setState({ color: color.hex });
   
  };
  handleChangeCardColorComplete = (color) => {
    this.setState({ color: color.hex });
   
  };

  handleChangeHighlightColorComplete = (color) => {
    this.setState({ highlightColor: color.hex });
  
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
    this.setState({ displayBgColorPicker: false });
    this.setState({ displaySecNavBgColorPicker: false });
    this.setState({ displaySecNavTextColorPicker: false });
    this.setState({ displaySecNavTextHighlightColorPicker: false });
    this.setState({ displayHighlightColorPicker: false });
  };


   
    render(){
      const styles = reactCSS({
        'default': {
          color: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `${this.state.backgroundColor}`,
          },
          color2: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `${this.state.color}`,
          },  color3: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `${this.state.highlightColor}`,
          },
          color4: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `${this.state.secNavBgColor}`,
          },
          color5: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `${this.state.secNavTextColor}`,
          },
          color6: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `${this.state.secNavTextHighlightColor}`,
          },
          swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
          },
          popover: {
            position: 'absolute',
            zIndex: '2',
          },
          cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          },
        },
      });
  


    return(
this.state.hideModal ? <div></div>  : 


<div className="modal-wrapper">
 
<div className="container">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10">
          

            <form>
              <div id="contact-form" className="form-container" data-form-container style={{color: 'rgb(46, 125, 50)', background:'white'}}>
                <div className="row modal-back-color">
                <div style={{'marginLeft': '38px'}} onClick={this.props.toggleStyleModal}><h5 className="close-button" style={{marginLeft:'440px'}}>X</h5></div>
                  <div className="form-title text-center">
                    <h5 className="modal-head" > Edit Style for Navbars  </h5>
                  </div>
                  
                </div>
                <div className="input-container">
                <div className="row">
                    <div className="col-md-12">
                    <form id="field">
                      <div className="col-md-6">
                      <h4>First Navbar</h4>
                    <div className="row">
                    <div className="col-md-3">
                    <h5 className="field-names">Background Color:</h5><p className="subscriptt">(First Navbar)</p></div> <div className="col-md-9 color "> <div style={ styles.swatch } onClick={ ()=>this.toggleColorPicker('bg') }>
          <div style={ styles.color } />
        </div>
        { this.state.displayBgColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ this.handleClose }/>
          <SketchPicker  color={this.state.backgroundColor} onChangeComplete={ this.handleChangeComplete }/><br/>
           </div> : null }
           </div>
           </div>
           <div className="row">
           <div className="col-md-3">
                    <br/> <h5 className="field-names">Text Color: </h5><p className="subscriptt">(First Navbar)</p> </div> <div className="col-md-9 color modalrow"><div style={ styles.swatch} onClick={ ()=>this.toggleColorPicker('color') }>
          <div style={ styles.color2 } />
        </div>
        { this.state.displayColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ this.handleClose }/>
          <SketchPicker  color={this.state.color} onChangeComplete={ this.handleChangeColorComplete }/><br/>
        </div> : null }
        </div></div>
        <div className="row">
           <div className="col-md-3">
        <br/> <h5 className="field-names">Height </h5> <p className="subscriptt">(First Navbar)</p></div> <div className="col-md-9 color modalrow"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.height} name="height"/><br/>
        </div>
        </div>
        <div className="row">
                    <div className="col-md-3">
                    <h5 className="field-names">Text Highlight Color: </h5><p className="subscriptt">(First Navbar)</p></div> <div className="col-md-9 color"> <div style={ styles.swatch } onClick={ ()=>this.toggleColorPicker('highlight') }>
          <div style={ styles.color3 } />
        </div>
        { this.state.displayHighlightColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ this.handleClose }/>
          <SketchPicker  color={this.state.highlightColor} onChangeComplete={ this.handleChangeHighlightColorComplete }/><br/>
           </div> : null }
           </div>
           </div>
        <div className="row">
           <div className="col-md-3">
        <br/> <h5 className="field-names">Logo: </h5> <p className="subscriptt">(Image)</p></div> <div className="col-md-9 modalrow"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.logo} name="logo"/><br/>
        <FileBase64
        multiple={ false }
        onDone={ this.getFiles.bind(this) } />  </div>
        </div>
                    </div>
                    <div className="col-md-6">
                     <h4>Second Navbar</h4>


                    <div className="row">
                    <div className="col-md-3">
                    <h5 className="field-names">Background Color:</h5><p className="subscriptt">(Second Nav)</p></div> <div className="col-md-9 color "> <div style={ styles.swatch } onClick={ ()=>this.toggleColorPicker('secNavBgColor') }>
          <div style={ styles.color4 } />
        </div>
        { this.state.displaySecNavBgColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ this.handleClose }/>
          <SketchPicker  color={this.state.secNavBgColor} onChangeComplete={ this.handleSecNavBgChangeComplete }/><br/>
           </div> : null }
           </div>
           </div>
           <div className="row">
           <div className="col-md-3">
                    <br/> <h5 className="field-names">Text Color: </h5><p className="subscriptt">(Second Nav)</p> </div> <div className="col-md-9 color modalrow"><div style={ styles.swatch} onClick={ ()=>this.toggleColorPicker('secNavTextColor') }>
          <div style={ styles.color5 } />
        </div>
        { this.state.displaySecNavTextColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ this.handleClose }/>
          <SketchPicker  color={this.state.secNavTextColor} onChangeComplete={ this.handleSecNavTextColorChangeComplete  }/><br/>
        </div> : null }
        </div></div>
        <div className="row">
           <div className="col-md-3">
        <br/> <h5 className="field-names">Height: </h5> <p className="subscriptt">(Second Nav)</p></div> <div className="col-md-9 color modalrow"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.secNavHeight} name="secNavHeight"/><br/>
        </div>
        </div>
        <div className="row">
                    <div className="col-md-3">
                    <h5 className="field-names">Text Highlight Color: </h5><p className="subscriptt">(Second Nav)</p></div> <div className="col-md-9 color"> <div style={ styles.swatch } onClick={ ()=>this.toggleColorPicker('secNavTextHighlightColor') }>
          <div style={ styles.color6 } />
        </div>
        { this.state.displaySecNavTextHighlightColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ this.handleClose }/>
          <SketchPicker  color={this.state.secNavTextHighlightColor} onChangeComplete={ this.handleSecNavTextHighlightColorChangeComplete }/><br/>
           </div> : null }
           </div>
           </div>
        










                    </div>
      

                  
            
               
                {/* <button type="button"   className="imagebutton"> Save </button>*/}
                   </form>
                      </div>
                      </div>
                
                  <div className="row submit-row">
                    <div className="col-md-2"></div><div className="col-md-4">
                    <button type="button" onClick={()=>this.saveStyle(this.props.keyindex)} className="btn btn-block savebutton submit-form valid">Save</button>
                    </div>
                    <div className="col-md-4">
                    <button type="button" onClick={()=>this.props.hideModal(this.props.keyindex)} className="btn btn-block cancelbutton submit-form valid">Cancel</button>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  
                  
                </div>
              </div>
            </form>
          </div>
        </div></div>
      </div>
    );
  

    }
}

export default NavbarModal;