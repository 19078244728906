import React from "react";

function Alert({ msg, type }) {
  return (
    <div className="container w-100">
      <div className={"alert mx-5 mt-4 " + type} role="alert">
        <h4> {msg}</h4>
      </div>
    </div>
  );
}

export default Alert;
