import React, { Component } from "react";
import "../../style/panel.css";
import { Link } from "react-router-dom";

export default class GuideBlogSidebar extends Component{

    render(){
        return(
            <div className="sidebar">
              <div id="sidebar1">
                <div className="sidebar-elementhead"><Link className="sidebar-link" to="/knowledge-hub-home">Home</Link></div>
                <div className="oneTab">
                  <div className="sidebar-element">
                    <i class="fa fa-envelope"/>
                    Guides Chapter
                    <i className="fa fa-angle-down " />
                  </div>
                  <div className="sidebar-subelement-2">
                  <Link className="sidebar-link" to={"/knowledgeHub/businus-school/"+this.props.learningType+"/add-guides-chapter"}>
                    <i class="fa fa-envelope" />
                    Add Chapter
                    <i className="fa fa-angle-right" />
                  </Link><br/>
                  <Link className="sidebar-link" to={"/knowledgeHub/businus-school/"+this.props.learningType+"/view-guides-chapter"}>
                    <i class="fa fa-envelope" />
                    View Chapter
                    <i className="fa fa-angle-right" />
                  </Link><br/>
                </div>
                </div>
                <div className="oneTab">
                  <div className="sidebar-element">
                    <i class="fa fa-envelope"/>
                    Guides Blog
                    <i className="fa fa-angle-down " />
                  </div>
                  <div className="sidebar-subelement-2">
                  <Link className="sidebar-link" to={"/knowledgeHub/businus-school/"+this.props.learningType+"/add-guides-blog"}>
                    <i class="fa fa-envelope" />
                    Add Blog
                    <i className="fa fa-angle-right" />
                  </Link><br/>
                  <Link className="sidebar-link" to={"/knowledgeHub/businus-school/"+this.props.learningType+"/view-guides-blog"}>
                    <i class="fa fa-envelope" />
                    View Blog
                    <i className="fa fa-angle-right" />
                  </Link><br/>
                </div>
                </div>
            </div>
            </div>
        )
    }
}