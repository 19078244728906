import React, { Component } from "react";
import "../../style/panel.css";
import { Link } from "react-router-dom";
import KnowledgeHubSidebar from './KnowledgeHubSidebar';

export default class KnowledgeHubDashboard extends Component{
    render(){
        return(
            <div className="row">
                <div className="col-md-2">
            <KnowledgeHubSidebar />
          </div>
          <div className="col-md-10">
                <div className="mainScreenKhub">
                <div className="container">
                    <div className="row">
                    <div className="col-md-6">
      <div className="tab-row1">
      <h2 className="tab-row-head">Article</h2>
      <div className="row">

      <div className="col-md-4">
     <div className="tab text-center">
     <Link to="/knowledgeHub/businus-school/legal/article">
     <img className="tab-image1" id="tab1" 
                  style={{ marginLeft: "-3px" }}
                  src={require("../../images/article-icon.png")}
                />
        <h2 className="tab-text-main"> Article </h2></Link>
     </div>
     </div> 
    
        
         </div>


         </div>

         </div>

         <div className="col-md-6 col-lg-6">
                <div className="tab-row1">
                    <div className="tab-row-head"> Banner </div>
                </div>
                <div className="row">
                <div className='col-md-4 col-lg-4'>
                    <div className="tab text-center">
                    <Link to="/knowledgeHub/businus-school/legal/addBanner">    
                <img className="tab-image1" id="tab1"
                            style={{ marginLeft: "-3px" }}
                            src={require("../../images/add-banner-icon.png")}
                            />
                    <h2 className="tab-text-main">Add Banner</h2></Link>
                </div>
                                </div>
                </div>
            </div>

            <div className="col-md-6 col-lg-6">
                <div className="tab-row1">
                    <div className="tab-row-head"> Bookmarks </div>
                </div>
                <div className="row">
                <div className='col-md-4 col-lg-4'>
                    <div className="tab text-center">
                    <Link to="/knowledgeHub/businus-school/legal-finance/view-bookmarks">    
                <img className="tab-image1" id="tab1"
                            style={{ marginLeft: "-3px" }}
                            src={require("../../images/N6.png")}
                            />
                    <h2 className="tab-text-main">View Bookmarks</h2></Link>
                </div>
                                </div>
                </div>
            </div>

         {/* <div className="col-md-6">
         <div className="tab-row1">
     <h2 className="tab-row-head">Legal</h2>
       <div className="row">


        <div className="col-md-4">
        
         <div className="tab text-center">
         <Link to="/knowledgeHub/businus-school/legal/companylaw">  
     <img className="tab-image2" id="tab1"
                  style={{ marginLeft: "-3px" }}
                  src={require("../../images/N5.png")}
                />
           <h2 className="tab-text-main">Company Law</h2></Link>
     </div>
     </div> 
         
      <div className="col-md-4">
         <div className="tab text-center">
         <Link to="/knowledgeHub/businus-school/legal/gst">    
     <img className="tab-image1" id="tab1"
                  style={{ marginLeft: "-3px" }}
                  src={require("../../images/N6.png")}
                />
           <h2 className="tab-text-main">GST</h2></Link>
     </div>
         </div> 
         <div className="col-md-4">
         <div className="tab text-center">
         <Link to="/knowledgeHub/businus-school/legal/custom">   
     <img className="tab-image3" id="tab1"
                  style={{ marginLeft: "-3px" }}
                  src={require("../../images/N7.png")}
                />
           <h2 className="tab-text-main">Custom</h2></Link>
     </div>
         </div> 

         </div>


       </div>
     </div> */}
{/* 
     <div className='row'>
            <div className='col-md-6 col-lg-6'>
                <div className='tab-row1'>
                    <div className='tab-row-head'>Guides</div>
                </div>

                <div className='row'>
                    <div className='col-md-4 col-lg-4'>
                    <div className="tab text-center">
         <Link to="/knowledgeHub/businus-school/legal/add-guides">    
     <img className="tab-image1" id="tab1"
                  style={{ marginLeft: "-3px" }}
                  src={require("../../images/N6.png")}
                />
           <h2 className="tab-text-main">Add Guides Name</h2></Link>
     </div>
                    </div>

                    <div className="col-md-4">
         <div className="tab text-center">
         <Link to="/knowledgeHub/businus-school/legal/view-guides">   
     <img className="tab-image3" id="tab1"
                  style={{ marginLeft: "-3px" }}
                  src={require("../../images/N7.png")}
                />
           <h2 className="tab-text-main">View Guides Name</h2></Link>
     </div>
         </div> 

                </div>
            </div>

            <div className="col-md-6 col-lg-6">
                <div className="tab-row1">
                    <div className="tab-row-head"> Banner </div>
                </div>
                <div className="row">
                <div className='col-md-4 col-lg-4'>
                    <div className="tab text-center">
                    <Link to="/knowledgeHub/businus-school/legal/addBanner">    
                <img className="tab-image1" id="tab1"
                            style={{ marginLeft: "-3px" }}
                            src={require("../../images/N6.png")}
                            />
                    <h2 className="tab-text-main">Add Banner</h2></Link>
                </div>
                                </div>
                </div>
            </div>
     </div> */}
            </div>
                </div>
                </div>
            </div></div>
        )
    }
}