import React from 'react';
import axios from 'axios';
import ReactTable from 'react-table';
import BreadCrumbs from '../../components/BreadCrumbs';
import { Link } from 'react-router-dom';
import Khub_HomepageSidebar from '../Homepage/Khub_HomepageSidebar';
import { apiBaseUrl21 } from '../../components/apiUtils';

export default class ListStayUpdated extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            resData:[]
        }
        
    }

    componentDidMount(){
        this.fetchAllData()
    }

    fetchAllData(){
        return axios({       
            url: apiBaseUrl21+'stayUpdated/list',
            method: 'GET',
            crossDomain: true,
            responseType: 'json', 
          }).then((response) => {   
            this.setState({resData:response.data})
         });  
    }



    render(){
        const columns = [
          {
            Header: <div className="form-textHead cell-head">Stay Updated Id</div>,
            accessor: 'stayUpdatedId',
            filterMethod: (filter, row) => {   return row.catagory.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:120
          },
          {
            Header:<div className="form-textHead cell-head"> Name</div>,
            accessor: d=>{return {id:d.id,name:d.name}}, 
            id:'name',
            Cell: props => <span className='form-text cell'><Link to={'/knowledgeHub/businus-school/homepage/view-stay-updated/'+props.value.id}>{props.value.name}</Link></span>,
            minWidth:220
          },          {
            Header: <div className="form-textHead cell-head">Email</div>,
            accessor: 'email',
            filterMethod: (filter, row) => {   return row.catagory.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:120
          },
          {
            Header: props => <span className="form-textHead cell-head"> Date</span>,
            accessor: 'date',
            filterMethod: (filter, row) =>
                     {   return row.source.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => 
            new Date(props.value).toDateString(),
            minWidth:180
          },{
            Header: props => <span className="form-textHead cell-head"> Time</span>,
            accessor: 'date',
            filterMethod: (filter, row) =>
                     {   return row.source.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => 
            new Date(props.value).toLocaleTimeString(),
            minWidth:180
          }]
        return(
          <div>
            <div className="row">
              <div className="col-md-2 col-lg-2"><Khub_HomepageSidebar/></div>
              <div className="col-md-10 col-lg-10">
            <div className = "container-fluid overflow-auto" style={{margin: "10px"}}>
            <div className="row breadcrumb-row">
          <div className="col-md-10" style={{marginTop:'10px'}}><BreadCrumbs/></div></div>
        <div className="container-fluid " style={{}}>
          <ReactTable data={this.state.resData} columns={columns} style={{height:'85vh',overflow:'scroll'}} filterable/>
        </div>
        
        </div>
        </div>
      </div>
        </div>
        )
    }
}