import React, { Component } from "react";
import axios from 'axios';

import { apiBaseUrl1,apiBaseUrl5 } from '../apiUtils';
import { apiBaseUrl2 } from '../apiUtils';
import { Link } from "react-router-dom";
import ReactTable from 'react-table'
import $ from "jquery";
import 'react-table/react-table.css'
import Modal from 'react-modal';
import { ChatFeed, Message } from 'react-chat-ui'
import _ from 'underscore';
import FileBase64 from 'react-file-base64';

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";



let context = null;
const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      padding               :'0px ',
      position               :'Absolute',
      zIndex                 :'30000'     ,
      backgroundColor         :'none',  
    }
  };

export default class UserCommunication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      subject:"",
      message:"",
      attachment:"NA",
      thread:[],
      templates:[],
      sendTo:"",
      documents:[],
      proposals:[],
      mails:[],
      entry: {isRegistered:false} ,
      modalIsOpen:false,
      docType:"MAIL",
      sliderState:1,
      attachmentPlaceholders:[],
 
    };

    context = this;
  }
   
fetchTemplates(that,source){

  axios.get(apiBaseUrl1+"businus/template/list/Update")
  .then(function (response) {
    console.log(response.data);
    that.setState({templates:response.data});
  })
  .catch(function (error) {
    console.log(error);
  });

}

  componentDidMount(){
         this.fetchThreadById(this,this.props.id);
         this.fetchTemplates(this);
}




  changeSliderState(orderNumber){

    this.setState({sliderState:orderNumber});

    }

    onChange = (e) => {

        const state = this.state
        state[e.target.name] = e.target.value;
        this.setState(state);
    
    
        console.log(this.state);
      }
      
      handleOnTemplateChange = (e) => {

        const state = this.state
        state[e.target.name] = e.target.value;
        state['selectedUpdate'] = e.target.value;
    
        this.setState(state);
    
    
        console.log(this.state);
      }
    
  sendMail(type) {
    var query = context.props.entry;
   axios
     .post(
       apiBaseUrl2+"businusquery/send",
       {
         
     "name": query.name,
     "email": query.email,
     "message": type=='update' ? context.state.selectedUpdate : context.state.message,
     "company":query.company, 
     "subject": context.state.subject!="" ? context.state.subject : 'NA',
     "priority": context.state.priority!="" ? context.state.priority : "NA",
     "attachment":context.state.attachment,
     "attachmentName":context.state.attachmentName,
     "queryId":context.props.id,
     "from":"admin",
     "recordType":"MAIL",
     "docType":context.state.docType,
     "senderEmail": context.state.senderEmail!="" ? context.state.senderEmail : "NA",
       })
     .then(function(response) {
       debugger;
     alert("SENT");
     context.fetchThreadById(context,query.id);
     })
     .catch(function(error) {
       console.log(error);
     });
 }

  fetchThreadById(that, id) {
      debugger;
    axios
      .get(
        apiBaseUrl2+"businusquery/listmail/" +
          id
      )
      .then(function(response) {
        debugger;
        console.log(response.data);
        var threads = response.data=="" ? [] : _.sortBy(response.data,'date').reverse();
        that.setState({ thread: threads});

        var documents = _.where(threads,{docType:"Document"});
        var proposals = _.where(threads,{docType:"Proposal"});
        var mails = _.where(threads,{docType:"Mail"});
        that.setState({documents});
        that.setState({proposals});
        that.setState({mails});
        
      
      })
      .catch(function(error) {
        console.log(error);
      });
  }

   
  openModal(type) {
    this.changeDocType(type);
    this.setState({modalIsOpen: true});
  }
 
  changeDocType(type){

    this.setState({docType:type});
  }

 
  closeModal=()=> {
    this.setState({modalIsOpen: false});
  }


  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });



          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  

  getFiles(files) {
    this.setState({ selectedFile: files[0] });

  }
  
  getFormattedDate(millis){

    return new Date(millis).toDateString();


  }

  render() {
      const {sliderState} = this.state;
      const {isRegistered} = this.props.entry;
    return (
      <div className="user-Div">
      
      {isRegistered ? 
            <div className="row multioptions-row" > 
               <div className={sliderState==1 ? "col-md-3 activeOption " : "col-md-3 options"} style={{backgroundColor:'rgb(27 94 136)'}}>
               <div className=" " onClick={()=> this.changeSliderState(1)} ><i className="fa fa-list" style={{fontSize:'14px'}} aria-hidden="true"></i> Timeline</div>
               </div>
              {/* <div className={sliderState==2 ? "col-md-2 active " : "col-md-2"} style={{backgroundColor:'#978cab'}}>
               <div className="options" onClick={()=> this.changeSliderState(2)}> Chat</div>
                      </div>*/}
                 <div className={sliderState==3 ? "col-md-2 activeOption " : "col-md-2 options"} style={{backgroundColor:'rgb(14 106 151)'}}>
               <div className="" onClick={()=> this.changeSliderState(3)}><i className="fa fa-envelope" style={{fontSize:'14px'}} aria-hidden="true"></i>Mail</div>
                      </div>
               <div className={sliderState==4 ? "col-md-3 activeOption " : "col-md-3 options"} style={{backgroundColor:'rgb(57 140 194)'}}>
               <div className="" onClick={()=> this.changeSliderState(4)}> <i className="fa fa-file" style={{fontSize:'14px'}} aria-hidden="true"></i>Proposals</div>
               </div>
               <div className={sliderState==5 ? "col-md-2 activeOption " : "col-md-2 options"} style={{backgroundColor:'rgb(77 155 206)'}}>
               <div className="" onClick={()=> this.changeSliderState(5)}> <i className="fa fa-folder" style={{fontSize:'14px'}} aria-hidden="true"></i>Documents</div>
               </div>
             
              
               <div className={sliderState==6 ? "col-md-2 activeOption " : "col-md-2 options"} style={{backgroundColor:'rgb(87, 170, 222)'}}>
               <div className="" onClick={()=> this.changeSliderState(6)}><i className="fa fa-bell" style={{fontSize:'14px'}} aria-hidden="true"></i>  Updates</div>
               </div>
               </div>
               : '' }


              
               {sliderState==1 ?
              <div>   <div className="row">
              <div className="col-md-12 offset-md-3">
              {isRegistered ? <div className=" header" style={{backgroundColor:'rgb(27 94 136)'}} >Your Timeline</div> : 
              <div className="alert-nouser" >Sorry! User not registered</div> }
                <ul className="timeline">
                
          {this.state.thread.map((thred, index) => (
            
           <li>
            <div className={(thred.docType=='Proposal' ? 'RED' : thred.docType =='Document'?'GREEN':'BLUE') + '-mail timeline-card'}>
            <div className="row ">
                <div className="col-md-8">{thred.status}</div> 
                <div className="col-md-4 text-right"> <button className="button-type"> <p className="montserrat timeline-subhead ">{thred.docType}</p></button></div></div>
          <h4 className="montserrat timeline-head" > <Link onClick={this.setMailPageUrl} to={"/mail/"+thred.id}  className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link></h4>
          <h4 className="montserrat timeline-subhead" > <a href="#" className=" timeline-elements float-right">{this.getFormattedDate(thred.date)}</a></h4> 
           <p className="montserrat timeline-subhead">{thred.id}</p>
           <p className="montserrat timeline-subhead">{thred.docType}</p>
           </div>
         </li>
        ))}
                  
                 
                </ul>
              </div>
            </div></div>:''}


            {sliderState==2 ?
              <div> 
               
              
 <div className="row">
              <div className="col-md-12 offset-md-3">
                <div className=" header" >Chat with our Assistants</div>
            {/*       <ul className="proposals">
           
          {this.state.thread.map((thred, index) => (
            
           <li>
             <div className="update-card">
               {thred.status}
          <h4 className="montserrat timeline-head" > <Link to={"/mail/"+thred.id}  className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link></h4>
          <h4 className="montserrat timeline-subhead" > <a href="#" className=" timeline-elements float-right">{this.getFormattedDate(thred.date)}</a></h4> 
           <p className="montserrat timeline-subhead">{thred.id}</p>
           </div>
         </li>
        ))}
                  
                 
          </ul>*/}
<div style={{backgroundColor:'#fffdf2'}}>
                <ChatFeed
      messages={this.state.messages} // Boolean: list of message objects
      isTyping={this.state.is_typing} // Boolean: is the recipient typing
      hasInputField={false} // Boolean: use our input, or use your own
      showSenderName // show the name of the user who sent the message
      bubblesCentered={false} //Boolean should the bubbles be centered in the feed?
      // JSON: Custom bubble styles
      bubbleStyles={
        { 
          text: {
            fontSize: 19
          },
          chatbubble: {
            borderRadius: 20,
            padding: 10,
            backgroundColor: '#0084FF',
           
          },
          recipientChatbubble: {
            backgroundColor: '#f29624',
          }
        }
      }
    />
    
    </div>
    <div className="row message-box text-left">
                    {/* <input  className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.subject} name="subject" placeholder="Subject" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif'}}/><br/> */}
                  <textarea className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.messageText} name="messageText" placeholder="Message" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif'}}></textarea><br/>
                  <div className="text-center"> <button  className="btn btn-primary send-big-btn"  onClick={this.sendMessage} placeholder="Attachment">Send</button></div>
                   
                    
 
 </div> 
              </div>
            </div>
</div>:''}

{sliderState==3 ?
  <div>  <div className=" header" style={{backgroundColor:'rgb(14 106 151)'}} >Previous Mails</div>
  <div className="row" style={{backgroundColor:'#eaeaea',padding:'5px 30px 5px 30px',textAlign:'right',fontSize:'15px',fontFamily:'montserrat',fontWeight:'500'}}><div className="butn" onClick={()=>this.openModal('Mail')}><i className="fa fa-send" style={{fontSize:'14px'}} aria-hidden="true"></i>Send Mail</div></div>

<Modal
isOpen={this.state.modalIsOpen}

onRequestClose={this.closeModal}
style={customStyles}
contentLabel="Example Modal"
>
<div className="cross-row text-right"><div style={{marginRight:'-5px'}} onClick={this.closeModal}><i style={{fontSize:'25px',fontWeight:'400'}} className="fa fa-close" aria-hidden="true"></i></div></div>
<div className="header2">Send Mail</div>
<div className="row" > 
<div className="options-row ">
<div className="col-md-6 upload-box" > <FileBase64
multiple={true}
onDone={this.getFiles.bind(this)}   /></div>

<div className="col-md-5">

<button className="btn btn-primary attachment" onClick={() => this.fileUploadHandler(this, "attachment")}
value="Select a File">
<i className="fa fa-paperclip" aria-hidden="true"></i>Attachment
</button></div>
</div>
</div>





<div className="row content1-div  text-left">

<input  className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.subject} name="subject" placeholder="Subject of your Document" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif'}}/><br/>
<textarea className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.message} name="message" placeholder="Body of your mail" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif',width:'98%'}}></textarea><br/>
<div className="text-center"> <button  className="btn btn-primary send-button"  onClick={this.sendMail} placeholder="Attachment">Send</button></div>



</div> 

</Modal>
<div className="row">
  <div className="col-md-12 offset-md-3">
   
    <ul className="proposals">
    
{this.state.mails.map((thred, index) => (

<li>
 <div className="update-card">
   {thred.status}
<h4 className="montserrat timeline-head" > <Link to={"/mail/"+thred.id}  className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link></h4>
<h4 className="montserrat timeline-subhead" > <a href="#" className=" timeline-elements float-right">{this.getFormattedDate(thred.date)}</a></h4> 
<p className="montserrat timeline-subhead">{thred.id}</p>
</div>
</li>
))}
      
     
    </ul>
  </div>
</div>
</div>:''}


                 {sliderState==4 ?
                   <div> 
                   <div className="header" style={{backgroundColor:'rgb(57 140 194)'}}>
                     Previous Proposals
                   </div>

                   <div className="row" style={{backgroundColor:'#eaeaea',padding:'5px 30px 5px 30px',textAlign:'right',fontSize:'15px',fontFamily:'montserrat',fontWeight:'500'}}><div className="butn" onClick={()=>this.openModal('Proposal')}><i className="fa fa-send" style={{fontSize:'14px'}} aria-hidden="true"></i>Send Proposal</div></div>

<Modal
isOpen={this.state.modalIsOpen}

onRequestClose={this.closeModal}
style={customStyles}
contentLabel="Example Modal"
>
<div className="cross-row text-right"><div style={{marginRight:'-5px'}} onClick={this.closeModal}><i style={{fontSize:'25px',fontWeight:'400'}} className="fa fa-close" aria-hidden="true"></i></div></div>
<div className="header2">
  Send Proposal
</div>

<div className="row" > 
<div className="options-row ">
{this.state.attachmentPlaceholders.map((attach,i)=>{

return <div style={{marginTop:'5px'}} className="row">
<div className="col-md-8 upload-box" > <FileBase64
  multiple={true}
  onDone={this.getFiles.bind(this)} /></div>


{i===0 ? 
<div className="col-md-3">

  <button className="btn btn-primary attachment" onClick={() => this.addMoreAttachment()}
    value="Select a File">
    <i className="fa fa-plus" aria-hidden="true"></i>Add More
</button>   



</div> : ''}
</div>

})}
           
           </div>
         </div>




<div className="row content1-div  text-left">

 <input  className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.subject} name="subject" placeholder="Subject of your Proposal" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif'}}/><br/>
<textarea className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.message} name="message" placeholder="Any comments" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif'}}></textarea><br/>
<div className="text-center"> <button  className="btn btn-primary send-button"  onClick={this.sendMail} placeholder="Attachment">Send</button></div>

 

</div> 

</Modal>
                  
                   
                   <div className="doc-section">
                   <div className="row doc-head-row">
                 <div className="col-md-6">
                 <div className="table-head text-left">
                 Proposal Name
                 </div>
                 </div>
                 <div className="col-md-3">
                 <div className="table-head text-center">
                 Uploaded On
                 </div>
                 </div>
                 <div className="col-md-3">
                 <div className="table-head text-center">
                 By
                 </div>
                   </div>
                   </div>
   
                   <ul className="proposals">
                
                {this.state.proposals.map((thred, index) => (
                  thred.attachment != "NA" ? 
                 <li>
                   <div className={thred.type  +' row doc-head-row'}>
                 <div className="col-md-6">
                 <div className="table-item text-center">
                 <Link to={"/mail/"+thred.id}  onClick={this.fetchMailById} className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link>
                 </div>
                 </div>
                 <div className="col-md-3">
                 <div className="table-item text-center">
                {this.getFormattedDate(thred.date)}
                 </div>
                 </div>
                 <div className="col-md-3">
                 <div className="table-item text-center">
                Businus.com
                 </div>
                   </div>
                   </div>
                   </li>:''
        ))}
               </ul>
               
                  
                   </div></div>:''}
                 {sliderState==5 ?
              <div> 
                <div className="header" style={{backgroundColor:'rgb(77 155 206)'}}>
                  Previous Documents
                </div>

                <div className="row" style={{backgroundColor:'#eaeaea',padding:'5px 30px 5px 30px',textAlign:'right',fontSize:'15px',fontFamily:'montserrat',fontWeight:'500'}}><div className="butn" onClick={()=>this.openModal('Document')}><i className="fa fa-send" style={{fontSize:'14px'}} aria-hidden="true"></i>Send Document</div></div>

<Modal
isOpen={this.state.modalIsOpen}

onRequestClose={this.closeModal}
style={customStyles}
contentLabel="Example Modal"
>
<div className="cross-row text-right"><div style={{marginRight:'-5px'}} onClick={this.closeModal}><i style={{fontSize:'25px',fontWeight:'400'}} className="fa fa-close" aria-hidden="true"></i></div></div>
<div className="header2">
  Send Document
</div>

<div className="row" > 
<div className="options-row ">
{this.state.attachmentPlaceholders.map((attach,i)=>{

return <div style={{marginTop:'5px'}} className="row">
<div className="col-md-8 upload-box" > <FileBase64
  multiple={true}
  onDone={this.getFiles.bind(this)} /></div>


{i===0 ? 
<div className="col-md-3">

  <button className="btn btn-primary attachment" onClick={() => this.addMoreAttachment()}
    value="Select a File">
    <i className="fa fa-plus" aria-hidden="true"></i>Add More
</button>   



</div> : ''}
</div>

})}
           
           </div>
         </div>





<div className="row content1-div  text-left">

 <input  className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.subject} name="subject" placeholder="Subject of your Document" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif'}}/><br/>
<textarea className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.message} name="message" placeholder="Any comments" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif'}}></textarea><br/>
<div className="text-center"> <button  className="btn btn-primary send-button"  onClick={this.sendMail} placeholder="Attachment">Send</button></div>

 

</div> 

</Modal>
              

               

             
           
                <div className="doc-section">
                <div className="row doc-head-row">
              <div className="col-md-6">
              <div className="table-head">
              Document Name
              </div>
              </div>
              <div className="col-md-3">
              <div className="table-head">
              Uploaded On
              </div>
              </div>
              <div className="col-md-3">
              <div className="table-head">
              By
              </div>
                </div>
                </div>

                <ul className="proposals">
                
                {this.state.documents.map((thred, index) => (
                  thred.attachment != "NA" ? 
                 <li>
                   <div className="row doc-head-row">
                 <div className="col-md-6">
                 <div className="table-item text-center">
                 <Link to={"/mail/"+thred.id}  onClick={this.fetchMailById} className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link>
                 </div>
                 </div>
                 <div className="col-md-3">
                 <div className="table-item text-center">
                {this.getFormattedDate(thred.date)}
                 </div>
                 </div>
                 <div className="col-md-3">
                 <div className="table-item text-center">
                {thred.from}
                 </div>
                   </div>
                   </div>
                   </li>:''
        ))}
               </ul>
               

               

                </div></div>:''}

                


           
            {sliderState==6 ?
              <div>   <div className="row">
              <div className="col-md-12 offset-md-3">
              <div className="header" style={{backgroundColor:'rgb(87, 170, 222)'}}>
                  Previous Updates
                </div>
              <div className="row" style={{backgroundColor:'#eaeaea',padding:'5px 30px 5px 30px',textAlign:'right',fontSize:'15px',fontFamily:'montserrat',fontWeight:'500'}}><div className="butn" onClick={()=>this.openModal('Update')}><i className="fa fa-send" style={{fontSize:'14px'}} aria-hidden="true"></i>Send Updates</div></div>
    
<Modal
isOpen={this.state.modalIsOpen}

onRequestClose={this.closeModal}
style={customStyles}
contentLabel="Example Modal"
>
<div className="cross-row text-right"><div style={{ marginRight: '-5px' }} onClick={this.closeModal}><i style={{ fontSize: '25px', fontWeight: '400' }} className="fa fa-close" aria-hidden="true"></i></div></div>
<div className="header2">
Send Proposal
</div>

<div className="row" >
<div className="options-row ">
{this.state.attachmentPlaceholders.map((attach,i)=>{

return <div style={{marginTop:'5px'}} className="row">
<div className="col-md-8 upload-box" > <FileBase64
  multiple={true}
  onDone={this.getFiles.bind(this)} /></div>


{i===0 ? 
<div className="col-md-3">

  <button className="btn btn-primary attachment" onClick={() => this.addMoreAttachment()}
    value="Select a File">
    <i className="fa fa-plus" aria-hidden="true"></i>Add More
</button>   



</div> : ''}
</div>

})}
           
           </div>
         </div>






<div className="row content1-div  text-left">
<select
               name="selectedTemplate"
               onChange={this.handleOnTemplateChange}
               value={this.state.selectedTemplate}
               className="form-control ember-text-field ember-view"
               type="text"
             >

           {this.state.templates.map(function(template,i){

             return(<option value={template.update}>{template.name}</option>)
           })}

             </select>

<textarea className="fieldbox2" onChange={this.onChange} type="text" value={this.state.selectedUpdate} name="selectedUpdate" placeholder="updates" style={{ fontSize: '17px', fontFamily: 'montserrat,sans-serif' }}></textarea><br />
<div className="text-center"> <button className="btn btn-primary send-button" onClick={()=>this.sendMail('update')} placeholder="Attachment">Send</button></div>



</div>

</Modal>
                <ul className="masterMail-page">
    
    {this.state.thread.map((thred, index) => (
    
    <li>
     <div className="mail-card">
       {thred.status}
    <div className="row"><div className="col-md-8"><h4 className="montserrat timeline-head"> <Link onClick={this.setMailPageUrl} to={"/master-mails/"+thred.id}  className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link></h4>
    
    </div>
    <div className="col-md-4">
      <h4 className="montserrat timeline-subhead" > 
      <a href="#" className=" timeline-elements float-right">{this.getFormattedDate(thred.date)}</a>
      </h4> 
      </div>
      </div>
    
    
    </div>
    </li>
    ))}
          
         
        </ul>
              </div>
            </div></div>:''}
          </div>
       
    );
  }
}
