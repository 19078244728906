import $ from "jquery";
import React, { Component } from "react";
import { Route } from 'react-router-dom';
import "../../style/panel.css";

import AccountInfo from "./accountinfo";

import passwordSettings from "./passwordSettings";
import SidebarAccount from "./SidebarAccount";

















const data = [];
export default class AccountMainapp extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []

    };

  }
  componentDidMount() {
    this.handleLine();
    //this.setState({title:this.props.match.params.productType});
  }
  handleLine() {


    const tab1 = this.refs.tab1;
    const sidebar1 = this.refs.sidebar1;
    const section = this.refs.section;


    $(function () {
      $("#tab1").click(function () {
        $("#section").toggle();
      });
    });

  }

  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
      <div className="mainDiv" >

        <div className="row">
          <div className="col-md-2">
            <SidebarAccount/>
          </div>
          <div className="col-md-10">


            <div className="section">
            {/* <Route exact path="/issues/home" component={Home} /> */}
            <Route exact path="/account-info" component={AccountInfo} />

            <Route exact path="/account-info/details" component={AccountInfo} />
              <Route exact path="/account-info/password-settings" component={passwordSettings} />
              
            </div>

          </div>
        </div>

      </div>

    );
  }
}
