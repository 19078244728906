import React, { Component } from "react";
import axios from "axios";

import { Link } from "react-router-dom";
import ReactTable from "react-table";
import $ from "jquery";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6
import "react-table/react-table.css";
import FileBase64 from "react-file-base64";
import Modal from "react-modal";
import { ChatFeed, Message } from "react-chat-ui";
import _ from "underscore";
import { Multiselect } from "multiselect-react-dropdown";
import {
  apiBaseUrl1,
  apiBaseUrl5,
  apiIssueMaster,
  apiLogsMaster,
  apiTaskMaster,
} from "../apiUtils";
import { apiBaseUrl2 } from "../apiUtils";
import ResourcesUserCommunication from "./ResourcesUserCommunication";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import AddModuleComponent from "./AddModuleComponent";
import TabListData from "./TabListData";
import SendMail from "../Masters/communicationMaster/sendMail";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px ",
    position: "Absolute",
    zIndex: "30000",
    backgroundColor: "none",
  },
};

let context = null;

export default class AssignmentUserCommunication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: "",
      message: "",
      priority: "",
      organisationThred: [],
      attachment: "NA",
      workflows: [],
      thread: [],
      sendTo: "",
      documents: [],
      proposals: [],
      timelineForTeams: [],
      teamMails: [],
      teamDocs: [],
      mails: [],
      entry: {
        checklists: [],
        team: [],
      },
      assignmentDocuments: [],
      attachmentPlaceholders: [{ attachment: "" }],
      assignmentComments: [],
      templatesForMail: [],
      templates: [],
      issues: [],
      expenses: [],
      progressLogs: [],
      modalIsOpen: false,
      docType: "Messages",
      sliderState: 1, //sta//state for storing the response from the backend,
      superSliderState: "clients",
      superTabs: [
        { title: "Clients", type: "communication", isActive: true },
        { title: "Teams", type: "team-communication" },
        { title: "Resources", type: "resources" },
        {
          title: "Workflow",
          type: "module",
          moduleType: "workflows",
          moduleName: "workflow",
          idProp: "workflowId",
          titleProp: "workflowName",
        },
        {
          title: "Tasks",
          type: "module",
          moduleType: "tasks",
          moduleName: "task",
          idProp: "taskId",
          titleProp: "title",
        },
        {
          title: "Logs",
          type: "module",
          moduleType: "logs",
          moduleName: "log",
          idProp: "logId",
          titleProp: "title",
        },
        {
          title: "Issues",
          type: "module",
          moduleType: "issues",
          moduleName: "issue",
          idProp: "issueId",
          titleProp: "title",
        },
      ],
      subTabs: [
        {
          title: "Timeline",
          type: "communication",
          isActive: true,
          tabSize: 3,
          tabColor: "#115e95",
          link: "/communication-master/view/page/",
          listName: "thread",
          listType: "timeline",
        },
        {
          title: "Timeline",
          type: "team-communication",
          tabSize: 4,
          tabColor: "#36ba87",
          link: "/team-communication/page/",
          listName: "timelineForTeams",
          listType: "timeline",
        },
        {
          title: "Team-Messages",
          type: "team-communication",
          tabSize: 4,
          tabColor: "#38ca9e",
          link: "/team-communication/page/TEAM-MAILS/",
          listName: "teamMails",
          listType: "message",
          docType: "TEAM-MAILS",
        },
        {
          title: "Team-Documents",
          type: "team-communication",
          tabSize: 4,
          tabColor: "#84dbb9",
          link: "/team-communication/page/TEAM-DOCS/",
          listName: "teamDocs",
          listType: "TEAM-DOCS",
          docType: "empDocument",
        },
        {
          title: "Messages",
          type: "communication",
          tabSize: 2,
          tabColor: "#2c6ca3",
          listType: "message",
          link: "/communication-master/view/page/Messages/",
          listName: "mails",
          templateList: "templatesForMail",
          docType: "Mail",
        },
        {
          title: "Proposals",
          type: "communication",
          tabSize: 3,
          tabColor: "#3f7baf",
          listType: "message",
          link: "/communication-master/view/page/Proposals/",
          listName: "proposals",
          templateList: "templatesForProposal",
          docType: "Proposal",
        },
        {
          title: "Documents",
          type: "communication",
          tabSize: 2,
          tabColor: "#63a5df",
          listType: "message",
          link: "/communication-master/view/page/Documents/",
          listName: "documents",
          templateList: "templatesForDocument",
          docType: "Document",
        },
        {
          title: "Updates",
          type: "communication",
          tabSize: 2,
          tabColor: "#82c0f7",
          listType: "message",
          link: "/communication-master/view/page/Updates/",
          listName: "updates",
          templateList: "templatesForUpdate",
          docType: "Update",
        },
      ],
    };

    context = this;
  }

  fetchTemplatesForMails(that, source) {
    axios
      .get(apiBaseUrl1 + "businus/template/list/client/" + localStorage.spId)
      .then(function (response) {
        console.log(response.data);
        var templates = response.data;

        that.setState({ allTemplates: response.data });
        var templateMails = _.where(templates, { templateType: "Messages" });
        var templateDocuments = _.where(templates, {
          templateType: "Document",
        });
        var templateProposals = _.where(templates, {
          templateType: "Proposal",
        });
        var templateUpdates = _.where(templates, { templateType: "Update" });

        templateMails.unshift({ name: "Custom ", update: "", id: "-1" });
        templateDocuments.unshift({ name: "Custom ", update: "", id: "-1" });
        templateProposals.unshift({ name: "Custom ", update: "", id: "-1" });
        templateUpdates.unshift({ name: "Custom ", update: "", id: "-1" });

        that.setState({ templatesForMail: templateMails });
        that.setState({ templatesForDocument: templateDocuments });
        that.setState({ templatesForProposal: templateProposals });
        that.setState({ templatesForUpdate: templateUpdates });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fetchTemplates(that, source) {
    axios
      .get(apiBaseUrl1 + "businus/template/list/Update/" + localStorage.spId)
      .then(function (response) {
        console.log(response.data);
        var templates = response.data;
        templates.unshift({ name: "Custom update", update: "" });

        that.setState({ templates });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  componentDidMount() {
    this.markAsRead(this, this.props.id);

    this.setState({ entry: this.props.entry });
    this.setState({ id: this.props.id });
    this.fetchTemplates(this);
    this.fetchWorkflows(this);
    this.fetchIssues(this);
    this.fetchTasks(this);
    this.fetchLogs(this);
    this.fetchTemplatesForMails(this);
    this.fetchThreadById(this, this.props.id);

    // this.fetchTeamMails(this,"TEAM-MAIL");
  }

  //function for changing the status button on click to open or close
  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);

    console.log(this.state);
  };

  handleOnTemplateChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;

    var selectedTemplate = _.findWhere(this.state.templates, {
      id: e.target.value,
    });
    state["selectedUpdate"] = selectedTemplate.update;
    //state['subjectUpdate'] = selectedTemplate.update;

    this.setState(state);

    console.log(this.state);
  };

  handleOnMailTemplateChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;

    var selectedTemplate = _.findWhere(this.state.allTemplates, {
      id: e.target.value,
    });
    state["subject"] = selectedTemplate.name;
    state["message"] = selectedTemplate.update;
    if (selectedTemplate.document != "")
      state["attachments"] = [
        {
          attachmentName: "template attachment",
          attachment: selectedTemplate.document,
        },
      ];
    //state['subjectUpdate'] = selectedTemplate.update;

    this.setState(state);

    console.log(this.state);
  };

  onCheckboxClick = (e, i) => {
    var query = context.state.entry;

    query.checklists[i].isDone = !query.checklists[i].isDone;

    axios
      .post(apiBaseUrl1 + "businus/project/update/" + context.state.id, {
        status: query.status != undefined ? query.status : "open",
        paymentStatus:
          query.paymentStatus != undefined
            ? query.paymentStatus
            : "Invoice Pending",
        checklists: query.checklists,
      })
      .then(function (response) {
        context.setState({ entry: query });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  openModal(type) {
    this.changeDocType(type);
    this.setState({
      modalIsOpen: true,
      attachmentPlaceholders: [{ attachment: "" }],
    });
  }

  saveResourceToList = (resources) => {
    if (context.props.entry.selectedOrganisation.id == 0) {
      axios
        .put(apiIssueMaster + "businusquery/issue/" + context.state.id, {
          resources: resources,
        })
        .then(function (response) {
          debugger;
          alert("success");
          // context.fetchThreadById(context,context.state.id);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      axios
        .post(apiBaseUrl1 + "businus/project/update/" + context.state.id, {
          resources: resources,
        })
        .then(function (response) {
          debugger;
          alert("success");
          // context.fetchThreadById(context,context.state.id);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  updateStatus = (type, value) => {
    var query = context.state.entry;

    if (type == "checklists") {
      if (query.checklists != undefined) {
        query.checklists.push({
          name: value,
          isDone: false,
        });
      } else {
        query["checklists"] = [];
        query.checklists.push({
          name: value,
          isDone: false,
        });
      }
    }

    axios
      .post(apiBaseUrl1 + "businus/project/update/" + context.state.id, {
        status: query.status != undefined ? query.status : "open",
        paymentStatus:
          query.paymentStatus != undefined
            ? query.paymentStatus
            : "Invoice Pending",
        checklists: query.checklists,
      })
      .then(function (response) {
        debugger;
        alert("success");
        context.fetchThreadById(context, query.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleQuillChange = (value) => {
    this.setState({ message: value });
    // document.getElementById('save').innerHTML = 'Add Act';
  };
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  markAsRead(that, id) {
    axios
      .get(apiBaseUrl2 + "businusquery/read/" + id)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  }

  fileUploadHandler = (that, docType) => {
    that.setState({ loading: true });

    let promises = [];

    _.each(that.state.selectedFile, function (selectedFile) {
      promises.push(
        axios.post(apiBaseUrl2 + "businusquery/uploadAttachment", {
          uploadImage: selectedFile.base64,
          imageName: selectedFile.name,
        })
      );
    });

    Promise.all(promises)
      .then((result) => {
        let attachments = that.state.attachments || [];

        _.each(result, function (response, key) {
          if (response.status == 200) {
            that.setState({ successupload: true });

            attachments.push({
              attachmentName: that.state.selectedFile[key].name,
              attachment:
                "https://businus-files-live.s3.amazonaws.com/mailAttachments/" +
                that.state.selectedFile[key].name,
            });
          }
        });

        that.setState({ attachments: attachments });
      })
      .catch((error) => console.log(error));
  };

  fetchWorkflows(that, source) {
    axios
      .get(apiBaseUrl1 + "businus/workflow/list/" + localStorage.spId)
      .then(function (response) {
        console.log(response.data);
        var workflows = response.data;

        workflows.unshift({ name: "custom", description: "" });
        workflows.unshift({ name: "Select a workflow", description: "" });
        that.setState({ workflows: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fetchIssues(that, source) {
    axios
      .get(apiIssueMaster + "businusquery/issues/" + localStorage.spId)
      .then(function (response) {
        console.log(response.data);
        let data = response.data;

        data.unshift({ title: "Select a Issue", description: "" });
        that.setState({ issues: data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fetchLogs(that, source) {
    axios
      .get(apiLogsMaster + "businusquery/Log-list/all/" + localStorage.spId)
      .then(function (response) {
        console.log(response.data);
        let data = response.data;

        data.unshift({ title: "Select a Log", description: "" });
        that.setState({ logs: data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fetchTasks(that, source) {
    axios
      .get(apiTaskMaster + "businusquery/task-list/" + localStorage.spId)
      .then(function (response) {
        console.log(response.data);
        let data = response.data;

        data.unshift({ title: "Select a Task", description: "" });
        that.setState({ tasks: data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  changeSliderState = (tab) => {
    let newSubTabList = this.state.subTabs;
    newSubTabList.map((item, index) => {
      if (tab && item.title == tab.title && item.type == tab.type) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }

      return item;
    });


    

    this.setState({ subTabs: newSubTabList });
  };

  getCurrentActiveSuperTab = () => {
    return (
      this.state.superTabs.find((item) => item.isActive) ||
      this.state.superTabs[0]
    );
  };

  getCurrentActiveSubTab = () => {
    return this.state.subTabs.find((item) => item.isActive);
  };

  changeSuperSliderState = (tab) => {
    let newTabList = this.state.superTabs;
    let activeTab = null;
    newTabList = newTabList.map((item, index) => {
      if (item.title == tab.title) {
        item.isActive = true;
        activeTab= item;
      } else {
        item.isActive = false;
      }

      return item;
    });


    
    const firstSubTab = this.state.subTabs.find(item=>{
      return item.type==activeTab.type;
    });

    
      this.changeSliderState(firstSubTab);
    
  
    this.setState({ superTabs: newTabList });

    /*  this.setState({ superSliderState: name });
    if (name === "clients") {
      this.setState({ sliderState: 1 });
    } else if (name == "resources") {
      this.setState({ sliderState: 21 });
    } else if (name == "workflow") {
      this.setState({ sliderState: 15 });
    } else if (name == "organisation") {
      this.setState({ sliderState: 13 });
    } else if (name == "task") {
      this.setState({ sliderState: 15 });
    } else if (name == "logs") {
      this.setState({ sliderState: 15 });
    } else if (name == "issues") {
      this.setState({ sliderState: 15 });
    } */
  };

  getFiles(files) {
    this.setState({ selectedFile: files });
    this.fileUploadHandler(this);
  }

  getFormattedDate(millis) {
    return new Date(millis).toDateString();
  }
  getFormattedTime(millis) {
    return new Date(millis).toLocaleTimeString();
  }

  changeDocType(type) {
    this.setState({ docType: type });
  }

  onSelect(optionsList, selectedItem) {
    context.setState({ team: optionsList });

    console.log(optionsList, selectedItem);
  }

  onRemove(optionList, removedItem) {
    context.setState({ team: optionList });
  }

  addNewWorkflow = () => {
    let selectedWorkflow = this.state.workflows[this.state.selectedWorkflow];

    this.props.addNewWorkflow(selectedWorkflow);
  };

  handleOnSave = (type, selected) => {
    this.props.addNewModule(type, selected);
  };

  addMoreAttachment = () => {
    let attachmentPlaceholders = this.state.attachmentPlaceholders;
    attachmentPlaceholders.push({ attachment: "" });
    this.setState({ attachmentPlaceholders });
  };

  sendMail(type) {
    var query = context.props.entry;

    if (context.state.docType.toUpperCase() == "TEAM-MAILS" || context.state.docType.toUpperCase() == "TEAM-DOCS") {
      this.sendMailForEmployee(context.state.docType.toUpperCase());
      return;
    }

    axios
      .post(apiBaseUrl2 + "businusquery/send", {
        name: query.name,
        email: query.email,
        message: context.state.message, //type=='update' ? context.state.selectedUpdate:
        company: query.company,
        toe: query.toe,
        subject:
          type == "Updates"
            ? context.state.subjectUpdate
            : context.state.subject != ""
            ? context.state.subject
            : "NA",
        priority: context.state.priority != "" ? context.state.priority : "NA",
        attachment: context.state.attachments,
        queryId: context.props.id,
        from: "admin",
        recordType: "MAIL",
        parentMailId: query.id,
        parentName: query.assignmentId,
        docType: context.state.docType.toUpperCase(),
        by: localStorage.userName,
        spId: localStorage.spId,
        screenType: context.props.type,
        amount: context.state.amount,
        isThreadMail: context.props.isDedicatedPage ? "Y" : "N",
        team: context.state.team,
        orgId: context.props.isDedicatedPage
          ? context.props.assignment.selectedOrganisation.id
          : context.props.entry.selectedOrganisation.id,
        visibleToClient: context.props.isDedicatedPage
          ? context.props.assignment.visibleToClient
          : context.props.entry.visibleToClient,
      })

      .then(function (response) {
        debugger;
        alert("SENT");

        context.fetchThreadById(context, context.props.id);

        context.setState({ subject: "" });
        context.setState({ message: "" });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fetchThreadById(that, id) {
    let url = apiBaseUrl2 + "businusquery/listmail/" + id;
    axios
      .get(url)
      .then(function (response) {
        debugger;
        console.log(response.data);
        //get all mails,documents,proposals sorted by date desc
        var threads =
          response.data == "" ? [] : _.sortBy(response.data, "date").reverse();

        if (context.props.isDedicatedPage) {
          threads = _.where(threads, { parentMailId: context.state.entry.id });
        } else {
          threads = _.filter(threads, function (d) {
            return d.isThreadMail != "Y";
          });
        }

        var documents = _.where(threads, { docType: "DOCUMENT" });
        var proposals = _.where(threads, { docType: "PROPOSAL" });
        var mails = _.filter(threads, (item) => {
          return item.docType.toLowerCase() == "mail";
        });
        var updates = _.where(threads, { docType: "UPDATE" });

        var assignmentDocuments = _.where(threads, {
          docType: "assignmentDocument",
        });
        var progressLogs = _.where(threads, { docType: "progressLog" });
        var expenses = _.where(threads, { docType: "expenseLog" });
        var issues = _.where(threads, { docType: "issueLog" });
        var assignmentComments = _.where(threads, {
          docType: "assignmentComment",
        });
        var teamMails = _.where(threads, { docType: "TEAM-MAILS" });
        var teamDocs = _.where(threads, { docType: "TEAM-DOCS" });

        var timeline = _.filter(threads, function (t) {
          return t.docType != "TEAM-MAILS" && t.docType != "TEAM-DOCS";
        });
        that.setState({ thread: timeline });

        var timelineForTeams = _.filter(threads, function (t) {
          return t.docType == "TEAM-MAILS" || t.docType == "TEAM-DOCS";
        });
        that.setState({ timelineForTeams });

        that.setState({ documents });
        that.setState({ proposals });
        that.setState({ mails });
        that.setState({ updates });
        that.setState({ teamMails, teamDocs });

        that.setState({
          assignmentComments,
          progressLogs,
          expenses,
          issues,
          assignmentDocuments,
        });
        that.setState({
          organisationThread: _.union(
            assignmentComments,
            progressLogs,
            expenses,
            issues,
            assignmentDocuments
          ),
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fetchTeamMails(that, mailType) {
    let paramEmail = localStorage.userEmail;
    if (localStorage.isSp == "true") {
      paramEmail = "all";
    }
    axios
      .get(
        apiBaseUrl2 +
          "businusquery/listMailByType/all/" +
          mailType +
          "/" +
          localStorage.spId +
          "/" +
          paramEmail
      )
      .then(function (response) {
        console.log(response.data);
        that.setState({ teamMails: _.sortBy(response.data, "date") });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  sendMailForEmployee(that, mailType) {
    var query = context.props.entry;

    axios
      .post(apiBaseUrl2 + "businusquery/sendByMaster", {
        assignmentId: query.assignmentId,

        name: query.name,
        email: query.email,
        message: context.state.message, //type=='update' ? context.state.selectedUpdate:
        company: query.company,
        subject: context.state.subject,
        priority: context.state.priority !== "" ? context.state.priority : "NA",
        attachment: context.state.attachments,
        queryId: context.props.id,
        parentMailId: query.id,
        by: localStorage.userName,
        spId: localStorage.spId,
        from: "admin",
        recordType: "EMP-MAIL",
        docType: context.state.docType.toUpperCase(),
        sendTo: query.team,
      })
      .then(function (response) {
        debugger;
        alert("SENT");

        context.fetchThreadById(context, context.props.id);

        context.setState({ subject: "" });
        context.setState({ message: "" });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  setMailPageUrl = () => {
    debugger;
    if (!this.props.isDedicatedPage) {
      var url = window.location.href.replace(window.location.origin, "");
      localStorage.setItem("MAILURL", url);
    }
  };

  getMessageUI = (tab) => {
    return (
      <div>
        <div style={{backgroundColor:tab.tabColor}} className=" header">
          {tab.title}
        </div>
        <div
          className="row"
          style={{
            backgroundColor: "#eaeaea",
            padding: "5px 30px 5px 30px",
            textAlign: "right",
            fontSize: "17px",
            fontFamily: "montserrat",
            fontWeight: "500",
          }}
        >
          <div className="butn" onClick={() => this.openModal(tab.docType)}>
            <i
              className="fa fa-send"
              style={{ fontSize: "14px" }}
              aria-hidden="true"
            ></i>
            Send {tab.title}
          </div>
        </div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="cross-row text-right">
            <div style={{ marginRight: "-5px" }} onClick={this.closeModal}>
              <i
                style={{ fontSize: "25px", fontWeight: "400" }}
                className="fa fa-close"
                aria-hidden="true"
              ></i>
            </div>
          </div>
          <div className="header2">Send {tab.title}</div>
          <div className="row">
            <div className="options-row ">
              {this.state[tab.templateList] ? (
                <div className="row ">
                  <select
                    name="selectedTemplate"
                    onChange={this.handleOnMailTemplateChange}
                    value={this.state.selectedTemplate}
                    className="form-control ember-text-field ember-view"
                    type="text"
                  >
                    {this.state[tab.templateList].map(function (template, i) {
                      return (
                        <option value={template.id}>{template.name}</option>
                      );
                    })}
                  </select>
                </div>
              ) : (
                ""
              )}

              {this.state.attachmentPlaceholders.map((attach, i) => {
                return (
                  <div style={{ marginTop: "5px" }} className="row">
                    <div className="col-md-8 upload-box">
                      {" "}
                      <FileBase64
                        multiple={true}
                        onDone={this.getFiles.bind(this)}
                      />
                    </div>

                    {i === 0 ? (
                      <div className="col-md-3">
                        <button
                          className="btn btn-primary attachment"
                          onClick={() => this.addMoreAttachment()}
                          value="Select a File"
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i>
                          Add More
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          <div className="row content1-div  text-left">
            <input
              className="fieldbox1"
              onChange={this.onChange}
              type="text"
              value={this.state.subject}
              name="subject"
              placeholder="Subject of your Message"
              style={{
                fontSize: "17px",
                fontFamily: "montserrat,sans-serif",
              }}
            />
            <br />
            <ReactQuill
              value={this.state.message}
              onChange={this.handleQuillChange}
              style={{ height: "240px" }}
            />
            <div className="text-center">
              {" "}
              <div
                className="btn btn-primary send-button"
                onClick={() => this.sendMail(tab.title)}
                placeholder="Attachment"
              >
                Send
              </div>
            </div>
          </div>
        </Modal>
        <TabListData
          titleProp="subject"
          idProp="mailId"
          tabList={this.state[tab.listName]}
          url={tab.link}
        />
      </div>
    );
  };

  /* UIs */
  getTimelineUI = (subTab) => {
    return (
      <div>
        <div className="row">
          <div className="col-md-12 offset-md-3">
            <div style={{backgroundColor:subTab.tabColor}} className=" header">Your Timeline</div>

            <ul
              className="timeline-user-comm"
              style={{ backgroundColor: "white" }}
            >
              {this.state[subTab.listName].map((thred, index) => (
                <li>
                  <div
                    className={
                      (thred.docType == "Proposal"
                        ? "RED"
                        : thred.docType == "Document"
                        ? "GREEN"
                        : thred.docType == "Mail"
                        ? "YELLOW"
                        : "BLUE") + "-mail timeline-user-comm-card"
                    }
                  >
                    <div className="row ">
                      <div className="col-md-8">
                        {" "}
                        <h4 className="montserrat timeline-user-comm-head">
                          {" "}
                          <Link
                            to={
                              subTab.link +
                              (thred.docType == "PROPOSAL"
                                ? "Proposals/"
                                : thred.docType == "DOCUMENT"
                                ? "Documents/"
                                : thred.docType == "MAIL"
                                ? "Messages/"
                                : thred.docType == "updates"
                                ? "Updates/"
                                : thred.docType + "/") +
                              thred.id
                            }
                            className="timeline-user-comm-elements"
                          >
                            {thred.subject}
                          </Link>
                        </h4>
                      </div>
                      <div className="col-md-4 text-right">
                        {" "}
                        <button className="button-type">
                          {" "}
                          <p className="montserrat timeline-user-comm-subhead ">
                            {thred.docType == "MAIL"
                              ? "MESSAGE"
                              : thred.docType == "TEAM-MAILS"
                              ? "TEAM-MESSAGES"
                              : thred.docType}
                          </p>
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        {subTab.type == "team-communication" ||
                        thred.from == "client"
                          ? thred.status
                          : "SENT"}
                      </div>
                      <div className="col-md-4">
                        {" "}
                        <h4 className="montserrat timeline-user-comm-subhead">
                          {" "}
                          <a
                            href="#"
                            className=" timeline-user-comm-elements float-right"
                          >
                            {this.getFormattedDate(thred.date)}
                          </a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { sliderState, superSliderState, entry } = this.state;
    const resources = this.props.entry.resources || {
      attachment: [],
      doc: [],
      comment: [],
      url: [],
    };
    return (
      <div>
        <div
          className="row "
          style={{ backgroundColor: "white", borderBottom: "2px solid grey" }}
        >
          {/* SUPER TABS */}
          {this.state.superTabs.map((item) => {
            return (
              <div
                className={
                  item.isActive
                    ? "col-md-2 active1 superoptions"
                    : "col-md-1 superoptions"
                }
              >
                <div
                  className=" "
                  onClick={() => this.changeSuperSliderState(item)}
                >
                  {item.title}
                </div>
              </div>
            );
          })}

       
        </div>

        {this.getCurrentActiveSuperTab()?.type === "communication" ||
        this.getCurrentActiveSuperTab()?.type === "team-communication" ? (
          <div className="row " style={{ borderTop: "2px solid #1E5B81" }}>
           
            {this.state.subTabs.map((item) => {
              return item.type == this.getCurrentActiveSuperTab()?.type ? (
                <div
                  className={
                    "col-md-" +
                    item.tabSize +
                    (item.isActive ? " activeOptionsAss " : "")
                  }
                  style={{ backgroundColor: item.tabColor }}
                >
                  <div
                    className="options "
                    onClick={() => this.changeSliderState(item)}
                  >
                    {item.title}
                  </div>
                </div>
              ) : (
                ""
              );
            })}
          </div>
        ) : this.getCurrentActiveSuperTab()?.type === "resources" ? (
          <ResourcesUserCommunication
            attachment={resources.attachment}
            doc={resources.doc}
            comment={resources.comment}
            checklist={resources.checklist || []}
            url={resources.url}
            saveResourceToList={this.saveResourceToList}
            parentType="Assignment"
            parentId={this.props.entry.id}
            assignment={this.props.entry}
            parentName={this.props.entry.assignmentId}
          />
        ) : this.getCurrentActiveSuperTab()?.type === "module" ? (
          (<AddModuleComponent
            type={this.getCurrentActiveSuperTab().moduleType}
            itemName={this.getCurrentActiveSuperTab().moduleName}
            sliderState={sliderState}
            visibleState={20}
            handleOnSave={this.handleOnSave}
            optionList={
              this.state[this.getCurrentActiveSuperTab().moduleType] || []
            }
            list={
              this.props.entry[this.getCurrentActiveSuperTab().moduleType] || []
            }
            changeSliderState={this.changeSliderState}
            titleProp={this.getCurrentActiveSuperTab().titleProp}
            idProp={this.getCurrentActiveSuperTab().idProp}
            assignmentData={this.props.entry}
          /> )
        ) : (
          ""
        )}

        {this.getCurrentActiveSubTab()?.listType == "timeline"
          ? this.getTimelineUI(this.getCurrentActiveSubTab())
          : ""}

        {sliderState == 2 ? (
          <div>
            <div className="row">
              <div className="col-md-12 offset-md-3">
                <div className=" header">Chat with our Assistants</div>
              
                <div style={{ backgroundColor: "#fffdf2" }}>
                  <ChatFeed
                    messages={this.state.messages} // Boolean: list of message objects
                    isTyping={this.state.is_typing} // Boolean: is the recipient typing
                    hasInputField={false} // Boolean: use our input, or use your own
                    showSenderName // show the name of the user who sent the message
                    bubblesCentered={false} //Boolean should the bubbles be centered in the feed?
                    // JSON: Custom bubble styles
                    bubbleStyles={{
                      text: {
                        fontSize: 19,
                      },
                      chatbubble: {
                        borderRadius: 20,
                        padding: 10,
                        backgroundColor: "#0084FF",
                      },
                      recipientChatbubble: {
                        backgroundColor: "#f29624",
                      },
                    }}
                  />
                </div>
                <div className="row message-box text-left">
                  {/* <input  className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.subject} name="subject" placeholder="Subject" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif'}}/><br/> */}
                  <textarea
                    className="fieldbox1"
                    onChange={this.onChange}
                    type="text"
                    value={this.state.messageText}
                    name="messageText"
                    placeholder="Message"
                    style={{
                      fontSize: "17px",
                      fontFamily: "montserrat,sans-serif",
                    }}
                  ></textarea>
                  <br />
                  <div className="text-center">
                    {" "}
                    <button
                      className="btn btn-primary send-big-btn"
                      onClick={this.sendMessage}
                      placeholder="Attachment"
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {this.getCurrentActiveSubTab()?.listType == "message"
          ? this.getMessageUI(this.getCurrentActiveSubTab())
          : ""}
 
        {/* Checklists */}
        {sliderState == 7 ? (
          <div>
            {" "}
            <div className="row">
              <div className="col-md-12 offset-md-3">
                <div className=" header">Checklists </div>
                <div
                  className="row"
                  style={{
                    backgroundColor: "#eaeaea",
                    padding: "5px 30px 5px 30px",
                    textAlign: "right",
                    fontSize: "17px",
                    fontFamily: "montserrat",
                    fontWeight: "500",
                  }}
                >
                  <div
                    className="butn"
                    onClick={() => this.openModal("Document")}
                  >
                    <i
                      className="fa fa-send"
                      style={{ fontSize: "14px" }}
                      aria-hidden="true"
                    ></i>
                    Add Item
                  </div>
                </div>

                <Modal
                  isOpen={this.state.modalIsOpen}
                  onRequestClose={this.closeModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <div className="cross-row text-right">
                    <div
                      style={{ marginRight: "-5px" }}
                      onClick={this.closeModal}
                    >
                      <i
                        style={{ fontSize: "25px", fontWeight: "400" }}
                        className="fa fa-close"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                  <div className="header2">Add Checklist Item</div>

                  <div className="row content1-div  text-left">
                    <input
                      className="fieldbox1"
                      onChange={this.onChange}
                      type="text"
                      value={this.state.title}
                      name="title"
                      placeholder="title"
                      style={{
                        fontSize: "17px",
                        fontFamily: "montserrat,sans-serif",
                      }}
                    />
                    <br />

                    <div className="text-center">
                      {" "}
                      <button
                        className="btn btn-primary send-button"
                        onClick={() =>
                          this.props.addNewChecklist(
                            "checklists",
                            this.state.title
                          )
                        }
                        placeholder="Attachment"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </Modal>
                <ul style={{ padding: "20px" }} className="checkList">
                  {this.props.entry.checklists != undefined
                    ? this.props.entry.checklists.map((check, index) => (
                        <li className="checkList-item">
                          <label>
                            <input
                              type="checkbox"
                              defaultChecked={check.isDone}
                              value={check.isDone}
                              onChange={(e) =>
                                this.props.onCheckboxClick(e, index)
                              }
                            />
                            <span className="check-title">{check.name}</span>
                          </label>
                        </li>
                      ))
                    : ""}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

      
        {sliderState == 19 ? (
          <div>
            <div className="header">Progress Log</div>

            <div
              className="row"
              style={{
                backgroundColor: "#eaeaea",
                padding: "5px 30px 5px 30px",
                textAlign: "right",
                fontSize: "17px",
                fontFamily: "montserrat",
                fontWeight: "500",
              }}
            >
              <div
                className="butn"
                onClick={() => this.openModal("progressLog")}
              >
                <i
                  className="fa fa-send"
                  style={{ fontSize: "14px" }}
                  aria-hidden="true"
                ></i>
                Add Progress
              </div>
            </div>

            <Modal
              isOpen={this.state.modalIsOpen}
              onRequestClose={this.closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="cross-row text-right">
                <div style={{ marginRight: "-5px" }} onClick={this.closeModal}>
                  <i
                    style={{ fontSize: "25px", fontWeight: "400" }}
                    className="fa fa-close"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div className="header2">Add Progress</div>

              <div className="row">
                <div className="options-row ">
                  {this.state.attachmentPlaceholders.map((attach, i) => {
                    return (
                      <div style={{ marginTop: "5px" }} className="row">
                        <div className="col-md-8 upload-box">
                          {" "}
                          <FileBase64
                            multiple={true}
                            onDone={this.getFiles.bind(this)}
                          />
                        </div>

                        {i === 0 ? (
                          <div className="col-md-3">
                            <button
                              className="btn btn-primary attachment"
                              onClick={() => this.addMoreAttachment()}
                              value="Select a File"
                            >
                              <i className="fa fa-plus" aria-hidden="true"></i>
                              Add More
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="row content1-div  text-left">
                <input
                  className="fieldbox1"
                  onChange={this.onChange}
                  type="text"
                  value={this.state.subject}
                  name="subject"
                  placeholder="Title"
                  style={{
                    fontSize: "17px",
                    fontFamily: "montserrat,sans-serif",
                  }}
                />
                <br />
                <ReactQuill
                  value={this.state.message}
                  onChange={this.handleQuillChange}
                  style={{ height: "300px" }}
                />
                <div className="text-center">
                  {" "}
                  <button
                    className="btn btn-primary send-button"
                    onClick={() => this.sendMail("progressLogs")}
                    placeholder="Attachment"
                  >
                    Add
                  </button>
                </div>
              </div>
            </Modal>

            <TabListData
              titleProp="subject"
              idProp="mailId"
              tabList={this.state.progressLogs}
              url="/team-communication/page/TEAM-DOCS/"
            />
          </div>
        ) : (
          ""
        )}

        {sliderState == 10 ? (
          <div>
            <div className="header">Expense Log</div>

            <div
              className="row"
              style={{
                backgroundColor: "#eaeaea",
                padding: "5px 30px 5px 30px",
                textAlign: "right",
                fontSize: "17px",
                fontFamily: "montserrat",
                fontWeight: "500",
              }}
            >
              <div
                className="butn"
                onClick={() => this.openModal("expenseLog")}
              >
                <i
                  className="fa fa-send"
                  style={{ fontSize: "14px" }}
                  aria-hidden="true"
                ></i>
                Add Expense
              </div>
            </div>

            <Modal
              isOpen={this.state.modalIsOpen}
              onRequestClose={this.closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="cross-row text-right">
                <div style={{ marginRight: "-5px" }} onClick={this.closeModal}>
                  <i
                    style={{ fontSize: "25px", fontWeight: "400" }}
                    className="fa fa-close"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div className="header2">Add Expense</div>

              <div className="row">
                <div className="options-row ">
                  {this.state.attachmentPlaceholders.map((attach, i) => {
                    return (
                      <div style={{ marginTop: "5px" }} className="row">
                        <div className="col-md-8 upload-box">
                          {" "}
                          <FileBase64
                            multiple={true}
                            onDone={this.getFiles.bind(this)}
                          />
                        </div>

                        {i === 0 ? (
                          <div className="col-md-3">
                            <button
                              className="btn btn-primary attachment"
                              onClick={() => this.addMoreAttachment()}
                              value="Select a File"
                            >
                              <i className="fa fa-plus" aria-hidden="true"></i>
                              Add More
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="row content1-div  text-left">
                <input
                  className="fieldbox1"
                  onChange={this.onChange}
                  type="text"
                  value={this.state.subject}
                  name="subject"
                  placeholder="TITLE"
                  style={{
                    fontSize: "17px",
                    fontFamily: "montserrat,sans-serif",
                  }}
                />
                <br />
                <ReactQuill
                  value={this.state.message}
                  onChange={this.handleQuillChange}
                  style={{ height: "300px" }}
                />
                <div className="text-center">
                  {" "}
                  <button
                    className="btn btn-primary send-button"
                    onClick={() => this.sendMail("expenseLog")}
                    placeholder="Attachment"
                  >
                    Add
                  </button>
                </div>
              </div>
            </Modal>

            <div className="doc-section">
              <div className="row doc-head-row1">
                <div className="col-md-3">
                  <div className="table-head">Amount</div>
                </div>
                <div className="col-md-3">
                  <div className="table-head">For</div>
                </div>
                <div className="col-md-3">
                  <div className="table-head">Uploaded On</div>
                </div>
                <div className="col-md-3">
                  <div className="table-head">By</div>
                </div>
              </div>

              <ul className="progressLogs">
                {this.state.expenses.map((thred, index) =>
                  thred.attachment != "NA" ? (
                    <li>
                      <div className="row ">
                        <div className="col-md-3">
                          <div className="table-item ">
                            <Link
                              to={"/assignment-mail/" + thred.id}
                              onClick={this.fetchMailById}
                              className="timeline-user-comm-elements"
                              href="https://www.totoprayogo.com/#"
                            >
                              {" "}
                              {thred.subject}
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="table-item text-center">
                            {thred.message}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="table-item text-center">
                            {this.getFormattedDate(thred.date)}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="table-item text-center">
                            {thred.from}
                          </div>
                        </div>
                      </div>
                    </li>
                  ) : (
                    ""
                  )
                )}
              </ul>
            </div>
          </div>
        ) : (
          ""
        )}

        {sliderState == 11 ? (
          <div>
            <div className="header">Issue Log</div>

            <div
              className="row"
              style={{
                backgroundColor: "#eaeaea",
                padding: "5px 30px 5px 30px",
                textAlign: "right",
                fontSize: "17px",
                fontFamily: "montserrat",
                fontWeight: "500",
              }}
            >
              <div className="butn" onClick={() => this.openModal("issueLog")}>
                <i
                  className="fa fa-send"
                  style={{ fontSize: "14px" }}
                  aria-hidden="true"
                ></i>
                Raise Issue
              </div>
            </div>

            <Modal
              isOpen={this.state.modalIsOpen}
              onRequestClose={this.closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="cross-row text-right">
                <div style={{ marginRight: "-5px" }} onClick={this.closeModal}>
                  <i
                    style={{ fontSize: "25px", fontWeight: "400" }}
                    className="fa fa-close"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div className="header2">Issue</div>

              <div className="row">
                <div className="options-row ">
                  {this.state.attachmentPlaceholders.map((attach, i) => {
                    return (
                      <div style={{ marginTop: "5px" }} className="row">
                        <div className="col-md-8 upload-box">
                          {" "}
                          <FileBase64
                            multiple={true}
                            onDone={this.getFiles.bind(this)}
                          />
                        </div>

                        {i === 0 ? (
                          <div className="col-md-3">
                            <button
                              className="btn btn-primary attachment"
                              onClick={() => this.addMoreAttachment()}
                              value="Select a File"
                            >
                              <i className="fa fa-plus" aria-hidden="true"></i>
                              Add More
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="row content1-div  text-left">
                <input
                  className="fieldbox1"
                  onChange={this.onChange}
                  type="text"
                  value={this.state.subject}
                  name="subject"
                  placeholder="ssssue"
                  style={{
                    fontSize: "17px",
                    fontFamily: "montserrat,sans-serif",
                  }}
                />
                <br />
                <ReactQuill
                  value={this.state.message}
                  onChange={this.handleQuillChange}
                  style={{ height: "300px" }}
                />
                <select
                  name="senderEmail"
                  value={this.state.senderEmail}
                  onChange={this.onChange}
                >
                  <option>High</option>
                  <option>Medium</option>
                  <option>Low</option>
                </select>
                <div className="text-center">
                  {" "}
                  <button
                    className="btn btn-primary send-button"
                    onClick={() => this.sendMail("issueLog")}
                    placeholder="Attachment"
                  >
                    Add
                  </button>
                </div>
              </div>
            </Modal>

            <div className="doc-section">
              <div className="row doc-head-row">
                <div className="col-md-3">
                  <div className="table-head">Issue</div>
                </div>
                <div className="col-md-3">
                  <div className="table-head">Priority</div>
                </div>
                <div className="col-md-3">
                  <div className="table-head">Uploaded On</div>
                </div>
                <div className="col-md-3">
                  <div className="table-head">By</div>
                </div>
              </div>

              <ul className="issueLogs">
                {this.state.issues.map((thred, index) =>
                  thred.attachment != "NA" ? (
                    <li>
                      <div className="row doc-head-row">
                        <div className="col-md-3">
                          <div className="table-item text-center">
                            <Link
                              to={"/assignment-mail/" + thred.id}
                              onClick={this.fetchMailById}
                              className="timeline-user-comm-elements"
                              href="https://www.totoprayogo.com/#"
                            >
                              {" "}
                              {thred.subject}
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="table-item text-center">
                            {thred.senderEmail}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="table-item text-center">
                            {this.getFormattedDate(thred.date)}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="table-item text-center">
                            {thred.from}
                          </div>
                        </div>
                      </div>
                    </li>
                  ) : (
                    ""
                  )
                )}
              </ul>
            </div>
          </div>
        ) : (
          ""
        )}

        {sliderState == 12 ? (
          <div>
            <div className="header">comments</div>

            <div
              className="row"
              style={{
                backgroundColor: "#eaeaea",
                padding: "5px 30px 5px 30px",
                textAlign: "right",
                fontSize: "17px",
                fontFamily: "montserrat",
                fontWeight: "500",
              }}
            >
              <div
                className="butn"
                onClick={() => this.openModal("assignmentComment")}
              >
                <i
                  className="fa fa-send"
                  style={{ fontSize: "14px" }}
                  aria-hidden="true"
                ></i>
                Add Comments
              </div>
            </div>

            <Modal
              isOpen={this.state.modalIsOpen}
              onRequestClose={this.closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="cross-row text-right">
                <div style={{ marginRight: "-5px" }} onClick={this.closeModal}>
                  <i
                    style={{ fontSize: "25px", fontWeight: "400" }}
                    className="fa fa-close"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div className="header2">comments</div>

              <div className="row content1-div  text-left">
                <textarea
                  className="fieldbox1"
                  onChange={this.onChange}
                  type="text"
                  value={this.state.message}
                  name="message"
                  placeholder="Any comments"
                  style={{
                    fontSize: "17px",
                    fontFamily: "montserrat,sans-serif",
                  }}
                ></textarea>
                <br />

                <div className="text-center">
                  {" "}
                  <button
                    className="btn btn-primary send-button"
                    onClick={() => this.sendMail("assignmentComment")}
                    placeholder="Attachment"
                  >
                    Add
                  </button>
                </div>
              </div>
            </Modal>

            <div className="doc-section">
              <div className="row doc-head-row">
                <div className="col-md-6">
                  <div className="table-head">comments</div>
                </div>
              </div>

              <ul className="proposals">
                {this.state.assignmentComments.map((thred, index) =>
                  thred.attachment != "NA" ? (
                    <li>
                      <div className="row doc-head-row">
                        <div className="col-md-6">
                          <div className="table-item text-center">
                            <Link
                              to={"/assignment-mail/" + thred.id}
                              onClick={this.fetchMailById}
                              className="timeline-user-comm-elements"
                              href="https://www.totoprayogo.com/#"
                            >
                              {" "}
                              {thred.message}
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="table-item text-center">
                            {this.getFormattedDate(thred.date)}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="table-item text-center">
                            {thred.from}
                          </div>
                        </div>
                      </div>
                    </li>
                  ) : (
                    ""
                  )
                )}
              </ul>
            </div>
          </div>
        ) : (
          ""
        )}
        {sliderState == 13 ? (
          <div>
            {" "}
            <div className="row">
              <div className="col-md-12 offset-md-3">
                <div className=" header">Your Timeline</div>

                <ul className="timeline-user-comm">
                  {this.state.timelineForTeams.map((thred, index) => (
                    <li>
                      <div
                        className={
                          (thred.docType == "Proposal"
                            ? "RED"
                            : thred.docType == "Document"
                            ? "GREEN"
                            : "BLUE") + "-mail timeline-user-comm-card"
                        }
                      >
                        <div className="row ">
                          <div className="col-md-8">{thred.status}</div>
                          <div className="col-md-4 text-right">
                            {" "}
                            <button className="button-type">
                              {" "}
                              <p className="montserrat timeline-user-comm-subhead ">
                                {thred.docType == "TEAM-MAILS"
                                  ? "TEAM-MESSAGES"
                                  : thred.docType}
                              </p>
                            </button>
                          </div>
                        </div>
                        <h4 className="montserrat timeline-user-comm-head">
                          {" "}
                          <Link
                            to={
                              "/team-communication/page/" +
                              thred.docType +
                              "/" +
                              thred.id
                            }
                            className="timeline-user-comm-elements"
                            href="https://www.totoprayogo.com/#"
                          >
                            {" "}
                            {thred.subject}
                          </Link>
                        </h4>
                        <h4 className="montserrat timeline-user-comm-subhead">
                          {" "}
                          <a
                            href="#"
                            className=" timeline-user-comm-elements float-right"
                            style={{ marginTop: "-20px" }}
                          >
                            {this.getFormattedDate(thred.date)}
                          </a>
                        </h4>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {sliderState == 14 ? (
          <div>
            {" "}
            <div className="row">
              <div className="col-md-12 offset-md-3">
                <div className=" header">Updates</div>

                <div
                  className="row"
                  style={{
                    backgroundColor: "#eaeaea",
                    padding: "5px 30px 5px 30px",
                    textAlign: "right",
                    fontSize: "17px",
                    fontFamily: "montserrat",
                    fontWeight: "500",
                  }}
                >
                  <div
                    className="butn"
                    onClick={() => this.openModal("Update")}
                  >
                    <i
                      className="fa fa-send"
                      style={{ fontSize: "14px" }}
                      aria-hidden="true"
                    ></i>
                    Send Update
                  </div>
                </div>

                <Modal
                  isOpen={this.state.modalIsOpen}
                  onRequestClose={this.closeModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <div className="cross-row text-right">
                    <div
                      style={{ marginRight: "-5px" }}
                      onClick={this.closeModal}
                    >
                      <i
                        style={{ fontSize: "25px", fontWeight: "400" }}
                        className="fa fa-close"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                  <div className="header2">Send Update</div>

                  <div className="row">
                    <div className="options-row ">
                      {this.state.attachmentPlaceholders.map((attach, i) => {
                        return (
                          <div style={{ marginTop: "5px" }} className="row">
                            <div className="col-md-8 upload-box">
                              {" "}
                              <FileBase64
                                multiple={true}
                                onDone={this.getFiles.bind(this)}
                              />
                            </div>

                            {i === 0 ? (
                              <div className="col-md-3">
                                <button
                                  className="btn btn-primary attachment"
                                  onClick={() => this.addMoreAttachment()}
                                  value="Select a File"
                                >
                                  <i
                                    className="fa fa-plus"
                                    aria-hidden="true"
                                  ></i>
                                  Add More
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="row content1-div  text-left">
                    <select
                      name="selectedTemplate"
                      onChange={this.handleOnTemplateChange}
                      value={this.state.selectedTemplate}
                      className="form-control ember-text-field ember-view"
                      type="text"
                    >
                      {this.state.templates.map(function (template, i) {
                        return (
                          <option value={template.id}>{template.name}</option>
                        );
                      })}
                    </select>
                    <input
                      className="fieldbox1"
                      onChange={this.onChange}
                      type="text"
                      value={this.state.subjectUpdate}
                      name="subjectUpdate"
                      placeholder="Subject of your Update"
                      style={{
                        fontSize: "17px",
                        fontFamily: "montserrat,sans-serif",
                      }}
                    />
                    <br />
                    <ReactQuill
                      value={this.state.message}
                      onChange={this.handleQuillChange}
                      style={{ height: "300px" }}
                    />
                    <div className="text-center">
                      {" "}
                      <div
                        className="btn btn-primary send-button"
                        onClick={() => this.sendMail("update")}
                        placeholder="Attachment"
                      >
                        Send
                      </div>
                    </div>
                  </div>
                </Modal>
                <tr className="doc-section">
                  <div className="row doc-head-row">
                    <th className="table-head text-center">Id</th>

                    <th className="table-head text-center">Update</th>

                    <th className="table-head text-center">Date of Creation</th>

                    <th className="table-head text-center">Time of Creation</th>

                    <th className="table-head text-center">By</th>
                  </div>

                  <ul className="proposals">
                    {this.state.updates.map((thred, index) => (
                      <li>
                        <div className={thred.type + " row doc-head-row"}>
                          <td className="table-item text-center">
                            {thred.mailId}
                          </td>

                          <td className="table-item text-center">
                            <Link
                              to={"/assignment-mail/" + thred.id}
                              onClick={this.fetchMailById}
                              className="timeline-user-comm-elements"
                              href="https://www.totoprayogo.com/#"
                            >
                              {" "}
                              {thred.subject}
                            </Link>
                          </td>

                          <td className="table-item text-center">
                            {this.getFormattedDate(thred.date)}
                          </td>

                          <td className="table-item text-center">
                            {this.getFormattedTime(thred.date)}
                          </td>

                          <td className="table-item text-center">{thred.by}</td>
                        </div>
                      </li>
                    ))}
                  </ul>
                </tr>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
