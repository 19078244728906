import React, { Component } from "react";

import { Link } from "react-router-dom";
import $ from "jquery";
import {getPrivilegeByName} from '../../privilegeUtils';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

const data = [];
export default class SidebarTask extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []
     
    };
    
  }
  componentDidMount(){
    this.handleLine();
   // this.setState({title:this.props.match.params.productType});
  }
  handleLine(){

    $(function(){
      $("#tab1").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
        $("#section").toggle();
      });
      });
      $(function(){
        $("#c2").click(function(e){
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d2").toggle();
          $("#d10").toggle();
      });
      });
      $(function(){
        $("#c3").click(function(e){
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d3").toggle();
          $("#d10").toggle();
        });
        });
      $(function(){
        $("#c4").click(function(e){
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d4").toggle();
          $("#d10").toggle();
          });
          });
      $(function(){
         $("#c5").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d5").toggle();
            });
            });
      $(function(){
          $("#c6").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d6").toggle();
              });
              });
      $(function(){
          $("#c7").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d7").toggle();
                });
                });
      $(function(){
          $("#c8").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d8").toggle();
                  });
                  });  
  
  }
  
  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
    
            <div className="sidebar">
              <div id="sidebar1">
              <div className="sidebar-head">Task Master</div>
              
                {/*<div className="sidebar-sectionName">
    Products</div>*/}
                <div className="oneTab">
                <div className="sidebar-elementhead"><Link  to="/home">Home</Link></div>
                <div className="sidebar-element" id="c1"><Link  to={"/tasks"}> <i className="fa fa-bar-chart" />Dashboard<i className="fa fa-angle-right " /></Link></div>
                  <div className="sidebar-subelement" id="d1"><i className="fa fa-envelope" />View<i className="fa fa-angle-right " /></div>
                </div>
                <div className="oneTab">
                  <div className="sidebar-element" id="c3">
                    <i className="fa fa-th-list green" />
                    Tasks
                    <i className="fa fa-angle-down " />
                  </div>
                
                  <div className="sidebar-subelement" id="d3">
                  {getPrivilegeByName('Client').canAdd == true ?
                   <div><Link className="sidebar-link" to={"/tasks/add-tasks"} style={{marginLeft:'1px'}}>
                    <i className="fa fa-plus" />
                    Create Task
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                    :''}
                    <div>
                    <Link className="sidebar-link" to={"/tasks/view-tasks"}>
                      <i className="fa fa-eye" />
                    View Tasks
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                  </div>
                </div>
               
               
                 <div className="oneTab">
                  <div className="sidebar-element" id="c2">
                 
                    <i className="fa fa-list-alt green" />
                    Task Category
                    <i className="fa fa-angle-down " />
                   
                  </div>
                 <div className="sidebar-subelement" id="d2">
                   
                    {getPrivilegeByName('Client').canAdd == true ?
                    <div>
                  <Link className="sidebar-link" to={"/tasks/add-tasksCategory"}>
                    <i className="fa fa-plus" />
                    Create  Task Category
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div> :''}
                    <div>
                  <Link className="sidebar-link" to={"/tasks/view-tasksCategory"}>
                    <i className="fa fa-eye" />
                    View  Task Category
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                   </div>
                   
                </div>
                
              
  
               
              </div>
            </div>
         
    );
  }
}
