import React, { Component } from "react";

import BreadCrumbs from '../../BreadCrumbs';
import { Link } from "react-router-dom";

import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { ChatFeed, Message } from 'react-chat-ui'


import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from 'underscore';
let context = null;
const data = [];
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px ',
    position: 'Absolute',
    zIndex: '30000',
    backgroundColor: 'none',
  }
};

export default class pageTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      subject: "",
      message: "",
      attachment: "NA",
      thread: [],
      sendTo: "",
      documents: [],
      proposals: [],
      mails: [],
      entry: {
        checklists:[]
      },
      assignmentDocuments:[],
      assignmentComments:[],
      issues:[],
      expenses:[],
      progressLogs:[],
      modalIsOpen: false,
      docType: "MAIL",
      sliderState: 1,//sta//state for storing the response from the backend,
      superSliderState: "clients"
    };
    context = this;
   
  }

  


  componentDidMount() {
    const id = this.props.match.params.id;

    this.setState({id});
    this.fetchQueryById(this, id);
   
  }


  


  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(
       apiBaseUrl1+"businusquery/getTemplateById/" + id
      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  

 

 

  
  


  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    localStorage.setItem("MAILURL", url);


  }




  //updating the status button

  render() {
    const { sliderState, superSliderState,entry } = this.state;
    const active = " active-button"
    const deactive = "";
    return (
      <div className="Div">
        <div className="row breadcrumb-row">
          <div className="col-md-10">
        <BreadCrumbs /> 
        </div>
        <div className="col-md-2">
          <button> <Link style={{marginRight:'50px'}} className="pull-right" to={"/assignment/edit-updateTemplate/"+entry.id}><i className="fa fa-edit"></i>EDIT</Link></button>
          </div>
        </div>
       
          
        
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
        <div className="dedicated-page" style={{padding:'0px'}}>
    <div className="row-resource-head">
                  <h2 className="resource-head">{entry.assignmentTitle} </h2>
                  </div>
            <div className="header-div2 ">
              
              <div className="row">
                <div className="col-md-8">


                  

               

                  <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                   Template Id:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.templateId!=undefined ? entry.templateId : 'Not available'}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                   Template Type:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.templateType!=undefined ? entry.templateType : 'Not available'}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                   Template Name:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.templateName!=undefined ? entry.templateName : 'Not available'}</h4>
                                </div>
                              </div>
                             
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                   Subject:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.name!=undefined ? entry.name : 'Not available'}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                  Description:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.update!=undefined ? entry.update : 'Not available'}</h4>
                                </div>
                              </div>


                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                  Attachment:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.document!=undefined ?<div><a href={entry.document} download><button className="panel-buttons"><i class="fa fa-paperclip" aria-hidden="true"></i>Download {entry.attachmentName}</button></a></div> : 'Not available'}</h4>
                                </div>
                              </div>
                          
                </div>



               


             
              </div>
            </div>


            {/* super slider tabs */}
          

            </div>

        </div>
      </div>
    );
  }
}

