import React, { Component } from "react";
import axios from 'axios';
import BreadCrumbs from "../BreadCrumbs";
import { apiBaseUrl5 } from '../apiUtils';
import { Link } from "react-router-dom";
import $ from "jquery";
import _ from 'underscore';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

//defining the context initially assigning it a null value

let context = null;

//defining the heads of the table, the accessors 



export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
    counters:{},
    sendQueryCounter:{}
 
    };

    context = this;
  }
  componentDidMount(){
    localStorage.setItem("email","gautammegha2@gmail.com");
  this.countQueries();

}


  //function for toggling the status button

  


  //function for fetching queries from backend 
  
  countQueries(){

    axios.get(apiBaseUrl5+"businusquery/count/"+"all " )
    .then(function (response) {
      console.log(response.data);
      context.setState({counters:response.data.queries});
      context.setState({sendQueryCounter:response.data.category});
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }

  //function for changing the status button on click

  


  render() {
      const {counters,sendQueryCounter} = this.state;
    return (
      <div>
      <div className="row breadcrumb1-row" style={{marginLeft:'0px'}}>
      <div className="col-md-12"><BreadCrumbs/></div>
    
  </div>
      <div className="DashboardDiv" style={{marginLeft:'-24px',padding: '20px',overflowY:'scroll'}} >
        
      
      <div className="row ">
      <h2 className="dashboard-head">Dashboard</h2>
      </div>
     
      
      <div className="row">
      </div>
      
      <div className="row">
      <div className="col-md-6">
      <Link to="./products/product-requests">
      <div className="card-counter primary">
      <i className="fa fa-ticket" />
      <span className="count-numbers">{counters.letsgetstarted!=undefined ? counters.letsgetstarted : 0}</span>
      <span className="count-name">Lets Get started Requests</span>
      </div>
      </Link>
      </div>
       
      <div className="col-md-6">
      <Link to="./products/queries-sent">
      <div className="card-counter primary">
      <i className="fa fa-ticket" />
      <span className="count-numbers">{counters.askanexpert!=undefined ? counters.askanexpert : 0}</span>
      <span className="count-name">Ask an expert</span>
      </div>
      </Link>
      </div>
      </div>
       
        <div className="row">
       
            </div>
            <div className="row">
         


        <div className="col-md-6">
        <Link to="./consulting/proposals-rquested">
          <div className="card-counter primary">
            <i className="fa fa-ticket" />
            <span className="count-numbers">{counters.requestaproposal!=undefined ? counters.requestaproposal : 0}</span>
            <span className="count-name">Request a Proposal</span>
          </div>
          </Link>
        </div>

        <div className="col-md-6">
        <Link to="./consulting/queries-sent">
          <div className="card-counter primary">
            <i className="fa fa-ticket" />
            <span className="count-numbers">{counters.subscribers!=undefined ? counters.subscribers : 0}</span>
            <span className="count-name">Subscriber Queries</span>
          </div>
          </Link>
        </div>
        
        </div>
        <div className="row">
      
            </div>
            <div className="row">
        <div className="col-md-3">
        <Link to="./docs/vetting-requests">
          <div className="card-counter primary">
            <i className="fa fa-ticket" />
            <span className="count-numbers">{counters.requestacallback!=undefined ? counters.requestacallback : 0}</span>
            <span className="count-name">Request a Callback</span>
          </div>
          </Link>
        </div>
        <div className="col-md-3">
        <Link to="./docs/downloads-requests">
          <div className="card-counter primary">
            <i className="fa fa-ticket" />
            <span className="count-numbers">{counters.contactUs!=undefined ? counters.contactUs : 0}</span>
            <span className="count-name">Contact Us Queries</span>
          </div>
          </Link>
        </div>
        <div className="col-md-3">
        <Link to="./docs/tailoring-requests">
          <div className="card-counter primary">
            <i className="fa fa-ticket" />
            <span className="count-numbers">{counters["Get-in-touch"]!=undefined ? counters["Get-in-touch"]: 0}</span>
            <span className="count-name">Work with us Requests</span>
          </div>
          </Link>
        </div>
        
     


        
     

        </div>


      </div>
            </div>
          
       
    );
  }
}
