import React from 'react';
import axios from 'axios';
import "../../style/panel.css";
import ReactTable from 'react-table';
import 'react-quill/dist/quill.snow.css'; // ES6
import _ from 'underscore';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import EditorJs from 'react-editor-js';
import {EditorjsTool} from '../Editor/tool'
import {v1 as uuidv1} from 'uuid';
import Image from "@editorjs/image";
import CustomSidebar from './CustomSidebar';
import Modal from 'react-modal';

let context = null

const customStyles={
  content:{
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      height                : '80vh',
      width                 : '70%',
      transform             : 'translate(-50%, -50%)'
  }
}

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});
class DragTrComponent extends React.Component {

  render() {
    const { children = null, rowInfo } = this.props;
    if (rowInfo) {
      // debugger;
      const { original, index } = rowInfo;
      const { id } = original;
      return (   
     
        <Draggable key={id} index={index} draggableId={id}>
          {(draggableProvided, draggableSnapshot) => (
            <div
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
              {...draggableProvided.dragHandleProps}
            >
              <ReactTable.defaultProps.TrComponent style={{ width: '100%' }}>
                {children}
              </ReactTable.defaultProps.TrComponent>
            </div>
          )}
        </Draggable>
      )
      
          
    }
     else
      return (
        <ReactTable.defaultProps.TrComponent  >
          {children}
        </ReactTable.defaultProps.TrComponent>
      );
  }
}

class DropTbodyComponent extends React.Component {
  render() {
    const { children = null } = this.props;

    return (
      <Droppable droppableId="droppable">
        {(droppableProvided, droppableSnapshot) => (
          <div ref={droppableProvided.innerRef}>
            <ReactTable.defaultProps.TbodyComponent  >
              {children}
            </ReactTable.defaultProps.TbodyComponent>
          </div>
        )}
      </Droppable>
    );
  }
}


class listcustom extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      act: {},
      resdata: [],
      text: "",
      type:[],
      law:[],
      u:0,
      external:[],
      allCategory:[],
      filterData:[],
      ModalIsOpen:false,
      isCategory:false,
      categoryHeading:'',
      lawHeading:'',
      lastUrl:'',
      categoryDescription:""
   }
    // this.showFile = this.showFile.bind(this)
    this.fetch_all_acts = this.fetch_all_acts.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fetch_from_dynamo = this.fetch_from_dynamo.bind(this);
    this.editAct = this.editAct.bind(this);
    this.deleteAct = this.deleteAct.bind(this);
    this.setLaw = this.setLaw.bind(this);
    this.setSection = this.setSection.bind(this);
    this.setSectionDes = this.setSectionDes.bind(this);
    this.update_act = this.update_act.bind(this);
    this.addUrl = this.addUrl.bind(this);
    this.incrementu = this.incrementu.bind(this);
    this.fetchCategory = this.fetchCategory.bind(this);
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.selectCategory = this.selectCategory.bind(this)
  }


  // showFile(content){
  //   document.getElementById('show_file').innerHTML = content;
  // }


  componentDidMount(){
    this.fetch_all_acts()
    this.fetchCategory()
  }

  setType(){
    var type=''
    var lastUrl = window.location.pathname.split("/").pop()
    
    if(lastUrl==='case-law'){
      type="Case Law"
    }
    else if(lastUrl==='acts'){
      type="Acts"
    }
    else if(lastUrl==='rules'){
      type="Rules"
    }
    else if(lastUrl==='circular-notification'){
      type="Circular Notification"
    }
    this.setState({lastUrl:type})
    return(type)
  }

  fetch_all_acts(){

    this.fetch_from_dynamo('all').then(data => {
      let type = this.setType()
      var val=data
      var temp=[]
      for(var i=0; i<val.length;i++){
        if(val[i].legalType==='Custom'){
          if(val[i].type===type){
          temp.push(val[i])
        }
        }
      }
      temp = _.sortBy(temp,'date')
      this.setState({ resdata:temp,})
    })
  }

  fetchCategory(){
    let allCategory=[]
    let type = this.setType()

    return axios({
      url: 'https://3j0okb7n5h.execute-api.us-east-1.amazonaws.com/dev/businus/legalCategory/list',
      method: 'GET',
      crossDomain: true,
      responseType: 'json', // important
    }).then((response) => {
      for(var i=0;i<response.data.length;i++){
        if(response.data[i].legalType==='Custom')
          if(response.data[i].type===type){
          allCategory.push(response.data[i])
        }
      }
      this.setState({allCategory:allCategory})
     
   });
}


  handleChange(value) {
    this.setState({ text: value });
    document.getElementById('save').innerHTML = 'Update';
    document.getElementById('save').className = 'btn btn-primary';
  }


  fetch_from_dynamo(){
    return axios({
      url: 'https://tj1cb28koh.execute-api.us-east-1.amazonaws.com/dev/businus/article/list',
      method: 'GET',
      crossDomain: true,
      responseType: 'json', // important
    }).then((response) => {
      // console.log('fetch_from_dynamo :', response.data)
      return response.data;

   });
  }


  editAct(act_id) {
    document.getElementById('save').innerHTML = 'Update';
    document.getElementById('save').className = 'btn btn-primary';
    
    var law =''
    var lawDes=''

    this.setState({
      text:  '',
      act: "",
    });

    var data = this.state.resdata

    for(var i=0;i<data.length;i++){
      if(data[i].id===act_id){
        data=data[i]
        break
      }
    }

    var category = this.state.allCategory

    for(var i=0;i<category.length;i++){
      if(category[i].id===data.law){
        law=category[i].law
        lawDes=category[i].lawDes
      }
    }

    this.setState({
      text: data.content,
      act: data,
      categoryHeading:data.law,
      lawHeading:law,
      categoryDescription:lawDes
    });

    // axios({
    //   url: 'https://tj1cb28koh.execute-api.us-east-1.amazonaws.com/dev/businus/article/get/' + act_id,
    //   method: 'GET',
    //   crossDomain: true,
    //   responseType: 'json', // important
    // }).then((response) => {
    //   var data = response.data;
    //   console.log("edit act : ", data);
    //   this.setState({
    //     act:  data,
    //     text:  data.content,
    //   });

      // document.getElementById('type').value = data.type;
      this.setLaw();
      // document.getElementById('law').value = data.law;
      this.setSection();
      // document.getElementById('lawDes').value = data.lawDes;
      document.getElementById('sec').value = data.section;
      this.setSectionDes();
      document.getElementById('secDes').value = data.sectionDes;
      document.getElementById('heading').value = data.heading;
      document.getElementById('subHeading').value = data.subHeading;
      data.seo===undefined? document.getElementById("seo").value='': document.getElementById("seo").value = data.seo;
      data.slug===undefined? document.getElementById('slug').value ='': document.getElementById('slug').value = data.slug;
      data.metaDescription===undefined? document.getElementById('metaDescription').value = '': document.getElementById('metaDescription').value = data.metaDescription;

      if(data.more!=undefined){      
        this.setState({external:data.more.external,u:data.more.external.length,})

        for(var i=0; i < data.more.external.length; i++){
          document.getElementById("src_"+i).value=data.more.external[i].src
          document.getElementById("url_"+i).value=data.more.external[i].url
          document.getElementById("srcName_"+i).value=data.more.external[i].name
        }
      }

  if(data.more===undefined){
    this.setState({external:undefined,u:0})
  }
    // });

  }


  deleteAct(act_id){
    // alert("Are u sure you want to Delete Article?");
    if (window.confirm("Are you sure you want to Delete this article ?")) {

      axios({
        url: 'https://tj1cb28koh.execute-api.us-east-1.amazonaws.com/dev/businus/article/get/' + act_id,
        method: 'Delete',
      }).then((response) => {
        console.log('resonse_deleteAct :', response.data);
        this.fetch_all_acts();
        alert("Blog Deleted");
      });

    } 
  }


  addUrl(){
    // adds a URL entry in MORE
    
    var out_div = document.createElement('div');
    var spn = document.createElement('span');
    var src = document.createElement("INPUT");
    var url = document.createElement("INPUT");
    var srcName = document.createElement("INPUT");
    var br = document.createElement("br");
    this.incrementu();
    var i = this.state.u;

    out_div.setAttribute("class","input-group mb-3");
    out_div.style.width = '80%';
    // out_div.setAttribute("id", "urldiv_" + i);

    spn.setAttribute("type", "text");
    spn.setAttribute("class", "input-group-addon");
    spn.style.width = '20%';
    spn.innerHTML = "Source Url";
    // spn.setAttribute("id", "lat_" + i);

    src.setAttribute("type", "text");
    src.setAttribute("class", "form-control");
    src.setAttribute("placeholder", "Source");
    src.setAttribute("id", "src_" + i);

    url.setAttribute("type", "text");
    url.setAttribute("class", "form-control");
    url.setAttribute("placeholder", "Url");
    url.setAttribute("id", "url_" + i);

    srcName.setAttribute("type", "text");
    srcName.setAttribute("class", "form-control");
    srcName.setAttribute("placeholder", "Name");
    srcName.setAttribute("id", "srcName_" + i);

    out_div.appendChild(spn);
    out_div.appendChild(src);
    out_div.appendChild(url);
    out_div.appendChild(srcName);
    document.getElementById("more").appendChild(out_div);
    document.getElementById("more").appendChild(br);

  }


  setLaw(){
    var e = document.getElementById("type");
    // var type = e.options[e.selectedIndex].text;
    var type= this.state.lastUrl
    var lawlist = document.getElementById("lawlist");
    var acts = this.state.allCategory;
    var laws = [];
    var disLaw = [];
    var opts = "";
    for (var i = 0; i < acts.length; i++) {
      if(acts[i].legalType==="Custom"){
      if (acts[i].type===type) {
        laws.push(acts[i]);
        if (!disLaw.includes(acts[i].law)) {
          disLaw.push(acts[i].law);
          opts += "<option>" + acts[i].law + "</option>";
        }
      }
    }
    }
    lawlist.innerHTML = opts;
    this.setState({
      type: laws,
    })
  }


  setSection(){
    var law = document.getElementById("law").value;
    var sectionlist = document.getElementById("sectionlist");
    var acts = this.state.type;
    var sec = [];
    var disSec = [];
    let categoryHeading=''
    var opts = "";
    var lawDes = "";
    for (var i = 0; i < acts.length; i++) {
      if (acts[i].law===law) {
        sec.push(acts[i]);
        categoryHeading=acts[i].id
        lawDes = acts[i].lawDes;
        // if (!disSec.includes(acts[i].section)) {
        //   disSec.push(acts[i].section);
        //   opts += "<option>" + acts[i].section + "</option>";
        // }
      }
    }
    sectionlist.innerHTML = opts;
    document.getElementById("lawDes").value = lawDes;
    this.setState({
      law: sec,
      categoryHeading:categoryHeading
    })
    
  }


  setSectionDes(){
    var sec = document.getElementById("sec").value;
    var secs = this.state.law;
    // console.log("secs :",secs);
    var secDes = "";
    for (var i = 0; i < secs.length; i++) {
      if (secs[i].section===sec) {
        secDes = secs[i].sectionDes;
      }
    }
    document.getElementById("secDes").value = secDes;
  }


  incrementu() {
    // increments variable for no. of urls in MORE

    var inc = this.state.u + 1;
    this.setState({
      u : inc
    });
  }

  uploadByFile(file) {

    let fd = new FormData();
    fd.append('file',file);
  }


  async update_act(){

    var external = []
    var internal = []
    for(var i= 0; i< this.state.u; i++){
      external[i]={
        src: document.getElementById("src_"+ i).value,
        url: document.getElementById('url_'+ i).value,
        name: document.getElementById('srcName_'+ i).value
      } 
    }

    var more ={
      external : external,
      internal : internal,
    }

    const content = await this.editorInstance.save()

    // var e = document.getElementById("type");
    // var type = e.options[e.selectedIndex].text;
    var act = {
      id: this.state.act.id,
      articleId:this.state.act.articleId,
      legalType: "Custom",
      type: this.state.act.type,
      law: this.state.act.law,
      lawDes: this.state.act.lawDes,
      section: document.getElementById('sec').value.trim(),
      sectionDes: document.getElementById('secDes').value.trim(),
      heading: document.getElementById('heading').value.trim(),
      subHeading: document.getElementById('subHeading').value.trim(),
      content:content,
      more:more,
      index:this.state.act.index,
      seo:document.getElementById('seo').value,
      slug:document.getElementById('slug').value,
      metaDescription:document.getElementById('metaDescription').value
    };
   

    axios({
      url: 'https://tj1cb28koh.execute-api.us-east-1.amazonaws.com/dev/businus/article/update/' + this.state.act.id,
      method: 'POST',
      data: act,
    }).then((response) => {
      alert("Content Updated");
      window.location.reload()
    });
  }

  updateIndex(Array,index){
    var act={
      id:Array.id,
      articleId:Array.articleId,
      legalType: "Custom",
      type: Array.type,
      law: Array.law,
      lawDes: Array.lawDes,
      section: Array.section,
      sectionDes: Array.sectionDes,
      heading: Array.heading,
      subHeading: Array.subHeading,
      content: Array.content,
      more: Array.more,
      index: index,
      seo:Array.seo,
      slug:Array.slug,
      metaDescription:Array.metaDescription
    }

    axios({
      url: 'https://tj1cb28koh.execute-api.us-east-1.amazonaws.com/dev/businus/article/update/' + Array.id,
      method: 'POST',
      data: act,
    }).then((response) => {
      
    });
  }

  handleDragEnd = result => {
    if (!result.destination) {
      return;
    }

    // var data =[]
    else{
      const filterData = reorder(
        this.state.filterData,
        result.source.index,
        result.destination.index
      );
      var temp =[]
      let source = result.source.index
      let destination = result.destination.index

        console.log('filter data --',this.state.filterData)

      for(var i =0; i< this.state.filterData.length;i++){
        if(this.state.filterData[i].index===source){
          temp= this.state.filterData[i]
          console.log("temp",temp)
        }
      }

      if(source<destination){
        for(var i =0; i< this.state.filterData.length;i++){      
          for(var j=source+1;j<destination+1;j++){
            if(j===this.state.filterData[i].index){
              this.updateIndex(this.state.filterData[i],j-1)
            }           
          }
        }
      }
      
      if(destination<source){
        for(var i =this.state.filterData.length-1; i>=0 ;i--){  
          for(var j=source-1;j>destination-1;j--){         

            if(j===this.state.filterData[i].index){
              this.updateIndex(this.state.filterData[i],j+1)
            }           
          }
        }
      }
      
      this.updateIndex(temp,destination)
      
      
      this.setState({
        filterData
      });
    }

  };

  getTrProps = (state, rowInfo) => {
    //  console.log(rowInfo);
    return { rowInfo };
  }

  closeModal(){
    this.setState({ModalIsOpen:false})
  }

  openModal(){
    this.setState({ModalIsOpen:true})
  }

  selectCategory(id){
      
      let filterData = []
      for(var i=0;i<this.state.resdata.length;i++){
        if(this.state.resdata[i].law===id){
          filterData.push(this.state.resdata[i])
        }
      }
      filterData=_.sortBy(filterData,'index')
      this.setState({filterData:filterData,isCategory:true,ModalIsOpen:false})
      
  }

  closeSelectCategory(){
    this.setState({isCategory:false})
  }


  render() {
    const columns = [{
      Header: <div className="form-textHead cell-head">Actions</div>,
      accessor: 'id',
      Cell: props =>  <div className='' style={{}}>
                        <button type="button" style={{margin: "3px"}} className="btn btn-primary" data-toggle="modal" data-target="#myModal" onClick={e=>this.editAct(props.value)}><span className="fas fa-edit"></span></button>
                        <button type="button" style={{margin: "3px"}} className="btn btn-danger" onClick={e=>this.deleteAct(props.value)}><span className="fas fa-trash"></span></button>
                      </div>,
      minWidth:150,
    },{
      Header: <div className="form-textHead cell-head">Article Id</div>,
      accessor: 'articleId',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:150,
    }, {
      Header: <div className="form-textHead cell-head">Type</div>,
      accessor: 'type',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:100,
    }, 
    {
      Header: <div className="form-textHead cell-head">Section</div>,
      accessor: 'section',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:100,
    },{
      Header: <div className="form-textHead cell-head">Section Description</div>,
      accessor: 'sectionDes',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:200,
    },{
      Header: <div className="form-textHead cell-head">Heading</div>,
      accessor: 'heading',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:100,
    },{
      Header: <div className="form-textHead cell-head">Sub Heading</div>,
      accessor: 'subHeading',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:100,
    },{
      Header: <div className="form-textHead cell-head">SEO</div>,
      accessor: 'seo',
      filterMethod: (filter, row) => {debugger;   return row.catagory.toLowerCase().includes(filter.value.toLowerCase()) },
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:200
    },{
      Header: <div className="form-textHead cell-head">Slug</div>,
      accessor: 'slug',
      filterMethod: (filter, row) => {debugger;   return row.catagory.toLowerCase().includes(filter.value.toLowerCase()) },
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:220
    }, {
      Header: <div className="form-textHead cell-head">Meta Description</div>,
      accessor: 'metaDescription',
      filterMethod: (filter, row) => {debugger;   return row.catagory.toLowerCase().includes(filter.value.toLowerCase()) },
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:220
    },{
      Header: props => <span className="form-textHead cell-head"> Date</span>,
      accessor: 'date',
      filterMethod: (filter, row) =>
               {   return row.source.toLowerCase().includes(filter.value.toLowerCase()) },
      Cell: props => 
      new Date(props.value).toDateString(),
      minWidth:180
    },{
      Header: props => <span className="form-textHead cell-head"> Time</span>,
      accessor: 'date',
      filterMethod: (filter, row) =>
               {   return row.source.toLowerCase().includes(filter.value.toLowerCase()) },
      Cell: props => 
      new Date(props.value).toLocaleTimeString(),
      minWidth:180
    }];

    return (      
    <div>
      <div className="row">
        <div className="col-md-2"> <CustomSidebar/></div>
      <div className="col-md-10">
      <div className = "container-fluid overflow-auto" style={{margin: "10px"}}>
      <div className='row'>
        <div className="col-md-9 col-lg-9" style={{margin: "10px"}}><h3>{this.state.lastUrl} List : </h3></div>
        <div className="col-md-2 col-lg-2" style={{marginTop:'2%'}}> <div className="download-button">{this.state.isCategory?<button onClick={()=>this.closeSelectCategory()}>Exit</button>:<button onClick={()=>this.openModal()}>Rearrange</button>}</div> </div>
      </div>
        
        <div className="container-fluid " style={{}}>
        {this.state.isCategory?
          <DragDropContext onDragEnd={this.handleDragEnd}> 
          <ReactTable 
             TrComponent={DragTrComponent}
             TbodyComponent={DropTbodyComponent}
             getTrProps={this.getTrProps} 
             data={this.state.filterData} 
             columns={columns} 
             style={{height:'85vh',overflow:'scroll'}} filterable/>
         </DragDropContext>:
         <ReactTable data={this.state.resdata} 
         columns={columns} 
         style={{height:'85vh',overflow:'scroll'}} filterable/>

        }
        
        </div>
        <div>
          <div id="myModal" className="modal fade" role="dialog">
            <div className="modal-dialog" style={{width:'1100px', marginTop: '100px'}}>

              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" onClick={this.fetch_all_acts}>&times;</button>
                  <h4 className="modal-title">Update {this.state.CustomType} </h4>
                </div>
                <div className="modal-body">
                  {/*--------------------------------edit-------------------------*/}
                  {this.state.act?
                    <div className="container-fluid "style={{margin: "30px",}}>

                      <div class="well well-sm">
                        <div class="input-group" style={{width:"80%"}}>
                          <span type="text" class="input-group-addon" id="" style={{width:"20%"}}>Type</span>
                          {/*<input type="text" class="form-control" id="heading" placeholder="" style={{}} required></input>*/}
                          <select class="form-control" onChange={this.setLaw} id="type">
                            <option>Case Law</option>
                            <option>Act</option>
                            <option>Rules</option>
                            <option>Circulars and Notification</option>
                          </select>
                        </div><br/>

                        <div class="input-group mb-3" style={{width:"80%"}}>
                          <span type="text" class="input-group-addon" id="" style={{width:"20%"}}>Law</span>
                          <input type="text" class="form-control" onChange={this.setSection} autocomplete="off" list="lawlist" id="law" placeholder="Heading" style={{}} required></input>
                          <input type="text" class="form-control" id="lawDes" placeholder="Description" style={{}} required></input>
                          <datalist id="lawlist" >
                            {/*<option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>*/}
                          </datalist>
                        </div><br/>

                        <div class="input-group mb-3" style={{width:"80%"}}>
                          <span type="text" class="input-group-addon" id="" style={{width:"20%"}}>Section</span>
                          <input type="text" class="form-control" onChange={this.setSectionDes} list="sectionlist" id="sec" placeholder="Heading" style={{}} required></input>
                          <input type="text" class="form-control" id="secDes" placeholder="Description" style={{}} required></input>
                          <datalist id="sectionlist">
                            {/*<option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>*/}
                          </datalist>
                        </div>
                      </div><br/>

                      <div class="well well-sm">
                        <div class="input-group mb-3" style={{width:"80%"}}>
                          <span type="text" class="input-group-addon" id="" placeholder="Title" style={{width:"20%"}}>Heading</span>
                          <input type="text" class="form-control" id="heading" placeholder="" style={{width:this.state.inpWidth}} required></input>
                        </div><br/>

                        <div class="input-group mb-3" style={{width:"80%"}}>
                          <span type="text" class="input-group-addon" id="" placeholder="Title" style={{width:"20%"}}>Sub Heading</span>
                          <input type="text" class="form-control" id="subHeading" placeholder="" style={{width:this.state.inpWidth}} required></input>
                        </div><br/>

                        {/*<div class='col-xs-2'> <input type='text' class="form-control" id='cat2' placeholder='Sub Category' required/> </div>
                        <div class='col-xs-2'> <input type='text' class="form-control" id='name' placeholder='Article Name' required/> </div>*/}

                        <div class="input-group" style={{width:"80%"}}>
                          <span type="text" class="input-group-addon" id="" placeholder="Content" style={{width:"20%"}}>Content</span>
                          
                          <div style={{border:"1px solid #ededed" ,marginRight:'3%'}}>
                        <div style={{height:'40%',paddingBottom:"0"}}>
                          
                          <EditorJs 
                  tools={{image:  {
                  class: Image,
                  config: {
                   uploader:{
                   uploadByFile: async function(file){
                      console.log(file);
                      const id = uuidv1()
                      const imageURL='https://businus-files-live.s3.amazonaws.com/image/'+id+'.png';
                      
                     let fileBase = await toBase64(file);
                     let result = await axios.post(
                      "https://tj1cb28koh.execute-api.us-east-1.amazonaws.com/dev/businus/uploadEditorImage",
               {
                   image:fileBase,
                   imageName:fileBase.name,
                   id:id,
                   imageURL:imageURL
               })

               return{
                success:1,
                file:{
                  url:imageURL
                }
                 }
                 
                      
                    },
                    
                   }
                  }
                },...EditorjsTool}} 
                data={this.state.text}
                instanceRef={instance=>this.editorInstance=instance}
                ></EditorJs>
                </div>
              </div>
                          
                          {/* <ReactQuill
                              formats={[
                                "background",
                                "bold",
                                "color",
                                "font",
                                "code",
                                "italic",
                                "link",
                                "size",
                                "strike",
                                "script",
                                "underline",
                                "blockquote",
                                "header",
                                "indent",
                                "list",
                                "align",
                                "direction",
                                "code-block",
                                "formula",
                              ]} value={this.state.text} onChange={this.handleChange} /> */}
                          {/*<textarea type="text" rows="10" class="form-control" id="content" placeholder="" style={{width:this.state.inpWidth}} required></textarea>*/}
                        </div><br/>
                      <input type='file' id='file' className='btn btn-default btn-file' accept='.txt' onChange={e => this.handleFileChosen(e.target.files[0])}/>
                    </div>

                    </div>
                  :""}

                    <div className='well well-sm'>
                      <h5> Add SEO </h5>

                      <div class="input-group mb-3" style={{width:"80%"}}>
                        <span type="text" class="input-group-addon" id="" placeholder="SEO"span style={{width:"20%"}}>SEO</span>
                        <input type="text" class="form-control" id="seo" style={{width:this.state.inpWidth}} required></input>
                      </div><br/>

                      <div class="input-group mb-3" style={{width:"80%"}}>
                        <span type="text" class="input-group-addon" id="" placeholder="Slug"span style={{width:"20%"}}>Slug</span>
                        <input type="text" class="form-control" id="slug" style={{width:this.state.inpWidth}} required></input>
                      </div><br/>

                      <div class="input-group mb-3" style={{width:"80%"}}>
                        <span type="text" class="input-group-addon" id="" placeholder="Meta Description"span style={{width:"20%"}}>Meta Description</span>
                        <input type="text" class="form-control" id="metaDescription" style={{width:this.state.inpWidth}} required></input>
                      </div><br/>

                    </div>

              <div class="well well-sm " id='more'>
                    <h5>Add More :- </h5>

                    <button type='button' class="btn btn-default" style={{margin:'10px'}} id = '' onClick={this.addUrl}>Add Url</button>
                
                </div>


                    {this.state.external!=undefined?
                    <div>
                      {this.state.external.map((show_more,index)=>
                     <div class="input-group mb-3 wth-80" style={{marginBottom:'2%'}}>
                     <span type="text" class="input-group-addon" id="" placeholder="Author" style={{width:"20%"}}>Source Url</span>
                     <input type="text" class="form-control" id={"src_"+ index} placeholder="Source" name='src'  ></input>
                     <input type="text" class="form-control" id={"url_"+ index} placeholder="URL" name='url'  ></input>
                     <input type="text" class="form-control" id={"srcName_"+ index} placeholder="Name" name='name'  ></input>
                   </div>
                      )}
                    
                    </div>
                   :'' }

                  {/*--------------------------------edit-------------------------*/}
                </div>
                <div className="modal-footer">
                  <button type='button' className="btn btn-primary" id = 'save' onClick={this.update_act}>Update</button>
                  {/*<button type='button' className="btn btn-default" id = 'dnld' onClick={this.download}>Download</button>*/}
                  <button type="button" className="btn btn-default" data-dismiss="modal" onClick={this.fetch_all_acts}>Close</button>
                </div>
              </div>

            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.ModalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}>
            {this.state.ModalIsOpen?
            <div>
              <div className='modal-close' onClick={this.closeModal}>&times;</div>
              <div className='row'>
              <div className='col-md-12 col-lg-12'>
                <h3> Select the category of the content you want to rearrange.</h3>
              </div>
              <div className="col-md-12 col-lg-12">
                <div className="rearrange-row-container">
                  {this.state.allCategory.map((cat)=>(
                    <div className="rearrange-row-wrap">
                    <div className="col-md-2 col-lg-2 rearrange-row-cell" onClick={()=>this.selectCategory(cat.id)}>{cat.legalCatgoryId}</div>
                    <div className="col-md-10 col-lg-10 rearrange-row-cell" onClick={()=>this.selectCategory(cat.id)}> {cat.law} </div>
                  </div>
                  ))}
                  
                </div>
              </div>
              </div>
            </div>:""
            }
          </Modal>
        </div>
      </div>
        </div>
      </div>
    )
  }
}
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}
export default listcustom
