import React, { Component } from "react";
import "../../style/panel.css";
import { Link } from "react-router-dom";
import BlogSidebar from "./BlogSidebar";

export default class BlogDashboard extends Component{
    render(){
        return(
            <div>
                <div className="row">
                    <div className='row-md-2 col-lg-2'> <BlogSidebar/>  </div>
                <div className="col-md-10 col-lg-10">
                <div className="mainScreenKhub">
                <div className="container">
                    <div className="row">
                    <div className="col-md-6">
      <div className="tab-row1">
      <h2 className="tab-row-head">Legal & Finance Blog Category</h2>
      <div className="row">

      <div className="col-md-4">
     <div className="tab text-center">
     <Link to="/knowledgeHub/add-blogCategory">
     <img className="tab-image1" id="tab1" 
                  style={{ marginLeft: "-3px", height:'50px'}}
                  src={require("../../images/add-category-icon.png")}
                />
        <h2 className="tab-text-main"> Add Blog Category </h2></Link>
     </div>
     </div> 
    
     <div className="col-md-4">
     <div className="tab text-center">
     <Link to="/knowledgeHub/view-blogCategory">
     <img className="tab-image1" id="tab1" 
                  style={{ marginLeft: "-3px", height:'50px' }}
                  src={require("../../images/view-blog-category.png")}
                />
        <h2 className="tab-text-main"> View Blog Category </h2></Link>
     </div>
     </div> 
        
         </div>


         </div>

         </div>

         <div className="col-md-6">
         <div className="tab-row1">
     <h2 className="tab-row-head">Blog Content</h2>
       <div className="row">


        <div className="col-md-4">
        
         <div className="tab text-center">
         <Link to="/knowledgeHub/add-article">  
     <img className="tab-image2" id="tab1"
                  style={{ marginLeft: "-3px" }}
                  src={require("../../images/add-content-icon.png")}
                />
           <h2 className="tab-text-main">Add Content</h2></Link>
     </div>
     </div> 
         
      <div className="col-md-4">
         <div className="tab text-center">
         <Link to="/knowledgeHub/view-article">    
     <img className="tab-image1" id="tab1"
                  style={{ marginLeft: "-3px" }}
                  src={require("../../images/view-content-icon.png")}
                />
           <h2 className="tab-text-main">View Content</h2></Link>
     </div>
         </div> 

         </div>


       </div>
     </div>
                    </div>
                </div>
                </div></div>
                </div>
            </div>
        )
    }
}