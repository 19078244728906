import React from 'react';
import 'react-quill/dist/quill.snow.css'; // ES6
import axios from 'axios';
import FileBase64 from 'react-file-base64';
import EditorJs from 'react-editor-js';
import {EditorjsTool} from '../../Editor/tool'
import {v1 as uuidv1} from 'uuid';
import Image from "@editorjs/image";
import Modal from 'react-modal';
import Truncate from 'react-truncate';
import BlogSidebar from '../../Blog/BlogSidebar';
import { apiBaseUrl20, s3BucketUrl } from '../../../components/apiUtils';


const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    zIndex                : "1",
    width                 : "80%",
    height                : "68vh",
    borderRadius          : "5px",
    boxShadow             : "0px 0px 9px 2px #c5c3c3",
    border                : "none"
  }
};

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export default class EditBlog extends React.Component {
  constructor(props) {
    super(props)

    var allText = 'Hi write something';
    this.state = {
      resData:[],
      heading:'',
      subHeading:'',
      authorName:'',
      authorDescription:'',
      datePosted:'',
      dateModified:'',
      readTime:'',
      source:'',
      sourceUrl:'',
      content:'',
      blogImageCredit:'',
      tags:'',
      text: allText,
      inpWidth:'100%',
      blogType:'',
      flag:false,
      imageArray:[],
      modalIsOpen:false,
      blogImageURL:'',
      truncateContent:'',
      categoryArray:[],
      authorImage:"",
      category:'',
      contentSummary:'',
      more:{
          external:[],
          internal:[],
      }
     }
    this.handleChange = this.handleChange.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    this.handleTags = this.handleTags.bind(this);
    this.save_to_dynamo = this.save_to_dynamo.bind(this);
    this.onChange = this.onChange.bind(this)
    this.finalChanges = this.finalChanges.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  componentDidMount(){
    let id = this.props.match.params.id
    this.fetchAllCategory()
    this.fetchBlogById(this,id)
}

fetchBlogById(that,id){
  var temp_tag=''
    return axios({
        url: (apiBaseUrl20+'blog/get/'+id),
        method:'GET',
        crossDomain:true,
        responseType:'json',
    }).then((response)=>{
      
      response.data.tags.forEach(element => {
        temp_tag+=element+','
      });
      
        that.setState({
            resData:response.data,
            heading:response.data.heading,
            subHeading:response.data.subHeading,
            content:response.data.content,
            source:response.data.source,
            sourceUrl:response.data.sourceUrl,
            datePosted:response.data.datePosted,
            readTime:response.data.readTime,
            authorName:response.data.authorName,
            authorDescription:response.data.authorDescription,
            blogImageCredit:response.data.blogImageCredit,
            seo:response.data.seo,
            slug:response.data.slug,
            metaDescription:response.data.metaDescription,
            more:response.data.more,
            blogType:response.data.blogType,
            authorImage:response.data.authorImageURL,
            contentSummary:response.data.contentSummary,
            category:response.data.category,
            tags:temp_tag
        })
    })
}

fetchAllCategory(){
  let temp_data=[]
    return axios({       
        url: (apiBaseUrl20+'blogCategory/list'),
        method: 'GET',
        crossDomain: true,
        responseType: 'json', 
      }).then((response) => {
        for(var i=0;i<response.data.length;i++){
          if(response.data[i].learningCategory===sessionStorage.getItem('learningCategory')){
            temp_data.push(response.data[i])
          }
        }
        this.setState({categoryArray:temp_data,category:temp_data[0].id})  
     });  
}

  async finalChanges(){
    const content = await this.editorInstance.save()
    this.filterParagraph(content)
    if(this.state.imageArray.length>1){
      this.setState({modalIsOpen:true})
    }
    else{
      this.save_to_dynamo()
    }
    this.setState({blogImageURL:this.state.imageArray[0]})
  }

  closeModal(){
    this.setState({modalIsOpen:false,})
  }

  onChange(e) {
    let value = e.target.value
    let name = e.target.name

    this.setState({[name]:value,flag:true});
  }

  handleOnChangeMore=(e,i)=>{
    var moreExt = this.state.more

    moreExt.external[i][e.target.name]=e.target.value

    this.setState({more:moreExt})
  }

  addMore=()=>{
      var newMore ={
          src:'',
          url:'',
          name:''
      }
      var more = this.state.more
      more.external.push(newMore)
      this.setState({more:more})
  }

  getAuthorFiles(files){
    this.setState({selectedFile: files[0]})
    this.fileUploadHandler(this,'authorImage')
  }


  fileUploadHandler = (that, docType) => {
    const id = uuidv1()
    const imageURL=s3BucketUrl+id+'.png';
    that.setState({ loading: true });
    axios.post(apiBaseUrl20+"uploadEditorImage",
      {
        "image": that.state.selectedFile.base64,
        "imageName": that.state.selectedFile.name,
        "id":id,
        "imageURL":imageURL
      })
      .then(function (response) {
        if (response.status === 200) {
          console.log("data--",response.data)
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: imageURL });

        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleChanges(e){
    let target = e.target;
    let name = target.name;
  
    this.setState({[name]:target.value});
  }

  handleTags(e){
    let target = e.target
    var tag = target.value.split(',')
    this.setState({tags: tag})
  }

  handleChange(value) {
    this.setState({ text: value })
  }

  uploadByFile(file) {

    let fd = new FormData();
    fd.append('file',file);
  }

  addImage(imageURL){
    this.setState({imageArray:this.state.imageArray.push(imageURL)})
  }

selectImage(image){
  this.setState({blogImageURL:image})
}

filterParagraph(jsonContent){
  let content=""
  let imageArray=this.state.imageArray
  let block = jsonContent.blocks
  for(var i=0;i<block.length;i++){
    switch(block[i].type){
      case "paragraph":
        content =  block[i].data.text 
        break;
      case "image":
        imageArray.push(block[i].data.file.url)
    }
  }
  this.setState({truncateContent:content,imageArray:imageArray})
}

validation(content){
  let flag=true

  if(this.state.heading.trim()===''){
    flag=false
    alert("Please add the heading")
  }
  else if(content.blocks.length===0){
    flag=false
    alert("Please add the content")
  }
  else if(this.state.category===""){
    flag=false
    alert("Please select the category")
  }
  else if(this.state.datePosted===""){
    flag=false
    alert("Please select the publishing date")
  }

  return(flag)
}

  async save_to_dynamo(){

    const content = await this.editorInstance.save()
    // this.filterParagraph(content)

    let validData=this.validation(content)
    
    if(typeof this.state.tags==='string'){
      
      var tag = this.state.tags.split(',')
      tag.pop()
      this.setState({tags:tag})
    }

    var blg = {
      blogImageURL: this.state.blogImageURL,
      authorImageURL: this.state.authorImage,
      heading: this.state.heading,
      subHeading: this.state.subHeading,
      datePosted: this.state.datePosted,
      authorName: this.state.authorName,
      authorDescription: this.state.authorDescription,
      readTime: this.state.readTime,
      source: this.state.source,
      sourceUrl:this.state.sourceUrl,
      content: content,
      contentSummary:this.state.contentSummary,
      blogImageCredit: this.state.blogImageCredit,
      category: this.state.category,
      tags: this.state.tags,
      more:this.state.more,
      blogType:this.state.blogType,
      seo:this.state.seo,
      slug:this.state.slug,
      metaDescription:this.state.metaDescription,
      truncateContent:this.state.truncateContent,
    };
    
    if(validData){
      console.log("blog :",blg);
      axios({
        url: (apiBaseUrl20+'blog/update/'+this.state.resData.id), 
        method: 'POST',
        data: blg,
      }).then((response) => {
        if(response.status===200){
          alert("Blog Updated");
          window.location.reload()
        }
     });
    } 
    
  }
 

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-2"><BlogSidebar/></div>
        <div className="col-md-10">
      <div className = "container-fluid overflow-auto" style={{margin: "10px", height:'90vh',overflow:'scroll'}}>
        <div style={{margin: "10px"}}><h3>Update Blog : </h3></div>

          <div className="container-fluid well well-sm" >
        
        <div>

          <div class="input-group" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Title" style={{width:"20%"}}>Title</span>
            <input type="text" class="form-control" id="heading" placeholder="" name="heading" value={this.state.heading} style={{}} onChange={this.handleChanges}></input>
          </div><br/>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Title" style={{width:"20%"}}>Subtitle</span>
            <input type="text" class="form-control" name="subHeading" value={this.state.subHeading} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>

        <div class="input-group" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Content" style={{width:"20%"}}>Content</span>
            
            <div style={{border:"1px solid #ededed",marginTop:'2%',marginRight:'3%'}}>
              <div style={{height:'40%',paddingBottom:"0"}}>
                  {this.state.resData.content!==undefined?
                                    <EditorJs 
                                    tools={{
                                      image:  {
                                      class: Image,
                                      inlineToolbar:true,
                                      config: {
                                       uploader:{
                                       uploadByFile: async function(file){
                                          console.log(file);
                                          const id = uuidv1()
                                          const imageURL=s3BucketUrl+id+'.png';
                                          
                                         let fileBase = await toBase64(file);
                                         let result = await axios.post(
                                          apiBaseUrl20+"uploadEditorImage",
                                   {
                                       image:fileBase,
                                       imageName:fileBase.name,
                                       id:id,
                                       imageURL:imageURL
                                   })
                    
                                   return{
                                    success:1,
                                    file:{
                                      url:imageURL
                                    }
                                     }
                                     
                                          
                                        },
                                        
                                       }
                                      }
                                    },...EditorjsTool}} 
                                    data={this.state.resData.content}
                                    instanceRef={instance=>this.editorInstance=instance}
                                    ></EditorJs>                  
                  :""}

                </div>
              </div>

          </div><br/>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" style={{width:"20%"}}>Content Summary</span>
            <textarea type="text" class="form-control" name="contentSummary" value={this.state.contentSummary} style={{width:this.state.inpWidth,padding:"50px 20px"}} onChange={this.handleChanges}></textarea>
          </div><br/>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Content Source"span style={{width:"20%"}}>Content Source</span>
            <input type="text" class="form-control" name="source" value={this.state.source} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Content Source"span style={{width:"20%"}}> Source Url</span>
            <input type="text" class="form-control" name="sourceUrl" value={this.state.sourceUrl} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>
            {console.log('render cat and tag',this.state.category, this.state.tags)}
          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Category" style={{width:"20%"}}>Category</span>
            <select class="form-control" name="category" value={this.state.category} onChange={this.handleChanges}>
            {this.state.categoryArray.map((cat)=>(
            <option value={cat.id}>{cat.category}</option>
            ))}
            </select>
          </div><br/> 

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Tags" style={{width:"20%"}}>Tags</span>
            <input type="text" class="form-control" name="tags" value={this.state.tags} onChange={this.handleTags} placeholder="break tags with ," style={{width:this.state.inpWidth}} ></input>
          </div><br/>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Date of Publishing" style={{width:"20%"}}>Date of Publishing</span>
            <input type="date" class="form-control" name="datePosted" value={this.state.datePosted} placeholder="yyyy-mm-dd" min="2018-01-01" max="2030-12-31" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>


          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Read Time" style={{width:"20%"}}>Read Time</span>
            <input type="number" class="form-control" name="readTime" value={this.state.readTime} placeholder="minutes" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>

          {this.state.blogType==="orginal"?
          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Author" style={{width:"20%"}}>Author</span>
            <input type="text" class="form-control" name="authorName" value={this.state.authorName} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div>:''}<br/>

          {this.state.blogType==="orginal"?
          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Author Description" style={{width:"20%"}}>Author Description</span>
            <input type="text" class="form-control" name="authorDescription" value={this.state.authorDescription} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div>:''}<br/>

          {this.state.blogType==="orginal"?
          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Article Image Credit" style={{width:"20%"}}>Article Image Credit</span>
            <input type="text" class="form-control" name="blogImageCredit" value={this.state.blogImageCredit} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div>:''}<br/>

          <div className='well well-sm'>
          <h5> Add SEO </h5>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="SEO"span style={{width:"20%"}}>SEO</span>
            <input type="text" class="form-control" name="seo" value={this.state.seo} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Slug"span style={{width:"20%"}}>Slug</span>
            <input type="text" class="form-control" name="slug" value={this.state.slug} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Meta Description"span style={{width:"20%"}}>Meta Description</span>
            <input type="text" class="form-control" name="metaDescription" value={this.state.metaDescription} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>

          </div>

          <div class="well well-sm " id='more'>
            <h5>Add More :- </h5>
            {this.state.more.external.length>0?
            this.state.more.external.map((more,i)=>(
            <div>
                <div class="input-group mb-3" style={{width:"80%"}}>
                <span type="text" class="input-group-addon" style={{width:"20%"}}>Source URL</span>
                <input type="text" class="form-control" name="src" value={this.state.more.external[i].src} style={{width:this.state.inpWidth}} onChange={(e)=>this.handleOnChangeMore(e,i)}></input>
                </div><br/>

                <div class="input-group mb-3" style={{width:"80%"}}>
                    <span type="text" class="input-group-addon" style={{width:"20%"}}>URL</span>
                    <input type="text" class="form-control" name="url" value={this.state.more.external[i].url} style={{width:this.state.inpWidth}} onChange={(e)=>this.handleOnChangeMore(e,i)}></input>
                </div><br/>

                <div class="input-group mb-3" style={{width:"80%"}}>
                    <span type="text" class="input-group-addon" style={{width:"20%"}}>Name</span>
                    <input type="text" class="form-control" name="name" value={this.state.more.external[i].name} style={{width:this.state.inpWidth}} onChange={(e)=>this.handleOnChangeMore(e,i)}></input>
                </div><br/>
                </div>
            )):''}
            <button type='button' class="btn btn-default" style={{margin:'10px'}} id = '' onClick={()=>this.addMore()}>Add Url</button>

          </div>

        <div style={{overflow: "auto"}}>
          
        {this.state.blogType==="orginal"?
            <div class="col-md-4" style={{width: "30%"}}>
              <div class="thumbnail">
                {this.state.authorImage!==""?
                <img src={this.state.authorImage} alt="authorImagePreview" id="authorImagePreview" style={{width: "100%",}}/>
                :""}
                <div class="caption">
                  <span class=""> <p>Author Image</p>
                  <FileBase64 multiple={true} onDone={this.getAuthorFiles.bind(this)}/>
                  </span>
                </div>
              </div>
            </div>:''}

         </div>

         <Modal 
         isOpen={this.state.modalIsOpen}
         onRequestClose={this.closeModal}
         style={customStyles}
         >
          <div className='modal-close' onClick={this.closeModal}>&times;</div>
          <div style={{marginTop:'2%'}}>
            <h4 style={{marginTop:"2%"}}>Select The Image You Want Show </h4>
          {this.state.imageArray.map((image)=>
            
              <img className="blogImageArray" onClick={()=>this.selectImage(image)} src={image}/>
            
          )}
          <div className="article-style ">
          <div className=''>
            <h4 className="resourcecategory col-md-12 col-lg-12"></h4>
            <div className='col-md-9 col-lg-9'>
              <h4 className="marginBlogTop">{this.state.heading}</h4>
              <Truncate line={2} ellipsis={<span>...<p style={{color:"#1d1dbc"}}>Read More</p></span>}>
                <p className="marginBlogTop">
                  {this.state.truncateContent}
                </p>
              </Truncate>
              <div className="marginBlogTop"> Source: {this.state.source} </div>
              <div className="marginBlogTop">{this.state.datePosted} * {this.state.readTime} mins read </div>
            </div>
            
            <div className="col-md-2 col-lg-2">
              <img style={{width:'12vw',height:"14vh"}} src={this.state.blogImageURL}></img>
            </div>
          </div>
          </div>

          <div ClassName="col-md-4" style={{width : "80%", position:"relative", height:"90px"}}>
            <button type='button' class="btn btn-primary bottomright" style={{margin:'10px'}} id = 'save' onClick={this.save_to_dynamo}>Update Blog</button>
          </div>
          </div>
         </Modal>

          
      
          <div ClassName="col-md-4" style={{width : "80%", position:"relative", height:"90px"}}>
            <button type='button' class="btn btn-primary bottomright" style={{margin:'10px'}} id='save' onClick={this.finalChanges}>Update Blog</button>
          </div>
          
          
          
        </div>
        </div>

        </div>
        </div>
      </div>
      </div>
    )
  }
}

