import React from 'react';
import 'react-quill/dist/quill.snow.css'; // ES6
import axios from 'axios';
import FileBase64 from 'react-file-base64';
import EditorJs from 'react-editor-js';
import {EditorjsTool} from '../Editor/tool'
import {v1 as uuidv1} from 'uuid';
import Image from "@editorjs/image";
import Modal from 'react-modal';
import Truncate from 'react-truncate';
import OpportunityBlogSidebar from './OpportunityBlogSidebar';
import { apiBaseUrl20, s3BucketUrl } from '../../components/apiUtils';


const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    zIndex                : "1",
    width                 : "80%",
    height                : "68vh",
    borderRadius          : "5px",
    boxShadow             : "0px 0px 9px 2px #c5c3c3",
    border                : "none"
  }
};

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export default class AddOpportunityBlog extends React.Component {
  constructor(props) {
    super(props)

    var allText = 'Hi write something';
    this.state = {
      heading:'',
      subHeading:'',
      authorName:'',
      authorDescription:'',
      datePosted:'',
      dateModified:'',
      readTime:'',
      source:'',
      sourceUrl:'',
      content:'',
      blogImageCredit:'',
      tags:'',
      text: allText,
      inpWidth:'100%',
      encodedBlogImage: "",
      encodedAuthorImage: "",
      u : 0,
      c : 0,
      headings : "",
      videoUrl:'',
      blogType:'',
      flag:false,
      imageArray:[],
      modalIsOpen:false,
      blogImageURL:'',
      truncateContent:'',
      categoryArray:[],
      authorImage:"",
      category:'',
      contentSummary:'',
     }
    this.handleChange = this.handleChange.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    this.handleTags = this.handleTags.bind(this);
    this.save_to_dynamo = this.save_to_dynamo.bind(this);
    this.encodeImage = this.encodeImage.bind(this);
    this.incrementu = this.incrementu.bind(this);
    this.addUrl = this.addUrl.bind(this);
    this.addContent = this.addContent.bind(this);
    this.incrementc = this.incrementc.bind(this);
    this.onChange = this.onChange.bind(this)
    this.finalChanges = this.finalChanges.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  componentDidMount(){
    this.fetchAllCategory()
}

fetchAllCategory(){
  let temp_data=[]
    return axios({       
        url: apiBaseUrl20+'blogCategory/list',
        method: 'GET',
        crossDomain: true,
        responseType: 'json', 
      }).then((response) => {
          for(var i=0;i<response.data.length;i++){
              if(response.data[i].learningCategory==='opportunity-growth'){
                temp_data.push(response.data[i])
            }
        }
        this.setState({categoryArray:temp_data,category:temp_data[0].id})  
     });  
}

  async finalChanges(){
    const content = await this.editorInstance.save()
    this.filterParagraph(content)
    if(this.state.imageArray.length>1){
      this.setState({modalIsOpen:true})
    }
    else{
      this.save_to_dynamo()
    }
    this.setState({blogImageURL:this.state.imageArray[0]})
  }

  closeModal(){
    this.setState({modalIsOpen:false,})
  }

  onChange(e) {
    let value = e.target.value
    let name = e.target.name

    this.setState({[name]:value,flag:true});
  }

  getAuthorFiles(files){
    this.setState({selectedFile: files[0]})
    this.fileUploadHandler(this,'authorImage')
  }


  fileUploadHandler = (that, docType) => {
    const id = uuidv1()
    const imageURL=s3BucketUrl+id+'.png';
    that.setState({ loading: true });
    axios.post(apiBaseUrl20+"uploadEditorImage",
      {
        "image": that.state.selectedFile.base64,
        "imageName": that.state.selectedFile.name,
        "id":id,
        "imageURL":imageURL
      })
      .then(function (response) {
        if (response.status === 200) {
          console.log("data--",response.data)
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: imageURL });
       
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleChanges(e){
    let target = e.target;
    let name = target.name;
  
    this.setState({[name]:target.value});
  }

  handleTags(e){
    let target = e.target
    // let name = target.name
    var tag = target.value.split(',')
    this.setState({tags: tag})
  }

  handleChange(value) {
    this.setState({ text: value })
    // document.getElementById('save').innerHTML = 'Save';
  }
// template-engine-images.s3.amazonaws.com/files/quill.txt


  // handleFileChosen(file) { 
  //   let fileReader;
  //   fileReader = new FileReader();
  //   fileReader.readAsText(file);
  //   fileReader.onloadend = function(e){
  //     var content = 'text';
  //     content = fileReader.result;
  //     console.log('handleFileChosen : ', content);
  //     this.setState({ text: content })
  //     // this.handleChange(content)
  //   }.bind(this);
  // };


  // upload_file(){
  //   var text = this.state.text;
  //   var encodedText =  btoa(text);
  //   console.log('encodedText :', encodedText);
  //
  //   axios({
  //     url: 'https://gwqzi2xcl8.execute-api.us-east-1.amazonaws.com/dev/uploadFiletos3', //your url
  //     method: 'POST',
  //     data:{file:encodedText, fileName:'quill.txt'}
  //   }).then((response) => {
  //     // this.handleFileChosen(response.data);
  //     console.log('resonse_data_upload :', response.data)
  //     document.getElementById('save').innerHTML = 'Saved <span class="fas fa-check-circle"></span>';
  //  });
  //
  // }


  encodeImage(imageid){
    var image = document.getElementById(imageid).files[0];
    // console.log(image);
    // document.getElementById(imageid + "Label").className = "custom-file-label selected";
    // document.getElementById(imageid + "Label").innerHTML = image.name;
    var reader  = new FileReader();
    var encodedImage ="";
    reader.addEventListener("load", function (e) {
      encodedImage = e.target.result.replace(/data:image\/(png|jpeg|jpg);base64,/, '');
      if (imageid=="blogImage") {
        this.setState({ encodedBlogImage:  encodedImage});
        document.getElementById('blogImagePreview').src=e.target.result;
        document.getElementById('blogImagePreview').style.display="block";
      }else if (imageid=="authorImage") {
        this.setState({ encodedAuthorImage:  encodedImage});
        document.getElementById('authorImagePreview').src=e.target.result;
        document.getElementById('authorImagePreview').style.display="block";
      }
      // return encodedImage;
    }.bind(this), false);
    reader.readAsDataURL(image);
  }


  uploadByFile(file) {

    let fd = new FormData();
    fd.append('file',file);
  }

  addImage(imageURL){
    this.setState({imageArray:this.state.imageArray.push(imageURL)})
  }

selectImage(image){
  this.setState({blogImageURL:image})
}

filterParagraph(jsonContent){
  let content=""
  let imageArray=[]
  let block = jsonContent.blocks
  for(var i=0;i<block.length;i++){
    switch(block[i].type){
      case "paragraph":
        content =  block[i].data.text 
        break;
      case "image":
        imageArray.push(block[i].data.file.url)
    }
  }
  this.setState({truncateContent:content,imageArray:imageArray})
}

validation(content){
  let flag=true

  if(this.state.heading.trim()===''){
    flag=false
    alert("Please add the heading")
  }
  else if(content.blocks.length===0){
    flag=false
    alert("Please add the content")
  }
  else if(this.state.category===""){
    flag=false
    alert("Please select the category")
  }
  else if(this.state.datePosted===""){
    flag=false
    alert("Please select the publishing date")
  }

  return(flag)
}

  async save_to_dynamo(){
    // console.log("encodedBlogImage  :", this.state.encodedBlogImage);
    // console.log("encodedAuthorImage  :", this.state.encodedAuthorImage);
    var external = [];
    var internal = [];
    var sel = "";
    for (var i = 0; i < this.state.u; i++) {
      external[i] = {
        src : document.getElementById('src_' + i).value,
        url : document.getElementById('url_' + i).value,
        name : document.getElementById('srcName_' + i).value,
      }
    }

    for (i = 0; i < this.state.c; i++) {
      sel = document.getElementById('head_' + i);
      internal[i] = {
        heading: sel.options[sel.selectedIndex].text,
        id: sel.options[sel.selectedIndex].id,
      }
    }

    var more = {
      external : external,
      internal : internal
    }

    const content = await this.editorInstance.save()
    // this.filterParagraph(content)

    let validData=this.validation(content)

    var blg = {
      blogImageURL: this.state.blogImageURL,
      authorImageURL: this.state.authorImage,
      heading: this.state.heading,
      subHeading: this.state.subHeading,
      datePosted: this.state.datePosted,
      dateModified: "0000-00-00",
      authorName: this.state.authorName,
      authorDescription: this.state.authorDescription,
      readTime: this.state.readTime,
      source: this.state.source,
      sourceUrl:this.state.sourceUrl,
      content: content,
      contentSummary:this.state.contentSummary,
      blogImageCredit: this.state.blogImageCredit,
      category: this.state.category,
      tags: this.state.tags,
      more:more,
      blogType:this.state.blogType,
      seo:this.state.seo,
      slug:this.state.slug,
      metaDescription:this.state.metaDescription,
      truncateContent:this.state.truncateContent,
      learningCategory:"opportunity-growth"
    };
   

    if(validData){
      
      axios({
        url: apiBaseUrl20+'blog/add', //your url
        method: 'POST',
        data: blg,
      }).then((response) => {
        if(response.status===200){
          alert("Blog Added");
          window.location.reload()
        }
     });
    }
    
    
  }

  incrementu() {
    // increments variable for no. of urls in MORE

    var inc = this.state.u + 1;
    this.setState({
      u : inc
    });
  }

  incrementc() {
    // increments variable for no. of website content in MORE

    var inc = this.state.c + 1;
    this.setState({
      c : inc
    });
  }

  addUrl(){
    // adds a URL entry in MORE
    
    var out_div = document.createElement('div');
    var spn = document.createElement('span');
    var src = document.createElement("INPUT");
    var url = document.createElement("INPUT");
    var srcName = document.createElement("INPUT");
    var br = document.createElement("br");
    this.incrementu();
    var i = this.state.u;

    out_div.setAttribute("class","input-group mb-3");
    out_div.style.width = '80%';
    // out_div.setAttribute("id", "urldiv_" + i);

    spn.setAttribute("type", "text");
    spn.setAttribute("class", "input-group-addon");
    spn.style.width = '20%';
    spn.innerHTML = "Source Url";
    // spn.setAttribute("id", "lat_" + i);

    src.setAttribute("type", "text");
    src.setAttribute("class", "form-control");
    src.setAttribute("placeholder", "Source");
    src.setAttribute("id", "src_" + i);

    url.setAttribute("type", "text");
    url.setAttribute("class", "form-control");
    url.setAttribute("placeholder", "Url");
    url.setAttribute("id", "url_" + i);

    srcName.setAttribute("type", "text");
    srcName.setAttribute("class", "form-control");
    srcName.setAttribute("placeholder", "Name");
    srcName.setAttribute("id", "srcName_" + i);

    out_div.appendChild(spn);
    out_div.appendChild(src);
    out_div.appendChild(url);
    out_div.appendChild(srcName);
    document.getElementById("more").appendChild(out_div);
    document.getElementById("more").appendChild(br);

  }

  addContent(){
    // adds a WEBSITE CONTENT entry in MORE

    var out_div = document.createElement('div');
    var spn = document.createElement('span');
    var src = document.createElement("select");
    var br = document.createElement("br");
    this.incrementc();
    var i = this.state.c;

    out_div.setAttribute("class","input-group mb-3");
    out_div.style.width = '80%';
    out_div.setAttribute("id", "web_" + i);

    spn.setAttribute("type", "text");
    spn.setAttribute("class", "input-group-addon");
    spn.style.width = '20%';
    spn.innerHTML = "Heading";

    // src.setAttribute("type", "text");
    src.setAttribute("class", "form-control");
    // src.setAttribute("placeholder", "Heading");
    src.setAttribute("id", "head_" + i);
    src.innerHTML = this.state.headings;

    out_div.appendChild(spn);
    out_div.appendChild(src);
    document.getElementById("more").appendChild(out_div);
    document.getElementById("more").appendChild(br);

  }

  

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-2"><OpportunityBlogSidebar/></div>
        <div className="col-md-10">
      <div className = "container-fluid overflow-auto" style={{margin: "10px", height:'90vh',overflow:'scroll'}}>
        <div style={{margin: "10px"}}><h3>Add Opportunity & Growth Blog : </h3></div>

          <div className="container-fluid well well-sm" >

          <div>
          <spam style={{paddingLeft:'27%'}}> Original </spam>
          <input type="radio" value="orginal" name='blogType' onChange={this.onChange}/>
          <spam style={{paddingLeft:'20%'}}> Curated </spam>
          <input type="radio" value="curated" name='blogType' onChange={this.onChange}/>
        </div>
        {this.state.flag===true?
        <div>

          <div class="input-group" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Title" style={{width:"20%"}}>Title</span>
            <input type="text" class="form-control" id="heading" placeholder="" name="heading" value={this.state.heading} style={{}} onChange={this.handleChanges}></input>
          </div><br/>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Title" style={{width:"20%"}}>Subtitle</span>
            <input type="text" class="form-control" name="subHeading" value={this.state.subHeading} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>

        <div class="input-group" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Content" style={{width:"20%"}}>Content</span>
            
            <div style={{border:"1px solid #ededed",marginTop:'2%',marginRight:'3%'}}>
              <div style={{height:'40%',paddingBottom:"0"}}>
                
                <EditorJs 
                tools={{
                  image:  {
                  class: Image,
                  inlineToolbar:true,
                  config: {
                   uploader:{
                   uploadByFile: async function(file){
                      
                      const id = uuidv1()
                      const imageURL=s3BucketUrl+id+'.png';
                      
                     let fileBase = await toBase64(file);
                     let result = await axios.post(
                      apiBaseUrl20+"uploadEditorImage",
               {
                   image:fileBase,
                   imageName:fileBase.name,
                   id:id,
                   imageURL:imageURL
               })

               return{
                success:1,
                file:{
                  url:imageURL
                }
                 }
                 
                      
                    },
                    
                   }
                  }
                },...EditorjsTool}} 
                instanceRef={instance=>this.editorInstance=instance}
                ></EditorJs>
                </div>
              </div>

          </div><br/>

          <div class="input-group mb-3" style={{color:"black",width:"80%"}}>
            <span type="text" class="input-group-addon" span style={{width:"20%"}}>Content Summary</span>
            <textarea type="text" class="form-control" name="contentSummary" value={this.state.contentSummary} placeholder="Please enter summary here..." style={{width:this.state.inpWidth,padding:"50px 20px"}} onChange={this.handleChanges}></textarea>
          </div><br/>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Content Source"span style={{width:"20%"}}>Content Source</span>
            <input type="text" class="form-control" name="source" value={this.state.source} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Content Source"span style={{width:"20%"}}> Source Url</span>
            <input type="text" class="form-control" name="sourceUrl" value={this.state.sourceUrl} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Category" style={{width:"20%"}}>Category</span>
            <select class="form-control" name="category" onChange={this.handleChanges}>
            {this.state.categoryArray.map((cat)=>(
            <option value={cat.id}>{cat.category}</option>
            ))}
            </select>
          </div><br/> 

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Tags" style={{width:"20%"}}>Tags</span>
            <input type="text" class="form-control" name="tags" value={this.state.tags} onChange={this.handleTags} placeholder="break tags with ," style={{width:this.state.inpWidth}} ></input>
          </div><br/>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Date of Publishing" style={{width:"20%"}}>Date of Publishing</span>
            <input type="date" class="form-control" name="datePosted" value={this.state.datePosted} placeholder="yyyy-mm-dd" min="2018-01-01" max="2030-12-31" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Read Time" style={{width:"20%"}}>Read Time</span>
            <input type="number" class="form-control" name="readTime" value={this.state.readTime} placeholder="minutes" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>

          {this.state.blogType==="orginal"?
          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Author" style={{width:"20%"}}>Author</span>
            <input type="text" class="form-control" name="authorName" value={this.state.authorName} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div>:''}<br/>

          {this.state.blogType==="orginal"?
          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Author Description" style={{width:"20%"}}>Author Description</span>
            <input type="text" class="form-control" name="authorDescription" value={this.state.authorDescription} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div>:''}<br/>

          {/* {this.state.blogType==="orginal"?
          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Article Image Credit" style={{width:"20%"}}>Article Image Credit</span>
            <input type="text" class="form-control" name="blogImageCredit" value={this.state.blogImageCredit} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div>:''}<br/> */}


          <div className='well well-sm'>
          <h5> Add SEO </h5>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="SEO"span style={{width:"20%"}}>SEO</span>
            <input type="text" class="form-control" name="seo" value={this.state.seo} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Slug"span style={{width:"20%"}}>Slug</span>
            <input type="text" class="form-control" name="slug" value={this.state.slug} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Meta Description"span style={{width:"20%"}}>Meta Description</span>
            <input type="text" class="form-control" name="metaDescription" value={this.state.metaDescription} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>

          </div>

          <div class="well well-sm " id='more'>
            <h5>Add More :- </h5>

            <button type='button' class="btn btn-default" style={{margin:'10px'}} id = '' onClick={this.addUrl}>Add Url</button>

            

          </div>

        <div style={{overflow: "auto"}}>
          
        {this.state.blogType==="orginal"?
            <div class="col-md-4" style={{width: "30%"}}>
              <div class="thumbnail">
                {this.state.authorImage!==""?
                <img src={this.state.authorImage} alt="authorImagePreview" id="authorImagePreview" style={{width: "100%",}}/>
                :""}
                <div class="caption">
                  <span class=""> <p>Author Image</p>
                  <FileBase64 multiple={true} onDone={this.getAuthorFiles.bind(this)}/>
                  </span>
                </div>
              </div>
            </div>:''}

     
          </div>

         <Modal 
         isOpen={this.state.modalIsOpen}
         onRequestClose={this.closeModal}
         style={customStyles}
         >
          <div className='modal-close' onClick={this.closeModal}>&times;</div>
          <div style={{marginTop:'2%'}}>
            <h4 style={{marginTop:"2%"}}>Select The Image You Want Show </h4>
          {this.state.imageArray.map((image)=>
            
              <img className="blogImageArray" onClick={()=>this.selectImage(image)} src={image}/>
            
          )}
          <div className="article-style ">
          <div className=''>
            <h4 className="resourcecategory col-md-12 col-lg-12"></h4>
            <div className='col-md-9 col-lg-9'>
              <h4 className="marginBlogTop">{this.state.heading}</h4>
              <Truncate line={2} ellipsis={<span>...<p style={{color:"#1d1dbc"}}>Read More</p></span>}>
                <p className="marginBlogTop">
                  {this.state.truncateContent}
                </p>
              </Truncate>
              <div className="marginBlogTop"> Source: {this.state.source} </div>
              <div className="marginBlogTop">{this.state.datePosted} * {this.state.readTime} mins read </div>
            </div>
            
            <div className="col-md-2 col-lg-2">
              <img style={{width:'12vw',height:"14vh"}} src={this.state.blogImageURL}></img>
            </div>
          </div>
          </div>

          <div ClassName="col-md-4" style={{width : "80%", position:"relative", height:"90px"}}>
            <button type='button' class="btn btn-primary bottomright" style={{margin:'10px'}} id = 'save' onClick={this.save_to_dynamo}>Post Blog</button>
          </div>
          </div>
         </Modal>

          
       
          <div ClassName="col-md-4" style={{width : "80%", position:"relative", height:"90px"}}>
            <button type='button' class="btn btn-primary bottomright" style={{margin:'10px'}} id='save' onClick={this.finalChanges}>Add Blog</button>
          </div>
          
          
          
        </div>:""}
        </div>

        </div>
        </div>
      </div>
      </div>
    )
  }
}
