import React, { Component } from "react";
import "../../style/panel.css";
import { Link } from "react-router-dom";
import BreadCrumbs from "../BreadCrumbs";
import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1, apiBaseUrl5 } from "../apiUtils";
import { apiBaseUrl2 } from "../apiUtils";
import FileBase64 from "react-file-base64";
import Modal from "react-modal";
import { ChatFeed, Message } from "react-chat-ui";
import UserCommunication from "../common/UserCommunication";

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import _ from "underscore";
let context = null;
const data = [];
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px ",
    position: "Absolute",
    zIndex: "30000",
    backgroundColor: "none",
  },
};

export default class pageLetsgetstarted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      subject: "",
      message: "",
      attachment: "NA",
      thread: [],
      sendTo: "",
      documents: [],
      proposals: [],
      mails: [],
      entry: {},
      modalIsOpen: false,
      docType: "MAIL",
      sliderState: 1, //sta//state for storing the response from the backend
    };
    context = this;
    this.openModal = this.openModal.bind(this);

    this.closeModal = this.closeModal.bind(this);
  }

  changeSliderState(orderNumber) {
    this.setState({ sliderState: orderNumber });
  }
  //function for changing the status button on click to open or close
  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);

    console.log(this.state);
  };

  openModal(type) {
    this.changeDocType(type);
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  componentDidMount() {
    const id = this.props.match.params.id;

    this.fetchQueryById(this, id);
    this.fetchThreadById(this, id);
    this.markAsRead(this, id);
  }

  markAsRead(that, id) {
    axios
      .get(apiBaseUrl2 + "businusquery/read/" + id)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  }

  
  updateStatus(that,id,status){

    axios.put(apiBaseUrl5+"businusquery/query/"+id,{status:status})
    .then(function (response) {
      console.log(response.data);
      
      
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }
  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(apiBaseUrl5 + "businusquery/querybyId/" + id)
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
        that.fetchRegisteredUsers(that, response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fetchRegisteredUsers(that, query) {
    axios
      .get(apiBaseUrl2 + "businus/users")
      .then(function (response) {
        console.log(response.data);
        that.setState({ users: response.data.Users });

        var isExists = _.filter(response.data.Users,function(usr){
          
          return usr. customerEmail === query.email;

      }).length;
        query["isRegistered"] = isExists > 0 ? true : false;

        that.setState({ entry: query });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fileUploadHandler = (that, docType) => {
    that.setState({ loading: true });
    debugger;
    axios
      .post(apiBaseUrl2 + "businusquery/uploadAttachment", {
        uploadImage: that.state.selectedFile.base64,

        imageName: that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({
            [docType]:
              "https://businus-files-live.s3.amazonaws.com/mailAttachments/" +
              that.state.selectedFile.name,
          });

          //that.successNotification("Uploaded Succesfully");
        } else {
          //   that.errorNotification("Some Error Has Occured");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  fetchThreadById(that, id) {
    axios
      .get(apiBaseUrl2 + "businusquery/listmail/" + id)
      .then(function (response) {
        debugger;
        console.log(response.data);
        var threads =
          response.data == "" ? [] : _.sortBy(response.data, "date").reverse();
        that.setState({ thread: threads });

        var documents = _.where(threads, { docType: "Document" });
        var proposals = _.where(threads, { docType: "Proposal" });
        var mails = _.where(threads, { docType: "Mail" });
        that.setState({ documents });
        that.setState({ proposals });
        that.setState({ mails });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getFiles(files) {
    this.setState({ selectedFile: files[0] });
  }

  getFormattedDate(millis) {
    return new Date(millis).toDateString();
  }

  toggleOpen = (status) => {
    if (status == undefined || status == "open") {
      status = "close";
    } else {
      status = "open";
    }
    //(this.setState({ entry: [...this.state.entry,{status:status} ] });)
    var newEntry = this.state.entry;
    newEntry.status = status;
    this.setState({ entry: newEntry });
    context.updateStatus(context, this.state.entry.id, status);
  };

  changeDocType(type) {
    this.setState({ docType: type });
  }

  sendMail() {
    var query = context.state.entry;
    axios
      .post(apiBaseUrl2 + "businusquery/send", {
        name: query.name,
        email: query.email,
        message: context.state.message,
        company: query.company,
        toe: query.toe,
        subject: context.state.subject,
        attachment: context.state.attachment,
        attachmentName: context.state.attachmentName,
        queryId: query.id,
        from: "client",
        recordType: "MAIL",
        docType: context.state.docType,
        sendTo: context.state.senderEmail,
      })
      .then(function (response) {
        debugger;
        alert("SENT");
        context.fetchThreadById(context, query.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    localStorage.setItem("MAILURL", url);
  }

  //updating the status button

  render() {
    const { sliderState } = this.state;
    const active = " active-button";
    const deactive = "";
    return (
      <div className="Div">
        <div className="row breadCrumb-row" style={{ marginLeft: "0px" }}>
          <div className="col-md-10">
            <BreadCrumbs />
          </div>
        </div>
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
          <div className="dedicated-page2">
            <div className=" ">
              <div className="row-resource-head">
                <h2 className="resource-head">
                  Query from {this.state.entry.title} {this.state.entry.name}{" "}
                </h2>
              </div>
              <div className="row content-row">
                <div className="col-md-5">
                  <h2 className="head-mail">
                    {" "}
                    {this.state.entry.title} {this.state.entry.name}
                  </h2>
                  <h2 className="head-mail">Email: {this.state.entry.email}</h2>
                  <h2 className="head-mail">
                    Company: {this.state.entry.company}
                  </h2>
                  <h2 className="head-mail">Mob: {this.state.entry.phone}</h2>
                </div>

                <div className="col-md-5">
                  <div className="content-div">
                    <h2 className="head-mail">
                      Employee: {this.state.entry.employee}
                    </h2>
                    <h2 className="head-mail">
                      State: {this.state.entry.state}
                    </h2>
                    <h2 className="head-mail">
                      City: {this.state.entry.cityName}
                    </h2>
                    <h2 className="head-mail">
                      Query: {this.state.entry.message}
                    </h2>
                  </div>
                </div>

                <div className="col-md-2">
                <div className="head-button">   <button  /*dyanamic classname*/
  className={"badge "+
  ( this.state.entry.status=="close" ? "red" : "")
  }
  onClick={() => context.toggleOpen(this.state.entry.status)}
  id="status"
  >
  
  {this.state.entry.status==undefined ? "open" : this.state.entry.status}
  </button></div>
                </div>
              </div>
            </div>

            {this.state.id != "" ? (
              <UserCommunication entry={this.state.entry} id={this.state.id} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
