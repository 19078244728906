import React, { Component } from "react";
import "../../style/panel.css";
import { Route } from "react-router-dom";

import KnowledgeHubSidebar from "../Blog/KnowledgeHubSidebar";
import AddAct from "../CompanyLaw/AddAct";
import AddBlog from "../Blog/AddBlog";
import ListActs from "../CompanyLaw/ListActs";
import ListBlogs from "../Blog/ListBlogs";
import listgst from "../GST/Listgst";
import listcustom from "../Custom/Listcustom";
import gst from "../GST/Addgst";
import custom from "../Custom/Addcustom";
import ActDashboard from "../CompanyLaw/ActDashboard";
import GSTDashboard from "../GST/GstDashboard";
import CustomDashboard from "../Custom/CustomDashboard";
import AddBlogCategory from "../Blog/AddBlogCategory";
import ListBlogCategory from "../Blog/ListBlogCategory";
import RearrangeDashboardGST from "../GST/RearrangeDashboardGST";
import ColumnOneGST from "../GST/ColumnOneGST";
import KnowledgeHubDashboard from "../Blog/knowledgeHubDashboard";
import BlogDashboard from "../Blog/BlogDashboard";
import LegalDashboard from "./LegalDashboard";
import AddActCategory from "../CompanyLaw/AddActCategory";
import AddGstCategory from "../GST/AddGstCategory";
import listGstCategory from "../GST/ListGstCategory";
import EximBlogDashboard from "../EXIM/EximBlogDashboard";
import AddEximBlogCategory from "../EXIM/AddEximBlogCategory";
import AddEximBlog from "../EXIM/AddEximBlog";
import ListEximBlog from "../EXIM/ListEximBlog";
import ListEximBlogCategory from "../EXIM/ListEximBlogCategory";
import ListWealthBlogCategory from "../Wealth/ListWealthBlogCategory";
import WealthBlogDashboard from "../Wealth/WealthBlogDashboard";
import AddWealthBlog from "../Wealth/AddWealthBlog";
import ListWealthBlog from "../Wealth/ListWealthBlog";
import AddWealthBlogCategory from "../Wealth/AddWealthBlogCategory";
import OpportunityBlogDashboard from "../Opportunity/OpportunityBlogDashboard";
import AddOpportunityBlog from "../Opportunity/AddOpportunityBlog";
import ListOpportunityBlog from "../Opportunity/ListOpportunityBlog";
import AddOpportunityBlogCategory from "../Opportunity/AddOpportunityBlogCategory";
import ListOpportunityBlogCategory from "../Opportunity/ListOpportunityBlogCategory";
import listActCategory from "../CompanyLaw/ListActCategory";
import AddCustomCategory from "../Custom/AddCustomCategory";
import listCustomCategory from "../Custom/ListCustomCategory";
import AddGuide from "../Guides/AddGuideName";
import AddGuideBlog from "../Guides/AddGuideBlog";
import ListGuideName from "../Guides/ListGuideName";
import GuideBlogDashoard from "../Guides/GuideBlogDashboard";
import AddGuideChapter from "../Guides/AddGuideChapters";
import ListGuideChapter from "../Guides/ListGuideChapter";
import EditGuide from "../Guides/EditGuideName";
import EditGuideChapter from "../Guides/EditGuideChapter";
import ListGuideBlog from "../Guides/ListGuideBlog";
import EditGuideBlog from "../Guides/EditGuideBlog";
import KhubHomepage from "../Homepage/Khub_Homepage";
import Khub_AddSlider from "../Homepage/AddSlider";
import Khub_ViewSlider from "../Homepage/ViewSlider";
import Khub_PageSlider from "../Homepage/PageSlider";
import Khub_EditSlider from "../Homepage/EditSlider";
import Khub_LegalBanner from "../Blog/LegalBanner";
import Khub_EximBanner from "../EXIM/EximBanner";
import Khub_WealthBanner from "../Wealth/WealthBanner";
import Khub_OpportunityBanner from "../Opportunity/OpportunityBanner";
import PageBlogCategory from "./BlogCategory/PageBlogCategory";
import EditBlogCategory from "./BlogCategory/EditBlogCategory";
import PageBlog from "./Blog/PageBlog";
import EditBlog from "./Blog/EditBlog";
import ListStayUpdated from "../StayUpdated/listStayUpdated";
import PageStayUpdated from "../StayUpdated/pageStayUpdated";
import EximDashboard from "../EXIM/EximDashboard";
import OpportunityDashboard from "../Opportunity/OpportunityDashboard";
import WealthDashboard from "../Wealth/WealthDashboard";
import ListLegalBookmark from "../Blog/bookmark";
import ListOpportunityBookmark from "../Opportunity/OpportunityBookmark";
import ListWealthBookmark from "../Wealth/WealthBookmark";


const data = [];
export default class KnowledgehubMainApp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      opened: []
    };
  }
 

  render() {
    return (
      <div className="mainDiv_blg col-md-12">
        <div className="row">
          {/* <div className="col-md-2">
            <KnowledgeHubSidebar />
          </div> */}
          <div className="">
            <div className="container-fluid section">
              <Route exact path="/knowledgeHub/businus-school/legal" component={KnowledgeHubDashboard}/>
              <Route exact path="/knowledgeHub" component={LegalDashboard}/>
              <Route exact path="/knowledgeHub/businus-school" component={LegalDashboard}/>
              <Route exact path="/knowledgeHub/businus-school/legal-finance/view-bookmarks" component={ListLegalBookmark}/>

              <Route exact path="/knowledgeHub/businus-school/homepage" component={KhubHomepage} />
              <Route exact path="/knowledgeHub/businus-school/homepage/add-horizontal-slider" component={Khub_AddSlider} />
              <Route exact path="/knowledgeHub/businus-school/homepage/add-vertical-slider" component={Khub_AddSlider} />
              <Route exact path="/knowledgeHub/businus-school/homepage/view-horizontal-slider" component={Khub_ViewSlider} />
              <Route exact path="/knowledgeHub/businus-school/homepage/view-vertical-slider" component={Khub_ViewSlider} />
              <Route exact path="/knowledgeHub/businus-school/homepage/view-horizontal-slider/:id" component={Khub_PageSlider} />
              <Route exact path="/knowledgeHub/businus-school/homepage/view-vertical-slider/:id" component={Khub_PageSlider} />
              <Route exact path="/knowledgeHub/businus-school/homepage/edit-horizontal-slider/:id" component={Khub_EditSlider} />
              <Route exact path="/knowledgeHub/businus-school/homepage/edit-vertical-slider/:id" component={Khub_EditSlider} />
              <Route exact path="/knowledgeHub/businus-school/homepage/view-stay-updated" component={ListStayUpdated} />
              <Route exact path="/knowledgeHub/businus-school/homepage/view-stay-updated/:id" component={PageStayUpdated} />
              

              <Route exact path="/knowledgeHub/businus-school/legal/article" component={BlogDashboard} />
              <Route exact path="/knowledgeHub/add-article" component={AddBlog} />
              <Route exact path="/knowledgeHub/view-article" component={ListBlogs} />
              <Route exact path="/knowledgeHub/view-article/:id" component={PageBlog} />
              <Route exact path="/knowledgeHub/edit-article/:id" component={EditBlog} />
              <Route exact path="/knowledgeHub/add-blogCategory" component={AddBlogCategory} />
              <Route exact path="/knowledgeHub/view-blogCategory" component={ListBlogCategory} />
              <Route exact path="/knowledgeHub/view-blogCategory/:id" component={PageBlogCategory} />
              <Route exact path="/knowledgeHub/edit-blogCategory/:id" component={EditBlogCategory} />
              <Route exact path="/knowledgeHub/businus-school/legal/addBanner" component={Khub_LegalBanner} />


              <Route exact path="/knowledgeHub/businus-school/exim" component={EximDashboard} />
              <Route exact path="/knowledgeHub/businus-school/exim/article" component={EximBlogDashboard} />
              <Route exact path="/knowledgeHub/add/exim-article" component={AddEximBlog} />
              <Route exact path="/knowledgeHub/view/exim-article" component={ListEximBlog} />
              <Route exact path="/knowledgeHub/add/exim-blogCategory" component={AddEximBlogCategory} />
              <Route exact path="/knowledgeHub/view/exim-blogCategory" component={ListEximBlogCategory} />
              <Route exact path="/knowledgeHub/businus-school/exim/addBanner" component={Khub_EximBanner} />
              <Route exact path="/knowledgeHub/businus-school/exim/view-bookmarks" component={ListLegalBookmark}/>


              <Route exact path="/knowledgeHub/businus-school/wealth" component={WealthDashboard} />
              <Route exact path="/knowledgeHub/businus-school/wealth/article" component={WealthBlogDashboard} />
              <Route exact path="/knowledgeHub/add/wealth-article" component={AddWealthBlog} />
              <Route exact path="/knowledgeHub/view/wealth-article" component={ListWealthBlog} />
              <Route exact path="/knowledgeHub/add/wealth-blogCategory" component={AddWealthBlogCategory} />
              <Route exact path="/knowledgeHub/view/wealth-blogCategory" component={ListWealthBlogCategory} />
              <Route exact path="/knowledgeHub/businus-school/wealth/addBanner" component={Khub_WealthBanner} />
              <Route exact path="/knowledgeHub/businus-school/stories-insights/view-bookmarks" component={ListWealthBookmark}/>


              <Route exact path="/knowledgeHub/businus-school/opportunity" component={OpportunityDashboard} />
              <Route exact path="/knowledgeHub/businus-school/opportunity/article" component={OpportunityBlogDashboard} />
              <Route exact path="/knowledgeHub/add/opportunity-article" component={AddOpportunityBlog} />
              <Route exact path="/knowledgeHub/view/opportunity-article" component={ListOpportunityBlog} />
              <Route exact path="/knowledgeHub/add/opportunity-blogCategory" component={AddOpportunityBlogCategory} />
              <Route exact path="/knowledgeHub/view/opportunity-blogCategory" component={ListOpportunityBlogCategory} />
              <Route exact path="/knowledgeHub/businus-school/opportunity/addBanner" component={Khub_OpportunityBanner} />
              <Route exact path="/knowledgeHub/businus-school/opportunity-growth/view-bookmarks" component={ListOpportunityBookmark}/>


{/* 
              <Route exact path="/knowledgeHub/businus-school/legal/companylaw" component={ActDashboard} />
              <Route exact path="/knowledgeHub/add-company-law/category/case-law" component={AddActCategory} />
              <Route exact path="/knowledgeHub/add-company-law/category/acts" component={AddActCategory} />
              <Route exact path="/knowledgeHub/add-company-law/category/rules" component={AddActCategory} />
              <Route exact path="/knowledgeHub/add-company-law/category/circular-notification" component={AddActCategory} />
              <Route exact path="/knowledgeHub/view-company-law/category/case-law" component={listActCategory} />
              <Route exact path="/knowledgeHub/view-company-law/category/acts" component={listActCategory} />
              <Route exact path="/knowledgeHub/view-company-law/category/rules" component={listActCategory} />
              <Route exact path="/knowledgeHub/view-company-law/category/circular-notification" component={listActCategory} />
            

              <Route exact path="/knowledgeHub/add-company-law/resources/case-law" component={AddAct} />
              <Route exact path="/knowledgeHub/add-company-law/resources/acts" component={AddAct} />
              <Route exact path="/knowledgeHub/add-company-law/resources/rules" component={AddAct} />
              <Route exact path="/knowledgeHub/add-company-law/resources/circular-notification" component={AddAct} />
              <Route exact path="/knowledgeHub/view-company-law/resources/case-law" component={ListActs} />
              <Route exact path="/knowledgeHub/view-company-law/resources/acts" component={ListActs} />
              <Route exact path="/knowledgeHub/view-company-law/resources/rules" component={ListActs} />
              <Route exact path="/knowledgeHub/view-company-law/resources/circular-notification" component={ListActs} />

              <Route exact path="/knowledgeHub/businus-school/legal/gst" component={GSTDashboard} />
              <Route exact path="/knowledgeHub/add-gst/category/case-law" component={AddGstCategory} />
              <Route exact path="/knowledgeHub/add-gst/category/acts" component={AddGstCategory} />
              <Route exact path="/knowledgeHub/add-gst/category/rules" component={AddGstCategory} />
              <Route exact path="/knowledgeHub/add-gst/category/circular-notification" component={AddGstCategory} />
              <Route exact path="/knowledgeHub/view-gst/category/case-law" component={listGstCategory} />
              <Route exact path="/knowledgeHub/view-gst/category/acts" component={listGstCategory} />
              <Route exact path="/knowledgeHub/view-gst/category/rules" component={listGstCategory} />
              <Route exact path="/knowledgeHub/view-gst/category/circular-notification" component={listGstCategory} />

              <Route exact path="/knowledgeHub/add-gst/resources/case-law" component={gst} />
              <Route exact path="/knowledgeHub/add-gst/resources/acts" component={gst} />
              <Route exact path="/knowledgeHub/add-gst/resources/rules" component={gst} />
              <Route exact path="/knowledgeHub/add-gst/resources/circular-notification" component={gst} />
              <Route exact path="/knowledgeHub/view-gst/resources/case-law" component={listgst} />
              <Route exact path="/knowledgeHub/view-gst/resources/acts" component={listgst} />
              <Route exact path="/knowledgeHub/view-gst/resources/rules" component={listgst} />
              <Route exact path="/knowledgeHub/view-gst/resources/circular-notification" component={listgst} />
              <Route exact path="/knowledgeHub/arrange/column1/gst/case-law" component={ColumnOneGST} />

              <Route exact path="/knowledgeHub/businus-school/legal/custom" component={CustomDashboard} />
              <Route exact path="/knowledgeHub/add-custom/category/case-law" component={AddCustomCategory} />
              <Route exact path="/knowledgeHub/add-custom/category/acts" component={AddCustomCategory} />
              <Route exact path="/knowledgeHub/add-custom/category/rules" component={AddCustomCategory} />
              <Route exact path="/knowledgeHub/add-custom/category/circular-notification" component={AddCustomCategory} />
              <Route exact path="/knowledgeHub/view-custom/category/case-law" component={listCustomCategory} />
              <Route exact path="/knowledgeHub/view-custom/category/acts" component={listCustomCategory} />
              <Route exact path="/knowledgeHub/view-custom/category/rules" component={listCustomCategory} />
              <Route exact path="/knowledgeHub/view-custom/category/circular-notification" component={listCustomCategory} />

              <Route exact path="/knowledgeHub/add-custom/resources/case-law" component={custom} />
              <Route exact path="/knowledgeHub/add-custom/resources/acts" component={custom} />
              <Route exact path="/knowledgeHub/add-custom/resources/rules" component={custom} />
              <Route exact path="/knowledgeHub/add-custom/resources/circular-notification" component={custom} />
              <Route exact path="/knowledgeHub/view-custom/resources/case-law" component={listcustom} />
              <Route exact path="/knowledgeHub/view-custom/resources/acts" component={listcustom} />
              <Route exact path="/knowledgeHub/view-custom/resources/rules" component={listcustom} />
              <Route exact path="/knowledgeHub/view-custom/resources/circular-notification" component={listcustom} />

              <Route exact path='/knowledgeHub/businus-school/:name/add-guides' component={AddGuide}/>
              <Route exact path='/knowledgeHub/businus-school/:name/view-guides' component={ListGuideName}/>
              <Route exact path='/knowledgeHub/businus-school/:name/add-guides-blog' component={AddGuideBlog}/>
              <Route exact path='/knowledgeHub/businus-school/:name/guides-blog-dashboard' component={GuideBlogDashoard}/>
              <Route exact path='/knowledgeHub/businus-school/:name/add-guides-chapter' component={AddGuideChapter}/>
              <Route exact path='/knowledgeHub/businus-school/:name/view-guides-chapter' component={ListGuideChapter}/>
              <Route exact path='/knowledgeHub/businus-school/:name/edit-guides/:id' component={EditGuide}/>
              <Route exact path='/knowledgeHub/businus-school/:name/edit-guides-chapter/:id' component={EditGuideChapter}/>
              <Route exact path='/knowledgeHub/businus-school/:name/view-guides-blog' component={ListGuideBlog}/>
              <Route exact path='/knowledgeHub/businus-school/:name/edit-guides-blog/:id' component={EditGuideBlog}/> */}

            
            </div>
          </div>
        </div>
      </div>
    );
  }
}
