import React from "react";
// import axios from "axios";
// import { apiBaseUrl1, apiBaseUrl5 } from "../apiUtils";
// import { apiBaseUrl2 } from "../apiUtils";
import { Link } from "react-router-dom";
// import $ from "jquery";
import BreadCrumbs from "../BreadCrumbs";
import "react-table/react-table.css";
import _ from "underscore";
// import {
//   Badge,
//   Card,
//   CardBody,
//   CardHeader,
//   Col,
//   Pagination,
//   PaginationItem,
//   PaginationLink,
//   Row,
//   Table,
// } from "reactstrap";

//defining the context initially assigning it a null value

// let context = null;
function SmartDocDashboard() {
  return (
    <div>
      <div className="row breadcrumb1-row" style={{ marginLeft: "0px" }}>
        <div className="col-md-12">
          <BreadCrumbs />
        </div>
      </div>
      <div
        className="DashboardDiv"
        style={{ paddingLeft: "14px", overflowY: "scroll" }}
      >
        <div className="row ">
          <h2 className="dashboard-head">Dashboard</h2>
        </div>

        <div className="row">
          <div className="col-md-6">
            <Link to="/smartdoc/view">
              <div className="card-counter primary">
                <i className="fa fa-ticket" />
                <span className="count-numbers">{"0"}</span>
                <span className="count-name">SmartDoc Counter</span>
              </div>
            </Link>
          </div>
          <div className="col-md-6">
            <Link to="/smartdoc/add">
              <div className="card-counter primary">
                <i className="fa fa-plus" />
                <span className="count-name">Add SmartDoc</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SmartDocDashboard;
