import React, { Component } from "react";
import Select from 'react-select';
import _ from 'underscore';
import axios from 'axios';
import { apiBaseUrl2 } from "../apiUtils";
import {Link} from "react-router-dom"
let context = [];
export default class TabListData extends Component {
  constructor(props) {
    super(props);
    this.state = {
   tabList:[],
  
    };

   
  }

  componentDidMount(){
    this.setState({id:this.props.id});
    this.setState({tabList:this.props.tabList ? this.props.tabList : undefined });

   
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.tabList != this.state.tabList){
    this.setState({id:this.props.id});
    this.setState({tabList:nextProps.tabList});
    }

   
  }

  
  getFormattedDate(millis) {
    return new Date(millis).toDateString();
  }
  getFormattedTime(millis) {
    return new Date(millis).toLocaleTimeString();
  }


 

  render() {
        const {
      sliderState,
      value,
      titleProp,
      idProp,
      optionList,
      type,
      handleOnSave,
      itemName,
      changeSliderState,
      visibleState,
    } = this.props;
    return( 
      
    
    
       


      
      <div className="row">


{this.state.tabList == undefined ? <div className="no-info">No Information Found</div> :
      <tr className="doc-section">
      
                        <div className="row doc-head-row">
                          <th className="table-head text-center">Id</th>
      
                          <th className="table-head text-center">Name</th>
      
                          <th className="table-head text-center">Date of Creation</th>
      
                          <th className="table-head text-center">Time of Creation</th>
      
                          <th className="table-head text-center">By</th>
                        </div>
                         

                        <ul className="proposals">
                          { this.state.tabList && this.state.tabList.map((thred, index) => (
                            <li>
                              <div className={thred.type + " row doc-head-row"}>
                                <td className="table-item text-center">
                                 {thred[idProp]}
                                </td>
      
                                <td className="table-item text-center">
                                  <Link
                                    to={this.props.url+ thred.id}
                                    onClick={this.fetchMailById}
                                    className="timeline-user-comm-elements"
                                   
                                  >
                                    {" "}
                                    {thred[titleProp]} 
                                  </Link>
                                </td>
      
                                <td className="table-item text-center">
                                  {this.getFormattedDate(thred.date)}
                                </td>
      
                                <td className="table-item text-center">
                                  {this.getFormattedTime(thred.date)}
                                </td>
      
                                <td className="table-item text-center">{thred.by}</td>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </tr>
  }
                </div>
             

                     
  
    )
}
}