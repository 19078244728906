import React, { Component } from "react";

import { Link } from "react-router-dom";
import BreadCrumbs from "../../BreadCrumbs";
import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { ChatFeed, Message } from 'react-chat-ui'
import { Multiselect } from 'multiselect-react-dropdown';
import SubmitButton from '../../common/SubmitButton';


import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from 'underscore';
let context = null;
const data = [];
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px ',
    position: 'Absolute',
    zIndex: '30000',
    backgroundColor: 'none',
  }
};

export default class editSP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spId: '',
      spName: '',
      spPhone: '',
      spEmail: '',
      spLogo:'',
     
      
      
      entry: {},
      superSliderState: 'additional',
      sliderState: 1,
      isSubmitting:false,
    };
    context = this;
    this.openModal = this.openModal.bind(this);

    this.closeModal = this.closeModal.bind(this);
  }

  changeSliderState(orderNumber) {

    this.setState({ sliderState: orderNumber });

  }
  //function for changing the status button on click to open or close
  handleOnChange = (e) => {
    debugger;
    var value = e.target.value;

    var formName = e.target.name;
    
    if(formName=="organisationEmail"){
      this.setState({emailExists:-1});
      this.checkIfEmailAlreadyExists(value);
    }

    this.setState({ [formName]: value });



    console.log(this.state);
  }



  getFiles(files) {
    this.setState({ selectedFile: files[0] });

  }


  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });



          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }


  fetchEmployees(that, source) {

    axios.get(apiBaseUrl2+"businus/employee/list/"+localStorage.spId)
      .then(function (response) {
        debugger;
        console.log(response.data);
        var allEmployees = response.data;

        var teamLeads = _.where(allEmployees, { title: 'Team Lead' });
        var teamMembers = _.filter(allEmployees, function (emp) {
          return emp.title != 'Team Lead';
        });


        var teamMembersMultiSelectData = []

        _.each(teamMembers, function (member) {


          teamMembersMultiSelectData.push({
            name: member.firstName + member.lastName,
            id: member.id
          })





        });


        that.setState({ teamMembers: teamMembersMultiSelectData, teamLeads: teamLeads });
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  onSelect(optionsList, selectedItem) {

    context.setState({ team: optionsList });

    console.log(optionsList, selectedItem);
  }

  onRemove(optionList, removedItem) {
    context.setState({ team: optionList });
  }



  handleOnChangePerson = (e, i) => {

    var contactPersons = this.state.contactPersons;

    contactPersons[i][e.target.name] = e.target.value;

    this.setState({ contactPersons: contactPersons });




  }

  changeSuperSliderState(name) {

    this.setState({ superSliderState: name });
    if (name === 'additional') {
      this.setState({ sliderState: 1 });
    }
    else {
      this.setState({ sliderState: 6 });
    }


  }


  addNewContact = () => {

    var newPerson = {
      salutation: "",
      name: "",
      email: "",
      phone: ""
    }

    var contactPersons = this.state.contactPersons;
    contactPersons.push(newPerson);
    this.setState({ contactPersons: contactPersons });



  }
  openModal(type) {
    this.changeDocType(type);
    this.setState({ modalIsOpen: true });
  }



  closeModal() {
    this.setState({ modalIsOpen: false });
  }


  componentDidMount() {
    const id = this.props.match.params.id;
    this.fetchEmployees(this);
    this.fetchQueryById(this, id);
    this.fetchThreadById(this, id);
    this.markAsRead(this, id);
  }


  markAsRead(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/read/" +
        id
      )
      .then(function (response) {


      })
      .catch(function (error) {
        console.log(error);
      });
  }


  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(
        apiBaseUrl2+"businus/customer/list/"+localStorage.spId

      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });



          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  fetchThreadById(that, id) {
    axios
      .get(
        apiBaseUrl2+"/businusquery/listmail/" +
        id
      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        var threads = response.data == "" ? [] : _.sortBy(response.data, 'date').reverse();
        that.setState({ thread: threads });

        var documents = _.where(threads, { docType: "Document" });
        var proposals = _.where(threads, { docType: "Proposal" });
        var mails = _.where(threads, { docType: "Mail" });
        that.setState({ documents });
        that.setState({ proposals });
        that.setState({ mails });


      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getFiles(files) {
    this.setState({ selectedFile: files[0] });

  }

  getFormattedDate(millis) {

    return new Date(millis).toDateString();


  }

  checkIfEmailAlreadyExists(email) {

    if(!this.validateEmail(email)){

      return;
    }
    
    axios
      .get(
        apiBaseUrl1+"businus/uniqueEmail/employee/"+email)
      .then(function (response) {
       
        if(response.data>0){
          context.setState({emailExists:1});
        }else{
          context.setState({emailExists:0});
        }
        
       
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }


  toggleOpen = status => {
    if (status == undefined || status == "open") {
      status = "close";
    } else {
      status = "open";
    }
    //(this.setState({ entry: [...this.state.entry,{status:status} ] });)
    var newEntry = this.state.entry;
    newEntry.status = status;
    this.setState({ entry: newEntry });
    context.updateStatus(context, this.state.entry.id, status);
  };

  changeDocType(type) {

    this.setState({ docType: type });
  }


  fileUploadHandlerForAttachment = (that, docType,i) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });


          
          //that.setState({ [docType]: "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });



          var attributesGroup = that.state.attributesGroup;
   
   

          attributesGroup[i]['attachment'] = "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name
      
          that.setState({attributesGroup:attributesGroup});

          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }


  sendMail() {
    var data = context.state;
    context.setState({ isSubmitting:true});
    axios
      .post(
        apiBaseUrl1+"businus/organisation/add",
        {
          spId: data.spId,
          spName: data.spName,
          spPhone: data.spPhone,
          spEmail: data.spEmail,
          spLogo: data.spLogo,
          mode:"offline",
          "by":localStorage.userName 





        })
      .then(function (response) {
        debugger;
        alert("Success!");
        context.setState({isSubmitting:'done'});
      })
      .catch(function (error) {
        console.log(error);
        context.setState({isSubmitting:'done'});
      });
  }

  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    localStorage.setItem("MAILURL", url);


  }

  
  
  handleOnChangeAttribute = (e,i)=>{
    
    var attributesGroup = this.state.attributesGroup;
   
   

    attributesGroup[i][e.target.name] = e.target.value;

    this.setState({attributesGroup:attributesGroup});




  }


  addNewAttribute = () =>{

    var newAttribute = {
      
      name:"",
      value:"",
      attachment:""
    }

    var attributesGroup = this.state.attributesGroup;
    attributesGroup.push(newAttribute);
    this.setState({attributesGroup:attributesGroup});
 


  }
  
  handleOnBankDetailsChange = (e) => {
    debugger;
    var value = e.target.value;

    var formName = e.target.name;

    this.setState({
      bankDetails:
        { ...this.state.bankDetails, [formName]: value }
    });



    console.log(this.state);
  }



  //updating the status button

  render() {
    const { sliderState } = this.state;
    const { superSliderState, entry } = this.state;
    const active = " active-button"
    const deactive = "";
    return (
      <div className="Div">
      <div className="row breadcrumb-row">
      <BreadCrumbs /> 
      </div>
    
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
        <div className="dedicated-page" style={{padding:'0px'}}>
    <div className="row-resource-head">
                  <h2 className="resource-head">Add Service Provider</h2>
                  </div>
            <div className="row ">
              <div className="col-md-12 info-column account-col2">
                <div id="field">
                 
                  <div className="container">
                    <div className="row">

                      <div className="col-md-8">
                      


                      
                        
                       

                        <div className="form-group">
                          <label className="control-label col-md-4">
                            {" "}
                            Service Provider Name:{" "}
                          </label>
                          <div className="col-md-6">
                            <input onChange={this.handleOnChange}
                              name="spName"
                              value={entry.spName}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>
                       

                     
                        <div className="form-group">
                          <label className="control-label col-md-4">
                            Service Provider Email:
                    </label>
                          <div className="col-md-6">
                            <input
                              name="spEmail"
                              onChange={this.handleOnChange}
                              value={entry.spEmail}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                          <br/>
                            {this.state.emailExists==1 ?  <span style={{color: 'red'}}>
                             Sorry this email is unavailable
                            </span> :
                            (this.state.emailExists==0 ? <span style={{color: 'green'}}>
                            Email available
                           </span>  : '')}
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-4">
                            Service Provider Phone:
                    </label>
                          <div className="col-md-6">
                            <input
                              name="spPhone"
                              onChange={this.handleOnChange}
                              value={entry.spPhone}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>

                        
                      </div>
                    </div>
                  </div>
                  <div className="row" >
                          <div className="options-row ">
                            <div className="col-md-6 upload-box" > <FileBase64
                              multiple={true}
                              onDone={this.getFiles.bind(this)} /></div>

                            <div className="col-md-5">

                              <button className="btn btn-primary attachment" onClick={() => this.fileUploadHandler(this, entry.spLogo)}
                                value="Select a File">
                                <i className="fa fa-paperclip" aria-hidden="true"></i>Attachment
</button></div>
                          </div>
                        </div>




                  {/*--------------------------------------------------------1-------------------------------*/}


{/*

                  {sliderState == 1 ?

                    <div className="container row">
                      <div className="col-md-6">
                        <div className="row" style={{ marginBottom: '-20px' }}>
                          <label className="control-label add-header col-md-12">
                            Billing Address
                  </label>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">Attention :</label>
                          <div className="col-md-7">
                            <input
                              name="billingAttention"
                              onChange={this.handleOnChange}
                              value={this.state.billingAttention}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">Country :</label>
                          <div className="col-md-7">
                            <input
                              name="billingCountry"
                              onChange={this.handleOnChange}
                              value={this.state.billingCountry}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-4">Address* :</label>
                          <div className="col-md-7">
                            <textarea
                              placeholder="Billing Address"
                              name="billingAddress"
                              onChange={this.handleOnChange}
                              value={this.state.billingAddress}
                              id="ember330"
                              className="form-control ember-text-area ember-view"
                              defaultValue={""}
                            />
                          </div>
                        </div>

                        <div className="form-group" style={{ marginTop: '36px' }}>
                          <label className="control-label col-md-4">City* :</label>
                          <div className="col-md-7">
                            <input
                              id="ember332"
                              name="billingCity"
                              onChange={this.handleOnChange}
                              value={this.state.billingCity}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />{" "}
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">State :</label>
                          <div className="col-md-7">
                            <input
                              id="ember333"
                              className="form-control ember-text-field ember-view"
                              name="billingState"
                              onChange={this.handleOnChange}
                              value={this.state.billingState}
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">Zip Code* :</label>
                          <div className="col-md-7">
                            <input
                              id="ember334"
                              name="billingZipCode"
                              onChange={this.handleOnChange}
                              value={this.state.billingZipCode}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />{" "}
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">Phone* :</label>
                          <div className="col-md-7">
                            <input
                              id="ember335"
                              name="billingPhone"
                              onChange={this.handleOnChange}
                              value={this.state.billingPhone}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />{" "}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="row" style={{ marginBottom: '-20px' }}>
                          <label className="control-label add-header col-md-12">
                            Shipping Address
                  </label>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">Attention :</label>
                          <div className="col-md-7">
                            <input
                              name="shippingAttention"
                              onChange={this.handleOnChange}
                              value={this.state.shippingAttention}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">Country :</label>
                          <div className="col-md-7">
                            <input
                              name="shippingCountry"
                              onChange={this.handleOnChange}
                              value={this.state.shippingCountry}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-4">Address :</label>
                          <div className="col-md-7">
                            <textarea
                              placeholder="Shipping Address"
                              name="shippingAddress"
                              onChange={this.handleOnChange}
                              value={this.state.shippingAddress}
                              id="ember330"
                              className="form-control ember-text-area ember-view"
                              defaultValue={""}
                            />
                          </div>
                        </div>

                        <div className="form-group" style={{ marginTop: '36px' }}>
                          <label className="control-label col-md-4">City:</label>
                          <div className="col-md-7">
                            <input
                              id="ember332"
                              name="shippingCity"
                              onChange={this.handleOnChange}
                              value={this.state.shippingCity}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />{" "}
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">State :</label>
                          <div className="col-md-7">
                            <input
                              id="ember333"
                              className="form-control ember-text-field ember-view"
                              name="shippingState"
                              onChange={this.handleOnChange}
                              value={this.state.shippingState}
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">Zip Code :</label>
                          <div className="col-md-7">
                            <input
                              id="ember334"
                              name="shippingZipCode"
                              onChange={this.handleOnChange}
                              value={this.state.shippingZipCode}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />{" "}
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">Phone :</label>
                          <div className="col-md-7">
                            <input
                              id="ember335"
                              name="shippingPhone"
                              onChange={this.handleOnChange}
                              value={this.state.shippingPhone}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    : ''}

                  {/*--------------------------------------------------------2-------------------------------
                  {sliderState == 2 ?
                    <div>
                      <div className="row" style={{ marginTop: '20px' }}>
                        <div className="col-md-12">
                          <div className="row" style={{ marginBottom: '-20px' }}>
                            <label className="control-label add-header col-md-12">
                              Contact Persons*
      </label>
                          </div>
                          <div className="text-right" style={{ marginRight: '40px' }}>
                            <button className="add-button" onClick={this.addNewContact} > <i className="fa fa-plus "></i></button>
                          </div>
                          <table className="add-customer-table" style={{ width: "100%" }}>
                            <tr>
                              <th className="table-head">Salutation</th>
                              <th className="table-head">Name</th>

                              <th className="table-head">Email Address </th>
                              <th className="table-head">Work Phone</th>
                            </tr>
                            {this.state.contactPersons.map((person, i) => (

                              <tr>
                                <td className="table-item">
                                  <select className="form-control select-form" name="salutation" placeholder="salutation" onChange={(e) => this.handleOnChangePerson(e, i)} value={this.state.contactPersons[i].salutation}>
                                    <option>Mr.</option>
                                    <option>Mrs</option>
                                    <option>Ms</option>
                                  </select>
                                </td>
                                <td className="table-item"><input name="name" onChange={(e) => this.handleOnChangePerson(e, i)}
                                  value={this.state.contactPersons[i].name} /> </td>
                                <td className="table-item"><input name="email" onChange={(e) => this.handleOnChangePerson(e, i)} value={this.state.contactPersons[i].email} /> </td>
                                <td className="table-item"><input name="phone" onChange={(e) => this.handleOnChangePerson(e, i)} value={this.state.contactPersons[i].phone} /> </td>
                              </tr>


                            ))}


                          </table></div></div></div> : ''}

                  {/*--------------------------------------------------------3-------------------------------


                  {sliderState == 3 ?

                    <div className="container row">
                      <div className="col-md-12">
                        <div className="row" style={{ marginBottom: '-20px' }}>
                          <label className="control-label add-header col-md-12">
                            Banking Details
      </label>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">Bank Name* :</label>
                          <div className="col-md-8">
                            <input
                              name="bankName"
                              onChange={this.handleOnBankDetailsChange}
                              value={this.state.bankDetails.bankName}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">Branch Name*:</label>
                          <div className="col-md-8">
                            <input
                              name="branchName"
                              onChange={this.handleOnBankDetailsChange}
                              value={this.state.bankDetails.branchName}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-4">Account No*:</label>
                          <div className="col-md-8">
                            <input
                              name="accountNo"
                              onChange={this.handleOnBankDetailsChange}
                              value={this.state.bankDetails.accountNo}
                              id="ember330"
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="form-group" >
                          <label className="control-label col-md-4">Account Type:</label>
                          <div className="col-md-6">

                            <select
                              name="accountType"
                              onChange={this.handleOnBankDetailsChange}
                              value={this.state.bankDetails.accountType}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            >
                              <option value="select">Select One</option>
                              <option value="savings">Savings</option>
                              <option value="current">Current</option>
                              <option value="credit">Credit Card A/C</option>
                              <option value="loans">Loans</option>
                              <option value="overdraft">Overdraft</option>
                              <option value="deposit">Deposit</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">IFSC Code* :</label>
                          <div className="col-md-8">
                            <input
                              id="ember333"
                              className="form-control ember-text-field ember-view"
                              name="ifscCode"
                              onChange={this.handleOnBankDetailsChange}
                              value={this.state.bankDetails.ifscCode}
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">MICR Code :</label>
                          <div className="col-md-8">
                            <input
                              id="ember334"
                              name="micrCode"
                              onChange={this.handleOnBankDetailsChange}
                              value={this.state.bankDetails.micrCode}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />{" "}
                          </div>
                        </div>

                      </div>



                    </div>
                    : ''}


                  
{sliderState == 4 ?
<div>
  <div className="row" style={{marginTop:'20px'}}>
    <div className="col-md-12">
    <div className="row" style={{marginBottom:'-20px'}}>
      <label className="control-label add-header col-md-12">
        Attributes
      </label>
    </div>
  <div className="text-right" style={{marginRight:'40px'}}>
 <button className="add-button" onClick={this.addNewAttribute} > <i className="fa fa-plus "></i></button>
  </div>
              <table className="add-customer-table" style={{width:"100%"}}>
  <tr>
    <th className="table-head">Attributes</th>
    <th className="table-head">Attribute Value</th>
  
    <th className="table-head">Attribute Attachment </th>
   
  </tr>
  {this.state.attributesGroup.map((person, i) => (
         
         <tr>
            <td className="table-item">
              <select className="form-control select-form" name="name" placeholder="salutation" onChange={(e) => this.handleOnChangeAttribute(e,i)} value={this.state.attributesGroup[i].name}>  
              <option value="select">Select</option>
 <option value="pan">PAN</option>
 
 <option value="Aadhar Card" >Aadhar Card Number</option>
 <option value="Authorised Capital">Authorised Capital</option>
 <option value="Category" >Category</option>
 <option value="CIN no">CIN no</option>
 <option value="DIN no">DIN no</option>
 <option value="Excise no">Excise No</option>
 <option value="GST No" >GST No</option>
 <option value="Incorporation Date">Incorporation Date</option>
 <option value="Paid Up Capital">Paid Up Capital</option>
 <option value="PAN">PAN</option>
 <option value="Pt No.(ec)">Pt No.(EC)</option>
 <option value="Pt No.(rc)">Pt No.(RC)</option>
 <option value="Service Tax No">Service Tax No.</option>
 <option value="Shop Act Expiry Date">Shop Act Expiry Date.</option>
 <option value="Shop Act No.">Shop Act No.</option>
 <option value="pan">Shop Act Reg Date.</option>
 <option value="pan">TAN No</option>
 <option value="pan">VAT No.</option>
 <option value="pan">With Effect From (W.E.F)</option>

       </select>
       </td>
         <td className="table-item"><input name="value" onChange={(e) => this.handleOnChangeAttribute(e,i)} 
         value={this.state.attributesGroup[i].value}/> </td>
         <td className="table-item">  <div className="col-md-6">
               
               <FileBase64
              multiple={true}
              onDone={this.getFiles.bind(this)}   /></div>
              
              <div className="col-md-5">
              
              <button className="btn btn-primary attachment" onClick={() => this.fileUploadHandlerForAttachment(this, "attributeAttachment",i)}
              value="Select a File">
              <i className="fa fa-paperclip" aria-hidden="true"></i>Attachment
              </button>
                                        </div> </td>
         
         </tr>
         
        
         ))}
 
 
</table></div></div></div> :''}


                  {sliderState == 5 ? <div className="container row">
                    <div className="col-md-12">
                      <div className="row" style={{ marginBottom: '-20px' }}>
                        <label className="control-label add-header col-md-12">
                          Attributes
      </label>
                      </div>

                      <div className="form-group">
                        <label className="control-label col-md-4">Team*:</label>
                        <div className="col-md-6">

                          {this.state.teamMembers.length > 0 ?
                            <Multiselect
                              options={this.state.teamMembers}
                              onSelect={this.onSelect}
                              placeholder='Select One or More'
                              onRemove={this.onRemove}
                              displayValue="name"
                            />
                            : ''}

                        </div>
                      </div>

                    </div>
                  </div>
                    : ''}



                  {sliderState == 6 ?
                    <div>
                      <div className="row">
                        <div className="col-md-6">

                          <div className="form-group">
                            <label className="control-label col-md-4">Module Access:</label>
                            <div className="col-md-6">

                              {this.state.modules.length > 0 ?
                                <Multiselect
                                  options={this.state.modules}
                                  onSelect={this.onSelect}
                                  selectedItem={this.state.selectedItem}
                                  onRemove={this.onRemove}
                                  displayValue="name"
                                />
                                : ''}

                            </div>
                          </div>

                        </div>

                        <div className="col-md-6">

                          <div className="form-group">
                            <label className="control-label col-md-4">Role* :</label>
                            <div className="col-md-7">
                              <select
                                name="role"
                                onChange={this.handleOnChange}
                                value={this.state.role}
                                className="form-control ember-text-field ember-view"
                                type="text"
                              > <option value="admin">Select</option>
                                <option value="admin">Admin</option>
                                <option value="employee">Employee</option>
                                <option value="customise">Customise</option>

                              </select>
                            </div>
                          </div>

                        </div>

                        {this.state.canCustomise == true ?
                          <table className="add-module-table" style={{ width: "84%", marginTop: '9%', marginLeft: '5%' }}>
                            <tr>
                              <th className="table-head" style={{ backgroundColor: '#e8eaed', width: "44%" }}>Selected Modules</th>
                              <th className="table-head" style={{ backgroundColor: '#fafac0' }}>View</th>

                              <th className="table-head" style={{ backgroundColor: '#d0fac0' }}>Add </th>
                              <th className="table-head" style={{ backgroundColor: '#e0dbff' }}>Update</th>
                              <th className="table-head" style={{ backgroundColor: '#fac6c0' }}>Delete</th>
                            </tr>


                            <tr>
                              <td className="table-item">
                                <select className="form-control select-form" name="salutation" placeholder="salutation" value={this.state.selectedItem}>
                                  {}
                                </select>
                              </td>
                              <td className="table-item" style={{ width: '80px' }}><input name="name" value="CAN_VIEW" type="checkbox" /> </td>
                              <td className="table-item"><input name="name" value="CAN_VIEW" type="checkbox" /> </td>
                              <td className="table-item"><input name="name" value="CAN_VIEW" type="checkbox" /> </td>
                              <td className="table-item"><input name="name" value="CAN_VIEW" type="checkbox" /> </td>



                            </tr>




                          </table>
                          : ''}
                      </div>

                    </div>
                    : ''}

                        */}

                  <div className="text-left">
                  <SubmitButton clickHandler={this.sendMail}
                   isSubmitting={this.state.isSubmitting} />
                  </div>
                  {/* this says that if editing state is on then the Function saveAccountDetails will be called */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

