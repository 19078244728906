import React, { Component } from "react";
import axios from "axios";
import { apiBaseUrl1,apiBaseUrl5 } from '../apiUtils';
import { apiBaseUrl2 } from '../apiUtils';
import "./style.css";

import { Auth } from 'aws-amplify';

import { Link, withRouter } from "react-router-dom";

let context = null;
class Login extends Component {
  constructor(props) {
    super();

    this.state = { email: "", password: "" ,newPassword:false,user:{} };
    context = this;
    this.loginSubmit = this.loginSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  checkUser() {

    Auth.currentAuthenticatedUser()
  
      .then(user => context.saveUserInfo(user))
  
      .catch(err => console.log(err))
  
  }

  componentDidMount(){
    //this.checkUser();
  }
  //To set the new values in the state
  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value }
    );

    console.log(this.state.credentials);
  }
  signOut() {
  
    Auth.signOut()
  
      .then(data => console.log(data))
  
      .catch(err => console.log(err))
  
  }
  
  setNewPassword =  async event =>{
    var user = this.state.user;
    var newPasswordValue = this.state.newPasswordValue;
    var email = this.state.email;
    debugger;
    const loggedUser = await Auth.completeNewPassword(
      user,              // the Cognito User Object
      newPasswordValue
  );

  context.setState({password:newPasswordValue});


  context.loginSubmit();
  //context.saveUserInfo(loggedUser);
    
  }
  getNewPassword = (user)=>{

    this.setState({newPassword:true});
    this.setState({user});




  }
  loginSubmit = async event => {
	//	event.preventDefault();

		this.setState({ isLoading: true });

		try {
      const user  = await Auth.signIn(this.state.email, this.state.password);
      
    /*   if (user.challengeName === 'SMS_MFA' ||
      user.challengeName === 'SOFTWARE_TOKEN_MFA') {
      // You need to get the code from the UI inputs
      // and then trigger the following function with a button click
      const code = getCodeFromUserInput();
      // If MFA is enabled, sign-in should be confirmed with the confirmation code
      const loggedUser = await Auth.confirmSignIn(
          user,   // Return object from Auth.signIn()
          code,   // Confirmation code  
          mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
      );
  }  else */if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      const {requiredAttributes} = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
      // You need to get the new password and required attributes from the UI inputs
      // and then trigger the following function with a button click
      // For example, the email and phone_number are required attributes
      //const {username, email, phone_number} = getInfoFromUserInput();
      context.getNewPassword(user);
     
  } /* else if (user.challengeName === 'MFA_SETUP') {
      // This happens when the MFA method is TOTP
      // The user needs to setup the TOTP before using it
      // More info please check the Enabling MFA part
      Auth.setupTOTP(user);
  }  */else {
     context.saveUserInfo(user);
  }
} catch (err) {
  if (err.code === 'UserNotConfirmedException') {
      // The error happens if the user didn't finish the confirmation step when signing up
      // In this case you need to resend the code and confirm the user
      // About how to resend the code and confirm the user, please check the signUp part
      alert(err.message);
  } else if (err.code === 'PasswordResetRequiredException') {
      // The error happens when the password is reset in the Cognito console
      // In this case you need to call forgotPassword to reset the password
      // Please check the Forgot Password part.
      alert(err.message);
  } else if (err.code === 'NotAuthorizedException') {
      // The error happens when the incorrect password is provided
      alert(err.message);
  } else if (err.code === 'UserNotFoundException') {
    alert(err.message); 
    // The error happens when the supplied username/email does not exist in the Cognito user pool
  } else {
    alert(err.message);
  }
}
 };
  
  saveUserInfo = (user)=>{
    localStorage.setItem("userId", user.attributes.locale);
    localStorage.setItem("isLoggedIn", "YES");
    localStorage.setItem("userName", user.attributes.name);
    localStorage.setItem("token", user.signInUserSession?.accessToken?.jwtToken); 
    /* 100 for employee
    100#org for organisational login
    100#sp for spId */
    /* we store org id or emplyee id at the time of adding organistaion or employee */
    if(user.attributes.locale.split("#")[1]=='org'){
      this.fetchOrganisationById(user.attributes.locale.split("#")[0]);
    }else if(user.attributes.locale.split("#")[1]=='sp'){
      this.fetchServiceProviderById(user.attributes.locale.split("#")[0]);
    }else{
      this.fetchEmployeeById(user.attributes.locale);
    }
    
  
  }

  fetchOrganisationById(id) {
    axios
      .get(
        apiBaseUrl1+"businusquery/getOrganisationById/" +
          id
      )
      .then(function(response) {
        if(response.data.modules!=undefined){
          localStorage.setItem("privileges",
          JSON.stringify(response.data.modules));
        }
      else{
        localStorage.setItem("privileges",
        JSON.stringify([]));
      }

        //variable will be same so that we dont have to change in application
         localStorage.setItem("employeeId",response.data.id)
         localStorage.setItem("spId",response.data.spId)
         localStorage.setItem("mobile",response.data.organisationPhone);
         localStorage.setItem("userEmail",response.data.organisationEmail);
         localStorage.setItem("isSp",false);
          window.location.reload();
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  
  fetchServiceProviderById(id) {
    axios
      .get(
        apiBaseUrl1+"businusquery/getServiceProviderById/" +
          id
      )
      .then(function(response) {
        if(response.data.modules!=undefined){
          localStorage.setItem("privileges",
          JSON.stringify(response.data.modules));
        }
      else{
        localStorage.setItem("privileges",
        JSON.stringify([]));
      }

        //variable will be same so that we dont have to change in application
         localStorage.setItem("employeeId",response.data.id)
         localStorage.setItem("spId",response.data.id)
         localStorage.setItem("mobile",response.data.spPhone);
         localStorage.setItem("userEmail",response.data.spEmail);
         localStorage.setItem("profile",response.data.spLogo|| "https://cdn3.iconfinder.com/data/icons/business-avatar-1/512/3_avatar-512.png");
         localStorage.setItem("isSp",true);
          window.location.reload();
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  
  

  fetchEmployeeById(id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/getEmployeeById/" +
        id
      )
      .then(function (response) {
        debugger;
       if(!response.data.employeeId){
         alert("Account not found")
         localStorage.clear();
         return
       }
        if(response.data.modules!=undefined){
          localStorage.setItem("privileges",
          JSON.stringify(response.data.modules));
        }
      else{
        localStorage.setItem("privileges",
        JSON.stringify([]));
      }
         localStorage.setItem("employeeId",response.data.id)
         localStorage.setItem("spId",response.data.spId)
         localStorage.setItem("mobile",response.data.mobile);
         localStorage.setItem("userEmail",response.data.email);
         localStorage.setItem("userRole",response.data.role);
         localStorage.setItem("isSp",false);
         localStorage.setItem("profile",response.data.profile || "https://cdn3.iconfinder.com/data/icons/business-avatar-1/512/3_avatar-512.png");
         window.location.replace("/");


      })
      .catch(function (error) {
        localStorage.clear();
        alert("Account not found123")
        console.log(error);
      });
  }

  

  forgetPassword  = (sendOtp)=>{

    if(!sendOtp){
      this.setState({forgotPassword:true})
    }
    else{

      if(this.state.email!=""){
        Auth.forgotPassword(this.state.email)
        .then(data => this.setState({changePassword:true}))
        .catch(err => console.log(err));
      }
      else{
        alert("email cannot be blank");
      }
      
  
    }

  }


  createNewPassword(){

    let username = this.state.email;
    let code = this.state.otp;
    let newPassword = this.state.newPasswordValue;

    Auth.forgotPasswordSubmit(username, code, newPassword)
    .then(data => window.location.reload())
    .catch(err => console.log(err));
  }

  render() {
    return (
      <div className="wrapper fadeInDown">
        <div id="formContent">
          <div className="fadeIn first" />
         
          <div className="row">
                <img id="1" 
                  style={{ marginLeft: "-3px",width:"180px",height:"65px",marginTop:"40px" }}
                  src={require("../../images/teamDashboard.png")}
                />
              </div>
         {/*} <div className="fadeIn first">
            <img
              className="userImage1"
              src="https://cdn.pixabay.com/photo/2016/11/18/23/38/child-1837375__340.png"
              id="icon"
              alt="User Icon"
            />
    </div>*/}
          
          <h2 className="hi-text" style={{marginTop:"40px"}}>Hi User!</h2>
          
         {this.state.newPassword  ?  <form>
            <div className="group">
              <label htmlFor="user" className="label" />
              <input
                id="email"
                name="email"
                type="email"
                onChange={this.handleChange}
                value={this.state.email}
                className="input fadeIn second text-box logintext"
                placeholder="Email "
                data-type="password"
                style={{width:"300px"}}
              /><br/>
             
            </div>
            <div className="group">
              <label htmlFor="pass" className="label" />
              <input
                id="newPassword"
                name="newPasswordValue"
                type="password"
                onChange={this.handleChange}
                value={this.state.newPasswordValue}
                className="input fadeIn second text-box logintext"
                placeholder="New Password"
              />
               
            </div>

            <small>Password should be of minimum 8 characters</small>
            
            <div className="group">
              <input
                type="button"
                className="signinbutton"
                onClick={this.setNewPassword}
                style={{marginTop:'15px'}}
                value="Change Password"
              />
            </div>
            <hr />
            <div className="row" style={{ padding: "30px" }}>
              <div className="col-md-6">
                {" "}
                <span className="button googlebtn" />{" "}
              </div>
              <div className="col-md-6" />
            </div>
          </form> :
           
           
           this.state.forgotPassword  ?  <form>
       
            <div className="group">
              <label htmlFor="email" className="label" />
              <input
                id="pass"
                name="email"
                type="email"
                onChange={this.handleChange}
                value={this.state.email}
                disabled={this.state.changePassword}
                className="input fadeIn second text-box logintext"
                placeholder="Email "
                data-type="emaill"
               
              /><br/>
             {/*    <small>Password should be of minimum 8 characters</small> */}
            </div>
            
            {this.state.changePassword  ?  
       <span>
       <div className="group">
              <label htmlFor="otp" className="label" />
              <input
                id="otp"
                name="otp"
                type="number"
                onChange={this.handleChange}
                value={this.state.otp}
                className="input fadeIn second text-box logintext"
                placeholder="OTP"
              />
            </div>
            <div className="group">
              <label htmlFor="user" className="label" />
              <input
                id="newPassword"
                name="newPasswordValue"
                type="password"
                onChange={this.handleChange}
                value={this.state.newPasswordValue}
                className="input fadeIn second text-box logintext"
                placeholder="New Password"
              />
            </div>
             </span>: ''}
           {this.state.changePassword ?  <div className="group">
              <input
                type="button"
                className="signinbutton"
                onClick={()=>this.createNewPassword()}
                style={{marginTop:'15px'}}
                value="Change Password"
              />
            </div> :
            <div className="group">
            <input
              type="button"
              className="signinbutton"
              onClick={()=>this.forgetPassword(true)}
              style={{marginTop:'15px'}}
              value="Generate OTP"
            />
          </div> }
            <hr />
            <div className="row" style={{ padding: "30px" }}>
              <div className="col-md-6">
                {" "}
                <span className="button googlebtn" />{" "}
              </div>
              <div className="col-md-6" />
            </div>
          </form> :
           
           <form>
           <div className="group">
             <label htmlFor="user" className="label" />
             <input
               id="user"
               name="email"
               type="text"
               onChange={this.handleChange}
               value={this.state.email}
               className="input fadeIn second text-box logintext"
               placeholder="Username"
             />
           </div>
           <div className="group">
             <label htmlFor="pass" className="label" />
             <input
               id="pass"
               name="password"
               type="password"
               onChange={this.handleChange}
               value={this.state.password}
               className="input fadeIn second text-box logintext"
               placeholder="Password"
               data-type="password"
             />
           </div>
           {this.state.required ? (
             <small className="error">All Fields are required</small>
           ) : (
             <p />
           )}
           {this.state.error ? (
             <small className="error">{this.state.errorMessage}</small>
           ) : (
             <p />
           )}
           <div className="group">
             <input
               type="button"
               className="signinbutton"
               onClick={this.loginSubmit}
               value="Sign In"
             />
           </div>
           <hr />
           <p onClick={()=>this.forgetPassword(false)}>Forgot Password ?</p>
           <div className="row" style={{ padding: "30px" }}>
             <div className="col-md-6">
               {" "}
               <span className="button googlebtn" />{" "}
             </div>
             <div className="col-md-6" />
           </div>
         </form>}
        </div>
      </div>
    );
  }
}

export default Login;
