import React, { Component } from "react";
import { Link } from "react-router-dom";
import BreadCrumbs from "../../BreadCrumbs";
import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { ChatFeed, Message } from 'react-chat-ui'
import {Multiselect} from 'multiselect-react-dropdown';
import {getPrivilegeByName} from '../../privilegeUtils';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from 'underscore';
let context = null;
const data = [];
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               :'0px ',
    position               :'Absolute',
    zIndex                 :'30000'     ,
    backgroundColor         :'none',  
  }
};
 
export default class pageProgressStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
   thread:[],
      sliderState:1,
      
      module: '',
      opened: [],
      subject: "",
      message: "",
      priority:"",
      attachment: "NA",
      thread: [],
      sendTo: "",
      documents: [],
      privilege:{},
      proposals: [],
      sliderState:1,
      superSliderState:'additional',
      mails: [],
      entry: {
        checklists : [],
        contactPersons : [],
        bankDetails : {}
      },
      assignmentDocuments:[],
      assignmentComments:[],
      issues:[],
      expenses:[],
      progressLogs:[],
      modalIsOpen: false,
      docType: "MAIL",
      sliderState: 1,//sta//state for storing the response from the backend,
      
    };
    context = this;
    this.openModal = this.openModal.bind(this);
   
    this.closeModal = this.closeModal.bind(this);
  }

  changeSuperSliderState(name) {

    this.setState({ superSliderState: name });
    if(name==='additional'){
      this.setState({ sliderState: 1 });
    }
    else{
      this.setState({ sliderState: 13 });
    }
  

  }
  

  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile,
        "isMultiple":true,
        "imageName": that.state.selectedFile,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile[0].name });
          var images = [];
          _.each(that.state.selectedFile,function(file){

              images.push("https://businus-files-live.s3.amazonaws.com/mailAttachments/" + file.name);

          })
         
          that.setState({ [docType]: images});
          



          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  



  getFiles(files) {
    this.setState({ selectedFile: files });

  }

  changeSliderState(orderNumber){

    this.setState({sliderState:orderNumber});

}
  //function for changing the status button on click to open or close
  onChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);


    console.log(this.state);
  }
  
  
  openModal(type) {
    this.changeDocType(type);
    this.setState({modalIsOpen: true});
  }
 
  
 
  closeModal() {
    this.setState({modalIsOpen: false});
  }
 

  componentDidMount() {

    var privileges =  JSON.parse(localStorage.getItem('privileges'));
    var privilege = _.findWhere(privileges,{module:"Client"});

    this.setState({privilege:privilege});

    const id = this.props.match.params.id;

    this.fetchQueryById(this, id);
   // this.fetchThreadById(this, id);
    //this.markAsRead(this,id);
  }


  markAsRead(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/read/" +
          id
      )
      .then(function(response) {
       
       
      })
      .catch(function(error) {
        console.log(error);
      });
  }


  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(
        apiBaseUrl1+"businusquery/getStatusById/" +
          id
      )
      .then(function(response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

 
  delete = (id) => {
    var result= window.confirm("Are you sure you want to delete?");
    if (result==true){
    axios
      .delete(
        apiBaseUrl2+"businusquery/getClientById/" +
          id
      )
      .then(function(response) {
        debugger;
        console.log(response.data);
        alert("Client has been deleted");
       // that.setState({ entry: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  }
}
  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });



          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  fetchThreadById(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/listmail/" +
          id
      )
      .then(function(response) {
        debugger;
        console.log(response.data);
        var threads = response.data=="" ? [] : _.sortBy(response.data,'date').reverse();
        that.setState({ thread: threads});

        var documents = _.where(threads,{docType:"Document"});
        var proposals = _.where(threads,{docType:"Proposal"});
        var mails = _.where(threads,{docType:"Mail"});
        that.setState({documents});
        that.setState({proposals});
        that.setState({mails});
        
      
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  getFiles(files) {
    this.setState({ selectedFile: files[0] });

  }

  getFormattedDate(millis){

    return new Date(millis).toDateString();


  }

  toggleOpen = status => {
    if (status == undefined || status == "open") {
      status = "close";
    } else {
      status = "open";
    }
    //(this.setState({ entry: [...this.state.entry,{status:status} ] });)
    var newEntry = this.state.entry;
    newEntry.status = status;
    this.setState({ entry: newEntry });
    context.updateStatus(context, this.state.entry.id, status);
  };
  
  changeDocType(type){

    this.setState({docType:type});
  }

  sendMail() {
     var query = context.state.entry;
    axios
      .post(
        apiBaseUrl2+"businusquery/send",
        {
          
      "name": query.name,
      "email": query.email,
      "message":context.state.message,
      "company":query.company, 
      "toe":query.toe,
      "subject":context.state.subject,
      "attachment":context.state.attachment,
      "attachmentName":context.state.attachmentName,
      "queryId":query.id,
      "from":"client",
      "recordType":"MAIL",
      "docType":context.state.docType,
      "senderEmail":context.state.senderEmail
        })
      .then(function(response) {
        debugger;
      alert("SENT");
      context.fetchThreadById(context,query.id);
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  setMailPageUrl(){
  var url = window.location.href.replace(window.location.origin,"");
  localStorage.setItem("MAILURL",url);


  }


  changeSliderState(orderNumber){

    this.setState({sliderState:orderNumber});

}


  //updating the status button
  
  render() {
    const {sliderState,entry,superSliderState} = this.state;
    const active = " active-button"
    const deactive = "";
    return (
      <div className="Div">
      <div className="row breadcrumb-row">
       <div className="col-md-8">
      <BreadCrumbs /> 
      </div>
      <div className="col-md-4">
        <h4>
        {getPrivilegeByName('Client').canEdit ?
        <Link style={{marginRight:'50px'}} className="pull-right" to={"../edit-progress-status/"+entry.id}><i className="fa fa-edit"></i>Edit</Link> : ''} 
     {getPrivilegeByName('Client').canDelete ?   
       <div style={{marginRight:'50px', cursor:'pointer'}} className=" pull-right" onClick={()=>this.delete(entry.id)}><i className="fa fa-trash"></i>Delete</div>:''}
       </h4>
       </div>
       </div>
      
    
    
{/* showing all the details of a single item from the table at the backend*/}
      <div className="row">
      <div className="dedicated-page" style={{padding:'0px'}}>
    <div className="row-resource-head">
                  <h2 className="resource-head">View Progress</h2>
                  </div>
          <div className="header-div ">
            <div
              className="bar"
              style={{ borderTop: "#1E5B81", color: "blue" }}
            />
            <div className="row">
              <div className="col-md-10">

              <div className="form-group">
                  <label className="control-label col-md-4">
                  Progress Status
                  </label>
                  <div className="col-md-6">
                  {this.state.entry.progressStatus}
                    
                  </div>
                </div>


                <div className="form-group">
                  <label className="control-label col-md-4">
                  Progress Color
                  </label>
                  <div className="col-md-6">
                  
                  <span className="color-block" style={{background:this.state.entry.progressStatusColor}}></span>
                    
                  </div>
                </div>
             

              
                
</div>


           

          
</div>
</div>
</div>
          
            </div>
          
            


      
    </div>
    );
  }
}

