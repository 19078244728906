import React, { Component } from "react";
import { Link } from "react-router-dom";
import BreadCrumbs from "../BreadCrumbs";


export default class AppointmentConsultDashoard extends Component{
    render(){
        return(
            <div>
                <div className="row breadcrumb1-row" style={{marginLeft:'0px'}}>
                    <div className="col-md-12 "> <BreadCrumbs/> </div>
                </div>
                <div className="DashboardDiv" style={{ marginLeft: "20px" }}>
                <div className="row ">
                    <h2 className="dashboard-head">Dashboard</h2>
                </div>

                <div className="row">
                <div className="col-md-6">
                    <Link to="/appointment-consultant/view-appointment">
                        <div className="card-counter primary">
                        <i className="fa fa-ticket" />
                        {/* <span className="count-numbers">{this.state.count}</span> */}
                        <span className="count-name">View Appointment</span>
                        </div>
                    </Link>
                </div>

                <div className="col-md-6">
                    <Link to="/appointment-consultant/add-appointment">
                        <div className="card-counter primary">
                        <i className="fa fa-plus" />

                        <span className="count-name">Add Appointment</span>
                        </div>
                    </Link>
                </div>

                <div className="col-md-6">
                    <Link to="/appointment-consultant/view-consultant">
                        <div className="card-counter primary">
                        <i className="fa fa-ticket" />
                        {/* <span className="count-numbers">{this.state.count}</span> */}
                        <span className="count-name">View Consultant</span>
                        </div>
                    </Link>
                </div>

                <div className="col-md-6">
                    <Link to="/appointment-consultant/add-consultant">
                        <div className="card-counter primary">
                        <i className="fa fa-plus" />

                        <span className="count-name">Add Consultant</span>
                        </div>
                    </Link>
                </div>

                <div className="col-md-6">
                    <Link to="/appointment-consultant/view-typeMaster">
                        <div className="card-counter primary">
                        <i className="fa fa-ticket" />
                        {/* <span className="count-numbers">{this.state.count}</span> */}
                        <span className="count-name">View Type Master</span>
                        </div>
                    </Link>
                </div>

                <div className="col-md-6">
                    <Link to="/appointment-consultant/add-typeMaster">
                        <div className="card-counter primary">
                        <i className="fa fa-plus" />

                        <span className="count-name">Add Type Master</span>
                        </div>
                    </Link>
                </div>

                <div className="col-md-6">
                    <Link to="/appointment-consultant/view-statusMaster">
                        <div className="card-counter primary">
                        <i className="fa fa-ticket" />
                        {/* <span className="count-numbers">{this.state.count}</span> */}
                        <span className="count-name">View Status Master</span>
                        </div>
                    </Link>
                </div>

                <div className="col-md-6">
                    <Link to="/appointment-consultant/add-statusMaster">
                        <div className="card-counter primary">
                        <i className="fa fa-plus" />

                        <span className="count-name">Add Status Master</span>
                        </div>
                    </Link>
                </div>
                </div>
                </div>
            </div>
        )
    }
}