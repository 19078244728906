import React, { Component } from 'react';
import reactCSS from 'reactcss'
import '../style/agency.css';
import $ from 'jquery';
import { SketchPicker } from 'react-color';
import '../animations.css';
import FileBase64 from 'react-file-base64';
import axios from 'axios';

import {apiBaseUrl,baseFilePath} from './apiUtils';

let context = null;


/**
 * Style-modal
 * It is the code for showing style modal in the template
 * @module Style-Modal
 */


class Modal extends Component {
  constructor(props){
    super(); 
    this.state ={
      backgroundColor:'',
      padding:'',
      margin:'',
      color:'' ,
      backgroundImage:'',
      displayColorPicker:false,
      displayBgColorPicker:false,
      isBackgroundImage: false,
      title:'',
      fileName:"",
      files:[]
    };
    this.saveStyle = this.saveStyle.bind(this);
    this.uploadApi = this.uploadApi.bind(this);
    this.hideModal = this.hideModal.bind(this);
   context = this;
    
   }
   
   componentDidMount(){
    var style = this.props.styleProp;
    var title = this.props.title;

    if(style.backgroundImage!=undefined && style.backgroundImage!="no image"){
      this.setState({isBackgroundImage:true});
    }
    this.setState({backgroundColor:style.backgroundColor});
    this.setState({padding:style.padding});
    this.setState({margin:style.margin});
    this.setState({color:style.color});
    //this.setState({cardColor:style.cardColor});
    this.setState({backgroundImage:style.backgroundImage})
    this.setState({title:title})

    
   }


   handleKeyPress = (event) => {
    if(event.key == 'Enter'){
      alert('hello')
    }
  }

   /**
 * uploadApi()
 * This segment is the code for upload image to the server.
 * @function uploadApi()
 */
   uploadApi(){
    var data = {image:this.state.files.base64,fileName:Math.random()+this.state.files.name}
    axios.post(apiBaseUrl+"/uploadImage",data)
    .then(function (response) {
  
      context.setState({backgroundImage:response.data.Location});
     
    })
    .catch(function (error) {
      console.log(error);
    });
  

   }

   getFiles(files){
    this.setState({ files: files })
    this.uploadApi();
  }

  onChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);


    console.log(this.state);
  }

/**
 * saveStyle()
 * This segment is the code for saving style and elements to the server.
 * @function saveStyle()
 */

  saveStyle(index){
    var context=this;

if(this.state.isBackgroundImage && this.state.backgroundImage!="no image"){
  var backgroundImage="url('"+this.state.backgroundImage+"')";
  this.setState({backgroundImage:backgroundImage});
  this.setState({backgroundColor:'#fff'});
}
else{
  this.setState({backgroundImage:'no image'});

}
/**
 * setTimeout()
 * This segment is the code for setting timeout.
 * @function setTimeout()
 */
   
     setTimeout(function(){
       var style =context.state;
       delete style.fileName;
       delete style.files;
      context.props.saveStyle(style);
      context.props.toggleStyleModal();
     }, 50);
    
   }

   /**
 * toggleColorPicker()
 * This segment is the code for toggling color picker in the modal.
 * @function toggleColorPicker()
 */
   
   toggleColorPicker = (colorPicker) => {
     if(colorPicker=="bg"){
      this.setState({ displayBgColorPicker: !this.state.displayBgColorPicker })
     }
     else{
      this.setState({ displayColorPicker: !this.state.displayColorPicker })

     }
    
  };


   hideModal(key){

    this.props.hideModal(key);
   }

   handleChangeComplete = (color) => {
    this.setState({ backgroundColor: color.hex });
  };
  handleChangeColorComplete = (color) => {
    this.setState({ color: color.hex });
  };
  handleChangeCardColorComplete = (color) => {
    this.setState({ color: color.hex });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
    this.setState({ displayBgColorPicker: false });
  };

  toggleChange = () => {
    this.setState({
      isBackgroundImage: !this.state.isBackgroundImage,
    });
  }
   
    render(){
      const styles = reactCSS({
        'default': {
          color: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `${this.state.backgroundColor}`,
          },
          color2: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `${this.state.color}`,
          },
          swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
          },
          popover: {
            position: 'absolute',
            zIndex: '2',
          },
          cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          },
        },
      });
  


    return(
this.state.hideModal ? <div></div>  : 


<div className="modal-wrapper">
<div className="container">
        <div className="row modal-section">
         <div className="col-md-1"></div>
          <div className="col-md-10">
            <form>
              <div id="contact-form" className="form-container" data-form-container style={{color: 'rgb(46, 125, 50)', background:'white', height:'440px'}}>
              <div className="row modal-back-color ">
                <div className="closebtn" onClick={this.props.toggleStyleModal}><h5 style={{marginLeft:'870px', fontSize:'15px',fontWeight:'500'}}>X</h5></div>
                  <div className="form-title text-left" >
                    <h4 className="modal-head" > Edit Style </h4>
                  </div>
                </div>
                <div className="input-container " >
                <div className="row " >
                    <div className="col-md-12">
                    <form id="field">
                    <div className="row ">
                    <div className="col-md-6">
                    
                    
                    <div className="row fieldd-row">
           <div className="col-md-4">
                     <br/> <h5 className="field-names">Title: </h5><p className="subscriptt">(Section Heading)</p></div> <div className="col-md-8 modalrow"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.title} name="title"/><br/>
                     </div></div>
                    
                    <div className="row fieldd-row">
                    <div className="col-md-4">
                    <input type="checkbox"
          checked={!this.state.isBackgroundImage}
          onChange={this.toggleChange}
        /> <h5 className="field-names">Background Color: </h5> </div> <div className="col-md-8 modalrow"> <div style={ styles.swatch } onClick={ ()=>this.toggleColorPicker('bg') }>
          <div style={ styles.color } />
        </div>
        { this.state.displayBgColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ this.handleClose }/>
          <SketchPicker  color={this.state.backgroundColor} onChangeComplete={ this.handleChangeComplete }/><br/>
           </div> : null }
           </div>
           </div>
           
           <div className="row fieldd-row">
           <div className="col-md-4">
        <br/> <h5 className="field-names">Padding: </h5> <p className="subscriptt">(Top Right Bottom Left)</p></div> <div className="col-md-8 modalrow"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.padding} name="padding"/><br/>
        </div>
        </div>
        </div>


        <div className="col-md-6">
        <div className="row fieldd-row">
           <div className="col-md-4">
                    <br/> 
                    <h5 className="field-names">Color: </h5></div> <div className="col-md-8 modalrow"><div style={ styles.swatch} onClick={ ()=>this.toggleColorPicker('color') }>
          <div style={ styles.color2 } />
        </div>
        { this.state.displayColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ this.handleClose }/>
          <SketchPicker  color={this.state.color} onChangeComplete={ this.handleChangeColorComplete }/><br/>
        </div> : null }
        </div></div>
        <div className="row fieldd-row">
           <div className="col-md-4">
        <br/> <input type="checkbox"
          checked={this.state.isBackgroundImage}
          onChange={this.toggleChange}
        /> <h5 className="field-names">Background Image: </h5> <p className="subscriptt">(Image)</p></div> <div className="col-md-8 modalrow"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.backgroundImage} name="backgroundImage" disabled={!this.state.isBackgroundImage}/><br/>
         <FileBase64
        multiple={ false }
        onDone={ this.getFiles.bind(this) } />
        </div>
        </div>
                    
        <div className="row fieldd-row">
           <div className="col-md-4">
                       <h5 className="field-names">Margin: </h5> <p className="subscriptt">(Top Right Bottom Left)</p></div> <div className="col-md-8 modalrow"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.margin} name="margin"/><br/>
                     </div></div>

                  
                
                    
                {/* <button type="button"   className="imagebutton"> Save </button>*/}
                </div>
                </div>
                   </form>
                   
                      </div>
                      </div>





                  
                  <div className="row submit-row">
                    <div className="col-md-2"></div><div className="col-md-4">
                    <button type="button" onClick={()=>this.saveStyle(this.props.keyindex)} onKeyDown={this.handleKeyPress} className="btn btn-block savebutton submit-form valid">Save</button>
                    </div>
                    <div className="col-md-4">
                    <button type="button" onClick={this.props.toggleStyleModal} className="btn btn-block cancelbutton submit-form valid">Cancel</button>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div></div>
      </div>
    );
  

    }
}

export default Modal;