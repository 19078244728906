import React, { Component } from "react";
import "../../style/panel.css";
import axios from 'axios';
import FileBase64 from 'react-file-base64';
import {v1 as uuidv1} from 'uuid';
import Khub_HomepageSidebar from "./Khub_HomepageSidebar";
import { apiBaseUrl20, apiBaseUrl21, s3BucketUrl } from "../../components/apiUtils";

export default class Khub_EditSlider extends Component{
    constructor(props){
        super(props)
        this.state={
            sliderUrl:"",
            sliderImageUrl:"",
            id:''
        }
        this.handleChanges = this.handleChanges.bind(this)
    }

    componentDidMount(){
        let id = window.location.href.split('/').pop()

        this.setState({id:id})
        this.fetchSliderById(this,id)
    }

    fetchSliderById(that,id){
        axios.get(apiBaseUrl21+"homepageSlider/get/"+id)
        .then(function (response) {
          that.setState({resData:response.data,sliderUrl:response.data.sliderUrl,sliderImageUrl:response.data.sliderImageUrl});
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    handleChanges(e){
        let target = e.target;
        let name = target.name;
      
        this.setState({[name]:target.value});
    }
    
    getSlider(files){
        this.setState({banner:files[0]});
        this.fileUploadHandler(this,'sliderImageUrl')
    }

    fileUploadHandler = (that, docType) => {
        const id = uuidv1()
        const imageURL=s3BucketUrl+id+'.png';
        that.setState({ loading: true });
        axios.post(apiBaseUrl20+"uploadEditorImage",
          {
            "image": that.state.banner.base64,
            "imageName": that.state.banner.name,
            "id":id,
            "imageURL":imageURL
          })
          .then(function (response) {
            if (response.status === 200) {
              that.setState({ successupload: true });
              that.setState({ sliderImageName: that.state.banner.name });
              that.setState({ [docType]: imageURL });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }

    submitChanges(){
        var allData={
            sliderImageUrl:this.state.sliderImageUrl,
            sliderUrl:this.state.sliderUrl
        }
        axios({
            url:apiBaseUrl21+"homepageSlider/update/"+this.state.id,
            method:"POST",
            data:allData,
        }).then(function (response){
            if(response.status===200){
                alert("Slider has been updated")
                window.location.reload()
            }
            })
        
    }
    render(){
        return(
        <div>
            <div className="row">
                <div className='col-md-2 col-lg-2'><Khub_HomepageSidebar/></div>
                <div className="col-md-10 col-lg-10">
            <div className="container-fluid overflow-auto" style={{margin: "10px", height:'90vh',}}>
                <div style={{margin: "10px"}}><h3>Add Slider : </h3></div>

                <div className="container-fluid well well-sm" >
                <div class="input-group" style={{width:"80%"}}>
                    <span class="input-group-addon" style={{width:"20%"}}>Slider Url</span>
                    <input type="text" class="form-control" id="SliderUrl" placeholder="Enter Slider Url" name="sliderUrl" value={this.state.sliderUrl} onChange={this.handleChanges}></input>
                </div><br/>
                </div>

                <div className="form-group">
                <label className="control-label col-md-4" >Upload Slider Image</label>
                <div className="col-md-6"  >
                <FileBase64 multiple={ true } onDone={ this.getSlider.bind(this) }/></div>
                
                </div>
                <div className="col-md-4 col-lg-4"/>
                <div className="col-md-6 col-lg-6">
                {this.state.sliderImageUrl!==''?
                <img style={{width:'200px',height:'150px'}} src={this.state.sliderImageUrl} />
                :""
                }</div>
                <div ClassName="col-md-4" style={{width : "80%", position:"relative", height:"50px"}}>
                    <button type='button' class="btn btn-primary bottomright" style={{margin:'10px'}} id='save' onClick={()=>this.submitChanges()}>Update Slider</button>
                </div>
            </div></div>
        </div>
        </div>
        )
    }
}