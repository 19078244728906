import React, { useEffect, useState } from "react";
import axios from "axios";
function Respond({
  sections,
  fileURL,
  smartDocId,
  smartDocFunction,
  changeSection,
  setSmartDocVersionURL,
}) {
  const [section, setSection] = useState(sections[0]);
  const [attributes, setAttributes] = useState(null);
  const [alert, setAlert] = useState(null);
  useEffect(() => {
    const arr = {};
    for (let x of sections) {
      for (let y of x.fields) {
        arr[y] = "";
      }
    }
    setAttributes(arr);
  }, []);

  const handleAttributes = (e) => {
    const { name, value } = e.target;

    setAttributes((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const handleSectionChange = (sec) => {
    setSection(sec);
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log(JSON.stringify(.keys()));
    let newAttr = attributes;
    for (let x of Object.keys(newAttr)) {
      if (newAttr[x] == "") {
        let temp = "{" + x + "}";
        newAttr[x] = temp;
      }
    }

    var { data } = await axios({
      url: "/api/smartDoc/submission",
      method: "POST",
      data: {
        docURL: fileURL,
        smartDocId,
        attributeValues: newAttr,
      },
    });

    smartDocFunction((prev) => {
      return { ...prev, submission: data.data.value.submission };
    });
    const url =
      data.data.value.submission[data.data.value.submission.length - 1].docLink;
    setSmartDocVersionURL(url);
    setAlert({ type: "alert-success", msg: "Sucess!!!" });
    changeSection("C");
  };

  const handleNext = () => {
    for (let x = 0; x < sections.length; x++) {
      if (sections[x].sectionName == section.sectionName) {
        setSection(sections[x + 1]);
      }
    }
  };
  return (
    <div className="mb-5 respond-section" style={{ minHeight: "400px" }}>
      {sections.length === 0 && (
        <div
          className="d-flex align-items-center justify-content-center w-100"
          style={{ minHeight: "400px" }}
        >
          <h2 className="text-center m-5">
            PLEASE COMPLETE WITH BUILD SECTION
          </h2>
        </div>
      )}
      {alert && (
        <div className={"alert mt-5 my-3 mx-3 px-4 " + alert.type} role="alert">
          <h3>{alert.msg}</h3>
        </div>
      )}
      <div className="d-flex mt-4">
        <div className="col-md-4 ">
          <div className="dropdown  d-flex flex-column col-md-12">
            {sections &&
              sections.map((item) => {
                return (
                  <button
                    type="button"
                    className={
                      section.name === item.sectionName
                        ? "section-active btn mt-5 "
                        : " section-bg btn mt-5 "
                    }
                    onClick={() => {
                      handleSectionChange(item);
                    }}
                  >
                    {item.sectionName}
                  </button>
                );
              })}
          </div>
        </div>
        <div className="col-md-8 d-flex flex-column mt-5">
          {section && (
            <div className="col-md-12">
              {attributes &&
                section.fields.map((item) => {
                  return (
                    <div className="d-flex flex-column" key={item}>
                      <label
                        className="form-check-label control-label col-md-4 "
                        for="flexCheckDefault"
                        style={{ fontSize: "1.5rem" }}
                      >
                        {item}
                      </label>
                      <div className="col-md-12">
                        <input
                          name={item}
                          // value={data.client.customerId + " " + data.client.companyName}
                          className="form-control ember-text-field ember-view"
                          type="text"
                          placeholder={item}
                          style={{ outline: "1px solid lightgrey" }}
                          onChange={handleAttributes}
                          value={attributes[item]}
                        />
                      </div>
                    </div>
                  );
                })}
              <div className="w-100 d-flex justify-content-center mt-4">
                {section.sectionName ===
                sections[sections.length - 1].sectionName ? (
                  <button
                    className="col-md-5 mt-4 btn btn-lg  section-bg"
                    onClick={onSubmit}
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    className="col-md-5 mt-4 btn btn-lg  section-bg "
                    onClick={handleNext}
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Respond;
