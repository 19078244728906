import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import AddTask from "../Masters/taskMaster/addTask";
import AddIssue from "../Masters/IssueMaster/addIssues";
import AddWorkflow from "../Masters/workflowMaster/addWorkflow";
import AddEmailLog from "../Masters/LogsMaster/addEmailLog";
import AddPhoneLog from "../Masters/LogsMaster/addPhoneLog";
import AddTimeLog from "../Masters/LogsMaster/addTimeLog";
import AddExpenseLog from "../Masters/LogsMaster/addExpenseLog";

export default class AddModuleComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      isOpen: false,
      optionList: [],
      selected: "",
      selectedLog: "timeline",
      logsTabs: [
        {
          title: "Timeline",
          type: "timeline",
          isActive: true,
          tabSize: 3,
          tabColor: "#872079",
          link: "/communication-master/view/page/",
          listName: "thread",
          listType: "timeline",
        },
      
        {title: this.props.itemName,
        type: "work-task-issue",
        isActive: true,
        tabSize: 3,
        tabColor: "#115e95",
        link: "/communication-master/view/page/",
        listName: "thread",
        listType: "timeline",

        },
        {
          title: "Phone Logs",
          type: "Phone",
          tabSize: 2,
          tabColor: "#a13392",
          listType: "message",
          link: "/communication-master/view/page/Messages/",
          listName: "mails",
          templateList: "templatesForMail",
          docType: "Mail",
        },
        {
          title: "Email Logs",
          type: "Email",
          tabSize: 3,
          tabColor: "#c953b9",
          listType: "message",
          link: "/communication-master/view/page/Proposals/",
          listName: "proposals",
          templateList: "templatesForProposal",
          docType: "Proposal",
        },
        {
          title: "Time Logs",
          type: "Time",
          tabSize: 2,
          tabColor: "#d968c9",
          listType: "message",
          link: "/communication-master/view/page/Documents/",
          listName: "documents",
          templateList: "templatesForDocument",
          docType: "Document",
        },
        {
          title: "Expense Logs",
          type: "Expense",
          tabSize: 2,
          tabColor: "#db74cd",
          listType: "message",
          link: "/communication-master/view/page/Updates/",
          listName: "updates",
          templateList: "templatesForUpdate",
          docType: "Update",
        },
      ],
    };
  }

  componentDidMount() {
    const { list, optionList } = this.props;
console.log(this.props.list);
    this.setState({ list, optionList });
  }

  componentWillReceiveProps(nextProp) {
    if (nextProp.itemName != this.props.itemName) {
      const { list, optionList } = nextProp;
      this.setState({ list, optionList ,  logsTabs: [
        {
          title: "Timeline",
          type: "timeline",
          isActive: true,
          tabSize: 3,
          tabColor: "#872079",
          link: "/communication-master/view/page/",
          listName: "thread",
          listType: "timeline",
        },
      
        {title: nextProp.itemName,
        type: "work-task-issue",
        isActive: true,
        tabSize: 3,
        tabColor: "#115e95",
        link: "/communication-master/view/page/",
        listName: "thread",
        listType: "timeline",

        },
        {
          title: "Phone Logs",
          type: "Phone",
          tabSize: 2,
          tabColor: "#a13392",
          listType: "message",
          link: "/communication-master/view/page/Messages/",
          listName: "mails",
          templateList: "templatesForMail",
          docType: "Mail",
        },
        {
          title: "Email Logs",
          type: "Email",
          tabSize: 3,
          tabColor: "#c953b9",
          listType: "message",
          link: "/communication-master/view/page/Proposals/",
          listName: "proposals",
          templateList: "templatesForProposal",
          docType: "Proposal",
        },
        {
          title: "Time Logs",
          type: "Time",
          tabSize: 2,
          tabColor: "#d968c9",
          listType: "message",
          link: "/communication-master/view/page/Documents/",
          listName: "documents",
          templateList: "templatesForDocument",
          docType: "Document",
        },
        {
          title: "Expense Logs",
          type: "Expense",
          tabSize: 2,
          tabColor: "#db74cd",
          listType: "message",
          link: "/communication-master/view/page/Updates/",
          listName: "updates",
          templateList: "templatesForUpdate",
          docType: "Update",
        },
      ],});
      
    }

  }

  

  toggleModal = (type) => {
    this.setState({ isOpen: !this.state.isOpen, selected: type });
  };

  handleOnTabClick = (tab) => {
    this.setState({ selectedLog: tab.type }); //async
    console.log(tab.type);
  };

  getFormattedDate(millis) {
    return new Date(millis).toDateString();
  }
  getFormattedTime(millis) {
    return new Date(millis).toLocaleTimeString();
  }

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);

    console.log(this.state);
  };

  onSaveHandler = (newItem) => {
    const { type, handleOnSave, optionList, titleProp, idProp } = this.props;
    this.setState({
      selected: newItem.data,
    });

    const selected = newItem.data;

    this.setState({
      list: [
        ...this.state.list,
        {
          [titleProp]: selected[titleProp],
          [idProp]: selected[idProp],
          id: selected.id,
          date: selected.date,
          by: selected.by,
          type: selected.type || "",
        },
      ],
    });

    handleOnSave(type, selected);

    this.toggleModal();
  };

  render() {
    const {
      sliderState,
      value,
      titleProp,
      idProp,

      type,
      handleOnSave,
      itemName,
      changeSliderState,
      visibleState,
    } = this.props;
    const { optionList } = this.state;
    return (
      <div>
        <div className="row ">
          {this.state.logsTabs.map((item, index) => {

            //for issues, workflow, tasks
            return this.props.itemName == "workflow" && index <= 1 ? (
              <div
                className={
                  "col-md-6" + (item.isActive ? " activeOptionsAss " : "")
                }
                 style={(item.type=="timeline" ? 
                 (this.props.itemName == "workflow" ? { backgroundColor: "#985ed1" }
                 :this.props.itemName == "tasks" ? { backgroundColor: "#64b53c" } 
                 : this.props.itemName == "issues" ? { backgroundColor: "#dbd330" }
                 
                  :"")
                  : this.props.itemName == "workflow" ? { backgroundColor: "#a670db" }
                  :this.props.itemName == "tasks" ? { backgroundColor: "#6fbf47" } 
                  : this.props.itemName == "issues" ? { backgroundColor: "#ede547" }
                 
                  :"" )}
              >
                <div
                  className="options "
                  onClick={() => this.handleOnTabClick(item)}
                >
                  {item.title}
                </div>
              </div>
            ) 
            

            :this.props.itemName == "task" && index <= 1 ? (
              <div
                className={
                  "col-md-6" + (item.isActive ? " activeOptionsAss " : "")
                }
                 style={(item.type=="timeline" ? 
                 (this.props.itemName == "task" ? { backgroundColor: "#64b53c" } 
                 : this.props.itemName == "issue" ? { backgroundColor: "#dbd330" }
                 
                  :"")
                 
                  :this.props.itemName == "task" ? { backgroundColor: "#6fbf47" } 
                  :this.props.itemName == "issue" ? { backgroundColor: "#ede547" }
                 
                  :"" )}
              >
                <div
                  className="options "
                  onClick={() => this.handleOnTabClick(item)}
                >
                  {item.title}
                </div>
              </div>
            ) 
            

            : this.props.itemName == "issue" && index <= 1 ? (
              <div
                className={
                  "col-md-6" + (item.isActive ? " activeOptionsAss " : "")
                }
                 style={(item.type=="timeline" ? 
                 ( this.props.itemName == "issue" ? { backgroundColor: "#e8b305" }
                 
                  :"")
                  
                 
                  : this.props.itemName == "issue" ? { backgroundColor: "#fac823" }
                 
                  :"" )}
              >
                <div
                  className="options "
                  onClick={() => this.handleOnTabClick(item)}
                >
                  {item.title}
                </div>
              </div>
            ) 
            

          
          
          
          
            //for logs
            
            
            : this.props.itemName == "log" && index !=1 ? (
              <div
                className={
                  "col-md-" +
                  item.tabSize +
                  (item.isActive ? " activeOptionsAss " : "")
                }
                style={{ backgroundColor: item.tabColor }}
              >
                <div
                  className="options "
                  onClick={() => this.handleOnTabClick(item)}
                >
                  {item.title}
                </div>
              </div>
            ) : (
              ""
            );
          })}
        </div>

          





        {/*heading type*/}
        <div style={this.props.itemName=="workflow" ? this.state.selectedLog=="timeline" ? {backgroundColor: "#985ed1", color: "#ffffff" }:{backgroundColor: "#a670db", color: "#ffffff" }:
        this.props.itemName=="task" ? this.state.selectedLog=="timeline" ? {backgroundColor: "#64b53c", color: "#ffffff" }:{backgroundColor: "#6fbf47", color: "#ffffff" }:
        this.props.itemName=="issue" ? this.state.selectedLog=="timeline" ? {backgroundColor: "#e8b305", color: "#ffffff" }:{backgroundColor: "#fac823", color: "#ffffff" }:
        this.props.itemName=="log" ? this.state.selectedLog=="timeline" ? {backgroundColor: "#872079", color: "#ffffff" }:this.state.selectedLog=="Phone" ? {backgroundColor: "#a13392", color: "#ffffff" }:this.state.selectedLog=="Time" ? {backgroundColor: "#d968c9", color: "#ffffff" } : this.state.selectedLog=="Expense"? {backgroundColor: "#db74cd", color: "#ffffff" }: {backgroundColor: "#c953b9", color: "#ffffff" }:
        ""} 
        
        
        
        
        
        className=" header">
        {!this.state.selectedLog ? "Your Timeline" : this.state.selectedLog=="timeline" ? "Your Timeline" : this.props.itemName == "log" 
                ? (this.state.selectedLog.charAt(0).toUpperCase() +this.state.selectedLog.slice(1).toLowerCase()+ " "+ "Logs")
                : this.props.itemName.charAt(0).toUpperCase()+this.props.itemName.slice(1).toLowerCase()+"s" }
        </div>
        <div
          className="row"
          style={{
            backgroundColor: "#eaeaea",
            padding: "5px 30px 5px 30px",
            textAlign: "right",
            fontSize: "17px",
            fontFamily: "montserrat",
            fontWeight: "500",
          }}
        >

          {/* ADD ROW shift alt a */}
         {this.state.selectedLog!='timeline' ?  <div className="butn" onClick={() => this.toggleModal()}>
            <i
              className="fa fa-send"
              style={{ fontSize: "14px" }}
              aria-hidden="true"
            ></i>
            <span>
            
              Add {" "}
              {this.props.itemName == "log" 
                ? this.state.selectedLog.charAt(0).toUpperCase() + this.state.selectedLog.slice(1).toLowerCase()+" Log"
                : this.props.itemName.charAt(0).toUpperCase() + this.props.itemName.slice(1).toLowerCase() }
            </span>
          </div> : ''}


        {/*   {this.props.itemName != "log" &&  this.state.selectedLog!='timeline' ? <div className="butn" onClick={() => this.toggleModal()}>
            <i
              className="fa fa-send"
              style={{ fontSize: "14px" }}
              aria-hidden="true"
            ></i>
            <span>
            
              Add {" "}
              {this.props.itemName == "log" 
                ? this.state.selectedLog.charAt(0).toUpperCase() + this.state.selectedLog.slice(1).toLowerCase()
                : this.props.itemName.charAt(0).toUpperCase() + this.props.itemName.slice(1).toLowerCase() }
            </span>
          </div> : ''}   
 */}
    {/*       {this.props.itemName != "log" && this.props.itemName== "work-type-issue" ? <div className="butn" onClick={() => this.toggleModal()}>
            <i
              className="fa fa-send"
              style={{ fontSize: "14px" }}
              aria-hidden="true"
            ></i>
            <span>
            
              Add {" "}
              {this.props.itemName == "log" 
                  ? this.state.selectedLog.charAt(0).toUpperCase() + this.state.selectedLog.slice(1).toLowerCase()
                  : this.props.itemName.charAt(0).toUpperCase() + this.props.itemName.slice(1).toLowerCase() }
            </span>
          </div> : ''} */}
        </div>




{/*Timeline*/}

{this.state.selectedLog=="timeline" ?
<div>
        <div className="row">
          <div className="col-md-12 offset-md-3">
           

            <ul
              className="timeline-user-comm"
              style={{ backgroundColor: "white" }}
            >
              
              {this.state.list.map((thred, index) => (
                <li>
                  <div
                    className={
                      (thred.docType == "Proposal"
                        ? "RED"
                        : thred.docType == "Document"
                        ? "GREEN"
                        : thred.docType == "Mail"
                        ? "YELLOW"
                        : "BLUE") + "-mail timeline-user-comm-card"
                    }
                  >
                    <div className="row ">
                      <div className="col-md-8">
                        {" "}
                        <h4 className="montserrat timeline-user-comm-head">
                          {" "}
                          <Link
                               to={
                                (type == "logs"
                                  ? thred.type == "Expense"
                                    ? `/${type}/view-expenses/`
                                    : `/${type}/view-${thred.type.toLowerCase()}Logs/`
                                  : type== "workflows" ? `/workflow/view/` : `/${type}/view-${type}/`) + thred.id
                              }
                            className="timeline-user-comm-elements"
                          >
                             {thred[titleProp]}
                          </Link>
                       
                        </h4>
                      </div>
                      <div className="col-md-4 text-right">
                     
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        Sent
                      </div>
                      <div className="col-md-4">
                        {" "}
                        <h4 className="montserrat timeline-user-comm-subhead">
                          {" "}
                          <a
                            href="#"
                            className=" timeline-user-comm-elements float-right"
                          >
                            {this.getFormattedDate(thred.date)}
                          </a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      :
        
        
        <div className="row">{/*list*/}
          <div className="col-md-12 offset-md-3">
            <ul className="timeline3" style={{ backgroundColor: "white" }}>
              <li>
                <tr className="doc-section">
                  <div className="row doc-head-row">
                    <th className="table-head text-center">Id</th>

                    <th className="table-head text-center">Name</th>

                    <th className="table-head text-center">Date of Creation</th>

                    <th className="table-head text-center">Time of Creation</th>

                    <th className="table-head text-center">By</th>
                  </div>

                  <ul className="proposalsss">
                    {this.props.itemName != "log" &&
                      this.state.list.map((thred, index) => (
                        <li>
                          <div className={thred.type + " row doc-head-row"}>
                            <td className="table-item text-center">
                              {thred[idProp]}
                            </td>

                            <td className="table-item text-center">
                              <Link
                                to={
                                  (type == "logs"
                                    ? thred.type == "Expense"
                                      ? `/${type}/view-expenses/`
                                      : `/${type}/view-${thred.type.toLowerCase()}Logs/`
                                    : `/${type}/view-${type}/`) + thred.id
                                }
                              >
                                {" "}
                                {thred[titleProp]}
                              </Link>
                            </td>

                            <td className="table-item text-center">
                              {this.getFormattedDate(thred.date)}
                            </td>

                            <td className="table-item text-center">
                              {this.getFormattedTime(thred.date)}
                            </td>

                            <td className="table-item text-center">
                              {thred.by}
                            </td>
                          </div>
                        </li>
                      ))}

                    {this.props.itemName == "log" &&
                      this.state.list.map((thred, index) => (
                        this.state.selectedLog == thred.type || this.state.selectedLog=='timeline' ? (
                        <li>
                          <div className={thred.type + " row doc-head-row"}>
                            <td className="table-item text-center">
                              {thred[idProp]} | {thred.type}
                            </td>

                            <td className="table-item text-center">
                              <Link
                                to={
                                  (type == "logs"
                                    ? thred.type == "Expense"
                                      ? `/${type}/view-expenses/`
                                      : `/${type}/view-${thred.type.toLowerCase()}Logs/`
                                    : `/${type}/view-${type}/`) + thred.id
                                }
                              >
                                {" "}
                                {thred[titleProp]}
                              </Link>
                            </td>

                            <td className="table-item text-center">
                              {this.getFormattedDate(thred.date)}
                            </td>

                            <td className="table-item text-center">
                              {this.getFormattedTime(thred.date)}
                            </td>

                            <td className="table-item text-center">
                              {thred.by}
                            </td>
                          </div>
                        </li>
                      ):'' ))}
                  </ul>
                </tr>
              </li>
            </ul>
          </div>
        </div>

                              }

        <Modal isOpen={this.state.isOpen} onRequestClose={this.toggleModal}>
          {this.props.itemName == "task" ? (
            <AddTask
              mode="component"
              onSaveHandler={this.onSaveHandler}
              assignmentData={this.props.assignmentData}
            />
          ) : this.props.itemName == "issue" ? (
            <AddIssue
              mode="component"
              onSaveHandler={this.onSaveHandler}
              assignmentData={this.props.assignmentData}
            />
          ) : this.props.itemName == "workflow" ? (
            <AddWorkflow
              mode="component"
              onSaveHandler={this.onSaveHandler}
              assignmentData={this.props.assignmentData}
            />
          ) : this.props.itemName == "log" ? (
            this.state.selectedLog == "Phone" ? (
              <AddPhoneLog
                mode="component"
                onSaveHandler={this.onSaveHandler}
                assignmentData={this.props.assignmentData}
              />
            ) : this.state.selectedLog == "Email" ? (
              <AddEmailLog
                mode="component"
                onSaveHandler={this.onSaveHandler}
                assignmentData={this.props.assignmentData}
              />
            ) : this.state.selectedLog == "Expense" ? (
              <AddExpenseLog
                mode="component"
                onSaveHandler={this.onSaveHandler}
                assignmentData={this.props.assignmentData}
              />
            ) : this.state.selectedLog == "Time" ? (
              <AddTimeLog
                mode="component"
                onSaveHandler={this.onSaveHandler}
                assignmentData={this.props.assignmentData}
              />
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </Modal>
      </div>
    );
  }
}
