import React from "react";
import axios from 'axios';
import ReactTable from "react-table";
import CustomSidebar from "./CustomSidebar";
import {
    Col,
    Row,
  } from "reactstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from 'underscore';
import Modal from 'react-modal';

const customStyles={
    content:{
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        height                : '80vh',
        width                 : '70%',
        transform             : 'translate(-50%, -50%)'
    }
}

class DragTrComponent extends React.Component {

    render() {
      const { children = null, rowInfo } = this.props;
      if (rowInfo) {
        // debugger;
        const { original, index } = rowInfo;
        const { id } = original;
        return (   
       
          <Draggable key={id} index={index} draggableId={id}>
            {(draggableProvided, draggableSnapshot) => (
              <div
                ref={draggableProvided.innerRef}
                {...draggableProvided.draggableProps}
                {...draggableProvided.dragHandleProps}
              >
                <ReactTable.defaultProps.TrComponent style={{ width: '100%' }}>
                  {children}
                </ReactTable.defaultProps.TrComponent>
              </div>
            )}
          </Draggable>
        )
        
            
      }
       else
        return (
          <ReactTable.defaultProps.TrComponent  >
            {children}
          </ReactTable.defaultProps.TrComponent>
        );
    }
  }
  
class DropTbodyComponent extends React.Component {
    render() {
      const { children = null } = this.props;
  
      return (
        <Droppable droppableId="droppable">
          {(droppableProvided, droppableSnapshot) => (
            <div ref={droppableProvided.innerRef}>
              <ReactTable.defaultProps.TbodyComponent  >
                {children}
              </ReactTable.defaultProps.TbodyComponent>
            </div>
          )}
        </Droppable>
      );
    }
  }

export default class listCustomCategory extends React.Component{

    constructor(props){
        super(props)
        this.state={
            resData:[],
            pageHeading:'',
            ModalIsOpen:false,
            id:'',
            updateResData:[],
            isRearrange:false
        }
        this.editAct=this.editAct.bind(this)
        this.deleteAct=this.deleteAct.bind(this)
        this.openModal=this.openModal.bind(this)
        this.closeModal=this.closeModal.bind(this)
        this.saveCategory=this.saveCategory.bind(this)
        this.toggleRearrange=this.toggleRearrange.bind(this)
    }

    componentDidMount(){
        var lastUrl = window.location.pathname.split('/').pop()

        this.setType(lastUrl)
    }
    setType(lastUrl){

        let page=''
        if(lastUrl==='circular-notification'){
            page='Circular Notification'
          }
          else if(lastUrl==='case-law'){
            page="Case Law"
          }
          else if(lastUrl==='acts'){
            page='Acts'
          }
          else if(lastUrl==='rules'){
            page='Rules'
          }
          this.setState({pageHeading:page})
          this.fetchCategory(page)
    }

    fetchCategory(page){
        let allCategory=[]
        return axios({
          url: 'https://3j0okb7n5h.execute-api.us-east-1.amazonaws.com/dev/businus/legalCategory/list',
          method: 'GET',
          crossDomain: true,
          responseType: 'json', // important
        }).then((response) => {
          for(var i=0;i<response.data.length;i++){
            if(response.data[i].legalType==='Custom')
              if(response.data[i].type===page){ 
              allCategory.push(response.data[i])
            }
        }

          
          this.setState({resData:_.sortBy(allCategory,'index')})
         
       });
    }

    updateIndex(Array,index){
        var act={
            id:Array.id,
            legalType:"Custom",
            type:this.state.pageHeading,
            law:Array.law,
            lawDes:Array.lawDes,
            index:index,
            legalCatgoryId:Array.legalCatgoryId,
        }
        
        axios({
          url: 'https://3j0okb7n5h.execute-api.us-east-1.amazonaws.com/dev/businus/legalCategory/update/' + Array.id,
          method: 'POST',
          data: act,
        }).then((response) => {
          console.log('resonse_update_act :', response.data);

        });
      }

    handleDragEnd = result => {
        if (!result.destination) {
          return;
        }
    
        // var data =[]
        else{
          const resData = reorder(
            this.state.resData,
            result.source.index,
            result.destination.index
          );
          var temp =[]
          let source = result.source.index
          let destination = result.destination.index
    
    
          for(var i =0; i< this.state.resData.length;i++){
            if(this.state.resData[i].index===source){
              temp= this.state.resData[i]
            }
          }
    
          if(source<destination){
            for(var i =0; i< this.state.resData.length;i++){      
              for(var j=source+1;j<destination+1;j++){
                if(j===this.state.resData[i].index){
                  this.updateIndex(this.state.resData[i],j-1)
                }           
              }
            }
          }
          
          if(destination<source){
            for(var i =this.state.resData.length-1; i>=0 ;i--){  
              for(var j=source-1;j>destination-1;j--){         
    
                if(j===this.state.resData[i].index){
                  this.updateIndex(this.state.resData[i],j+1)
                }           
              }
            }
          }
          
          this.updateIndex(temp,destination)
      
          this.setState({
            resData
          });
        }
    
      };

    async editAct(id){
        await this.openModal()

        let allCategory=this.state.resData
        for(var i=0;i<allCategory.length;i++){
            if(allCategory[i].id===id){
                document.getElementById('law').value=allCategory[i].law
                document.getElementById('lawDes').value=allCategory[i].lawDes
                this.setState({id:id,updateResData:allCategory[i]})
            }
        }
        
    }

    deleteAct(id){
        if (window.confirm("Are you sure you want to delete this category?")) {

            axios({
              url: 'https://3j0okb7n5h.execute-api.us-east-1.amazonaws.com/dev/businus/legalCategory/get/' + id,
              method: 'Delete',
            }).then((response) => {
              console.log('resonse_deleteAct :', response.data);
              this.fetchCategory(this.state.pageHeading);
            });
      
          }
    }

    getTrProps = (state, rowInfo) => {
        //  console.log(rowInfo);
        return { rowInfo };
    };

    closeModal(){
        this.setState({ModalIsOpen:false})
    }
    
    openModal(){
        this.setState({ModalIsOpen:true})
    }



    saveCategory(){

        let Array=this.state.updateResData

        let law= document.getElementById('law')
        let lawDes=document.getElementById('lawDes')

        if(law.value.trim()===""){
            alert("Please add the category")
        }
        if(law.value.trim()!==""){
            let data={
                id:Array.id,
                legalType:"Custom",
                type:this.state.pageHeading,
                law:law.value.trim(),
                lawDes:lawDes.value.trim(),
                index:Array.index,
                legalCatgoryId:Array.legalCatgoryId,
            }

            axios({
                url: 'https://3j0okb7n5h.execute-api.us-east-1.amazonaws.com/dev/businus/legalCategory/update/' + Array.id,
                method: 'POST',
                data: data,
              }).then((response) => {
                console.log('resonse_update_act :', response.data);
                if(response.status===200){
                    alert("Category Updated")
                    window.location.reload()
                }
              });
        }

    }

    toggleRearrange(that){
      that.setState({isRearrange:!that.state.isRearrange,})
      
      if(that.state.isRearrange){
        that.fetchCategory(that.state.pageHeading)
      }
    }

    render(){
        const columns = [
            {
            Header: <div className="form-textHead cell-head">Actions</div>,
            accessor: 'id',
            Cell: props =>  <div className='' style={{}}>
                              <button type="button" style={{margin: "3px"}} className="btn btn-primary" data-toggle="modal" data-target="#myModal" onClick={e=>this.editAct(props.value)}><span className="fas fa-edit"></span></button>
                              <button type="button" style={{margin: "3px"}} className="btn btn-danger" onClick={e=>this.deleteAct(props.value)}><span className="fas fa-trash"></span></button>
                            </div>,
            minWidth:150,
          },
          {
            Header: <div className="form-textHead cell-head">Article Id</div>,
            accessor: 'legalCatgoryId',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:150,
          }, {
            Header: <div className="form-textHead cell-head">Type</div>,
            accessor: 'type',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:100,
          }, {
            Header: <div className="form-textHead cell-head">Law</div>,
            accessor: 'law',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:100,
          }, {
            Header: <div className="form-textHead cell-head">Law Description</div>,
            accessor: 'lawDes',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:200,
          },{
            Header: props => <span className="form-textHead cell-head"> Date</span>,
            accessor: 'date',
            filterMethod: (filter, row) =>
                     {   return row.source.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => 
            new Date(props.value).toDateString(),
            minWidth:180
          },{
            Header: props => <span className="form-textHead cell-head"> Time</span>,
            accessor: 'date',
            filterMethod: (filter, row) =>
                     {   return row.source.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => 
            new Date(props.value).toLocaleTimeString(),
            minWidth:180
          }]
        return(
            <div>
                <div className="col-md-2 col-lg-2">
                    <CustomSidebar/>
                </div>
                <div className='col-md-10 col-lg-10'>
                    <div className="section">
                        <div className="DIV">
                        {/* <BreadCrumbs/> */}
                        <div className='row'>
        <div className="col-md-9 col-lg-9" style={{margin: "10px"}}><h3>{this.state.pageHeading} List : </h3></div>
        <div className="col-md-2 col-lg-2" style={{marginTop:'2%'}}> <div className="download-button">{this.state.isRearrange?<button onClick={()=>this.toggleRearrange(this)}>Exit</button>:<button onClick={()=>this.toggleRearrange(this)}>Rearrange</button>}</div> </div>
        </div>
                        <div className='row'>
                        <Row>
                <Col>
                            <div className='card'>
                            {this.state.isRearrange?
                            <DragDropContext onDragEnd={this.handleDragEnd}> 
         <ReactTable 
            TrComponent={DragTrComponent}
            TbodyComponent={DropTbodyComponent}
            getTrProps={this.getTrProps} 
            className="myTable" 
            data={this.state.resData}
            columns={columns} 
            filterable/>
        </DragDropContext>:
        <ReactTable data={this.state.resData}
        columns={columns}
        filterable/>}
                            </div>
                </Col>
                </Row>
                        </div>

        <div className="row">
            <Modal
            isOpen={this.state.ModalIsOpen}
            onRequestClose={this.closeModal}
            style={customStyles}
            >
                {this.state.ModalIsOpen?
                <div>
                                    <div className='modal-close' onClick={this.closeModal}>&times;</div>
                <div>
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                        <h3 className=""> Update Custom Category </h3>

                        <div>
                        <div class="well well-sm">
                        <div class="input-group mb-3" style={{width:"80%"}}>
                        <span type="text" class="input-group-addon" id="" style={{width:"20%"}}>Column one</span>
                        <input type="text" class="form-control" list="lawlist" id="law" placeholder="Heading" autocomplete="off" style={{}} required></input>
                        <input type="text" class="form-control" list='lawDeslist' id="lawDes" placeholder="Description" style={{}} autoComplete='off' required></input>
                        </div><br/>  

        </div> </div>
        <div ClassName="col-md-4" style={{width : "80%", position:"relative", height:"90px"}}>
            <button type='button' class="btn btn-primary" style={{margin:'10px',float:'right'}} onClick={()=>this.saveCategory()}>Update Category</button>
        </div>
                        </div>
                    </div>
                </div>
                </div>
                :""}

            </Modal>
        </div>

                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
}

 