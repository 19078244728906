import React, { Component } from "react";
import "../../style/panel.css";
import { Link } from "react-router-dom";
import $ from "jquery";

export default class WealthBlogSidebar extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []

    };

  }
  componentDidMount(){
    this.handleLine();
  }
  handleLine(){

    $(function(){
      $("#c1").click(function(){
        $("#d1").toggle();
      });
      });
      $(function(){
      $("#blog").click(function(){
        $("#category").toggle();
      });
      });
      $(function(){
        $("#category").click(function(){
          $("#addCategory").toggle();
        });
      });
      $(function(){
        $("#blog").click(function(){
          $("#blog1").toggle();
        });
      });
      $(function(){
        $("#blog1").click(function(){
          $("#addBlog").toggle();
        });
      });
     
  }

  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (

            <div className="sidebar">
              <div id="sidebar1">
                <div className="sidebar-elementhead"><Link className="sidebar-link" to="/knowledgeHub">Home</Link></div>
                <div className="oneTab">
                <div className="sidebar-element"> <i class="fa fa-bar-chart" />Dashboard<i className="fa fa-angle-right " /></div>
                {/* <div className="sidebar-subelement" id="d1"><i class="fa fa-envelope" />View<i className="fa fa-angle-right " /></div> */}
                </div>
                <div className="oneTab">
                  <div className="sidebar-element" id="blog">
                    <i class="fa fa-envelope"/>
                    Stories & Insights
                    <i className="fa fa-angle-down " />
                  </div>
                  <h2 className="sidebar-subelement" id='category'>
                  <i class="fa fa-envelope" />
                     Blog Category
                  <i className="fa fa-angle-down "/>
                  </h2>
                  <div className="sidebar-subelement2" id="addCategory">
                  <Link className="sidebar-link" to="/knowledgeHub/add/wealth-blogCategory">
                    <i class="fa fa-envelope" />
                    Add
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                  <Link className="sidebar-link" to="/knowledgeHub/view/wealth-blogCategory">
                    <i class="fa fa-envelope" />
                    View 
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                </div>

                <h2 className="sidebar-subelement" id='blog1'>
                  <i class="fa fa-envelope" />
                     Blog
                  <i className="fa fa-angle-down "/>
                  </h2>

                  <div className="sidebar-subelement2" id="addBlog">
                  <Link className="sidebar-link" to="/knowledgeHub/add/wealth-article">
                    <i class="fa fa-envelope" />
                    Add
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                  <Link className="sidebar-link" to="/knowledgeHub/view/wealth-article">
                    <i class="fa fa-envelope" />
                    View 
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                </div>

                 
                </div>

              </div>
            </div>

    );
  }
}
