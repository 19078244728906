import React from 'react';
import axios from 'axios';
import "../../style/panel.css";
import ReactTable from 'react-table';
import 'react-quill/dist/quill.snow.css'; // ES6
import _ from 'underscore';
import EditorJs from 'react-editor-js';
import {EditorjsTool} from '../Editor/tool'
import {v1 as uuidv1} from 'uuid';
import Image from "@editorjs/image";
import BreadCrumbs from '../../components/BreadCrumbs';
import EximBlogSidebar from './EximBlogSidebar';
import { Link } from 'react-router-dom';
import { apiBaseUrl20, s3BucketUrl } from '../../components/apiUtils';

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export default class ListEximBlog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      text: "",
      article: {},
      resdata: [],
      encodedBlogImage: "",
      encodedAuthorImage: "",
      u:0,
      more:[],
      authorImageURL:"",
      homepageNavbarCount:0,
      homepageCardCount:0,
    }
    this.handleChange = this.handleChange.bind(this);
    this.fetch_from_dynamo = this.fetch_from_dynamo.bind(this);
    this.editArticle = this.editArticle.bind(this);
    this.encodeImage = this.encodeImage.bind(this);
    this.update_blog = this.update_blog.bind(this);
    this.encodeImageReader = this.encodeImageReader.bind(this);
    this.addUrl = this.addUrl.bind(this);
    this.incrementu = this.incrementu.bind(this);
  }

  componentDidMount(){
    this.fetch_from_dynamo()
  }


  handleChange(value) {
    this.setState({ text: value })
    document.getElementById('save').innerHTML = 'Save';
    document.getElementById('save').className = 'btn btn-primary';
  }


  fetch_from_dynamo=()=>{
      let temp_data=[]
      let countNav=0
      let countCard=0
    return axios({
      url: (apiBaseUrl20+'blog/list'),
      method: 'GET',
      crossDomain: true,
      responseType: 'json', // important
    }).then((response) => {
      
      for (var i=0; i<response.data.length;i++){
        if(response.data[i].learningCategory==='exim'){
          temp_data.push(response.data[i])
        }
        if(response.data[i].homepageNavbar){
          countNav++
        }
        if(response.data[i].homepageCard){
          countCard++
        }
      }

      this.setState({resdata:temp_data,homepageNavbarCount:countNav,homepageCardCount:countCard}) 

   });
  }

  fetch_single_data(id){
    return axios({
      url: (apiBaseUrl20+'blog/get/'+id),
      method: 'GET',
      crossDomain: true,
      responseType: 'json', // important
    }).then((response) => {
      return response.data;

   });
  }


  editArticle(art_id) {
    
    document.getElementById('save').innerHTML = 'Update';
    document.getElementById('save').className = 'btn btn-primary';
    this.setState({
      text:  '',
      article: "",
      encodedBlogImage: "noUpdate",
      encodedAuthorImage: "noUpdate",
    });
    this.fetch_single_data(art_id).then(data => {
      
      this.setState({
        article:  data,
        text:  data.content,
      });
      document.getElementById('heading').value = data.heading;
      document.getElementById('subHeading').value = data.subHeading;
      document.getElementById('source').value = data.source;
      document.getElementById('sourceUrl').value = data.sourceUrl
      document.getElementById('tags').value = data.tags;
      document.getElementById('datePosted').value = data.datePosted;
      document.getElementById('readTime').value = data.readTime;
      data.seo===undefined? document.getElementById("seo").value='': document.getElementById("seo").value = data.seo;
      data.slug===undefined? document.getElementById('slug').value ='': document.getElementById('slug').value = data.slug;
      data.metaDescription===undefined? document.getElementById('metaDescription').value = '': document.getElementById('metaDescription').value = data.metaDescription; 
      if(data.blogType!='curated'){document.getElementById('authorName').value = data.authorName};
      if(data.blogType!='curated'){document.getElementById('authorDescription').value = data.authorDescription};
      if(data.blogType!='curated'){document.getElementById('blogImageCredit').value = data.blogImageCredit};
      if(data.blogType!=="curated"){
        document.getElementById('authorName').value = data.authorName;
        document.getElementById('authorDescription').value = data.authorDescription;
        document.getElementById('blogImageCredit').value = data.blogImageCredit;
        this.setState({authorImageURL:data.authorImageUrl})
      }
      if(data.more!=undefined){
        this.setState({more:data.more.external,u:data.more.external.length})

        for(var i=0; i< data.more.external.length; i++){
          document.getElementById("src_"+i).value=data.more.external[i].src
          document.getElementById("url_"+i).value=data.more.external[i].url
          document.getElementById("srcName_"+i).value=data.more.external[i].name
        }
      }
      if(data.more==undefined){
        this.setState({more:undefined})
      }

    });
  }

  encodeImage(imageid){
    var image = document.getElementById(imageid).files[0];
    this.encodeImageReader(imageid, image);
  }


  encodeImageReader(imageid, image){
    var reader  = new FileReader();
    var encodedImage ="";
    reader.addEventListener("load", function (e) {
      encodedImage = e.target.result.replace(/data:image\/(png|jpeg|jpg);base64,/, '');
      if (imageid=="blogImage") {
        this.setState({ encodedBlogImage:  encodedImage});
        document.getElementById('blogImagePreview').src=e.target.result;
        document.getElementById('blogImagePreview').style.display="block";
      }else if (imageid=="authorImage") {
        this.setState({ encodedAuthorImage:  encodedImage});
        document.getElementById('authorImagePreview').src=e.target.result;
        document.getElementById('authorImagePreview').style.display="block";
      }
    }.bind(this), false);
    reader.readAsDataURL(image);
  }

  uploadByFile(file) {

    let fd = new FormData();
    fd.append('file',file);
  }

 async update_blog(){
    var data= this.state.article

    const content = await this.editorInstance.save()

    var external = []
    var internal = []
    for(var i= 0; i< this.state.u; i++){
      external[i]={
        src: document.getElementById("src_"+ i).value,
        url: document.getElementById('url_'+ i).value,
        name: document.getElementById('srcName_'+ i).value
      } 
    }

    var more ={
      external : external,
      internal : internal,
    }

    var blg = {
      id: this.state.article.id,
      blogImageUrl: this.state.article.blogImageUrl,
      authorImageUrl: this.state.article.authorImageUrl,
      blogImageId: this.state.article.blogImageId,
      authorImageId: this.state.article.authorImageId,
      learningCategory:this.state.article.learningCategory,
      heading: document.getElementById('heading').value,
      subHeading: document.getElementById('subHeading').value,
      dateModified: "0000-00-00",
      authorName: data.blogType!='curated'? document.getElementById('authorName').value:'',
      authorDescription: data.blogType!='curated'? document.getElementById('authorDescription').value:'',
      readTime: document.getElementById('readTime').value,
      source: document.getElementById('source').value,
      sourceUrl:document.getElementById('sourceUrl').value,
      content: content,
      blogImageCredit: data.blogType!='curated'? document.getElementById('blogImageCredit').value:'',
      // catagory: document.getElementById('catagory').value,
      tags: document.getElementById('tags').value.split(","),
      more:more,
      seo:document.getElementById('seo').value,
      slug:document.getElementById('slug').value,
      metaDescription:document.getElementById('metaDescription').value
    };

    this.updateBlog(blg,this.state.article.id)
  }


  deleteArticle(art_id, blogImageId, authorImageId){

    if (window.confirm("Are you sure you want to Delete Article '" + art_id +"' ?")) {

      axios({
        url: (apiBaseUrl20+'blog/get/' + art_id),
        method: 'Delete',
        data: {
          blogImageId: blogImageId,
          authorImageId: authorImageId,
        },
      }).then((response) => {
        console.log('resonse_deleteArticle :', response.data);
        this.fetch_all_blogs();
        alert("Blog Deleted");
      });

    } else {

    }
  }

  incrementu() {
    // increments variable for no. of urls in MORE

    var inc = this.state.u + 1;
    this.setState({
      u : inc
    });
  }

  addUrl(){
    // adds a URL entry in MORE
    
    var out_div = document.createElement('div');
    var spn = document.createElement('span');
    var src = document.createElement("INPUT");
    var url = document.createElement("INPUT");
    var srcName = document.createElement("INPUT");
    var br = document.createElement("br");
    this.incrementu();
    var i = this.state.u;

    out_div.setAttribute("class","input-group mb-3");
    out_div.style.width = '80%';
    // out_div.setAttribute("id", "urldiv_" + i);

    spn.setAttribute("type", "text");
    spn.setAttribute("class", "input-group-addon");
    spn.style.width = '20%';
    spn.innerHTML = "Source Url";
    // spn.setAttribute("id", "lat_" + i);

    src.setAttribute("type", "text");
    src.setAttribute("class", "form-control");
    src.setAttribute("placeholder", "Source");
    src.setAttribute("id", "src_" + i);

    url.setAttribute("type", "text");
    url.setAttribute("class", "form-control");
    url.setAttribute("placeholder", "Url");
    url.setAttribute("id", "url_" + i);

    srcName.setAttribute("type", "text");
    srcName.setAttribute("class", "form-control");
    srcName.setAttribute("placeholder", "Name");
    srcName.setAttribute("id", "srcName_" + i);

    out_div.appendChild(spn);
    out_div.appendChild(src);
    out_div.appendChild(url);
    out_div.appendChild(srcName);
    document.getElementById("more").appendChild(out_div);
    document.getElementById("more").appendChild(br);

  }

updateHomepgaeNavbar(navbarValue,id){
  navbarValue= !navbarValue
  let data={
    homepageNavbar:navbarValue
  }
  if(navbarValue===false || this.state.homepageNavbarCount<6){
    this.updateBlog(data,id)
  }
  else{
    alert("Can't Add more then 6 values in navbar")
  }
}

updateHomepgaeCard(cardValue,id){
  
  cardValue= !cardValue
  let data={
    homepageCard:cardValue
  }
  if(cardValue===false || this.state.homepageCardCount<4){
    this.updateBlog(data,id)
  }
  else{
    alert("Can't Add more then 4 values in Card")
  }
}

updateBlog=(data,id)=>{
  axios({
    url: (apiBaseUrl20+'blog/update/' + id),
    method: 'POST',
    data:data,
  }).then((response) => {
    if(response.status===200){
      alert("Blog Updated");
      window.location.reload()
    }    
  });
}

  render() {
    const columns = [
    {
      Header: <div className="form-textHead cell-head">Blog Id</div>,
      accessor: d=>{return{id:d.id,blogId:d.blogId}},
      id:'blogId',
      filterMethod: (filter, row) => {   return row.catagory.toLowerCase().includes(filter.value.toLowerCase()) },
      Cell: props => <span className='form-text cell'><Link to={'/knowledgeHub/view-article/'+props.value.id}>{props.value.blogId}</Link></span>,
      minWidth:120
    }, 
    {
      Header: <div className="form-textHead cell-head">Homepage Navbar</div>,
      accessor: d =>{return{id:d.id,homepageNavbar:d.homepageNavbar,blogId:d.blogId}},
      id: 'blogId',
      filterMethod: (filter, row) => {   return row.catagory.toLowerCase().includes(filter.value.toLowerCase()) },
      Cell: props =><div> 
      <button onClick={()=>this.updateHomepgaeNavbar(props.value.homepageNavbar,props.value.id)}>{props.value.homepageNavbar?"Remove":"Add"}</button></div>,
      minWidth:220
    },
    {
      Header: <div className="form-textHead cell-head">Homepage Card</div>,
      accessor: d =>{return{_id:d._id,id:d.id,homepageCard:d.homepageCard}},
      id: '_id',
      filterMethod: (filter, row) => {   return row.catagory.toLowerCase().includes(filter.value.toLowerCase()) },
      Cell: props =><div> 
      <button onClick={()=>this.updateHomepgaeCard(props.value.homepageCard,props.value.id)}>{props.value.homepageCard?"Remove":"Add"}</button></div>,
      minWidth:220
    },
     {
      Header:<div className="form-textHead cell-head"> Heading</div>,
      accessor: d=>{return{id:d.id,heading:d.heading}},
      id:'id',
      Cell: props => <span className='form-text cell'><Link to={'/knowledgeHub/view-article/'+props.value.id}>{props.value.heading}</Link></span>,
      minWidth:220
    }, {
      Header: <div className="form-textHead cell-head">Sub Heading</div>,
      accessor: 'subHeading',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:220
    }, {
      Header: <div className="form-textHead cell-head">Content</div>,
      accessor: 'truncateContent',
      Cell: props => <span className='form-text cell' dangerouslySetInnerHTML={{__html:props.value}}></span>,
      minWidth:320
    },{
      Header:<div className="form-textHead cell-head">Author Description</div>,
      accessor: 'authorDescription',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:220    
    }, {
      Header:<div className="form-textHead cell-head">Author Image Credit</div>,
      accessor: 'authorImageCredit',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:100
    }, {
      Header:<div className="form-textHead cell-head">Author Name</div>,
      accessor: 'authorName',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:150
    }, {
      Header:<div className="form-textHead cell-head">Blog Image Credit</div>,
      accessor: 'blogImageCredit',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:220
    }, {
      Header:<div className="form-textHead cell-head">Date Posted</div>,
      accessor: 'datePosted',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:150
    }, {
      Header:<div className="form-textHead cell-head">Posted By</div>,
      accessor: 'postedBy',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:150
    }, {
      Header:<div className="form-textHead cell-head">Read Time</div>,
      accessor: 'readTime',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:100
    }, {
      Header:<div className="form-textHead cell-head">Source</div>,
      accessor: 'source',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:100
    },
    {
      Header:<div className="form-textHead cell-head">Source URL</div>,
      accessor: 'sourceUrl',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:100
    },
    {
      Header:<div className="form-textHead cell-head">Video URL</div>,
      accessor: 'videoUrl',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:100
    },
    {
      Header:<div className="form-textHead cell-head">Tags</div>,
      accessor: 'tags',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:100
    }, {
      Header: <div className="form-textHead cell-head">SEO</div>,
      accessor: 'seo',
      filterMethod: (filter, row) => {debugger;   return row.catagory.toLowerCase().includes(filter.value.toLowerCase()) },
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:200
    }, {
      Header: <div className="form-textHead cell-head">Slug</div>,
      accessor: 'slug',
      filterMethod: (filter, row) => {debugger;   return row.catagory.toLowerCase().includes(filter.value.toLowerCase()) },
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:220
    }, {
      Header: <div className="form-textHead cell-head">Meta Description</div>,
      accessor: 'metaDescription',
      filterMethod: (filter, row) => {debugger;   return row.catagory.toLowerCase().includes(filter.value.toLowerCase()) },
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:220
    },{
      Header: props => <span className="form-textHead cell-head"> Date</span>,
      accessor: 'date',
      filterMethod: (filter, row) =>
               {   return row.source.toLowerCase().includes(filter.value.toLowerCase()) },
      Cell: props => 
      new Date(props.value).toDateString(),
      minWidth:180
    },{
      Header: props => <span className="form-textHead cell-head"> Time</span>,
      accessor: 'date',
      filterMethod: (filter, row) =>
               {   return row.source.toLowerCase().includes(filter.value.toLowerCase()) },
      Cell: props => 
      new Date(props.value).toLocaleTimeString(),
      minWidth:180
    }];

    return (
      <div>
        <div className="row">
          <div className="col-md-2 col-lg-2"><EximBlogSidebar/></div>
          <div className="col-md-10 col-lg-10">
      <div className = "Div" >
       <div className="row breadcrumb-row">
          <div className="col-md-10" style={{marginTop:'10px'}}><BreadCrumbs/></div></div>
        <div className="row" style={{}}>
          <ReactTable data={this.state.resdata} columns={columns} style={{height:'85vh',overflow:'scroll'}} filterable/>
        </div>
        <div>
          <div id="myModal" className="modal fade" role="dialog">
            <div className="modal-dialog" style={{width:'1100px', marginTop: '100px'}}>

              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" onClick={this.fetch_all_blogs}>&times;</button>
                  <h4 className="modal-title">Update EXim Blog</h4>
                </div>
                <div className="modal-body">
                  {/*--------------------------------edit-------------------------*/}

                  {/* {this.state.article? */}
                  <div className="container-fluid "style={{margin: "30px",}}>

                    <div class="input-group wth-100" >
                      <span type="text" class="input-group-addon" id="" placeholder="Title" style={{width:"20%"}}>Title</span>
                      <input type="text" class="form-control" id="heading" placeholder=""  style={{}} required></input>
                    </div><br/>

                    <div class="input-group mb-3 wth-100" >
                      <span type="text" class="input-group-addon" id="" placeholder="Title" style={{width:"20%"}}>Subtitle</span>
                      <input type="text" class="form-control" id="subHeading" placeholder=""  style={{width:this.state.inpWidth}} required></input>
                    </div><br/>

                    {this.state.article.content===undefined?"":
                                       <div class="input-group wth-100">
                                       <span type="text" class="input-group-addon" id="" placeholder="Content" style={{width:"20%"}}>Content</span>
                                       
                                       <div style={{border:"1px solid #ededed" ,marginRight:'3%'}}>
                                         <div style={{height:'40%',paddingBottom:"0"}}>
                                         
                                           <EditorJs 
                                   tools={{image:  {
                                   class: Image,
                                   config: {
                                    uploader:{
                                    uploadByFile: async function(file){
                                       console.log(file);
                                       const id = uuidv1()
                                       const imageURL=s3BucketUrl+id+'.png';
                                       
                                      let fileBase = await toBase64(file);
                                      let result = await axios.post(
                                       apiBaseUrl20+"uploadEditorImage",
                                {
                                    image:fileBase,
                                    imageName:fileBase.name,
                                    id:id,
                                    imageURL:imageURL
                                })
                 
                                return{
                                 success:1,
                                 file:{
                                   url:imageURL
                                 }
                                  }
                                  
                                       
                                     },
                                     
                                    }
                                   }
                                 },...EditorjsTool}} 
                                 data={this.state.article.content}
                                 instanceRef={instance=>this.editorInstance=instance}
                                 ></EditorJs>
                                 </div>
                               </div>
                  
                                     </div> 
                    }
                    <br/>

                    <div class="input-group mb-3 wth-100">
                      <span type="text" class="input-group-addon" id="" placeholder="Content Source"span style={{width:"20%"}}>Content Source</span>
                      <input type="text" class="form-control" id="source" placeholder="" style={{width:this.state.inpWidth}} required></input>
                    </div><br/>

                    <div class="input-group mb-3 wth-100">
                      <span type="text" class="input-group-addon" id="" placeholder="Content URL"span style={{width:"20%"}}>Source URL</span>
                      <input type="text" class="form-control" id="sourceUrl" placeholder="" style={{width:this.state.inpWidth}} required></input>
                    </div><br/>

                    
                    {/* <div class="input-group mb-3 wth-100">
                      <span type="text" class="input-group-addon" id="" placeholder="Category" style={{width:"20%"}}>Category</span> */}
                      {/*<input type="text" class="form-control" id="catagory" placeholder=""  style={{width:this.state.inpWidth}} required></input>*/}
                      {/* <select class="form-control" id="catagory">
                        <option>cat1</option>
                        <option>cat2</option>
                        <option>cat3</option>
                        <option>cat4</option>
                      </select>
                    </div><br/> */}

                    <div class="input-group mb-3 wth-100">
                      <span type="text" class="input-group-addon" id="" placeholder="Tags" style={{width:"20%"}}>Tags</span>
                      <input type="text" class="form-control" id="tags" placeholder="break tags with space"  style={{width:this.state.inpWidth}} required></input>
                    </div><br/>

                    <div class="input-group mb-3 wth-100">
                      <span type="text" class="input-group-addon" id="" placeholder="Date of Publishing" style={{width:"20%"}}>Date of Publishing</span>
                      <input type="date" class="form-control" id="datePosted" placeholder="yyyy-mm-dd" min="2018-01-01" max="2030-12-31" style={{width:this.state.inpWidth}} required></input>
                    </div><br/>


                    <div class="input-group mb-3 wth-100">
                      <span type="text" class="input-group-addon" id="" placeholder="Read Time" style={{width:"20%"}}>Read Time</span>
                      <input type="number" class="form-control" id="readTime" placeholder="minutes"  style={{width:this.state.inpWidth}} required></input>
                    </div><br/>

                  {this.state.article.blogType!='curated'?
                 <div class="input-group mb-3 wth-100">
                 <span type="text" class="input-group-addon" id="" placeholder="Author" style={{width:"20%"}}>Author</span>
                 <input type="text" class="form-control" id="authorName" placeholder=""  style={{width:this.state.inpWidth}} required></input>
               </div>:'' }
                    <br/>

                  {this.state.article.blogType!='curated'?
                  <div class="input-group mb-3 wth-100">
                  <span type="text" class="input-group-addon" id="" placeholder="Author Description" style={{width:"20%"}}>Author Description</span>
                  <input type="text" class="form-control" id="authorDescription" placeholder=""  style={{width:this.state.inpWidth}} ></input>
                </div>:""
                  }
                    <br/>

                    {this.state.article.blogType!="curated"?
                    <div class="input-group mb-3 wth-100">
                    <span type="text" class="input-group-addon" id="" placeholder="Article Image Credit" style={{width:"20%"}}>Article Image Credit</span>
                    <input type="text" class="form-control" id="blogImageCredit" placeholder=""  style={{width:this.state.inpWidth}} ></input>
                  </div>:''}
                    <br/>

                    <div className='well well-sm'>
                      <h5> Add SEO </h5>

                      <div class="input-group mb-3" style={{width:"80%"}}>
                        <span type="text" class="input-group-addon" id="" placeholder="SEO"span style={{width:"20%"}}>SEO</span>
                        <input type="text" class="form-control" id="seo" style={{width:this.state.inpWidth}} required></input>
                      </div><br/>

                      <div class="input-group mb-3" style={{width:"80%"}}>
                        <span type="text" class="input-group-addon" id="" placeholder="Slug"span style={{width:"20%"}}>Slug</span>
                        <input type="text" class="form-control" id="slug" style={{width:this.state.inpWidth}} required></input>
                      </div><br/>

                      <div class="input-group mb-3" style={{width:"80%"}}>
                        <span type="text" class="input-group-addon" id="" placeholder="Meta Description"span style={{width:"20%"}}>Meta Description</span>
                        <input type="text" class="form-control" id="metaDescription" style={{width:this.state.inpWidth}} required></input>
                      </div><br/>

                    </div>

                    <div class="well well-sm " id='more'>
                    <h5>Add More :- </h5>

                    <button type='button' class="btn btn-default" style={{margin:'10px'}} id = '' onClick={this.addUrl}>Add Url</button>
                    
                    {this.state.more!=undefined?
                    <div>
                      {this.state.more.map((show_more,index)=>
                     <div class="input-group mb-3 wth-80" style={{marginBottom:'2%'}}>
                     <span type="text" class="input-group-addon" id="" placeholder="Author" style={{width:"20%"}}>Source Url</span>
                     <input type="text" class="form-control" id={"src_"+ index} placeholder="Source" name='src'  ></input>
                     <input type="text" class="form-control" id={"url_"+ index} placeholder="URL" name='url'  ></input>
                     <input type="text" class="form-control" id={"srcName_"+ index} placeholder="Name" name='name'  ></input>
                   </div>
                      )}
                    
                    </div>
                   :'' }
          </div>
                    {this.state.article.blogType!='curated'?
                     <div class="col-md-6" style={{width: "30%"}}>
                      <div class="thumbnail">
                       <img src={this.state.article.authorImageURL} alt="authorImagePreview"style={{width: "100%",}}/>
                       <div class="caption">
                         <span class=""> <p>Author Image</p>
                         <input type="file" class="form-control" id="authorImage" name="filename" onChange={() => this.encodeImage('authorImage')}></input>
                         </span>
                       </div>
                     </div>
                   </div>:""
                  }
                   
             </div>

                  {/*-------------------------------------edit---------------------------------------*/}

                </div>
                <div className="modal-footer">
                  <button type='button' className="btn btn-primary" id = 'save' onClick={this.update_blog}>Update</button>
                  <button type="button" className="btn btn-default" data-dismiss="modal" onClick={this.fetch_all_blogs}>Close</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
      </div>
    )
  }
}
