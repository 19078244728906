import React, { Component } from "react";
import CsvDownloader from 'react-csv-downloader'
import BreadCrumbs from '../../BreadCrumbs';
import { Link } from "react-router-dom";
import axios from 'axios';
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import ReactTable from 'react-table';
import $ from "jquery";
import _ from 'underscore';

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

const data = [];


const columnsForExcel = [
  {
    id: 'employeeId',
    displayName : "Employee ID",

  },
  {
    id: 'firstName',
    displayName : "First Name",

  },
  {
    id: 'lastName',
    displayName : "Last Name",

  },
  {
    displayName : "Email",
    id: "email",
   
  },
  {
    id: 'gender',
    displayName : "Gender",

  },
  {
    id: 'nickName',
    displayName : "Nick Name",

  },
  {
    id: 'profile',
    displayName : "Profile",

  },
  {
    id: 'role',
    displayName : "Role",

  },
  {
    displayName : "Organisation",
    id: "Organisation",
   
  },
  {
    displayName : "Work Ex",
    id: "workExperience",
   
  },
  {
    displayName : "Attributes",
    id: "attributes",
   
  },
  {
    displayName : "Dependant Name",
    id: "dependantName",
   
  },{
    displayName : "Education",
    id: "education",
   
  },
  {
    displayName : "By",
    id: "by",
   
  },
   {
   
    id: "date",
    displayName : "Date",
  },
  {
    displayName : "Time",
    id: "time",
  
  }
  
];



let context="null";

//defined all the columns and fetched the data for every cell.
const columns = [



  {

    Header: props => <span className="form-textHead cell-head">Employee Id</span>,
    accessor: 'employeeId',
    id:"employeeId",
    filterMethod: (filter, row) =>
  { return row.employeeId.toLowerCase().includes(filter.value.toLowerCase()); },
    Cell: props => <span className='form-text cell'>{props.value}</span>,
    minWidth:180
    },
    {

      Header: props => <span className="form-textHead cell-head">Name</span>,
      accessor: d => {return {firstName:d.firstName,lastName:d.lastName,id:d.id}},
      filterMethod: (filter, row) =>
               { return row.name.firstName.toLowerCase().includes(filter.value.toLowerCase()) || row.name.lastName.toLowerCase().includes(filter.value.toLowerCase()) }
                       
                    ,
      id:"name",
      Cell: props => 
      <Link className="link" to={"./view-employee/"+props.value.id}>{props.value.firstName +" "+ props.value.lastName}</Link>,
      minWidth:180
      },
  
{
  Header: props => <span className="form-textHead cell-head"> Email</span>,
  accessor: 'email',
  filterMethod: (filter, row) =>
  { return row.email.toLowerCase().includes(filter.value.toLowerCase()); },
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:240
},
{
  Header: props => <span className="form-textHead cell-head">department</span>,
  accessor: 'department',
  filterMethod: (filter, row) =>
  { return row.department.toLowerCase().includes(filter.value.toLowerCase()); },
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:170
},
{
  Header: props => <span className="form-textHead cell-head">Title</span>,
  accessor: 'title',
  filterMethod: (filter, row) =>
  { return row.title.toLowerCase().includes(filter.value.toLowerCase()); },
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:170
},
{
  Header: props => <span className="form-textHead cell-head">date Of Joining</span>,
  accessor: 'dateOfJoining',
  
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:170
},
{
  Header: props => <span className="form-textHead cell-head">Mobile</span>,
  accessor: 'mobile',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:170
},

{
  Header: props => <span className="form-textHead cell-head">Gender</span>,
  accessor: 'gender',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:170
}, {
  Header: props => <span className="form-textHead cell-head">Date of Creation</span>,
  accessor: d => {return {date:d.date,id:d.id}},
  filterMethod: (filter, row) =>
           { return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase()) }
                   
                ,
  id:"teamMembers",
  Cell: props => 
  new Date(props.value.date).toDateString(),
  minWidth:180
 
},
{
  Header: props => <span className="form-textHead cell-head">By</span>,
  accessor: 'by',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:170
 
},
{
  Header: props => <span className="form-textHead cell-head">Time of Creation</span>,
  accessor: d => {return {date:d.date,id:d.id}},
  filterMethod: (filter, row) =>
           { return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase()) }
                   
                ,
  id:"teamMembers",
  Cell: props => 
  new Date(props.value.date).toLocaleTimeString()
  ,
  minWidth:280
 
},
]
export default class viewEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      menu1:[
       



 ]
 
    };
    context = this;
  }
  componentDidMount(){

  this.fetchQueries(this,this.props.source);}


  toggleOpen = id => {
    var list  =this.state.menu1;
    var index = _.findIndex(list,function(entry){return entry.id==id});
    
    list[index].status =  (list[index].status == "open" || list[index].status==undefined) ? "close" : 'open';
    
    var newList = JSON.parse(JSON.stringify( list ));
    context.setState({menu1:newList});
    context.updateStatus(context,id,list[index].status);
    
    
    
      };

  
  fetchQueries(that,source){

    axios.get(apiBaseUrl2+"businus/employee/list/"+localStorage.spId)
    .then(function (response) {
      console.log(response.data);

       let allEmp = response.data;
      if(localStorage.userRole!='Admin'){

        allEmp = _.filter(allEmp,function(emp){

          return emp.role!='Admin';
        });
      }
      that.generateExcelData(allEmp);
      that.setState({menu1:_.sortBy(allEmp,"employeeId")});
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }

  generateExcelData = data => {
    
    let excelData = [];
    _.each(data, function(d) {
  
      
      let row = {
        employeeId: d.mailId,
       firstName: d.firstName,
       lastName:d.lastName,
       email:d.email,
       nickName:d.nickName,
       profile:d.profile,
       gender: d.gender,
       role:d.role,
       education:d.education,
       dependants:d.dependants,
       workExperience: d.workExperience,
       organisation:d.organisation,
       by: d.by,       
        date: new Date(d.date).toDateString(),
        time: new Date(d.date).toLocaleTimeString()
      };
       
      excelData.push(row);
      console.log(excelData);
    });
    this.setState({ excelData });
  };
  
   getCommaSeparatedTeamName=(teamMember)=>{
    console.log(teamMember)
   let names= _.map(teamMember, function(d){
   return d.name;
   })
   console.log(names)
   return names.join(";")
   }
  

  render() {
    return (
      <div>
       
      
      <div className="Div">
      <div className="row breadcrumb-row">
          <div className="col-md-10"><BreadCrumbs/></div>
          <div className="col-md-2">
            <div className=" download-button">
      <CsvDownloader 
      text="Download in .csv format"
      datas={this.state.excelData}
      filename="employee"
      separator=","
      wrapColumnChar=""
      columns={columnsForExcel}
      /></div>
      </div>
      </div>
            <div className="row">
          
              <Row>
                <Col>
                  <div className="card">

                  <ReactTable className="myTable" resolveData={data => data.map(row => row)}  data={this.state.menu1} columns={columns} filterable />
                  


                    
                       
                      
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          </div>
    );
  }
}
