import React, { Component } from "react";
import "../../style/panel.css";
import { Link } from "react-router-dom";

const data = [];
export default class EximSidebar extends Component {

  constructor(props) {
    super(props);
    

  }

  render() {
    return (

            <div className="sidebar">
              <div id="sidebar1">
                <div className="sidebar-elementhead"><Link className="sidebar-link" to="/knowledgeHub">Home</Link></div>
                <div className="oneTab">
                <div className="sidebar-element" id="c1"> <i class="fa fa-bar-chart" />Dashboard<i className="fa fa-angle-right " /></div>
                {/* <div className="sidebar-subelement" id="d1"><i class="fa fa-envelope" />View<i className="fa fa-angle-right " /></div> */}
                </div>
                <div className="oneTab">
                  <div className="sidebar-element" id="blog">
                  <Link className="sidebar-link" to="/knowledgeHub/businus-school/exim/article">
                    <i class="fa fa-envelope" />
                    Articles
                    <i className="fa fa-angle-right " /></Link>
                  </div>

                </div>

                <div className="oneTab">
                  <div className="sidebar-element" id="blog">
                  <Link className="sidebar-link" to="/knowledgeHub/businus-school/exim/addBanner">
                    <i class="fa fa-envelope" />
                    Add Banner
                    <i className="fa fa-angle-right " /></Link>
                  </div>

                </div>

                <div className="oneTab">
                  <div className="sidebar-element" id="blog">
                  <Link className="sidebar-link" to="/knowledgeHub/businus-school/exim/view-bookmarks">
                    <i class="fa fa-envelope" />
                    View Bookmarks
                    <i className="fa fa-angle-right " /></Link>
                  </div>

                </div>

              </div>
            </div>

    );
  }
}
