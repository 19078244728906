import React, { Component } from "react";
import { Link } from "react-router-dom";
import BreadCrumbs from "../../BreadCrumbs";
import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { ChatFeed, Message } from 'react-chat-ui'
import {validate} from '../../common/Validation';
import Select from 'react-select';
import SubmitButton from '../../common/SubmitButton';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from 'underscore';
import { grey } from "ansi-colors";
let context = null;
const data = [];
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               :'0px ',
    position               :'Absolute',
    zIndex                 :'30000'     ,
    backgroundColor         :'none',  
  }
};
 
export default class addPaymentStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
     isSubmitting:false,
     entry:{}
    };
    context = this;
    
  }

  

  //function for changing the status button on click to open or close
  handleOnChange = (e) => {
  
    var value = e.target.value;
  
    var formName = e.target.name;

    this.setState({entry:{...this.state.entry,[formName]:value}})  

    console.log(this.state);
  }
  handleOnChangeColor = (e) => {
  
    var value = e.target.value;
  
    var formName = e.target.name;

    this.setState({entry:{...this.state.entry,[formName]:value}})  


    console.log(this.state);
  }

 

  
  
  


  


  
 

  componentDidMount() {

    const id = this.props.match.params.id;

    this.setState({id});
    this.fetchQueryById(this,id);
  }


 

  fetchQueryById(that, id) {
    axios
      .get(
        apiBaseUrl1+"businusquery/getStatusById/" +
          id
      )
      .then(function(response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  //fetching the data by id which this file recieved from the list

  
  

  
  
  
  
  
  
  
    
    

  
  

  sendMail() {
     var data = context.state.entry;
     
    
context.setState({isSubmitting:true});
   
   
    //var selectedOrganisation = _.findWhere(context.state.allOrganisations,{id:data.assignedOrganisation});

   //var organisation = {name:selectedOrganisation.organisationName,id:selectedOrganisation.id,logo:selectedOrganisation.logo};
    axios
      .post(
        apiBaseUrl1+"businus/status/update/"+context.state.id,
        {
          "paymentStatus":data.paymentStatus,
          "paymentStatusColor":data.paymentStatusColor,
          "type":"payment",
          "by":localStorage.userName 
          

        })
      .then(function(response) {
        debugger;
      alert("Success!");
    //  context.fetchThreadById(context,query.id);
    context.setState({isSubmitting:'done'});
      })
      .catch(function(error) {
        console.log(error);
        context.setState({isSubmitting:'done'});
      });
  }

  setMailPageUrl(){
  var url = window.location.href.replace(window.location.origin,"");
  localStorage.setItem("MAILURL",url);


  }

  onSelect = selectedOption => {
    this.setState(
      { team:selectedOption },
      () => console.log(`Option selected:`, this.state.selectedOption)
    );
  };






  //updating the status button
  
  render() {
    const {sliderState} = this.state;
    const {superSliderState, entry} = this.state;    
    const active = " active-button"
    const deactive = "";
    return (
      <div className="Div">
      <div className="row breadcrumb-row">
       
      <BreadCrumbs /> 
      </div>  
  {/* showing all the details of a single item from the table at the backend*/}
  <div className="row">
  <div className="dedicated-page" style={{padding:'0px'}}>
    <div className="row-resource-head">
                  <h2 className="resource-head">Edit </h2>
                  </div>
      <div className="row ">
     
        <div className="col-md-12 info-column account-col2">
          <div id="field">
            
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                

                 
                  
                  <div className="form-group">
                    <label className="control-label col-md-4">
                      {" "}
                      Edit Payment status :{" "}
                    </label>
                    <div className="col-md-6">
                      <input
                        id="ember301"
                        onChange={this.handleOnChange}
                        name="paymentStatus"
                      value={this.state.entry.paymentStatus}
                        className="form-control ember-text-field ember-view"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="control-label col-md-4">
                      {" "}
                      Edit Color of status :{" "}
                    </label>
                    <div className="col-md-6">
                      <input
                        
                        onChange={this.handleOnChangeColor}
                        name="paymentStatusColor"
                      value={this.state.entry.paymentStatusColor}
                        className="form-control "
                        type="color"
                      />
                    </div>
                  </div>
            


                 
                </div>
              </div>
            </div>

          
    
     



    
     



            <div className="text-left">
            <SubmitButton clickHandler={this.sendMail}
                   isSubmitting={this.state.isSubmitting} />
            </div>
            {/* this says that if editing state is on then the Function saveAccountDetails will be called */}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
      
     
    );
  }
}

