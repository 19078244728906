import React, { Component } from "react";
import "../../../style/panel.css";
import { BrowserRouter, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import $ from "jquery";

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";

import BreadCrumbs from "../../BreadCrumbs";

import Home from "../../home";
import pageProgressStatus from "./pageProgress";
import addProgressStatus from "./addProgress";
import viewProgressStatus from "./viewProgress";
import editProgressStatus from "./editProgress";
import pagePaymentStatus from "./pagePayment";
import addPaymentStatus from "./addPayment";
import viewPaymentStatus from "./viewPayment";
import editPaymentStatus from "./editPayment";

import SidebarStatus from "./sidebarStatus";
import Dashboard from "./Dashboard";

const data = [];
export default class statusMainapp extends Component {
  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: [],
    };
  }
  componentDidMount() {
    this.handleLine();
    //this.setState({title:this.props.match.params.productType});
  }
  handleLine() {
    const tab1 = this.refs.tab1;
    const sidebar1 = this.refs.sidebar1;
    const section = this.refs.section;

    $(function () {
      $("#tab1").click(function (e) {
        $(e.currentTarget).parent().toggleClass("sidebar-secondary");
        $(e.currentTarget).find("i:first").toggleClass("sidebar-back-arrow");
        $("#section").toggle();
      });
    });
  }

  toggleOpen = (id) => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter((i) => i !== id)] });
    }
  };

  render() {
    return (
      <div className="mainDiv">
        <div className="row">
          <div className="col-md-2">
            <SidebarStatus title={this.state.title} />
          </div>
          <div className="col-md-10">
            <div className="section">
              <Route exact path="/assignment-master/status/" component={Dashboard} />
              <Route
                exact
                path="/assignment-master/status/add-progress-status"
                component={addProgressStatus}
              />

              <Route
                exact
                path="/assignment-master/status/view-progress-status"
                component={viewProgressStatus}
              />
              <Route
                exact
                path="/assignment-master/status/view-progress-status/:id"
                component={pageProgressStatus}
              />

              <Route
                exact
                path="/assignment-master/status/edit-progress-status/:id"
                component={editProgressStatus}
              />
              <Route
                exact
                path="/assignment-master/status/add-payment-status"
                component={addPaymentStatus}
              />

              <Route
                exact
                path="/assignment-master/status/view-payment-status"
                component={viewPaymentStatus}
              />
              <Route
                exact
                path="/assignment-master/status/view-payment-status/:id"
                component={pagePaymentStatus}
              />

              <Route
                exact
                path="/assignment-master/status/edit-payment-status/:id"
                component={editPaymentStatus}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
