import React, { Component } from 'react';

import '../style/agency.css';
import '../style/animate.css';
import { SketchPicker } from 'react-color';
import '../animations.css';
import Modal1 from './teamModal1';
import StyleModal from './StyleModal';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'




/**
 * Team
 * It is the code for showing team section in the template
 * @module Team
 */
class Contact extends Component {
   
    constructor(props){
      super(); 
      this.state ={
        title:'',Products:[{name:"Team1",post:"lead",icon:"https://mycs.net.au/wp-content/uploads/2016/03/person-icon-flat.png", color:'',
      descriptionColor:'',
      bgcolor:'',

      facebookid:'',
      twitterid:'',
      linkid:''},
      {name:"Team2",post:"lead",icon:"https://mycs.net.au/wp-content/uploads/2016/03/person-icon-flat.png", color:'',
      descriptionColor:'',
      bgcolor:'#fff',

      facebookid:'',
      twitterid:'',
      linkid:''},
      {name:"Team3",post:"lead",icon:"https://mycs.net.au/wp-content/uploads/2016/03/person-icon-flat.png", color:'',
      descriptionColor:'#000',
      bgcolor:'#fff',

      facebookid:'',
      twitterid:'',
      linkid:''}
    ],toggleStyleMode:false,hideModal:true, showImageBox:false, newUrl:'',
      styles:{backgroundImage:'fg',
      backgroundColor:'#ccccff',
             padding:'60px 10px 10px 10px',
             margin:'1000px 0px 0px 0px',
             color:'black'
           }};
      this.addProducts = this.addProducts.bind(this);
      this.removeProduct = this.removeProduct.bind(this);
      this.removeLastProduct = this.removeLastProduct.bind(this);
      this.onclickImage = this.onclickImage.bind(this);
      this.onclickOK = this.onclickOK.bind(this);
      this.onclickName = this.onclickName.bind(this);
      this.onclickNameOK = this.onclickNameOK.bind(this);
      this.onclickPost = this.onclickPost.bind(this);
      this.onclickPostOK = this.onclickPostOK.bind(this);
      this.functionmodal = this.functionmodal.bind(this);
      this.hideModal = this.hideModal.bind(this);
      this.saveProduct = this.saveProduct.bind(this);
      this.saveStyle = this.saveStyle.bind(this);
      this.toggleStyleModal = this.toggleStyleModal.bind(this);
      this.confirmDelete = this.confirmDelete.bind(this);
     }
   
     componentDidMount(){
       this.setState({title:this.props.title});

        var productCount = this.props.productCount;
        var products = [];
        var innerProducts = this.props.innerProducts;

        
      
        if(innerProducts.length==0){
        for(var i=0;i<productCount;i++){
        
        products.push({name:"Team Member "+(i+1),post:"Post",icon:"https://png.pngitem.com/pimgs/s/130-1300380_female-user-image-icon-hd-png-download.png", color:'#000',
        descriptionColor:'#000',
        bgcolor:'#fff',
  
        facebookid:'dd',
        twitterid:'dd',
        linkid:'dd'});
        
        } 
      
    }
    else
    { products = innerProducts;}



        this.setState({styles:this.props.productStyle});
        this.setState({Products:products});
        
     }

     
     onclickImage(i,newimageUrl){
      this.setState({imageUrl:newimageUrl});
       
        var products= this.state.Products;
        products.forEach(function(prod,keyindex){
        if (keyindex==i){
          prod.showImageBox=true;
          
        }
        
        } );
   
       this.setState({Products:products});
     }
       
     onclickOK(i){ 
      var imageUrl= this.state.imageUrl;
      var products= this.state.Products;
      products.forEach(function(prod,keyindex){
      if (keyindex==i){
      
        prod.icon=imageUrl;
        prod.showImageBox=false;
        
      }
      
      } );
  
     this.setState({Products:products});
     this.setState(
       {showImageBox:false});
   }
  

     onclickName(i,newName){
      this.setState({Name:newName});
       
        var products= this.state.Products;
        products.forEach(function(prod,keyindex){
        if (keyindex==i){
          prod.showNameBox=true;
          
        }
        
        } );
   
       this.setState({Products:products});
     }
  
     onclickNameOK(i){ 
      var Name= this.state.Name;
      var products= this.state.Products;
      products.forEach(function(prod,keyindex){
      if (keyindex==i){
      
        prod.name=Name;
        prod.showNameBox=false;
        
      }
      
      } );
  
     this.setState({Products:products});
     this.setState(
       {showNameBox:false});
   }


   onclickPost(i,newPost){
    this.setState({Post:newPost});
     
      var products= this.state.Products;
      products.forEach(function(prod,keyindex){
      if (keyindex==i){
        prod.showPostBox=true;
        
      }
      
      } );
 
     this.setState({Products:products});
   }

   onclickPostOK(i){ 
    var Post= this.state.Post;
    var products= this.state.Products;
    products.forEach(function(prod,keyindex){
    if (keyindex==i){
    
      prod.post=Post;
      prod.showPostBox=false;
      
    }
    
    } );

   this.setState({Products:products});
   this.setState(
     {showPostBox:false});
 }


 onChange = (e) => {

  const state = this.state
  state[e.target.name] = e.target.value;
  this.setState(state);


  console.log(this.state);
}


/**
 * addProducts()
 * This segment is the code for adding products in the section.
 * @function addProducts()
 */
     addProducts(){
      var prod = this.state.Products;
      var productName = prod.length+1;
      prod.push({name:"Team Member "+productName,post:"Post",icon:"https://png.pngitem.com/pimgs/s/130-1300380_female-user-image-icon-hd-png-download.png", color:'',
      descriptionColor:'#000',
      bgcolor:'#fff',

      facebookid:'',
      twitterid:'',
      linkid:''});
      this.setState({Products:prod});
      
      
     }
   

     saveProduct(i,product){
   
      var products= this.state.Products;
      products.forEach(function(prod,keyindex){
      if (keyindex==i){
      
        prod.name=product.name;
        prod.icon=product.icon;
        prod.post=product.post;
        prod.facebookid=product.facebookid;
        prod.backgroundColor=product.backgroundColor;
        prod.color=product.color;
        prod.descriptionColor=product.descriptionColor;
        prod.twitterid=product.twitterid;
        prod.linkid=product.linkid;

        
      }
      
      } );
    
     this.setState({Products:products});
     
	 this.props.saveSectionProperties(
		this.props.sectionKey,
		this.state.title,
		this.state.styles,
		products
		);
     
      
      
     }
   
     removeLastProduct(){
       var prod = this.state.Products;
       //var productName = prod.length+1;
       prod.pop();
       this.setState({Products:prod});
       
       
      }
    

      removeProduct(removeAt){
     
        var prod = this.state.Products;
        //var productName = prod.length+1;
        prod.splice(removeAt,1);
        this.setState({Products:prod});
        
        
       }

       confirmDelete(index){


        confirmAlert({
          title: 'Confirm to Delete',
          message: 'Are you sure you want to delete section.',
          buttons: [
            {
              label: 'Yes',
              onClick: () => this.removeProduct(index)
            },
            {
              label: 'No',
              onClick: () => console.log("")
            }
          ]
        })
      }
    

       hideModal(i){

  
   
        var products= this.state.Products;
        products.forEach(function(prod,keyindex){
        if (keyindex==i){
          prod.toggleEditMode= !prod.toggleEditMode;
          
        }
        
        } );
    
       this.setState({Products:products});
       
       }
    
    
       toggleStyleModal(){
    
        var status = !this.state.toggleStyleMode;
        this.setState({toggleStyleMode:status});
        
    
       }
       saveStyle(newstyle){
    
        
        this.setState({styles:newstyle});
        this.setState({title:newstyle.title});
        this.props.saveSectionProperties(
          this.props.sectionKey,
          newstyle.title,
          newstyle,
          this.state.Products
          );
    
       }
    
       functionmodal(){
        var modal = document.getElementById('myModal');
    
        // Get the button that opens the modal
        var btn = document.getElementById("myBtn");
        
        // Get the <span> element that closes the modal
        var span = document.getElementsByClassName("close")[0];
        
        // When the user clicks on the button, open the modal 
        btn.onclick = function() {
          modal.style.display = "block";
        }
        
        // When the user clicks on <span> (x), close the modal
        span.onclick = function() {
          modal.style.display = "none";
        }
        
        // When the user clicks anywhere outside of the modal, close it
        window.onclick = function(event) {
          if (event.target == modal) {
            modal.style.display = "none";
          }
        }
       }
    render(){
      const {styles }= this.state;
    return(



<div>
        
        {/* Team */}
        <section style={{color:styles.color,
          backgroundColor:styles.backgroundColor,
          backgroundImage:styles.backgroundImage!='no image' ? styles.backgroundImage : '',
          padding:styles.padding,
          margin:styles.margin
          }}  id={this.props.title.replace(/\s/g, "")}>
        {this.state.toggleStyleMode ?  <StyleModal title={this.state.title} saveProduct={this.saveStyle} toggleStyleModal={this.toggleStyleModal} saveStyle={this.saveStyle}  styleProp={this.state.styles}/> : '' }
          <div className="container">
            <div className="row head-row">
              <div className="col-lg-12 text-center">
                <h2 className="section-heading ">{this.state.title}<span className=" editclass editsign" onClick={()=>this.toggleStyleModal()}><i className="fa fa-edit editclass"></i></span><span className=" editclass adddivsign" onClick={this.addProducts}>+</span></h2>
               
              </div>
            </div>
            <div className="row">  { this.state.Products.map((prod,i) => 
              <div className="col-sm-4 animatedParent cardd">
               {prod.toggleEditMode ?  <Modal1 saveProduct={this.saveProduct} hideModal={this.hideModal} product={prod} keyindex={i} /> : '' } 
                <div style={{backgroundColor:prod.backgroundColor,padding:'40px 0px'}} className="team-member animated fadeInUp ">
              
                  <img className="mx-auto rounded-circle" onClick={()=>this.onclickImage(i,prod.icon)} src={prod.icon==''?'no-image':prod.icon} alt />
              


                
                     
                
                 
                 <h4  style={{color:prod.color}} className="team-heading editable" >{prod.name}<span className=" editclass editsign" onClick={()=>this.hideModal(i)}><i className="fa editclass fa-edit"></i></span><span className=" editclass removesign" onClick={()=>this.confirmDelete(i)}>X</span></h4>
                 
                
               
                     
                 
                  <p style={{color:prod.descriptionColor}} className="team-description" onClick={()=>this.onclickPost(i,prod.post)} contentEditable="true">{prod.post}</p>
                 
                  
                 
                    
                  <ul className="list-inline social-buttons">
                    <li className="list-inline-item">
                      <a href={prod.twitterid} target="_blank">
                        <i style={{color:'#fff'}} className="fab fa-twitter" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={prod.facebookid} target="_blank">
                        <i style={{color:'#fff'}} className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={prod.linkid} target="_blank">
                        <i style={{color:'#fff'}} className="fab fa-linkedin-in" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
               )}
              
              
            </div>
          
          </div>
        </section>
       
       </div>
    );
  

    }
}

export default Contact;