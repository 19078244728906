import React from 'react';
import parser from 'html-react-parser';
import { Header } from '@editorjs/header';
import Truncate from 'react-truncate';

function BreifReadOnly({ data,headers}) {
    let index =-1
    let bioArray=''
    let content =""
  const renderBlock = ({type,data}) => {
    // let content = ""
    switch (type) {
      case "header":  
        index+=1
              
          
        const element =  React.createElement(
          `h${data.level}`,
          {
            className: 'ce-header',
          },
          data.text
        )  
        content =    (<div id={'header'+index} style={{height: "fit-content"}}>
            {element}
          </div>)
      break;
      case "list":
        content = (<ul className={`"cdx-block" "cdx-list" "cdx-list--${data.style}"`}>
          {data.items.map(item =>  <li class="cdx-list__item">{item}</li>)}
        </ul>)
      break;
      case "table":
        content = (
            <div className='tc-editor cdx-block'>
                <div className='tc-table__wrap'>
                    <table className='tc-table'>
                        <tbody>
                            {data.content.map(itemArray=>
                            <tr>
                            {itemArray.map(item=>
                                
                                    <td className='tc-table__cell'>
                                        <div className='tc-table__area'>
                                            <div className='tc-table__inp' contentEditable='false'>{item}</div>
                                        </div>
                                    </td>
                                
                            )}
                            </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
        )
      break;
      case "embed":
          content = (<div className="cdx-block embed-tool">
          <center> <iframe className="embed-tool__content" style={{width: "fit-100%",marginTop:"3%"}} height="320" frameborder="0" width="580"
            allowfullscreen src={data.embed}></iframe> </center>
          <div style={{'text-align': "center", 'margin-top': "5px"}}>{data.caption}</div>
          </div> )
      break;
      case "code":
        content = (<div class="cdx-block ce-code">
            <span style={{'text-align': "right", 'margin-bottom': "5px"}}>{data.language}</span>
            <pre className="ce-code__textarea cdx-input prettyprint"><code className="lang-js">{data.code+""}</code></pre>
          </div>)
      break;
      case "delimiter":
        content = (<div class="cdx-delimiter cdx-block">
          </div>)
      break;
      case "quote":
        content = (<blockquote class="cdx-block cdx-quote">
            <div className='cdx-input cdx-quote__text' contentEditable='false'>
                {data.text}
            </div>
            <div className='cdx-input cdx-quote__caption' contentEditable='false'>
                - {data.caption}
            </div>
          </blockquote>)
      break;
      case "image":
          content = (
              data.file!=undefined?
            <div className="cdx-block image-tool image-tool--filled">
              <div className="image-tool__image">
                <img className="image-tool__image-picture" style={{marginTop:'2%',width:'50vw'}} src={data.file.url}></img>
                <div style={{'text-align': "right", 'margin-bottom': "5px"}}>{data.caption}</div>
              </div>
            </div>:''
          )
      break;
      default:
        content = (<div className="ce-paragraph cdx-block"> {parser(`${data.text}`)} </div>)
        break;
    }
    bioArray=bioArray+(<div className="ce-block ce-profile-parent">
    <div className="ce-block__content ce-profile-child">
      {content}
    </div>
  </div>)
  }

  return (
      <div>
    {data.blocks.slice(0).reverse().map((block)=> renderBlock(block,data))}
    <Truncate lines={7} ellipsis={<a style={{marginLeft:"1%"}} href='#bio'>Read more</a>}>
        {content}
    </Truncate>
    </div>
  );
}

export default BreifReadOnly;