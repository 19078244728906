import React, { Component } from "react";
import { Link } from "react-router-dom";
import BreadCrumbs from "../../BreadCrumbs";
import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { ChatFeed, Message } from 'react-chat-ui'
import Select from 'react-select';
import {Multiselect} from 'multiselect-react-dropdown';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from 'underscore';
let context = null;
const data = [];
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               :'0px ',
    position               :'Absolute',
    zIndex                 :'30000'     ,
    backgroundColor         :'none',  
  }
};
 
export default class EditClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName:data.companyName,
      companyDisplayName:data.companyDisplayName,
      customerEmail:data.customerEmail,
      customerType:data.customerType,
      primaryContact:data.primaryContact,
      customerContact:data.customerContact,
      website:data.website,
      billingAddress:data.billingAddress,
      billingCountry:data.billingCountry,
      billingAttention:data.billingAttention,
      billingCity:data.billingCity,
      billingPhone:data.billingPhone,
      billingZipCode:data.billingZipCode,
      assignedOrganisation:[],
      shippingAddress:data.shippingAddress,
      shippingCountry:data.shippingCountry,
      shippingAttention:data.shippingAttention,
      shippingCity:data.shippingCity,
      shippingPhone:data.shippingPhone,
      shippingZipCode:data.shippingZipCode,
      contactPersons:data.contactPersons,
      onlineOrganisations:[],
      offlineOrganisations:[],
      sliderState:1,
      superSliderState:'additional',
      teamMembers:[],
      entry:{bankDetails:{},contactPersons:[],team:[]},
contactPersons:[],
assignedOrganisation:'',
onlineOrganisations:[],
      offlineOrganisations:[],
      team:[],
      teamMembers:[],
    };
    context = this;
    
  }

  changeSliderState(orderNumber){

    this.setState({sliderState:orderNumber});

}

changeSuperSliderState(name) {

  this.setState({ superSliderState: name });
  if(name==='additional'){
    this.setState({ sliderState: 1 });
  }
  else{
    this.setState({ sliderState: 13 });
  }


}

handleOnChangeOrg = (e) => {
  debugger;
    var value = e.target.value;
  
    var formName = e.target.name;

      this.setState({[formName]:value});
      
   
  }
  //function for changing the status button on click to open or close
  handleOnChange = (e) => {
  debugger;
    var value = e.target.value;
  
    var formName = e.target.name;

    this.setState({entry:{...this.state.entry,[formName]:value}});

    if(formName=="customerEmail"){
      this.setState({emailExists:-1});
      this.checkIfEmailAlreadyExists(value);
    }

    if(formName=='mode' && value=="offline")
    {this.setState({showOffline:true})
    }
    if(formName=='mode' && value=="online")
    {this.setState({showOffline:false})
    }
    if(formName=='role' && value=="admin")
    {this.setState({canCustomise:false})
    }
    if(formName=='role' && value=="employee")
    {this.setState({canCustomise:false})
    }
    


  console.log(this.state);
}


handleOnChangeTeam = (e) => {
  
    var value = e.target.value;
  
    var formName = e.target.name;

    this.setState({[formName]:value});

  


  console.log(this.state);
}
onMultiSelect = selectedOption => {
  this.setState(
    { assignedOrganisation:selectedOption },
    () => console.log(`Option selected:`, this.state.selectedOption)
  );
};

onRemove(optionList, removedItem) {
  context.setState({modules:optionList});
}

  
  handleOnChangePerson = (e,i)=>{
    
    var contactPersons = this.state.entry.contactPersons;

    contactPersons[i][e.target.name] = e.target.value;

    
    this.setState({entry:{...this.state.entry,contactPersons:contactPersons}});

    




  }


  fetchOrganisations(that,source){

    axios.get(apiBaseUrl1+"businus/organisation/list/"+localStorage.spId)
    .then(function (response) {
      console.log(response.data);
      debugger;

       var online =  _.where(response.data,{organisationMode:"online"});

       online.unshift({organisationId:"",organisationName:"Select Organisation"});

       var offline = _.where(response.data,{organisationMode:"offline"});
      var newOffline = [];
       _.each(offline, function (member) {


        newOffline.push({
          value:member.id,
            label: member.organisationName,
          name: member.organisationName,
          id: member.id,
          logo:member.logo,
          organisationId:member.organisationId,
          organisationEmail:member.organisationEmail
        })





      });

       //offline.unshift({organisationId:"",organisationName:"Select Organisation"});
      that.setState({allOrganisations:response.data});
      that.setState({onlineOrganisations:online});
      that.setState({offlineOrganisations:newOffline});
    })
    .catch(function (error) {
      console.log(error);
      
    });
  
  }


  
  addNewContact = () =>{

    var newPerson = {
      salutation:"",
      name:"",
      email:"",
      phone:""
    }

    var contactPersons = this.state.entry.contactPersons;
    contactPersons.push(newPerson);
    this.setState({entry:{...this.state.entry,contactPersons:contactPersons}});
 


  }
  
 

  componentDidMount() {
    const id = this.props.match.params.id;
    this.setState({id});
    this.fetchEmployees(this);

    
    
   
    this.fetchOrganisations(this);
  }


  fetchQueryById(id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/getClientById/" +
          id
      )
      .then(function(response) {
        debugger;
        console.log(response.data);
        context.setState({ entry: response.data });

        if(response.data.mode=="online"){
          context.setState({assignedOrganisation:response.data.assignedOrganisation[0].id});
        }
        else{

          context.setState({assignedOrganisation:response.data.assignedOrganisation,showOffline:true});

        }


        if(response.data.team!='NA'){
          context.setState({team:response.data.team});

        }

        context.setState({teamLead:response.data.teamLead.id});


      })
      .catch(function(error) {
        console.log(error);
      });
  }


  //fetching the data by id which this file recieved from the list

  
  
  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });



          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  getFiles(files) {
    this.setState({ selectedFile: files[0] });

  }

  getFormattedDate(millis){

    return new Date(millis).toDateString();


  }

  
  
  changeDocType(type){

    this.setState({docType:type});
  }

  fetchEmployees(that,source){

    axios.get(apiBaseUrl2+"businus/employee/list/"+localStorage.spId)
    .then(function (response) {
      debugger;
      console.log(response.data);
      var allEmployees = response.data;

      var teamLeads  = _.where(allEmployees,{title:'Team Lead'});
      var teamMembers  = _.filter(allEmployees,function(emp){
        return emp.title!='Team Lead';
      });



      

      var teamMembersMultiSelectData = []

      _.each(teamMembers, function (member) {


        teamMembersMultiSelectData.push({
          value:member.id,
            label: member.employeeId +" "+ member.firstName +" "+ member.lastName,
          name: member.employeeId +" "+ member.firstName +" "+  member.lastName,
          id: member.id,
          email:member.email
        })





      });

      teamLeads.unshift({employeeId:"",firstName:"Select Team Lead"});
      that.setState({teamMembers:teamMembersMultiSelectData,teamLeads:teamLeads});


      setTimeout(()=>{ that.fetchQueryById(that.state.id);},100);
     
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }
  

  sendMail = ()=> {
     var data = context.state.entry;
     var organisation = [];
    if(data.mode=="online"){
      var selectedOrganisation = _.findWhere(context.state.allOrganisations,{id:this.state.assignedOrganisation});
      organisation.push({name:selectedOrganisation.organisationName,id:selectedOrganisation.id,logo:selectedOrganisation.logo,organisationEmail:selectedOrganisation.organisationEmail});
      
    }else{
      organisation = this.state.assignedOrganisation;
    }


    var teamLead = _.findWhere(context.state.teamLeads,{ employeeId: this.state.teamLead});

    if(teamLead){
      teamLead = {id:teamLead.id,name:teamLead.firstName+teamLead.lastName};
    }
    else{
      teamLead="NA";
      
    }

    axios
      .post(
        apiBaseUrl2+"businus/client/update/"+data.id,
        {
          
          "companyName":data.companyName,
          "companyDisplayName":data.companyDisplayName,
          "customerEmail":data.customerEmail,
          "customerType":data.customerType,
          "salutation":data.salutation,
          "primaryContact":data.primaryContact,
          "customerContact":data.customerContact,
          "website":data.website,
          "billingAddress":data.billingAddress,
          "billingCountry":data.billingCountry,
          "billingAttention":data.billingAttention,
          "billingCity":data.billingCity,
          "billingState":data.billingState,
          "billingPhone":data.billingPhone,
          "billingZipCode":data.billingZipCode,
          "shippingAddress":data.shippingAddress,
          "shippingCountry":data.shippingCountry,
          "shippingAttention":data.shippingAttention,
          "shippingCity":data.shippingCity,
          "shippingState":data.shippingState,
          "shippingPhone":data.shippingPhone,
          "shippingZipCode":data.shippingZipCode,
          "contactPersons":data.contactPersons,
          "bankDetails":data.bankDetails,
          "team":context.state.team,
          "teamLead":teamLead,
           "mode":data.mode,
          "assignedOrganisation":organisation,
          "attributes":data.attributes
        })
      .then(function(response) {
        debugger;
      alert("Success!");
    //  context.fetchThreadById(context,query.id);
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  setMailPageUrl(){
  var url = window.location.href.replace(window.location.origin,"");
  localStorage.setItem("MAILURL",url);


  }

  
  onSelect = selectedOption => {
    this.setState(
      { team:selectedOption },
      () => console.log(`Option selected:`, this.state.selectedOption)
    );
  };

handleOnChangeAttribute = (e,i)=>{
    
  var entry = this.state.entry;




  this.setState({entry:entry});

 
 

  entry.attributes[i][e.target.name] = e.target.value;

  this.setState({entry});




}



fileUploadHandlerForAttachment = (that, docType,i) => {


  that.setState({ loading: true });
  debugger;
  axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
    {
      "uploadImage": that.state.selectedFile.base64,

      "imageName": that.state.selectedFile.name,
    })
    .then(function (response) {
      if (response.status == 200) {
        that.setState({ successupload: true });
        that.setState({ attachmentName: that.state.selectedFile.name });


        
        that.setState({ [docType]: "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });



        var entry = that.state.entry;
 
 

        entry.attributes[i]['attachment'] = "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name
    
        that.setState({entry:entry});

        //that.successNotification("Uploaded Succesfully");

      } else {
        //   that.errorNotification("Some Error Has Occured");
      }

    })
    .catch(function (error) {
      console.log(error);
    });
}



addNewAttribute = () =>{

  var newAttribute = {
    
    name:"",
    value:"",
    attachment:""
  }

  var entry = this.state.entry;

  entry.attributes = entry.attributes || [];

  entry.attributes.push(newAttribute);
  this.setState({entry:entry});



}

onRemove = (optionList, removedItem) => {
  
  this.setState({entry:{...this.state.entry,team:optionList}});
}


  handleOnBankDetailsChange = (e) => {
    debugger;
      var value = e.target.value;
    
      var formName = e.target.name;
  

      var entry = this.state.entry;
      var bankDetails = entry.bankDetails;

      bankDetails[formName] = value;
     
        this.setState({entry:
          {...entry,bankDetails:bankDetails}});
  
  
  
     
    }

  //updating the status button
  
  render() {
    const {sliderState} = this.state;
    const {superSliderState, entry} = this.state;    
    const active = " active-button"
    const deactive = "";
    return (
      <div className="Div">
      <div className="row breadcrumb-row" style={{marginLeft:'10px'}}>
      <BreadCrumbs /> 
      </div>
  {/* showing all the details of a single item from the table at the backend*/}
  <div className="row">
    <div className="dedicated-page">
      <div className="row ">
        <div className="col-md-12 info-column account-col2">
          <div id="field">
            <h2 className="account-head"> Edit Client</h2>
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                      
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        {" "}
                        Mode* :{" "}
                      </span>{" "}
                    </label>
                    <div className="col-md-8">
                      <div className="radio-inline">
                        <label className="cursor-poiner">
                          <input
                            name="mode"
                          type="radio"
                          value="offline"
                          checked={this.state.entry.mode=="offline"}
                            onChange={this.handleOnChange}
                            defaultValue="offline"
                          />
                          Offline{" "}
                        </label>
                      </div>
                      <div className="radio-inline">
                        <label className="cursor-poiner">
                          {" "}
                          <input
                            name="mode"
                            onChange={this.handleOnChange}
                            value="online"
                          checked={this.state.entry.mode=="online"}
                            type="radio"
                            defaultValue="online"
                          />
                          Online{" "}
                        </label>
                      </div>
                      <div className="radio-inline">
                        <label className="cursor-poiner">
                          <input
                            name="mode"
                          type="radio"
                          value="both"
                          checked={this.state.entry.mode=="both"}
                            onChange={this.handleOnChange}
                            defaultValue="both"
                          />
                          Both{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                      
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        {" "}
                        Customer Type:{" "}
                      </span>{" "}
                    </label>
                    <div className="col-md-8">
                      <div className="radio-inline">
                        <label className="cursor-poiner">
                          <input
                            name="customerType"
                             value="business"
                            checked={this.state.entry.customerType=="business"}
                            type="radio"
                            onChange={this.handleOnChange}
                            defaultValue="business"
                          />
                          Business:{" "}
                        </label>
                      </div>
                      <div className="radio-inline">
                        <label className="cursor-poiner">
                          {" "}
                          <input
                            name="customerType"
                            value="individual"
                            onChange={this.handleOnChange}
                            checked={this.state.entry.customerType=="individual"}
                            type="radio"
                            defaultValue="individual"
                          />
                          Individual:{" "}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                        id="ember295"
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        {" "}
                        Primary Contact:{" "}
                      </span>{" "}
                    </label>
                    <div
                      className="inline-fields col-md-8"
                      style={{ padding: 0 }}
                    >
                      <div className="col-md-2">
                        <select
                          placeholder="Salutation"
                          autofocus
                          onChange={this.handleOnChange}
                          name="salutation"
                        value={this.state.entry.salutation}
                          className="form-control select-form"
                        >
                          <option>Mr.</option>
                          <option>Mrs</option>
                          <option>Ms</option>
                        </select>
                      </div>

                      <div className="col-md-8">
                        <input
                          placeholder="Name"
                          autofocus
                          id="ember299"
                          onChange={this.handleOnChange}
                          name="primaryContact"
                        value={this.state.entry.primaryContact}
                          className="form-control ember-text-field ember-view"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  

                  <div className="form-group">
                    <label className="control-label col-md-4">
                      {" "}
                      Company Name:{" "}
                    </label>
                    <div className="col-md-6">
                      <input
                        id="ember301"
                        onChange={this.handleOnChange}
                        name="companyName"
                      value={this.state.entry.companyName}
                        className="form-control ember-text-field ember-view"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label col-md-4">
                      {" "}
                      Company Display Name:{" "}
                    </label>
                    <div className="col-md-6">
                      <input
                        id="ember301"
                        onChange={this.handleOnChange}
                        name="companyDisplayName"
                      value={this.state.entry.companyDisplayName}
                        className="form-control ember-text-field ember-view"
                        type="text"
                      />
                    </div>
                  </div>


                  <div className="form-group">
                        <label className="control-label col-md-4">Assigned Organisation</label>
                        <div className="col-md-8">
                      
{this.state.showOffline ? 
                        
                         
                            this.state.offlineOrganisations.length > 0 ?
                               <Select
                               value={this.state.assignedOrganisation}
                               onChange={this.onMultiSelect}
                               options={this.state.offlineOrganisations}
                               isMulti={true}
                             />
                              : '' 
                              :
  
                          
                          <select
                              name="assignedOrganisation"
                              onChange={this.handleOnChangeOrg}
                              value={this.state.assignedOrganisation}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            >
                     
  
                              {this.state.onlineOrganisations.map(function (organisation, i) {
  
                                return (<option value={organisation.id}>{organisation.organisationName}</option>)
                              })}
                            
  
                            </select>
                            }
             
                        </div>
                       
                      </div>

         
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  <div className="form-group">
                    <label className="control-label col-md-4">
                      Customer Email:
                    </label>
                    <div className="col-md-6">
                      <input
                        name="customerEmail"
                        onChange={this.handleOnChange}
                        value={this.state.entry.customerEmail}
                        className="form-control ember-text-field ember-view"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="control-label col-md-4">
                      Customer Phone:
                    </label>
                    <div className="col-md-6">
                      <input
                        name="customerContact"
                        onChange={this.handleOnChange}
                        value={this.state.entry.customerContact}
                        className="form-control ember-text-field ember-view"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="control-label col-md-4">Website:</label>
                    <div className="col-md-6">
                      <input
                        name="website"
                        onChange={this.handleOnChange}
                        value={this.state.entry.website}
                        className="form-control ember-text-field ember-view"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          
    
     <div className="row " style={{marginTop:'40px'}}>
     <div className={superSliderState == 'additional' ? "col-md-2 active1 superoptions" : "col-md-2 superoptions"} >
       <div className=" " onClick={() => this.changeSuperSliderState('additional')} >Additional Info</div>
     </div>

    
   </div>


{superSliderState==='additional' ? 

   <div className="row " style={{borderTop:'2px solid #4985BD', backgroundColor:'#f7f7f7'}}>
     <div className={sliderState == 1 ? "col-md-2 active2 " : "col-md-2"} >
       <div className="options8 " onClick={() => this.changeSliderState(1)} ><i className="fa fa-home optionsIcon" style={{fontSize:'12px'}}></i>Address</div>
     </div>
     <div className={sliderState == 2 ? "col-md-3 active2 " : "col-md-3"} >
       <div className="options8" onClick={() => this.changeSliderState(2)}><i className="fa fa-envelope optionsIcon" style={{fontSize:'12px'}}></i>Customer Email</div>
     </div>
     <div className={sliderState == 3 ? "col-md-3 active2 " : "col-md-3"} >
       <div className="options8" onClick={() => this.changeSliderState(3)}><i className="fa fa-university optionsIcon" style={{fontSize:'12px'}}></i> Bank Details</div>
     </div>
     <div className={sliderState == 4 ? "col-md-2 active2 " : "col-md-2"} >
       <div className="options8" onClick={() => this.changeSliderState(4)}><i className="fa fa-address-card optionsIcon" style={{fontSize:'12px'}}></i> Attributes</div>
     </div>
     <div className={sliderState == 5 ? "col-md-2 active2 " : "col-md-2"} >
       <div className="options8" onClick={() => this.changeSliderState(5)}><i className="fa fa-users optionsIcon" style={{fontSize:'12px'}}></i> Employees</div>
     </div>
   </div>

   : superSliderState==='access' ?

   <div className="row " style={{borderTop:'2px solid #4985BD', backgroundColor:'#f7f7f7'}}>
     <div className={sliderState == 1 ? "col-md-2 active2 " : "col-md-2"} >
       <div className="options8 " onClick={() => this.changeSliderState(1)} ><i className="fa fa-home optionsIcon" style={{fontSize:'12px'}}></i>Access Control</div>
     </div>
     
   </div>

   :superSliderState==='reports' ?

   <div className="row " style={{borderTop:'2px solid #4985BD', backgroundColor:'#f7f7f7'}}>
     <div className={sliderState == 1 ? "col-md-2 active2 " : "col-md-2"} >
       <div className="options8 " onClick={() => this.changeSliderState(1)} ><i className="fa fa-home optionsIcon" style={{fontSize:'12px'}}></i>Reports</div>
     </div>
    
   </div>
   :''
}

{/*--------------------------------------------------------1-------------------------------*/}




            {sliderState==1 ?

            <div className="container row">
              <div className="col-md-6">
                <div className="row" style={{marginBottom:'-20px'}}>
                  <label className="control-label add-header col-md-12">
                    Billing Address
                  </label>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-4">Attention :</label>
                  <div className="col-md-7">
                    <input
                      name="billingAttention"
                      onChange={this.handleOnChange}
                      value={this.state.entry.billingAttention}
                      className="form-control ember-text-field ember-view"
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-4">Country :</label>
                  <div className="col-md-7">
                    <input
                      name="billingCountry"
                      onChange={this.handleOnChange}
                      value={this.state.entry.billingCountry}
                      className="form-control ember-text-field ember-view"
                      type="text"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-md-4">Address* :</label>
                  <div className="col-md-7">
                    <textarea
                      placeholder="Billing Address"
                      name="billingAddress"
                      onChange={this.handleOnChange}
                      value={this.state.entry.billingAddress}
                      id="ember330"
                      className="form-control ember-text-area ember-view"
                      defaultValue={""}
                    />
                  </div>
                </div>

                <div className="form-group" style={{marginTop:'36px'}}>
                  <label className="control-label col-md-4">City* :</label>
                  <div className="col-md-7">
                    <input
                      id="ember332"
                      name="billingCity"
                      onChange={this.handleOnChange}
                      value={this.state.entry.billingCity}
                      className="form-control ember-text-field ember-view"
                      type="text"
                    />{" "}
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-4">State :</label>
                  <div className="col-md-7">
                    <input
                      id="ember333"
                      className="form-control ember-text-field ember-view"
                      name="billingState"
                      onChange={this.handleOnChange}
                      value={this.state.entry.billingState}
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-4">Zip Code* :</label>
                  <div className="col-md-7">
                    <input
                      id="ember334"
                      name="billingZipCode"
                      onChange={this.handleOnChange}
                      value={this.state.entry.billingZipCode}
                      className="form-control ember-text-field ember-view"
                      type="text"
                    />{" "}
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-4">Phone* :</label>
                  <div className="col-md-7">
                    <input
                      id="ember335"
                      name="billingPhone"
                      onChange={this.handleOnChange}
                      value={this.state.entry.billingPhone}
                      className="form-control ember-text-field ember-view"
                      type="text"
                    />{" "}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="row" style={{marginBottom:'-20px'}}>
                  <label className="control-label add-header col-md-12">
                    Shipping Address
                  </label>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-4">Attention :</label>
                  <div className="col-md-7">
                    <input
                      name="shippingAttention"
                      onChange={this.handleOnChange}
                      value={this.state.entry.shippingAttention}
                      className="form-control ember-text-field ember-view"
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-4">Country :</label>
                  <div className="col-md-7">
                    <input
                      name="shippingCountry"
                      onChange={this.handleOnChange}
                      value={this.state.entry.shippingCountry}
                      className="form-control ember-text-field ember-view"
                      type="text"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-md-4">Address :</label>
                  <div className="col-md-7">
                    <textarea
                      placeholder="Shipping Address"
                      name="shippingAddress"
                      onChange={this.handleOnChange}
                      value={this.state.entry.shippingAddress}
                      id="ember330"
                      className="form-control ember-text-area ember-view"
                      defaultValue={""}
                    />
                  </div>
                </div>

                <div className="form-group" style={{marginTop:'36px'}}>
                  <label className="control-label col-md-4">City:</label>
                  <div className="col-md-7">
                    <input
                      id="ember332"
                      name="shippingCity"
                      onChange={this.handleOnChange}
                      value={this.state.entry.shippingCity}
                      className="form-control ember-text-field ember-view"
                      type="text"
                    />{" "}
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-4">State :</label>
                  <div className="col-md-7">
                    <input
                      id="ember333"
                      className="form-control ember-text-field ember-view"
                      name="shippingState"
                      onChange={this.handleOnChange}
                      value={this.state.entry.shippingState}
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-4">Zip Code :</label>
                  <div className="col-md-7">
                    <input
                      id="ember334"
                      name="shippingZipCode"
                      onChange={this.handleOnChange}
                      value={this.state.entry.shippingZipCode}
                      className="form-control ember-text-field ember-view"
                      type="text"
                    />{" "}
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-4">Phone :</label>
                  <div className="col-md-7">
                    <input
                      id="ember335"
                      name="shippingPhone"
                      onChange={this.handleOnChange}
                      value={this.state.entry.shippingPhone}
                      className="form-control ember-text-field ember-view"
                      type="text"
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
            :''}

{/*--------------------------------------------------------2-------------------------------*/}
{sliderState==2 ? 
<div>
  <div className="row" style={{marginTop:'20px'}}>
    <div className="col-md-12">
    <div className="row" style={{marginBottom:'-20px'}}>
      <label className="control-label add-header col-md-12">
        Contact Persons*
      </label>
    </div>
  <div className="text-right" style={{marginRight:'40px'}}>
 <button className="add-button" onClick={this.addNewContact} > <i className="fa fa-plus "></i></button>
  </div>
              <table className="add-customer-table" style={{width:"100%"}}>
  <tr>
    <th className="table-head">Salutation</th>
    <th className="table-head">Name</th>
  
    <th className="table-head">Email Address </th>
    <th className="table-head">Work Phone</th>
  </tr>
  {this.state.entry.contactPersons.map((person, i) => (
         
         <tr>
            <td className="table-item">
              <select className="form-control select-form" name="salutation" placeholder="salutation" onChange={(e) => this.handleOnChangePerson(e,i)} value={this.state.entry.contactPersons[i].salutation}>  
         <option>Mr.</option>
         <option>Mrs</option>
         <option>Ms</option>
       </select>
       </td>
         <td className="table-item"><input name="name" onChange={(e) => this.handleOnChangePerson(e,i)} 
         value={this.state.entry.contactPersons[i].name}/> </td>
         <td className="table-item"><input name="email" onChange={(e) => this.handleOnChangePerson(e,i)} value={this.state.entry.contactPersons[i].email}/> </td>
         <td className="table-item"><input name="phone" onChange={(e) => this.handleOnChangePerson(e,i)} value={this.state.entry.contactPersons[i].phone}/> </td>
         </tr>
         
        
         ))}
 
 
</table></div></div></div> :''}

{/*--------------------------------------------------------3-------------------------------*/}


{sliderState==3 ?

<div className="container row">
  <div className="col-md-12">
    <div className="row" style={{marginBottom:'-20px'}}>
      <label className="control-label add-header col-md-12">
        Banking Details
      </label>
    </div>
    <div className="form-group">
      <label className="control-label col-md-4">Bank Name* :</label>
      <div className="col-md-8">
        <input
          name="bankName"
          onChange={this.handleOnBankDetailsChange}
          value={this.state.entry.bankDetails.bankName}
          className="form-control ember-text-field ember-view"
          type="text"
        />
      </div>
    </div>
    <div className="form-group">
      <label className="control-label col-md-4">Branch Name*:</label>
      <div className="col-md-8">
        <input
          name="branchName"
          onChange={this.handleOnBankDetailsChange}
          value={this.state.entry.bankDetails.branchName}
          className="form-control ember-text-field ember-view"
          type="text"
        />
      </div>
    </div>

    <div className="form-group">
      <label className="control-label col-md-4">Account No*:</label>
      <div className="col-md-8">
        <input
         name="accountNo"
          onChange={this.handleOnBankDetailsChange}
          value={this.state.entry.bankDetails.accountNo}
          id="ember330"
          className="form-control ember-text-field ember-view"
          type="text"
        />
      </div>
    </div>

  
    <div className="form-group" >
      <label className="control-label col-md-4">Account Type:</label>
      <div className="col-md-6">
       
         <select
          name="accountType"
          onChange={this.handleOnBankDetailsChange}
          value={this.state.entry.bankDetails.accountType}
          className="form-control ember-text-field ember-view"
          type="text"
        >
          <option value="none selected">Select One</option>
         <option value="savings">Savings</option>
  <option value="current">Current</option>
  <option value="credit card">Credit Card A/C</option>
  <option value="loans">Loans</option>
  <option value="overdraft">Overdraft</option>
  <option value="deposit">Deposit</option>
</select>
      </div>
    </div>
    <div className="form-group">
      <label className="control-label col-md-4">IFSC Code* :</label>
      <div className="col-md-8">
        <input
          id="ember333"
          className="form-control ember-text-field ember-view"
          name="ifscCode"
          onChange={this.handleOnBankDetailsChange}
          value={this.state.entry.bankDetails.ifscCode}
          type="text"
        />
      </div>
    </div>
    <div className="form-group">
      <label className="control-label col-md-4">MICR Code :</label>
      <div className="col-md-8">
        <input
          id="ember334"
          name="micrCode"
          onChange={this.handleOnBankDetailsChange}
          value={this.state.entry.bankDetails.micrCode}
          className="form-control ember-text-field ember-view"
          type="text"
        />{" "}
      </div>
    </div>
  
  </div>

  
  
</div>
:''}


{sliderState == 4 ?
<div>
  <div className="row" style={{marginTop:'20px'}}>
    <div className="col-md-12">
    <div className="row" style={{marginBottom:'-20px'}}>
      <label className="control-label add-header col-md-12">
        Attributes
      </label>
    </div>
  <div className="text-right" style={{marginRight:'40px'}}>
 <button className="add-button" onClick={this.addNewAttribute} > <i className="fa fa-plus "></i></button>
  </div>
              <table className="add-customer-table" style={{width:"100%"}}>
  <tr>
    <th className="table-head">Attributes</th>
    <th className="table-head">Attribute Value</th>
  
    <th className="table-head">Attribute Attachment </th>
   
  </tr>
  {this.state.entry.attributes!=undefined ? this.state.entry.attributes.map((person, i) => (
         
         <tr>
            <td className="table-item">
              <select className="form-control select-form" name="name" placeholder="salutation" onChange={(e) => this.handleOnChangeAttribute(e,i)} value={this.state.entry.attributes[i].name}>  
              <option value="pan">PAN</option>
 
 <option >Aadhar Card Number</option>
 <option >Authorised Capital</option>
 <option >Category</option>
 <option >CIN no</option>
 <option >DIN no</option>
 <option >Excise No</option>
 <option >GST No</option>
 <option >Incorporation Date</option>
 <option >Paid Up Capital</option>
 <option >PAN</option>
 <option >Pt No.(EC)</option>
 <option >Pt No.(RC)</option>
 <option >Service Tax No.</option>
 <option >Shop Act Expiry Date.</option>
 <option >Shop Act No.</option>
 <option >Shop Act Reg Date.</option>
 <option >TAN No</option>
 <option >VAT No.</option>
 <option >With Effect From (W.E.F)</option>

       </select>
       </td>
         <td className="table-item"><input name="value" onChange={(e) => this.handleOnChangeAttribute(e,i)} 
         value={this.state.entry.attributes[i].value}/> </td>
         <td className="table-item">  <div className="col-md-6">
               
               <FileBase64
              multiple={true}
              onDone={this.getFiles.bind(this)}   /></div>
              
              <div className="col-md-5">
              
              <button className="btn btn-primary attachment" onClick={() => this.fileUploadHandlerForAttachment(this, "attributeAttachment",i)}
              value="Select a File">
              <i className="fa fa-paperclip" aria-hidden="true"></i>Attachment
              </button>
                                        </div> </td>
         
         </tr>
         
        
         )):''}
 
 
</table></div></div></div> :''}



{ sliderState== 5 ? <div className="container row">
  <div className="col-md-12">
    <div className="row" style={{marginBottom:'-20px'}}>
      <label className="control-label add-header col-md-12">
        Team
      </label>
    </div>

    <div className="form-group">
                        <label className="control-label col-md-3">Team Lead:</label>
                        <div className="col-md-7">
                          <select
                            name="teamLead"
                            onChange={this.handleOnChangeTeam}
                            value={this.state.teamLead}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >

                            {this.state.teamLeads.map(function (teamLead, i) {

                              return (<option value={teamLead.employeeId}>{teamLead.employeeId} {teamLead.firstName} {teamLead.lastName}</option>)
                            })}

                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="control-label col-md-3">Team:</label>
                        <div className="col-md-7">

                          {this.state.teamMembers.length > 0 ?
                             <Select
                             value={this.state.team}
                             onChange={this.onSelect}
                             options={this.state.teamMembers}
                             isMulti={true}
                           />
                            : ''}

                        </div>
                      </div>

    </div> 
    </div> 
    : ''}


            <div className="text-left">
              <button type="button" onClick={this.sendMail} className="button1">
                {" "}
                Update{" "}
              </button>
            </div>
            {/* this says that if editing state is on then the Function saveAccountDetails will be called */}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
      
     
    );
  }
}

