import React, { Component } from "react";

import { Link } from "react-router-dom";
import BreadCrumbs from "../../BreadCrumbs";
import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { ChatFeed, Message } from 'react-chat-ui'


import SubmitButton  from "../../common/SubmitButton";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from 'underscore';
let context = null;
const data = [];
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               :'0px ',
    position               :'Absolute',
    zIndex                 :'30000'     ,
    backgroundColor         :'none',  
  }
};
 
export default class addTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assignmentTitle:data.assignmentTitle,
      startDate:data.startDate,
      endDate:data.endDate,
      assignmentOverview:data.assignmentOverview,
      priority:data.priority,
      clients:[],
      attachmentPlaceholders: [],
      attachmentArray: [],
      attachment: "",
     
    };
    context = this;
    this.openModal = this.openModal.bind(this);
   
    this.closeModal = this.closeModal.bind(this);
  }

  changeSliderState(orderNumber){

    this.setState({sliderState:orderNumber});

}
  //function for changing the status button on click to open or close
  handleOnChange = (e) => {
  debugger;
    var value = e.target.value;
  
    var formName = e.target.name;

      this.setState({[formName]:value});



    console.log(this.state);
  }
  
  
  openModal(type) {
    this.changeDocType(type);
    this.setState({modalIsOpen: true});
  }
 
  
 
  closeModal() {
    this.setState({modalIsOpen: false});
  }
 

  componentDidMount() {
    const id = this.props.match.params.id;

    this.fetchQueryById(this, id);
    this.fetchThreadById(this, id);
    this.fetchClients(this);
    this.markAsRead(this,id);
  }


  markAsRead(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/read/" +
          id
      )
      .then(function(response) {
       
       
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  getFiles(files) {
    this.setState({ selectedFile: files[0] });
    this.fileUploadHandler(this);
  }


  fileUploadHandler = (that, docType) => {
    that.setState({ loading: true });
    debugger;
    axios
      .post(apiBaseUrl2 + "businusquery/uploadAttachment", {
        uploadImage: that.state.selectedFile.base64,

        imageName: that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({
            attachment: {
              name: that.state.selectedFile.name,
              attachment:
                "https://businus-files-live.s3.amazonaws.com/mailAttachments/" +
                that.state.selectedFile.name,
            },
          });
          let attachmentArray = that.state.attachmentArray;
          attachmentArray.push(that.state.attachment);
      
          that.setState({ attachmentArray: attachmentArray });
          that.setState({
            [docType]:
              "https://businus-files-live.s3.amazonaws.com/mailAttachments/" +
              that.state.selectedFile.name,
          });
          that.setState({ i: "1" });

          console.log(attachmentArray,this.state.i);

          //that.successNotification("Uploaded Succesfully");
        } else {
          //   that.errorNotification("Some Error Has Occured");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };


    addMoreAttachment() {
    var i = 0;
    var addMore = "false";
    this.setState({ addMore: "true" });
  }


  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(
        apiBaseUrl1 +"businus/customer/list/"+localStorage.spId 
         
      )
      .then(function(response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  
 
  fetchThreadById(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/listmail/" +
          id
      )
      .then(function(response) {
        debugger;
        console.log(response.data);
        var threads = response.data=="" ? [] : _.sortBy(response.data,'date').reverse();
        that.setState({ thread: threads});

        var documents = _.where(threads,{docType:"Document"});
        var proposals = _.where(threads,{docType:"Proposal"});
        var mails = _.where(threads,{docType:"Mail"});
        that.setState({documents});
        that.setState({proposals});
        that.setState({mails});
        
      
      })
      .catch(function(error) {
        console.log(error);
      });
  }


  fetchClients(that,source){

    axios.get(apiBaseUrl2+"businus/customer/list/"+localStorage.spId)
    .then(function (response) {
      console.log(response.data);
      that.setState({clients:response.data});
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }



  getFormattedDate(millis){

    return new Date(millis).toDateString();


  }

  toggleOpen = status => {
    if (status == undefined || status == "open") {
      status = "close";
    } else {
      status = "open";
    }
    //(this.setState({ entry: [...this.state.entry,{status:status} ] });)
    var newEntry = this.state.entry;
    newEntry.status = status;
    this.setState({ entry: newEntry });
    context.updateStatus(context, this.state.entry.id, status);
  };
  
  changeDocType(type){

    this.setState({docType:type});
  }


  

  sendMail() {
     var data = context.state;
     context.setState({isSubmitting:false});
    axios
      .post(
        apiBaseUrl1 +"businus/template/add",
        {
          
          "title":data.title,
          "name":data.name,
          templateName:data.templateName,
          templateId:data.templateId,
          "type":"Team",
          update:data.update,
          checklists:[],
          document:data.document,
          "templateType":data.type,
          "by":localStorage.userName,
          spId:localStorage.spId,
         
        })
      .then(function(response) {
        debugger;
      alert("Success!");
      context.setState({isSubmitting:'done'});
    //  context.fetchThreadById(context,query.id);
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  setMailPageUrl(){
  var url = window.location.href.replace(window.location.origin,"");
  localStorage.setItem("MAILURL",url);


  }




  //updating the status button
  
  render() {
    const {sliderState} = this.state;
    const active = " active-button"
    const deactive = "";
    return (
      <div className="Div">
        <div className="row breadcrumb1-row" style={{marginLeft:'2px'}} >
      <div className="col-md-12"><BreadCrumbs/></div>
    
  </div>
  {/* showing all the details of a single item from the table at the backend*/}
  <div className="row">
    <div className="dedicated-page2">
      <div className="row ">
        <div className="col-md-12 info-column account-col2">
          <div id="field">
            <h2 className="account-head"> Add Template</h2>
            <div className="row">
                      <div className="col-md-8">

                      <div className="form-group">
                          <label className="control-label col-md-4">
                            <span
                              id="ember295"
                              className="popovercontainer text-dashed-underline ember-view"
                              data-original-title
                              title
                            >
                             Type
                      </span>{" "}
                          </label>
                          <div
                            className="inline-fields col-md-8"
                            style={{ padding: 0 }}
                          >

                            <div className="col-md-8">
                              <select

                                autofocus
                                id="ember299"
                                onChange={this.handleOnChange}
                                name="type"
                                value={this.state.type}
                                className="form-control ember-text-field ember-view"
                                type="text"
                              >
                                <option>Select</option>
                                  <option>Messages</option>
                                  
                                  <option>Document</option>


                              </select>
                            </div>
                          </div>
                        </div>
                        
                        <div className="form-group">
                          <label className="control-label col-md-4">
                            <span
                              id="ember295"
                              className="popovercontainer text-dashed-underline ember-view"
                              data-original-title
                              title
                            >
                             Template Name
                      </span>{" "}
                          </label>
                          <div
                            className="inline-fields col-md-8"
                            style={{ padding: 0 }}
                          >

                            <div className="col-md-8">
                              <input

                                autofocus
                                id="ember299"
                                onChange={this.handleOnChange}
                                name="templateName"
                                value={this.state.templateName}
                                className="form-control ember-text-field ember-view"
                                type="text"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-4">
                            <span
                              id="ember295"
                              className="popovercontainer text-dashed-underline ember-view"
                              data-original-title
                              title
                            >
                             Template Subject
                      </span>{" "}
                          </label>
                          <div
                            className="inline-fields col-md-8"
                            style={{ padding: 0 }}
                          >

                            <div className="col-md-8">
                              <input

                                autofocus
                                id="ember299"
                                onChange={this.handleOnChange}
                                name="name"
                                value={this.state.name}
                                className="form-control ember-text-field ember-view"
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                        


                       

                        <div className="form-group">
                          <label className="control-label col-md-4">

                            Template Body
                    </label>
                          <div className="col-md-6">
                            <textarea
                              id="ember301"
                              onChange={this.handleOnChange}
                              name="update"
                              value={this.state.update}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            ></textarea>
                          </div>
                        </div>

                        <div className="row" style={{ marginTop: "30px" }}>
                      {this.state.i == "1" ? (
                        <div className="row">
                          <div className="col-md-4">
                            
                            <button
                              className="btn btn-primary attachment"
                             
                              value="Select a File"
                            >
                             
                              Add multiple attachments:
                            </button>{" "}
                          </div>
                       
                            <div className="col-md-6 upload-box">
                              {" "}
                              <FileBase64
                                multiple={true}
                                onDone={this.getFiles.bind(this)}
                              />
                            </div>
                        
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-md-4">
                            {" "}
                            <button
                              className="btn btn-primary attachment"
                              onClick={() => this.addMoreAttachment()}
                              value="Select a File"
                            >
                              
                              Add a Attachment
                            </button>{" "}
                          </div>
                         
                            <div className="col-md-6 upload-box">
                              {" "}
                              <FileBase64
                                multiple={true}
                                onDone={this.getFiles.bind(this)}
                              />
                            </div>
                         
                        </div>
                      )}
                      <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                          {this.state.attachmentArray.map((attach, i) => {
                            return <h6>{i + ". " + attach.name}</h6>; ///ask
                          })}
                        </div>
                      </div>

                      </div>
                    </div>

                    </div>
           

            <div className="text-left">
            <SubmitButton clickHandler={this.sendMail}
                   isSubmitting={this.state.isSubmitting} /> 
            </div>
            {/* this says that if editing state is on then the Function saveAccountDetails will be called */}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
      
     
    );
  }
}

