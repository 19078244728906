import React, { Component } from "react";

import PageCheckList from '../Resources/pageChecklist';
export default class pageChecklistWrapper extends Component {
  

 


  //updating the status button

  render() {
   

    return (
            <PageCheckList type="component"  id={this.props.match.params.id} />
    );
  }
}

