import React, { Component } from "react";

import BreadCrumbs from '../../BreadCrumbs';
import { Link } from "react-router-dom";
import axios from 'axios';
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import ReactTable from 'react-table';
import $ from "jquery";
import _ from 'underscore';

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

const data = [];


let context="null";

//defined all the columns and fetched the data for every cell.
const columns = [



  {

    Header: props => <span className="form-textHead cell-head">Employee Id</span>,
    accessor: 'employeeId',
    id:"employeeId",
    Cell: props => <span className='form-text cell'>{props.value}</span>,
    filterMethod: (filter, row) =>
    { return row.employeeId.toLowerCase().includes(filter.value.toLowerCase()) },
    minWidth:180
    },
    {

      Header: props => <span className="form-textHead cell-head">Name</span>,
      accessor: d => {return {firstName:d.firstName,lastName:d.lastName,id:d.id}},
      filterMethod: (filter, row) =>
               { return row.name.firstName.toLowerCase().includes(filter.value.toLowerCase()) ||  row.name.lastName.toLowerCase().includes(filter.value.toLowerCase()) }
                       
                    ,
      id:"name",
      Cell: props => 
      <Link className="link" to={"./edit-controls/"+props.value.id}>{props.value.firstName + props.value.lastName}</Link>,
      minWidth:180
      },
  
      {
        Header: props => <span className="form-textHead cell-head"> Email</span>,
        accessor: 'email',
        Cell: props => <span className='form-text cell'>{props.value}</span>,
        minWidth:240
      },
      {
        Header: props => <span className="form-textHead cell-head">department</span>,
        accessor: 'department',
        Cell: props => <span className='form-text cell'>{props.value}</span>,
        minWidth:170
      },
      {
        Header: props => <span className="form-textHead cell-head">Title</span>,
        accessor: 'title',
        Cell: props => <span className='form-text cell'>{props.value}</span>,
        minWidth:170
      },
]
export default class viewAccessControlEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      menu1:[
       



 ]
 
    };
    context = this;
  }
  componentDidMount(){

  this.fetchQueries(this,this.props.source);}


  toggleOpen = id => {
    var list  =this.state.menu1;
    var index = _.findIndex(list,function(entry){return entry.id==id});
    
    list[index].status =  (list[index].status == "open" || list[index].status==undefined) ? "close" : 'open';
    
    var newList = JSON.parse(JSON.stringify( list ));
    context.setState({menu1:newList});
    context.updateStatus(context,id,list[index].status);
    
    
    
      };

  
  fetchQueries(that,source){

    axios.get(apiBaseUrl2+"businus/employee/list/"+localStorage.spId)
    .then(function (response) {
      console.log(response.data);
      that.setState({menu1:response.data});
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }

  

  render() {
    return (
      <div>
       
      
      <div className="Div">
        <div className="row breadCrumb-row">
      <BreadCrumbs/>
      </div>
            <div className="row">
          
              <Row>
                <Col>
                  <div className="card">

                  <ReactTable className="myTable" resolveData={data => data.map(row => row)}  data={this.state.menu1} columns={columns} filterable />
                  


                    
                       
                      
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          </div>
    );
  }
}
