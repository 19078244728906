import React, { Component } from "react";
import axios from 'axios';
import Select from 'react-select';
import '../css/appointment.css';
import _ from "underscore";
import BreadCrumbs from "../../BreadCrumbs";

export default class EditAppointment extends Component{
    constructor(props){
        super(props)
        this.state={
            resData:[],
            title:'',
            description:'',
            meetingType:"",
            location:'',
            typeMasterList:[],
            consultantList:[],
            bookingDetailList:[],
            clientList:[],
            teamList:[],
            otherMemberList:[],
            email:'',
            appointmentType:'',
            consultantName:'',
            meetingTypeList:["InPersonMeeting","InBoundPhoneCall","OutBoundPhoneCall","WebMeeting"],
            locationPlaceHolder:'Please Mention the Correct Address',
            selectedClientOption:null,
            selectedTeamOption:null,
            dateArray:[],
            meetingSlot:[],
            bookingDate:'',
            currentBookingDetail:[],
            prevTime:"",
            openAppointmentFlag:true,
            timeArray:['12:00','1:00','2:00','3:00','4:00','5:00','6:00','7:00','8:00','9:00','10:00','11:00'],
            hourArray:[1,2,3,4,5,6,7,8,9,10,11,12],
            minuteArray:[],
            id:''
        }
        this.dateSelected = this.dateSelected.bind(this)
        this.fetchQueries = this.fetchQueries.bind(this)
    }
    componentDidMount(){
        let id = this.props.match.params.id
        this.setState({id:id})

        this.fetchAllClient(this)
        this.fetchAllTeam(this)
        this.fetchAllAppointmentType(this)
        this.fetchAllConsultant(this)
        this.fetchQueries(this,id)
        this.returnMinute(this)
        // this.dateSelected(this.state.resData.meetingSlot.date)
    }

    returnMinute(){
        let minArray=[]
        for(var i=0;i<60;i++){
            minArray.push(i)
        }
        this.setState({minuteArray:minArray})
    }

    fetchQueries(that,id){
        axios.get("https://q07uwo7htc.execute-api.us-east-1.amazonaws.com/dev/businus/getAppointmentById/"+id)
        .then(async function (response) {
          console.log(response.data);
          that.setState({
              resData:response.data,
              openAppointmentFlag:response.date.openAppointmentFlag,
              title:response.data.title,
              description:response.data.description,
              appointmentType:response.data.appointmentType,
              consultantName:response.data.consultantName,
              meetingType:response.data.meetingType,
              location:response.data.location,
              selectedClientOption:response.data.selectedClientOption,
              selectedTeamOption:response.data.selectedTeamOption,
              otherMemberList:response.data.otherMemberList,
              meetingSlot:response.data.meetingSlot,
            });
                await that.dateSelected(response.data.meetingSlot.date)
                that.handleMeetingTime('',response.data.meetingSlot.timing)
            
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    handleMeetingTImeChange = (e) =>{
        var name = e.target.name
        this.setState({[name]:e.target.value})
        
        this.handleMeetingTime('',e.target.value)
    }

    handleOnChange = (e) => {
        var value = e.target.value;
        var formName = e.target.name;

        this.setState({ [formName]: value });
    }

    handleMeetingChange = (e) => {
        var value = e.target.value;
        if(value==='Open Appointment'){
            this.setState({openAppointment:true})
        }
        else{
            this.fetchBookingDetail(value,true)
        }
    }

    handleMeetingType=(e)=>{
        var value = e.target.value;
        var formName = e.target.name;
        let location =''

        if(value==='InPersonMeeting'){
            location='Please Mention the Correct Address'
        }
        else if(value==='InBoundPhoneCall'){
            location='Please provide the incoming number'
        }
        else if(value==='OutBoundPhoneCall'){
            location='Please provide the outgoing number'
        }
        else{
            location='Please mention the Web Meeting link'
        }
        this.setState({ [formName]: value,locationPlaceHolder:location });

    }

    handleClientChange=(selectedClientOption)=>{
        this.setState({selectedClientOption})
    }

    fetchAllClient(that){
        axios.get("https://uf5i6v71yi.execute-api.us-east-1.amazonaws.com/dev/businus/customer/list")
        .then(function(response){
            var allClientData = response.data;
            var clientMultiSelectData = [];

            _.each(allClientData, function(client){
                clientMultiSelectData.push({
                    value:client.id,
                    label: client.customerId+" "+client.primaryContact,
                    name:client.customerId+" "+client.primaryContact,
                    id:client.id,
                    email:client.customerEmail,
                })
            })
            that.setState({clientList:clientMultiSelectData})
        }).catch(function(error){
            console.log(error)
        })
    }

    handleTeamChange=(selectedTeamOption)=>{
        this.setState({selectedTeamOption})
    }

    addOtherMembersToArray(email){
        console.log('email --',email)
        let array = this.state.otherMemberList;
        array.unshift({
          key: array.length + 1,
          email: email,
        });
        this.setState({ otherMemberList: array, email: "" });
    }

    handleOtherMembersDelete(key){
        let array = this.state.otherMemberList;
        for (var i = 0; i < array.length; i++) {
          if (array[i].key === key) {
            array.splice(i, 1);
          }
        }
        this.setState({ otherMemberList: array });
    }

    fetchAllTeam(that){
        axios.get("https://287qejmrtb.execute-api.us-east-1.amazonaws.com/dev/businus/employee/list/"+localStorage.spId)
        .then(function(response){
            var allTeamData = response.data;
            var teamMultiSelectData = [];

            _.each(allTeamData, function(team){
                teamMultiSelectData.push({
                    value:team.id,
                    label: team.employeeId+" "+team.firstName +" "+team.lastName,
                    name:team.employeeId+" "+team.firstName +" "+team.lastName,
                    id:team.id,
                    email:team.email,
                })
            })
            that.setState({teamList:teamMultiSelectData})
        }).catch(function(error){
            console.log(error)
        })
    }


    fetchAllAppointmentType(that){
        axios.get("https://q07uwo7htc.execute-api.us-east-1.amazonaws.com/dev/businus/typeMaster/list")
        .then(function (response) {
          console.log(response.data);
          that.setState({typeMasterList:response.data,appointmentType:response.data[0].title});
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    fetchAllConsultant(that){
        return axios({       
            url: 'https://q07uwo7htc.execute-api.us-east-1.amazonaws.com/dev/businus/consltant/list',
            method: 'GET',
            crossDomain: true,
            responseType: 'json', 
          }).then((response) => {
          that.setState({consultantList:response.data,consultantName:response.data[0].personalDetail.personalDetail.name})
          that.fetchBookingDetail(response.data[0].id)
         });
    }

    fetchBookingDetail(id){
        let consultantList = this.state.consultantList

        for(var i=0;i<consultantList.length;i++){
            if(consultantList[i].id===id){
                this.setState({bookingDetailList:consultantList[i].bookingDetail,consultantName:consultantList[i].personalDetail.personalDetail.name})
            }
        }
        if(this.state.bookingDetailList.length>0){
            this.generateBookingDate()
        }
    }

    generateBookingDate(){
        let today = new Date()
        let index = today.getDay()
        let dateArray = this.state.dateArray

        let bookingDay=''
        let months=["January","February","March","April","May","June","July","August","September","October","November","December"]
        let days = ["Sun","Mon","Tue","Wed","Thurs","Fri","Sat"]

        for(var i=1; i<8; i++){
            bookingDay=today.getDate()+" "+months[today.getMonth()].slice(0,3)+" "+days[index]
            dateArray.push({key:days[index],day:bookingDay})
            today = this.handleBookingDate(today)
            index = today.getDay()
        }
    
        this.setState({dateArray:dateArray})

        this.availableBookingDate(dateArray)
    }
    handleBookingDate(today){
        let tomorrow =  new Date(today.getTime()+86400000)
        return tomorrow
    }

    availableBookingDate(dateArray){
    let flag = true

    for(var j=0;j<dateArray.length;j++){
        for(var i=0;i<this.state.bookingDetailList.length; i++){ 
            if(this.state.bookingDetailList[i].day===dateArray[j].key){
                flag = false
            }
        }
        if(flag){
            document.getElementById(dateArray[j].day).style.textDecorationLine='line-through';
            document.getElementById(dateArray[j].day).disabled = true;
        }
        flag = true
    }
}

    dateSelected=(date)=>{
        let key =''
        console.log('date selected',this.state.dateArray,date)
        for(var i=0;i<this.state.dateArray.length;i++){
            if(date===this.state.dateArray[i].day){
                document.getElementById(this.state.dateArray[i].day).classList.add('meeting-day-active')
                key=this.state.dateArray[i].key
            }
            else{
                document.getElementById(this.state.dateArray[i].day).classList.remove('meeting-day-active')
                if(this.state.prevTime!==''){
                    document.getElementById(this.state.prevTime).classList.remove('meeting-timing-active')
                }
            }
        }
        this.setState({bookingDate:date})
        this.filterTiming(key,date)
    }

    filterTiming(key,currentDate){
        let currentBookingDetail=[]
        
        let allBookingData = this.state.bookingDetailList
        for(var i=0; i<allBookingData.length;i++){
            if(key === allBookingData[i].day){
                currentBookingDetail = allBookingData[i]
                break 
            }
        }
        
        let bookedSlot = [{morning:[],afternoon:[],evening:[]}]

        if(allBookingData.length>0){

        let morning =[]
        let afternoon = []
        let evening =[]

        for(var i =0;i<allBookingData.length;i++){
            if(allBookingData[i].date===currentDate){
                if(allBookingData[i].bookingSlot.morning!==""){
                    morning.push(allBookingData[i].bookingSlot.morning)
                }
                else if(allBookingData[i].bookingSlot.afternoon!==""){
                    afternoon.push(allBookingData[i].bookingSlot.afternoon)
                }
                else if(allBookingData[i].bookingSlot.evening!==""){
                    evening.push(allBookingData[i].bookingSlot.evening)
                }
            }
        }

    
        filterAvailableTime('morning',morning,currentBookingDetail.booking.morning)
        filterAvailableTime('afternoon',afternoon,currentBookingDetail.booking.afternoon)
        filterAvailableTime('evening',evening,currentBookingDetail.booking.evening)
        
        function filterAvailableTime(day,dayArray,currentDayArray){
            let tempTimeArray =[]
            for(var i=0;i<dayArray.length;i++){
                for(var j=0;j<currentDayArray.length;j++){
                    if(dayArray[i]===currentDayArray[j]){
                        tempTimeArray.push(currentDayArray[j])
                    }
                }    
            }
            if(day==='morning'){
                bookedSlot[0].morning.push(tempTimeArray)
            }
            else if(day === 'afternoon'){
                bookedSlot[0].afternoon.push(tempTimeArray)
            }
            else if(day === 'evening'){
                bookedSlot[0].evening.push(tempTimeArray)
            }
        }
    }

    this.setState({currentBookingDetail:currentBookingDetail})
    this.availableBookingTime(currentBookingDetail,bookedSlot)
    }

    availableBookingTime(currentBookingDetail,bookedSlot){
    
    let morningId=['7:00 AM','7:30 AM','8:00 AM','8:30 AM','9:00 AM','9:30 AM','10:00 AM','10:30 AM','11:00 AM','11:30 AM']
    let afternoonId=['12:00 PM','12:30 PM','1:00 PM','1:30 PM','2:00 PM','2:30 PM','3:00 PM','3:30 PM']
    let eveningId=['4:00 PM','4:30 PM','5:00 PM','5:30 PM','6:00 PM','6:30 PM','7:00 PM','7:30 PM','8:00 PM','8:30 PM']

    let morning = currentBookingDetail.booking.morning
    let afternoon = currentBookingDetail.booking.afternoon
    let evening = currentBookingDetail.booking.evening

    filterAvailableTime(morning,morningId)
    filterAvailableTime(afternoon,afternoonId)
    filterAvailableTime(evening,eveningId)

    function filterAvailableTime(dayArray,arrayID){
        if(dayArray.length>0){
        for(var j=0; j<arrayID.length;j++){
            for(var i=0; i<dayArray.length; i++){
                if(dayArray[i]===arrayID[j]){
                    document.getElementById(arrayID[j]).style.textDecorationLine='none';
                    document.getElementById(arrayID[j]).disabled = false;
                    break
                }
                else{
                document.getElementById(arrayID[j]).style.textDecorationLine='line-through';
                document.getElementById(arrayID[j]).disabled = true;
                }
            }
        }
    }
    else{
        for(var i=0; i<arrayID.length; i++){
            document.getElementById(arrayID[i]).style.textDecorationLine='line-through';
            document.getElementById(arrayID[i]).disabled = true
        }
    }
    }

    let morningBookedTime = bookedSlot[0].morning
    let afternoonBookedTime = bookedSlot[0].afternoon
    let eveningBookedTime = bookedSlot[0].evening

    if(morningBookedTime.length>0){
        filterBookedTime(morningBookedTime[0])
    }
    if(afternoonBookedTime.length>0){
        filterBookedTime(afternoonBookedTime[0])
    }
    if(eveningBookedTime.length>0){
        filterBookedTime(eveningBookedTime[0])
    }

    function filterBookedTime(dayArray){
        for(var i=0; i<dayArray.length; i++){
            document.getElementById(dayArray[i]).style.textDecorationLine='line-through';
            document.getElementById(dayArray[i]).disabled = true;
        }
    }

    }

    handleMeetingTime = (day,time)=>{
        let meetingSlot = {
            date:this.state.bookingDate,
            timing:time,
        }
        
        if(this.state.bookingDetailList.length>0){
            if(this.state.prevTime!==''){
                document.getElementById(this.state.prevTime).classList.remove('meeting-timing-active')
            }
            document.getElementById(time).classList.add('meeting-timing-active')
        }

        this.setState({meetingSlot:meetingSlot,prevTime:time})
    }

    saveMeeting(){
        let that = this.state

        let openAppointment = {
            date: that.appointmentDate,
            time:that.timing + " " + that.timePeriod,
            duration:that.hour + " hr" + that.minute +" min",
            timeZone:that.timeZone
        }
        
        let data={
            title:that.title,
            appointmentType:that.appointmentType,
            description:that.description,
            consultantName:that.consultantName,
            meetingSlot:that.meetingSlot,
            meetingType:that.meetingType,
            location:that.location,
            selectedClientOption:that.selectedClientOption,
            selectedTeamOption:that.selectedTeamOption,
            otherMemberList:that.otherMemberList,
            openAppointmentFlag:that.openAppointment,
            openAppointment:openAppointment,
            status:'submitted'
        }
        axios({
            url:'https://q07uwo7htc.execute-api.us-east-1.amazonaws.com/dev/businus/updateAppointment/'+this.state.id,
            method:'POST',
            data:data,
          }).then((response)=>{
             if(response.status){
                    alert('Your data is saved')
                    window.location.replace('/appointment-consultant/view-appointment')
             }
          })
    }

    render(){
        const {selectedClientOption,selectedTeamOption}=this.state;
        return(
            <div className="Div">
                <div className="row breadcrumb-row">
                    <div className="col-md-10">
                    <BreadCrumbs />
                </div>
                </div>
            <div className="row">
                <div className="col-md-11 col-lg-11 col-sm-12">
                    <div className="appointment-card">
                    <div className="form-group">
                          <label className="control-label col-md-4">
                            Title
                          </label>
                          <div className="col-md-8">
                            <input
                              id="ember301"
                              onChange={this.handleOnChange}
                              name="title"
                              value={this.state.title}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                    </div>

                    <div className="form-group">
                        <label className="control-label col-md-4">Appointment Type</label>
                        <div className="col-md-8">
                        <select
                              name="appointmentType"
                              onChange={event=>this.handleOnChange(event)}
                              value={this.state.appointmentType}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            >
                               {this.state.typeMasterList.map((app, i) => (
                            <option>{app.title}</option> ))}
                            </select>
                        </div>
                    </div>

                        <div className="form-group">
                          <label className="control-label col-md-4">
                            Appointment Description
                          </label>
                          <div className="col-md-8">
                            <input
                              id="ember301"
                              onChange={this.handleOnChange}
                              name="description"
                              value={this.state.description}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="form-group">
                        <label className="control-label col-md-4">Consultant Name</label>
                        <div className="col-md-8">
                        <select
                            //   name="consultantName"
                              onChange={event =>this.handleMeetingChange(event)}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            >
                            <option value={null}> Select Option </option>
                            <option value='Open Appointment'> Open Appointment </option>
                               {this.state.consultantList.map((con, i) => (
                            <option value={con.id}>{con.personalDetail.personalDetail.name}</option> ))}
                            </select>
                        </div>
                        </div>

                        {this.state.openAppointmentFlag?
                        <div>
                        <div className="row">
                        <label className="control-label col-md-4" style={{paddingLeft:'50px'}}>When</label>
                        <div className="col-md-8">
                        <div className="col-md-4"> <input type='date' className="form-control ember-text-field ember-view" value={this.state.appointmentDate} name='appointmentDate' onChange={this.handleOnChange} /> </div>
                        
                        <div className="col-md-4">
                        <select
                          name='timing'
                          onChange={event=>this.handleOnChange(event)}
                          className="form-control ember-text-field ember-view"
                          type="text"
                        >
                           {this.state.timeArray.map((time, i) => (
                        <option value={time}>{time}</option> ))}
                        </select> </div>

                        <div className="col-md-4">
                            <select name='timePeriod'
                            value={this.state.timePeriod}
                            onChange={event=>this.handleOnChange(event)}
                            className="form-control ember-text-field ember-view">
                                <option value='AM'> AM </option>
                                <option value='PM'> PM </option>
                            </select>
                        </div>
                        </div>
                        </div>

                        <div className="row" style={{padding:'15px 0px'}}>
                        <label className="control-label col-md-4" style={{paddingLeft:'50px'}}>Duration</label>
                        
                        
                        <div className="col-md-8">
                        <div className="col-md-4">
                        <select
                          name='hour'
                          onChange={event=>this.handleOnChange(event)}
                          className="form-control ember-text-field ember-view"
                          type="text"
                        >
                           {this.state.hourArray.map((hour, i) => (
                        <option value={hour}>{hour}</option> ))}
                        </select> </div>
                        <div className="control-label col-md-1"> Hr </div>

                        <div className="col-md-4">
                        <select
                          name='minute'
                          onChange={event=>this.handleOnChange(event)}
                          className="form-control ember-text-field ember-view"
                          type="text"
                        >
                           {this.state.minuteArray.map((minute, i) => (
                        <option value={minute}>{minute}</option> ))}
                        </select></div>
                        <div className="col-md-2 control-label"> Min </div>

                    </div></div>
                    
                    <div className="row">
                    <label className="control-label col-md-4" style={{paddingLeft:'50px'}}> TIme Zone</label>
                        <div className="col-md-8">
                        <select 
                            name="timeZone"
                            onChange={event=>this.handleOnChange(event)}
                            className="form-control ember-text-field ember-view"
                            type="text"
                        >
                            <option>Select the Time Zone</option>
                            <option value="Etc/GMT+12">(GMT-12:00) International Date Line West</option>
                            <option value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</option>
                            <option value="Pacific/Honolulu">(GMT-10:00) Hawaii</option>
                            <option value="US/Alaska">(GMT-09:00) Alaska</option>
                            <option value="America/Los_Angeles">(GMT-08:00) Pacific Time (US & Canada)</option>
                            <option value="America/Tijuana">(GMT-08:00) Tijuana, Baja California</option>
                            <option value="US/Arizona">(GMT-07:00) Arizona</option>
                            <option value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
                            <option value="US/Mountain">(GMT-07:00) Mountain Time (US & Canada)</option>
                            <option value="America/Managua">(GMT-06:00) Central America</option>
                            <option value="US/Central">(GMT-06:00) Central Time (US & Canada)</option>
                            <option value="America/Mexico_City">(GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
                            <option value="Canada/Saskatchewan">(GMT-06:00) Saskatchewan</option>
                            <option value="America/Bogota">(GMT-05:00) Bogota, Lima, Quito, Rio Branco</option>
                            <option value="US/Eastern">(GMT-05:00) Eastern Time (US & Canada)</option>
                            <option value="US/East-Indiana">(GMT-05:00) Indiana (East)</option>
                            <option value="Canada/Atlantic">(GMT-04:00) Atlantic Time (Canada)</option>
                            <option value="America/Caracas">(GMT-04:00) Caracas, La Paz</option>
                            <option value="America/Manaus">(GMT-04:00) Manaus</option>
                            <option value="America/Santiago">(GMT-04:00) Santiago</option>
                            <option value="Canada/Newfoundland">(GMT-03:30) Newfoundland</option>
                            <option value="America/Sao_Paulo">(GMT-03:00) Brasilia</option>
                            <option value="America/Argentina/Buenos_Aires">(GMT-03:00) Buenos Aires, Georgetown</option>
                            <option value="America/Godthab">(GMT-03:00) Greenland</option>
                            <option value="America/Montevideo">(GMT-03:00) Montevideo</option>
                            <option value="America/Noronha">(GMT-02:00) Mid-Atlantic</option>
                            <option value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Is.</option>
                            <option value="Atlantic/Azores">(GMT-01:00) Azores</option>
                            <option value="Africa/Casablanca">(GMT+00:00) Casablanca, Monrovia, Reykjavik</option>
                            <option value="Etc/Greenwich">(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London</option>
                            <option value="Europe/Amsterdam">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
                            <option value="Europe/Belgrade">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
                            <option value="Europe/Brussels">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                            <option value="Europe/Sarajevo">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
                            <option value="Africa/Lagos">(GMT+01:00) West Central Africa</option>
                            <option value="Asia/Amman">(GMT+02:00) Amman</option>
                            <option value="Europe/Athens">(GMT+02:00) Athens, Bucharest, Istanbul</option>
                            <option value="Asia/Beirut">(GMT+02:00) Beirut</option>
                            <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
                            <option value="Africa/Harare">(GMT+02:00) Harare, Pretoria</option>
                            <option value="Europe/Helsinki">(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</option>
                            <option value="Asia/Jerusalem">(GMT+02:00) Jerusalem</option>
                            <option value="Europe/Minsk">(GMT+02:00) Minsk</option>
                            <option value="Africa/Windhoek">(GMT+02:00) Windhoek</option>
                            <option value="Asia/Kuwait">(GMT+03:00) Kuwait, Riyadh, Baghdad</option>
                            <option value="Europe/Moscow">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
                            <option value="Africa/Nairobi">(GMT+03:00) Nairobi</option>
                            <option value="Asia/Tbilisi">(GMT+03:00) Tbilisi</option>
                            <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
                            <option value="Asia/Muscat">(GMT+04:00) Abu Dhabi, Muscat</option>
                            <option value="Asia/Baku">(GMT+04:00) Baku</option>
                            <option value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
                            <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
                            <option value="Asia/Yekaterinburg">(GMT+05:00) Yekaterinburg</option>
                            <option value="Asia/Karachi">(GMT+05:00) Islamabad, Karachi, Tashkent</option>
                            <option value="Asia/Calcutta">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                            <option value="Asia/Calcutta">(GMT+05:30) Sri Jayawardenapura</option>
                            <option value="Asia/Katmandu">(GMT+05:45) Kathmandu</option>
                            <option value="Asia/Almaty">(GMT+06:00) Almaty, Novosibirsk</option>
                            <option value="Asia/Dhaka">(GMT+06:00) Astana, Dhaka</option>
                            <option value="Asia/Rangoon">(GMT+06:30) Yangon (Rangoon)</option>
                            <option value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
                            <option value="Asia/Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
                            <option value="Asia/Hong_Kong">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                            <option value="Asia/Kuala_Lumpur">(GMT+08:00) Kuala Lumpur, Singapore</option>
                            <option value="Asia/Irkutsk">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
                            <option value="Australia/Perth">(GMT+08:00) Perth</option>
                            <option value="Asia/Taipei">(GMT+08:00) Taipei</option>
                            <option value="Asia/Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
                            <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
                            <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
                            <option value="Australia/Adelaide">(GMT+09:30) Adelaide</option>
                            <option value="Australia/Darwin">(GMT+09:30) Darwin</option>
                            <option value="Australia/Brisbane">(GMT+10:00) Brisbane</option>
                            <option value="Australia/Canberra">(GMT+10:00) Canberra, Melbourne, Sydney</option>
                            <option value="Australia/Hobart">(GMT+10:00) Hobart</option>
                            <option value="Pacific/Guam">(GMT+10:00) Guam, Port Moresby</option>
                            <option value="Asia/Vladivostok">(GMT+10:00) Vladivostok</option>
                            <option value="Asia/Magadan">(GMT+11:00) Magadan, Solomon Is., New Caledonia</option>
                            <option value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</option>
                            <option value="Pacific/Fiji">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
                            <option value="Pacific/Tongatapu">(GMT+13:00) Nuku'alofa</option>
                            </select>
                        </div>
                    </div>
                        </div>:

                    <div className="row">
                        <label className="control-label col-md-4" style={{paddingLeft:'50px'}}>Meeting Slots</label>
                        {this.state.bookingDetailList.length>0?
                        <div className="col-md-8">
                        <ul className="meeting-slot-days-c">
                            {this.state.dateArray.map((slot)=>(
                                <li className="meeting-slot-d"> <button id={slot.day} className="meeting-booking-day" onClick={()=>this.dateSelected(slot.day)}> {slot.day} </button>  </li>
                            ))}
                        </ul>

                <div className="timing-slot-container">
                <div>
                <h4>Morning</h4>
                <div className='meeting-outer-time'>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('morning','7:00 AM')} id='7:00 AM' className='meeting-timing'>7:00 AM</button></div>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('morning','7:30 AM')} id='7:30 AM' className='meeting-timing'>7:30 AM</button></div>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('morning','8:00 AM')} id='8:00 AM' className='meeting-timing'>8:00 AM</button></div>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('morning','8:30 AM')} id='8:30 AM' className='meeting-timing'>8:30 AM</button></div>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('morning','9:00 AM')} id='9:00 AM' className='meeting-timing'>9:00 AM</button></div>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('morning','9:30 AM')} id='9:30 AM' className='meeting-timing'>9:30 AM</button></div>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('morning','10:00 AM')} id='10:00 AM' className='meeting-timing'>10:00 AM</button></div>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('morning','10:30 AM')} id='10:30 AM' className='meeting-timing'>10:30 AM</button></div>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('morning','11:00 AM')} id='11:00 AM' className='meeting-timing'>11:00 AM</button></div>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('morning','11:30 AM')} id='11:30 AM' className='meeting-timing'>11:30 AM</button></div>
                </div>
            </div>

            <div>
                <h4> Afternoon </h4>
                <div className='meeting-outer-time'>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('afternoon','12:00 PM')} id='12:00 PM' className='meeting-timing'>12:00 PM</button></div>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('afternoon','12:30 PM')} id='12:30 PM' className='meeting-timing'>12:30 PM</button></div>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('afternoon','1:00 PM')} id='1:00 PM' className='meeting-timing'>1:00 PM</button></div>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('afternoon','1:30 PM')} id='1:30 PM' className='meeting-timing'>1:30 PM</button></div>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('afternoon','2:00 PM')} id='2:00 PM' className='meeting-timing'>2:00 PM</button></div>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('afternoon','2:30 PM')} id='2:30 PM' className='meeting-timing'>2:30 PM</button></div>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('afternoon','3:00 PM')} id='3:00 PM' className='meeting-timing'>3:00 PM</button></div>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('afternoon','3:30 PM')} id='3:30 PM' className='meeting-timing'>3:30 PM</button></div>
                </div>
            </div>

            <div>
                <h4> Evening </h4>
                <div className='meeting-outer-time'>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('evening','4:00 PM')} id='4:00 PM' className='meeting-timing'>4:00 PM</button></div>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('evening','4:30 PM')} id='4:30 PM' className='meeting-timing'>4:30 PM</button></div>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('evening','5:00 PM')} id='5:00 PM' className='meeting-timing'>5:00 PM</button></div>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('evening','5:30 PM')} id='5:30 PM' className='meeting-timing'>5:30 PM</button></div>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('evening','6:00 PM')} id='6:00 PM' className='meeting-timing'>6:00 PM</button></div>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('evening','6:30 PM')} id='6:30 PM' className='meeting-timing'>6:30 PM</button></div>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('evening','7:00 PM')} id='7:00 PM' className='meeting-timing'>7:00 PM</button></div>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('evening','7:30 PM')} id='7:30 PM' className='meeting-timing'>7:30 PM</button></div>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('evening','8:00 PM')} id='8:00 PM' className='meeting-timing'>8:00 PM</button></div>
                    <div className='meeting-inner-time'><button onClick={()=>this.handleMeetingTime('evening','8:30 PM')} id='8:30 PM' className='meeting-timing'>8:30 PM</button></div>
                </div>
            </div></div>
                    </div>
                        :<div className="col-md-8">
                            <div className="col-md-4"> <input type='date' valuue={this.state.bookingDate} name='bookingDate' onChange={this.handleOnChange} /> </div>
                            <div className="col-md-4">
                            <select
                              name='timing'
                              value={this.state.timing}
                              onChange={event=>this.handleMeetingTImeChange(event)}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            >
                               {this.state.timeArray.map((time, i) => (
                            <option>{time}</option> ))}
                            </select> </div>
                        </div>}
                        
                    </div>}

                    <div className="form-group">
                        <label className="control-label col-md-4"> Meeting Type </label>
                        <div className="col-md-8">
                        <select
                            name="meetingType"
                            onChange={this.handleMeetingType}
                            className="form-control ember-text-field ember-view"
                            type="text"
                        >
                               {this.state.meetingTypeList.map((mType) => (
                            <option value={mType} >{mType}</option> ))}
                            </select>
                        </div>
                    </div>

                    <div className="form-group">
                          <label className="control-label col-md-4">
                            Location
                          </label>
                          <div className="col-md-8">
                            <input
                              id="ember301"
                              onChange={this.handleOnChange}
                              name="location"
                              value={this.state.location}
                              className="form-control ember-text-field ember-view"
                              type="text"
                              placeholder={this.state.locationPlaceHolder}
                            />
                          </div>
                    </div>

                    <div className="form-group">
                          <label className="control-label col-md-4">
                            Client Members
                          </label>
                          <div className="col-md-8">
                              {this.state.clientList.length>0?
                                <Select
                                    value={selectedClientOption}
                                    onChange={this.handleClientChange}
                                    options={this.state.clientList}
                                    isMulti={true}
                                />  
                              :''}
                            
                          </div>
                    </div>

                    <div className="form-group">
                          <label className="control-label col-md-4">
                            Team Members
                          </label>
                          <div className="col-md-8">
                              {console.log('render client',selectedTeamOption)}
                              {this.state.teamList.length>0?
                                <Select
                                    value={selectedTeamOption}
                                    onChange={this.handleTeamChange}
                                    options={this.state.teamList}
                                    isMulti={true}
                                />  
                              :''}
                            
                          </div>
                    </div>

                    <div className="form-group">
                          <label className="control-label col-md-4"> Other Members</label>
                          <div className="col-md-6">
                              <input 
                                id='ember301'
                                name='email'
                                value={this.state.email}
                                onChange={(e)=>this.handleOnChange(e)}
                                className="form-control ember-text-field ember-view"
                                type='text'
                                />
                          </div>
                          <button className="appointment-other-button col-md-2" onClick={()=>this.addOtherMembersToArray(this.state.email)}> Add Member </button>
                    </div>

                    <div className="form-group">
                          <div className="col-md-4"></div>
                          <div className="col-md-8 appointment-other-box">
                          {this.state.otherMemberList.length>0?
                                this.state.otherMemberList.map((other)=>(
                                    <div>
                                    <div className="col-md-2 appointment-other-chip">{other.email} </div>                                   
                                    <div className="col-md-1 appointment-other-closeChip" onClick={()=>this.handleOtherMembersDelete(other.key)}>&times;</div>
                                    </div>
                                ))
                            :""}
                          </div>
                    </div>                         
                    <button className="button1" style={{float:'right'}} onClick={()=>this.saveMeeting()}> Update Meeting </button>

                    </div>
                </div>
            </div>
            </div>
        )
    }
}