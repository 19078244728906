import $ from "jquery";
import React, { Component } from "react";
import { Route } from 'react-router-dom';
import "../../../style/panel.css";
import Dashboard from './Dashboard';
import EditAccessControl from "./editAccessControl";
import EditAccessControlEmployee from "./editAccessControlEmployee";
import SidebarClient from './SidebarAccessControl';
import ViewAccessControl from "./viewAccessControl";
import ViewAccessControlEmployee from "./viewAccessControlEmployee";
















const data = [];
export default class AccessControlMainapp extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []
     
    };
    
  }
  componentDidMount(){
    this.handleLine();
    //this.setState({title:this.props.match.params.productType});
  }
  handleLine(){
    
   
    const tab1= this.refs.tab1;
    const sidebar1= this.refs.sidebar1;
    const section= this.refs.section;
   
    
    $(function(){
      $("#tab1").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
        $("#section").toggle();
      });
      });
     
  }
  
  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
      <div className="mainDiv" >
       
       <div className="row">
         <div className="col-md-2">
         <SidebarClient title={this.state.title}/>
         </div>
         <div className="col-md-10">
         
      
<div className="section">
<Route  exact path = "/access-control/employee/view-controls" component = {ViewAccessControlEmployee} />
<Route  exact path = "/access-control/employee/edit-controls/:id" component = {EditAccessControlEmployee} />
<Route  exact path = "/access-control/client/view-controls" component = {ViewAccessControl} />
{/* <Route  exact path = "/access-control/client/-controls/:id" component = {PageAccessControl} /> */}
<Route  exact path = "/access-control" component = {Dashboard} />
{/* <Route exact path="/client-mail/:id" component={dedicatedMailPage} /> */}
<Route exact path="/access-control/client/edit-controls/:id" component={EditAccessControl} />



</div>

         </div>
       </div>
      
     </div>

    );
  }
}
