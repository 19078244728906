import React from 'react';
import axios from 'axios';
import ReactTable from 'react-table';
import AppointmentConsultantSidebar from '../AppointmentConsultantSidear';
import BreadCrumbs from '../../BreadCrumbs';
import { Link } from 'react-router-dom';

export default class ListConsultant extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            resdata:[],
        }
    }

    componentDidMount(){
        this.fetchAllConsultant()
    }

    fetchAllConsultant(){
    
        return axios({       
            url: 'https://q07uwo7htc.execute-api.us-east-1.amazonaws.com/dev/businus/consltant/list',
            method: 'GET',
            crossDomain: true,
            responseType: 'json', 
          }).then((response) => {
          this.setState({resdata:response.data})
         });
    }

    deleteCategory(id){
        if (window.confirm("Are you sure you want to delete this user ?")) {
            axios({
              url: 'https://q07uwo7htc.execute-api.us-east-1.amazonaws.com/dev/businus/getConsultantById/' + id,
              method: 'Delete',
            }).then((response) => {
            if(response.status===200){
                alert("User Deleted");
                window.location.reload()
            }
            });
      
          }
    }
    
    editGuideChapter(id){
      window.location.replace('/appointment-consultant/edit-consultant/'+id)
    }

    render(){
        const columns = [
          {
            Header: <div className="form-textHead cell-head">Consultant Id</div>,
            accessor: 'consultantId',
            filterMethod: (filter, row) => {   return row.catagory.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:120
          },
           {
            Header:<div className="form-textHead cell-head"> Name</div>,
            accessor: d => {return {personalDetail:d.personalDetail.personalDetail.name,id:d.id}},
            id:'personalDetail',
            Cell: props => <Link to={'/appointment-consultant/view-consultant/'+props.value.id} >{props.value.personalDetail}</Link>,
            minWidth:220
          },
          {
            Header:<div className="form-textHead cell-head"> Experience</div>,
            accessor: 'personalDetail.personalDetail.experience',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:220
          },
          {
            Header:<div className="form-textHead cell-head"> Practise</div>,
            accessor: 'personalDetail.personalDetail.practise',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:220
          },
          {
            Header:<div className="form-textHead cell-head"> Location</div>,
            accessor: 'personalDetail.personalDetail.location',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:220
          },
          {
            Header:<div className="form-textHead cell-head"> Language</div>,
            accessor: 'personalDetail.personalDetail.language',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:220
          },
          {
            Header:<div className="form-textHead cell-head"> Created By</div>,
            accessor: 'createdBy',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:220
          },
          {
            Header: props => <span className="form-textHead cell-head"> Date</span>,
            accessor: 'date',
            filterMethod: (filter, row) =>
                     {   return row.source.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => 
            new Date(props.value).toDateString(),
            minWidth:180
          },
          {
            Header: props => <span className="form-textHead cell-head"> Time</span>,
            accessor: 'date',
            filterMethod: (filter, row) =>
                     {   return row.source.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => 
            new Date(props.value).toLocaleTimeString(),
            minWidth:180
          }
        ]
        return(
          <div>
            <div className="row">
              <div>
            <div className = "container-fluid overflow-auto" style={{margin: "10px"}}>
            <div className="row breadcrumb-row">
          <div className="col-md-10" style={{marginTop:'10px'}}><BreadCrumbs/></div></div>
        <div className="container-fluid " style={{}}>
          <ReactTable data={this.state.resdata} columns={columns} style={{height:'85vh',overflow:'scroll'}} filterable/>
                

        </div>
        </div>
        </div>
      </div>
        </div>
        )
    }
}
