import React, { Component } from "react";
import "../../style/panel.css";
import { Link } from "react-router-dom";
import WealthBlogSidebar from "./WealthBlogSidebar";

export default class WealthBlogDashboard extends Component{
    render(){
        return(
            <div>
                <div className="row">
                    <div className='row-md-2 col-lg-2'> <WealthBlogSidebar/>  </div>
                <div className="col-md-10 col-lg-10">
                <div className="mainScreenKhub">
                <div className="container">
                    <div className="row">
                    <div className="col-md-6">
      <div className="tab-row1">
      <h2 className="tab-row-head">Stories & Insights Blog Category</h2>
      <div className="row">

      <div className="col-md-4">
     <div className="tab text-center">
     <Link to="/knowledgeHub/add/wealth-blogCategory">
     <img className="tab-image1" id="tab1" 
                  style={{ marginLeft: "-3px" }}
                  src={require("../../images/add-category-icon.png")}
                />
        <h2 className="tab-text-main"> Add Blog Category </h2></Link>
     </div>
     </div> 
    
     <div className="col-md-4">
     <div className="tab text-center">
     <Link to="/knowledgeHub/view/wealth-blogCategory">
     <img className="tab-image1" id="tab1" 
                  style={{ marginLeft: "-3px" }}
                  src={require("../../images/view-blog-category.png")}
                />
        <h2 className="tab-text-main"> View Blog Category </h2></Link>
     </div>
     </div> 
        
         </div>


         </div>

         </div>

         <div className="col-md-6">
         <div className="tab-row1">
     <h2 className="tab-row-head">Stories & Insights Blog Content</h2>
       <div className="row">


        <div className="col-md-4">
        
         <div className="tab text-center">
         <Link to="/knowledgeHub/add/wealth-article">  
     <img className="tab-image2" id="tab1"
                  style={{ marginLeft: "-3px" }}
                  src={require("../../images/add-content-icon.png")}
                />
           <h2 className="tab-text-main">Add Content</h2></Link>
     </div>
     </div> 
         
      <div className="col-md-4">
         <div className="tab text-center">
         <Link to="/knowledgeHub/view/wealth-article">    
     <img className="tab-image1" id="tab1"
                  style={{ marginLeft: "-3px" }}
                  src={require("../../images/view-content-icon.png")}
                />
           <h2 className="tab-text-main">View Content</h2></Link>
     </div>
         </div> 

         </div>


       </div>
     </div>
                    </div>
{/* 
        <div className='row'>
            <div className='col-md-6 col-lg-6'>
                <div className='tab-row1'>
                    <div className='tab-row-head'>Guides</div>
                </div>

                <div className='row'>
                    <div className='col-md-4 col-lg-4'>
                    <div className="tab text-center">
         <Link to="/knowledgeHub/businus-school/wealth/add-guides">    
     <img className="tab-image1" id="tab1"
                  style={{ marginLeft: "-3px" }}
                  src={require("../../images/N6.png")}
                />
           <h2 className="tab-text-main">Add Guides Name</h2></Link>
     </div>
                    </div>

                    <div className="col-md-4">
         <div className="tab text-center">
         <Link to="/knowledgeHub/businus-school/wealth/view-guides">   
     <img className="tab-image3" id="tab1"
                  style={{ marginLeft: "-3px" }}
                  src={require("../../images/N7.png")}
                />
           <h2 className="tab-text-main">View Guides Name</h2></Link>
     </div>
         </div> 

                </div>
            </div>

            <div className="col-md-6 col-lg-6">
                <div className="tab-row1">
                    <div className="tab-row-head"> Banner </div>
                </div>
                <div className="row">
                <div className='col-md-4 col-lg-4'>
                    <div className="tab text-center">
                    <Link to="/knowledgeHub/businus-school/wealth/addBanner">    
                <img className="tab-image1" id="tab1"
                            style={{ marginLeft: "-3px" }}
                            src={require("../../images/N6.png")}
                            />
                    <h2 className="tab-text-main">Add Banner</h2></Link>
                </div>
                                </div>
                </div>
            </div>
     </div> */}
                    
                </div>
                </div></div>
                </div>
            </div>
        )
    }
}