import React, { Component } from "react";
import "../style/panel.css";
import { Link,Redirect } from "react-router-dom";
import $ from "jquery";



import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import Header from "./header";

import Login from "./Login/login";



export default class AuthScreen extends Component {

  constructor(props) {
    super(props);
   
    this.state = {
 
      isLogged:false

     
    };

    this.loggedIn = this.loggedIn.bind(this);
    
  }

  componentDidMount(){
 // for a single session
      if(localStorage.getItem("isLoggedIn")=="YES"){

        this.setState({isLogged:true});

      }
      else{
        this.setState(
          {isLogged:false}
        );
      }
     


  } 
  //flag for session

    loggedIn(){

      this.setState({isLogged:true});

    }
  

  render() {
    return (
  
        

this.state.isLogged ?     <div>
   <Header/>
   
   

</div> : !window.location.href.includes("userForms") ? 
<Login loggedIn={this.loggedIn}/>
  :''
    );
  }
}
