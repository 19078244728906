import React, { useEffect, useState } from "react";
import "./pagedoc.css";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import BreadCrumbs from "../BreadCrumbs";
import $ from "jquery";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

// import ReactTableUI from "./ReactTableUI";
import View from "./smartDocComponents/view";
import axios from "axios";
import ReactExport from "react-export-excel";

import _ from "underscore";
import { useParams } from "react-router-dom";
import Build from "./smartDocComponents/build";
import Respond from "./smartDocComponents/respond";
import Submission from "./smartDocComponents/submission";
// import AssignmentUserCommunication from "../Common/AssignmentUserCommunication";
const KeyCodes = {
  comma: 188,
  enter: 13,
};

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
function PageDoc() {
  let { id } = useParams();
  const [section, setSection] = useState("A");
  const [smartDoc, setSmartDoc] = useState(null);
  const [smartDocVersionURL, setSmartDocVersionURL] = useState(null);
  const handleChangeSection = (event) => {
    const section = event.target.getAttribute("data-section");
    setSection(section);
  };

  useEffect(() => {
    const getSmartDocs = async () => {
      const { data } = await axios
        .get(` https://vdz44wruzh.execute-api.us-east-1.amazonaws.com/live/businusquery/getSmartDocById/${id}`)
        .catch((err) => console.log("ERR: " + err));
      setSmartDoc(data);
      if (data.submission && data.submission.length == 0) {
        setSmartDocVersionURL(" ");
      }
      if (data.submission && data.submission.length != 0) {
        setSmartDocVersionURL(
          data.submission[data.submission.length - 1].docLink
        );

      }
      else{
        setSmartDocVersionURL(" ");
      }
    };
    getSmartDocs();
  }, []);

  return (
    <>
      <div className="Div">
        <div className="row breadcrumb1-row" style={{ marginLeft: "2px" }}>
          <div className="col-md-10">
            <BreadCrumbs />
          </div>
          <div className="col-md-2">
            <ExcelFile
              element={
                <button className="download-button-wrapper">
                  Download Data in Excel format
                </button>
              }
            >
              <ExcelSheet
                //  data={this.state.excelData}
                name="WorkflowList"
              >
                {/* {this.state.columns != undefined
                ? this.state.columns.map((d) => (
                    <ExcelColumn label={d.displayName} value={d.id} />
                  ))
                : ""} */}
              </ExcelSheet>

              {/* {this.state.workFlowList.map((d) => ( */}
              <ExcelSheet
              //    data={d.workflow} name={d.name}
              >
                {/* {d.workflowColumns != undefined
                  ? d.workflowColumns.map((dd) => (
                      <ExcelColumn label={dd.name} value={dd.id} />
                    ))
                  : ""} */}
              </ExcelSheet>
              {/* ))} */}
              <ExcelSheet
                // data={this.state.attachments}
                name="Attachments"
              >
                {/* {colforAttach.map((d) => (
                <ExcelColumn label={d.label} value={d.id} />
              ))} */}
              </ExcelSheet>
              <ExcelSheet
                // data={this.state.documents}
                name="Documents"
              >
                {/* {colforDoc.map((d) => (
                <ExcelColumn label={d.label} value={d.id} />
              ))} */}
              </ExcelSheet>
              <ExcelSheet
                // data={this.state.checklists}
                name="Checklists"
              >
                {/* {colforChecklists.map((d) => (
                <ExcelColumn label={d.label} value={d.id} />
              ))} */}
              </ExcelSheet>
              <ExcelSheet
                // data={this.state.urls}
                name="Urls"
              >
                {/* {colforUrls.map((d) => (
                <ExcelColumn label={d.label} value={d.id} />
              ))} */}
              </ExcelSheet>
              <ExcelSheet
                // data={this.state.comments}
                name="Comments"
              >
                {/* {colforComments.map((d) => (
                <ExcelColumn label={d.label} value={d.id} />
              ))} */}
              </ExcelSheet>
            </ExcelFile>
          </div>
        </div>
        <div className="row">
          <div
            className="dedicated-page"
            style={{ padding: "0px", overflowY: "auto" }}
          >
            <div className="row-resource-head">
              <h2 className="resource-head">
                SmartDoc id: {smartDoc && smartDoc.smartDocId}
                {/* {smartDoc && JSON.stringify(smartDoc.sections)} */}
              </h2>
            </div>
            <div className="">
              <div
                style={{
                  height: "100%",
                  minHeight: "430px",
                }}
                className="row header-divi "
              >
                <div className="col-md-10">
                  {/* {submissions && JSON.stringify(submissions)} */}
                  <div className="form-group">
                    <label className="control-label col-md-3">
                      SmartDoc Id:
                    </label>
                    <div className="col-md-7">
                      {smartDoc && (
                        <input
                          name="workflowId"
                          disabled="disabled"
                          value={smartDoc.smartDocId}
                          className="form-control ember-text-field ember-view"
                          type="text"
                        />
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label col-md-3">Smar Name:</label>
                    <div className="col-md-7">
                      {smartDoc && (
                        <input
                          name="workflowName"
                          //   onChange={this.handleOnChange}
                          //   disabled={this.state.isEdit ? "" : "disabled"}
                          value={smartDoc.docName}
                          //   onBlur={this.sendMail}
                          className="form-control ember-text-field ember-view"
                          type="text"
                        />
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="control-label col-md-3">
                      SmartDoc Overview:
                    </label>
                    <div className="col-md-7">
                      {smartDoc && (
                        <input
                          name="workflowOverview"
                          // onChange={this.handleOnChange}
                          // onBlur={this.sendMail}
                          // disabled={this.state.isEdit ? "" : "disabled"}
                          value={smartDoc.docOverview}
                          className="form-control ember-text-field ember-view"
                          type="text"
                        />
                      )}
                    </div>
                  </div>
                  <span>
                    <div className="form-group">
                      <label className="control-label col-md-3">Client:</label>
                      <div className="col-md-7">
                        {smartDoc && (
                          <input
                            name="workflowOverview"
                            value={smartDoc.selectedClient}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          />
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label col-md-3">
                        Shared To (Client Contacts)
                      </label>
                      <div className="col-md-7">
                        {smartDoc && (
                          <Select
                            isMulti
                            name="clients"
                            value={smartDoc.sharedToClient}
                          />
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label col-md-3">
                        Shared To (Team):
                      </label>
                      <div className="col-md-7">
                        {smartDoc && (
                          <Select
                            isMulti
                            name="clients"
                            value={smartDoc.sharedToTeams}
                          />
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label col-md-3">Tags</label>
                      <div className="col-md-7">
                        {smartDoc && (
                          <CreatableSelect isMulti value={smartDoc.tags} />
                        )}
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div style={{display: 'flex'}} className="d-flex w-100 justify-content-evenly mt-4">
              <div
                data-section="A"
                onClick={handleChangeSection}
                className={
                  (section == "A" ? "section-active " : " ") +
                  "btn-lg sections respond mx-1"
                }
              >
                Build
              </div>
              <div
                data-section="B"
                onClick={handleChangeSection}
                className={
                  (section == "B" ? "section-active " : " ") +
                  "btn-lg sections respond mx-1"
                }
              >
                Respond
              </div>
              <div
                data-section="C"
                onClick={handleChangeSection}
                className={
                  (section == "C" ? "section-active  " : " ") +
                  "btn-lg sections view mx-1"
                }
              >
                View
              </div>
              <div
                data-section="D"
                onClick={handleChangeSection}
                className={
                  (section == "D" ? "section-active  " : " ") +
                  "btn-lg sections view mx-1"
                }
              >
                Submission
              </div>
            </div>
            {smartDoc && (
              <>
                {section === "A" ? (
                  <Build
                    attributes={smartDoc.attributes}
                    sections={smartDoc.sections}
                    smartDocId={id}
                    smartDocFunction={setSmartDoc}
                  />
                ) : (
                  ""
                )}
                {section === "B" ? (
                  <Respond
                    sections={smartDoc.sections}
                    fileURL={smartDoc.fileURL}
                    smartDocId={id}
                    smartDocFunction={setSmartDoc}
                    changeSection={setSection}
                    setSmartDocVersionURL={setSmartDocVersionURL}
                  />
                ) : (
                  ""
                )}

                {section === "D" ? (
                  <Submission
                    submissions={smartDoc.submission}
                    smartDocId={id}
                    smartDocName={smartDoc.docName}
                    setSection={setSection}
                    setSmartDocVersionURL={setSmartDocVersionURL}
                  />
                ) : (
                  ""
                )}
                {smartDocVersionURL && section === "C" ? (
                  <View sdURL={smartDocVersionURL} />
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PageDoc;
