import React from 'react';
import 'react-quill/dist/quill.snow.css'; // ES6
import axios from 'axios';
import FileBase64 from 'react-file-base64';
import EditorJs from 'react-editor-js';
import {EditorjsTool} from '../Editor/tool'
import {v1 as uuidv1} from 'uuid';
import Image from "@editorjs/image";
import GuideBlogSidebar from './GuideBlogSidebar';

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export default class AddGuideBlog extends React.Component {
  constructor(props) {
    super(props)

    var allText = 'Hi write something';
    this.state = {
      heading:'',
      subHeading:'',
      authorName:'',
      authorDescription:'',
      datePosted:'',
      dateModified:'',
      readTime:'',
      source:'',
      sourceUrl:'',
      content:'',
      blogImageCredit:'',
      tags:'',
      text: allText,
      inpWidth:'100%',
      headings : "",
      videoUrl:'',
      blogType:'',
      flag:false,
      imageArray:[],
      blogImageURL:'',
      more:[],
      truncateContent:'',
      chapterArray:[],
      authorImage:"",
      Chapter:'',
      learningType:""
     }
    this.handleChange = this.handleChange.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    this.handleTags = this.handleTags.bind(this);
    this.save_to_dynamo = this.save_to_dynamo.bind(this);
    this.onChange = this.onChange.bind(this)
    this.finalChanges = this.finalChanges.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  componentDidMount(){
    let url = window.location.href.split('/')
    let index = url.length-2
    this.setState({learningType:url[index]})
    this.fetchAllChapter(url[index])
}

fetchAllChapter(learningType){
    let id=localStorage.getItem('guideBlogFilterId')
    let temp_data=[]
    return axios({       
        url: 'https://q7ay5k66xi.execute-api.us-east-1.amazonaws.com/dev/businus/guideChapter/list/'+id,                
        method: 'GET',
        crossDomain: true,
        responseType: 'json',
      }).then((response) => {
        for(var i=0;i<response.data.length;i++){
          if(response.data[i].guideId===id && response.data[i].learningType===learningType){
              temp_data.push(response.data[i])
          }
        }
        this.setState({chapterArray:temp_data,chapter:temp_data[0].id})  
     });  
}

  async finalChanges(){
    const content = await this.editorInstance.save()
    this.filterParagraph(content)
    if(this.state.imageArray.length>1){
      this.setState({modalIsOpen:true})
    }
    else{
      this.save_to_dynamo()
    }
    this.setState({blogImageURL:this.state.imageArray[0]})
  }

  closeModal(){
    this.setState({modalIsOpen:false,})
  }

  onChange(e) {
    let value = e.target.value
    let name = e.target.name

    this.setState({[name]:value,flag:true});
  }

  getAuthorFiles(files){
    this.setState({selectedFile: files[0]})
    this.fileUploadHandler(this,'authorImage')
  }


  fileUploadHandler = (that, docType) => {
    const id = uuidv1()
    const imageURL='https://businus-files-live.s3.amazonaws.com/image/'+id+'.png';
    that.setState({ loading: true });
    axios.post("https://tj1cb28koh.execute-api.us-east-1.amazonaws.com/dev/businus/uploadEditorImage",
      {
        "image": that.state.selectedFile.base64,
        "imageName": that.state.selectedFile.name,
        "id":id,
        "imageURL":imageURL
      })
      .then(function (response) {
        if (response.status === 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: imageURL });

        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleChanges(e){
    let target = e.target;
    let name = target.name;
  
    this.setState({[name]:target.value});
  }

  handleTags(e){
    let target = e.target
    var tag = target.value.split(',')
    this.setState({tags: tag})
  }

  handleChange(value) {
    this.setState({ text: value })
  }

  uploadByFile(file) {

    let fd = new FormData();
    fd.append('file',file);
  }

  addImage(imageURL){
    this.setState({imageArray:this.state.imageArray.push(imageURL)})
  }

selectImage(image){
  this.setState({blogImageURL:image})
}

handleOnChangeMore=(e,i)=>{
    var more = this.state.more

    more[i][e.target.name] = e.target.value

    this.setState({more:more})
}

addMore(){
    var newMore ={
        source:'',
        url:'',
        name:""
    }

    let moreArray = this.state.more
    moreArray.push(newMore)
    this.setState({more:moreArray})
}

filterParagraph(jsonContent){
  let content=""
  let imageArray=this.state.imageArray
  let block = jsonContent.blocks
  for(var i=0;i<block.length;i++){
    switch(block[i].type){
      case "paragraph":
        content =  block[i].data.text 
        break;
      case "image":
        imageArray.push(block[i].data.file.url)
    }
  }
  this.setState({truncateContent:content,imageArray:imageArray})
}

validation(content){
  let flag=true

  if(this.state.heading.trim()===''){
    flag=false
    alert("Please add the heading")
  }
  else if(content.blocks.length===0){
    flag=false
    alert("Please add the content")
  }
  else if(this.state.chapter===""){
    flag=false
    alert("Please select the chapter")
  }

  return(flag)
}

  async save_to_dynamo(){

    const content = await this.editorInstance.save()


    let validData=this.validation(content)

    var blg = {
      blogImageURL: this.state.blogImageURL,
      authorImageURL: this.state.authorImage,
      heading: this.state.heading,
      subHeading: this.state.subHeading,
      authorName: this.state.authorName,
      authorDescription: this.state.authorDescription,
      readTime: this.state.readTime,
      source: this.state.source,
      sourceUrl:this.state.sourceUrl,
      content: content,
      blogImageCredit: this.state.blogImageCredit,
      chapter:this.state.chapter,
      tags: this.state.tags,
      more:this.state.more,
      blogType:this.state.blogType,
      seo:this.state.seo,
      slug:this.state.slug,
      metaDescription:this.state.metaDescription,
      truncateContent:this.state.truncateContent,
      guideId:localStorage.getItem('guideBlogFilterId'),
      learningType:this.state.learningType
    };
    
    if(validData){
      console.log("blog :",blg);
      axios({
        url: 'https://q7ay5k66xi.execute-api.us-east-1.amazonaws.com/dev/businus/guideBlog/add',
        method: 'POST',
        data: blg,
      }).then((response) => {
        if(response.status===200){
          alert("Blog Added");
          window.location.reload()
        }
     });
    }
    
    
  }
 

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-2"><GuideBlogSidebar learningType={this.state.learningType}/></div>
        <div className="col-md-10">
      <div className = "container-fluid overflow-auto" style={{margin: "10px", height:'90vh',overflow:'scroll'}}>
        <div style={{margin: "10px"}}><h3>Add Blog : </h3></div>

          <div className="container-fluid well well-sm" >

          <div>
          <spam style={{paddingLeft:'27%'}}> Original </spam>
          <input type="radio" value="orginal" name='blogType' onChange={this.onChange}/>
          <spam style={{paddingLeft:'20%'}}> Curated </spam>
          <input type="radio" value="curated" name='blogType' onChange={this.onChange}/>
        </div>
        {this.state.flag===true?
        <div>

          <div class="input-group" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Title" style={{width:"20%"}}>Title</span>
            <input type="text" class="form-control" id="heading" placeholder="" name="heading" value={this.state.heading} style={{}} onChange={this.handleChanges}></input>
          </div><br/>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Title" style={{width:"20%"}}>Subtitle</span>
            <input type="text" class="form-control" name="subHeading" value={this.state.subHeading} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>

        <div class="input-group" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Content" style={{width:"20%"}}>Content</span>
            
            <div style={{border:"1px solid #ededed",marginTop:'2%',marginRight:'3%'}}>
              <div style={{height:'40%',paddingBottom:"0"}}>
                
                <EditorJs 
                tools={{
                  image:  {
                  class: Image,
                  inlineToolbar:true,
                  config: {
                   uploader:{
                   uploadByFile: async function(file){
                      console.log(file);
                      const id = uuidv1()
                      const imageURL='https://businus-files-live.s3.amazonaws.com/image/'+id+'.png';
                      
                     let fileBase = await toBase64(file);
                     let result = await axios.post(
                      "https://tj1cb28koh.execute-api.us-east-1.amazonaws.com/dev/businus/uploadEditorImage",
               {
                   image:fileBase,
                   imageName:fileBase.name,
                   id:id,
                   imageURL:imageURL
               })

               return{
                success:1,
                file:{
                  url:imageURL
                }
                 }
                 
                      
                    },
                    
                   }
                  }
                },...EditorjsTool}} 
                instanceRef={instance=>this.editorInstance=instance}
                ></EditorJs>
                </div>
              </div>
            </div><br/>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Content Source"span style={{width:"20%"}}>Content Source</span>
            <input type="text" class="form-control" name="source" value={this.state.source} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Content Source"span style={{width:"20%"}}> Source Url</span>
            <input type="text" class="form-control" name="sourceUrl" value={this.state.sourceUrl} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Chapter" style={{width:"20%"}}>Chapter</span>
            <select class="form-control" name="chapter" onChange={this.handleChanges}>
            {this.state.chapterArray.map((cat)=>(
            <option value={cat.id}>{cat.chapter}</option>
            ))}
              
            </select>
          </div><br/> 

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Tags" style={{width:"20%"}}>Tags</span>
            <input type="text" class="form-control" name="tags" value={this.state.tags} onChange={this.handleTags} placeholder="break tags with ," style={{width:this.state.inpWidth}} ></input>
          </div><br/>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Read Time" style={{width:"20%"}}>Read Time</span>
            <input type="number" class="form-control" name="readTime" value={this.state.readTime} placeholder="minutes" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>

          {this.state.blogType==="orginal"?
          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Author" style={{width:"20%"}}>Author</span>
            <input type="text" class="form-control" name="authorName" value={this.state.authorName} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div>:''}<br/>

          {this.state.blogType==="orginal"?
          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Author Description" style={{width:"20%"}}>Author Description</span>
            <input type="text" class="form-control" name="authorDescription" value={this.state.authorDescription} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div>:''}<br/>
{/* 
          {this.state.blogType==="orginal"?
          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Article Image Credit" style={{width:"20%"}}>Article Image Credit</span>
            <input type="text" class="form-control" name="blogImageCredit" value={this.state.blogImageCredit} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div>:''}<br/> */}


          <div className='well well-sm'>
          <h5> Add SEO </h5>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="SEO"span style={{width:"20%"}}>SEO</span>
            <input type="text" class="form-control" name="seo" value={this.state.seo} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Slug"span style={{width:"20%"}}>Slug</span>
            <input type="text" class="form-control" name="slug" value={this.state.slug} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>

          <div class="input-group mb-3" style={{width:"80%"}}>
            <span type="text" class="input-group-addon" id="" placeholder="Meta Description"span style={{width:"20%"}}>Meta Description</span>
            <input type="text" class="form-control" name="metaDescription" value={this.state.metaDescription} placeholder="" style={{width:this.state.inpWidth}} onChange={this.handleChanges}></input>
          </div><br/>

          </div>

          <div class="well well-sm " id='more'>
            <h5>Add More :- </h5>
            
            {this.state.more.length>0?
            this.state.more.map((more,index)=>(
            <div className='col-md-12 col-lg-12'>
            <div className="form-group">
                    <label className="control-label col-md-4">Name</label>
                    <div className="col-md-6">
                 <input type="text" className="form-control ember-text-field ember-view" name="name" value={this.state.more[index].name} onChange={(e)=>this.handleOnChangeMore(e,index)}  placeholder='Enter Name'></input>  
            </div>
                </div> 

            <div className="form-group">
                    <label className="control-label col-md-4">URL</label>
                    <div className="col-md-6">
                 <input type="text" className="form-control ember-text-field ember-view" name="url" value={this.state.more[index].url} onChange={(e)=>this.handleOnChangeMore(e,index)}  placeholder='Enter URL'></input>  
            </div>
                </div>

            <div className="form-group">
                    <label className="control-label col-md-4">Source</label>
                    <div className="col-md-6">
                 <input type="text" className="form-control ember-text-field ember-view" name="source"  value={this.state.more[index].source} onChange={(e)=>this.handleOnChangeMore(e,index)}  placeholder='Enter Source'></input>  
            </div>
            </div> 
            </div>
            )):""}
            
            <button type='button' class="btn btn-default" style={{margin:'10px'}} id = '' onClick={()=>this.addMore()}>Add Url</button>

          </div>

        <div style={{overflow: "auto"}}>
          
        {this.state.blogType==="orginal"?
            <div class="col-md-4" style={{width: "30%"}}>
              <div class="thumbnail">
                {this.state.authorImage!==""?
                <img src={this.state.authorImage} alt="authorImagePreview" id="authorImagePreview" style={{width: "100%",}}/>
                :""}
                <div class="caption">
                  <span class=""> <p>Author Image</p>
                  <FileBase64 multiple={true} onDone={this.getAuthorFiles.bind(this)}/>
                  </span>
                </div>
              </div>
            </div>:''}

     
          </div>         

          <div ClassName="col-md-4" style={{width : "80%", position:"relative", height:"90px"}}>
            <button type='button' class="btn btn-primary bottomright" style={{margin:'10px'}} id='save' onClick={this.finalChanges}>Add Blog</button>
          </div>
          
          
          
        </div>:""}
        </div>

        </div>
        </div>
      </div>
      </div>
    )
  }
}

