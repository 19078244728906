import axios from 'axios';
import React from 'react';
import GuideBlogSidebar from './GuideBlogSidebar';

export default class EditGuideChapter extends React.Component{
    constructor(props){
        super(props)
        this.state={
            chapter:'',
            id:'',
            learningType:""
        }
        this.handleChanges = this.handleChanges.bind(this)
    }

    componentDidMount(){
        let url = window.location.href.split('/')
        let index = url.length
        let id = url[index-1]
        this.setState({id:id,learningType:url[index-3]})

        this.fetchChapterById(id)
    }

    fetchChapterById(id){
          return axios({       
              url: 'https://q7ay5k66xi.execute-api.us-east-1.amazonaws.com/dev/businus/getGuideChapterById/'+id,
              method: 'GET',
              crossDomain: true,
              responseType: 'json', 
            }).then((response) => {
            this.setState({chapter:response.data.chapter})
           });
    }

    handleChanges(e){
        let target = e.target;
        let name = target.name;
      
        this.setState({[name]:target.value});
    }

    finalChanges(){
        if(this.state.chapter.trim()===""){
            alert("Please enter the chapter name")
        }
       let data ={
        chapter:this.state.chapter,
       }

       
        if(this.state.chapter.trim()!==""){
            axios({
                url: 'https://q7ay5k66xi.execute-api.us-east-1.amazonaws.com/dev/businus/updateGuideChapter/'+this.state.id, 
                method: 'POST',
                data:data,
              }).then((response) => {
                if(response.status===200){
                  alert("Blog Chapter Added");
                  window.location.replace('/knowledgeHub/businus-school/'+this.state.learningType+'view-guides-chapter')
                }
             });
        }
    }




    render(){
        return(
            <div>
                <div className="row">
                    <div className='col-md-2 col-lg-2'><GuideBlogSidebar learningType={this.state.learningType}/></div>
                <div className="col-md-10 col-lg-10">
            <div className="container-fluid overflow-auto" style={{margin: "10px", height:'90vh',}}>
                <div style={{margin: "10px"}}><h3>Add Chapter : </h3></div>

                <div className="container-fluid well well-sm" >
                <div class="input-group" style={{width:"80%"}}>
                    <span type="text" class="input-group-addon" id="" placeholder="Chapter" style={{width:"20%"}}>Chapter</span>
                    <input type="text" class="form-control" id="chapter" placeholder="Enter Chapter" name="chapter" value={this.state.chapter} onChange={this.handleChanges}></input>
                </div><br/>
                
    
            </div>
                <div ClassName="col-md-4" style={{width : "80%", position:"relative", height:"50px"}}>
                    <button type='button' class="btn btn-primary bottomright" style={{margin:'10px'}} id='save' onClick={()=>this.finalChanges()}>Add Chapter</button>
                </div>
            </div></div>
        </div>
      </div>
        )
    }
}