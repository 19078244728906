import React, { Component } from "react";

import { Link } from "react-router-dom";
import BreadCrumbs from "../../BreadCrumbs";
import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { ChatFeed, Message } from 'react-chat-ui'
import Select from 'react-select';
import SubmitButton from '../../common/SubmitButton';
import ResourcesUserCommunication from '../../common/ResourcesUserCommunication';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from 'underscore';
let context = null;
const data = [];
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               :'0px ',
    position               :'Absolute',
    zIndex                 :'30000'     ,
    backgroundColor         :'none',  
  }
};
 
export default class addAssignmentTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resources:{attachment:[],url:[],comment:[],checklist:[],doc:[]},
      assignmentTitle:data.assignmentTitle,
      startDate:data.startDate,
      endDate:data.endDate,
      assignmentOverview:data.assignmentOverview,
      priority:data.priority,
      clients:[],
      checklists:[],
      progressStatusList:[],
      sliderState:1,
      visibility:data.visibility,
     reminderState:1,
     clients: [],
     organisations: [],
     teamLeads: [],
     teams: [],
     progressStatusList:[],
     
     currentTemplate: "",
     templates: [],
     sliderState:"1",
     reminderState:"1",
     teamMembers: [],
     isSubmitting:false,
     occurenceArray:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,"Never"]

    };
    context = this;
    this.openModal = this.openModal.bind(this);
   
    this.closeModal = this.closeModal.bind(this);
  }

  changeSliderState(orderNumber){

    this.setState({sliderState:orderNumber});

}

fetchStatus(that,status){

  axios.get(apiBaseUrl1 +"businus/status/list/progress")
  .then(function (response) {
    console.log(response.data);

    response.data.unshift({progressStatus:'Select Status'});
      that.setState({progressStatusList:response.data});
    
    
  })
  .catch(function (error) {
    console.log(error);
  });

}
addNewCheckList(){

  var entry = this.state;
  
  if(entry.checklists!=undefined){
    entry.checklists.push({
        name:"",
        isDone:false,
        description:""

    });
  }
  
  else{
    entry['checklists'] = [];
    entry.checklists.push({
      name:"",
      isDone:false,
      description:""
    });
  }


  this.setState({entry});


}
onChange = (e) => {
  debugger;
    var value = e.target.value;
  
    var formName = e.target.name;

      this.setState({[formName]:value});



    console.log(this.state);
  }
  //function for changing the status button on click to open or close
  handleOnChange = (e) => {
  debugger;
    var value = e.target.value;
  
    var formName = e.target.name;

      this.setState({[formName]:value});



    console.log(this.state);
  }
  



  saveResourceToList=(resources)=>{



    context.setState({resources:resources});
  



  }
  
  openModal(type) {
    this.changeDocType(type);
    this.setState({modalIsOpen: true});
  }
 
  
 
  closeModal() {
    this.setState({modalIsOpen: false});
  }
 

  componentDidMount() {
    const id = this.props.match.params.id;

    this.fetchQueryById(this,id);
    this.fetchEmployees(this,id);
  
    
  
  }


  fetchEmployees(that, source) {

    axios.get(apiBaseUrl2+"businus/employee/list/"+localStorage.spId)
      .then(function (response) {
        debugger;
        console.log(response.data);
        var allEmployees = response.data;

        var teamLeads = allEmployees;
        var teamMembers = allEmployees;

        var teamMembersMultiSelectData = []

        _.each(teamMembers, function (member) {


          teamMembersMultiSelectData.push({
            value:member.id,
              label: member.employeeId +" "+ member.firstName +" "+ member.lastName,
            name: member.employeeId +" "+ member.firstName +" "+  member.lastName,
            id: member.id,
            email:member.email
          })





        });

        teamLeads.unshift({employeeId:"",firstName:"Select Team Lead"});
        that.setState({ teamMembers: teamMembersMultiSelectData, teamLeads: teamLeads });
      })
      .catch(function (error) {
        console.log(error);
      });

  }


  


  fetchEmployees(that, source) {

    axios.get(apiBaseUrl2+"businus/employee/list/"+localStorage.spId)
      .then(function (response) {
        debugger;
        console.log(response.data);
        var allEmployees = response.data;

        var teamLeads = allEmployees;
        var teamMembers = _.filter(allEmployees, function (emp) {
          return emp.title != 'Team Lead';
        });


        var teamMembersMultiSelectData = []

        _.each(teamMembers, function (member) {


          teamMembersMultiSelectData.push({
            value:member.id,
              label: member.employeeId +" "+ member.firstName +" "+ member.lastName,
            name: member.employeeId +" "+ member.firstName +" "+  member.lastName,
            id: member.id,
            email:member.email
          })





        });

        teamLeads.unshift({employeeId:"",firstName:"Select Team Lead"});
        that.setState({ teamMembers: teamMembersMultiSelectData, teamLeads: teamLeads });
      })
      .catch(function (error) {
        console.log(error);
      });

  }


  //fetching the data by id which this file recieved from the list

  

  
  

  onSelect = selectedOption => {
    this.setState(
      { team:selectedOption },
      () => console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  onRemove(optionList, removedItem) {
    context.setState({ team: optionList });
  }
  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile,
        "isMultiple":true,
        "imageName": that.state.selectedFile,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile[0].name });
          var images = [];
          _.each(that.state.selectedFile,function(file){

              images.push("https://businus-files-live.s3.amazonaws.com/mailAttachments/" + file.name);

          })
         
          that.setState({ [docType]: images});
          



          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  



  getFiles(files) {
    this.setState({ selectedFile: files });

  }

  getFormattedDate(millis){

    return new Date(millis).toDateString();


  }

  changeReminderState(orderNumber) {

    this.setState({ reminderState: orderNumber });
    

  }
  toggleOpen = status => {
    if (status == undefined || status == "open") {
      status = "close";
    } else {
      status = "open";
    }
    //(this.setState({ entry: [...this.state.entry,{status:status} ] });)
    var newEntry = this.state.entry;
    newEntry.status = status;
    this.setState({ entry: newEntry });
    context.updateStatus(context, this.state.entry.id, status);
  };
  
  changeDocType(type){

    this.setState({docType:type});
  }


  updateStatus =  (type,value) => {
    var checklists = context.state.checklists;



      if(checklists!=undefined){

        checklists.push({
          name:value,
          isDone:false
        })

      }
  else{
    checklists = [];
    checklists.push({
      name:value,
      isDone:false
    })
    }

      this.setState({checklists:checklists})


    
    
  }


  fetchQueryById(that, id) {
    axios
      .get(
        apiBaseUrl1 +"businusquery/getTemplateById/" + id
      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  sendMail() {
     var data = context.state;
     var teamLead = _.findWhere(context.state.teamLeads,{ employeeId: data.teamLead});

    if(teamLead){
      teamLead = {id:teamLead.id,name:teamLead.firstName+teamLead.lastName};
    }
    else{
      alert("Team Lead can't be blank");
      return;
    }
     context.setState({isSubmitting:true})
    axios
      .post(
        apiBaseUrl1 +"businus/template/add",
        {
          
          
            "title":data.title,
            "name":data.name,
            "description":data.description,
            templateId:data.templateId,
            "type":"Assignment",
            "checklists":data.checklists,
            resources:data.resources,
            update:"NA",
            document:data.document,
            "by":localStorage.userName,  
          
         
          "selectedTemplate": data.selectedTemplate,
           teamLead: teamLead != undefined ? teamLead : "NA",
          team: data.team,
          
        
          "priority": data.priority,
          "recurringFrequency":data.recurringFrequency || "NA",
          "recurringNo":data.recurringNo || "NA",
          "plainWords":data.plainWords,
          "type":"Assignment",
          "reminderType":data.reminderState===1 ? "typeDueDate" : data.reminderState===2 ?  "typeSpecificDate" : data.reminderState===3 ? 'typeStatus' :'',
          "remindingFrequency":data.remindingFrequency != undefined ? data.remindingFrequency: "NA",
          "remindingDate":data.remindingDate != undefined ? data.remindingDate: "NA",
          "reminderStatus":data.reminderStatus,
          "visibleToClient":data.visibleToClient,
          spId:localStorage.spId

         
        })
      .then(function(response) {
        debugger;
      alert("Success!");
    //  context.fetchThreadById(context,query.id);
    context.setState({isSubmitting:'done'})
      })
      .catch(function(error) {
        console.log(error);
        context.setState({isSubmitting:'done'})
      });
  }

  setMailPageUrl(){
  var url = window.location.href.replace(window.location.origin,"");
  localStorage.setItem("MAILURL",url);


  }




  //updating the status button
  
  render() {
    const {sliderState} = this.state;
    const active = " active-button"
    const deactive = "";
    const { reminderState } = this.state;
    const {resources} = this.state;
    return (
      <div className="Div">
   <div className="row breadcrumb-row">
            <div className="col-md-10">
              <BreadCrumbs />
            </div>
            </div>
  {/* showing all the details of a single item from the table at the backend*/}
  <div className="row">
  <div className="dedicated-page" style={{padding:'0px'}}>
    <div className="row-resource-head">
                  <h2 className="resource-head">Create Template</h2>
                  </div>
      <div className="row ">
        <div className="col-md-12 info-column account-col2">
          <div id="field">
         
            
            <div className="row">
                      <div className="col-md-9">

                        
                        <div className="form-group">
                          <label className="control-label col-md-3">
                            <span
                              id="ember295"
                              className="popovercontainer text-dashed-underline ember-view"
                              data-original-title
                              title
                            >
                             Template Name:
                      </span>{" "}
                          </label>
                          <div
                            className="inline-fields col-md-9"
                            style={{ padding: 0 }}
                          >

                            <div className="col-md-9">
                              <input

                                autofocus
                                id="ember299"
                                onChange={this.handleOnChange}
                                name="name"
                                value={this.state.entry.name}
                                className="form-control ember-text-field ember-view"
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-3">
                            <span
                             
                            >
                              Assignment Name:
                      </span>{" "}
                          </label>
                          <div
                            className="inline-fields col-md-9"
                            style={{ padding: 0 }}
                          >

                            <div className="col-md-9">
                              <input

                                autofocus
                                id="ember299"
                                onChange={this.handleOnChange}
                                name="title"
                                value={this.state.title}
                                className="form-control ember-text-field ember-view"
                                type="text"
                              />
                            </div>
                          </div>
                        </div>


                        <div className="form-group">
                          <label className="control-label col-md-3">

                            Assignment Overview:
                    </label>
                          <div className="col-md-8">
                            <textarea
                              id="ember301"
                              onChange={this.handleOnChange}
                              name="description"
                              value={this.state.description}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            ></textarea>
                          </div>
                        </div>
                        <div className="form-group" style={{marginTop:"50px"}}>
                        <label className="control-label col-md-3">Priority:</label>
                        <div className="col-md-7">
                          <select
                            name="priority"
                            onChange={this.handleOnChange}
                            value={this.state.priority}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >


<option value="Select">Select priority</option>
                            <option value="HIGH">High</option>
                            <option value="MEDIUM">Medium</option>
                            <option value="LOW">Low</option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
      <div className="form-group">
                        <label className="control-label col-md-3">Visible to Client</label>
                        <div className="col-md-7">
                          <select
                            name="visibleToClient"
                            onChange={this.handleOnChange}
                            value={this.state.visibleToClient}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >

<option value="Select">Select Option</option>
<option value="NOT_VISIBLE">Not Visible</option>
<option  disabled={this.state.selectedClientMode=="both"} value="VISIBLE">Visible</option>

                          </select>
                        </div>
                      </div>

      </div>

      <div className="form-group">
                        <label className="control-label col-md-3">Team Lead:</label>
                        <div className="col-md-7">
                          <select
                            name="teamLead"
                            onChange={this.handleOnChange}
                            value={this.state.teamLead}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >

                            {this.state.teamLeads.map(function (teamLead, i) {

                              return (<option value={teamLead.employeeId}>{teamLead.employeeId} {teamLead.firstName} {teamLead.lastName}</option>)
                            })}

                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="control-label col-md-3">Team:</label>
                        <div className="col-md-7">

                          {this.state.teamMembers.length > 0 ?
                             <Select
                             value={this.state.team}
                             onChange={this.onSelect}
                             options={this.state.teamMembers}
                             isMulti={true}
                           />
                            : ''}

                        </div>
                      </div>
                      
                        </div>
                        </div>
                      
                        <div className="row " style={{marginTop:'40px'}} > 
             
              
                
                      <div className={sliderState==3 ? "col-md-4 activeOptionsAss " : "col-md-4"} style={{backgroundColor:'rgb(46 182 144)'}}>
               <div className="options" onClick={()=> this.changeSliderState(3)}>Reminders</div>
                      </div>

                      <div className={sliderState==4 ? "col-md-4 activeOptionsAss " : "col-md-4"} style={{backgroundColor:'rgb(97 190 158)'}}>
               <div className="options" onClick={()=> this.changeSliderState(4)}>Recurrances</div>
                      </div>

                      <div className={sliderState==5 ? "col-md-4 activeOptionsAss " : "col-md-4"} style={{backgroundColor:'rgb(97 140 158)'}}>
               <div className="options" onClick={()=> this.changeSliderState(5)}>Resources</div>
                      </div>
                   
              
            
              
                      
               </div>

               {sliderState==1 ?
                          
                             <div className="row">
                <div className="col-md-12 offset-md-3">
                  <div className=" header" >Checklists </div>
                 
                 
                  <div className="row" style={{ backgroundColor: '#eaeaea', padding: '5px 30px 5px 30px', textAlign: 'right', fontSize: '17px', fontFamily: 'montserrat', fontWeight: '500' }}><div className="butn" onClick={() => this.openModal('Document')}><i className="fa fa-send" style={{ fontSize: '14px' }} aria-hidden="true"></i>Add Item</div></div>

<Modal
  isOpen={this.state.modalIsOpen}

  onRequestClose={this.closeModal}
  style={customStyles}
  contentLabel="Example Modal"
>
  <div className="cross-row text-right"><div style={{ marginRight: '-5px' }} onClick={this.closeModal}><i style={{ fontSize: '25px', fontWeight: '400' }} className="fa fa-close" aria-hidden="true"></i></div></div>
  <div className="header2">
    Add Checklist Item
</div>

  <div className="row content1-div  text-left">

    <input className="fieldbox1" onChange={this.onChange} type="text" value={this.state.title} name="title" placeholder="title" style={{ fontSize: '17px', fontFamily: 'montserrat,sans-serif' }} /><br />
  
    <div className="text-center"> <button className="btn btn-primary send-button" onClick={()=>this.updateStatus('checklists',this.state.title)} placeholder="Attachment">Add</button></div>



  </div>

</Modal>
                  <ul style={{padding:'20px'}} className="checkList">

                    {this.state.checklists!=undefined ? this.state.checklists.map((check, index) => (

                      <li className="checkList-item">
                        <label>
                        <input type="checkbox" defaultChecked={check.isDone} value={check.isDone}  onChange={(e)=>this.onCheckboxClick(e,index)}/><span className="check-title">{check.name}</span></label>
                      </li>
                    )) : ''}


                  </ul>
                </div>
              </div> : ''}

              {
   sliderState===5 ? 

        <ResourcesUserCommunication 
        attachment={resources.attachment}
        doc={resources.doc}
        comment={resources.comment}
        checklist={resources.checklist || []}
        url={resources.url}
        saveResourceToList={this.saveResourceToList}
        parentType="Template"
        parentId={0}
        parentName={0}
        />

        :  '' }
              
              {sliderState==2 ?
              <div>
               <div className=" header" >Mail</div>  
              <div className="form-group">
                          <label className="control-label col-md-4">

                            Document
                    </label>
                    <div className="row">
                          <div className="col-md-5">
                         
 <FileBase64
multiple={true}
onDone={this.getFiles.bind(this)}   /></div>

<div className="col-md-5">

<button className="btn btn-primary attachment" onClick={() => this.fileUploadHandler(this, "document")}
value="Select a File">
<i className="fa fa-paperclip" aria-hidden="true"></i>Attachment
</button>
                          </div>
                        </div></div></div>: ''}
                        { sliderState==4 ?
   <div> 
      <div className=" header" >Recurrances</div>  
      <div className="row">
   <div className="col-md-8 offset-md-3">
    

     <div className="form-group">
                        <label className="control-label col-md-3">Recurring Frequency:</label>
                        <div className="col-md-7">
                          <select
                            name="recurringFrequency"
                            onChange={this.handleOnChange}
                            value={this.state.recurringFrequency}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >


<option value="Select">Select frequency</option>
<option value="None">None</option>
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Yearly">Yearly</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="control-label col-md-3">End After:</label>
                        <div className="col-md-7">
                          <select
                            name="recurringNo"
                            onChange={this.handleOnChange}
                            value={this.state.recurringNo}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >


                     <option value="Select">Select frequency</option>
                     {this.state.occurenceArray.map(function (item, i) {

                      return (<option value={item}> {item}</option>)
                      })}

                          </select>
                        </div>
                      </div>    


                      <div className="form-group">
                        <label className="control-label col-md-3">
                          Plain Words:
                    </label>
                        <div className="col-md-7">
                          <input
                            name="plainWords"
                            onChange={this.handleOnChange}
                            value={this.state.plainWords}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          />
                        </div>
                      </div>            
    
   </div>
   
 </div>
 </div> 
  :""}
                        
{ sliderState==3 ?
   <div> 
      <div className=" header" >Reminder</div>  
      <div className="row "  >
  <div className={reminderState == 1 ? "col-md-4 active " : "col-md-4"} >
    <div className="reminder-options "  ><input type="radio" onClick={() => this.changeReminderState(1)} name="reminderType" value="typeDueDate"/>
<label style={{marginLeft:"10px"}}>Based on Due date</label></div>
  </div>
  <div className={reminderState == 2 ? "col-md-4 active " : "col-md-4"} >
    <div className="reminder-options" ><input type="radio"  name="reminderType" onClick={() => this.changeReminderState(2)} value="typeSpecificDate"/>
<label style={{marginLeft:"10px"}}>On a Specific date</label></div>
  </div>
  <div className={reminderState == 3 ? "col-md-4 active " : "col-md-4"} >
    <div className="reminder-options" ><input type="radio"  name="reminderType" onClick={() => this.changeReminderState(3)} value="typeStatus"/>
<label style={{marginLeft:"10px"}}>Based on Status</label></div>
  </div>
  
</div>

{ reminderState==1 ?
      <div className="row">
   <div className="col-md-8 offset-md-3">
    

     <div className="form-group">
                        <label className="control-label col-md-3">Reminder Frequency:</label>
                        <div className="col-md-7">
                          <select
                            name="remindingFrequency"
                            onChange={this.handleOnChange}
                            value={this.state.remindingFrequency}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >


<option value="Select">Select frequency</option>
<option value="None">None</option>
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Yearly">Yearly</option>
                          </select>
                        </div>
                      </div>
                     


                                
    
   </div>
   
 </div>
 :""}


{ reminderState==2 ?
      <div className="row">
   <div className="col-md-8 offset-md-3">
    

   
                      <div className="form-group">
                        <label className="control-label col-md-3">
                          Specific Date:
                    </label>
                        <div className="col-md-7">
                          <input
                            name="remindingDate"
                            onChange={this.handleOnChange}
                            value={this.state.remindingDate}
                            className="form-control ember-text-field ember-view"
                            type="date"
                          />
                        </div>
                      </div>


                    
   </div>
   
 </div>
 :""}
 { reminderState==3 ?
      <div className="row">
   <div className="col-md-8 offset-md-3">
    

   
    <div className="form-group">
      <label className="control-label col-md-3">
        Status:
  </label>
      <div className="col-md-7">
      <div className="head-button2">
 <select onChange={this.handleOnChange} className="processStatus" value={this.state.reminderStatus} name="reminderStatus">
 {this.state.progressStatusList.map((value)=>{

return <option >{value.progressStatus}</option>
})}
</select>
 </div>
      </div>
    </div>
    <div className="form-group">
      <label className="control-label col-md-3">Reminder Frequency:</label>
      <div className="col-md-7">
        <select
          name="remindingFrequency"
          onChange={this.handleOnChange}
          value={this.state.remindingFrequency}
          className="form-control ember-text-field ember-view"
          type="text"
        >


<option value="Select">Select frequency</option>
<option value="None">None</option>
          <option value="Daily">Daily</option>
          <option value="Weekly">Weekly</option>
          <option value="Monthly">Monthly</option>
          <option value="Yearly">Yearly</option>
        </select>
      </div>
    </div>


  
</div>
 </div>
 :""}
 </div> 
:""}
                        
                      </div>
                    </div>

           
           

            <div className="text-left" style={{marginTop:'80px'}}>
            <SubmitButton clickHandler={this.sendMail}
                   isSubmitting={this.state.isSubmitting} /> 
            </div>
            {/* this says that if editing state is on then the Function saveAccountDetails will be called */}
          </div>
        </div>
      
    </div>
 
</div>
      
     
    );
  }
}

