import React, { Component } from "react";
import "../../style/panel.css";
import { Link } from "react-router-dom";
import $ from "jquery";



const data = [];
export default class GSTSidebar extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []

    };

  }
  componentDidMount(){
    this.handleLine();
  }
  handleLine(){

      $(function(){
      $("#gst").click(function(){
        $("#caselaw2").toggle();
      });
      });
      $(function(){
        $("#caselaw2").click(function(){
          $("#caseadd2").toggle();
        });
        });
      $(function(){
      $("#gst").click(function(){
        $("#act2").toggle();
      });
      });
      $(function(){
        $("#act2").click(function(){
          $("#actadd2").toggle();
        });
        });
      $(function(){
      $("#gst").click(function(){
        $("#rule2").toggle();
      });
      });
      $(function(){
        $("#rule2").click(function(){
          $("#ruleadd2").toggle();
        });
        });
      $(function(){
      $("#gst").click(function(){
        $("#circular2").toggle();
      });
      });
      $(function(){
        $("#circular2").click(function(){
          $("#circularadd2").toggle();
        });
        });
     
  }

  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (

            <div className="sidebar">
              <div id="sidebar1">
                <div className="sidebar-elementhead"><Link className="sidebar-link" to="/knowledgeHub">Home</Link></div>
             
                <div className="oneTab">
                <div className="sidebar-element" id="c1"> <Link className="sidebar-link" to="/knowledgeHub/businus-school/legal"><i class="fa fa-bar-chart" />Dashboard<i className="fa fa-angle-right " /></Link></div>
                {/* <div className="sidebar-subelement" id="d1"><i class="fa fa-envelope" />View<i className="fa fa-angle-right " /></div> */}
                </div>
                <div className="oneTab">
                  <div className="sidebar-element" id="gst">
                    <i class="fa fa-envelope" />
                    GST
                    <i className="fa fa-angle-down " />
                  </div>
                  <h2 className="sidebar-subelement" id='caselaw2'>
                  <i class="fa fa-envelope" />
                      Case Law
                  <i className="fa fa-angle-down "/>
                  </h2>
                <div className="sidebar-subelement2" id="caseadd2">
                  <Link className="sidebar-link" to="/knowledgeHub/add-gst/category/case-law/">
                    <i class="fa fa-envelope" />
                    Add Category
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                  <Link className="sidebar-link" to="/knowledgeHub/view-gst/category/case-law">
                    <i class="fa fa-envelope" />
                    View Category
                    <i className="fa fa-angle-right " />
                  </Link><br/>

                  <Link className="sidebar-link" to="/knowledgeHub/add-gst/resources/case-law">
                    <i class="fa fa-envelope" />
                    Add Resources
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                  <Link className="sidebar-link" to="/knowledgeHub/view-gst/resources/case-law">
                    <i class="fa fa-envelope" />
                    View Resources
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                </div>

                  <h2 className="sidebar-subelement" id='act2'>
                  <i class="fa fa-envelope" />
                    Acts
                  <i className="fa fa-angle-down " />
                  </h2>
                  <div className="sidebar-subelement2" id="actadd2">
                  <Link className="sidebar-link" to="/knowledgeHub/add-gst/category/acts">
                    <i class="fa fa-envelope" />
                      Add Category
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                  <Link className="sidebar-link" to="/knowledgeHub/view-gst/category/acts">
                    <i class="fa fa-envelope" />
                      View Category
                    <i className="fa fa-angle-right " />
                  </Link>

                  <Link className="sidebar-link" to="/knowledgeHub/add-gst/resources/acts">
                    <i class="fa fa-envelope" />
                    Add Resources
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                  <Link className="sidebar-link" to="/knowledgeHub/view-gst/resources/acts">
                    <i class="fa fa-envelope" />
                    View Resources
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                </div>

                <h2 className="sidebar-subelement" id='rule2'>
                  <i class="fa fa-envelope" />
                    Rules
                  <i className="fa fa-angle-down " />
                  </h2>
                  <div className="sidebar-subelement2" id="ruleadd2">
                  <Link className="sidebar-link" to="/knowledgeHub/add-gst/category/rules">
                    <i class="fa fa-envelope" />
                      Add Category
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                  <Link className="sidebar-link" to="/knowledgeHub/view-gst/category/rules">
                    <i class="fa fa-envelope" />
                      View Category
                    <i className="fa fa-angle-right " />
                  </Link>

                  <Link className="sidebar-link" to="/knowledgeHub/add-gst/resources/rules">
                    <i class="fa fa-envelope" />
                    Add Resources
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                  <Link className="sidebar-link" to="/knowledgeHub/view-gst/resources/rules">
                    <i class="fa fa-envelope" />
                    View Resources
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                </div>

                <h2 className="sidebar-subelement" id='circular2'>
                  <i class="fa fa-envelope" />
                    Circular & Notification
                  <i className="fa fa-angle-down " />
                  </h2>
                  <div className="sidebar-subelement2" id="circularadd2">
                  <Link className="sidebar-link" to="/knowledgeHub/add-gst/category/circular-notification">
                    <i class="fa fa-envelope" />
                      Add Category
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                  <Link className="sidebar-link" to="/knowledgeHub/view-gst/category/circular-notification">
                    <i class="fa fa-envelope" />
                      View Category
                    <i className="fa fa-angle-right " />
                  </Link>

                  <Link className="sidebar-link" to="/knowledgeHub/add-gst/resources/circular-notification">
                    <i class="fa fa-envelope" />
                    Add Resources
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                  <Link className="sidebar-link" to="/knowledgeHub/view-gst/resources/circular-notification">
                    <i class="fa fa-envelope" />
                    View Resources
                    <i className="fa fa-angle-right " />
                  </Link><br/>
                </div>

                </div>

              </div>
            </div>

    );
  }
}
