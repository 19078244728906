import React, { Component } from "react";
import "../../style/panel.css";
import { Link } from "react-router-dom";
import $ from "jquery";


const data = [];
export default class Sidebar extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []
     
    };
    
  }
  componentDidMount(){
    this.handleLine();
  }
  handleLine(){

    $(function(){
      $("#tab1").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
        $("#section").toggle();
      });
      });
      $(function(){
        $("#c2").click(function(e){
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d2").toggle();
          $("#d10").toggle();
      });
      });
      $(function(){
        $("#c3").click(function(e){
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d3").toggle();
          $("#d10").toggle();
        });
        });
      $(function(){
        $("#c4").click(function(e){
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d4").toggle();
          $("#d10").toggle();
          });
          });
      $(function(){
        $("#c5").click(function(e){
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d5").toggle();
          $("#d10").toggle();
            });
            });
      $(function(){
        $("#c6").click(function(e){
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d6").toggle();
          $("#d10").toggle();
              });
              });
      $(function(){
        $("#c7").click(function(e){
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d7").toggle();
          $("#d10").toggle();
                });
                });
      $(function(){
          $("#c8").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d8").toggle();
                  });
                  });  
  
  }
  
  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
    
            <div className="sidebar">
              <div id="sidebar1">
              <div className="sidebar-head"><Link to={"/home"}>Home</Link></div>
              
                {/*<div className="sidebar-sectionName">
    Products</div>*/}
                <div className="oneTab">
                <div className="sidebar-elementhead"><Link className="sidebar-link" >Calendar</Link></div>
               
                <div className="sidebar-element" id="c1"> <Link to={"/calendar"}><i className="fa fa-bar-chart" />View Calendar<i className="fa fa-angle-right " /></Link></div>
                <div className="sidebar-element" id="c1"> <Link to={"/edit-calImages"}><i className="fa fa-bar-chart" />Edit Calendar Images<i className="fa fa-angle-right " /></Link></div>
                 
                </div>
             
                
               
              
               
               
               
              </div>
            </div>
         
    );
  }
}
