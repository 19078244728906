import _ from 'underscore';

export const getPrivilegeByName = function(name){


 // return {canView:true,canEdit:true,canAdd:true,canDelete:true};
  if(localStorage.userId=='68a21760-b1ab-11ec-9848-4b5758ef29c7#sp'){
    return {canView:true,canEdit:true,canAdd:true,canDelete:true};
  }


var privileges =  JSON.parse(localStorage.getItem('privileges'));
var legalModules=['Incorporate+','Protect+','Windup+','Tax+','Comply+','Corporate','Startup','Social','Business Registration','Business Protection','Web and Internet','Human Resources','Intellectual Property','Service Contract']
var masterModules=['Client','Employee','Organisation'];

var myPrivilege=_.findWhere(privileges,{module:name});
if(myPrivilege!=undefined){
  return myPrivilege
}
else if(name=='Legal'){

    var hasAccess = false;

  _.each(legalModules,function(d){
    myPrivilege=_.findWhere(privileges,{module:d});

    if(myPrivilege!=undefined && myPrivilege.canView){
      hasAccess = true
    }
    
    });
  
    return {canView:hasAccess};
  
  }
else if(name=='Master'){
  var hasAccess = false;

  _.each(masterModules,function(d){
   myPrivilege=_.findWhere(privileges,{module:d});
   if(myPrivilege!=undefined && myPrivilege.canView){
    hasAccess = true
    }
  
  });

  return {canView:hasAccess};

}
else{
return {canView:false,canEdit:true,canAdd:true,canDelete:true};
  }





}