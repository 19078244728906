import React, { Component } from 'react';
import _ from 'underscore';


let context = null;
export default class BreadCrumbs extends Component {
  
  
  constructor(props){
    
    super();
    this.state = {
    breadcrumbs : [],
    nonLinks:['Sent','View','Mail','Page','TEAM-MAILS','TEAM-DOCS','Recieved','Template','Document','Proposal','Update','Messages','Test','Docs','Homepage','Homepage','View-template','Edit-tasks','Send','view-checklist'],
    maxUrl:0,
resourceName:"",
      };

      context = this;
   
  
  
}



componentWillReceiveProps(nextProps){


  if(this.props!=nextProps){
    let currentUrl = window.location.href;
    let  currentUrlArray = currentUrl.split("/");
     let urlLength = currentUrlArray.length;
     let breads = [];
     console.log(currentUrlArray);
     _.each(currentUrlArray,function(data,key){

       breads.push({
           "name":data.charAt(0).toUpperCase()+data.slice(1),
           "url" : currentUrlArray.slice(0,key+1).join("/")


       });


     });

     console.log(breads);


     this.setState({breadcrumbs:breads,
     maxUrl:urlLength});
  }
  

}

componentDidMount(){
      let currentUrl = window.location.href;
     let  currentUrlArray = currentUrl.split("/");
      let urlLength = currentUrlArray.length;
      let breads = [];
      let breadcrumbForSubject = "";
      console.log(currentUrlArray);
      _.each(currentUrlArray,function(data,key){

        breads.push({
            "name":data.charAt(0).toUpperCase()+data.slice(1),
            "url" : currentUrlArray.slice(0,key+1).join("/")


        });

        breadcrumbForSubject = breadcrumbForSubject + data.charAt(0).toUpperCase()+data.slice(1) +" | ";

      });

      localStorage.setItem("breadCrumbs",breadcrumbForSubject);
      console.log(breads);


      this.setState({breadcrumbs:breads,
      maxUrl:urlLength});


    


}

  
render() {
  const {type} = this.props;
  const {resourceName} =this.state;
      return (
      <div className="breadcrumb breadCrumb-mobile col-md-12">
      <div>

       <a className="breadcrumb__step breadcrumb__step" href="/">Home</a>

   
       {
      type=="resource" ? this.state.breadcrumbs.map((data,index) =>
         
         (index > 2 && index < 6)?   <a className="breadcrumb__step" href={data.url}>{data.name}</a>

         : ''
       )
       
  :type=="workflow" ? this.state.breadcrumbs.map((data,index) =>
         
  (index >2 && index <= 6)?   <a className="breadcrumb__step" href={data.url}>{data.name}</a>

  :( index > 6) ?  <span className='breadcrumb__step'>{data.name}</span> : ''
)


      : this.state.breadcrumbs.map((data,index) =>
         
      (index > 2 )? 
      <>
     {this.state.nonLinks.includes(data.name) && typeof Number(data.name) === "number" ? <span className='breadcrumb__step'>{data.name}</span> : <a className="breadcrumb__step" href={data.url}>{data.name}</a>}
      </>
      
      : ''
    ) } 
        
        
      </div>  </div>

    );
  }
}