import React, { Component } from "react";
import "../../style/panel.css";
import { Link } from "react-router-dom";
import BreadCrumbs from '../BreadCrumbs';
import $ from "jquery";
import axios from 'axios';
import { apiBaseUrl1,apiBaseUrl5 } from '../apiUtils';
import { apiBaseUrl2 } from '../apiUtils';
import _ from 'underscore';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import UserCommunication from "../common/UserCommunication";
let context = null;
const data = [];
export default class pageContactus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      entry:{},
    };
    context =this;
  }


  componentDidMount(){
    const id = this.props.match.params.id;
 
   
    this.fetchQueryById(this,id);
  }


  toggleOpen = status => {
    if (status==undefined || status== "open") {
      status="close"
    }
   else{
     status="open"
   }
 //this.setState({ entry: [...this.state.entry,{status:status} ] });
 var newEntry=this.state.entry;
 newEntry.status=status;
 this.setState({entry:newEntry})
 context.updateStatus(context,this.state.entry.id,status);

  };
  fetchQueryById(that, id) {
    axios
      .get(
        apiBaseUrl5+"businusquery/querybyId/" +
          id
      )
      .then(function(response) {
        debugger;
        console.log(response.data);
        //that.setState({ entry: response.data });
        that.fetchRegisteredUsers(that,response.data);
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  
  fetchRegisteredUsers(that,query){

    axios.get(apiBaseUrl2+"businus/users")
    .then(function (response) {
      console.log(response.data);
      that.setState({users:response.data.Users});
    
       
      
       
        var isExists = _.filter(response.data.Users,function(usr){
          
            return usr. customerEmail === query.email;

        }).length;

       
        query['isRegistered'] = isExists > 0 ? true : false;


      

        that.setState({entry:query});


    })
    .catch(function (error) {
      console.log(error);
    });
  
  }

  updateStatus(that,id,status){

    axios.put(apiBaseUrl5+"businusquery/query/"+id,{status:status})
    .then(function (response) {
      console.log(response.data);
      
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }

  render() {
    return (
      <div className="Div">
      <div className="row breadcrumb-row">
          <div className="col-md-10"><BreadCrumbs/></div></div>
            
        <div className="row">
        <div className="dedicated-page2">
           
           <div className="row-resource-head">
                <h2 className="resource-head">Query by {this.state.entry.title} {this.state.entry.name} </h2>
                </div>
              <div className="row" style={{marginLeft:"30px"}}>
                <div className="col-md-5">
                  <h2 className="head-name">
                    {this.state.entry.title} {this.state.entry.name}
                  </h2>
                  <h2 className="head-mail">Email: {this.state.entry.email}</h2>
                  <h2 className="head-mail">
                    Company: {this.state.entry.company}
                  </h2>
                  <h2 className="head-mail">Mob: {this.state.entry.phone}</h2>
                </div>
                <div className="col-md-5">

                <h2 className="head-mail">Message:{this.state.entry.message} </h2>
                </div>
                <div className="col-md-2">
                  <div className="head-button">
                    {" "}
                    <button /*dyanamic classname*/
                    onClick={()=>this.toggleOpen(this.state.entry.status)}
                      className={
                        "badge " +
                        (this.state.entry.status == "close" ? "red" : "")
                      }
                    
                      id="status"
                    >
                      {this.state.entry.status == undefined
                        ? "open"
                        : this.state.entry.status}
                    </button>
                  </div>
               
              

              </div>

           
            </div>
            {this.state.id!="" ? 
         <UserCommunication 
         entry={this.state.entry}
         id={this.state.id}
       /> : ""}
          </div>
         
        </div>
       
      </div>
    );
  }
}