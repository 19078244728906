import React, { Component } from "react";

import { Link } from "react-router-dom";
import axios from "axios";
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import { ToastsStore } from 'react-toasts';

import $ from "jquery";

import ReactTableUI from './ReactTableUI';

import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { ChatFeed, Message } from 'react-chat-ui'
import { getPrivilegeByName } from '../../privilegeUtils';
import AddComment from './AddComment.js';
import PageWorkflowComponent from './PageWorkflowComponent';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from 'underscore';
import PageCheckList from '../Resources/pageChecklist';
export default class pageChecklistWrapper extends Component {
  

 


  //updating the status button

  render() {
   

    return (
            <PageCheckList type="component"  id={this.props.match.params.id} />
    );
  }
}

