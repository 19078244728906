import React, { Component } from "react";
import Select from 'react-select';
import _ from 'underscore';
import axios from 'axios';
import { apiBaseUrl2 } from "../apiUtils";
let context = [];
export default class ShareComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactPersons:[],
  
    };

   
  }

  componentDidMount(){
   
    this.setState({contactPersons:this.props.contactPersons});

  }

  onSelect = selectedOption => {
    this.setState(
      { sharedTo:selectedOption },
      () => this.props.handleonshareChange(selectedOption)
    );
  };
  
  onRemove(optionList, removedItem) {
  context.setState({sharedTo:optionList});
  this.props.handleonshareChange(optionList)
  }


  render() {
    return( 
      
      <span>
    
       
<div className="row">
  {/*     <div className="form-group">
                        <label className="control-label col-md-3">Visible to Client</label>
                        <div className="col-md-7">
                          <select
                            name="visibleToClient"
                            onChange={this.handleOnChange}
                            value={this.state.visibleToClient}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >

<option value="Select">Select Option</option>
<option value="NOT_VISIBLE">Not Visible</option>
<option  disabled={this.state.selectedClientMode=="both"} value="VISIBLE">Visible</option>


                          </select>
                        </div>
                      </div>
 */}
      </div>

                      
                     

                     
                    
                    
                   {/*    <div className="form-group">
                        <label className="control-label col-md-3">Select Client:</label>
                        <div className="col-md-7">
                          <select
                            name="selectedClient"
                            onChange={this.handleOnChange}
                            value={this.state.selectedClient}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >

                            {this.state.clients.map(function (client, i) {

                              return (<option value={client.id}>{client.customerId} {client.companyName}</option>)
                            })}

                          </select>
                        </div>
                      </div> */}

                      <div className="form-group">
            <label className="control-label col-md-3">Select Client Contact:</label>
            <div className="col-md-7">

            {this.state.contactPersons.length > 0 ?
                 <Select
                 value={this.state.sharedTo}
                 onChange={this.onSelect}
                 options={this.state.contactPersons}
                 isMulti={true}
               />
               : ''}
  </div>
  </div>
                     
                    
                    
        
        
          </span>


    );
  }
}
