import React, { Component } from 'react';

import '../style/agency.css';
import { SketchPicker } from 'react-color';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'
import '../animations.css';
import Modal from './Modal';
import StyleModal from './StyleModal';


/**
 * Register
 * It is the code for showing service section in the template
 * @module Services
 */
class Services extends Component {
  constructor(props){
    super(); 
    this.state ={
      toggleStyleMode:false,hideModal:true,Products:[] ,
      title:'', 
    showImageBox:false, newUrl:'',
     styles:{ 
      
          }};



    this.addProducts = this.addProducts.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
    this.removeLastProduct = this.removeLastProduct.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.functionmodal = this.functionmodal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.saveProduct = this.saveProduct.bind(this);
    this.saveStyle = this.saveStyle.bind(this);
    this.toggleStyleModal = this.toggleStyleModal.bind(this);
    
   }
   
   componentDidMount(){
    var productCount = this.props.productCount;
    var title = this.props.title;
    var innerProducts = this.props.innerProducts;
  
    var products = [];

    if(innerProducts){
    
  
    if(innerProducts.length==0){
    for(var i=0;i<productCount;i++){
  
    products.push({name:"Service "+(i+1), 
    icon:"https://www.pinclipart.com/picdir/middle/487-4879901_vector-illustration-of-office-paperwork-piling-up-in.png",
    content:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." 
    ,showImageBox:false, 
    showHeadBox:false, 
    showContentBox:false,
    toggleEditMode:false,
    styles:{backgroundColor:'#fff',
    color:'#000',
    descriptionColor:'#000',
    margin:'0px 0px 0px 0px'}});
  
    }
    }
    else{
    products = innerProducts;
    }
      
  }
    this.setState({styles:this.props.productStyle});
    this.setState({Products:products});
    this.setState({title:title});
    }
      
  
     
   
/**
 * addProducts()
 * This segment is the code for generating publish id for register.
 * @function addProducts()
 */
   

  

    addProducts(){
      var prod = this.state.Products;
      var productName = "Service " + (prod.length+1);
      const newProduct = {
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        icon: "https://www.pinclipart.com/picdir/middle/487-4879901_vector-illustration-of-office-paperwork-piling-up-in.png",
        name: productName,
        showContentBox: false,
        showHeadBox: false,
        showImageBox: false,
        styles: { backgroundColor: '#fff', color: "#000",
        descriptionColor:'#000'},
        backgroundColor:'#fff',
        toggleEditMode: false
            };
       prod.push(newProduct)
   
      this.setState({Products:prod});
      this.saveProduct(prod.length-1,newProduct)
      
     }


     /**
 * saveProducts()
 * This segment is the code for generating publish id for register.
 * @function saveProducts()
 */
   
  saveProduct(i,product){
   
    var products= this.state.Products;
    products.forEach(function(prod,keyindex){
    if (keyindex==i){
    
      prod.name=product.name;
      prod.icon=product.icon;
      prod.content=product.content;
    
       try{
        prod.styles.backgroundColor = product.backgroundColor;
    
        prod.styles.color = product.color;
      }
    catch(e){
      prod.styles = product;
    }
    }
    
    } );
  
   this.setState({Products:products});
   this.props.saveSectionProperties(
		this.props.sectionKey,
		this.state.title,
		this.state.styles,
		products
		);
    
    
   }

  onChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);


    console.log(this.state);
  }

    /**
 * removeLastProduct()
 * This segment is the code for removing last service card.
 * @function removeLastProduct()
 */
  removeLastProduct(){
    var prod = this.state.Products;
    //var productName = prod.length+1;
    prod.pop();
    this.setState({Products:prod});
    
    
   }
   /**
 * removeProduct()
 * This segment is the code for removing product with remove at.
 * @function removeProduct()
 */

   removeProduct(removeAt){
     
    var prod = this.state.Products;
    //var productName = prod.length+1;
    prod.splice(removeAt,1);
    this.setState({Products:prod});
    
    
   }
   /**
 * removeProduct()
 * This segment is the code for confirmimg delete service card with parameter remove at.
 * @function removeProduct()
 */

   confirmDelete(index){


    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete section.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.removeProduct(index)
        },
        {
          label: 'No',
          onClick: () => console.log("")
        }
      ]
    })
  }
   hideModal(i){

  
   
    var products= this.state.Products;
    products.forEach(function(prod,keyindex){
    if (keyindex==i){
      prod.toggleEditMode= !prod.toggleEditMode;
      
    }
    
    } );

   this.setState({Products:products});
   
   }


   toggleStyleModal(){

    var status = !this.state.toggleStyleMode;
    this.setState({toggleStyleMode:status});
    

   }
   saveStyle(newstyle){

    
    this.setState({styles:newstyle});
    this.setState({title:newstyle.title});
    this.props.saveSectionProperties(
			this.props.sectionKey,
      newstyle.title,
      newstyle,
      this.state.Products
      );

   }

   functionmodal(){
    var modal = document.getElementById('myModal');

    // Get the button that opens the modal
    var btn = document.getElementById("myBtn");
    
    // Get the <span> element that closes the modal
    var span = document.getElementsByClassName("close")[0];
    
    // When the user clicks on the button, open the modal 
    btn.onclick = function() {
      modal.style.display = "block";
    }
    
    // When the user clicks on <span> (x), close the modal
    span.onclick = function() {
      modal.style.display = "none";
    }
    
    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function(event) {
      if (event.target == modal) {
        modal.style.display = "none";
      }
    }
   }
   
    render(){
      const {styles }= this.state;
    return(



<div>
    
        {/* Services */}
        <section style={{color:styles.color,
          backgroundColor:styles.backgroundColor,
          backgroundImage:styles.backgroundImage!='no image' ? styles.backgroundImage : '',
          padding:styles.padding,
          margin:styles.margin
          }} id={this.props.title.replace(/\s/g, "")}>
        {this.state.toggleStyleMode ?  <StyleModal title={this.state.title} saveProduct={this.saveStyle} toggleStyleModal={this.toggleStyleModal} saveStyle={this.saveStyle}  styleProp={this.state.styles}/> : '' } 
          <div className="container">
            <div className="row templaterow">
              <div className="col-lg-12 text-center ">
                <h2  className="section-heading cursor  animated fadeInUp">{this.state.title} <span className="editclass editsign" onClick={()=>this.toggleStyleModal()}><i  className="fa fa-edit editclass"></i></span><span className="adddivsign editclass" style={{fontSize:'28px',top:'-7px',right:'66px'}} onClick={this.addProducts}>+</span></h2>
              
              </div>
            </div>
            <div className="row text-center">
            { this.state.Products.map((prod,i) => 
               
              <div  className="col-md-4 animatedParent cardd">
                {prod.toggleEditMode ?  <Modal saveProduct={this.saveProduct} hideModal={this.hideModal} product={prod} keyindex={i} /> : '' } 
              <div style={prod.styles} className="tempcard1 animated fadeInLeft ">
              {!prod.showImageBox ? 
              <button type="button" className="btn buttOn  btn-lg"   onClick={()=>this.onclickImage(i,prod.icon)}><span className="fa-2x ">
                 {/*} <i className="fas fa-circle fa-stack-2x text-primary" />
                  <i className={prod.icon + " fas icon  fa-stack-1x fa-inverse"} />
              */}
              <img className="mx-auto rounded1-circle editable " onClick={()=>this.hideModal(i)} src={prod.icon} alt />
                </span>
                </button>
                  :''}
                 
            
                 <h4 style={{color:prod.styles.color}} className="service-heading " >{prod.name}<span style={{fontSize:'15px',fontWeight:'400',top:'6px'}} className="editsign editclass" onClick={()=>this.hideModal(i)}><i  className="fa fa-edit editclass"></i></span><span style={{fontSize:'20px',fontWeight:'400'}} className="removesign editclass" onClick={()=>this.confirmDelete(i)}><i className="fas fa-times"></i></span></h4>

               

          
                 <p style={{color:prod.styles.descriptionColor,fontSize:'14px',fontFamily:'montserrat'}} className=" editable" onClick={this.hideModal} >{prod.content}</p>

              



              
                


  
  
  
                
               
                 </div>
              


   
      
                 </div>
            )}


          
            {/*
              <div className="col-md-4 animatedParent">
              <div className="tempcard animated fadeInLeft">
                <span className="fa-stack fa-2x ">
                  <i className="fas fa-circle fa-stack-2x text-primary" />
                  <i className="fas icon fa-laptop fa-stack-1x fa-inverse" />
                </span>
                <h4 className="service-heading" contentEditable="true">Product2</h4>
                <p className="text-muted" contentEditable="true">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima maxime quam architecto quo inventore harum ex magni, dicta impedit.</p>
              </div>
              </div>
              <div className="col-md-4 animatedParent">
              <div className="tempcard animated fadeInLeft ">
                <span className="fa-stack fa-2x ">
                  <i className="fas fa-circle fa-stack-2x text-primary" />
                  <i className="fas icon fa-lock fa-stack-1x fa-inverse" />
                </span>
                <h4 className="service-heading" contentEditable="true">Product3</h4>
                <p className="text-muted" contentEditable="true">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima maxime quam architecto quo inventore harum ex magni, dicta impedit.</p>
              </div>
            </div>*/}
            </div>
          </div>
        </section>
     
        <script src="../css3-animate-it.js"></script>
       </div>
    );
  

    }
}

export default Services;