export const baseFilePath = "template-engine-images/uploadedImages"
export const apiBaseUrl = "https://io9jvi51rg.execute-api.us-east-1.amazonaws.com/dev/templateengine";
export const apiWebzyUrl = "https://h9hir67oah.execute-api.us-east-1.amazonaws.com/dev/";
export const isValid = function (...args) {

    var isValid= false;
    args.forEach((arg, index) => {
        
        if(arg==null || arg.length<1 || arg=='' ){
                isValid = true;

        }

      });

      return isValid;

}
