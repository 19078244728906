import React, { Component } from "react";

import BreadCrumbs from '../../BreadCrumbs';
import { Link } from "react-router-dom";
import CreatableSelect from 'react-select/creatable';
import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1,apiIssueMaster, apiLogsMaster } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import {validate} from '../../common/Validation';
import { ChatFeed, Message } from 'react-chat-ui'
import Select from 'react-select';
import SubmitButton from '../../common/SubmitButton';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from 'underscore';
import MultiAttachments from "../../common/MultiAttachments";
let context = null;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px ',
    position: 'Absolute',
    zIndex: '30000',
    backgroundColor: 'none',
  }
};
const data = [{
  name: 'one',
  value: 'one'
},
{
  name: 'two',
  value: 'two'
},
{
  name: 'three',
  value: 'three'
},
{
  name: 'four',
  value: 'four'
},
{
  name: 'five',
  value: 'five'
},
{
  name: 'six',
  value: 'six'
}];
export default class AddTimeLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resources:{attachment:[],url:[],comment:[],checklist:[],doc:[]},
      assignmentTitle: data.assignmentTitle,
      startDate: data.startDate,
      endDate: data.endDate,
      overview: '',
      priority: data.priority,
      clients: [],
      organisations: [],
      teamLeads: [],
      teams: [],
      progressStatusList:[],
      groups:[],
      types:[],
      currentTemplate: "",
      templates: [],
      employees:[],
      sliderState:"1",
      reminderState:"1",
      teamMembers: [],
      isSubmitting:false,
      occurenceArray:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,"Never"]

    };
    context = this;
    this.openModal = this.openModal.bind(this);

    this.closeModal = this.closeModal.bind(this);
  }

  changeSliderState(orderNumber) {

    this.setState({ sliderState: orderNumber });
    

  }
  changeReminderState(orderNumber) {

    this.setState({ reminderState: orderNumber });
    

  }

  fetchStatus(that,status){

    axios.get(apiBaseUrl1 +"businus/status/list/progress")
    .then(function (response) {
      console.log(response.data);
  
      response.data.unshift({progressStatus:'Select Status'});
        that.setState({progressStatusList:response.data});
      
      
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }
  
  //function for changing the status button on click to open or close
  handleOnChange = (e) => {
    debugger;
    var value = e.target.value;

    var formName = e.target.name;
    this.setState({ [formName]: value });


setTimeout(()=>{
  if(formName=='quantity' || formName=='rate'){

    let amount = 0;

    let quantity = this.state.quantity || 0;
    let rate = this.state.rate || 0;

    amount = parseInt(quantity) * parseInt(rate);

 this.setState({ amount });

  }

},0)
  

  
 
  }


  handleQuillChange=(value)=> {
    this.setState({ overview: value })
    // document.getElementById('save').innerHTML = 'Add Act';
  }

  handleOnDateChange = (e) => {

    var value = e.target.value;
    var formName = e.target.name;
    var startDate = this.state.startDate;
    var d1 = new Date(startDate);
    var d2 = new Date(value);

    if (d1 < d2) {
      this.setState({ [formName]: value });

    } else {
      alert("Start date can not be greater than End date")
    }



    console.log(this.state);
  }

 

  openModal(type) {
    this.changeDocType(type);
    this.setState({ modalIsOpen: true });
  }



  closeModal() {
    this.setState({ modalIsOpen: false });
  }


  componentDidMount() {
    

    //this.fetchQueryById(this, id);
   
    this.fetchGroups(this);
   // this.fetchTypes(this);
    //sthis.fetchThreadById(this, id);
    this.fetchEmployees(this);
    this.fetchClients(this);
    this.fetchStatus(this);
    //this.markAsRead(this, id);
  }


 /*  markAsRead(that, id) {
    axios
      .get(
       apiBaseUrl2+"businusquery/read/" +
        id
      )
      .then(function (response) {


      })
      .catch(function (error) {
        console.log(error);
      });
  }
 */

  //fetching the data by id which this file recieved from the list

  /* fetchQueryById(that, id) {
    axios
      .get(
        apiBaseUrl1+"businus/customer/list/"+localStorage.spId

      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  } */

  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });



          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
 /*  fetchThreadById(that, id) {
    axios
      .get(
       apiBaseUrl2+"businusquery/listmail/" +
        id
      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        var threads = response.data == "" ? [] : _.sortBy(response.data, 'date').reverse();
        that.setState({ thread: threads });

        var documents = _.where(threads, { docType: "Document" });
        var proposals = _.where(threads, { docType: "Proposal" });
        var mails = _.where(threads, { docType: "Mail" });
        that.setState({ documents });
        that.setState({ proposals });
        that.setState({ mails });


      })
      .catch(function (error) {
        console.log(error);
      });
  } */


  fetchClients(that, source) {

    axios.get(apiBaseUrl2+"businus/customer/list/"+localStorage.spId)
      .then(function (response) {
        console.log(response.data);
        
        response.data.unshift({clientId:"",companyName:"Select Client"});
        const assignmentData = that.props.assignmentData;
        that.setState({ clients: response.data },()=>{

          if(assignmentData){
          
            that.setState({selectedClient:assignmentData.selectedClient.id});
          }
        
        });

      })
      .catch(function (error) {
        console.log(error);
      });

  }


  fetchEmployees(that, source) {

    axios.get(apiBaseUrl2+"businus/employee/list/"+localStorage.spId)
      .then(function (response) {
        debugger;
        console.log(response.data);
        var allEmployees = response.data;
        var employees = response.data;

      
        var teamMembers = allEmployees;

        var teamMembersMultiSelectData = []

        _.each(teamMembers, function (member) {


          teamMembersMultiSelectData.push({
            value:member.id,
              label: member.employeeId +" "+ member.firstName +" "+ member.lastName,
            name: member.employeeId +" "+ member.firstName +" "+  member.lastName,
            id: member.id,
            email:member.email
          })





        });

      
        employees =teamMembers;



        that.setState({ teamMembers: teamMembersMultiSelectData,employees:employees });
      })
      .catch(function (error) {
        console.log(error);
      });

  }


  handleOnClientChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;

    var selectedClient = _.findWhere(this.state.clients,{id:e.target.value});
    var organisations =selectedClient.assignedOrganisation;
    debugger;
    if(organisations!="0" && organisations!==undefined){
      organisations.unshift({name:"Select Organisation",organisationId:""})
      
      console.log(organisations)
    }
    
    
    var contactPersons = selectedClient.contactPersons;
    
    contactPersons.push({email:selectedClient.customerEmail,name:selectedClient.primaryContact});
   // state['contactPersons'] = contactPersons;
    //state['subjectUpdate'] = selectedTemplate.update;


    contactPersons =   _.map(contactPersons, function (member,key) {


      member =   {
          value:member.id + key,
        label: member.name +" : "+ member.email,
        email:member.email
      };

      return member;




    });
    state['contactPersons'] = contactPersons;

    this.setState(state);

    this.setState({organisations});
    console.log(this.state);

  }

  onSelect = selectedOption => {
    this.setState(
      { team:selectedOption },
      () => console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  onRemove(optionList, removedItem) {
    context.setState({ team: optionList });
  }


  
  
  onSelectShare = selectedOption => {
    this.setState(
      { sharedTo:selectedOption },
      () => console.log(`Option selected:`, this.state.selectedOption)
    );
  };
  
  onRemoveShare(optionList, removedItem) {
  context.setState({sharedTo:optionList});
  }
  


  getFiles(files) {
    this.setState({ selectedFile: files[0] });

  }

  getFormattedDate(millis) {

    return new Date(millis).toDateString();


  }
  
  openForm=()=>{
    // Create a "hidden" input
    var aux = document.createElement("input");
  
    let link  = window.location.href.replace("assignment/add-assignment","userforms/assignment") ;
    
    window.open(link,"Create new assignment", "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000");
  }


  toggleOpen = status => {
    if (status == undefined || status == "open") {
      status = "close";
    } else {
      status = "open";
    }
    //(this.setState({ entry: [...this.state.entry,{status:status} ] });)
    var newEntry = this.state.entry;
    newEntry.status = status;
    this.setState({ entry: newEntry });
    context.updateStatus(context, this.state.entry.id, status);
  };

  changeDocType(type) {

    this.setState({ docType: type });
  }

  

  fetchGroups(that, source) {

    axios.get(apiIssueMaster+"businusquery/issue/master/ACTIVITY/"+localStorage.spId)
      .then(function (response) {
        console.log(response.data);
        var groups = response.data;     
        groups.unshift({title:'Select a Category',description:''});
        that.setState({ groups});
      })
      .catch(function (error) {
        console.log(error);
      });

  }
  


  sendMail() {

    var data = context.state;
    if(validate('required', data.title, data.selectedClient,data.overview, data.dueDate,  data.selectedOrganisation, data.sharedTo,data.team, data.billingType, data.duration, data.sharedTo)){

      alert
      ("Please fill all the required fields");
      return;
    }
   

    if (context.props.mode == "component") {
      // data.selectedClient = this.props.assignmentData.selectedClient.id;
       data.assignmentId = context.props.assignmentData.assignmentId;
       data.queryId = context.props.assignmentData.id;
       data.sharedTo= _.union(context.props.assignmentData.team,[context.props.assignmentData.teamLead]);
     }
     var selectedClient = _.findWhere(data.clients,{ id: data.selectedClient});
    if(selectedClient){
      selectedClient  = {id:selectedClient.id,name:selectedClient.primaryContact,email:selectedClient.customerEmail,companyName:selectedClient.companyName,mode:selectedClient.mode};
  
    }
    else{
      alert("Client can't be blank");
      return;
    }
   

    var selectedOrganisation= context.state.organisations[data.selectedOrganisation];


    var selectedCategory = context.state.groups[data.selectedCategory];

    let sharedTo = data.sharedTo;
    
    context.setState({isSubmitting:true});
      axios
      .post(
        apiLogsMaster +"businusquery/Log",
        {
         
          "title": data.title,
          "selectedCategory": selectedCategory,
          "selectedOrganisation":selectedOrganisation,
          selectedClient:selectedClient,
          team: data.team,
          "dueDate": data.dueDate,
          "overview": data.overview,
          "billingType":data.billingType,
          "duration":data.duration,
          attachments:data.attachments,
          type:"Time",
          "by":localStorage.userName,
          sharedTo:sharedTo,
          spId:localStorage.spId,
          assignmentId: data.assignmentId || 0,
          queryId: data.queryId || 0,
        })
      .then(function (response) {
        debugger;
        alert("Success!");
        //  context.fetchThreadById(context,query.id);
       
        context.setState({

        })
        if (context.props.onSaveHandler) {
          context.props.onSaveHandler(response);
        }
        context.setState({isSubmitting:'done'});
      }
      )
      .catch(function (error) {
        context.setState({isSubmitting:false});
        console.log(error);
      });
  }

  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    localStorage.setItem("MAILURL", url);


  }

  handleChange = (newValue, actionMeta) => {
    this.setState({tags:newValue});
  };


  //updating the status button

  render() {
    const { sliderState } = this.state;
    const { reminderState } = this.state;
    const active = " active-button"
    const deactive = "";
    return (
      <div className="Div">
      {this.props.mode != "component" ? (
          <div className="row breadcrumb-row">
            <div className="col-md-8">
              <BreadCrumbs />
            </div>
            <div className="col-md-4"></div>
          </div>
        ) : (
          ""
        )}
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
        <div className="dedicated-page" style={{padding:'0px'}}>
    <div className="row-resource-head">
                  <h2 className="resource-head">Create Time Log </h2>
                  </div>
            <div className="row ">
              <div className="col-md-12 info-column account-col2">
                <form id="field">
              
                  <div className="container">
                    

              <div className="row">

                    <div className="col-md-9">
                    <div className="form-group">
                        <label className="control-label col-md-3">Activity Category</label>
                        <div className="col-md-7">
                          <select
                            name="selectedCategory"
                            onChange={this.handleOnChange}
                            value={this.state.selectedCategory}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >

                            {this.state.groups.map(function (template, i) {

                              return (<option value={i}>{template.title}</option>)
                            })}

                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="control-label col-md-3">
                           Title
                    </label>
                        <div className="col-md-7">
                          <input
                            name="title"
                            onChange={this.handleOnChange}
                            value={this.state.title}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          />
                        </div>
                      </div>





                      <div className="form-group" style={{marginBottom:'50px'}}>
                        <label className="control-label col-md-3">
                           Notes:
                    </label>
                        <div className="col-md-7">
                          
                          <ReactQuill
                              formats={[
                                "background",
                                "bold",
                                "color",
                                "font",
                                "code",
                                "italic",
                                "link",
                                "size",
                                "strike",
                                "script",
                                "underline",
                                "blockquote",
                                "header",
                                "indent",
                                "list",
                                "align",
                                "direction",
                                "code-block",
                                "formula",
                              ]} value={this.state.overview} name="overview" onChange={this.handleQuillChange} style={{height:'200px'}}/><br />
                        </div>
                      </div>
                      <div className="form-group" style={{marginType:'30px'}}>
                        <label className="control-label col-md-3">Billing Type</label>
                        <div className="col-md-7">
                          <select
                            name="billingType"
                            onChange={this.handleOnChange}
                            value={this.state.billingType}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          > <option value="Select">Select Option</option>
                            <option value="Billable">Billable</option>
                            <option value="Non billable">Non billable</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group" style={{marginBottom:'20px'}}>
                        <label className="control-label col-md-3">
                          Duration:
                    </label>
                        <div className="col-md-7">
                          <input
                            name="duration"
                            onChange={this.handleOnChange}
                            value={this.state.duration}
                            className="form-control ember-text-field ember-view"
                            type="number"
                          />
                        </div>
                      </div>
                     


                    

                     
                     

                      {/*      <div className="form-group">
                    <label className="control-label col-md-4">
                      Customer Display Name
                    </label>
                    <div className="col-md-6">
                      <input
                          onChange={this.handleOnChange}
                          name="customerDisplayName"
                        value={this.state.customerDisplayName}
                        className="form-control ember-text-field ember-view"
                        type="text"
                      />
                    </div>
                  </div> */}

             
                 
<div style={{marginTop:'20px', marginLeft:'50px'}} className="row">
             <div className="col-md-12">
                     
                   
                      <div className="row" style={{marginTop:"50px"}} >
                      <MultiAttachments saveAttachments={(attachments)=>{this.setState({attachments})}} />
         </div>

         <div className="form-group">
                        <label className="control-label col-md-3">
                          Date:
                    </label>
                        <div className="col-md-7">
                          <input
                            name="dueDate"
                            onChange={this.handleOnChange}
                            value={this.state.dueDate}
                            className="form-control ember-text-field ember-view"
                            type="date"
                          />
                        </div>
                      </div>
                     
                    


                     
                     

                     

                      
    
                     
                    
                    
                      {this.props.mode!='component' ?      <div className="form-group">
                        <label className="control-label col-md-3">Share To Client:</label>
                        <div className="col-md-7">
                          <select
                            name="selectedClient"
                            onChange={this.handleOnClientChange}
                            value={this.state.selectedClient}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >

                            {this.state.clients.map(function (client, i) {

                              return (<option value={client.id}>{client.customerId} {client.companyName}</option>)
                            })}

                          </select>
                        </div>
                      </div> :''}
                     

                      <div className="form-group">
                        <label className="control-label col-md-3">Organisation:</label>
                        <div className="col-md-7">
                          <select
                            name="selectedOrganisation"
                             onChange={this.handleOnChange}
                            value={this.state.selectedOrganisation}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >

                            {this.state.organisations.map(function (organisation, i) {

                              return (<option value={i}> {organisation.organisationId+" "+organisation.name}</option>)
                            })}

                          </select>
                        </div>
                      </div>
                      
                    
                      
                     

                     

                   
                      {this.props.mode!='component' ?      <div className="form-group">
                        <label className="control-label col-md-3">Shared To (Team):</label>
                        <div className="col-md-7">

                        {this.state.employees.length > 0 ?
                             <Select
                             value={this.state.sharedTo}
                             onChange={this.onSelectShare}
                             options={this.state.teamMembers}
                             isMulti={true}
                           />
                            :  ''}

                        
                        </div> 
                      </div>: ''}
                      </div>
                      </div>
                    </div>
                  </div>
                  </div>

                  

  
  
 <div> 
     
     
      </div>
                    


                  <div className="text-left">
                  <SubmitButton clickHandler={this.sendMail}
                   isSubmitting={this.state.isSubmitting} />
                  </div>
                  {/* this says that if editing state is on then the Function saveAccountDetails will be called */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>


    );
  }
}

