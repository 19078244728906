import React, { Component } from "react";
import "../../style/panel.css";
import { Link } from "react-router-dom";

export default class LegalDashboard extends Component{
    render(){
        return(
            <div className="row">
                <div className="col-md-1 col-lg-1"/>
                <div className="col-md-11 col-lg-11">
                <div className='cotainer'>
                    <div className="col-md-12 col-lg-12">
                    <div className="tab-row1">
                    <h2 className="tab-row-head">Businus School</h2></div></div>
                    <div className="row">

                    <div className="col-md-2">
                    <div className="tab text-center">
                    <Link to="/knowledgeHub/businus-school/homepage">
                    <img className="tab-image1" id="tab1" 
                                style={{ marginLeft: "-3px" }}
                                src={require("../../images/home.png")}
                                />
                        <h2 className="tab-text-main"> Homepage </h2></Link>
                    </div>
                    </div>


                    <div className="col-md-2">
                    <div className="tab text-center">
                    <Link to="/knowledgeHub/businus-school/legal">
                    <img className="tab-image1" id="tab1" 
                                style={{ marginLeft: "-3px" }}
                                src={require("../../images/finance-icon.png")}
                                />
                        <h2 className="tab-text-main"> Legal & Finance </h2></Link>
                    </div>
                    </div> 

                    <div className="col-md-2">
                    <div className="tab text-center">
                    <Link to="/knowledgeHub/businus-school/exim">
                    <img className="tab-image1" id="tab1" 
                                style={{ marginLeft: "-3px" }}
                                src={require("../../images/exim-icon.png")}
                                />
                        <h2 className="tab-text-main"> EXIM </h2></Link>
                    </div>
                    </div> 

                    <div className="col-md-2">
                    <div className="tab text-center">
                    <Link to="/knowledgeHub/businus-school/wealth">
                    <img className="tab-image1" id="tab1" 
                                style={{ marginLeft: "-3px" }}
                                src={require("../../images/insights-icon.png")}
                                />
                        <h2 className="tab-text-main"> Stories & Insights </h2></Link>
                    </div>
                    </div> 


                    <div className="col-md-2">
                    <div className="tab text-center">
                    <Link to="/knowledgeHub/businus-school/opportunity">
                    <img className="tab-image1" id="tab1" 
                                style={{ marginLeft: "-3px" }}
                                src={require("../../images/opportunity-icon.png")}
                                />
                        <h2 className="tab-text-main"> Opportunity & Growth </h2></Link>
                    </div>
                    </div> 
                        
                        </div>

                        </div>
                    </div>
                
            {/* </div> */}
            </div>
        )
    }
}