import React from 'react';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import axios from 'axios';
import { apiBaseUrl3 } from '../../apiUtils';
import $ from "jquery";
import SubmitButton from '../../common/SubmitButton';
import { apiBaseUrl1 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import _ from 'underscore';
import Select from 'react-select';
let context = null;
class AddDocResource extends React.Component {
  constructor(props) {
    super(props)

    var allText = 'Hi write something';
    this.state = {
      templates:[],
      assignments:[],
      contactPersons:[],
      text: allText,
      inpWidth:'100%',
      encodedBlogImage: "",
      encodedAuthorImage: "",
      u : 0,
      c : 0,
      clients:[],
      employees:[],
      headings : "",
      isSubmitting:false,
      contactPersonsClients:[],
      contactPersons:[]
     }
    this.handleChange = this.handleChange.bind(this);
    // this.handleFileChosen = this.handleFileChosen.bind(this)
    // this.upload_file = this.upload_file.bind(this)
    this.save_to_dynamo = this.save_to_dynamo.bind(this);
    this.encodeImage = this.encodeImage.bind(this);
    this.incrementu = this.incrementu.bind(this);
    this.addUrl = this.addUrl.bind(this);
    this.addContent = this.addContent.bind(this);
    this.incrementc = this.incrementc.bind(this);
    context =this;
    this.fetchAssignments(this);
  }
  handleOnAttachment= (e) => {

    var value = e.target.value;

    var formName = e.target.name;

    this.setState({ [formName]: value });



    console.log(this.state);
  }


  fetchClients(that,source){

    axios.get(apiBaseUrl2+"businus/customer/list/"+localStorage.spId)
    .then(function (response) {
      console.log(response.data);
      that.setState({clients:_.sortBy(response.data,"customerId")});
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }
  componentDidMount(){
  this.fetchClients(this);
  this.fetchTemplates(this,'Document');
  this.fetchEmployees(this);
  }



  
  handleOnTemplateChange = (e) => {
    debugger;
        var value = e.target.value;
    
        var selectedTemplate = this.state.templates[value];
        var formName = e.target.name;
    
    


        this.setState({ 'encodedBlogImage': selectedTemplate.blog.encodedAuthorImage });
        this.setState({ 'encodedAuthorImage': selectedTemplate.blog.encodedAuthorImage });
        document.getElementById('heading').value  = selectedTemplate.blog.heading;
        document.getElementById('subHeading').value  = selectedTemplate.blog.subHeading;
        document.getElementById('datePosted').value  = selectedTemplate.blog.datePosted;
        document.getElementById('authorName').value  = selectedTemplate.blog.authorName;
        document.getElementById('authorDescription').value  = selectedTemplate.blog.authorDescription;
        document.getElementById('readTime').value  = selectedTemplate.blog.readTime;
        document.getElementById('source').value  = selectedTemplate.blog.source;
        document.getElementById('sourceurl').value  = selectedTemplate.blog.sourceUrl;
        document.getElementById('blogImageCredit').value  = selectedTemplate.blog.blogImageCredit;
        document.getElementById('tags').value  = selectedTemplate.blog.tags.join(",");
        document.getElementById('authorImageCredit').value  = selectedTemplate.blog.authorImageCredit;
        document.getElementById('video').value  = selectedTemplate.blog.video;
        
        this.setState({ 'text': selectedTemplate.blog.content });
    
        
    
      
    
    
      }
    

 
 
  handleChange(value) {
    this.setState({ text: value })
    // document.getElementById('save').innerHTML = 'Save';
  }
// template-engine-images.s3.amazonaws.com/files/quill.txt


  // handleFileChosen(file) {
  //   let fileReader;
  //   fileReader = new FileReader();
  //   fileReader.readAsText(file);
  //   fileReader.onloadend = function(e){
  //     var content = 'text';
  //     content = fileReader.result;
  //     console.log('handleFileChosen : ', content);
  //     this.setState({ text: content })
  //     // this.handleChange(content)
  //   }.bind(this);
  // };


  // upload_file(){
  //   var text = this.state.text;
  //   var encodedText =  btoa(text);
  //   console.log('encodedText :', encodedText);
  //
  //   axios({
  //     url: 'https://gwqzi2xcl8.execute-api.us-east-1.amazonaws.com/dev/uploadFiletos3', //your url
  //     method: 'POST',
  //     data:{file:encodedText, fileName:'quill.txt'}
  //   }).then((response) => {
  //     // this.handleFileChosen(response.data);
  //     console.log('resonse_data_upload :', response.data)
  //     document.getElementById('save').innerHTML = 'Saved <span className="fas fa-check-circle"></span>';
  //  });
  //
  // }


  encodeImage(imageid){
    var image = document.getElementById(imageid).files[0];
    // console.log(image);
    // document.getElementById(imageid + "Label").className = "custom-file-label selected";
    // document.getElementById(imageid + "Label").innerHTML = image.name;
    var reader  = new FileReader();
    var encodedImage ="";
    reader.addEventListener("load", function (e) {
      encodedImage = e.target.result.replace(/data:image\/(png|jpeg|jpg);base64,/, '');
      if (imageid=="blogImage") {
        this.setState({ encodedBlogImage:  encodedImage});
        document.getElementById('blogImagePreview').src=e.target.result;
        document.getElementById('blogImagePreview').style.display="block";
      }else if (imageid=="authorImage") {
        this.setState({ encodedAuthorImage:  encodedImage});
        document.getElementById('authorImagePreview').src=e.target.result;
        document.getElementById('authorImagePreview').style.display="block";
      }
      // return encodedImage;
    }.bind(this), false);
    reader.readAsDataURL(image);
  }


  save_to_dynamo(){
    // console.log("encodedBlogImage  :", this.state.encodedBlogImage);
    // console.log("encodedAuthorImage  :", this.state.encodedAuthorImage);

    var external = [];
    var internal = [];
    var sel = "";
    for (var i = 0; i < this.state.u; i++) {
      external[i] = {
        src : document.getElementById('src_' + i).value,
        url : document.getElementById('url_' + i).value,
        name : document.getElementById('srcName_' + i).value,
      }
    }

    for (i = 0; i < this.state.c; i++) {
      sel = document.getElementById('head_' + i);
      internal[i] = {
        heading: sel.options[sel.selectedIndex].text,
        id: sel.options[sel.selectedIndex].id,
      }
    }

    var more = {
      external : external,
      internal : internal
    }

    var query = context.state;
    let hasAssignment = query.attachmentAssignment;
    let sharedTo = context.state.sharedTo;
    if(hasAssignment=='N'){
  
      sharedTo = _.union(sharedTo,context.state.sharedToClient);
  
    }
    var blg = {
      encodedblogImage: this.state.encodedBlogImage,
      encodedAuthorImage: this.state.encodedAuthorImage,
      heading: document.getElementById('heading').value,
      subHeading: document.getElementById('subHeading').value,
      datePosted: document.getElementById('datePosted').value,
      dateModified: "0000-00-00",
      authorName: document.getElementById('authorName').value,
      authorDescription: document.getElementById('authorDescription').value,
      postedBy: localStorage.userName,
      readTime: document.getElementById('readTime').value,
      source: document.getElementById('source').value,
      sourceUrl:document.getElementById('sourceurl').value,
      content: this.state.text,
      blogImageCredit: document.getElementById('blogImageCredit').value,
/*       catagory: document.getElementById('catagory').value, */
      tags: document.getElementById('tags').value.split(","),
      authorImageCredit: document.getElementById('authorImageCredit').value,
      blogRating: "0",
      authorRating: "0",
      videoUrl:document.getElementById('video').value,
      by:localStorage.userName,
      docType:'Document',
      date: new Date().getTime(),
      sharedTo:sharedTo,
     hasAssignment:context.state.attachmentAssignment,
     selectedAssignment:context.state.selectedAssignment,
     spId:localStorage.spId,
      // more:more

    };
    console.log("blog :",blg);

   context.setState({isSubmitting:true});
    axios({
      url: apiBaseUrl3+'businus/workflowDoc/add', //your url
      method: 'POST',
      data: blg,
    }).then((response) => {
      console.log('resonse_data_dynamo :', response.data);
      alert("Doc Added");
    /*   document.getElementById('save').innerHTML = 'Added <span className="fas fa-check-circle"></span>';
      document.getElementById('save').className = 'btn btn-success bottomright';
 */
      //this.props.AssignId(response.data);
       alert("Doc Added");
       context.setState({isSubmitting:'done'});
   });
  }

  incrementu() {
    // increments variable for no. of urls in MORE

    var inc = this.state.u + 1;
    this.setState({
      u : inc
    });
  }

  incrementc() {
    // increments variable for no. of website content in MORE

    var inc = this.state.c + 1;
    this.setState({
      c : inc
    });
  }

  addUrl(){
    // adds a URL entry in MORE
    
    var out_div = document.createElement('div');
    var spn = document.createElement('span');
    var src = document.createElement("INPUT");
    var url = document.createElement("INPUT");
    var srcName = document.createElement("INPUT");
    var br = document.createElement("br");
    this.incrementu();
    var i = this.state.u;

    out_div.setAttribute("class","input-group mb-3");
    out_div.style.width = '80%';
    // out_div.setAttribute("id", "urldiv_" + i);

    spn.setAttribute("type", "text");
    spn.setAttribute("class", "input-group-addon");
    spn.style.width = '20%';
    spn.innerHTML = "Source Url";
    // spn.setAttribute("id", "lat_" + i);

    src.setAttribute("type", "text");
    src.setAttribute("class", "form-control");
    src.setAttribute("placeholder", "Source");
    src.setAttribute("id", "src_" + i);

    url.setAttribute("type", "text");
    url.setAttribute("class", "form-control");
    url.setAttribute("placeholder", "Url");
    url.setAttribute("id", "url_" + i);

    srcName.setAttribute("type", "text");
    srcName.setAttribute("class", "form-control");
    srcName.setAttribute("placeholder", "Name");
    srcName.setAttribute("id", "srcName_" + i);

    out_div.appendChild(spn);
    out_div.appendChild(src);
    out_div.appendChild(url);
    out_div.appendChild(srcName);
    document.getElementById("more").appendChild(out_div);
    document.getElementById("more").appendChild(br);

  }

  addContent(){
    // adds a WEBSITE CONTENT entry in MORE

    var out_div = document.createElement('div');
    var spn = document.createElement('span');
    var src = document.createElement("select");
    var br = document.createElement("br");
    this.incrementc();
    var i = this.state.c;

    out_div.setAttribute("class","input-group mb-3");
    out_div.style.width = '80%';
    out_div.setAttribute("id", "web_" + i);

    spn.setAttribute("type", "text");
    spn.setAttribute("class", "input-group-addon");
    spn.style.width = '20%';
    spn.innerHTML = "Heading";

    // src.setAttribute("type", "text");
    src.setAttribute("class", "form-control");
    // src.setAttribute("placeholder", "Heading");
    src.setAttribute("id", "head_" + i);
    src.innerHTML = this.state.headings;

    out_div.appendChild(spn);
    out_div.appendChild(src);
    document.getElementById("more").appendChild(out_div);
    document.getElementById("more").appendChild(br);

  }

  handleTweets(){
    var val= document.getElementById('tweets').value
    this.setState({text:val}) 
  }


  onSelect = selectedOption => {
    this.setState(
      { sharedTo:selectedOption },
      () => console.log(`Option selected:`, this.state.selectedOption)
    );
  };
  
  onRemove(optionList, removedItem) {
  context.setState({sharedTo:optionList});
  }
  
  

  handleOnAssignChange = (e) =>{
    debugger;
    const state = this.state
    state[e.target.name] = e.target.value;

    var selectedAssignment= _.findWhere(this.state.assignments,{id:e.target.value});
    let teamLead = selectedAssignment.teamLead;
    let team = selectedAssignment.team;
    let client = selectedAssignment.selectedClient;
    let contactPersons = [];
    contactPersons.push(
      {label:"Team Lead :"+teamLead.name,value:teamLead.id,...teamLead,type:'teamlead'});
      contactPersons.push(
        {label:"Client :"+client.name,value:client.id,...client,type:'client'});
        
        _.each(team,function(d){
          contactPersons.push(
            d);
        })
   // state['contactPersons'] = contactPersons;
    //state['subjectUpdate'] = selectedTemplate.update;



    state['contactPersons'] = contactPersons;


    this.setState(state);


    console.log(this.state);
  
  }

  
  fetchAssignments(that,source){

    axios
    .get(
     apiBaseUrl1+"businus/project/list/" +
        localStorage.getItem("employeeId")
    )
    .then(function(response) {
      console.log(response.data);
      that.setState({ assignments: _.sortBy(response.data, "projectId") });
      that.generateExcelData(response.data);
    })
    .catch(function(error) {
      console.log(error);
    });
  }


  
  fetchEmployees(that,source){

    axios.get(apiBaseUrl2+"businus/employee/list/"+localStorage.spId)
    .then(function (response) {
      console.log(response.data);

       let allEmp = response.data;
      if(localStorage.userRole!='Admin'){

        allEmp = _.filter(allEmp,function(emp){

          return emp.role!='Admin';
        });
      }
     
      let contactPersons =[];


      _.each(allEmp, function (member) {


        contactPersons.push({
          value:member.id,
            label: member.employeeId +" "+ member.firstName +" "+ member.lastName,
          name: member.employeeId +" "+ member.firstName +" "+  member.lastName,
          id: member.id,
          email:member.email
        })





      });
      
      that.setState({employees:contactPersons});
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }
  
  handleOnClientChange = (e) =>{
    debugger;
    const state = this.state
    state[e.target.name] = e.target.value;

    var selectedClient= _.findWhere(this.state.clients,{id:e.target.value});
    let primaryContact = {id:selectedClient.id,name:selectedClient.primaryContact,email:selectedClient.customerEmail,label:selectedClient.primaryContact};
    let contacts = selectedClient.contactPersons;
   
    let contactPersons = [];
    contactPersons.push(primaryContact);
  
      
        _.each(contacts,function(d){
          contactPersons.push({label:d.name,type:'contact',...d});
        })
   // state['contactPersons'] = contactPersons;
    //state['subjectUpdate'] = selectedTemplate.update;



    state['contactPersonsClients'] = contactPersons;


    this.setState(state);


    console.log(this.state);
  
  }


  fetchTemplates(that, type) {

    axios.get(apiBaseUrl1+"businus/template/list/resources/" + localStorage.spId)
      .then(function (response) {
        console.log(response.data);
        console.log(type);
        var templates = response.data;
        
       


        templates = _.where(templates,{templateType:type});
        templates.unshift({name:'custom',description:''});
        templates.unshift({name:'Select a template',description:''});
        that.setState({ templates: templates });
      })
      .catch(function (error) {
        console.log(error);
        
      });

  }
  render() {
    return (
      <div className = "container-fluid overflow-hidden" style={{margin: "10px", height:'90vh'}}>
        <div style={{margin: "10px"}}><h3>Add Doc : </h3></div>

          <div className="container-fluid well well-sm" style={{}}>

       


            <div className="input-group" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Title" style={{width:"20%"}}>Template</span>
            <select
                            name="currentTemplate"
                            onChange={this.handleOnTemplateChange}
                            value={this.state.currentTemplate}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >

                            {this.state.templates.map(function (template, i) {

                              return (<option value={i}>{template.templateId} {template.title || template.name}</option>)
                            })}

                          </select>
            </div>                  

          <div className="input-group" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Title" style={{width:"20%"}}>Title</span>
            <input type="text" className="form-control" id="heading" placeholder="" style={{}} required></input>
          </div>

          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Title" style={{width:"20%"}}>Subtitle</span>
            <input type="text" className="form-control" id="subHeading" placeholder="" style={{width:this.state.inpWidth}} required></input>
          </div><br/>

        <div className="input-group" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Content" style={{width:"20%"}}>Content</span>
            <ReactQuill
                              formats={[
                                "background",
                                "bold",
                                "color",
                                "font",
                                "code",
                                "italic",
                                "link",
                                "size",
                                "strike",
                                "script",
                                "underline",
                                "blockquote",
                                "header",
                                "indent",
                                "list",
                                "align",
                                "direction",
                                "code-block",
                                "formula",
                              ]} value={this.state.text} onChange={this.handleChange} />
            {/*<textarea type="text" rows="10" className="form-control" id="content" placeholder="" style={{width:this.state.inpWidth}} required></textarea>*/}
          </div><br/>

          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Content Source"span style={{width:"20%"}}>Content Source</span>
            <input type="text" className="form-control" id="source" placeholder="" style={{width:this.state.inpWidth}} required></input>
          </div><br/>

          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Content Source"span style={{width:"20%"}}> Source Url</span>
            <input type="text" className="form-control" id="sourceurl" placeholder="" style={{width:this.state.inpWidth}} required></input>
          </div><br/>

          {/*<img id="blogImagePreview" className="" src="#" alt="authorImage" style={{width: "16%"}}/>
          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Article Image" style={{width:"20%"}}>Article Image</span>
            <div className="form-control" style={{width:this.state.inpWidth}}>
              <input type="file" className="form-control" id="blogImage" name="filename" onChange={() => this.encodeImage('blogImage')}></input>
              <label className="custom-file-label" for="" id="blogImageLabel">Choose file ... only png</label>
            </div>
          </div><br/>*/}

        

          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Tags" style={{width:"20%"}}>Tags</span>
            <input type="text" className="form-control" id="tags" placeholder="break tags with ," style={{width:this.state.inpWidth}} required></input>
          </div><br/>

         

          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Date of Publishing" style={{width:"20%"}}>Date of Publishing</span>
            <input type="date" className="form-control" id="datePosted" placeholder="yyyy-mm-dd" min="2018-01-01" max="2030-12-31" style={{width:this.state.inpWidth}} required></input>
          </div><br/>

          {/*<div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="form-control input-group-text" id="" placeholder="Date Edited" style={{width:"20%"}}></span>
            <input type="text" className="form-control" id="dateModified" placeholder="yyyy-mm-dd" style={{width:this.state.inpWidth}} required></input>
          </div><br/>*/}

          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Read Time" style={{width:"20%"}}>Read Time</span>
            <input type="number" className="form-control" id="readTime" placeholder="minutes" style={{width:this.state.inpWidth}} required></input>
          </div><br/>

          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Author" style={{width:"20%"}}>Author</span>
            <input type="text" className="form-control" id="authorName" placeholder="" style={{width:this.state.inpWidth}} required></input>
          </div><br/>

          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Author Description" style={{width:"20%"}}>Author Description</span>
            <input type="text" className="form-control" id="authorDescription" placeholder="" style={{width:this.state.inpWidth}} required></input>
          </div><br/>

          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Author Image Credit" style={{width:"20%"}}>Author Image Credit</span>
            <input type="text" className="form-control" id="authorImageCredit" placeholder="" style={{width:this.state.inpWidth}} required></input>
          </div><br/>

          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Article Image Credit" style={{width:"20%"}}>Article Image Credit</span>
            <input type="text" className="form-control" id="blogImageCredit" placeholder="" style={{width:this.state.inpWidth}} required></input>
          </div><br/>

          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Article Image Credit" style={{width:"20%"}}>Video Url</span>
            <input type="text" className="form-control" id="video" placeholder="" style={{width:this.state.inpWidth}} required></input>
          </div><br/>
          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon" id="" placeholder="Article Image Credit" style={{width:"20%"}}>Tweets</span>
            <input type="text" className="form-control" id="tweets" placeholder="" style={{width:this.state.inpWidth}} required></input>
          </div><br/>
          <button onClick={()=>this.handleTweets()} >Insert</button><br/>

          <div className="well well-sm " id='more'>
            <h5>Add More :- </h5>

            <button type='button' className="btn btn-default" style={{margin:'10px'}} id = '' onClick={this.addUrl}>Add Url</button>

            {/* <button type='button' className="btn btn-default" style={{margin:'10px'}} id = '' onClick={this.addContent}>Add Website Content</button> */}
            

          </div>

        <div style={{overflow: "auto"}}>
            <div className="col-md-4" style={{width: "30%"}}>
              <div className="thumbnail">
                <img src="" alt="authorImagePreview" id="authorImagePreview" style={{width: "100%", display:"none"}}/>
                <div className="caption">
                  <span className=""> <p>Author Image</p>
                  <input type="file" className="form-control btn btn-default btn-file" id="authorImage" name="filename" onChange={() => this.encodeImage('authorImage')}></input>
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-4" style={{width: "30%"}}>
              <div className="thumbnail">
                <img src="" alt="blogImagePreview" id="blogImagePreview" style={{width: "100%", display:"none"}}/>
                <div className="caption">
                  <span className=""> <p>Article Image</p>
                  <input type="file" className="form-control btn btn-default btn-file" id="blogImage" name="filename" onChange={() => this.encodeImage('blogImage')}></input>
                  </span>
                </div>
              </div>
            </div>
          </div>

      
    



    
<span>
                  <div className="form-group">
                        <label className="control-label col-md-3">Client:</label>
                        <div className="col-md-7">
                        <select
                            name="selectedClient"
                            onChange={this.handleOnClientChange}
                            value={this.state.selectedClient}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >
                <option value="">Select Client</option>
                            {this.state.clients.map(function (client, i) {

                              return (<option value={client.id}>{client.customerId} {client.companyName}</option>)
                            })}

                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="control-label col-md-3">Shared To (Client Contacts)</label>
                        <div className="col-md-7">

                        {this.state.contactPersonsClients.length > 0 ?
                             <Select
                             value={this.state.sharedToClient}
                             onChange={this.onSelectClients}
                             options={this.state.contactPersonsClients}
                             isMulti={true}
                           />
                            :  <input
                            name="subject"
                            disabled="true"
                            value='Select Client Above'
                            className="form-control ember-text-field ember-view"
                            type="text"
                          />}

                        
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="control-label col-md-3">Shared To (Team):</label>
                        <div className="col-md-7">

                        {this.state.employees.length > 0 ?
                             <Select
                             value={this.state.sharedTo}
                             onChange={this.onSelect}
                             options={this.state.employees}
                             isMulti={true}
                           />
                            :  ''}

                        
                        </div>
                      </div>
                      </span>
                      

                


          {/*<img id="authorImagePreview" className="" src="#" alt="authorImage" style={{width: "16%"}}/>
          <div className="input-group mb-3" style={{width:"80%"}}>
            <span type="text" className="input-group-addon " id="" style={{width:"20%"}}>
              <span type="text" id="" placeholder="Author Image" >Author Image</span>
            </span>
            <div className="form-control custom-file" style={{width:this.state.inpWidth}}>
              <input type="file" className="form-control" id="authorImage" name="filename" onChange={() => this.encodeImage('authorImage')}></input>
              <label className="custom-file-label" for="" id="authorImageLabel">Choose file ... only png</label>
            </div>
          </div><br/>*/}

          {/*<div className="input-group mb-3" style={{width:"80%"}}>
            <input type="text" className="input-group-addon" id="" placeholder="Blog Rating" style={{width:"20%"}}></input>
            <input type="text" className="form-control" id="blogRating" placeholder="*" style={{width:this.state.inpWidth}} required></input>
          </div><br/>*/}

          {/*<div className="input-group mb-3" style={{width:"80%"}}>
            <input type="text" className="input-group-addon" id="" placeholder="Author Rating" style={{width:"20%"}}></input>
            <input type="text" className="form-control" id="authorRating" placeholder="*" style={{width:this.state.inpWidth}} required></input>
          </div><br/>*/}
          <div ClassName="col-md-4" style={{width : "80%", position:"relative", height:"50px"}}>
          <SubmitButton clickHandler={this.save_to_dynamo}
                   isSubmitting={this.state.isSubmitting} />     </div>
        </div>


      </div>
    )
  }
}
export default AddDocResource
