import React, { Component } from "react";
import "../../style/panel.css";
import axios from 'axios';
import FileBase64 from 'react-file-base64';
import {v1 as uuidv1} from 'uuid';
import Khub_HomepageSidebar from "./Khub_HomepageSidebar";
import { apiBaseUrl20, apiBaseUrl21, s3BucketUrl } from "../../components/apiUtils";

export default class Khub_AddSlider extends Component{
    constructor(props){
        super(props)
        this.state={
            sliderType:'',
            sliderUrl:"",
            sliderImageUrl:"",
            countSlider:0
        }
        this.handleChanges = this.handleChanges.bind(this)
    }

    componentDidMount(){
        let sliderType = window.location.href.split('/').pop()

        this.setState({sliderType:sliderType.split('-')[1]})
        this.fetchSlider(sliderType.split('-')[1])
    }

    fetchSlider(type){
        let sliderArray=[]
        axios.get(apiBaseUrl21+'homepageSlider/list')
        .then(function (response){
            for(var i=0;i<response.data.length;i++){
                if(response.data[i].sliderType===type){
                    sliderArray.push(response.data[i])
                }
            }
            this.setState({countSlider:sliderArray.length})
        }).catch(function (error){
            console.log(error);
        })
    }

    handleChanges(e){
        let target = e.target;
        let name = target.name;

        this.setState({[name]:target.value});
    }
    
    getSlider(files){
        this.setState({banner:files[0]});
        this.fileUploadHandler(this,'sliderImageUrl')
    }

    fileUploadHandler = (that, docType) => {
        const id = uuidv1()
        const imageURL=s3BucketUrl+id+'.png';
        console.log('image url',imageURL)
        
        that.setState({ loading: true });
        axios.post(apiBaseUrl20+"uploadEditorImage",
          {
            "image": that.state.banner.base64,
            "imageName": that.state.banner.name,
            "id":id,
            "imageURL":imageURL
          })
          .then(function (response) {
            if (response.status === 200) {
              that.setState({ successupload: true });
              that.setState({ sliderImageName: that.state.banner.name });
              that.setState({ [docType]: imageURL });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }

    submitChanges(){
        var allData={
            sliderImageUrl:this.state.sliderImageUrl,
            sliderUrl:this.state.sliderUrl,
            sliderType:this.state.sliderType
        }
        // if(this.state.sliderType==='horizontal'){
        //     if(this.state.countSlider<5){
        //         submitSliderValue(allData)
        //     }
        //     else{
        //         alert('More then 5 slider is not allowed')
        //     }
        // }
        // else{
        //     if(this.state.countSlider<4){
        //         submitSliderValue(allData)
        //     }
        //     else{
        //         alert('More then 4 slider is not allowed')
        //     }
        // }
        // console.log('allData',allData)
        // function submitSliderValue(allData){
            axios({
                url:apiBaseUrl21+"homepageSlider/add",
                method:"POST",
                data:allData,
            }).then(function (response){
                if(response.status===200){
                    alert("Slider has been added")
                    window.location.reload()
                }
            })
        // } 
    }
    render(){
        return(
        <div>
            <div className="row">
                <div className='col-md-2 col-lg-2'><Khub_HomepageSidebar/></div>
                <div className="col-md-10 col-lg-10">
            <div className="container-fluid overflow-auto" style={{margin: "10px", height:'90vh',}}>
                <div style={{margin: "10px"}}><h3>Add Slider : </h3></div>

                <div className="container-fluid well well-sm" >
                <div class="input-group" style={{width:"80%"}}>
                    <span class="input-group-addon" style={{width:"20%"}}>Slider Url</span>
                    <input type="text" class="form-control" id="SliderUrl" placeholder="Enter Slider Url" name="sliderUrl" value={this.state.sliderUrl} onChange={this.handleChanges}></input>
                </div><br/>
                </div>

                <div className="form-group">
                <label className="control-label col-md-4" >Upload Slider Image</label>
                <div className="col-md-6"  >
                <FileBase64 multiple={ true } onDone={ this.getSlider.bind(this) }/></div>
                
                </div>
                <div className="col-md-4 col-lg-4"/>
                <div className="col-md-6 col-lg-6">
                {this.state.sliderImageUrl!==''?
                <img style={{width:'200px',height:'150px'}} src={this.state.sliderImageUrl} />
                :""
                }</div>
                <div ClassName="col-md-4" style={{width : "80%", position:"relative", height:"50px"}}>
                    <button type='button' class="btn btn-primary bottomright" style={{margin:'10px'}} id='save' onClick={()=>this.submitChanges()}>Add Slider</button>
                </div>
            </div></div>
        </div>
        </div>
        )
    }
}