import React, { Component } from "react";

import { Link } from "react-router-dom";
import BreadCrumbs from "../../BreadCrumbs";
import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1,apiIssueMaster } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { ChatFeed, Message } from 'react-chat-ui'
import SubmitButton from '../../common/SubmitButton';

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from 'underscore';
let context = null;
const data = [];
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px ',
    position: 'Absolute',
    zIndex: '30000',
    backgroundColor: 'none',
  }
};

export default class addIssueType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workEx: [],
      education:[],
      dependents:[],
      gender:"male"
    };
    context = this;
    this.openModal = this.openModal.bind(this);

    this.closeModal = this.closeModal.bind(this);
  }

  changeSliderState(orderNumber) {

    this.setState({ sliderState: orderNumber });

  }
  //function for changing the status button on click to open or close
  handleOnChange = (e) => {
    debugger;
    var value = e.target.value;

    var formName = e.target.name;

    this.setState({ [formName]: value });



    console.log(this.state);
  }

  handleOnChangePerson = (e, i, stateName) => {

    var list = this.state[stateName];

    list[i][e.target.name] = e.target.value;

    this.setState({ [stateName]: list });




  }

  addNewContact = (stateName) => {


    var newRow = {}



    if (stateName === 'workEx') {
      newRow = {
        previousCompany: "",
        jobTitle: "",
        fromDate: "",
        toDate: "",
        jobDescription: ""
      }

    } else if (stateName === 'education') {

      newRow = {
        schoolName: "",
        degree: "",
        fieldOfStudy: "",
        dateOfCompletion: "",
        additionalNotes: "",
        interests: ""
      }

    }
    else {
      newRow = {
        name: "",
        relationship: "",
        dateOfBirth: ""
      }
    }


    var list = this.state[stateName];
    list.push(newRow);
    this.setState({ [stateName]: list });



  }
  openModal(type) {
    this.changeDocType(type);
    this.setState({ modalIsOpen: true });
  }



  closeModal() {
    this.setState({ modalIsOpen: false });
  }


  componentDidMount() {
    const id = this.props.match.params.id;

    this.fetchQueryById(this, id);
    this.fetchThreadById(this, id);
    this.markAsRead(this, id);
  }


  markAsRead(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/read/" +
        id
      )
      .then(function (response) {


      })
      .catch(function (error) {
        console.log(error);
      });
  }


  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(
        apiBaseUrl2+"businus/customer/list/"+localStorage.spId

      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });



          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  fetchThreadById(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/listmail/" +
        id
      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        var threads = response.data == "" ? [] : _.sortBy(response.data, 'date').reverse();
        that.setState({ thread: threads });

        var documents = _.where(threads, { docType: "Document" });
        var proposals = _.where(threads, { docType: "Proposal" });
        var mails = _.where(threads, { docType: "Mail" });
        that.setState({ documents });
        that.setState({ proposals });
        that.setState({ mails });


      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getFiles(files) {
    this.setState({ selectedFile: files[0] });

  }

  getFormattedDate(millis) {

    return new Date(millis).toDateString();


  }

  toggleOpen = status => {
    if (status == undefined || status == "open") {
      status = "close";
    } else {
      status = "open";
    }
    //(this.setState({ entry: [...this.state.entry,{status:status} ] });)
    var newEntry = this.state.entry;
    newEntry.status = status;
    this.setState({ entry: newEntry });
    context.updateStatus(context, this.state.entry.id, status);
  };

  changeDocType(type) {

    this.setState({ docType: type });
  }




  sendMail() {
    var data = context.state;
    context.setState({isSubmitting:true})
    axios
      .post(
        apiIssueMaster+"businusquery/issue/master",
        {
          "title": data.title,
          "type":"TYPE",
          description:data.description,
          "by":localStorage.userName ,
          spId:localStorage.spId
        })
      .then(function (response) {
        debugger;
        alert("Success!");
        //  context.fetchThreadById(context,query.id);
        context.setState({isSubmitting:'done'})
      })
      .catch(function (error) {
        console.log(error);
        context.setState({isSubmitting:'done'})
      });
  }

  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    localStorage.setItem("MAILURL", url);


  }




  //updating the status button

  render() {
    const { sliderState } = this.state;
    const active = " active-button"
    const deactive = "";
    return (
      <div className="Div">
      <div className="row breadcrumb-row">
           <div className="col-md-10">
             <BreadCrumbs />
           </div>
           </div>
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
          <div className="dedicated-page">
          <div className="row-resource-head">
                  <h2 className="resource-head">Add a Type </h2>
                  </div>
            <div className="row ">
              <div className="col-md-12 info-column account-col2">
                <div id="field">
                  
                  <div className="container">
                    <div className="row">
                      <h4  className="account-head" > Basic info</h4>
                      <div className="col-md-8">


                    


                        <div className="form-group">
                          <label className="control-label col-md-4">
                            Title
                          </label>
                          <div className="col-md-8">
                            <input
                              id="ember301"
                              onChange={this.handleOnChange}
                              name="title"
                              value={this.state.title}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">
                           Description
                          </label>
                          <div className="col-md-8">
                            <textarea
                              id="ember301"
                              onChange={this.handleOnChange}
                              name="description"
                              value={this.state.description}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>

                       



                      </div>
                    </div>






                    


                    
                    
                  


                  

                        
                  </div>

                  <div className="text-left">
                  <SubmitButton clickHandler={this.sendMail}
                   isSubmitting={this.state.isSubmitting} /> 
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    );
  }
}

