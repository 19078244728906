import React, { Component } from "react";

import { Link } from "react-router-dom";
import $ from "jquery";
import UniquePage from "./editAccessControl";
import {getPrivilegeByName} from '../../privilegeUtils';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

const data = [];
export default class SidebarAccessControl extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []
     
    };
    
  }
  componentDidMount(){
    this.handleLine();
   // this.setState({title:this.props.match.params.productType});
  }
  handleLine(){

    $(function(){
      $("#tab1").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary-access');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
        $("#section").toggle();
      });
      });
      $(function(){
        $("#c2").click(function(e){
          $(e.currentTarget).parent().toggleClass('sidebar-secondary-access');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d2").toggle();
          $("#d10").toggle();
      });
      });
      $(function(){
        $("#c3").click(function(e){
          $(e.currentTarget).parent().toggleClass('sidebar-secondary-access');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d3").toggle();
          $("#d10").toggle();
        });
        });
      $(function(){
        $("#c4").click(function(e){
          $(e.currentTarget).parent().toggleClass('sidebar-secondary-access');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d4").toggle();
          $("#d10").toggle();
          });
          });
      $(function(){
        $("#c5").click(function(e){
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d5").toggle();
          $("#d10").toggle();
            });
            });
      $(function(){
        $("#c6").click(function(e){
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d6").toggle();
          $("#d10").toggle();
              });
              });
      $(function(){
        $("#c7").click(function(e){
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d7").toggle();
          $("#d10").toggle();
                });
                });
      $(function(){
          $("#c8").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d8").toggle();
                  });
                  });  
  
  }
  
  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
    
            <div className="sidebar">
              <div id="sidebar1">
                <div className="sidebar-elementhead"><Link className="sidebar-link" to="/home">Access Control</Link></div>
                {/*<div className="sidebar-sectionName">
    Products</div>*/}
                <div className="oneTab">
              
                  <div className="sidebar-subelement" id="d1"><i className="fa fa-envelope" />View<i className="fa fa-angle-right " /></div>
                </div>
                <div className="oneTab">
                  <div className="sidebar-element" id="c3">
                    <i className="fa fa-users" />
                    Clients
                    <i className="fa fa-angle-down " />
                  </div>
                  <div className="sidebar-subelement" id="d3">
                 
                  
                     <div>
                    <Link className="sidebar-link" to={"/access-control/client/view-controls"} style={{marginLeft:'-5px'}}>
                      <i className="fa fa-e" />
                    View Client Controls
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                  </div>
                </div>

                <div className="oneTab">
                  <div className="sidebar-element" id="c4">
                    <i className="fa fa-briefcase" />
                    Employees
                    <i className="fa fa-angle-down " />
                  </div>
                  <div className="sidebar-subelement" id="d4">
                 
                  
                      <div>
                    <Link className="sidebar-link" to={"/access-control/employee/view-controls"} style={{marginLeft:'-5px'}}>
                      <i className="fa fa-e" />
                    View Employee Controls
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                  </div>
                </div>
               
               
             
              
               
              </div>
            </div>
         
    );
  }
}
