import React, { Component } from "react";
import axios from 'axios';
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import { Link } from "react-router-dom";
import BreadCrumbs from "../../BreadCrumbs";
import $ from "jquery";
import 'react-table/react-table.css'
import _ from 'underscore';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

//defining the context initially assigning it a null value

let context = null;

//defining the heads of the table, the accessors 



export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
    counters:{},
    sendQueryCounter:{},
    count:'',
 
    };

    context = this;
  }
  componentDidMount(){
    localStorage.setItem("email","gautammegha2@gmail.com");
  this.countQueries();

}


  //function for toggling the status button

  


  //function for fetching queries from backend 
  
  countQueries(){

    let paramEmail = localStorage.userEmail;
    if(localStorage.isSp=='true'){
      paramEmail='all'
    }
    axios.get(apiBaseUrl2+"businusquery/listMailByType/all/all/"+localStorage.spId+"/"+paramEmail)
   
    .then(function (response) {
      console.log(response.data);
      var threads = response.data ;
      var documents = _.where(threads, { docType: "TEAM-DOCS" });
      var proposals = _.where(threads, { docType: "TEAM-PROPOSALS" });
      var mails = _.where(threads, { docType: "TEAM-MAILS" });
      var mails2 = _.where(threads, { docType: "mail" });
      var updates = _.where(threads, { docType: "TEAM-UPDATES" });
     
      context.setState({countDoc:documents.length});
      context.setState({countProposals:proposals.length});
      context.setState({countMails:mails.length});
      context.setState({countUpdates:updates.length});
      context.setState({counters:response.data.queries});
      context.setState({sendQueryCounter:response.data.category});
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }

  //function for changing the status button on click

  


  render() {
      const {counters,sendQueryCounter} = this.state;
    return (
      <div >
      
      <div className="row breadcrumb1-row" style={{marginLeft:'0px'}} >
      <div className="col-md-12"><BreadCrumbs/></div>
    
  </div>
      <div className="DashboardDiv" style={{paddingLeft:'15px',overflowY:'scroll'}} >
        
      
      <div className="row ">
      <h2 className="dashboard-head">Dashboard</h2>
      </div>
      
          
            <div className="row">
        <div className="col-md-6">
          <Link to="/team-communication/view/mail">
          <div className="card-counter primary">
          <div className="icon-carddd"> <i className="fa fa-envelope" /></div>
            <span className="count-numbers">{this.state.countMails}</span>
            <span className="count-name">Messages Counter</span>
          </div>
          </Link>
        </div>
        <div className="col-md-6">
          <Link to="/team-communication/send/mail">
          <div className="card-counter primary">
            <i className="fa fa-plus" />
            
            <span className="count-name">Send Message</span>
          </div>
          </Link>
        </div>
       
       
        
        </div>

        
        <div className="row">
        
            </div>
            <div className="row">
        <div className="col-md-6">
          <Link to="/team-communication/view/document">
          <div className="card-counter primary">
          <div className="icon-carddd"> <i className="fa fa-folder" /></div>
            <i className="fa fa-ticket" />
            <span className="count-numbers">{this.state.countDoc}</span>
            <span className="count-name">Documents Counter</span>
          </div>
          </Link>
        </div>
        <div className="col-md-6">
          <Link to="/team-communication/send/document">
          <div className="card-counter primary">
            <i className="fa fa-plus" />
            
            <span className="count-name">Send Documents</span>
          </div>
          </Link>
        </div>
       
       
        </div>
        </div>
       
       
       
         
        
     

        


    
            </div>
          
       
    );
  }
}
