import React, { Component } from "react";
import "../../style/panel.css";
import { Link } from "react-router-dom";
import GuideBlogSidebar from "./GuideBlogSidebar";

export default class GuideBlogDashoard extends Component{
    constructor(props){
        super(props)
        this.state={
            learningType:''
        }
    }

    componentDidMount(){
        let url = window.location.href.split('/')
        let index = url.length-2
        this.setState({learningType:url[index]})
    }


    render(){
        return(
            <div>
            <div className='row'>
            <div className='col-md-2 col-lg-2'> <GuideBlogSidebar learningType={this.state.learningType}/> </div>

            <div className='col-md-10 col-lg-10'>
                <div className='mainScreenKhub'>
                <div className='container'>
                    <div className='row'>
                    <div>
                     <div className='tab-row1'>
                        <h2 className="tab-row-head">Guides</h2>
                        <div className="row">

                        <div className="col-md-3">
                        <div className="tab text-center">
                        <Link to={"/knowledgeHub/businus-school/"+this.state.learningType+"/add-guides-chapter"}>
                        <img className="tab-image1" id="tab1" 
                                    style={{ marginLeft: "-3px" }}
                                    src={require("../../images/home.png")}
                                />
                        <h2 className="tab-text-main"> Add Guides Chapter</h2></Link>
                        </div>
                        </div> 

                        <div className="col-md-3">
                        <div className="tab text-center">
                        <Link to={"/knowledgeHub/businus-school/"+this.state.learningType+"/add-guides-blog"}>
                        <img className="tab-image1" id="tab1" 
                                    style={{ marginLeft: "-3px" }}
                                    src={require("../../images/home.png")}
                                />
                        <h2 className="tab-text-main"> Add Guides Blog</h2></Link>
                        </div>
                        </div> 
                        
                        <div className="col-md-3">
                        <div className="tab text-center">
                        <Link to={"/knowledgeHub/businus-school/"+this.state.learningType+"/view-guides-chapter"}>
                        <img className="tab-image1" id="tab1" 
                                    style={{ marginLeft: "-3px" }}
                                    src={require("../../images/home.png")}
                                />
                        <h2 className="tab-text-main">View Guides Chapter</h2></Link>
                        </div>
                        </div> 

                        <div className="col-md-3">
                        <div className="tab text-center">
                        <Link to={"/knowledgeHub/businus-school/"+this.state.learningType+"/view-guides-blog"}>
                        <img className="tab-image1" id="tab1" 
                                    style={{ marginLeft: "-3px" }}
                                    src={require("../../images/home.png")}
                                />
                        <h2 className="tab-text-main">View Guides Blog</h2></Link>
                        </div>
                        </div> 
                        </div>
                    </div>   
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        )

    }
}