import React, { Component } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import Khub_HomepageSidebar from './Khub_HomepageSidebar';
import BreadCrumbs from '../../components/BreadCrumbs';
import { apiBaseUrl21 } from "../../components/apiUtils";

export default class Khub_PageSlider extends Component{
    constructor(){
        super()
        this.state={
            resData:[],
        }
    }

    componentDidMount(){
        let id = this.props.match.params.id
        this.fetchQueries(this,id)
    }

    fetchQueries(that,id){

        axios.get(apiBaseUrl21+"homepageSlider/get/"+id)
        .then(function (response) {
          that.setState({resData:response.data});
        })
        .catch(function (error) {
          console.log(error);
        });
      }

    deleteSlider(id){
        if (window.confirm("Are you sure you want to delete this slider ?")) {
            axios({
              url: apiBaseUrl21+'homepageSlider/get/' + id,
              method: 'Delete',
            }).then((response) => {
            if(response.status===200){
                alert("Blog Deleted");
                window.history.back()
            }
            });
        }
    }

      render(){
          return(
              <div className="row">
                  <div className='col-md-2 col-lg-2'><Khub_HomepageSidebar/></div>
                  <div>
                    <div className="col-md-10 col-lg-10">
                        <div className="col-md-12 col-lg-12">
                        <div className="container-fluid overflow-auto" style={{margin: "10px", height:'90vh',}}>
                        <div className="row breadCrumb1-row">
                            <h4> Slider
                            <Link className="pull-right" style={{color:'#fff',marginRight:'3%'}} to={"/knowledgeHub/businus-school/homepage/edit-vertical-slider/"+this.state.resData.id}><i className="fa fa-edit"></i>EDIT</Link>
                            <p className="pull-right" style={{color:'#fff',marginRight:'3%',marginTop:'-5px',marginBottom:'0',cursor:'pointer'}} onClick={()=>this.deleteSlider(this.state.resData.id)}> Delete</p>
                            </h4>
                        </div>

                        <div className="container-fluid well well-sm" >
                        <div class="input-group" style={{width:"80%"}}>
                            <span class="input-group-addon" style={{width:"20%"}}>Slider Url</span>
                            <div class="form-control" > {this.state.resData.sliderUrl} </div>
                        </div><br/>
                        </div>

                        <div className="container-fluid well well-sm" >
                        <div class="input-group" style={{width:"80%"}}>
                            <span class="input-group-addon" style={{width:"20%"}}>Image URL</span>
                            {this.state.resData.sliderImageUrl!==''?
                        <img style={{width:'200px',height:'150px',marginLeft:'20px'}} src={this.state.resData.sliderImageUrl} />
                        :""
                        }
                        </div><br/>
                        </div>
                    </div></div>
                    </div>
                  </div>
              </div>
          )
      }
}