import React from 'react';
import axios from 'axios';
import ReactTable from 'react-table';
import GuideSidebar from './GuideSidebar';
import BreadCrumbs from '../../components/BreadCrumbs';


export default class ListGuideName extends React.Component{
    constructor(props){
        super(props)
        this.state={
            resdata:[],
            learningType:''
        }
    }

    componentDidMount(){
        let url = window.location.href.split('/')
        let index = url.length-2
        this.setState({learningType:url[index]})
        this.fetchAllGuide(url[index])
    }

    fetchAllGuide(learningType){
        let temp=[]
        axios({
            url:'https://q7ay5k66xi.execute-api.us-east-1.amazonaws.com/dev/businus/guide/list',
            method:'get',
            crossDomain:true,
            responseType:'json',
        }).then((response)=>{
            for (var i=0;i<response.data.length;i++){
                if(response.data[i].learningType===learningType){
                    temp.push(response.data[i])
                }
            }
            this.setState({resdata:temp})
        })
    }

    redirectToGuideBlog(id){
        localStorage.setItem('guideBlogFilterId',id)
        window.location.replace('/knowledgeHub/businus-school/'+this.state.learningType+'/guides-blog-dashboard')
    }

    deleteGuideName(id){
        if (window.confirm("Are you sure you want to delete this guide. All data under this guide will be removed ?")) {
            axios({
              url: 'https://q7ay5k66xi.execute-api.us-east-1.amazonaws.com/dev/businus/getGuideById/' + id,
              method: 'Delete',
            }).then((response) => {
            if(response.status===200){
                alert("Guide Deleted");
                window.location.reload()
            }
            });
      
          }
    }

    editGuideName(id){
        window.location.replace('/knowledgeHub/businus-school/'+this.state.learningType+'/edit-guides/'+id)
    }

    render(){
        const columns =[{
            Header: <div className="form-textHead cell-head">Actions</div>,
            accessor: d => { return {id:d.id}},
            id:"id",
            Cell: props =>  <div className='' style={{}}>
                <button type="button" style={{margin: "3px"}} className="btn btn-primary" onClick={e=>this.editGuideName(props.value.id)}><span className="fas fa-edit"></span></button>
                <button type="button" style={{margin: "3px"}} className="btn btn-danger" onClick={e=>this.deleteGuideName(props.value.id)}><span className="fas fa-trash"></span></button>
              </div>,
      
            minWidth:150
          },
          {
            Header: <div className="form-textHead cell-head">Guide Id</div>,
            accessor: 'guideNameId',
            filterMethod: (filter, row) => {debugger;   return row.catagory.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:120
          }, 
          {
            Header: <div className="form-textHead cell-head">Guide Name</div>,
            accessor: d => { return {id:d.id,guideName:d.guideName}},
            id:"id",
            Cell: props => <span className='form-text cell' style={{cursor:'pointer'}} onClick={()=>this.redirectToGuideBlog(props.value.id)}>{props.value.guideName}</span>,
            minWidth:100
          },
           {
            Header:<div className="form-textHead cell-head"> Guide Descriprion </div>,
            accessor: 'guideDescription',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:220
          },
        //   {
        //    Header:<div className="form-textHead cell-head"> Tag </div>,
        //    accessor: 'tags',
        //    Cell: props => <span className='form-text cell'>{props.value}</span>,
        //    minWidth:220
        //  }
        ]
        return(
            <div>
                <div className='row'>
                    <div className='col-md-2 col-lg-2'><GuideSidebar learningType={this.state.learningType}/></div>
                    <div className='col-md-10 col-lg-10'>
                        <div className='DIV'>
                        <div className='row breadcrumb-row'>
                            <div className='col-md-10' style={{marginTop:'10px'}}><BreadCrumbs/> </div>
                        </div>
                        <div className='row'>
                            <ReactTable data={this.state.resdata} columns={columns} style={{height:'85vh',overflow:"scroll"}} filterable/>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}