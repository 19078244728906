import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./profile.css";

export default class ProfileHomepage extends Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div>
            <h3 className="professional-profile-hp-h">
              {" "}
              Professional Profile{" "}
            </h3>
          </div>
          <div className="col-md-6">
            <div className="profile-card">
              <Link to="/add-profile">
                <img
                  className="professional-profile-hp-d-img"
                  src={
                    "https://businus-files-live.s3.amazonaws.com/default-image/user-profile.jpg"
                  }
                />
                <div>
                  <h4 className="professional-profile-hp-card1-t"> Profile </h4>
                </div>
              </Link>
            </div>
          </div>

          <div className="col-md-6">
            <div className="profile-card">
              <Link to="/profile/mybooking/list">
                <img
                  className="professional-profile-hp-d-img"
                  src={
                    "https://businus-files-live.s3.amazonaws.com/default-image/bookingImage.png"
                  }
                />
                <div>
                  <h4 className="professional-profile-hp-card2-t">
                    {" "}
                    My Booking{" "}
                  </h4>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
