import React, { Component } from "react";

import BreadCrumbs from '../../BreadCrumbs';
import { Link } from "react-router-dom";

import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1,apiBaseUrl5, apiIssueMaster } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { ChatFeed, Message } from 'react-chat-ui'
import {getPrivilegeByName} from '../../privilegeUtils';
import {Multiselect} from 'multiselect-react-dropdown';
import ReactExport from "react-export-excel";


import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from 'underscore';
import AssignmentUserCommunication from "../../common/AssignmentUserCommunication";
import { memberExpression } from "@babel/types";
let context = null;
const data = [];
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px ',
    position: 'Absolute',
    zIndex: '30000',
    backgroundColor: 'none',
  }
};


export default class pageIssues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      subject: "",
      message: "",
      priority:"",
      id:"",
      attachment: "NA",
      thread: [],
      sendTo: "",
      documents: [],
      proposals: [],
      mails: [],
      entry: {
        checklists:[],
        selectedOrganisation:'',
        selectedType:'',
        selectedGroup:'',
        selectedStatus:'',
        tags:[],
      },
      statusList:[],
      assignmentDocuments:[],
      assignmentComments:[],
      paymentStatusList:[],
      progressStatusList:[],
      templates:[],
      issues:[],
      expenses:[],
      progressLogs:[],
      modalIsOpen: false,
      docType: "MAIL",
      sliderState: 1,//sta//state for storing the response from the backend,
      superSliderState: "clients"
    };
    context = this;
    this.openModal = this.openModal.bind(this);

    this.closeModal = this.closeModal.bind(this);
  }

  changeSliderState(orderNumber) {

    this.setState({ sliderState: orderNumber });

  }

  //function for changing the status button on click to open or close
  onChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);


    console.log(this.state);
  }


  handleOnTemplateChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;
    state['selectedUpdate'] = e.target.value;

    this.setState(state);


    console.log(this.state);
  }


  onChangeStatus = (e) => {

    const entry = this.state.entry
    entry[e.target.name] = e.target.value;
    this.setState({entry:entry});
    

    this.updateStatus();



  }


  openModal(type) {
    this.changeDocType(type);
    this.setState({ modalIsOpen: true });
  }



  closeModal() {
    this.setState({ modalIsOpen: false });
  }


  componentDidMount() {
    const id = this.props.match.params.id;

    this.setState({id});
    this.fetchQueryById(this, id);
   // this.fetchThreadById(this, id);
   this.fetchTemplatesForUpdate(this);
   // this.fetchTemplates(this);
    this.fetchStatus(this);
   
    //this.markAsRead(this, id);
  }


getColor(status){
var paymentStatus= _.findWhere(this.state.paymentStatusList,{paymentStatus:status});
return paymentStatus!=undefined ? paymentStatus.paymentStatusColor:'blue'
}
getProgressColor(status){
  var progressStatus= _.findWhere(this.state.progressStatusList,{progressStatus:status});
  return progressStatus!=undefined ? progressStatus.progressStatusColor:'blue'
  }
  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(
        apiIssueMaster+"businusquery/getIssueById/" + id )
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  delete = (id) => {
    axios
      .delete(
        apiBaseUrl1 +"businusquery/getProjectById/" +
          id
      )
      .then(function(response) {
        debugger;
        console.log(response.data);
       // that.setState({ entry: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  getFiles(files) {
    this.setState({ selectedFile: files[0] });

  }

  getFormattedDate(millis) {

    return new Date(millis).toDateString();


  }


  addNewCheckList(){

    var entry = this.state.entry;
    
    if(entry.checklists!=undefined){
      entry.checklists.push({
          name:"",
          isDone:false,
          description:""

      });
    }
    
    else{
      entry['checklists'] = [];
      entry.checklists.push({
        name:"",
        isDone:false,
        description:""
      });
    }


    this.setState({entry});


  }

 

  

  onCheckboxClick = (e,i)=>{
    var query = context.state.entry;


    
    query.checklists[i].isDone = !query.checklists[i].isDone;

    

    axios
    .post(
      apiBaseUrl1+"businus/project/update/"+context.state.id,
      {

       status:query.status!=undefined ? query.status : "open",
       paymentStatus:query.paymentStatus!=undefined ? query.paymentStatus : 'Invoice Pending',
       checklists:query.checklists,
      })
    .then(function (response) {
      
      context.setState({entry:query});
    })
    .catch(function (error) {
      console.log(error);
    });

    

  }

  addNewWorkflow = (work)=>{
    debugger;
    var query = context.state.entry;


    let workflows = query.workflow;


    if(workflows){
       workflows.push(work);
    }
    else{
      workflows = [work];
    }
    

    

    axios
    .put(apiIssueMaster+"businusquery/issue/"+context.state.id,
      {

      workflow:workflows
      })
    .then(function (response) {
      
      context.setState({entry:query});
      alert("Workflow added");
    })
    .catch(function (error) {
      console.log(error);
    });

    

  }


  updateStatus =  (type,value) => {
    var query = context.state.entry;

   

    axios
      .put(
        apiIssueMaster+"businusquery/issue/"+context.state.id,
        {

         status:query.status ,
         
         
        })
      .then(function (response) {
        debugger;
        alert("success");
        //context.fetchThreadById(context, query.id);
      })
      .catch(function (error) {
        console.log(error);
      });
    
  }
  toggleOpen = status => {
    if (status == undefined || status == "open") {
      status = "close";
    } else {
      status = "open";
    }
    //(this.setState({ entry: [...this.state.entry,{status:status} ] });)
    var newEntry = this.state.entry;
    newEntry.status = status;
    this.setState({ entry: newEntry });
    context.updateStatus(context, this.state.entry.id, status);
  };

  changeDocType(type) {

    this.setState({ docType: type });
  }

  onSelect(optionsList, selectedItem) {

    context.setState({team:optionsList});

    console.log(optionsList,selectedItem);
}

onRemove(optionList, removedItem) {
  context.setState({team:optionList});
}


fetchTemplatesForUpdate(that,source){

  axios.get(apiBaseUrl1 +"businus/template/list/Update")
  .then(function (response) {
    console.log(response.data);
    that.setState({templates:response.data});
  })
  .catch(function (error) {
    console.log(error);
  });

}


fetchStatus(that,status){

  axios.get(apiIssueMaster+"businusquery/issue/master/STATUS")
  .then(function (response) {
    console.log(response.data);
let tempList = response.data;
       tempList.unshift({title:'Select status'});
      that.setState({statusList:tempList});
     
  
    
  })
  .catch(function (error) {
    console.log(error);
  });

}


  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    localStorage.setItem("MAILURL", url);


  }


  
  addNewWorkflow = (work)=>{
    debugger;
    var query = context.state.entry;


    let workflows = query.workflow;


    if(workflows){
       workflows.push(work);
    }
    else{
      workflows = [work];
    }
    

    

    axios
    .put(
      apiIssueMaster+"businusquery/issue/"+context.state.id,
      {

      workflow:workflows
      })
    .then(function (response) {
      
      context.setState({entry:query});
      alert("Workflow added");
    })
    .catch(function (error) {
      console.log(error);
    });

    

  }


  //updating the status button

  render() {
    const { sliderState, superSliderState,entry } = this.state;
    const active = " active-button"
    const deactive = "";
    return (
      <div className="Div">
        <div className="row breadcrumb-row">
        <BreadCrumbs /> 
     <h4> {entry.parentId!="0" ?  <button className="download-button-wrapper"> <Link style={{marginRight:'50px'}} className="pull-right" to={"/assignment/view-assignment/"+entry.parentId}><i className="fa fa-plus"></i>Created From</Link></button> : ''}
     {getPrivilegeByName('Assignment').canEdit == true ? <button className="download-button-wrapper" style={{marginLeft:'20px',marginRight:'20px'}}> <Link style={{marginRight:'50px'}} className="pull-right" to={"/issues/edit-issues/"+entry.id}><i className="fa fa-edit"></i>Edit</Link></button>:''}
        {getPrivilegeByName('Assignment').canDelete == true ?   
      <button className="download-button-wrapper">  <div style={{marginRight:'50px'}} className=" pull-right" onClick={()=>this.delete(entry.issueId)}><i className="fa fa-trash"></i>Delete</div></button>:''}
       </h4>
     
       </div>
      
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
          <div className="dedicated-page">
            <div className="header-div2 ">
              <div
                className="bar"
                style={{ borderTop: "#1E5B81", color: "blue" }}
              />
              <div className="row">
                <div className="col-md-9">
                <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Issue Id:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.issueId}</h4>
                                </div>
                              </div>

                <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Due Date:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.dueDate}</h4>
                                </div>
                              </div>


                          
                             
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Priority:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.priority}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Overview:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <p style={{padding:'20px'}} className="detail-fields max-200"><div className="" dangerouslySetInnerHTML={{ __html: entry.overview}}></div></p>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Team Members:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.team!=undefined ? entry.team.map((member)=>{

return (<span>{member.name} , <br/></span>)

}) : ''
}</h4>
                                </div>
                              </div>
                   

                             

                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Type:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.selectedType.title}</h4>
                                </div>
                              </div>
                              
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Group:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.selectedGroup.title}</h4>
                                </div>
                              </div>

                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Status:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.selectedStatus.title}</h4>
                                </div>
                              </div>
                             
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Visiblity:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.visibleToClient}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                   Client:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.selectedClient!=undefined ? entry.selectedClient.name : ''}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                   Organisation:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.selectedOrganisation!=undefined ? entry.selectedOrganisation.name : ''}</h4>
                                </div>
                              </div>
                            
                             <div className="form-group">
                        <label className="control-label col-md-3">Tags</label>
                        <div className="col-md-7">
                        <div  className=" ember-text-field ember-view" style={{display:"inline-flex"}}>{this.state.entry.tags!=undefined ? this.state.entry.tags.map((client)=>{

return (


   <h5 className="tags">{client.label},&nbsp;</h5>
)

}) :''}</div>
     
                       
          
                        
                        </div>
</div>

                           
                            


                  


                  

                 
                




                 
                </div>


                <div className="col-md-3">
               
                 
                </div>
              </div>
            </div>

         <div className="multidiv">
          {this.state.id!="" ?   <AssignmentUserCommunication
            id={this.state.id}
            isDedicatedPage={false}
            addNewWorkflow={this.addNewWorkflow}
            entry={this.state.entry}
            type='issues'
            onCheckboxClick = {this.onCheckboxClick}
            addNewChecklist= {this.updateStatus}
            /> :""}


             </div>
          </div>
        </div>
      </div>
    );
  }
}

