import React, { Component } from "react";

import BreadCrumbs from '../../BreadCrumbs';
import { Link } from "react-router-dom";
import axios from 'axios';
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import CsvDownloader from 'react-csv-downloader'
import ReactTable from 'react-table';
import $ from "jquery";
import _ from 'underscore';

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

const data = [];


let context="null";
const columnsForExcel = [
  {
    id: 'mailId',
    displayName : "Mail ID",

  },
  {
    id: 'assignmentId',
    displayName : "Assingment ID",

  },
  {
    displayName : "Client",
    id: "client",
   
  },
  {
    displayName : "Sender Mail",
    id: "email",
   
  },
  {
    displayName : "Subject",
    id: "subject",
   
  },
  {
    displayName : "Message",
    id: "message",
   
  },
  {
    displayName : "Attachment",
    id: "attachmentName",
   
  },
  
  {
   
    id: "date",
    displayName : "Date",
  },
  {
    displayName : "Time",
    id: "time",
  
  }
];
//defined all the columns and fetched the data for every cell.
const columns = [

  
  {

    Header: props => <span className="form-textHead cell-head">Id</span>,
    accessor: d => {return {mailId:d.mailId,id:d.id,docType:d.docType}},
    filterMethod: (filter, row) =>
             { return row.name.name.toLowerCase().includes(filter.value.toLowerCase()) }
                     
                  ,
                
    id:"mailId",
    Cell: props => 
    <Link className="link" to={"../page/"+props.value.docType+"/"+props.value.id}><span>{props.value.mailId}</span></Link>,
    minWidth:180
    },
  {

    Header: props => <span className="form-textHead cell-head">Assigned To</span>,
    accessor: d => {return {assignmentId:d.assignmentId,id:d.queryId}},
    filterMethod: (filter, row) =>
             { return row.name.name.toLowerCase().includes(filter.value.toLowerCase()) }
                     
                  ,
                
    id:"assignmentId",
    Cell: props => 
    (props.value.assignmentId=='NA' ? 'NA' : <Link className="link" to={"/assignment/view-assignment/"+props.value.id}><span>{props.value.assignmentId}</span></Link>
    ),
    minWidth:180
    },

    {
      Header: props => <span className="form-textHead cell-head">Sent To</span>,
      accessor: 'sendTo',
      filterMethod: (filter, row) =>
                 { debugger; return row.sendTo.find(item=>item.email.toLowerCase().includes(filter.value.toLowerCase())) }
                         ,
      Cell: props => <span className='form-text cell'>{typeof props.value =="object" ? (props.value!=undefined ? props.value.map(item=>item.email).join(', ') : 'NA') : props.value  }</span>,
      minWidth:240
    },
{
  Header: props => <span className="form-textHead cell-head">Client</span>,
  accessor: 'client',
  filterMethod: (filter, row) =>
             { return row.client.toLowerCase().includes(filter.value.toLowerCase()); },
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:240
},
{
  Header: props => <span className="form-textHead cell-head">Subject</span>,
  accessor: 'subject',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:240
},
{
  Header: props => <span className="form-textHead cell-head">Body</span>,
  accessor: 'message',
  Cell: props => <span className='form-text cell' dangerouslySetInnerHTML={{__html:props.value}}></span>,
  minWidth:240
},
{

  Header: props => <span className="form-textHead cell-head">Attachments</span>,
  accessor: d => {return {id:d.queryId,value:d.attachment,docType:d.docType}},
  filterMethod: (filter, row) =>
           { return row.name.name.toLowerCase().includes(filter.value.toLowerCase()) }
                   
                ,
              
  id:"attachment",
  Cell: props => <span className='form-text cell'>{props.value=='NA' ? 'NA':
  <Link className="link" to={"../page/"+props.value.docType+"/"+props.value.id}><span>View Attachments</span></Link>}</span> ,
  minWidth:180
  },
{
  Header: props => <span className="form-textHead cell-head">By</span>,
  accessor: 'by',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:170
 
},
{
  Header: props => <span className="form-textHead cell-head">Date of Creation</span>,
  accessor: d => {return {date:d.date,id:d.id}},
  filterMethod: (filter, row) =>
           { return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase()) }
                   
                ,
  id:"teamMembers",
  Cell: props => 
  new Date(props.value.date).toDateString(),
  minWidth:180
 
},
{
  Header: props => <span className="form-textHead cell-head">Time of Creation</span>,
  accessor: d => {return {date:d.date,id:d.id}},
  filterMethod: (filter, row) =>
           { return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase()) }
                   
                ,
  id:"teamMembers",
  Cell: props => 
  new Date(props.value.date).toLocaleTimeString()
  ,
  minWidth:280
 
},



]
export default class viewMail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      menu1:[
       



 ]
 
    };
    context = this;
  }
  componentDidMount(){

  let mailType = this.props.match.params.mailType;
  //mailType we get is "mail" but we want to capitalize first letter i.e Mail or Update or Document
  mailType = mailType.charAt(0).toUpperCase() + mailType.slice(1);


  if(mailType=="Mail"){
    mailType="TEAM-MAILS";
  }
  else{
    mailType="TEAM-DOCS";
  }


  this.fetchQueries(this,mailType)

}

generateExcelData = data => {
    
  let excelData = [];
  _.each(data, function(d) {

    
    let row = {
    
      
     
        mailId: d.mailId,
        assignmentId:d.assignmentId,
        email:d.email,
        client:d.client,
        name:d.name,
        status:d.status,
        subject:d.subject,
        sendTo:d.sendTo,
        
        docType:d.docType,
        message:d.message.replace(/<[^>]*>/g, ''),
      date: new Date(d.date).toDateString(),
      time: new Date(d.date).toLocaleTimeString()
   
   
    
    }

     
    excelData.push(row);
    console.log(excelData);
  });
  this.setState({ excelData });
};

getCommaSeparated=(teamMember,name)=>{
  console.log(teamMember)
 let names= _.map(teamMember, function(d){
 return d[name];
 })
 
 return names.join(";")
 }
componentWillReceiveProps(nextProps){


  if(this.props!=nextProps){

    this.setState({menu1:[]});
  let mailType = nextProps.match.params.mailType;
  //mailType we get is "mail" but we want to capitalize first letter i.e Mail or Update or Document
  mailType = mailType.charAt(0).toUpperCase() + mailType.slice(1);

  if(mailType=="Mail"){
    mailType="TEAM-MAILS";
  }
  else{
    mailType="TEAM-DOCS";
  }
  this.fetchQueries(this,mailType)
  }

}


  toggleOpen = id => {
    var list  =this.state.menu1;
    var index = _.findIndex(list,function(entry){return entry.id==id});
    
    list[index].status =  (list[index].status == "open" || list[index].status==undefined) ? "close" : 'open';
    
    var newList = JSON.parse(JSON.stringify( list ));
    context.setState({menu1:newList});
    context.updateStatus(context,id,list[index].status);
    
    
    
      };

  
  fetchQueries(that,mailType){

    let paramEmail = localStorage.userEmail;
    if(localStorage.isSp=='true'){
      paramEmail='all'
    }

    axios.get(apiBaseUrl2+"businusquery/listMailByType/all/"+mailType+"/"+localStorage.spId+"/"+paramEmail)
    .then(function (response) {
      console.log(response.data);
      that.setState({menu1:_.sortBy(response.data,"date")});
      that.generateExcelData(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }

  

  render() {
    return (
      <div>
       
      
      <div className="Div">
      <div className="row breadcrumb-row">
          <div className="col-md-10"><BreadCrumbs/></div>
          <div className="col-md-2">
            <div className=" download-button">
      <CsvDownloader text="Download in .csv format" datas={this.state.menu1} filename="Documents.csv" /></div>
      </div>
      </div>
            <div className="row">
          
              <Row>
                <Col>
                  <div className="card">

                  <ReactTable className="myTable" resolveData={data => data.map(row => row)}  data={this.state.menu1} columns={columns} filterable />
                  


                    
                       
                      
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          </div>
    );
  }
}
