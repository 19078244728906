import Embed from "@editorjs/embed";

import Paragraph from "@editorjs/paragraph";
import List from "@editorjs/list";
// import Warning from '@editorjs/warning'

import Header from "@editorjs/header";
import Marker from "@editorjs/marker";

import axios from "axios";
// import Delimiter from '@editorjs/delimiter'

// import SimpleImage from '@editorjs/simple-image'

export const EditorjsTool = {
  embed: {
    class: Embed,
    config: {
      services: {
        youtube: true,
        coub: true,
        twitter: true,
        codeopen: {
          height: 400,
          width: 600,
        },
      },
    },
  },
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
  },
  list: {
    class: List,
    inlineToolbar: true,
  },

  header: {
    class: Header,
    inlineToolbar: true,
  },
  marker: {
    class: Marker,
    inlineToolbar: true,
  },
};
