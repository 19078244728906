import React, { Component } from "react";
import axios from 'axios';
import ReactTable from 'react-table';
import { Link } from "react-router-dom";
import Khub_HomepageSidebar from './Khub_HomepageSidebar';
import BreadCrumbs from '../../components/BreadCrumbs';
import { apiBaseUrl21 } from "../../components/apiUtils";

export default class Khub_ViewSlider extends Component{
    constructor(props){
        super(props)
        this.state={
            sliderArray:[],
            countSlider:0,
        }
    }
    componentDidMount(){
        let sliderType = window.location.href.split('/').pop()

        this.setState({sliderType:sliderType.split('-')[1]})
        this.fetchSlider(this,sliderType.split('-')[1])
    }
    fetchSlider(that,type){
        let sliderArray=[]
        let counter=0
        axios.get(apiBaseUrl21+'homepageSlider/list')
        .then(function (response){
            for(var i=0;i<response.data.length;i++){
                if(response.data[i].sliderType===type){
                    sliderArray.push(response.data[i])
                    if(response.data[i].displaySlider){
                        counter++
                    }
                }
            }
            
            that.setState({sliderArray:sliderArray,countSlider:counter})
        }).catch(function (error){
            console.log(error);
        })
    }

    addSliderToHomepage(displaySlider,id,sliderType){
        let allData ={
            displaySlider:!displaySlider
        }
        if(sliderType==='horizontal'){
            if(this.state.countSlider<5){
                submitSliderValue(allData)
            }
            else{
                alert('More then 5 slider is not allowed')
            }
        }
        else{
            if(this.state.countSlider<4){
                submitSliderValue(allData)
            }
            else{
                alert('More then 4 slider is not allowed')
            }
        }
       
        function submitSliderValue(allData){
            axios({
                url:apiBaseUrl21+"homepageSlider/update/"+id,
                method:"POST",
                data:allData,
            }).then(function (response){
                if(response.status===200){
                    alert("Slider has been updated")
                    window.location.reload()
                }
                })
        }
    }
    render(){
        const columns = [
          {
            Header: <div className="form-textHead cell-head">Slider Id</div>,
            accessor: d=>{return {id:d.id,sliderId:d.sliderId}},
            id:'sliderId',
            filterMethod: (filter, row) => {   return row.catagory.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => <span className='form-text cell'> <Link to={'/knowledgeHub/businus-school/homepage/view-horizontal-slider/'+props.value.id}> {props.value.sliderId}</Link></span>,
            minWidth:120
          },
          {
            Header: <div className="form-textHead cell-head">Add</div>,
            accessor: d =>{return{id:d.id,displaySlider:d.displaySlider,sliderType:d.sliderType}},
            id: 'blogId',
            filterMethod: (filter, row) => {   return row.catagory.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props =><div> 
            <button onClick={()=>this.addSliderToHomepage(props.value.displaySlider,props.value.id,props.value.sliderType)}>{props.value.displaySlider?"Remove":"Add"}</button></div>,
            minWidth:220
          },
           {
            Header:<div className="form-textHead cell-head"> Slider Url </div>,
            accessor: 'sliderImageUrl',
            Cell: props => <span className='form-text cell'>{props.value}</span>,
            minWidth:220
          },{
            Header: props => <span className="form-textHead cell-head"> Date</span>,
            accessor: 'date',
            filterMethod: (filter, row) =>
                     {   return row.source.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => 
            new Date(props.value).toDateString(),
            minWidth:180
          },{
            Header: props => <span className="form-textHead cell-head"> Time</span>,
            accessor: 'date',
            filterMethod: (filter, row) =>
                     {   return row.source.toLowerCase().includes(filter.value.toLowerCase()) },
            Cell: props => 
            new Date(props.value).toLocaleTimeString(),
            minWidth:180
          }]
        return(
            <div>
            <div className="row">
              <div className="col-md-2 col-lg-2"><Khub_HomepageSidebar/></div>
              <div className="col-md-10 col-lg-10">
                <div className = "container-fluid overflow-auto" style={{margin: "10px"}}>
                <div className="row breadcrumb-row">
                    <div className="col-md-10" style={{marginTop:'10px'}}><BreadCrumbs/></div></div>
                    <div className="container-fluid " style={{}}>
                    <ReactTable data={this.state.sliderArray} columns={columns} style={{height:'85vh',overflow:'scroll'}} filterable/>
                    </div>
            </div>
            </div>
            </div>
            </div>
        )
    }
}