import React, { Component } from "react";
import BreadCrumbs from "../../BreadCrumbs";
import { Link } from "react-router-dom";
import axios from 'axios';
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import CsvDownloader from 'react-csv-downloader'
import ReactTable from 'react-table';
import $ from "jquery";
import _ from 'underscore';

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

const data = [];


let context="null";

const columnsForExcel = [
  {
    id: 'assignmentId',
    displayName : "Assingment ID",

  },
  {
    displayName : "Name",

    id: "name"
  },
  {
    displayName : "Status",
    id: "status",
   
  },
  {
    displayName : "Payment Status",
    id: "paymentStatus",
   
  },
  {
    displayName : "Start Date",
    id: "startDate",
   
  },
  {
    displayName : "End Date",
    id: "endDate",
 
  },

  {
    displayName : "Overview",
    id: "assignmentOverview",
   
  },

  {
    displayName : "Priority",
    id: "priority",
   
  },
  {
    displayName : "Client",
    id: "selectedClient",
   
  },
  {
    displayName : "Organisation",
    id: "selectedOrganisation",

  },
  {
    displayName : "Team Lead",
    id: "teamLead",
 
  },
  {
    displayName : "Team",
   
    id: "teamMembers",
   
  },
  {
    displayName : "Template",
   
    id: "template",
   
  },
  {
    displayName : "Recurrance Frequency",
   
    id: "recurranceFrequency",
   
  },
  {
    displayName : "Priority",
   
    id: "priority",
   
  },
  {
    displayName : "By",
   
    id: "by",
   
  },
  {
   
    id: "date",
    displayName : "Date",
  },
  {
    displayName : "Time",
    id: "time",
  
  }
];

//defined all the columns and fetched the data for every cell.
const columns = [
  {
    Header: props => <span className="form-textHead cell-head">Client ID</span>,
    accessor: 'customerId',
    Cell: props => <span className='form-text cell'>{props.value}</span>,
    filterMethod: (filter, row) =>
  { return row.customerId.toLowerCase().includes(filter.value.toLowerCase()) },       
    minWidth:170
  },
  {

    Header: props => <span className="form-textHead cell-head">Company Name</span>,
    accessor: d => {return {mail:d.email,id:d.id,name:d.companyName}},
    filterMethod: (filter, row) =>
             { return row.companyName.name ? row.companyName.name.toLowerCase().includes(filter.value.toLowerCase()) : false}
                     
                  ,
    id:"companyName",
    Cell: props => 
    <Link className="link" to={"./view-clients/"+props.value.id}>{props.value.name}</Link>,
    minWidth:180
    },
{
  Header: props => <span className="form-textHead cell-head">Customer Email</span>,
  filterMethod: (filter, row) =>
  {debugger;   return row.customerEmail.toLowerCase().includes(filter.value.toLowerCase()) },
  accessor: 'customerEmail',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:240
},
{
  Header: props => <span className="form-textHead cell-head">Customer Mode</span>,
  filterMethod: (filter, row) =>
  {debugger;   return row.mode.toLowerCase().includes(filter.value.toLowerCase()) },
  accessor: 'mode',
  Cell: props => <span className='form-text cell'>{props.value!=undefined ? props.value : 'Online'}</span>,
  minWidth:240
},
{
  Header: props => <span className="form-textHead cell-head">Customer Type</span>,
  filterMethod: (filter, row) =>
  {debugger;   return row.customerType.toLowerCase().includes(filter.value.toLowerCase()) },
  accessor: 'customerType',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:170
},
 {
  Header: props => <span className="form-textHead cell-head">Company Display Name</span>,
  filterMethod: (filter, row) =>
  {debugger;   return row.companyDisplayName.toLowerCase().includes(filter.value.toLowerCase()) },
  accessor: 'companyDisplayName',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:220
},

{
  Header: props => <span className="form-textHead cell-head">Primary Contact</span>,
  filterMethod: (filter, row) =>
  {debugger;   return row.primaryContact.toLowerCase().includes(filter.value.toLowerCase()) },
  accessor: 'primaryContact',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:170
 
},{
  Header: props => <span className="form-textHead cell-head">Customer Contact</span>,
  accessor: 'customerContact',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:150
},{
  Header: props => <span className="form-textHead cell-head">Website</span>,
  accessor: 'website',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:240
},{
  Header: props => <span className="form-textHead cell-head">Billing Address</span>,
  filterMethod: (filter, row) =>
  {debugger;   return row.billingAddress.toLowerCase().includes(filter.value.toLowerCase()) },
  accessor: 'billingAddress',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:150
},{
  Header: props => <span className="form-textHead cell-head">Billing Country</span>,
  filterMethod: (filter, row) =>
  {debugger;   return row.billingCountry.toLowerCase().includes(filter.value.toLowerCase()) },
  accessor: 'billingCountry',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:180
},
{
  Header: props => <span className="form-textHead cell-head">Billing Attention</span>,

  accessor: 'billingAttention',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:180
},
{
  Header: props => <span className="form-textHead cell-head">Billing City</span>,
  accessor: 'billingCity',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:180
},
{
  Header: props => <span className="form-textHead cell-head">Billing Phone</span>,
  accessor: 'billingPhone',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:180
},
{
  Header: props => <span className="form-textHead cell-head">Zip code</span>,
  accessor: 'billingZipCode',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:180
},
{
  Header: props => <span className="form-textHead cell-head">Shipping Address</span>,
  accessor: 'shippingAddress',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:180
},
{
  Header: props => <span className="form-textHead cell-head">Shipping Country</span>,
  accessor: 'shippingCountry',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:180
},
{
  Header: props => <span className="form-textHead cell-head">Shipping Attention</span>,
  accessor: 'shippingAttention',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:180
},
{
  Header: props => <span className="form-textHead cell-head">Shipping City</span>,
  accessor: 'shippingCity',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:180
},
{
  Header: props => <span className="form-textHead cell-head">Shipping Zipcode</span>,
  accessor: 'shippingZipCode',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:180
},
{
  Header: props => <span className="form-textHead cell-head">By</span>,
  accessor: 'by',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:170
 
},
{
  Header: props => <span className="form-textHead cell-head">Date of Creation</span>,
  accessor: d => {return {date:d.date,id:d.id}},
  filterMethod: (filter, row) =>
           { return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase()) }
                   
                ,
  id:"teamMembers",
  Cell: props => 
  new Date(props.value.date).toDateString(),
  minWidth:180
 
},
{
  Header: props => <span className="form-textHead cell-head">Time of Creation</span>,
  accessor: d => {return {date:d.date,id:d.id}},
  filterMethod: (filter, row) =>
           { return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase()) }
                   
                ,
  id:"teamMembers",
  Cell: props => 
  new Date(props.value.date).toLocaleTimeString()
  ,
  minWidth:280
 
},
{
  Header: props => <span className="form-textHead cell-head">Bank Name</span>,
  accessor: d => {return {bank:d.bankDetails,id:d.id}},
  id:"bankName",
  Cell: props => <span className='form-text cell'>{props.value.bank!=undefined ? props.value.bank.bankName : ''}</span>,
  minWidth:180
},
{
  Header: props => <span className="form-textHead cell-head">Branch Name</span>,
  accessor: d => {return {bank:d.bankDetails,id:d.id}},
  id:"branchName",
  Cell: props => <span className='form-text cell'>{props.value.bank!=undefined ? props.value.bank.branchName : ''}</span>,
  minWidth:180
},
{
  Header: props => <span className="form-textHead cell-head">Account No</span>,
  accessor: d => {return {bank:d.bankDetails,id:d.id}},
  id:"accountNo",
  Cell: props => <span className='form-text cell'>{props.value.bank!=undefined ? props.value.bank.accountNo : ''}</span>,
  minWidth:180
},
{
  Header: props => <span className="form-textHead cell-head">Account Code</span>,
  accessor: d => {return {bank:d.bankDetails,id:d.id}},
  id:"accountCode",
  Cell: props => <span className='form-text cell'>{props.value.bank!=undefined ? props.value.bank.accountCode : ''}</span>,
  minWidth:180
},
{
  Header: props => <span className="form-textHead cell-head">Ifsc Code</span>,
  accessor: d => {return {bank:d.bankDetails,id:d.id}},
  id:"ifscCode",
  Cell: props => <span className='form-text cell'>{props.value.bank!=undefined ? props.value.bank.ifscCode : ''}</span>,
  minWidth:180
},
{
  Header: props => <span className="form-textHead cell-head">Micr Code</span>,
  accessor: d => {return {bank:d.bankDetails,id:d.id}},
  id:"micrCode",
  Cell: props => <span className='form-text cell'>{props.value.bank!=undefined ? props.value.bank.micrCode : ''}</span>,
  minWidth:180
},
/* {
  Header: props => <span className="form-textHead cell-head">Attribute Type</span>,
  accessor: 'attributes',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:180
},
{
  Header: props => <span className="form-textHead cell-head">Attribute Value</span>,
  accessor: 'attributeVal',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:180
},
{
  Header: props => <span className="form-textHead cell-head">Attribute Attachment</span>,
  accessor: 'attributeAttachment',
  Cell: props => <a href={props.value} download className='form-text cell'>Download</a>,
  minWidth:180
}, */


]
export default class viewClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      menu1:[
       



 ]
 
    };
    context = this;
  }
  componentDidMount(){

  this.fetchQueries(this,this.props.source);}


  toggleOpen = id => {
    var list  =this.state.menu1;
    var index = _.findIndex(list,function(entry){return entry.id==id});
    
    list[index].status =  (list[index].status == "open" || list[index].status==undefined) ? "close" : 'open';
    
    var newList = JSON.parse(JSON.stringify( list ));
    context.setState({menu1:newList});
    context.updateStatus(context,id,list[index].status);
    
    
    
      };

  
  fetchQueries(that,source){

    axios.get(apiBaseUrl2+"businus/customer/list/"+localStorage.spId)
    .then(function (response) {
      console.log(response.data);
      that.setState({menu1:_.sortBy(response.data,"customerId")});
      that.generateExcelData(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }

  generateExcelData = data => {
    
    let excelData = [];
    _.each(data, function(d) {

      
      let row = {
      customerId:d.customerId,
    companyName:d.companyName,
  customerEmail:d.customerEmail,
     mode:d.mode,
     customerType:d.customerType,
     companyDisplayName:d.companyDisplayName,
     customerContact:d.customerContact,
    website:d.website,
    billingAddress:d.billingAddress,
   billingAttention:d.billingAttention,
     billingCity:d.billingCity,
     billingPhone:d.billingPhone,
     billingZipCode:d.billingZipCode,
     shippingAddress:d.shippingAddress,
    shippingCountry:d.shippingCountry,
 'by':d.by,
        Cell: props => <span className='form-text cell'>{props.value}</span>,
        minWidth:170
       
      };
       
      excelData.push(row);
      console.log(excelData);
    });
    this.setState({ excelData });
  };

   getCommaSeparatedTeamName=(teamMember)=>{
    console.log(teamMember)
   let names= _.map(teamMember, function(d){
   return d.name;
   })
   console.log(names)
   return names.join(",")
   }


  

  render() {
    return (
      <div>
       
      
      <div className="Div">
      <div className="row breadcrumb-row">
          <div className="col-md-10" ><BreadCrumbs/></div>
          <div className="col-md-2">
            <div className=" download-button">
      <CsvDownloader text="Download in .csv format" datas={this.state.excelData} filename="clients" /></div>
      </div>
      </div>
            <div className="row">
          
              <Row>
                <Col>
                  <div className="card">

                  <ReactTable className="myTable" resolveData={data => data.map(row => row)}  data={this.state.menu1} columns={columns} filterable />
                  


                    
                       
                      
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          </div>
    );
  }
}
