import axios from 'axios';
import React, {Component} from 'react'
import './clientReview.css'
import GroupClientReview from './GroupClientReview';
import StarReview from './StarReview';

let context = null;

export default class AddClientReview extends Component{
    constructor(props){
        super(props)
        this.state={
            openModal:false,
            clientReview:[],
            rating:0,
            ratingArray:[],
            isReview:false,
            isCheckReview:false,
            reviewId:'',
            ratingId:'',
            ratingArray:[],
            profileId:this.props.profileId,
            isRating:false
        }
        this.closeModal=this.closeModal.bind(this)
        this.openModal=this.openModal.bind(this)
        this.submitReview=this.submitReview.bind(this)
        this.submitForm=this.submitForm.bind(this)
        this.handleStar= this.handleStar.bind(this)
        this.handleChange =this.handleChange.bind(this)
        this.addReview=this.addReview.bind(this)
        this.updateReview=this.updateReview.bind(this)
        this.fetchAllReview = this.fetchAllReview.bind(this)
        context=this;
    }

    componentWillMount(){
        this.fetchAllReview(this.state.profileId)
        this.fetchAllRating(this.state.profileId)
    }

    async openModal(){
    await this.setState({openModal:true})
        document.getElementById('comment-modal').classList.remove('hide')
        document.getElementById('comment-modal').classList.add('in')
        document.getElementById('comment-modal').classList.add('open-modal')
        this.handleStar()
    }

    closeModal(){
        document.getElementById('comment-modal').classList.remove('in')
        document.getElementById('comment-modal').classList.remove('open-modal')    
        this.setState({openModal:false})
    }

    handleChange(e){
        let name=e.target.name
        let value=e.target.value
        this.setState({[name]:value})
    }

    handleStar(){
        const stars = document.querySelectorAll('.star');
        console.log(stars)
        for(var i=0; i<stars.length;i++){
            stars[i].starValue=i+1;
            ["click","mouseover","mouseout"].forEach(function(e) {
                stars[i].addEventListener(e,showRating)
            })
            
        }

        function showRating(e){
            let type = e.type

            let starValue = this.starValue;
            stars.forEach(function(elem,ind) {
                if(type==="click"){
                    if(ind<starValue){
                        elem.classList.add("orange")
                       context.setState({rating:starValue})
                    }
                    else{
                        elem.classList.remove("orange")
                    }
                }

                if(type==="mouseover"){
                    if(ind<starValue){
                        elem.classList.add("checked")
                    }
                    else{
                        elem.classList.remove("checked")
                    }
                }

                if(type==="mouseout"){
                        elem.classList.remove("checked")
                   
                }       
            });

        }
       
    }
    
    fetchAllReview(id){
        var clientReview = []
        var isCheckReview = false
        var reviewId =""
        axios({
            url:'https://8cop2jp1c8.execute-api.us-east-1.amazonaws.com/dev/businus/profile/listProfileReview',
            method:'GET',
            crossDomain:true,
            response:'JSON',
          }).then((response)=>{
              for(var i=0;i<response.data.length;i++){
                  if(response.data[i].profileId===id){
                    clientReview = response.data[i].clientReview
                    isCheckReview = true
                    reviewId = response.data[i].id
                }
                  
              }
              
            this.setState({
                clientReview:clientReview,
                isCheckReview:isCheckReview,
                reviewId:reviewId})
          })
          
    } 

    updateReview(id,clientReview){
        let data={
            clientReview:clientReview,
        }
        axios({
            url:"https://8cop2jp1c8.execute-api.us-east-1.amazonaws.com/dev/businus/profile/updateProfileReview/"+id,
            method:'POST',
            data:data,
        }).then((response)=>{
            if(response.status){
                this.submitForm()
                this.setState({clientReview:clientReview})
            }
            console.log(response.data)
        })
    }

    addReview(clientReview){
        let data={
            profileId:this.state.profileId,
            clientReview:clientReview,
        }
        axios({
            url:"https://8cop2jp1c8.execute-api.us-east-1.amazonaws.com/dev/businus/profile/addProfileReview",
            method:"POST",
            data:data,
        }).then((response)=>{
            if(response.status){
                this.submitForm()
                this.setState({clientReview:clientReview})
            }
            console.log(response.data)
        })
    }

    submitReview(){
        let clientReview = this.state.clientReview
        let rating=this.state.rating
        const ratingPercentage = (rating/5)*100
        const ratingPercentageRound = Math.round(ratingPercentage/10)*10+"%"
        let time = new Date()

        clientReview.unshift({
            key:clientReview.length+1,
            title:this.state.title,
            comment:this.state.comment,
            rating:ratingPercentageRound,
            time:time,
            name:'Himanshu',
        })

        if(this.state.isCheckReview){
            this.updateReview(this.state.reviewId,clientReview)
        }
        else{
            this.addReview(clientReview)
        }

        this.starStatistic(rating)
        // this.setState({clientReview:clientReview,})
        
    }

    starStatistic(rating){
        let ratingArray = this.state.ratingArray
            let five = ratingArray.five
            let four = ratingArray.four
            let third = ratingArray.third
            let two = ratingArray.two
            let one = ratingArray.one

            if(rating===5){
               five = ratingArray.five!==undefined?five+1:1
            }
            else if(rating===4){
                four = ratingArray.four!==undefined?four+1:1
            }
            else if(rating===3){
                third = ratingArray.third!==undefined?third+1:1
            }
            else if(rating===2){
                two = ratingArray.two!==undefined?two+1:1
            }
            else{
                one = ratingArray.one!==undefined?one+1:1
            }
            let total = ratingArray.total!==undefined?ratingArray.total+1:1
            let ratingSum = ratingArray.ratingSum!==undefined?ratingArray.ratingSum+rating:rating
            let ratingAverage = ratingSum/(total*5)
    
            let Rating ={
                five:five,
                four:four,
                third:third,
                two:two,
                one:one,
                total:total,
                ratingSum:ratingSum,
                ratingAverage:ratingAverage, 
            }

            
            if(this.state.isRating){
                this.updateRating(this.state.ratingId,Rating)
            }
            else{
                this.addRating(Rating)
            }
            this.setState({ratingArray:ratingArray,isRating:true})
    }

    fetchAllRating(id){
        var ratingArray = []
        var isRating = false
        var ratingId=''
        axios({
            url:'https://8cop2jp1c8.execute-api.us-east-1.amazonaws.com/dev/businus/profile/getProfileRatingById/'+id,
            method:'GET',
            crossDomain:true,
            response:'JSON',
          }).then((response)=>{
                if(response.data){
                    ratingArray = response.data[0].rating
                    isRating = true
                    ratingId = response.data[0].id
                }
          
                    
        this.setState({
            ratingArray:ratingArray,
            isRating:isRating,
            ratingId:ratingId
            })
      })
    }

    addRating(rating){
        let data={
            profileId:this.state.profileId,
            rating:rating,
        }
        axios({
            url:"https://8cop2jp1c8.execute-api.us-east-1.amazonaws.com/dev/businus/profile/addProfileRating",
            method:'POST',
            data:data
        }).then((response)=>{
            console.log(response.data)
        })
        this.fetchAllRating(this.state.profileId)
    }

    updateRating(id,rating){
        let data={
            rating:rating,
        }
        axios({
            url:"https://8cop2jp1c8.execute-api.us-east-1.amazonaws.com/dev/businus/profile/updateProfileRating/"+id,
            method:"POST",
            data:data,
        }).then((response)=>{
            console.log(response.data)
        })
        this.fetchAllRating(this.state.profileId)
    }

    submitForm(){
       let modal_header = document.getElementById('modal-header').classList
       modal_header.remove("show")
       modal_header.add('hide')
       
       let form_box = document.getElementById('form-box').classList
       form_box.remove('show')
       form_box.add("hide")
       
       let feedback = document.getElementById('feedback').classList
       feedback.remove('hide')
       feedback.add('show')
    //    this.setState({isReview:true})
    }


    render(){
        return(
            <div>
                <div className='leave-client-comment'>
                    <div style={{cursor:'pointer'}} onClick={()=>this.openModal()}>
                        <i class="fas fa-pencil-alt"></i> <span style={{marginLeft:'1%'}}>  Leave a review </span>
                    </div>
                </div>
                {/* {this.state.openModal? */}
                    <div>
                        <div className='modal fade hide' id='comment-modal'>
                            <div className='modal-dialog'>
                                <div className='modal-content'>
                                    <button type='button' className='close' onClick={()=>this.closeModal()}> X </button>
                                    <div className='modal-header show' id='modal-header'>
                                        <div className='modal-title'> Add Comment </div>
                                    </div>
                                    <form className='form-box show' id='form-box'>
                                        <div className='row'>
                                            <div className='col-md-12 col-sm-12 col-xs-12'>
                                               <ul style={{listStyle:"none"}}>
                                                    <li className="fa fa-star star"></li>
                                                    <li className="fa fa-star star"></li>
                                                    <li className="fa fa-star star"></li>
                                                    <li className="fa fa-star star"></li>
                                                    <li className="fa fa-star star"></li>
                                                </ul>
                                            </div>
                                        </div>

                                        {/* <div className='row'>
                                            <div className='col-md-6 col-sm-6 col-xs-12'> <input type='text' placeholder='First Name'/> </div>
                                            <div className='col-md-6 col-sm-6 col-xs-12'> <input type='text' placeholder='Last Name'/>  </div>
                                        </div> */}
                                        <div className='row'>
                                            <div className='col-md-12 col-sm-12 col-xs-12'> <input type='text' onChange={this.handleChange} name='title' value={this.state.title} placeholder='Title'/> </div>
                                            {/* <div className='col-md-6 col-sm-6 col-xs-12'> <input type='text' placeholder='Company'/> </div> */}
                                        </div>
                                        {/* <div className='row'>
                                            <div className='col-md-12 col-sm-12 col-xs-12'>
                                            <input type='text' placeholder='Email Address'/></div>
                                        </div> */}
                                        <div className='row'>
                                            <div className='form-box_row'>
                                            <textarea onChange={this.handleChange} name='comment' value={this.state.comment} placeholder='Your Comment'></textarea></div>
                                        </div>
                                        <div style={{float:'right'}}>
                                            <button type='button' className='btn-send-feedback' onClick={()=>this.submitReview()}> Submit Form</button>
                                        </div>   
                                    </form>
                                 
                                    <div className='hide' id='feedback'>
                                    
                                    <i class="fas fa-check-circle submit-check"></i>
                                    <div style={{padding:'0 10%'}}>
                                       <h3> Your comment was saved successfully!</h3>
                                       <h3>Thank you for your feedback</h3></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* :""
                } */}
                <div className='row'>
                    <div className='col-md-4 col-lg-4 col-sm-4'>
                        <StarReview rating={this.state.ratingArray} />
                    </div>
                    <div className='col-md-8 col-lg-8 col-sm-8'>
                    {this.state.clientReview.length!==0?
                        this.state.clientReview.map((review)=>(
                            <GroupClientReview
                            id={review.id}
                            key={review.key}
                            name={review.name}
                            comment={review.comment}
                            time={review.time}
                            title={review.title}
                            ratingPercentage={review.rating}
                            />
                        ))
                        :""
                        }
                    </div>
                </div>
                
            </div>
        )
    }
}