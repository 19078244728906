import React, { Component } from "react";
import axios from 'axios';
import BreadCrumbs from "../BreadCrumbs";
import { apiBaseUrl2 ,apiBaseUrl5} from '../apiUtils';
import { Link } from "react-router-dom";
import $ from "jquery";
import 'react-table/react-table.css'
import _ from 'underscore';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

//defining the context initially assigning it a null value

let context = null;

//defining the heads of the table, the accessors 



export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
    counters:{},
    sendQueryCounter:{}
 
    };

    context = this;
  }
  componentDidMount(){
    localStorage.setItem("email","gautammegha2@gmail.com");
    this.countQueries(this,this.props.match.params.docType);

}



  //function for toggling the status button

  


  //function for fetching queries from backend 
  
  countQueries(that,source){

    axios.get(apiBaseUrl5+"businusquery/countCat/"+source)
    .then(function (response) {
      console.log(response.data);
      context.setState({counters:response.data.queries});
      context.setState({sendQueryCounter:response.data.category});
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }


  //function for changing the status button on click

  


  render() {
      const {counters,sendQueryCounter} = this.state;
    return (
      <div>
      <div className="row breadcrumb1-row" style={{marginLeft:'0px'}}>
      <div className="col-md-12"><BreadCrumbs/></div>
    
  </div>
      <div className="DashboardDiv" style={{paddingLeft:'24px',overflowY:'scroll'}} >
        
      
      <div className="row ">
      <h2 className="dashboard-head">Dashboard</h2>
      </div>
      
      
    
            <div className="row">
        <div className="col-md-6">
          <Link to={"/legal/docs/"+this.props.match.params.docType+"/instant-download"}>
          <div className="card-counter primary">
            <i className="fa fa-ticket" />
            <span className="count-numbers">{counters.instantdownload!=undefined ? counters.instantdownload : 0}</span>
            <span className="count-name">Instant Download </span>
          </div>
          </Link>
        </div>
       
        <div className="col-md-6">
        <Link to={"/legal/docs/"+this.props.match.params.docType+"/vetting"}>
          <div className="card-counter primary">
            <i className="fa fa-ticket" />
            <span className="count-numbers">{counters.vetting!=undefined ? counters.vetting : 0}</span>
            <span className="count-name">Vetting</span>
          </div>
          </Link>
        </div>
       
        
        </div>
       
       
            <div className="row">
         


            <div className="col-md-6">
        <Link to={"/legal/docs/"+this.props.match.params.docType+"/tailoring"}>
          <div className="card-counter primary">
            <i className="fa fa-ticket" />
            <span className="count-numbers">{counters.tailored!=undefined ? counters.tailored : 0}</span>
            <span className="count-name">Tailoring</span>
          </div>
          </Link>
        </div>

        <div className="col-md-6">
        <Link to={"/legal/docs/"+this.props.match.params.docType+"/ask-an-expert"}>
          <div className="card-counter primary">
            <i className="fa fa-ticket" />
            <span className="count-numbers">{counters.askanexpert!=undefined ? counters.askanexpert : 0}</span>
            <span className="count-name">Ask an Expert Requests</span>
          </div>
          </Link>
        </div>
        
        </div>
       
            
       
        </div>
         
       
     


        
     

        


      </div>
            
          
       
    );
  }
}
