import React, { Component } from "react";
import { Link } from "react-router-dom";
import Khub_HomepageSidebar from "./Khub_HomepageSidebar";

export default class KhubHomepage extends Component{
    render(){
        return(
            <div className="row">
            <div className="col-md-2 col-lg-2">
                <Khub_HomepageSidebar/>
            </div>
                
                <div className="col-md-10 col-lg-10">
                <div className='cotainer'>
                    <div className="col-md-6 col-lg-6">
                    <div className="tab-row1">
                    <h2 className="tab-row-head">Horizontal Slider</h2></div>

                    <div className="col-md-5">
                    <div className="tab text-center">
                    <Link to="/knowledgeHub/businus-school/homepage/add-horizontal-slider">
                    <img className="tab-image1" id="tab1" 
                                style={{ marginLeft: "-3px" }}
                                src={require("../../images/add-slider-icon.png")}
                                />
                        <h2 className="tab-text-main"> Add Slider </h2></Link>
                    </div>
                    </div>


                    <div className="col-md-5">
                    <div className="tab text-center">
                    <Link to="/knowledgeHub/businus-school/homepage/view-horizontal-slider">
                    <img className="tab-image1" id="tab1" 
                                style={{ marginLeft: "-3px" }}
                                src={require("../../images/view-slider-icon.png")}
                                />
                        <h2 className="tab-text-main"> View Slider </h2></Link>
                    </div>
                    </div> 
                    </div>
                    <div className="col-md-6 col-lg-6">
                    <div className="tab-row1">
                    <h2 className="tab-row-head">Vertical Slider</h2></div>
                    <div className="col-md-5">
                    <div className="tab text-center">
                    <Link to="/knowledgeHub/businus-school/homepage/add-vertical-slider">
                    <img className="tab-image1" id="tab1" 
                                style={{ marginLeft: "-3px" }}
                                src={require("../../images/add-vertical-slider-icon.png")}
                                />
                        <h2 className="tab-text-main"> Add Slider </h2></Link>
                    </div>
                    </div> 

                    <div className="col-md-5">
                    <div className="tab text-center">
                    <Link to="/knowledgeHub/businus-school/homepage/view-vertical-slider">
                    <img className="tab-image1" id="tab1" 
                                style={{ marginLeft: "-3px" }}
                                src={require("../../images/view-vertical-slider-icon.png")}
                                />
                        <h2 className="tab-text-main"> View Slider </h2></Link>
                    </div>
                    </div> 
                        
                    </div>

                    <div className="col-md-6 col-lg-6">
                    <div className="tab-row1">
                    <h2 className="tab-row-head">Stay Updated</h2></div>
                    <div className="col-md-5">
                    <div className="tab text-center">
                    <Link to="/knowledgeHub/businus-school/homepage/view-stay-updated">
                    <img className="tab-image1" id="tab1" 
                                style={{ marginLeft: "-3px" }}
                                src={require("../../images/view-stay-updated-icon.png")}
                                />
                        <h2 className="tab-text-main"> View Stay Updated </h2></Link>
                    </div>
                    </div> 
                    </div>

                        </div>
                    </div>
            </div>
        )
    }
}