import axios from 'axios';
import React from 'react';
import FileBase64 from 'react-file-base64';
import { apiBaseUrl20 } from '../../components/apiUtils';
import EximBlogSidebar from './EximBlogSidebar';

export default class AddEximBlogCategory extends React.Component{
    constructor(props){
        super(props)
        this.state={
            category:'',
            categoryIcon:''
        }
        this.handleChanges = this.handleChanges.bind(this)
    }

    handleChanges(e){
        let target = e.target;
        let name = target.name;
      
        this.setState({[name]:target.value});
    }

    finalChanges(){
        if(this.state.category.trim()===""){
            alert("Please enter the category")
        }
       

        if(this.state.category.trim()!==""){
            let InwardData={
                    category:this.state.category,
                    learningCategory:'exim'
                }
            axios({
                url: (apiBaseUrl20+'blogCategory/add'), 
                method: 'POST',
                data: InwardData
              }).then((response) => {
                if(response.status===200){
                  alert("EXIM Blog Category Added");
                  window.location.reload()
                }
             });
        }
    }




    render(){
        return(
            <div>
                <div className="row">
                    <div className='col-md-2 col-lg-2'><EximBlogSidebar/></div>
                <div className="col-md-10 col-lg-10">
            <div className="container-fluid overflow-auto" style={{margin: "10px", height:'90vh',}}>
                <div style={{margin: "10px"}}><h3>Add EXIM Category : </h3></div>

                <div className="container-fluid well well-sm" >
                <div class="input-group" style={{width:"80%"}}>
                    <span type="text" class="input-group-addon" id="" placeholder="Category" style={{width:"20%"}}>Category</span>
                    <input type="text" class="form-control" id="category" placeholder="Enter Category" name="category" value={this.state.category} onChange={this.handleChanges}></input>
                </div><br/>
                
    
            </div>
                <div ClassName="col-md-4" style={{width : "80%", position:"relative", height:"90px"}}>
                    <button type='button' class="btn btn-primary bottomright" style={{margin:'10px'}} id='save' onClick={()=>this.finalChanges()}>Add Category</button>
                </div>
            </div></div>
        </div>
      </div>
        )
    }
}