import React, { Component } from "react";

import { Link } from "react-router-dom";
import BreadCrumbs from "../../BreadCrumbs";
import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1, apiBaseUrl5 } from "../../apiUtils";
import { apiBaseUrl2 } from "../../apiUtils";
import FileBase64 from "react-file-base64";
import Modal from "react-modal";
import { ChatFeed, Message } from "react-chat-ui";
import Select from "react-select";
import PageChecklistComponent from "./pageChecklistComponent";
import SubmitButton from "../../common/SubmitButton";
import DocResourceTemplate from "./DocResourceTemplate";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import _ from "underscore";
let context = null;
const data = [];
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px ",
    position: "Absolute",
    zIndex: "30000",
    backgroundColor: "none",
  },
};

export default class addResourceTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assignmentTitle: data.assignmentTitle,
      startDate: data.startDate,
      endDate: data.endDate,
      assignmentOverview: data.assignmentOverview,
      entry: {
        checklists: [],
      },
      priority: data.priority,
      contactPersonsClients: [],
      contactPersons: [],
      employees: [],
      clients: [],
      attachmentAssignment: "N",
    };
    context = this;
    this.openModal = this.openModal.bind(this);

    this.closeModal = this.closeModal.bind(this);
  }

  handleOnChangeCol = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    this.setState({
      [name]: value,
    });
  };

  saveToChecklistList = (checklist, index) => {
    let entry = this.state.entry;

    entry.checklists[index] = checklist;

    this.setState({ entry: entry });
  };

  openWorkflowModal = () => {
    this.setState({ modalWorkflowIsOpen: true });
  };

  toggleEdit = () => {
    this.setState({ isEdit: !this.state.isEdit });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  addWorkflowInList = () => {
    let columnName = this.state.columnName;

    let newWorkflow = {
      name: columnName,
      list: [],
    };

    let entry = this.state.entry;
    entry.checklists.push(newWorkflow);

    this.setState({ entry: entry });
    this.setState({ modalWorkflowIsOpen: false });
  };

  changeSliderState(orderNumber) {
    this.setState({ sliderState: orderNumber });
  }
  //function for changing the status button on click to open or close
  handleOnChange = (e) => {
    debugger;
    var value = e.target.value;

    var formName = e.target.name;

    this.setState({ [formName]: value });

    console.log(this.state);
  };

  openModal(type) {
    this.changeDocType(type);
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  componentDidMount() {
    const id = this.props.match.params.id;

    this.fetchQueryById(this, id);
    this.fetchThreadById(this, id);
    this.fetchClients(this);
    // this.fetchTemplates(this);

    this.fetchAssignments(this);
    this.fetchEmployees(this);
    this.markAsRead(this, id);
  }

  fetchAssignments(that, source) {
    axios
      .get(
        apiBaseUrl1 +
          "businus/project/list/" +
          localStorage.getItem("employeeId")
      )
      .then(function (response) {
        console.log(response.data);
        that.setState({ assignments: _.sortBy(response.data, "projectId") });
        that.generateExcelData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  markAsRead(that, id) {
    axios
      .get(apiBaseUrl2 + "businusquery/read/" + id)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  }

  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(apiBaseUrl1 + "businus/customer/list/" + localStorage.spId)
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fileUploadHandler = (that, docType) => {
    that.setState({ loading: true });
    debugger;
    axios
      .post(apiBaseUrl2 + "businusquery/uploadAttachment", {
        uploadImage: that.state.selectedFile.base64,

        imageName: that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({
            [docType]:
              "https://businus-files-live.s3.amazonaws.com/mailAttachments/" +
              that.state.selectedFile.name,
          });

          //that.successNotification("Uploaded Succesfully");
        } else {
          //   that.errorNotification("Some Error Has Occured");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  fetchThreadById(that, id) {
    axios
      .get(apiBaseUrl2 + "businusquery/listmail/" + id)
      .then(function (response) {
        debugger;
        console.log(response.data);
        var threads =
          response.data == "" ? [] : _.sortBy(response.data, "date").reverse();
        that.setState({ thread: threads });

        var documents = _.where(threads, { docType: "Document" });
        var proposals = _.where(threads, { docType: "Proposal" });
        var mails = _.where(threads, { docType: "Mail" });
        that.setState({ documents });
        that.setState({ proposals });
        that.setState({ mails });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fetchClients(that, source) {
    axios
      .get(apiBaseUrl2 + "businus/customer/list/" + localStorage.spId)
      .then(function (response) {
        console.log(response.data);
        that.setState({ clients: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getFiles(files) {
    this.setState({ selectedFile: files[0] });
  }

  getFormattedDate(millis) {
    return new Date(millis).toDateString();
  }

  toggleOpen = (status) => {
    if (status == undefined || status == "open") {
      status = "close";
    } else {
      status = "open";
    }
    //(this.setState({ entry: [...this.state.entry,{status:status} ] });)
    var newEntry = this.state.entry;
    newEntry.status = status;
    this.setState({ entry: newEntry });
    context.updateStatus(context, this.state.entry.id, status);
  };

  changeDocType(type) {
    this.setState({ docType: type });
  }

  sendMail = (blog) => {
    var data = context.state;
    context.setState({ isSubmitting: false });

    axios
      .post(apiBaseUrl1 + "businus/template/add", {
        title: data.tname,
        name: data.name || data.tname,
        description: data.description,
        templateId: data.templateId,
        type: "resources",
        update: data.update,
        attachmentName: context.state.attachmentName,
        url: data.url,

        checklists: data.entry.checklists,
        document: data.attachment,
        blog: blog,
        templateType: data.type,
        by: localStorage.userName,
        spId: localStorage.spId,
      })
      .then(function (response) {
        debugger;
        alert("Success!");
        context.setState({ isSubmitting: "done" });
        //  context.fetchThreadById(context,query.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    localStorage.setItem("MAILURL", url);
  }

  onSelect = (selectedOption) => {
    this.setState({ sharedTo: selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  onRemove(optionList, removedItem) {
    context.setState({ sharedTo: optionList });
  }

  onSelectClients = (selectedOption) => {
    this.setState({ sharedToClient: selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  onRemoveClients(optionList, removedItem) {
    context.setState({ sharedToClient: optionList });
  }

  handleOnAttachment = (e) => {
    var value = e.target.value;

    var formName = e.target.name;

    this.setState({ [formName]: value });

    console.log(this.state);
  };

  handleOnAssignChange = (e) => {
    debugger;
    const state = this.state;
    state[e.target.name] = e.target.value;

    var selectedAssignment = _.findWhere(this.state.assignments, {
      id: e.target.value,
    });
    let teamLead = selectedAssignment.teamLead;
    let team = selectedAssignment.team;
    let client = selectedAssignment.selectedClient;
    let contactPersons = [];
    contactPersons.push({
      label: "Team Lead :" + teamLead.name,
      value: teamLead.id,
      ...teamLead,
      type: "teamlead",
    });
    contactPersons.push({
      label: "Client :" + client.name,
      value: client.id,
      ...client,
      type: "client",
    });

    _.each(team, function (d) {
      contactPersons.push(d);
    });
    // state['contactPersons'] = contactPersons;
    //state['subjectUpdate'] = selectedTemplate.update;

    state["contactPersons"] = contactPersons;

    this.setState(state);

    console.log(this.state);
  };

  fetchEmployees(that, source) {
    axios
      .get(apiBaseUrl2 + "businus/employee/list/" + localStorage.spId)
      .then(function (response) {
        console.log(response.data);

        let allEmp = response.data;
        if (localStorage.userRole != "Admin") {
          allEmp = _.filter(allEmp, function (emp) {
            return emp.role != "Admin";
          });
        }

        let contactPersons = [];

        _.each(allEmp, function (member) {
          contactPersons.push({
            value: member.id,
            label:
              member.employeeId +
              " " +
              member.firstName +
              " " +
              member.lastName,
            name:
              member.employeeId +
              " " +
              member.firstName +
              " " +
              member.lastName,
            id: member.id,
            email: member.email,
          });
        });

        that.setState({ employees: contactPersons });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleOnClientChange = (e) => {
    debugger;
    const state = this.state;
    state[e.target.name] = e.target.value;

    var selectedClient = _.findWhere(this.state.clients, {
      id: e.target.value,
    });
    let primaryContact = {
      id: selectedClient.id,
      name: selectedClient.primaryContact,
      email: selectedClient.customerEmail,
      label: selectedClient.primaryContact,
    };
    let contacts = selectedClient.contactPersons;

    let contactPersons = [];
    contactPersons.push(primaryContact);

    _.each(contacts, function (d) {
      contactPersons.push({ label: d.name, type: "contact", ...d });
    });
    // state['contactPersons'] = contactPersons;
    //state['subjectUpdate'] = selectedTemplate.update;

    state["contactPersonsClients"] = contactPersons;

    this.setState(state);

    console.log(this.state);
  };

  saveToTemplate = (blog) => {
    this.sendMail(blog);
  };

  //updating the status button

  render() {
    const { sliderState } = this.state;
    const active = " active-button";
    const deactive = "";
    return (
      <div className="dedicated-div">
        <div className="row breadcrumb1-row" style={{ marginLeft: "2px" }}>
          <div className="col-md-12">
            <BreadCrumbs />
          </div>
        </div>
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
          <div className="dedicated-page2">
            <div className="row ">
              <div className="col-md-12 info-column account-col2">
                <div id="field">
                  <h2 className="account-head"> Add Template</h2>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="form-group">
                        <label className="control-label col-md-4">
                          <span
                            id="ember295"
                            className="popovercontainer text-dashed-underline ember-view"
                            data-original-title
                            title
                          >
                            Type
                          </span>{" "}
                        </label>
                        <div
                          className="inline-fields col-md-8"
                          style={{ padding: 0 }}
                        >
                          <div className="col-md-8">
                            <select
                              autofocus
                              id="ember299"
                              onChange={this.handleOnChange}
                              name="type"
                              value={this.state.type}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            >
                              <option>Select Template Type</option>
                              <option>Attachment</option>

                              <option>Url</option>

                              <option>Document</option>
                              <option>Checklist</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="control-label col-md-4">
                          <span
                            id="ember295"
                            className="popovercontainer text-dashed-underline ember-view"
                            data-original-title
                            title
                          >
                            Template Name
                          </span>{" "}
                        </label>
                        <div
                          className="inline-fields col-md-8"
                          style={{ padding: 0 }}
                        >
                          <div className="col-md-8">
                            <input
                              autofocus
                              id="ember299"
                              onChange={this.handleOnChange}
                              name="tname"
                              value={this.state.tname}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>

                      {this.state.type != "Document" ? (
                        <span>
                          <div className="form-group">
                            <label className="control-label col-md-4">
                              <span
                                id="ember295"
                                className="popovercontainer text-dashed-underline ember-view"
                                data-original-title
                                title
                              >
                                Name
                              </span>{" "}
                            </label>
                            <div
                              className="inline-fields col-md-8"
                              style={{ padding: 0 }}
                            >
                              <div className="col-md-8">
                                <input
                                  autofocus
                                  id="ember299"
                                  onChange={this.handleOnChange}
                                  name="name"
                                  value={this.state.name}
                                  className="form-control ember-text-field ember-view"
                                  type="text"
                                />
                              </div>
                            </div>
                          </div>

                          {this.state.type == "Url" ? (
                            <div className="form-group">
                              <label className="control-label col-md-4">
                                Url
                              </label>
                              <div className="col-md-6">
                                <input
                                  id="ember301"
                                  onChange={this.handleOnChange}
                                  name="url"
                                  value={this.state.url}
                                  className="form-control ember-text-field ember-view"
                                  type="text"
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="form-group">
                            <label className="control-label col-md-4">
                              {this.state.type == "Checklist"
                                ? "Overview"
                                : "Description"}
                            </label>
                            <div className="col-md-6">
                              <textarea
                                id="ember301"
                                onChange={this.handleOnChange}
                                name="update"
                                value={this.state.update}
                                className="form-control ember-text-field ember-view"
                                type="text"
                              ></textarea>
                            </div>
                          </div>

                          {this.state.type == "Attachment" ? (
                            <div className="form-group">
                              <label className="control-label col-md-4">
                                <span
                                  id="ember295"
                                  className="popovercontainer text-dashed-underline ember-view"
                                  data-original-title
                                  title
                                >
                                  Attachment:
                                </span>{" "}
                              </label>
                              <div
                                className="inline-fields col-md-8"
                                style={{ padding: 0 }}
                              >
                                <div className="col-md-12">
                                  <div className="col-md-8 upload-box">
                                    {" "}
                                    <FileBase64
                                      multiple={true}
                                      onDone={this.getFiles.bind(this)}
                                    />
                                  </div>

                                  <div className="col-md-4">
                                    <button
                                      className="btn btn-primary attachment"
                                      onClick={() =>
                                        this.fileUploadHandler(
                                          this,
                                          "attachment"
                                        )
                                      }
                                      value="Select a File"
                                    >
                                      <i
                                        className="fa fa-paperclip"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          {this.state.type == "Checklist" ? (
                            <div>
                              <button
                                className="add-button"
                                onClick={() => this.openWorkflowModal()}
                                style={{ width: "220px" }}
                              >
                                {" "}
                                <i
                                  style={{ fontSize: "15px" }}
                                  className="fa fa-plus "
                                ></i>
                                Add a Checklist
                              </button>

                              {this.state.entry.checklists.map(
                                (checklist, index) => {
                                  return (
                                    <PageChecklistComponent
                                      checklistId={this.state.entry.id}
                                      checklist={checklist}
                                      checklistIndex={index}
                                      saveTochecklistList={
                                        this.saveToChecklistList
                                      }
                                    />
                                  );
                                }
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </span>
                      ) : (
                        <DocResourceTemplate
                          saveToTemplate={this.saveToTemplate}
                        />
                      )}
                    </div>
                  </div>

                  {/* < div style={{marginTop:'15px',marginLeft:'24px'}} className="form-group">
                    <label className="control-label col-md-4">
                      <span
                      
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        {" "}
                        Do you want to attach an Assignment* :{" "}
                      </span>{" "}
                    </label>
                    <div className="col-md-8">
                      <div className="radio-inline">
                        <label className="cursor-poiner">
                          <input
                            name="attachmentAssignment"
                            type="radio"
                            value={'Y'}
                            onChange={this.handleOnAttachment}
                            defaultValue={'Y'}
                          />
                        Yes
                        </label>
                      </div>
                      <div className="radio-inline">
                        <label className="cursor-poiner">
                          <input
                            name="attachmentAssignment"
                            type="radio"
                            value={'N'}
                            onChange={this.handleOnAttachment}
                            defaultValue={'N'}
                          />
                        No
                        </label>
                      </div>
                      
                      
                    </div>
                  </div>
            {this.state.attachmentAssignment=='Y' ? 
<span>
                  <div className="form-group">
                        <label className="control-label col-md-3">Assignment:</label>
                        <div className="col-md-7">
                        <select
                            name="selectedAssignment"
                            onChange={this.handleOnAssignChange}
                            value={this.state.selectedAssignment}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >
                <option value="">Select Assignment</option>
                            {this.state.assignments.map(function (client, i) {

                              return (<option value={client.id}>{client.assignmentId} {client.assignmentTitle}</option>)
                            })}

                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="control-label col-md-3">Shared To:</label>
                        <div className="col-md-7">

                        {this.state.contactPersons.length > 0 ?
                             <Select
                             value={this.state.sharedTo}
                             onChange={this.onSelect}
                             options={this.state.contactPersons}
                             isMulti={true}
                           />
                            :  <input
                            name="subject"
                            disabled="true"
                            value='Select Assignment Above'
                            className="form-control ember-text-field ember-view"
                            type="text"
                          />}

                        
                        </div>
                      </div>
                      </span>
                      :''}




    {this.state.attachmentAssignment=='N' ? 
<span>
                  <div className="form-group">
                        <label className="control-label col-md-3">Client:</label>
                        <div className="col-md-7">
                        <select
                            name="selectedClient"
                            onChange={this.handleOnClientChange}
                            value={this.state.selectedClient}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >
                <option value="">Select Client</option>
                            {this.state.clients.map(function (client, i) {

                              return (<option value={client.id}>{client.customerId} {client.companyName}</option>)
                            })}

                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="control-label col-md-3">Shared To (Client Contacts)</label>
                        <div className="col-md-7">

                        {this.state.contactPersonsClients.length > 0 ?
                             <Select
                             value={this.state.sharedToClient}
                             onChange={this.onSelectClients}
                             options={this.state.contactPersonsClients}
                             isMulti={true}
                           />
                            :  <input
                            name="subject"
                            disabled="true"
                            value='Select Client Above'
                            className="form-control ember-text-field ember-view"
                            type="text"
                          />}

                        
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="control-label col-md-3">Shared To (Team):</label>
                        <div className="col-md-7">

                        {this.state.employees.length > 0 ?
                             <Select
                             value={this.state.sharedTo}
                             onChange={this.onSelect}
                             options={this.state.employees}
                             isMulti={true}
                           />
                            :  ''}

                        
                        </div>
                      </div>
                      </span>
                      :''}
 */}

                  <Modal
                    isOpen={this.state.modalWorkflowIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <div className="cross-row text-right">
                      <div
                        style={{ marginRight: "-5px" }}
                        onClick={this.closeModal}
                      >
                        <i
                          style={{ fontSize: "25px", fontWeight: "400" }}
                          className="fa fa-close"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                    <div className="header2">Add Category name</div>

                    <div className="row content1-div  text-left">
                      <input
                        className="fieldbox1"
                        onChange={this.handleOnChangeCol}
                        type="text"
                        value={this.state.columnName}
                        name="columnName"
                        placeholder="Category Name"
                        style={{
                          fontSize: "17px",
                          fontFamily: "montserrat,sans-serif",
                        }}
                      />

                      <div className="text-center">
                        {" "}
                        <div
                          className="btn btn-primary send-button"
                          onClick={() => this.addWorkflowInList()}
                          placeholder="Attachment"
                        >
                          Submit
                        </div>
                      </div>
                    </div>
                  </Modal>

                  <div className="text-left">
                    {this.state.type == "Document" ? (
                      ""
                    ) : (
                      <SubmitButton
                        clickHandler={this.sendMail}
                        isSubmitting={this.state.isSubmitting}
                      />
                    )}
                  </div>
                  {/* this says that if editing state is on then the Function saveAccountDetails will be called */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
