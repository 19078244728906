import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Submission({
  submissions,
  smartDocName,
  setSection,
  smartDocId,
  setSmartDocVersionURL,
}) {
  return (
    <div
      className="container w-100  submission submission-section"
      style={{ minHeight: "400px" }}
    >
      {submissions.length != 0 ? (
        <>
          <div className="w-100 d-flex justify-content-end">
            <button
              type="button"
              className="btn my-4 mx-5 btn-outline-success btn-lg response-btn"
              onClick={() => {
                setSection("B");
              }}
            >
              Add New Response
            </button>
          </div>

          <table className="table">
            <thead className="thead-light">
              <tr className="">
                <th scope="col">Actions</th>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                <th scope="col">By</th>
                <th scope="col">Date</th>
                <th scope="col">Time</th>
              </tr>
            </thead>
            <tbody>
              {/* {submissions && JSON.stringify(submissions)} */}

              {submissions.map((item) => {
                return (
                  <tr className="">
                    <th className="">
                      <span>
                        <i
                          className="fa fa-eye"
                          aria-hidden="true"
                          onClick={() => {
                            setSmartDocVersionURL(item.docLink);
                            setSection("C");
                          }}
                        />
                        |
                        <i className="fa fa-trash" aria-hidden="true" />
                      </span>
                    </th>
                    <td>{item.submissionId.split("-")[0]}</td>
                    <td>{smartDocName}</td>

                    <td>YOUR ADMINNAME</td>
                    <td>{new Date(item.date).toDateString()}</td>
                    <td>{new Date(item.date).toLocaleTimeString()}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: "400px" }}
        >
          <h2 className="text-center m-5">
            NO SUBMISSIONS DONE YET PLEASE FILL THE RESPOND SECTION FIRST
          </h2>
        </div>
      )}
    </div>
  );
}
