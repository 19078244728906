import React, { Component } from 'react';

import '../style/agency.css';
import '../style/animate.css';
import FooterStyleModal from './FooterStyleModal';
import SocialModal from './SocialModal';


/**
 * Footer Section
 * this is the code of the footer section of the template
 * @module Footer
 */
class Footer extends Component {
  constructor(props){
    super();
    this.state={facebook:"",
  twitter:"",
  linkedin:"",
  toggleModal:"hide",

  footerstyles:{backgroundColor:'',
  height:'',
  padding:'',
  color:'' ,
  margin:'',
  copyright:"",
  policies:"",
  tou:"",
  dp:"",
  addressLine1:"",
  addressLine2:"",
  addressLine3:"",
  addressLine4:"",
  addressLine5:""
 },
showFooterModal:false};

  this.toggleFooterModal =this.toggleFooterModal.bind(this);
  this.saveSocialIcons = this.saveSocialIcons.bind(this);
  this.toggleFooterStyleModal = this.toggleFooterStyleModal.bind(this);
this.saveFooterStyle = this.saveFooterStyle.bind(this);
  }
  toggleFooterModal(){
    var status = "";
    if(this.state.toggleModal=="hide"){
      status = "show";
    }
    else{
      status = "hide";
    }
    
    this.setState({toggleModal:status});
    

   }
   saveSocialIcons(newfooterContent){

    
    this.setState({facebook:newfooterContent.facebook});
    this.setState({twitter:newfooterContent.twitter});
    this.setState({linkedin:newfooterContent.linkedin});
    

   }
   toggleFooterStyleModal(){

    var status = !this.state.showFooterModal;
    this.setState({showFooterModal:status});
    

   }

   componentDidMount(){
     var newStyle= this.props.footerstyles
    this.setState({footerstyles:this.props.footerstyles});
    this.setState({copyright:newStyle.copyright});
    this.setState({dp:newStyle.dp});
    this.setState({tou:newStyle.tou});
    this.setState({policies:newStyle.policies});
    this.setState({addressLine1:newStyle.addressLine1});
    this.setState({addressLine2:newStyle.addressLine2});
    this.setState({addressLine3:newStyle.addressLine3});
    this.setState({addressLine4:newStyle.addressLine4});
    this.setState({addressLine5:newStyle.addressLine5});
    
   }


   /**
 * saveFooterStyle()
 * This is the segment of code used to save footer style and elements.
 * @function saveFooterStyle()
 */

saveFooterStyle(newStyle){

  this.setState({footerstyles:newStyle});
  this.setState({copyright:newStyle.copyright});
  this.setState({dp:newStyle.dp});
  this.setState({tou:newStyle.tou});
  this.setState({policies:newStyle.policies});
  this.setState({addressLine1:newStyle.addressLine1});
  this.setState({addressLine2:newStyle.addressLine2});
  this.setState({addressLine3:newStyle.addressLine3});
  this.setState({addressLine4:newStyle.addressLine4});
  this.setState({addressLine5:newStyle.addressLine5});
  
  this.props.saveFooterProperties(newStyle);

}


    render(){
     
    return(



<div>

        {/* Footer */}
     
        <footer style={{backgroundColor:this.props.footerstyles.backgroundColor,height:this.props.footerstyles.height}} >

         <div >
{this.state.toggleModal=="hide" ?  '' :
<SocialModal toggleSocialIconModal={this.toggleFooterModal} saveSocialIcons={this.saveSocialIcons} socialIcon={this.state}/> 
}
{this.state.showFooterModal ? <FooterStyleModal   toggleStyleModal={this.toggleFooterStyleModal} saveStyle={this.saveFooterStyle}  copyright={this.state.copyright}  tou={this.state.tou} policies={this.state.policies} dp={this.state.dp} styleProp={this.state.footerstyles}/> : ''}
          <i className="fa editclas fa-edit" onClick={this.toggleFooterStyleModal}></i>
          <div className=""> {/* <div className="row footer-row">
          
       <div className="col-md-2"></div>
       
         <div className="col-md-3">
         <h4 className="footer-head" style={{fontSize:'18px',textAlign:'left'}}>Address</h4>
         <h4 className="address-text" style={{fontSize:'16px',textAlign:'left'}}>{this.state.addressLine1}</h4>
         <h4 className="address-text" style={{fontSize:'16px',textAlign:'left'}}>{this.state.addressLine2}</h4>
         <h4 className="address-text" style={{fontSize:'16px', textAlign:'left'}}>{this.state.addressLine3}</h4>
         <h4 className="address-text" style={{fontSize:'16px', textAlign:'left'}}>{this.state.addressLine4}</h4>
         <h4 className="address-text" style={{fontSize:'16px',textAlign:'left'}}>{this.state.addressLine5}</h4>
         </div>
         
         
         <div className="col-md-3">
         <h4 className="footer-head" style={{fontSize:'18px',textAlign:'left'}}>Our Policies</h4>
         <li className="footer-item" style={{listStyle:'none',textAlign:'left'}}>
                    <a href={this.state.policies}  style={{color:this.state.footerstyles.color}} target="_blank"><i class="fa fa-angle-double-right"></i>Privacy Policy</a>
                  </li>
                  <li className="footer-item" style={{listStyle:'none',textAlign:'left'}}>
                    <a href={this.state.tou}  style={{color:this.state.footerstyles.color}}><i class="fa fa-angle-double-right"></i>Terms of Use</a>
                  </li>
                  <li className="footer-item" style={{listStyle:'none',textAlign:'left'}}>
                    <a href={this.state.dp}  style={{color:this.state.footerstyles.color}}><i class="fa fa-angle-double-right"></i>Disclaimer Policy</a>
                  </li>

             
              
              
          
         </div>
         <div className="col-md-1"></div>
         
         <div className="col-md-3">
         <h4 className="footer-head" style={{fontSize:'18px'}}>Social Icon</h4>
         <ul className="social-buttons">
               
              
                 <li className="" style={{listStyle:'none'}}>
                  
                   <div className="row">
                   <div className="col-md-1"> <a href={this.state.twitter} target="_blank"><i className="fa fa-twitter" /> </a></div>
                   <div className="col-md-6"><h6 className="footer-item1">Twitter</h6></div>
                   
                   </div>
                  
                 </li>
                 <li className="" style={{listStyle:'none'}}>
                  <div className="row">
                   <div className="col-md-1"> <a href={this.state.facebook} target="_blank"> <i className="fa fa-facebook-f" /> </a></div>
                   <div className="col-md-6"><h6 className="footer-item1">Facebook</h6></div>
                   
                   </div>
                 
                  
                 </li>
                 <li className="" style={{listStyle:'none'}}>
                 <div className="row">
                   <div className="col-md-1"> <a href={this.state.linkedin} target="_blank"> <i className="fa fa-linkedin-in" /> </a></div>
                   <div className="col-md-6"> <h6 className="footer-item1"> Linked In</h6></div>
                   
                   </div>
                 
                   
                  
                   
                 </li>
                 <li className=" editclass" style={{listStyle:'none'}}>
                   <a href="javascript:void(0)"  onClick={this.toggleFooterModal} >
                     <i className="fa fa editclass fa-edit" />
                   </a>
                 </li>
               </ul>
         </div>         


</div>*/}
            
          
          <div className="row copyright-row" style={{backgroundColor:this.props.footerstyles}}>
          <div className="col-md-5"></div>
              <div className="col-md-2">
              <div className="copyright" style={{color:this.props.footerstyles.color}}>Copyright<i class="fa fa-copyright"></i>&nbsp;{this.state.copyright}</div>
               </div>
                <div className="col-md-5"></div>
             
            
            
            </div>
            </div>
            </div>
        </footer>

        
       </div>
    );
  

    }
}

export default Footer;