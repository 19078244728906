import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../../components/BreadCrumbs';
import BlogSidebar from '../../Blog/BlogSidebar';
import { apiBaseUrl20 } from '../../../components/apiUtils';

export default class EditBlogCategory extends React.Component{
    constructor(props){
        super(props)
        this.state={
            resData:[],
            category:''
        }
        this.handleChanges = this.handleChanges.bind(this)
    }

    componentDidMount(){
        let id = this.props.match.params.id
        this.fetchBlogById(id)
    }

    handleChanges=(e)=>{
        let target = e.target;
        let name = target.name;

        this.setState({[name]:target.value})
    }

    fetchBlogById(id){
        return axios({
            url: (apiBaseUrl20+'blogCategory/get/'+id),
            method: 'GET',
            crossDomain: true,
            responseType: 'json',
          }).then((response) => {
            this.setState({resData:response.data,category:response.data.category});
         });
    }

    updateBlogId(id){
        axios({
            url: (apiBaseUrl20+'blogCategory/update/' + id),
            method: 'POST',
            data: {
                category:this.state.category,
            }
          }).then((response) => {
            if(response.status===200){
              alert("Blog Category Updated");
              window.location.reload()
            }
          });
    }

    render(){
        return(
            <div className='row'>
                <div className="col-md-2 col-lg-2"><BlogSidebar/></div>
                <div className='col-md-10 col-lg-10'>
                <div className='Div'>
                <div className="row breadcrumb-row">
                    <div className="col-md-10">
                        <BreadCrumbs />
                    </div>
                </div>
                
                <div className="dedicated-page">
                <div className='row-resource-head'><h4 className='resource-head'>Blog Category </h4></div>
                
                <div className='row'>
                    <div className='dedicated-page'>
                        <div className='header-div2'>
                            <div className='bar' style={{borderTop:"#1E5B81", color: "blue"}}>
                                <div className='row'>
                                <div className='col-md-5 col-lg-5'/>
                                
                                <div className='content-div'>
                                <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span id="ember295" className="popovercontainer text-dashed-underline ember-view" data-original-title title>
                                  Category :
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <input type='text' className="detail-fields" name='category' value={this.state.category} onChange={this.handleChanges}/>
                                </div>
                              </div>

                            <div ClassName="col-md-4" style={{width : "80%", position:"relative", height:"90px"}}>
                                <button type='button' class="button1" style={{margin:'10px'}} id='save' onClick={()=>this.updateBlogId(this.state.resData.id)}>Update Category</button>
                            </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>
                </div>
            </div>
        )
    }
}