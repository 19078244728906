import React from 'react';
import axios from 'axios';
import BlogSidebar from '../../Blog/BlogSidebar';
import { Link } from 'react-router-dom';
import { apiBaseUrl20 } from '../../../components/apiUtils';

export default class PageBlogCategory extends React.Component{
    constructor(props){
        super(props)
        this.state={
            resData:[]
        }
    }

    componentDidMount(){
        let id = this.props.match.params.id
        this.fetchBlogById(id)
    }

    fetchBlogById(id){
        return axios({
            url: (apiBaseUrl20+'blogCategory/get/'+id),
            method: 'GET',
            crossDomain: true,
            responseType: 'json',
          }).then((response) => {
            this.setState({resData:response.data});
         });
    }

    deleteBlogId(id){
        if (window.confirm("Are you sure you want to delete this category ?")) {
            axios({
              url: (apiBaseUrl20+'blogCategory/get/' + id),
              method: 'Delete',
            }).then((response) => {
            if(response.status===200){
                alert("Blog Category Deleted");
                window.location.replace('/knowledgeHub/view-blogCategory/')
            }
            });
          }
    }

    render(){
        return(
            <div className='row'>
                <div className="col-md-2 col-lg-2"><BlogSidebar/></div>
                <div className='col-md-10 col-lg-10'>
                <div className="dedicated-div">
                <div className='row breadCrumb1-row'><h4>Blog Category : <div className='pull-right' style={{color:'#fff',marginRight:'3%',cursor:'pointer'}} onClick={()=>this.deleteBlogId(this.state.resData.id)}><i class="fas fa-trash-alt"></i> Delete </div><Link className="pull-right" style={{color:'#fff',marginRight:'3%'}} to={"/knowledgeHub/edit-blogCategory/"+this.state.resData.id}><i className="fa fa-edit"></i>EDIT</Link></h4></div>
                
                <div className='row'>
                    <div className='dedicated-page'>
                        <div className='header-div2'>
                            <div className='bar' style={{borderTop:"#1E5B81", color: "blue"}}>
                                <div className='row'>
                                <div className='col-md-5 col-lg-5'/>
                                
                                <div className='content-div'>
                                <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span id="ember295" className="popovercontainer text-dashed-underline ember-view" data-original-title title>
                                  Category :
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{this.state.resData.category}</h4>
                                </div>
                              </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>
            </div>
        )
    }
}