import React, { Component } from "react";
import "../../Professionals/profile.css";
import EditorJs from "react-editor-js";
import { EditorjsTool } from "../../Professionals/Editor/tool";
import { v1 as uuidv1 } from "uuid";
import Image from "@editorjs/image";
import axios from "axios";
import FileBase64 from "react-file-base64";
import Modal from "react-modal";
import PreviewConsultant from "./previewConsultant";

const customStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "30%",
    height: "60vh",
    position: "relative",
    transform: "translate(-50%, -50%)",
  },
};

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default class EditConsultant extends Component {
  constructor() {
    super();
    this.state = {
      validProfile: true,
      openCopy: false,
      profileId: "",
      stepperIndex: 0,
      stepperFlag: false,
      isTitle: 0,
      experienceIndex: 0,
      awardIndex: 0,
      skillArray: [],
      skill: "",
      experience_len: 0,
      award_len: 0,
      experienceArray: [],
      personalDetail: [],
      name: "",
      experience: "",
      practise: "",
      language: "",
      location: "",
      education: {
        highSchool: {
          school: "",
          coureType: "",
          passingYear: "",
          mark: "",
          markType: "",
        },
        interSchool: {
          school: "",
          coureType: "",
          passingYear: "",
          mark: "",
          markType: "",
        },
        graduation: {
          graduationCollege: "",
          coureTypeGraduation: "",
          graduationPassingYear: "",
          graduationMark: "",
          graduationMarkType: "",
        },
        postGraduation: {
          postCollege: "",
          courseTypePostGraduation: "",
          postPassingYear: "",
          postMark: "",
          postMarkType: "",
        },
      },
      award: [],
      selectedFile: null,
      profileDetail: [],
      profileImageUrl: "",
      isPreview: false,
      data: [],
      bio: [],
      bio_data: [],
      addExperience: false,
      checkExperience: false,
      showExperienceArray: false,
      showAwardArray: false,
      experienceFlag: false,
      awardFlag: false,
      educationFlag: false,
      bookingSlotFlag: false,
      workingDayFlag: false,
      addAward: false,
      checkAward: false,
      coverImageUrl: "",
      isCategory: 0,
      productIndex: 0,
      productDetailArray: [],
      product_len: 1,
      serviceImageUrl: "",
      flag_product: false,
      check_product_add: 1,
      tempProductDetail: [],
      isMorningSlot: true,
      isAfternoonSlot: true,
      isEveningSlot: true,
      morningSlot: "",
      afternoonSlot: "",
      eveningSlot: "",
      morningTimeArray: [],
      afternoonTimeArray: [],
      eveningTimeArray: [],
      bookingDetail: [],
      booking: 0,
      bookingDayArray: [],
      bookingSlotTab: 0,
      onlineConsultation: "",
      InpersonConsultation: "",
      feeDetail: [],
      freeOnlineConsultation: false,
      consultationOnlineFeeDisabled: true,
      freeOnlineConsultationDisabled: true,
      freeInPersonConsultation: false,
      consultationInPersonFeeDisabled: true,
      freeInPersonConsultationDisabled: true,
      email: localStorage.getItem("email"),
      allProfile: [],
      copyBookingTime: [],
      pasteTimingFlag: false,
      copyBookingDay: "",
      allDays: ["Mon", "Tue", "Wed", "Thurs", "Fri", "Sat", "Sun"],
    };
    this.handleTitle = this.handleTitle.bind(this);
    this.handlePersonalDetail = this.handlePersonalDetail.bind(this);
    this.handleBio = this.handleBio.bind(this);
    this.handleEducation = this.handleEducation.bind(this);
    this.getProductFiles = this.getProductFiles.bind(this);
    this.handleProductDetail = this.handlePersonalDetail.bind(this);
    this.addProductImage = this.addProductImage.bind(this);
    this.handleBookingTime = this.handleBookingTime.bind(this);
    this.handleMeetingTime = this.handleMeetingTime.bind(this);
    this.deleteBookingTime = this.deleteBookingTime.bind(this);
    this.addBookingDay = this.addBookingDay.bind(this);
    this.changeBookingTab = this.changeBookingTab.bind(this);
    this.addFeeAndBookDay = this.addFeeAndBookDay.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFreeInPersonConsultantChange = this.handleFreeInPersonConsultantChange.bind(this);
    this.handleFreeOnlineConsultantChange =  this.handleFreeOnlineConsultantChange.bind(this);
    this.closeCopy = this.closeCopy.bind(this);
    this.pasteBookingDetail = this.pasteBookingDetail.bind(this);

  }

  componentDidMount() {
      let id = this.props.match.params.id
    this.fetchDetailById(id);
  }

  fetchDetailById(id) {
    axios({
      url: "https://q07uwo7htc.execute-api.us-east-1.amazonaws.com/dev/businus/getConsultantById/"+id,
      method: "GET",
      crossDomain: true,
      response: "JSON",
    }).then((response) => {

            this.setState({
              allProfile: response.data,
              profileDataFlag: true,
              profileId: response.data.id,
            });
            this.setProfileData();
    });
  }

  setProfileData() {
    let allProfile = this.state.allProfile;
    let education = this.state.allProfile.personalDetail.education;
    let productDetailArray =
      this.state.allProfile.serviceDetail.length > 0
        ? this.state.allProfile.serviceDetail
        : [];
    let tempProductDetail = [];
    if (productDetailArray.length > 0) {
      for (var i = 1; i < productDetailArray.length; i++) {
        tempProductDetail.push(productDetailArray[i]);
      }
    }
    
    this.setState({
      profileImageUrl:
        allProfile.personalDetail.personalDetail.profileDetail.profileImageUrl,
      coverImageUrl:
        allProfile.personalDetail.personalDetail.profileDetail.coverImageUrl,
      name: allProfile.personalDetail.personalDetail.name,
      experience: allProfile.personalDetail.personalDetail.experience,
      location: allProfile.personalDetail.personalDetail.location,
      language: allProfile.personalDetail.personalDetail.language,
      practise: allProfile.personalDetail.personalDetail.practise,
      bio_data: allProfile.personalDetail.bio,
      education: allProfile.personalDetail.education,
      school10:
        education.highSchool.school !== undefined
          ? education.highSchool.school
          : "",
      coureType10:
        education.highSchool.coureType !== undefined
          ? education.highSchool.coureType
          : "",
      passingYear10:
        education.highSchool.passingYear !== undefined
          ? education.highSchool.passingYear
          : "",
      mark10:
        education.highSchool.mark !== undefined
          ? education.highSchool.mark
          : "",
      markType10:
        education.highSchool.markType !== undefined
          ? education.highSchool.markType
          : "",
      school12:
        education.interSchool.school !== undefined
          ? education.interSchool.school
          : "",
      coureType12:
        education.interSchool.coureType !== undefined
          ? education.interSchool.coureType
          : "",
      passingYear12:
        education.interSchool.passingYear !== undefined
          ? education.interSchool.passingYear
          : "",
      mark12:
        education.interSchool.mark !== undefined
          ? education.interSchool.mark
          : "",
      markType12:
        education.interSchool.markType !== undefined
          ? education.interSchool.markType
          : "",
      graduationCollege:
        education.graduation.graduationCollege !== undefined
          ? education.graduation.graduationCollege
          : "",
      coureTypeGraduation:
        education.graduation.courseTypeGraduation !== undefined
          ? education.graduation.courseTypeGraduation
          : "",
      graduationPassingYear:
        education.graduation.graduationPassingYear !== undefined
          ? education.graduation.graduationPassingYear
          : "",
      graduationMark:
        education.graduation.graduationMark !== undefined
          ? education.graduation.graduationMark
          : "",
      graduationMarkType:
        education.graduation.graduationMarkType !== undefined
          ? education.graduation.graduationMarkType
          : "",
      postCollege:
        education.postGraduation.postCollege !== undefined
          ? education.postGraduation.postCollege
          : "",
      courseTypePostGraduation:
        education.postGraduation.courseTypePostGraduation !== undefined
          ? education.postGraduation.courseTypePostGraduation
          : "",
      postPassingYear:
        education.postGraduation.postPassingYear !== undefined
          ? education.postGraduation.postPassingYear
          : "",
      postMark:
        education.postGraduation.postMark !== undefined
          ? education.postGraduation.postMark
          : "",
      postMarkType:
        education.postGraduation.postMarkType !== undefined
          ? education.postGraduation.postMarkType
          : "",
      experienceIndex: allProfile.personalDetail.experience.length,
      experienceArray:
        allProfile.personalDetail.experience.length > 0
          ? allProfile.personalDetail.experience
          : [],
      skillArray:
        allProfile.personalDetail.skill.length > 0
          ? allProfile.personalDetail.skill
          : [],
      award_len: allProfile.personalDetail.award.length,
      award:
        allProfile.personalDetail.award.length > 0
          ? allProfile.personalDetail.award
          : [],
      tempProductDetail: tempProductDetail,
      productDetailArray: productDetailArray,
      serviceImageUrl:
        allProfile.serviceDetail.length > 0
          ? allProfile.serviceDetail[0].serviceImageUrl
          : "",
      serviceName:
        allProfile.serviceDetail.length > 0
          ? allProfile.serviceDetail[0].serviceName
          : "",
      serviceDescription:
        allProfile.serviceDetail.length > 0
          ? allProfile.serviceDetail[0].serviceDescription
          : "",
      bookingDetail:
        allProfile.bookingDetail.length > 0 ? allProfile.bookingDetail : [],
      feeDetail: allProfile.feeDetail,
      onlineConsultation:
        allProfile.feeDetail.onlineConsultation !== undefined
          ? allProfile.feeDetail.onlineConsultation.onlineConsultation
          : "",
      onlineFee:
        allProfile.feeDetail.onlineConsultation !== undefined
          ? allProfile.feeDetail.onlineConsultation.onlineFee
          : "",
      freeOnlineConsultation:
        allProfile.feeDetail.onlineConsultation !== undefined
          ? allProfile.feeDetail.onlineConsultation.freeOnlineConsultation
          : "",
      consultationOnlineFeeDisabled:
        allProfile.feeDetail.onlineConsultation !== undefined
          ? allProfile.feeDetail.onlineConsultation.freeOnlineConsultation
          : "",
      freeOnlineConsultationDisabled: true,
      InpersonConsultation:
        allProfile.feeDetail.InpersonConsultation !== undefined
          ? allProfile.feeDetail.InpersonConsultation.InpersonConsultation
          : "",
      InPersonfee:
        allProfile.feeDetail.InpersonConsultation !== undefined
          ? allProfile.feeDetail.InpersonConsultation.InPersonfee
          : "",
      freeInPersonConsultation:
        allProfile.feeDetail.InpersonConsultation !== undefined
          ? allProfile.feeDetail.InpersonConsultation.freeInPersonConsultation
          : "",
      consultationInPersonFeeDisabled:
        allProfile.feeDetail.InpersonConsultation !== undefined
          ? allProfile.feeDetail.InpersonConsultation
              .consultationInPersonFeeDisabled
          : "",
      freeInPersonConsultationDisabled: true,
      check_product_add: allProfile.serviceDetail.length,
      product_len: allProfile.serviceDetail.length,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isCategory !== this.state.isCategory) {
      if (this.state.isCategory !== 2) {
        this.handleCategory(this, this.state.isCategory, false);
      }
    }

    if (prevState.experienceFlag !== this.state.experienceFlag) {
      if (this.state.experienceFlag) {
        this.showExperienceArray();
      }
    }

    if (prevState.awardFlag !== this.state.awardFlag) {
      if (this.state.awardFlag) {
        this.showAwardArray();
      }
    }

    if (prevState.educationFlag !== this.state.educationFlag) {
      if (this.state.educationFlag) {
        this.showEducation();
      }
    }

    if (prevState.bookingSlotTab !== this.state.bookingSlotTab) {
      if (this.state.bookingSlotTab === 0) {
        if (this.state.bookingDetail.length > 0) {
          this.addBookingDay(this.state.bookingDetail[0].day);
          this.navigateBookingDays(
            this.state.bookingDetail,
            this.state.booking,
            false
          );
        }
      } else {
        if (this.state.bookingSlotTab === 1) {
          this.showFeeDetail();
        }
      }
    }

    if (prevState.bookingSlotFlag !== this.state.bookingSlotFlag) {
      if (this.state.bookingSlotFlag) {
        this.showBookingSlot();
      }
    }

    if (prevState.workingDayFlag !== this.state.workingDayFlag) {
      if (this.state.workingDayFlag) {
        this.changeBookingTab("workingDay");
        if (this.state.bookingDetail.length > 0) {
          this.addBookingDay(this.state.bookingDetail[0].day);
           }
      }
    }
  }


  handleChange(e) {
    let target = e.target;
    let name = target.name;
    this.setState({ [name]: target.value });
  }

  handleFreeOnlineConsultantChange(e) {
    if (this.state.onlineFee !== "") {
      this.setState({ onlineFee: "" });
    }
    this.setState({
      freeOnlineConsultation: !this.state.freeOnlineConsultation,
      consultationOnlineFeeDisabled: !this.state.consultationOnlineFeeDisabled,
    });
  }

  handleFreeInPersonConsultantChange(e) {
    if (this.state.InPersonfee !== "") {
      this.setState({ InPersonfee: "" });
    }
    this.setState({
      freeInPersonConsultation: !this.state.freeInPersonConsultation,
      consultationInPersonFeeDisabled:
        !this.state.consultationInPersonFeeDisabled,
    });
  }

  handleProductDetail(e) {
    let target = e.target;
    let name = target.name;

    this.setState({ [name]: target.value });
  }

  handlePersonalDetail(e) {
    let target = e.target;
    let name = target.name;

    this.setState({ [name]: target.value });
  }

  handleBookingTime(e) {
    let value = e.target.value;
    if (value === "morning") {
      let isMorningSlot = !this.state.isMorningSlot;
      this.setState({ isMorningSlot: isMorningSlot, morningSlot: "morning" });
      if (isMorningSlot) {
        this.resetMeetingTime(value);
      }
    }
    if (value === "afternoon") {
      let isAfternoonSlot = !this.state.isAfternoonSlot;
      this.setState({
        isAfternoonSlot: isAfternoonSlot,
        afternoonSlot: "afternoon",
      });
      if (this.state.isAfternoonSlot) {
        this.resetMeetingTime(value);
      }
    }
    if (value === "evening") {
      let isEveningSlot = !this.state.isEveningSlot;
      this.setState({ isEveningSlot: isEveningSlot, eveningSlot: "evening" });
      if (isEveningSlot) {
        this.resetMeetingTime(value);
      }
    }
  }

  addPersonalDetail() {
    const that = this.state;
    let dataEntry = true;
    if (that.name === undefined) {
      dataEntry = false;
      alert("Please Enter the name");
    } else if (that.experience === undefined) {
      dataEntry = false;
      alert("Please Enter your experience");
    } else if (that.practise === undefined) {
      dataEntry = false;
      alert("Please Enter your practise");
    } else if (that.location === undefined) {
      dataEntry = false;
      alert("Please Enter your location");
    } else if (that.language === undefined) {
      dataEntry = false;
      alert("Please Enter your language");
    }

    if (dataEntry) {
      let profileDetail = {
        profileImageId: that.profileImageId,
        profileImageName: that.profileImageName,
        profileImageUrl: that.profileImageUrl,
        coverImageId: that.coverImageId,
        coverImageName: that.coverImageName,
        coverImageUrl: that.coverImageUrl,
      };
      let personalDetail = {
        name: that.name,
        experience: that.experience,
        practise: that.practise,
        location: that.location,
        language: that.language,
        profileDetail: profileDetail,
      };

      this.setState({ personalDetail: personalDetail });
      return true;
    } else {
      return false;
    }
  }

  async handleBio() {
    const content = await this.editorInstance.save();
    this.setState({ bio: content, bio_data: content });
  }

  handleEducation(e) {
    let education = this.state.education;

    let target = e.target;
    let name = target.name;

    this.setState({ [name]: target.value });
  }

  addEducation() {
    const that = this.state;
    let education = {
      highSchool: {
        school: that.school10,
        courseType: that.coureType10,
        passingYear: that.passingYear10,
        mark: that.mark10,
        markType: that.markType10,
      },
      interSchool: {
        school: that.school12,
        courseType: that.coureType12,
        passingYear: that.passingYear12,
        mark: that.mark12,
        markType: that.markType12,
      },
      graduation: {
        graduationCollege: that.graduationCollege,
        courseTypeGraduation: that.coureTypeGraduation,
        graduationPassingYear: that.graduationPassingYear,
        graduationMark: that.graduationMark,
        graduationMarkType: that.graduationMarkType,
      },
      postGraduation: {
        postCollege: that.postCollege,
        courseTypePostGraduation: that.coureTypePostGraduation,
        postPassingYear: that.postPassingYear,
        postMark: that.postMark,
        postMarkType: that.postMarkType,
      },
    };
    this.setState({ education: education });
  }

  handleCourseType(id, index) {
    document.getElementById(id).classList.add("courseType-active");

    switch (index) {
      case 0:
        this.setState({ coureType10: "Full Time" });
        document
          .getElementById("partTime10")
          .classList.remove("courseType-active");
        break;
      case 1:
        this.setState({ coureType10: "Part Time" });
        document
          .getElementById("fullTime10")
          .classList.remove("courseType-active");
        break;
      case 2:
        this.setState({ coureType12: "Full Time" });
        document
          .getElementById("partTime12")
          .classList.remove("courseType-active");
        break;
      case 3:
        this.setState({ coureType12: "Part Time" });
        document
          .getElementById("fullTime12")
          .classList.remove("courseType-active");
        break;
      case 4:
        this.setState({ coureTypeGraduation: "Full Time" });
        document
          .getElementById("partTimeGraduation")
          .classList.remove("courseType-active");
        break;
      case 5:
        this.setState({ coureTypeGraduation: "Part Time" });
        document
          .getElementById("fullTimeGraduation")
          .classList.remove("courseType-active");
        break;
      case 6:
        this.setState({ coureTypePostGraduation: "Full Time" });
        document
          .getElementById("partTimePostGraduation")
          .classList.remove("courseType-active");
        break;
      case 7:
        this.setState({ coureTypePostGraduation: "Part Time" });
        document
          .getElementById("fullTimePostGraduation")
          .classList.remove("courseType-active");
        break;
    }
  }

  setSkill(e) {
    this.setState({ skill: e });
  }

  handleCategory(that, index, bookingCond) {
    let cond = that.addPersonalDetail();

    if (index === 0) {
      this.setState({ isTitle: 0 });
    }

    if (cond) {
      let category = document.getElementById(that.state.isCategory);
      category.classList.remove("add-user-i-active");
      document.getElementById(index).classList.add("add-user-i-active");
      let temp = this.state.tempProductDetail;

      if (that.state.check_product_add !== that.state.product_len) {
        for (var i = temp.length + 1; i < this.state.product_len; i++) {
          temp.unshift({
            serviceImageUrl: document.getElementById("productImage_" + i).src,
            serviceName: document.getElementById("serviceName_" + i).value,
            serviceDescription: document.getElementById(
              "serviceDescription_" + i
            ).value,
          });
          that.setState({
            tempProductDetail: temp,
            check_product_add: that.state.product_len,
            serviceImageUrl: document.getElementById("productImage_0").src,
          });
          // that.addProductDetailArray
        }
      } else {
        for (var i = 0; i < temp.length; i++) {
          if (document.getElementById("serviceName_" + (i + 1)) !== null) {
            // document.getElementById("productImage_"+i).src=temp[i].serviceImageUrl
            document.getElementById("serviceName_" + (i + 1)).value =
              temp[i].serviceName;
            document.getElementById("serviceDescription_" + (i + 1)).value =
              temp[i].serviceDescription;
          }
          console.log("temp", "serviceName_" + (i + 1));
        }
      }
      that.setState({ isCategory: index, flag_product: true });
    }

    if (bookingCond) {
      this.setState({ workingDayFlag: true });
      this.addProductDetailArray();
    }
  }

  handleTitle(that, index) {
    let nav = "next";
    if (index < that.state.isTitle) {
      nav = "prev";
    }

    if (that.state.checkAward) {
      if (that.state.award_len !== 0) {
        if (index === 5) {
          for (var i = 1; i < that.state.award_len + 1; i++) {
            document.getElementById("award-show-" + i).style.display = "block";
          }
        } else {
          for (var i = 1; i < that.state.award_len + 1; i++) {
            document.getElementById("award-show-" + i).style.display = "none";
          }
        }
      }
    }

    if (that.state.checkExperience) {
      if (that.state.experience_len !== 0) {
        if (index === 3) {
          for (var i = 1; i < that.state.experience_len + 1; i++) {
            document.getElementById("experience-show-" + i).style.display =
              "block";
          }
        } else {
          for (var i = 1; i < that.state.experience_len + 1; i++) {
            document.getElementById("experience-show-" + i).style.display =
              "none";
          }
        }
      }
    }
    console.log("exp", that.state.checkExperience);
    // let title = document.getElementById(that.state.isTitle)
    // title.classList.remove("add-user-i-active")
    // document.getElementById(index).classList.add('add-user-i-active')
    // that.setState({isTitle:index})
  }

  uploadByFile(file) {
    let fd = new FormData();
    fd.append("file", file);
  }

  addProductDetail() {
    let index = this.state.productIndex + 1;
    this.setState({ productIndex: index, check_product_add: index });

    var br = document.createElement("br");
    var out_div = document.createElement("div");
    out_div.setAttribute("id", "product-d-show-" + index);

    var in_div_I = document.createElement("div");
    var in_div_PN = document.createElement("div");
    var in_div_PD = document.createElement("div");

    var img_I = document.createElement("img");
    var text_div_PN = document.createElement("div");
    var text_div_PD = document.createElement("div");

    var label_PN = document.createElement("label");
    var label_PD = document.createElement("label");
    // var file_Base64 = document.createElement('FileBase64')

    var serviceName = document.createElement("input");
    var serviceDescription = document.createElement("input");
    var image = document.createElement("input");
    var uploadbtn = document.createElement("button");

    out_div.setAttribute("class", "row product-d");
    in_div_I.setAttribute("class", "col-md-4 col-lg-4 col-sm-12");
    in_div_PD.setAttribute("class", "col-md-8 col-lg-8 col-sm-12");
    in_div_PN.setAttribute("class", "col-md-8 col-lg-8 col-sm-12");

    img_I.setAttribute("class", "profileImageUrl");
    img_I.setAttribute("id", "productImage_" + index);
    img_I.setAttribute(
      "src",
      "https://businus-files-live.s3.amazonaws.com/default-image/service.png"
    );
    text_div_PD.setAttribute("class", "col-md-6 col-md-lg-6 col-sm-6");
    text_div_PN.setAttribute("class", "col-md-6 col-lg-6 col-sm-6");

    label_PD.setAttribute("class", "control-label col-md-4 col-lg-4 col-sm-6");
    label_PN.setAttribute("class", "control-label col-md-4 col-lg-4 col-sm-6");
    image.setAttribute("type", "file");
    image.setAttribute("multiple", "true");
    image.setAttribute("accept", "image/*");
    image.setAttribute("id", "productFile_" + index);
    image.addEventListener(
      "change",
      function () {
        var image = document.getElementById("productFile_" + index);
        if (image !== null) {
          image = image.files[0];
          var fileBase = "";
          const reader = new FileReader();
          reader.readAsDataURL(image);
          reader.onload = function () {
            fileBase = reader.result;
            this.fileUploadHandler(
              this,
              fileBase,
              "productImageId",
              "productImage_" + index
            );
          }.bind(this);
        }
      }.bind(this)
    );

    // uploadbtn.innerHTML='Upload Image'
    // uploadbtn.setAttribute('class','uploadProductImage')
    // uploadbtn.addEventListener('click',this.getProductFiles.bind(this))

    in_div_I.appendChild(img_I);
    in_div_I.appendChild(image);
    // in_div_I.appendChild(uploadbtn)
    out_div.appendChild(in_div_I);

    label_PN.innerHTML = "Product Name";
    serviceName.setAttribute(
      "class",
      "form-control ember-text-field ember-view"
    );
    serviceName.setAttribute("type", "text");
    serviceName.setAttribute("placeholder", "Enter the product name");
    serviceName.setAttribute("id", "serviceName_" + index);
    text_div_PN.appendChild(serviceName);
    in_div_PN.appendChild(label_PN);
    in_div_PN.appendChild(text_div_PN);
    out_div.appendChild(in_div_PN);

    label_PD.innerHTML = "Product Description";
    serviceDescription.setAttribute(
      "class",
      "form-control ember-text-field ember-view"
    );
    serviceDescription.setAttribute("type", "text");
    serviceDescription.setAttribute(
      "placeholder",
      "Enter more about the product"
    );
    serviceDescription.setAttribute("id", "serviceDescription_" + index);
    text_div_PD.appendChild(serviceDescription);
    in_div_PD.appendChild(label_PD);
    in_div_PD.appendChild(text_div_PD);
    out_div.appendChild(in_div_PD);

    document.getElementById("productDetail").appendChild(out_div);
    document.getElementById("productDetail").appendChild(br);

    this.setState({ product_len: this.state.product_len + 1 });
  }

  addExperience() {
    let index = this.state.experienceIndex + 1;
    this.setState({ experienceIndex: index });
    var out_div = document.createElement("div");
    out_div.setAttribute("id", "experience-show-" + index);

    var in_div_T = document.createElement("div");
    var in_div_EMP = document.createElement("div");
    var in_div_C = document.createElement("div");
    var in_div_L = document.createElement("div");
    var in_div_S = document.createElement("div");
    var in_div_E = document.createElement("div");
    var in_div_D = document.createElement("div");

    var text_div_T = document.createElement("div");
    var text_div_EMP = document.createElement("div");
    var text_div_C = document.createElement("div");
    var text_div_L = document.createElement("div");
    var text_div_S = document.createElement("div");
    var text_div_E = document.createElement("div");
    var text_div_D = document.createElement("div");

    var label_T = document.createElement("label");
    var label_EMP = document.createElement("label");
    var label_C = document.createElement("label");
    var label_L = document.createElement("label");
    var label_S = document.createElement("label");
    var label_E = document.createElement("label");
    var label_D = document.createElement("label");

    var title = document.createElement("input");
    var employmentType = document.createElement("input");
    var companyName = document.createElement("input");
    var location = document.createElement("input");
    var startDate = document.createElement("input");
    var endDate = document.createElement("input");
    var description = document.createElement("input");
    var br = document.createElement("br");

    out_div.setAttribute("class", "experience-d ");
    // out_div.style.width = '80%'
    in_div_T.setAttribute("class", "form-group");
    in_div_EMP.setAttribute("class", "form-group");
    in_div_C.setAttribute("class", "form-group");
    in_div_L.setAttribute("class", "form-group");
    in_div_S.setAttribute("class", "form-group");
    in_div_E.setAttribute("class", "form-group");
    in_div_D.setAttribute("class", "form-group");

    text_div_T.setAttribute("class", "col-md-6 col-lg-6");
    text_div_EMP.setAttribute("class", "col-md-6 col-lg-6");
    text_div_C.setAttribute("class", "col-md-6 col-lg-6");
    text_div_L.setAttribute("class", "col-md-6 col-lg-6");
    text_div_S.setAttribute("class", "col-md-6 col-lg-6");
    text_div_E.setAttribute("class", "col-md-6 col-lg-6");
    text_div_D.setAttribute("class", "col-md-6 col-lg-6");

    label_T.setAttribute("class", "control-label col-md-4 col-lg-4");
    label_EMP.setAttribute("class", "control-label col-md-4 col-lg-4");
    label_C.setAttribute("class", "control-label col-md-4 col-lg-4");
    label_L.setAttribute("class", "control-label col-md-4 col-lg-4");
    label_S.setAttribute("class", "control-label col-md-4 col-lg-4");
    label_E.setAttribute("class", "control-label col-md-4 col-lg-4");
    label_D.setAttribute("class", "control-label col-md-4 col-lg-4");

    // out_div.appendChild(in_div);

    label_T.innerHTML = "Title";
    title.setAttribute("class", "form-control ember-text-field ember-view");
    title.setAttribute("type", "text");
    title.setAttribute("placeholder", "Title");
    title.setAttribute("id", "title_" + index);
    text_div_T.appendChild(title);
    in_div_T.appendChild(label_T);
    in_div_T.appendChild(text_div_T);
    out_div.appendChild(in_div_T);

    label_EMP.innerHTML = "Employement Type";
    employmentType.setAttribute(
      "class",
      "form-control ember-text-field ember-view"
    );
    employmentType.setAttribute("type", "text");
    employmentType.setAttribute("placeholder", "Employement Type");
    employmentType.setAttribute("id", "employementType_" + index);
    text_div_EMP.appendChild(employmentType);
    in_div_EMP.appendChild(label_EMP);
    in_div_EMP.appendChild(text_div_EMP);
    out_div.appendChild(in_div_EMP);

    label_C.innerHTML = "Company Name";
    companyName.setAttribute(
      "class",
      "form-control ember-text-field ember-view"
    );
    companyName.setAttribute("type", "text");
    companyName.setAttribute("placeholder", "Company Name");
    companyName.setAttribute("id", "companyName_" + index);
    text_div_C.appendChild(companyName);
    in_div_C.appendChild(label_C);
    in_div_C.appendChild(text_div_C);
    out_div.appendChild(in_div_C);

    label_L.innerHTML = "Location";
    location.setAttribute("class", "form-control ember-text-field ember-view");
    location.setAttribute("type", "text");
    location.setAttribute("placeholder", "Location");
    location.setAttribute("id", "location_" + index);
    text_div_L.appendChild(location);
    in_div_L.appendChild(label_L);
    in_div_L.appendChild(text_div_L);
    out_div.appendChild(in_div_L);

    label_S.innerHTML = "Start Date";
    // startDate.setAttribute("class","form-control ember-text-field ember-view");
    startDate.setAttribute("type", "date");
    startDate.setAttribute("placeholder", "Start Date");
    startDate.setAttribute("id", "startDate_" + index);
    text_div_S.appendChild(startDate);
    in_div_S.appendChild(label_S);
    in_div_S.appendChild(text_div_S);
    out_div.appendChild(in_div_S);

    label_E.innerHTML = "End Date";
    // endDate.setAttribute("class","form-control ember-text-field ember-view");
    endDate.setAttribute("type", "date");
    endDate.setAttribute("placeholder", "End Date");
    endDate.setAttribute("id", "endDate_" + index);
    text_div_E.appendChild(endDate);
    in_div_E.appendChild(label_E);
    in_div_E.appendChild(text_div_E);
    out_div.appendChild(in_div_E);

    label_D.innerHTML = "Description";
    description.setAttribute(
      "class",
      "form-control ember-text-field ember-view"
    );
    description.setAttribute("type", "text");
    description.setAttribute("placeholder", "Description");
    description.setAttribute("id", "description_" + index);
    text_div_D.appendChild(description);
    in_div_D.appendChild(label_D);
    in_div_D.appendChild(text_div_D);
    out_div.appendChild(in_div_D);

    // if(this.state.isTitle===3){
    document.getElementById("experience").appendChild(out_div);
    document.getElementById("experience").appendChild(br);
    // }

    this.setState({
      experience_len: this.state.experience_len + 1,
      addExperience: true,
    });
  }

  addProductDetailArray() {
    let productDetailArray = this.state.productDetailArray;
    if (document.getElementById("serviceName_0").value !== "") {
      console.log("I am Called 1");
      if (
        this.state.check_product_add !== this.state.productDetailArray.length
      ) {
        console.log("I am Called 2");
        for (var i = 0; i < this.state.product_len; i++) {
          console.log("I am Called 3");
          productDetailArray.unshift({
            serviceImageUrl: document.getElementById("productImage_" + i).src,
            serviceName: document.getElementById("serviceName_" + i).value,
            serviceDescription: document.getElementById(
              "serviceDescription_" + i
            ).value,
          });
        }
      }
    }
    this.setState({
      productDetailArray: productDetailArray,
      check_product_add: this.state.product_len,
    });
    // this.handlePreview(this)
  }

  addExperienceArray() {
    let experienceArray = [];
    let index = this.state.experienceIndex;

    if (index > 0) {
      for (var i = 1; i < this.state.experience_len + 1; i++) {
        if (document.getElementById("title_" + i).value !== "") {
          experienceArray.unshift({
            title: document.getElementById("title_" + i).value,
            employementType: document.getElementById("employementType_" + i)
              .value,
            companyName: document.getElementById("companyName_" + i).value,
            location: document.getElementById("location_" + i).value,
            startDate: document.getElementById("startDate_" + i).value,
            endDate: document.getElementById("endDate_" + i).value,
            description: document.getElementById("description_" + i).value,
          });
        }
        // else{
        //     if(confirm("Title is mandatory. If not added experience won't be saved")){

        //     }
        // }
      }
      this.setState({ experienceArray: experienceArray, addExperience: false });
    }
  }

  showExperienceArray() {
    let index = this.state.experienceIndex;
    let experienceArray = this.state.experienceArray;
    console.log("experience", document.getElementById("title_1"));
    if (index > 0) {
      for (var i = 1; i < index + 1; i++) {
        document.getElementById("title_" + i).value =
          experienceArray[i - 1].title;
        document.getElementById("employementType_" + i).value =
          experienceArray[i - 1].employementType;
        document.getElementById("companyName_" + i).value =
          experienceArray[i - 1].companyName;
        document.getElementById("location_" + i).value =
          experienceArray[i - 1].location;
        document.getElementById("startDate_" + i).value =
          experienceArray[i - 1].startDate;
        document.getElementById("endDate_" + i).value =
          experienceArray[i - 1].endDate;
        document.getElementById("description_" + i).value =
          experienceArray[i - 1].description;
      }
    }
    this.setState({ experienceFlag: false });
  }

  showEducation() {
    console.log("update", this.state.education);
    if (this.state.education.highSchool.school !== "") {
      if (this.state.education.highSchool.coureType !== "") {
        if (this.state.education.highSchool.coureType === "Full Time") {
          document
            .getElementById("fullTime10")
            .classList.add("courseType-active");
        } else if (this.state.education.highSchool.coureType === "Part Time") {
          document
            .getElementById("partTime10")
            .classList.add("courseType-active");
        }
      }
      if (this.state.education.highSchool.markType !== "") {
        if (this.state.education.highSchool.markType === "percentage") {
          document.getElementById("percentage10").checked = true;
        } else if (this.state.education.highSchool.markType === "cgpa") {
          document.getElementById("cgpa10").checked = true;
        }
      }
    }

    if (this.state.education.interSchool.school !== "") {
      if (this.state.education.interSchool.coureType !== "") {
        if (this.state.education.interSchool.coureType === "Full Time") {
          document
            .getElementById("fullTime12")
            .classList.add("courseType-active");
        } else if (this.state.education.interSchool.coureType === "Part Time") {
          document
            .getElementById("partTime12")
            .classList.add("courseType-active");
        }
      }
      if (this.state.education.interSchool.markType !== "") {
        if (this.state.education.interSchool.markType === "percentage") {
          document.getElementById("percentage12").checked = true;
        } else if (this.state.education.interSchool.markType === "cgpa") {
          document.getElementById("cgpa12").checked = true;
        }
      }
    }

    if (this.state.education.graduation.graduationCollege !== "") {
      if (this.state.education.graduation.coureTypeGraduation !== "") {
        if (
          this.state.education.graduation.coureTypeGraduation === "Full Time"
        ) {
          document
            .getElementById("fullTimeGraduation")
            .classList.add("courseType-active");
        } else if (
          this.state.education.graduation.coureTypeGraduation === "Part Time"
        ) {
          document
            .getElementById("partTimeGraduation")
            .classList.add("courseType-active");
        }
      }
      if (this.state.education.graduation.graduationMarkType !== "") {
        if (
          this.state.education.graduation.graduationMarkType === "percentage"
        ) {
          document.getElementById("graduationPercentage").checked = true;
        } else if (
          this.state.education.graduation.graduationMarkType === "cgpa"
        ) {
          document.getElementById("graduationCgpa").checked = true;
        }
      }
    }
    if (this.state.education.postGraduation.postCollege !== "") {
      if (this.state.education.postGraduation.courseTypePostGraduation !== "") {
        if (
          this.state.education.postGraduation.courseTypePostGraduation ===
          "Full Time"
        ) {
          document
            .getElementById("fullTimePostGraduation")
            .classList.add("courseType-active");
        } else if (
          this.state.education.postGraduation.courseTypePostGraduation ===
          "Part Time"
        ) {
          document
            .getElementById("partTimePostGraduation")
            .classList.add("courseType-active");
        }
      }
      if (this.state.education.postGraduation.postMarkType !== "") {
        if (this.state.education.postGraduation.postMarkType === "percentage") {
          document.getElementById("postPercentage").checked = true;
        } else if (
          this.state.education.postGraduation.postMarkType === "cgpa"
        ) {
          document.getElementById("postCgpa").checked = true;
        }
      }
    }
    this.setState({ educationFlag: false });
  }

  showBookingSlot() {
    if (this.state.feeDetail.length > 0) {
      if (this.state.feeDetail.consultationType !== "") {
        if (
          this.state.feeDetail.consultationType ===
          "Online Consultation / Telephonic"
        ) {
          document.getElementById("online_c").classList.add("consult-active");
        } else {
          document.getElementById("inperson_c").classList.add("consult-active");
        }
      }
    }
    this.setState({ bookingSlotFlag: false });
  }

  addSkill(skill) {
    let array = this.state.skillArray;
    array.unshift({
      key: array.length + 1,
      skill: skill,
    });
    this.setState({ skillArray: array, skill: "" });
  }

  handleDelete(key) {
    let array = this.state.skillArray;
    for (var i = 0; i < array.length; i++) {
      if (array[i].key === key) {
        array.splice(i, 1);
      }
    }
    this.setState({ skillArray: array });
  }

  addAward() {
    let index = this.state.awardIndex + 1;
    this.setState({ awardIndex: index });

    var out_div = document.createElement("div");
    var in_div = document.createElement("div");
    var text_div = document.createElement("div");
    var label = document.createElement("label");
    var award = document.createElement("input");
    var br = document.createElement("br");

    out_div.setAttribute("id", "award-show-" + index);
    label.innerHTML = "Award " + index;
    award.setAttribute("class", "form-control ember-text-field ember-view");
    award.setAttribute("type", "text");
    award.setAttribute("placeholder", "Award " + index);
    award.setAttribute("id", "award_" + index);
    text_div.appendChild(award);
    in_div.appendChild(label);
    in_div.appendChild(text_div);
    out_div.appendChild(in_div);

    document.getElementById("award").appendChild(out_div);
    document.getElementById("award").appendChild(br);

    this.setState({ award_len: this.state.award_len + 1, addAward: true });
  }

  addAwardArray() {
    let award = [];
    if (this.state.addAward) {
      for (var i = 1; i < this.state.award_len + 1; i++) {
        award.unshift({
          award: document.getElementById("award_" + i).value,
        });
      }
      this.setState({ award: award, addAward: false });
    }
  }

  showAwardArray() {
    let index = this.state.award_len;
    let award = this.state.award;
    if (index > 0) {
      for (var i = 1; i < index + 1; i++) {
        document.getElementById("award_" + i).value = award[i - 1].award;
      }
    }
    this.setState({ awardFlag: false });
  }

  handleNavigation(index, nav, isTitle) {
    let experienceFlag = this.state.experienceFlag;
    let awardFlag = this.state.awardFlag;
    let educationFlag = this.state.educationFlag;
    let bookingSlotFlag = this.state.bookingSlotFlag;
    let showExperienceArray = this.state.showExperienceArray;
    let showAwardArray = this.state.showAwardArray;
    // let workingDayFlag = this.state.workingDayFlag

    const progressText = document.querySelectorAll(".step p");
    const progressCheck = document.querySelectorAll(".step .check");
    const bullet = document.querySelectorAll(".step .bullet");

    let current = 1;

    if (nav === "next") {
      // if(isTitle<5){
      //     isTitle+=1
      // }

      switch (index) {
        case 0:
          let cond = this.addPersonalDetail();
          if (cond) {
            bullet[index].classList.add("active");
            progressCheck[index].classList.add("active");
            progressText[index].classList.add("active");
            // isTitle=1
          } else {
            isTitle = 0;
          }
          break;
        case 1:
          // current=index+1
          bullet[index].classList.add("active");
          progressCheck[index].classList.add("active");
          progressText[index].classList.add("active");
          this.handleBio();
          educationFlag = true;
          // isTitle=2
          break;
        case 2:
          // current=index+1
          bullet[index].classList.add("active");
          progressCheck[index].classList.add("active");
          progressText[index].classList.add("active");
          this.addEducation();
          experienceFlag = true;
          if (this.state.experienceIndex > 0) {
            showExperienceArray = true;
          }
          // isTitle=3
          break;
        case 3:
          // current=index+1
          bullet[index].classList.add("active");
          progressCheck[index].classList.add("active");
          progressText[index].classList.add("active");
          this.addExperienceArray();
          // isTitle=4
          break;
        case 4:
          // current=index+1
          bullet[index].classList.add("active");
          progressCheck[index].classList.add("active");
          progressText[index].classList.add("active");
          // this.addSkill()
          // isTitle=5
          awardFlag = true;
          if (this.state.award_len > 0) {
            showAwardArray = true;
          }

          break;
        case 5:
          // current=index+1
          bullet[index].classList.add("active");
          progressCheck[index].classList.add("active");
          this.addAwardArray();
          this.handleCategory(this, 1, false);
          // isTitle=6
          break;
        case 6:
          this.addProductDetailArray();
          // workingDayFlag=true
          this.handleCategory(this, 2, true);
          // this.changeBookingTab("workingDay")
          // isTitle=7
          break;
        case 7:
          console.log("check product", this.state.productDetailArray);
          this.changeBookingTab("fee");
          bookingSlotFlag = true;
          // this.addBookingDetails()
          // isTitle=8
          break;
        case 8:
          this.addFeeAndBookDay(this);
          this.handlePreview(this);
          break;
      }
    } else {
      // isTitle-=1
      switch (index) {
        case 8:
          this.changeBookingTab("workingDay");
          // isTitle=7
          break;
        case 7:
          this.handleCategory(this, 1, false);
          // isTitle=6
          break;
        case 6:
          // bullet[index-1].classList.remove('active')
          // progressCheck[index-1].classList.remove('active')
          // progressText[index-1].classList.remove('active')
          // // isTitle=5
          // awardFlag=true
          this.handleCategory(this, 0, false);
          break;
        case 5:
          bullet[index - 1].classList.remove("active");
          progressCheck[index - 1].classList.remove("active");
          progressText[index - 1].classList.remove("active");
          this.addAwardArray();
          // isTitle=4
          break;
        case 4:
          bullet[index - 1].classList.remove("active");
          progressCheck[index - 1].classList.remove("active");
          progressText[index - 1].classList.remove("active");
          // index=3
          experienceFlag = true;
          if (this.state.experienceIndex > 0) {
            showExperienceArray = true;
          }
          break;
        case 3:
          bullet[index - 1].classList.remove("active");
          progressCheck[index - 1].classList.remove("active");
          progressCheck[index - 1].classList.remove("active");
          this.addExperienceArray();
          educationFlag = true;
          // isTitle=2
          break;
        case 2:
          bullet[index - 1].classList.remove("active");
          progressCheck[index - 1].classList.remove("active");
          progressText[index - 1].classList.remove("active");
          this.addEducation();
          // isTitle=1
          break;
        case 1:
          bullet[index - 1].classList.remove("active");
          progressCheck[index - 1].classList.remove("active");
          progressText[index - 1].classList.remove("active");
          this.handleBio();
          // isTitle=0
          break;
        case 0:
          let cond = this.addPersonalDetail();
          if (cond) {
            bullet[index - 1].classList.remove("active");
            progressCheck[index - 1].classList.remove("active");
            progressText[index - 1].classList.remove("active");
          }
          break;
      }
    }

    this.setState({
      isTitle: isTitle,
      stepperIndex: index,
      experienceFlag: experienceFlag,
      awardFlag: awardFlag,
      educationFlag: educationFlag,
      bookingSlotFlag: bookingSlotFlag,
      showExperienceArray: showExperienceArray,
      showAwardArray: showAwardArray,
    });
    // this.handleTitle(this,isTitle)
  }

  handlePreview(that) {
    let personalDetails = {
      personalDetail: this.state.personalDetail,
      bio: this.state.bio,
      education: this.state.education,
      experience: this.state.experienceArray,
      skill: this.state.skillArray,
      award: this.state.award,
    };
    // console.log("bio",this.state.bookingDetail)
    that.setState({ isPreview: true, data: personalDetails });
  }

  getMoreProductFiles(i) {
    var image = document.getElementById("productFile_" + i).files[0];
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = function () {
      var fileBase = reader.result;
      this.fileUploadHandler(
        this,
        fileBase,
        "productImageId",
        "productImage_" + i
      );
    };
  }

  getProductFiles(files) {
    this.setState({ selectedFile: files[0], productImageName: files[0].name });
    this.fileUploadHandler(
      this,
      files[0].base64,
      "productImageId",
      "productImage_0"
    );
  }

  getFiles(files) {
    this.setState({ selectedFile: files[0], profileImageName: files[0].name });
    this.fileUploadHandler(
      this,
      files[0].base64,
      "profileImageId",
      "profileImageUrl"
    );
  }

  getFilesCover(files) {
    this.setState({ selectedFile: files[0], coverImageName: files[0].name });
    this.fileUploadHandler(
      this,
      files[0].base64,
      "coverImageId",
      "coverImageUrl"
    );
  }

  addProductImage(imageId) {
    var image = document.getElementById(imageId);
    if (image !== null) {
      image = image.files[0];
    }
    // var reader = new FileReader
  }

  async fileUploadHandler(that, selectedFile, imageId, imageUrl) {
    const id = uuidv1();

    that.setState({ loading: true });

    await axios
      .post(
        "https://8cop2jp1c8.execute-api.us-east-1.amazonaws.com/dev/businus/upload/profileImage",
        {
          image: selectedFile,
          id: id,
        }
      )
      .then(function (response) {
        if (response.status == 200) {
          if (imageId === "productImageId") {
            that.setState({ successupload: true, [imageId]: id });
            document.getElementById(imageUrl).src =
              "https://businus-files-live.s3.amazonaws.com/profile/" + id + ".png";
          } else {
            that.setState({
              successupload: true,
              [imageId]: id,
              [imageUrl]:
                "https://businus-files-live.s3.amazonaws.com/profile/" + id + ".png",
            });
          }
        } 
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  addBookingDay(bookingDayValue) {
    let bookingDetail = this.state.bookingDetail;
    let booking = this.state.booking;
    let dayInArray = true;

    let bookingJSON = {
      morning: [],
      afternoon: [],
      evening: [],
    };

    if (bookingDetail.length > 0) {
      for (var i = 0; i < bookingDetail.length; i++) {
        if (bookingDayValue === bookingDetail[i].day) {
          dayInArray = false;
          booking = i + 1;
          break;
        }
      }
    }

    if (dayInArray) {
      this.resetMeetingTime();
      bookingDetail.splice(booking, 0, {
        day: bookingDayValue,
        booking: bookingJSON,
      });
      booking = booking + 1;

    } else {
      this.navigateBookingDays(bookingDetail, booking, true);

    }

    for (var i = 0; i < bookingDetail.length; i++) {
      if (bookingDayValue === bookingDetail[i].day) {
        document
          .getElementById(bookingDetail[i].day)
          .classList.remove("active-booking");
        document
          .getElementById(bookingDetail[i].day)
          .classList.add("currentActiveBooking");
      } else {
        // console.log('booking',bookingDetail[i].day)
        document
          .getElementById(bookingDetail[i].day)
          .classList.remove("currentActiveBooking");
        document
          .getElementById(bookingDetail[i].day)
          .classList.add("active-booking");
      }
    }

    this.setState({ booking: booking, bookingDetail: bookingDetail });
  }

  navigateBookingDays(bookingDetail, booking, resetCall) {
    if (resetCall) {
      this.resetMeetingTime();
    }
    let currentBookingDetail = [];
    switch (booking) {
      case 1:
        currentBookingDetail = bookingDetail[0].booking;
        break;
      case 2:
        currentBookingDetail = bookingDetail[1].booking;
        break;
      case 3:
        currentBookingDetail = bookingDetail[2].booking;
        break;
      case 4:
        currentBookingDetail = bookingDetail[3].booking;
        break;
      case 5:
        currentBookingDetail = bookingDetail[4].booking;
        break;
      case 6:
        currentBookingDetail = bookingDetail[5].booking;
        break;
      case 7:
        currentBookingDetail = bookingDetail[6].booking;
        break;
    }

    let currentMorningTiming = currentBookingDetail.morning;
    let currentAfternoonTiming = currentBookingDetail.afternoon;
    let currentEveningTiming = currentBookingDetail.evening;

    // console.log('navigate')

    activeCurrentTiming(currentMorningTiming);
    activeCurrentTiming(currentAfternoonTiming);
    activeCurrentTiming(currentEveningTiming);

    function activeCurrentTiming(timingArray) {
      for (var i = 0; i < timingArray.length; i++) {
        document.getElementById(timingArray[i]).classList.add("active-timing");
      }
    }
  }

  deleteBookingTime(that, time, timeSlot) {
    let bookingDetail = that.state.bookingDetail;
    let booking = that.state.booking;

    switch (booking) {
      case 1:
        if (timeSlot === "morning") {
          let tempBooking = bookingDetail[0].booking.morning;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else if (timeSlot === "afternoon") {
          let tempBooking = bookingDetail[0].booking.afternoon;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else {
          let tempBooking = bookingDetail[0].booking.evening;
          this.iterateDeleteBookingTIme(tempBooking, time);
        }
        break;

      case 2:
        if (timeSlot === "morning") {
          let tempBooking = bookingDetail[1].booking.morning;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else if (timeSlot === "afternoon") {
          let tempBooking = bookingDetail[1].booking.afternoon;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else {
          let tempBooking = bookingDetail[1].booking.evening;
          this.iterateDeleteBookingTIme(tempBooking, time);
        }
        break;

      case 3:
        if (timeSlot === "morning") {
          let tempBooking = bookingDetail[2].booking.morning;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else if (timeSlot === "afternoon") {
          let tempBooking = bookingDetail[2].booking.afternoon;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else {
          let tempBooking = bookingDetail[2].booking.evening;
          this.iterateDeleteBookingTIme(tempBooking, time);
        }
        break;

      case 4:
        if (timeSlot === "morning") {
          let tempBooking = bookingDetail[3].booking.morning;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else if (timeSlot === "afternoon") {
          let tempBooking = bookingDetail[3].booking.afternoon;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else {
          let tempBooking = bookingDetail[3].booking.evening;
          this.iterateDeleteBookingTIme(tempBooking, time);
        }
        break;

      case 5:
        if (timeSlot === "morning") {
          let tempBooking = bookingDetail[4].booking.morning;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else if (timeSlot === "afternoon") {
          let tempBooking = bookingDetail[4].booking.afternoon;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else {
          let tempBooking = bookingDetail[4].booking.evening;
          this.iterateDeleteBookingTIme(tempBooking, time);
        }
        break;

      case 6:
        if (timeSlot === "morning") {
          let tempBooking = bookingDetail[5].booking.morning;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else if (timeSlot === "afternoon") {
          let tempBooking = bookingDetail[5].booking.afternoon;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else {
          let tempBooking = bookingDetail[5].booking.evening;
          this.iterateDeleteBookingTIme(tempBooking, time);
        }
        break;

      case 7:
        if (timeSlot === "morning") {
          let tempBooking = bookingDetail[6].booking.morning;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else if (timeSlot === "afternoon") {
          let tempBooking = bookingDetail[6].booking.afternoon;
          this.iterateDeleteBookingTIme(tempBooking, time);
        } else {
          let tempBooking = bookingDetail[6].booking.evening;
          this.iterateDeleteBookingTIme(tempBooking, time);
        }
        break;
    }
  }

  iterateDeleteBookingTIme(bookingSlotDetail, time) {
    for (var i = 0; i < bookingSlotDetail.length; i++) {
      if (bookingSlotDetail[i] === time) {
        bookingSlotDetail.splice(i, 1);
      }
    }
  }

  handleMeetingTime(event, time) {
    let bookingDetail = this.state.bookingDetail;

    if (event === "morning") {
      let elem = document.getElementById(time);
      if (elem.classList.contains("active-timing")) {
        elem.classList.remove("active-timing");
        this.deleteBookingTime(this, time, "morning");
      } else {
        elem.classList.add("active-timing");
        this.addBookingDetails(this, time, "morning");
      }
    } else if (event === "afternoon") {
      let elem = document.getElementById(time);
      if (elem.classList.contains("active-timing")) {
        elem.classList.remove("active-timing");
        this.deleteBookingTime(this, time, "afternoon");
      } else {
        elem.classList.add("active-timing");
        this.addBookingDetails(this, time, "afternoon");
      }
    } else {
      let elem = document.getElementById(time);
      if (elem.classList.contains("active-timing")) {
        elem.classList.remove("active-timing");
        this.deleteBookingTime(this, time, "evening");
      } else {
        elem.classList.add("active-timing");
        this.addBookingDetails(this, time, "evening");
      }
    }
  }

  resetMeetingTime() {
    let morningId = [ "7:00 AM", "7:30 AM", "8:00 AM", "8:30 AM", "9:00 AM", "9:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM",];
    let afternoonId = ["12:00 PM", "12:30 PM", "1:00 PM", "1:30 PM", "2:00 PM", "2:30 PM", "3:00 PM", "3:30 PM",];
    let eveningId = ["4:00 PM", "4:30 PM", "5:00 PM", "5:30 PM", "6:00 PM", "6:30 PM", "7:00 PM", "7:30 PM", "8:00 PM", "8:30 PM",];

    // if(this.state.bookingDetail.length===event){
    for (var i = 0; i < morningId.length; i++) {
      document.getElementById(morningId[i]).classList.remove("active-timing");
    }

    for (var i = 0; i < afternoonId.length; i++) {
      document.getElementById(afternoonId[i]).classList.remove("active-timing");
    }

    for (var i = 0; i < eveningId.length; i++) {
      document.getElementById(eveningId[i]).classList.remove("active-timing");
    }
    // }
  }

  addBookingDetails(that, time, timeSlot) {
    let booking = that.state.booking;
    let bookingDetail = that.state.bookingDetail;

    console.log("booking", booking, " booking Detail", bookingDetail);

    if (timeSlot === "morning") {
      bookingDetail[booking - 1].booking.morning.push(time);
    } else if (timeSlot === "afternoon") {
      bookingDetail[booking - 1].booking.afternoon.push(time);
    } else {
      bookingDetail[booking - 1].booking.evening.push(time);
    }

  
    this.setState({ bookingDetail: bookingDetail });
  }

  changeBookingTab(tab) {
    if (tab === "workingDay") {
      document.getElementById(tab).classList.add("bookingSlot-tab-active");
      document.getElementById("fee").classList.remove("bookingSlot-tab-active");
      console.log("change booking tab called");
      this.setState({ bookingSlotTab: 0 });
    } else {
      document.getElementById(tab).classList.add("bookingSlot-tab-active");
      document
        .getElementById("workingDay")
        .classList.remove("bookingSlot-tab-active");

      this.setState({ bookingSlotTab: 1 });
    }
    this.setState({ workingDayFlag: false });

  }

  setConsultationType(index) {
    if (index === 0) {
      if (
        document.getElementById("online_c").classList.contains("consult-active")
      ) {
        document.getElementById("online_c").classList.remove("consult-active");
        this.setState({
          onlineConsultation: "",
          consultationOnlineFeeDisabled: true,
          freeOnlineConsultationDisabled: true,
        });
      } else {
        document.getElementById("online_c").classList.add("consult-active");
        this.setState({
          onlineConsultation: "Online Consultation / Telephonic",
          freeOnlineConsultationDisabled: false,
          consultationOnlineFeeDisabled: false,
          freeOnlineConsultation: false,
        });
      }
    } else {
      if (
        document
          .getElementById("inperson_c")
          .classList.contains("consult-active")
      ) {
        document
          .getElementById("inperson_c")
          .classList.remove("consult-active");
        this.setState({
          InpersonConsultation: "",
          consultationInPersonFeeDisabled: true,
          freeInPersonConsultationDisabled: true,
        });
      } else {
        document.getElementById("inperson_c").classList.add("consult-active");
        this.setState({
          InpersonConsultation: "Inperson Consultation",
          consultationInPersonFeeDisabled: false,
          freeInPersonConsultationDisabled: false,
          freeInPersonConsultation: false,
        });
      }
    }
  }

  copyBookingDay(bookingTime, bookingDay) {
    this.setState({
      copyBookingTime: bookingTime,
      pasteTimingFlag: true,
      copyBookingDay: bookingDay,
    });
  }

  async pasteBookingDetail(that, day) {
    let bookingDetail = that.state.bookingDetail;
    let copyBookingTime = that.state.copyBookingTime;

    await that.addBookingDay(day);
    document.getElementById("paste" + day).classList.add("active-booking");
    if (copyBookingTime.morning.length > 0) {
      await IterateMeetingTiming("morning", copyBookingTime.morning);
    }

    if (copyBookingTime.afternoon.length > 0) {
      await IterateMeetingTiming("afternoon", copyBookingTime.afternoon);
    }

    if (copyBookingTime.evening.length > 0) {
      await IterateMeetingTiming("evening", copyBookingTime.evening);
    }

    function IterateMeetingTiming(dayPart, timingArray) {
      for (var i = 0; i < timingArray.length; i++) {
        that.addBookingDetails(that, timingArray[i], dayPart);
      }
    }

    this.navigateBookingDays(
      that.state.bookingDetail,
      that.state.booking,
      true
    );
  }

  closeCopy() {
    this.setState({ openCopy: false, pasteTimingFlag: false });
  }

  openModal() {
    let bookingDetail = this.state.bookingDetail;
    let allDays = this.state.allDays;

    for (var i = 0; i < bookingDetail.length; i++) {
      for (var j = 0; j < allDays.length; j++) {
        if (bookingDetail[i].day === allDays[j]) {
          allDays.splice(j, 1);
        }
      }
    }
    this.setState({ openCopy: true, allDays: allDays });
  }

  addFeeAndBookDay(that) {
    let feeDetail = {
      onlineConsultation: {
        onlineConsultation: that.state.onlineConsultation,
        onlineFee: that.state.onlineFee,
        freeOnlineConsultation: that.state.freeOnlineConsultation,
      },
      InpersonConsultation: {
        InpersonConsultation: that.state.InpersonConsultation,
        InPersonfee: that.state.InPersonfee,
        freeInPersonConsultation: that.state.freeInPersonConsultation,
      },
    };
    that.setState({ feeDetail: feeDetail });
  }

  showFeeDetail() {
    if (this.state.onlineConsultation === "Online Consultation / Telephonic") {
      document.getElementById("online_c").classList.add("consult-active");
      this.setState({
        consultationOnlineFeeDisabled: false,
        freeOnlineConsultationDisabled: false,
      });
    } else if (this.state.onlineConsultation === "") {
      this.setState({
        consultationOnlineFeeDisabled: true,
        freeOnlineConsultationDisabled: true,
      });
    }
    if (this.state.InpersonConsultation === "Inperson Consultation") {
      document.getElementById("inperson_c").classList.add("consult-active");
      this.setState({
        consultationInPersonFeeDisabled: false,
        freeInPersonConsultationDisabled: false,
      });
    } else if (this.state.InpersonConsultation === "") {
      this.setState({
        consultationInPersonFeeDisabled: true,
        freeInPersonConsultationDisabled: true,
      });
    }
  }

  render() {
    const { isTitle, isPreview } = this.state;
    return (
      <div>
        <div className="row">
          <div className="col-md-12 col-lg-12 col-sm-12">
            <div className="col-md-11 col-lg-11 col-sm-12">
              {!isPreview? (
                <div>
                  <div className="row">
                    <div
                      onClick={() => this.handleCategory(this, 0, false)}
                      id={0}
                      className="col-md-2 col-lg-2 add-user-info add-user-i-active"
                    >
                      Personal Details
                    </div>
                    <div
                      onClick={() => this.handleCategory(this, 1, false)}
                      id={1}
                      className="col-md-2 col-lg-2 add-user-info"
                    >
                      Services
                    </div>
                    <div
                      onClick={() => this.handleCategory(this, 2, true)}
                      id={2}
                      className="col-md-2 col-lg-2 add-user-info"
                    >
                      Booking Slots
                    </div>

                  </div>

                  {this.state.isCategory === 0 ? (
                    <div>
                      <div className="container add-user-container">
                        <div className="stepper-bar col-md-12 col-lg-12 small-d">
                          <div className="step">
                            <p>Personal Details</p>
                            <div className="bullet">
                              <span>1</span>
                            </div>
                            <div className="check fas fa-check"></div>
                          </div>
                          <div className="step">
                            <p>Bio</p>
                            <div className="bullet">
                              <span>2</span>
                            </div>
                            <div className="check fas fa-check"></div>
                          </div>
                          <div className="step">
                            <p>Education</p>
                            <div className="bullet">
                              <span>3</span>
                            </div>
                            <div className="check fas fa-check"></div>
                          </div>
                          <div className="step">
                            <p>Experience</p>
                            <div className="bullet">
                              <span>4</span>
                            </div>
                            <div className="check fas fa-check"></div>
                          </div>
                          <div className="step">
                            <p>Skills</p>
                            <div className="bullet">
                              <span>5</span>
                            </div>
                            <div className="check fas fa-check"></div>
                          </div>
                          <div className="step">
                            <p>Awards</p>
                            <div className="bullet">
                              <span>6</span>
                            </div>
                            <div className="check fas fa-check"></div>
                          </div>
                        </div>
                        {isTitle === 0 ? (
                          <div>
                            <div className="col-md-1 col-lg-1 "></div>
                            <h3 style={{ marginTop: "2%" }}>
                              Personal Details
                            </h3>
                            <div className="col-md-1 col-lg-1 "></div>

                            <div className="col-md-4 col-lg-4 col-sm-12">
                              <h4>Profile Image :</h4>
                              {this.state.profileImageUrl === "" ? (
                                <img
                                  className="profileImageUrl"
                                  src={
                                    "https://businus-files-live.s3.amazonaws.com/default-image/user-profile.jpg"
                                  }
                                />
                              ) : (
                                <img
                                  className="profileImageUrl"
                                  src={this.state.profileImageUrl}
                                />
                              )}
                              <FileBase64
                                multiple={true}
                                onDone={this.getFiles.bind(this)}
                              />

                              <div style={{ marginTop: "2%" }}>
                                <h4>Cover Image :</h4>
                                {this.state.coverImageUrl !== "" ? (
                                  <img
                                    className="coverImageUrl"
                                    src={this.state.coverImageUrl}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              <FileBase64
                                multiple={true}
                                onDone={this.getFilesCover.bind(this)}
                              />
                            </div>

                            <div className="col-md-7 col-lg-7 col-sm-12">
                              <div className="form-group">
                                <label className="control-label col-md-4 col-lg-4 col-sm-4">
                                  Name
                                </label>
                                <div className="col-md-6 col-lg-6 col-sm-8">
                                  <input
                                    type="text"
                                    className="form-control ember-text-field ember-view"
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.handlePersonalDetail}
                                    placeholder="Enter your name"
                                    required
                                  ></input>
                                </div>
                              </div>

                              <div className="form-group">
                                <label className="control-label col-md-4 col-lg-4 col-sm-4">
                                  Experience
                                </label>
                                <div className="col-md-6 col-lg-6 col-sm-8">
                                  <input
                                    type="number"
                                    className="form-control ember-text-field ember-view"
                                    name="experience"
                                    value={this.state.experience}
                                    onChange={this.handlePersonalDetail}
                                    placeholder="Enter your experience"
                                  ></input>
                                </div>
                              </div>

                              <div className="form-group">
                                <label className="control-label col-md-4 col-lg-4 col-sm-4">
                                  Practise{" "}
                                </label>
                                <div className="col-md-6 col-lg-6 col-sm-8">
                                  <input
                                    type="text"
                                    className="form-control ember-text-field ember-view"
                                    name="practise"
                                    value={this.state.practise}
                                    onChange={this.handlePersonalDetail}
                                    placeholder="Enter your practise & area"
                                  ></input>
                                </div>
                              </div>

                              <div className="form-group">
                                <label className="control-label col-md-4 col-lg-4 col-sm-4">
                                  Location
                                </label>
                                <div className="col-md-6 col-lg-6 col-sm-8">
                                  <input
                                    type="text"
                                    className="form-control ember-text-field ember-view"
                                    name="location"
                                    value={this.state.location}
                                    onChange={this.handlePersonalDetail}
                                    placeholder="Enter your location"
                                  ></input>
                                </div>
                              </div>

                              <div className="form-group">
                                <label className="control-label col-md-4 col-lg-4 col-sm-4">
                                  Languages
                                </label>
                                <div className="col-md-6 col-lg-6 col-sm-8">
                                  <input
                                    type="text"
                                    className="form-control ember-text-field ember-view"
                                    name="language"
                                    value={this.state.language}
                                    onChange={this.handlePersonalDetail}
                                    placeholder="Enter preferred language"
                                  ></input>
                                </div>
                              </div>
                            </div>
                            <button
                              type="submit"
                              className="right-float nxt-button"
                              onClick={() =>
                                this.handleNavigation(0, "next", 1)
                              }
                            >
                              Next
                            </button>
                          </div>
                        ) : isTitle === 1 ? (
                          <div>
                            <div className="col-md-1"></div>
                            <h3>About</h3>
                            <div
                              style={{ padding: "3%", background: "#fcfcfc" }}
                            >
                              <EditorJs
                                data={this.state.bio_data}
                                instanceRef={(instance) =>
                                  (this.editorInstance = instance)
                                }
                                tools={{
                                  image: {
                                    class: Image,
                                    inlineToolbar: true,
                                    config: {
                                      uploader: {
                                        uploadByFile: async function (file) {
                                          console.log(file);
                                          const id = uuidv1();
                                          const imageURL =
                                            "https://businus-files-live.s3.amazonaws.com/image/" +
                                            id +
                                            ".png";

                                          let fileBase = await toBase64(file);
                                          let result = await axios.post(
                                            "https://tj1cb28koh.execute-api.us-east-1.amazonaws.com/dev/businus/uploadEditorImage",
                                            {
                                              image: fileBase,
                                              imageName: fileBase.name,
                                              id: id,
                                              imageURL: imageURL,
                                            }
                                          );

                                          return {
                                            success: 1,
                                            file: {
                                              url: imageURL,
                                            },
                                          };
                                        },
                                      },
                                    },
                                  },
                                  ...EditorjsTool,
                                }}
                              ></EditorJs>

                              <button
                                className="right-float nxt-button"
                                onClick={() =>
                                  this.handleNavigation(1, "next", 2)
                                }
                              >
                                Next
                              </button>
                              <button
                                className="right-float prev-button"
                                onClick={() =>
                                  this.handleNavigation(1, "prev", 0)
                                }
                              >
                                Prev
                              </button>
                            </div>
                          </div>
                        ) : isTitle === 2 ? (
                          <div>
                            <div className="col-md-1"></div>
                            <h3>Education</h3>
                            <div className="col-md-1"></div>
                            <div
                              className="col-md-11"
                              style={{ padding: "2%" }}
                            >
                              <h4 style={{ paddingBottom: "3%" }}>
                                10th Standard
                              </h4>
                              <div className="education-d">
                                <div className="form-group">
                                  <label className="control-label col-md-4 col-lg-4">
                                    School
                                  </label>
                                  <div className="col-md-6 col-lg-6">
                                    <input
                                      type="text"
                                      className="form-control ember-text-field ember-view"
                                      name="school10"
                                      value={this.state.school10}
                                      onChange={this.handleEducation}
                                      placeholder="Enter school name"
                                    ></input>
                                  </div>
                                </div>

                                <div className="form-group">
                                  <label className="control-label col-md-4 col-lg-4">
                                    Course Type
                                  </label>
                                  <div className="col-md-6 col-lg-6">
                                    <button
                                      className="courseType"
                                      value="Full Time"
                                      name="coureType10"
                                      id="fullTime10"
                                      onClick={() =>
                                        this.handleCourseType("fullTime10", 0)
                                      }
                                      onChange={this.handleEducation}
                                    >
                                      Full Time
                                    </button>
                                    <button
                                      className="courseType"
                                      value="Part Time"
                                      name="coureType10"
                                      id="partTime10"
                                      onClick={() =>
                                        this.handleCourseType("partTime10", 1)
                                      }
                                      onChange={this.handleEducation}
                                    >
                                      Part Time
                                    </button>
                                  </div>
                                </div>

                                <div className="form-group">
                                  <label className="control-label col-md-4 col-lg-4">
                                    Passing Year
                                  </label>
                                  <div className="col-md-6 col-lg-6">
                                    <input
                                      type="date"
                                      name="passingYear10"
                                      value={this.state.passingYear10}
                                      onChange={this.handleEducation}
                                    ></input>
                                  </div>
                                </div>

                                <div className="form-group">
                                  <label className="control-label col-md-4 col-lg-4">
                                    Marks
                                  </label>
                                  <div className="col-md-6 col-lg-6">
                                    <input
                                      type="text"
                                      className="form-control ember-text-field ember-view"
                                      name="mark10"
                                      value={this.state.mark10}
                                      onChange={this.handleEducation}
                                      placeholder="Enter marks"
                                    ></input>{" "}
                                    <br />
                                    <div style={{ marginTop: "1%" }}>
                                      <input
                                        type="radio"
                                        id="percentage10"
                                        onChange={this.handleEducation}
                                        name="markType10"
                                        value="percentage"
                                      ></input>
                                      <label
                                        for="percentage"
                                        style={{ paddingRight: "3%" }}
                                      >
                                        Percentage
                                      </label>
                                      <input
                                        type="radio"
                                        id="cgpa10"
                                        onChange={this.handleEducation}
                                        name="markType10"
                                        value="cgpa"
                                      ></input>
                                      <label for="cgpa">C.G.P.A.</label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <h4 style={{ paddingBottom: "3%" }}>
                                12th Standard
                              </h4>
                              <div className="education-d">
                                <div className="form-group">
                                  <label className="control-label col-md-4 col-lg-4">
                                    School
                                  </label>
                                  <div className="col-md-6 col-lg-6">
                                    <input
                                      type="text"
                                      className="form-control ember-text-field ember-view"
                                      name="school12"
                                      value={this.state.school12}
                                      onChange={this.handleEducation}
                                      placeholder="Enter school name"
                                    ></input>
                                  </div>
                                </div>

                                <div className="form-group">
                                  <label className="control-label col-md-4 col-lg-4">
                                    Course Type
                                  </label>
                                  <div className="col-md-6 col-lg-6">
                                    <button
                                      className="courseType"
                                      value="Full Time"
                                      name="coureType12"
                                      id="fullTime12"
                                      onClick={() =>
                                        this.handleCourseType("fullTime12", 2)
                                      }
                                      onChange={this.handleEducation}
                                    >
                                      Full Time
                                    </button>
                                    <button
                                      className="courseType"
                                      value="Part Time"
                                      name="coureType12"
                                      id="partTime12"
                                      onClick={() =>
                                        this.handleCourseType("partTime12", 3)
                                      }
                                      onChange={this.handleEducation}
                                    >
                                      Part Time
                                    </button>
                                  </div>
                                </div>

                                <div className="form-group">
                                  <label className="control-label col-md-4 col-lg-4">
                                    Passing Year
                                  </label>
                                  <div className="col-md-6 col-lg-6">
                                    <input
                                      type="date"
                                      name="passingYear12"
                                      value={this.state.passingYear12}
                                      onChange={this.handleEducation}
                                    ></input>
                                  </div>
                                </div>

                                <div className="form-group">
                                  <label className="control-label col-md-4 col-lg-4">
                                    Marks
                                  </label>
                                  <div className="col-md-6 col-lg-6">
                                    <input
                                      type="text"
                                      className="form-control ember-text-field ember-view"
                                      name="mark12"
                                      value={this.state.mark12}
                                      onChange={this.handleEducation}
                                      placeholder="Enter marks"
                                    ></input>{" "}
                                    <br />
                                    <div style={{ marginTop: "1%" }}>
                                      <input
                                        type="radio"
                                        id="percentage12"
                                        onChange={this.handleEducation}
                                        name="markType12"
                                        value="percentage"
                                      ></input>
                                      <label
                                        for="percentage"
                                        style={{ paddingRight: "3%" }}
                                      >
                                        Percentage
                                      </label>
                                      <input
                                        type="radio"
                                        id="cgpa12"
                                        onChange={this.handleEducation}
                                        name="markType12"
                                        value="cgpa"
                                      ></input>
                                      <label for="cgpa">C.G.P.A.</label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <h4 style={{ paddingBottom: "3%" }}>
                                Graduation
                              </h4>
                              <div className="education-d">
                                <div className="form-group">
                                  <label className="control-label col-md-4 col-lg-4">
                                    College
                                  </label>
                                  <div className="col-md-6 col-lg-6">
                                    <input
                                      type="text"
                                      className="form-control ember-text-field ember-view"
                                      name="graduationCollege"
                                      value={this.state.graduationCollege}
                                      onChange={this.handleEducation}
                                      placeholder="Enter College Name"
                                    ></input>
                                  </div>
                                </div>

                                <div className="form-group">
                                  <label className="control-label col-md-4 col-lg-4">
                                    Course Type
                                  </label>
                                  <div className="col-md-6 col-lg-6">
                                    <button
                                      className="courseType"
                                      value="Full Time"
                                      name="coureTypeGraduation"
                                      id="fullTimeGraduation"
                                      onClick={() =>
                                        this.handleCourseType(
                                          "fullTimeGraduation",
                                          4
                                        )
                                      }
                                      onChange={this.handleEducation}
                                    >
                                      Full Time
                                    </button>
                                    <button
                                      className="courseType"
                                      value="Part Time"
                                      name="coureTypeGraduation"
                                      id="partTimeGraduation"
                                      onClick={() =>
                                        this.handleCourseType(
                                          "partTimeGraduation",
                                          5
                                        )
                                      }
                                      onChange={this.handleEducation}
                                    >
                                      Part Time
                                    </button>
                                  </div>
                                </div>

                                <div className="form-group">
                                  <label className="control-label col-md-4 col-lg-4">
                                    Passing Year
                                  </label>
                                  <div className="col-md-6 col-lg-6">
                                    <input
                                      type="date"
                                      name="graduationPassingYear"
                                      value={this.state.graduationPassingYear}
                                      onChange={this.handleEducation}
                                    ></input>
                                  </div>
                                </div>

                                <div className="form-group">
                                  <label className="control-label col-md-4 col-lg-4">
                                    Marks
                                  </label>
                                  <div className="col-md-6 col-lg-6">
                                    <input
                                      type="text"
                                      className="form-control ember-text-field ember-view"
                                      name="graduationMark"
                                      value={this.state.graduationMark}
                                      onChange={this.handleEducation}
                                      placeholder="Enter marks"
                                    ></input>{" "}
                                    <br />
                                    <div style={{ marginTop: "1%" }}>
                                      <input
                                        type="radio"
                                        id="graduationPercentage"
                                        onChange={this.handleEducation}
                                        name="graduationMarkType"
                                        value="percentage"
                                      ></input>
                                      <label
                                        for="percentage"
                                        style={{ paddingRight: "3%" }}
                                      >
                                        Percentage
                                      </label>
                                      <input
                                        type="radio"
                                        id="graduationCgpa"
                                        onChange={this.handleEducation}
                                        name="graduationMarkType"
                                        value="cgpa"
                                      ></input>
                                      <label for="cgpa">C.G.P.A.</label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <h4 style={{ paddingBottom: "3%" }}>
                                Post Graduation
                              </h4>
                              <div className="education-d">
                                <div className="form-group">
                                  <label className="control-label col-md-4 col-lg-4">
                                    College
                                  </label>
                                  <div className="col-md-6 col-lg-6">
                                    <input
                                      type="text"
                                      className="form-control ember-text-field ember-view"
                                      name="postCollege"
                                      value={this.state.postCollege}
                                      onChange={this.handleEducation}
                                      placeholder="Enter college name"
                                    ></input>
                                  </div>
                                </div>

                                <div className="form-group">
                                  <label className="control-label col-md-4 col-lg-4">
                                    Course Type
                                  </label>
                                  <div className="col-md-6 col-lg-6">
                                    <button
                                      className="courseType"
                                      value="Full Time"
                                      name="coureTypePostGraduation"
                                      id="fullTimePostGraduation"
                                      onClick={() =>
                                        this.handleCourseType(
                                          "fullTimePostGraduation",
                                          6
                                        )
                                      }
                                      onChange={this.handleEducation}
                                    >
                                      Full Time
                                    </button>
                                    <button
                                      className="courseType"
                                      value="Part Time"
                                      name="coureTypePostGraduation"
                                      id="partTimePostGraduation"
                                      onClick={() =>
                                        this.handleCourseType(
                                          "partTimePostGraduation",
                                          7
                                        )
                                      }
                                      onChange={this.handleEducation}
                                    >
                                      Part Time
                                    </button>
                                  </div>
                                </div>

                                <div className="form-group">
                                  <label className="control-label col-md-4 col-lg-4">
                                    Passing Year
                                  </label>
                                  <div className="col-md-6 col-lg-6">
                                    <input
                                      type="date"
                                      name="postPassingYear"
                                      value={this.state.postPassingYear}
                                      onChange={this.handleEducation}
                                    ></input>
                                  </div>
                                </div>

                                <div className="form-group">
                                  <label className="control-label col-md-4 col-lg-4">
                                    Marks
                                  </label>
                                  <div className="col-md-6 col-lg-6">
                                    <input
                                      type="text"
                                      className="form-control ember-text-field ember-view"
                                      name="postMark"
                                      value={this.state.postMark}
                                      onChange={this.handleEducation}
                                      placeholder="Enter marks"
                                    ></input>{" "}
                                    <br />
                                    <div style={{ marginTop: "1%" }}>
                                      <input
                                        type="radio"
                                        id="postPercentage"
                                        onChange={this.handleEducation}
                                        name="postMarkType"
                                        value="percentage"
                                      ></input>
                                      <label
                                        for="percentage"
                                        style={{ paddingRight: "3%" }}
                                      >
                                        Percentage
                                      </label>
                                      <input
                                        type="radio"
                                        id="postCgpa"
                                        onChange={this.handleEducation}
                                        name="postMarkType"
                                        value="cgpa"
                                      ></input>
                                      <label for="cgpa">C.G.P.A.</label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <button
                                className="right-float nxt-button"
                                onClick={() =>
                                  this.handleNavigation(2, "next", 3)
                                }
                              >
                                Next
                              </button>
                              <button
                                className=" right-float prev-button"
                                onClick={() =>
                                  this.handleNavigation(2, "prev", 1)
                                }
                              >
                                Prev
                              </button>
                            </div>
                          </div>
                        ) : isTitle === 3 ? (
                          <div>
                            <div className="col-md-1 col-lg-1 "></div>
                            <h3>Experience</h3>
                            {this.state.showExperienceArray
                              ? this.state.experienceArray.map((exp, index) => (
                                  <div className="experience-d">
                                    <div className="form-group">
                                      <label className="control-label col-md-4 col-lg-4">
                                        {" "}
                                        Title{" "}
                                      </label>
                                      <div className="col-md-6 col-lg-6">
                                        <input
                                          className="form-control ember-text-field ember-view"
                                          type="text"
                                          placeholder="Title"
                                          id={"title_" + (index + 1)}
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <label className="control-label col-md-4 col-lg-4">
                                        Employement Type
                                      </label>
                                      <div className="col-md-6 col-lg-6">
                                        <input
                                          className="form-control ember-text-field ember-view"
                                          type="text"
                                          placeholder="Employement Type"
                                          id={"employementType_" + (index + 1)}
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <label className="control-label col-md-4 col-lg-4">
                                        {" "}
                                        Company Name
                                      </label>
                                      <div className="col-md-6 col-lg-6">
                                        <input
                                          className="form-control ember-text-field ember-view"
                                          type="text"
                                          placeholder="Company Name"
                                          id={"companyName_" + (index + 1)}
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <label className="control-label col-md-4 col-lg-4">
                                        Location
                                      </label>
                                      <div className="col-md-6 col-lg-6">
                                        <input
                                          className="form-control ember-text-field ember-view"
                                          type="text"
                                          placeholder="Location"
                                          id={"location_" + (index + 1)}
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <label className="control-label col-md-4 col-lg-4">
                                        Start Date
                                      </label>
                                      <div className="col-md-6 col-lg-6">
                                        <input
                                          className="form-control ember-text-field ember-view"
                                          type="date"
                                          id={"startDate_" + (index + 1)}
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <label className="control-label col-md-4 col-lg-4">
                                        {" "}
                                        End Date
                                      </label>
                                      <div className="col-md-6 col-lg-6">
                                        <input
                                          className="form-control ember-text-field ember-view"
                                          type="date"
                                          id={"endDate_" + (index + 1)}
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <label className="control-label col-md-4 col-lg-4">
                                        Description
                                      </label>
                                      <div className="col-md-6 col-lg-6">
                                        <input
                                          className="form-control ember-text-field ember-view"
                                          type="text"
                                          placeholder="Description"
                                          id={"description_" + (index + 1)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ))
                              : ""}
                            <div className="col-md-1 col-lg-1"></div>
                            <div className="col-md-11 col-lg-11">
                              <div id="experience"></div>
                              <button
                                style={{ border: "none" }}
                                onClick={() => this.addExperience()}
                              >
                                <i className="fa fa-plus"></i>
                              </button>
                            </div>

                            <button
                              className="right-float nxt-button"
                              onClick={() =>
                                this.handleNavigation(3, "next", 4)
                              }
                            >
                              Next
                            </button>
                            <button
                              className="right-float prev-button"
                              onClick={() =>
                                this.handleNavigation(3, "prev", 2)
                              }
                            >
                              Prev
                            </button>
                          </div>
                        ) : isTitle === 4 ? (
                          <div>
                            <div className="col-md-1 col-lg-1"></div>
                            <h3>Skills</h3>
                            <div className="col-md-1 col-lg-1" />
                            <div className="col-md-11 col-lg-11">
                              <div id="skill">
                                <textarea
                                  name="skill"
                                  className="form-control1"
                                  placeholder="Enter Your Skills"
                                  onChange={(event) =>
                                    this.setSkill(event.target.value)
                                  }
                                  value={this.state.skill}
                                ></textarea>

                                {this.state.skillArray !== []
                                  ? this.state.skillArray.map((skill) => (
                                      <div className="chip">
                                        {skill.skill}
                                        <span
                                          className="closeChip"
                                          onClick={() =>
                                            this.handleDelete(skill.key)
                                          }
                                        >
                                          &times;
                                        </span>
                                      </div>
                                    ))
                                  : ""}
                                <br />
                                <button
                                  className="skill-button"
                                  onClick={() =>
                                    this.addSkill(this.state.skill)
                                  }
                                >
                                  Add Skills
                                </button>

                                <button
                                  className="right-float nxt-button"
                                  onClick={() =>
                                    this.handleNavigation(4, "next", 5)
                                  }
                                >
                                  Next
                                </button>
                                <button
                                  className="right-float prev-button"
                                  onClick={() =>
                                    this.handleNavigation(4, "prev", 3)
                                  }
                                >
                                  Prev
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : isTitle === 5 ? (
                          <div>
                            <div className="col-md-1 col-lg-1" />
                            <h3>Awards</h3>

                            {this.state.showAwardArray
                              ? this.state.award.map((award, index) => (
                                  <div className="award-d">
                                    <div className="form-group">
                                      <label className="control-label col-md-4 col-lg-4">
                                        {" "}
                                        Award{" "}
                                      </label>
                                      <div className="col-md-6 col-lg-6">
                                        <input
                                          className="form-control ember-text-field ember-view"
                                          type="text"
                                          placeholder="Award"
                                          id={"award_" + (index + 1)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ))
                              : ""}
                            <div className="col-md-1 col-lg-1" />
                            <div className="col-md-11 col-lg-11">
                              <div id="award"></div>
                              <button
                                style={{ border: "none" }}
                                onClick={() => this.addAward()}
                              >
                                <i className="fa fa-plus"></i>
                              </button>
                            </div>

                            <button
                              className="right-float nxt-button"
                              onClick={() =>
                                this.handleNavigation(5, "next", 6)
                              }
                            >
                              Next
                            </button>
                            <button
                              className="right-float prev-button"
                              onClick={() =>
                                this.handleNavigation(5, "prev", 4)
                              }
                            >
                              Prev
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.isCategory === 1 ? (
                    <div className="container add-user-container">
                      <div id="productContainer">
                        <div
                          className="row product-d"
                          style={{ marginBottom: "2%" }}
                        >
                          <div className="col-md-4 col-lg-4 col-sm-12">
                            {this.state.serviceImageUrl === "" ? (
                              <img
                                id="productImage_0"
                                className="profileImageUrl"
                                src={
                                  "https://businus-files-live.s3.amazonaws.com/default-image/service.png"
                                }
                              />
                            ) : (
                              <img
                                id="productImage_0"
                                className="profileImageUrl"
                                src={this.state.serviceImageUrl}
                              />
                            )}
                            <FileBase64
                              multiple={true}
                              onDone={this.getProductFiles.bind(this)}
                            />
                          </div>
                          <div className="col-md-8 col-lg-8 col-sm-8">
                            <div className="form-group">
                              <label className="control-label col-md-4 col-lg-4 col-sm-4">
                                {" "}
                                Service Name
                              </label>
                              <div className="col-md-6 col-lg-6 col-sm-8">
                                <input
                                  type="text"
                                  id="serviceName_0"
                                  className="form-control ember-text-field ember-view"
                                  name="serviceName"
                                  value={this.state.serviceName}
                                  onChange={this.handleProductDetail}
                                  placeholder="Enter the services name"
                                ></input>
                              </div>
                            </div>

                            <div className="form-group">
                              <label className="control-label col-md-4 col-lg-4 col-sm-4">
                                Service Description
                              </label>
                              <div className="col-md-6 col-lg-6 col-sm-8">
                                <input
                                  type="text"
                                  id="serviceDescription_0"
                                  className="form-control ember-text-field ember-view"
                                  name="serviceDescription"
                                  value={this.state.serviceDescription}
                                  onChange={this.handleProductDetail}
                                  placeholder="Enter more about the services"
                                ></input>
                              </div>
                            </div>
                          </div>
                        </div>

                        {this.state.tempProductDetail.map((prod, index) => (
                          <div
                            className="row product-d"
                            style={{ marginBottom: "2%" }}
                          >
                            <div className="col-md-4 col-lg-4 col-sm-12">
                              <img
                                className="profileImageUrl"
                                id={"productImage_" + (index + 1)}
                                src={prod.serviceImageUrl}
                              />
                              <input
                                id={"productFile_" + (index + 1)}
                                type="file"
                                multiple={true}
                                accept="image/*"
                                onChange={() =>
                                  this.getMoreProductFiles(index + 1)
                                }
                              />
                            </div>
                            <div className="col-md-8 col-lg-8 col-sm-12">
                              <div className="form-group">
                                <label className="control-label col-md-4 col-lg-4 col-sm-4">
                                  {" "}
                                  Service Name{" "}
                                </label>
                                <div className="col-md-6 col-lg-6 col-sm-8">
                                  <input
                                    type="text"
                                    id={"serviceName_" + (index + 1)}
                                    className="form-control ember-text-field ember-view"
                                    name={"serviceName_" + (index + 1)}
                                    placeholder="Enter the services name"
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4 col-lg-4 col-sm-4">
                                  {" "}
                                  Product Description{" "}
                                </label>
                                <div className="col-md-6 col-lg-6 col-sm-8">
                                  <input
                                    type="text"
                                    id={"serviceDescription_" + (index + 1)}
                                    className="form-control ember-text-field ember-view"
                                    name={"serviceDescription_" + (index + 1)}
                                    placeholder="Enter more about your services"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                        <div id="productDetail"></div>
                        <button
                          style={{ border: "none" }}
                          onClick={() => this.addProductDetail()}
                        >
                          <i className="fa fa-plus"></i>
                        </button>

                        <button
                          className="right-float nxt-button"
                          onClick={() => this.handleNavigation(6, "next", 7)}
                        >
                          Next
                        </button>
                        <button
                          className="right-float prev-button"
                          onClick={() => this.handleNavigation(6, "prev", 5)}
                        >
                          Prev
                        </button>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.isCategory === 2 ? (
                    <div className="container add-user-container">
                      <div className="row">
                        <div
                          className="col-md-6 col-lg-6 col-sm-6 bookingSlot-tab-active"
                          id="workingDay"
                          onClick={() => this.changeBookingTab("workingDay")}
                        >
                          {" "}
                          <h4 className="bookingSlot-tab-t">
                            {" "}
                            Working Days{" "}
                          </h4>{" "}
                        </div>
                        <div
                          className="col-md-6 col-lg-6 col-sm-6"
                          id="fee"
                          onClick={() => this.changeBookingTab("fee")}
                        >
                          <h4 className="bookingSlot-tab-t"> Fees </h4>{" "}
                        </div>
                      </div>
                      <div
                        className="row product-d"
                        style={{ marginBottom: "2%" }}
                      >
                        {this.state.bookingSlotTab === 0 ? (
                          <div>
                            <div style={{ paddingLeft: "5%" }}>
                              <h3>How Many Days a Week?</h3>
                              <div>
                                <button
                                  onClick={() => this.addBookingDay("Mon")}
                                  id="Mon"
                                  className="booking-day"
                                >
                                  Mon
                                </button>
                                <button
                                  onClick={() => this.addBookingDay("Tue")}
                                  id="Tue"
                                  className="booking-day"
                                >
                                  Tue
                                </button>
                                <button
                                  onClick={() => this.addBookingDay("Wed")}
                                  id="Wed"
                                  className="booking-day"
                                >
                                  Wed
                                </button>
                                <button
                                  onClick={() => this.addBookingDay("Thurs")}
                                  id="Thurs"
                                  className="booking-day"
                                >
                                  Thurs
                                </button>
                                <button
                                  onClick={() => this.addBookingDay("Fri")}
                                  id="Fri"
                                  className="booking-day"
                                >
                                  Fri
                                </button>
                                <button
                                  onClick={() => this.addBookingDay("Sat")}
                                  id="Sat"
                                  className="booking-day"
                                >
                                  Sat
                                </button>
                                <button
                                  onClick={() => this.addBookingDay("Sun")}
                                  id="Sun"
                                  className="booking-day"
                                >
                                  Sun
                                </button>
                              </div>
                              <button
                                className="copyDayTime"
                                onClick={() => this.openModal()}
                              >
                                {" "}
                                Copy{" "}
                              </button>
                              <br />
                              <label style={{ marginLeft: "1%" }}>
                                Morning
                              </label>
                              <div className="row">
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "morning",
                                        "7:00 AM"
                                      )
                                    }
                                    id="7:00 AM"
                                    className="booking-timing"
                                  >
                                    7:00 AM
                                  </button>
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "morning",
                                        "7:30 AM"
                                      )
                                    }
                                    id="7:30 AM"
                                    className="booking-timing"
                                  >
                                    7:30 AM
                                  </button>
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "morning",
                                        "8:00 AM"
                                      )
                                    }
                                    id="8:00 AM"
                                    className="booking-timing"
                                  >
                                    8:00 AM
                                  </button>
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "morning",
                                        "8:30 AM"
                                      )
                                    }
                                    id="8:30 AM"
                                    className="booking-timing"
                                  >
                                    8:30 AM
                                  </button>
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "morning",
                                        "9:00 AM"
                                      )
                                    }
                                    id="9:00 AM"
                                    className="booking-timing"
                                  >
                                    9:00 AM
                                  </button>
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "morning",
                                        "9:30 AM"
                                      )
                                    }
                                    id="9:30 AM"
                                    className="booking-timing"
                                  >
                                    9:30 AM
                                  </button>
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "morning",
                                        "10:00 AM"
                                      )
                                    }
                                    id="10:00 AM"
                                    className="booking-timing"
                                  >
                                    10:00 AM
                                  </button>
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "morning",
                                        "10:30 AM"
                                      )
                                    }
                                    id="10:30 AM"
                                    className="booking-timing"
                                  >
                                    10:30 AM
                                  </button>
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "morning",
                                        "11:00 AM"
                                      )
                                    }
                                    id="11:00 AM"
                                    className="booking-timing"
                                  >
                                    11:00 AM
                                  </button>
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "morning",
                                        "11:30 AM"
                                      )
                                    }
                                    id="11:30 AM"
                                    className="booking-timing"
                                  >
                                    11:30 AM
                                  </button>
                                </div>
                              </div>
                              <label style={{ marginLeft: "1%" }}>
                                Afternoon
                              </label>
                              <div className="row">
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "afternoon",
                                        "12:00 PM"
                                      )
                                    }
                                    id="12:00 PM"
                                    className="booking-timing"
                                  >
                                    12:00 PM
                                  </button>
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "afternoon",
                                        "12:30 PM"
                                      )
                                    }
                                    id="12:30 PM"
                                    className="booking-timing"
                                  >
                                    12:30 PM
                                  </button>
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "afternoon",
                                        "1:00 PM"
                                      )
                                    }
                                    id="1:00 PM"
                                    className="booking-timing"
                                  >
                                    1:00 PM
                                  </button>
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "afternoon",
                                        "1:30 PM"
                                      )
                                    }
                                    id="1:30 PM"
                                    className="booking-timing"
                                  >
                                    1:30 PM
                                  </button>
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "afternoon",
                                        "2:00 PM"
                                      )
                                    }
                                    id="2:00 PM"
                                    className="booking-timing"
                                  >
                                    2:00 PM
                                  </button>
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "afternoon",
                                        "2:30 PM"
                                      )
                                    }
                                    id="2:30 PM"
                                    className="booking-timing"
                                  >
                                    2:30 PM
                                  </button>
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "afternoon",
                                        "3:00 PM"
                                      )
                                    }
                                    id="3:00 PM"
                                    className="booking-timing"
                                  >
                                    3:00 PM
                                  </button>
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "afternoon",
                                        "3:30 PM"
                                      )
                                    }
                                    id="3:30 PM"
                                    className="booking-timing"
                                  >
                                    3:30 PM
                                  </button>
                                </div>
                              </div>
                              <label style={{ marginLeft: "1%" }}>
                                Evening
                              </label>
                              <div className="row">
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "evening",
                                        "4:00 PM"
                                      )
                                    }
                                    id="4:00 PM"
                                    className="booking-timing"
                                  >
                                    4:00 PM
                                  </button>
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "evening",
                                        "4:30 PM"
                                      )
                                    }
                                    id="4:30 PM"
                                    className="booking-timing"
                                  >
                                    4:30 PM
                                  </button>
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "evening",
                                        "5:00 PM"
                                      )
                                    }
                                    id="5:00 PM"
                                    className="booking-timing"
                                  >
                                    5:00 PM
                                  </button>
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "evening",
                                        "5:30 PM"
                                      )
                                    }
                                    id="5:30 PM"
                                    className="booking-timing"
                                  >
                                    5:30 PM
                                  </button>
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "evening",
                                        "6:00 PM"
                                      )
                                    }
                                    id="6:00 PM"
                                    className="booking-timing"
                                  >
                                    6:00 PM
                                  </button>
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "evening",
                                        "6:30 PM"
                                      )
                                    }
                                    id="6:30 PM"
                                    className="booking-timing"
                                  >
                                    6:30 PM
                                  </button>
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "evening",
                                        "7:00 PM"
                                      )
                                    }
                                    id="7:00 PM"
                                    className="booking-timing"
                                  >
                                    7:00 PM
                                  </button>
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "evening",
                                        "7:30 PM"
                                      )
                                    }
                                    id="7:30 PM"
                                    className="booking-timing"
                                  >
                                    7:30 PM
                                  </button>
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "evening",
                                        "8:00 PM"
                                      )
                                    }
                                    id="8:00 PM"
                                    className="booking-timing"
                                  >
                                    8:00 PM
                                  </button>
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-3">
                                  <button
                                    onClick={() =>
                                      this.handleMeetingTime(
                                        "evening",
                                        "8:30 PM"
                                      )
                                    }
                                    id="8:30 PM"
                                    className="booking-timing"
                                  >
                                    8:30 PM
                                  </button>
                                </div>
                              </div>
                            </div>

                            <Modal
                              isOpen={this.state.openCopy}
                              onRequestClose={this.closeCopy}
                              style={customStyle}
                            >
                              <div>
                                <div
                                  style={{ cursor: "pointer", float: "right" }}
                                  onClick={() => this.closeCopy()}
                                >
                                  &#10006;
                                </div>
                                {this.state.pasteTimingFlag ? (
                                  <div>
                                    {this.state.allDays.map((day) => (
                                      <button
                                        onClick={() =>
                                          this.pasteBookingDetail(this, day)
                                        }
                                        id={"paste" + day}
                                        className="booking-day"
                                      >
                                        {day}
                                      </button>
                                    ))}

                                    <button
                                      className="copySubmitBtn"
                                      onClick={() => this.closeCopy()}
                                    >
                                      {" "}
                                      Done{" "}
                                    </button>
                                  </div>
                                ) : (
                                  <div>
                                    <ul style={{ listStyle: "none" }}>
                                      <li>
                                        {this.state.bookingDetail.length > 0
                                          ? this.state.bookingDetail.map(
                                              (book) => (
                                                <button
                                                  className="booking-day"
                                                  id={"copy_" + book.day}
                                                  onClick={() =>
                                                    this.copyBookingDay(
                                                      book.booking,
                                                      book.day
                                                    )
                                                  }
                                                >
                                                  {book.day}
                                                </button>
                                              )
                                            )
                                          : ""}
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </Modal>

                            <button
                              className="right-float nxt-button"
                              onClick={() =>
                                this.handleNavigation(7, "next", 8)
                              }
                            >
                              Next
                            </button>
                            <button
                              className="right-float prev-button"
                              onClick={() =>
                                this.handleNavigation(7, "prev", 6)
                              }
                            >
                              Prev
                            </button>
                          </div>
                        ) : (
                          <div>
                            <div className="form-group bt-fields">
                              <label className="control-label col-md-4 col-lg-4 col-sm-4">
                                {" "}
                                Select Your preferred consultation type{" "}
                              </label>
                              <div className="col-md-6 col-lg-6 col-sm-8">
                                <button
                                  className="consultationType"
                                  id="online_c"
                                  onClick={() => this.setConsultationType(0)}
                                >
                                  {" "}
                                  Online Consultation / Telephonic{" "}
                                </button>
                                <button
                                  className="consultationType"
                                  id="inperson_c"
                                  onClick={() => this.setConsultationType(1)}
                                >
                                  {" "}
                                  Inperson Consultation{" "}
                                </button>
                              </div>
                            </div>
                            <div className="form-group bt-fields">
                              <label className="control-label col-md-4 col-lg-4 col-sm-4">
                                Online Consultaion Fee
                              </label>
                              <div className="col-md-6 col-lg-6 col-sm-8">
                                <input
                                  type="number"
                                  disabled={
                                    this.state.consultationOnlineFeeDisabled
                                  }
                                  className="form-control ember-text-field ember-view"
                                  placeholder="Enter your per hour fee"
                                  name="onlineFee"
                                  value={this.state.onlineFee}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>

                            <div className="control-label col-md-4 col-lg-4 col-sm-4"></div>
                            <div className="col-md-8 col-lg-8 col-sm-8">
                              <input
                                checked={this.state.freeOnlineConsultation}
                                disabled={
                                  this.state.freeOnlineConsultationDisabled
                                }
                                type="checkbox"
                                id="freeOnlineConsultation"
                                name="freeOnlineConsultation"
                                value="freeOnlineConsultation"
                                onChange={this.handleFreeOnlineConsultantChange}
                              />
                              <label style={{ marginLeft: "2%" }}>
                                {" "}
                                Select this if you want to provide free online
                                consultation{" "}
                              </label>
                            </div>

                            <div className="form-group bt-fields">
                              <label className="control-label col-md-4 col-lg-4 col-sm-4">
                                Inperson Consultaion Fee
                              </label>
                              <div className="col-md-6 col-lg-6 col-sm-8">
                                <input
                                  type="number"
                                  disabled={
                                    this.state.consultationInPersonFeeDisabled
                                  }
                                  className="form-control ember-text-field ember-view"
                                  placeholder="Enter your per hour fee"
                                  name="InPersonfee"
                                  value={this.state.InPersonfee}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>

                            <div className="control-label col-md-4 col-lg-4 col-sm-4"></div>
                            <div className="col-md-8 col-lg-8 col-sm-8">
                              <input
                                checked={this.state.freeInPersonConsultation}
                                disabled={
                                  this.state.freeInPersonConsultationDisabled
                                }
                                type="checkbox"
                                id="freeInPersonConsultation"
                                name="freeInPersonConsultation"
                                value="freeInPersonConsultation"
                                onChange={
                                  this.handleFreeInPersonConsultantChange
                                }
                              />
                              <label style={{ marginLeft: "2%" }}>
                                {" "}
                                Select this if you want to provide free inperson
                                consultation{" "}
                              </label>
                            </div>

                            <button
                              className="right-float nxt-button"
                              onClick={() =>
                                this.handleNavigation(8, "next", 9)
                              }
                            >
                              Preview
                            </button>
                            <button
                              className="right-float prev-button"
                              onClick={() =>
                                this.handleNavigation(8, "prev", 7)
                              }
                            >
                              Prev
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div>
                  <PreviewConsultant
                    profileId={this.state.profileId}
                    validProfile={this.state.validProfile}
                    data={this.state.data}
                    productDetail={this.state.productDetailArray}
                    bookingDetail={this.state.bookingDetail}
                    feeDetail={this.state.feeDetail}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
