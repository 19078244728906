import React, { Component } from "react";

import BreadCrumbs from '../../BreadCrumbs';
import { Link } from "react-router-dom";
import axios from 'axios';
import { apiBaseUrl1,apiBaseUrl3 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import CsvDownloader from 'react-csv-downloader'
import ReactTable from 'react-table';
import $ from "jquery";
import _ from 'underscore';

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

const data = [];




let context = "null";

const columnsForExcel = [
  {
    id: 'assignmentId',
    displayName : "Assingment ID",

  },
  {
    displayName : "Name",

    id: "name"
  },
  {
    displayName : "Status",
    id: "status",
   
  },
  {
    displayName : "Payment Status",
    id: "paymentStatus",
   
  },
  {
    displayName : "Start Date",
    id: "startDate",
   
  },
  {
    displayName : "End Date",
    id: "endDate",
 
  },

  {
    displayName : "Overview",
    id: "assignmentOverview",
   
  },

  {
    displayName : "Priority",
    id: "priority",
   
  },
  {
    displayName : "Client",
    id: "selectedClient",
   
  },
  {
    displayName : "Organisation",
    id: "selectedOrganisation",

  },
  {
    displayName : "Team Lead",
    id: "teamLead",
 
  },
  {
    displayName : "Team",
   
    id: "teamMembers",
   
  },
  {
    displayName : "Template",
   
    id: "template",
   
  },
  {
    displayName : "Recurrance Frequency",
   
    id: "recurranceFrequency",
   
  },
  {
    displayName : "Priority",
   
    id: "priority",
   
  },
  {
    displayName : "By",
   
    id: "by",
   
  },
  {
   
    id: "date",
    displayName : "Date",
  },
  {
    displayName : "Time",
    id: "time",
  
  }
];
//defined all the columns and fetched the data for every cell.
const columnsForDoc = [  
  {

    Header: props => <span className="form-textHead cell-head">ID</span>,
    accessor: 'workflowDocId',
    Cell: props =>
      <span>{props.value}</span>,
    minWidth: 180
  },

  {Header: props => <span className="form-textHead cell-head">Name</span>,
  accessor: d => {return {workflowDocId:d.workflowDocId,id:d.id,name:d.name}},
  filterMethod: (filter, row) =>
           { return row.name.name.toLowerCase().includes(filter.value.toLowerCase()) }
                   
                ,
              
  id:"name",
  Cell: props => 
  <Link className="link" to={"./doc/"+ props.value.id}><span>{props.value.name}</span></Link>,
  minWidth:180
  
},{Header: props => <span className="form-textHead cell-head">Assigned To</span>,
accessor: d => {return {name:d.parentName, id:d.parentId, type:d.parentType, assignedId:d.parentNameId}},
filterMethod: (filter, row) =>
         { return row.name.name.toLowerCase().includes(filter.value.toLowerCase()) }
                 
              ,
            
id:"assignedTo",
Cell: props => 
props.value.name ? 
<Link className="link" to={"/assignment-master/assignment/view-assignment/"+ props.value.id}><span>{props.value.name}</span></Link> : 'NA',
minWidth:180

},
{
  Header: props => <span className="form-textHead cell-head">Title</span>,
  accessor: 'title',
  Cell: props =><span className='form-text cell'>{props.value}</span>,
  minWidth: 240
},
{
  Header: props => <span className="form-textHead cell-head">Sub Title</span>,
  accessor: 'subTitle',
  Cell: props =><span className='form-text cell'>{props.value}</span>,
  minWidth: 240
},
  {
    Header: props => <span className="form-textHead cell-head">Content</span>,
    accessor: 'content',
    Cell: props => <span className='form-text cell'  dangerouslySetInnerHTML={{ __html: props.value }}></span>,
    minWidth: 240
  },

  {
    Header: (props) => <span className="form-textHead cell-head">Organisation</span>,
    accessor: (d) => {
      return { name: d.selectedOrganisation ?  d.selectedOrganisation.name : '' };
    },
    filterMethod: (filter, row) => {
      return row.selectedOrganisation.name.toLowerCase().includes(filter.value.toLowerCase());
    },
    id: "selectedOrganisation",
    Cell: (props) => (
      <span className="form-text cell">
        {props.value != undefined ? props.value.name : "-"}
      </span>
    ),
    minWidth: 170,
  },
 
  {
    Header: props => <span className="form-textHead cell-head">Sent To</span>,
    accessor: 'sharedTo',
    filterMethod: (filter, row) =>
    { debugger; return row.sharedTo.find(item=>item.email.toLowerCase().includes(filter.value.toLowerCase())) }
            ,
 
Cell: props => <span className='form-text cell'>{typeof props.value =="object" ? (props.value!=undefined ? props.value.map(item=>item.email).join(" , ") : 'NA') : props.value  }</span>,
minWidth:400
  },
 
  {
    Header: props => <span className="form-textHead cell-head">By</span>,
    accessor: 'by',
    Cell: props => <span className='form-text cell'>{props.value}</span>,
    minWidth: 170

  },
  {
    Header: props => <span className="form-textHead cell-head">Date of Creation</span>,
    accessor: d => { return { date: d.date, id: d.id } },
    filterMethod: (filter, row) => { return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase()) }

    ,
    id: "teamMembers",
    Cell: props =>
      new Date(props.value.date).toDateString(),
    minWidth: 180

  },
  {
    Header: props => <span className="form-textHead cell-head">Time of Creation</span>,
    accessor: d => { return { date: d.date, id: d.id } },
    filterMethod: (filter, row) => { return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase()) }

    ,
    id: "teamMembers",
    Cell: props =>
      new Date(props.value.date).toLocaleTimeString()
    ,
    minWidth: 280

  },

  




]
const columnsForAttachment = [

  {

    Header: props => <span className="form-textHead cell-head">Attachment Id</span>,
    accessor: 'workflowDocId',
    Cell: props =>
      <span>{props.value}</span>,
    minWidth: 180
  },

  {Header: props => <span className="form-textHead cell-head">Name</span>,
  accessor: d => {return {workflowDocId:d.workflowDocId,id:d.id,name:d.name}},
  filterMethod: (filter, row) =>
           { return row.name.name.toLowerCase().includes(filter.value.toLowerCase()) }
                   
                ,
              
  id:"name",
  Cell: props => 
  <Link className="link" to={"./attachment/"+ props.value.id}><span>{props.value.name}</span></Link>,
  minWidth:180
  
},{Header: props => <span className="form-textHead cell-head">Assigned To</span>,
accessor: d => {return {name:d.parentName, id:d.parentId, type:d.parentType, assignedId:d.parentNameId}},
filterMethod: (filter, row) =>
         { return row.name.name.toLowerCase().includes(filter.value.toLowerCase()) }
                 
              ,
            
id:"assignedTo",
Cell: props => 
props.value.name ? 
<Link className="link" to={"/assignment-master/assignment/view-assignment/" +props.value.id}><span>{props.value.name}</span></Link> : 'NA',
minWidth:180

},
{
  Header: props => <span className="form-textHead cell-head">Sent To</span>,
  accessor: 'sharedTo',
  filterMethod: (filter, row) =>
  { debugger; return row.sharedTo.find(item=>item.email.toLowerCase().includes(filter.value.toLowerCase())) }
          ,

Cell: props => <span className='form-text cell'>{typeof props.value =="object" ? (props.value!=undefined ? props.value.map(item=>item.email).join(" , ") : 'NA') : props.value  }</span>,
minWidth:440
},
  {
    Header: props => <span className="form-textHead cell-head">description</span>,
    accessor: 'description',
    Cell: props => <span className='form-text cell' dangerouslySetInnerHTML={{ __html: props.value }}></span>,
    minWidth: 240
  },
  {
    Header: props => <span className="form-textHead cell-head">Attachment</span>,
    accessor: 'attachment',
    Cell: props => props.value !== "NA" ? <a href={props.value} >Download</a> : props.value,
    minWidth: 240
  },
  {
    Header: props => <span className="form-textHead cell-head">By</span>,
    accessor: 'by',
    Cell: props => <span className='form-text cell'>{props.value}</span>,
    minWidth: 170

  },
  {
    Header: props => <span className="form-textHead cell-head">Date of Creation</span>,
    accessor: d => { return { date: d.date, id: d.id } },
    filterMethod: (filter, row) => { return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase()) }

    ,
    id: "teamMembers",
    Cell: props =>
      new Date(props.value.date).toDateString(),
    minWidth: 180

  },
  {
    Header: props => <span className="form-textHead cell-head">Time of Creation</span>,
    accessor: d => { return { date: d.date, id: d.id } },
    filterMethod: (filter, row) => { return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase()) }

    ,
    id: "teamMembers",
    Cell: props =>
      new Date(props.value.date).toLocaleTimeString()
    ,
    minWidth: 280

  },

  


]




const columnsForUrl = [

  {

    Header: props => <span className="form-textHead cell-head">ID</span>,
    accessor: 'workflowDocId',
    Cell: props =>
      <span>{props.value}</span>,
    minWidth: 180
  },

  {Header: props => <span className="form-textHead cell-head">Name</span>,
  accessor: d => {return {workflowDocId:d.workflowDocId,id:d.id,name:d.name}},
  filterMethod: (filter, row) =>
           { return row.name.name.toLowerCase().includes(filter.value.toLowerCase()) }
                   
                ,
              
  id:"name",
  Cell: props => 
  <Link className="link" to={"./url/"+ props.value.id}><span>{props.value.name}</span></Link>,
  minWidth:180
  
},{Header: props => <span className="form-textHead cell-head">Assigned To</span>,
accessor: d => {return {name:d.parentName, id:d.parentId, type:d.parentType, assignedId:d.parentNameId}},
filterMethod: (filter, row) =>
         { return row.name.name.toLowerCase().includes(filter.value.toLowerCase()) }
                 
              ,
            
id:"assignedTo",
Cell: props => 
props.value.name ? 
<Link className="link" to={"/assignment-master/assignment/view-assignment/"+ props.value.id}><span>{props.value.name}</span></Link> : 'NA',
minWidth:180


},
  {
    Header: props => <span className="form-textHead cell-head">Url</span>,
    accessor: 'url',
    Cell: props => props.value !== "" ? <a href={props.value} target="_blank"> click here </a> : props.value,
    minWidth: 240
  },
  {
    Header: props => <span className="form-textHead cell-head">description</span>,
    accessor: 'description',
    Cell: props => <span className='form-text cell' dangerouslySetInnerHTML={{ __html: props.value }}></span>,
    minWidth: 240
  },
  {
    Header: props => <span className="form-textHead cell-head">Sent To</span>,
    accessor: 'sharedTo',
    filterMethod: (filter, row) =>
    { debugger; return row.sharedTo.find(item=>item.email.toLowerCase().includes(filter.value.toLowerCase())) }
            ,
 
Cell: props => <span className='form-text cell'>{typeof props.value =="object" ? (props.value!=undefined ? props.value.map(item=>item.email).join(" , ") : 'NA') : props.value  }</span>,
minWidth:240
  },
  {
    Header: props => <span className="form-textHead cell-head">By</span>,
    accessor: 'by',
    Cell: props => <span className='form-text cell'>{props.value}</span>,
    minWidth: 170

  },
  {
    Header: props => <span className="form-textHead cell-head">Date of Creation</span>,
    accessor: d => { return { date: d.date, id: d.id } },
    filterMethod: (filter, row) => { return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase()) }

    ,
    id: "teamMembers",
    Cell: props =>
      new Date(props.value.date).toDateString(),
    minWidth: 180

  },
  {
    Header: props => <span className="form-textHead cell-head">Time of Creation</span>,
    accessor: d => { return { date: d.date, id: d.id } },
    filterMethod: (filter, row) => { return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase()) }

    ,
    id: "teamMembers",
    Cell: props =>
      new Date(props.value.date).toLocaleTimeString()
    ,
    minWidth: 280

  },

  

]
export default class viewMail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      menu1: [




      ]

    };
    context = this;
  }
  componentDidMount() {

    let mailType = this.props.match.params.docType;
    //mailType we get is "mail" but we want to capitalize first letter i.e Mail or Update or Document
    mailType = mailType.charAt(0).toUpperCase() + mailType.slice(1);

    this.setState({docType:mailType});
    this.fetchQueries(this, mailType)

  }


  componentWillReceiveProps(nextProps) {


    if (this.props != nextProps) {

      this.setState({ menu1: [] });
      let mailType = nextProps.match.params.docType;
      //mailType we get is "mail" but we want to capitalize first letter i.e Mail or Update or Document
      mailType = mailType.charAt(0).toUpperCase() + mailType.slice(1);
      this.setState({docType:mailType});
      this.fetchQueries(this, mailType)
    }

  }


  fetchQueries(that, mailType) {

    axios.get(apiBaseUrl3 + "businus/listWorkflowDoc/list/"+mailType+"/"+localStorage.spId)
      .then(function (response) {
        console.log(response.data);
        that.setState({ menu1: _.sortBy(response.data, "date") });
      })
      .catch(function (error) {
        console.log(error);
      });

  }



  render() {
    return (
      <div>


        <div className="Div">
          <div className="row breadcrumb-row">
            <div className="col-md-10"><BreadCrumbs /></div>
            <div className="col-md-2">
              <div className=" download-button">
                <CsvDownloader text="Download in .csv format" datas={this.state.menu1} filename="organisation" /></div>
            </div>
          </div>
          <div className="row">

            <Row>
              <Col>
                <div className="card">

                  <ReactTable className="myTable" resolveData={data => data.map(row => row)} data={this.state.menu1}
                    columns={this.state.docType == 'Url' ? columnsForUrl : this.state.docType=='Attachment'? columnsForAttachment: columnsForDoc} filterable />






                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}
