import React, { Component } from "react";
import "../../style/panel.css";
import axios from 'axios';
import { apiBaseUrl1,apiBaseUrl5 } from '../apiUtils';
import { apiBaseUrl2 } from '../apiUtils';
import { Link } from "react-router-dom";
import $ from "jquery";
import BreadCrumbs from '../BreadCrumbs';
import ReactTable from 'react-table';
import _ from 'underscore';
import ReactExport from "react-export-excel";
import CsvDownloader from 'react-csv-downloader'
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

const data = [];
let context="null";
const colformail = [{
  id:"mailId",
  label:"MailId"},{
  id:"subject",
  label:"Subject"},
  {id:"message",
  label:"Message"},
    {
      id:"attachment",
      label:"Attachment"},
      {
        id:"from",
        label:"From"},
        
          {
            id:"client",
            label:"Client"},
            {
              id:"assignmentId",
              label:"AssignmentId"}]
const columnsForExcel = [
  {
    id: 'assignmentId',
    displayName : "Assingment ID",

  },
  {
    displayName : "Name",

    id: "name"
  },
  {
    displayName : "Status",
    id: "status",
   
  },
  {
    displayName : "Payment Status",
    id: "paymentStatus",
   
  },
  {
    displayName : "Start Date",
    id: "startDate",
   
  },
  {
    displayName : "End Date",
    id: "endDate",
 
  },

  {
    displayName : "Overview",
    id: "assignmentOverview",
   
  },

  {
    displayName : "Priority",
    id: "priority",
   
  },
  {
    displayName : "Client",
    id: "selectedClient",
   
  },
  {
    displayName : "Organisation",
    id: "selectedOrganisation",

  },
  {
    displayName : "Team Lead",
    id: "teamLead",
 
  },
  {
    displayName : "Team",
   
    id: "teamMembers",
   
  },
  {
    displayName : "Template",
   
    id: "selectedTemplate",
   
  },
  {
    displayName : "Recurrance Frequency",
   
    id: "recurringFrequency",
   
  },
  {
    displayName : "Priority",
   
    id: "priority",
   
  },
  {
    displayName : "By",
   
    id: "by",
   
  },
  {
    displayName : "Date",
    id: "date",
    
  },
  {
    displayName : "Time",
    id: "time",
  
  }
];
const columns = [
 {
    Header: props => <span className="form-textHead cell-head text-center">Status</span>,
    accessor: d => {return {isRegistered:d.isRegistered,id:d.id}},
    filterMethod: (filter, row) =>
             { return row.name.name.toLowerCase().includes(filter.value.toLowerCase()) }
                     
                  ,
    id:"registered",
    Cell: props => props.value.isRegistered ? <div className="text-center">
  
  <button className="regUser">Registered</button></div>
     :<div className="text-center">
    <Link className="link" to={"/legal/homepage/contact-us/add-client/"+props.value.id}><button className="addUser">Add User</button></Link></div>,
    minWidth:180
  },{
  Header: props => <span className="form-textHead cell-head">Name</span>,
  accessor: d => {return {name:d.name,id:d.id,title:d.title}},
  id:"name",
  filterMethod: (filter, row) =>
  {debugger;   return row.name.name.toLowerCase().includes(filter.value.toLowerCase()) }
          
       ,
  Cell: props => <Link className="link" to={"/legal/homepage/contact-us/"+props.value.id}>{props.value.title} {props.value.name}</Link>,
  
}, {
  Header: props => <span className="form-textHead cell-head">Email</span>,
  filterMethod: (filter, row) =>
  {debugger;   return row.email.toLowerCase().includes(filter.value.toLowerCase()) },
  accessor: 'email',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:180
},
{
  Header: props => <span className="form-textHead cell-head">Phone</span>,
  accessor: 'phone',
  Cell: props => <span className='form-text cell'>{props.value}</span>
},{
  Header: props => <span className="form-textHead cell-head">Company</span>,
  accessor: 'company',
  Cell: props => <span className='form-text cell'>{props.value}</span>
},{
  Header: props => <span className="form-textHead cell-head">Query</span>,
  accessor: 'message',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:200
},{
  Header: props => <span className="form-textHead cell-head">Status</span>,
  id:"status",
  accessor: d => {return {status:d.status,id:d.id}},
  Cell: props => <button
  className={"badge "+
  ( props.value.status=="close" ? "red" : "")
  }
  onClick={() => context.toggleOpen(props.value.id)}
  id="status"
  >
  
  {props.value.status==undefined ? "open" : props.value.status}
  </button>
},


{
  Header: props => (
    <span className="form-textHead cell-head">Date of Creation</span>
  ),
  accessor: d => {
    return { date: d.date, id: d.id };
  },
  filterMethod: (filter, row) => {
    return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase());
  },

  id: "date",
  Cell: props => new Date(props.value.date).toDateString(),
  minWidth: 180
},
{
  Header: props => (
    <span className="form-textHead cell-head">Time of Creation</span>
  ),
  accessor: d => {
    return { date: d.date, id: d.id };
  },
  filterMethod: (filter, row) => {
    return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase());
  },

  id: "time",
  Cell: props => new Date(props.value.date).toLocaleTimeString(),
  minWidth: 280
}


]
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
export default class Contactus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      menu1:[
       



 ]
 
    };
    context = this;
  }
  componentDidMount(){

  this.fetchQueries(this,this.props.source);
  this.fetchAllMails(this);}


  toggleOpen = id => {
    var list  =this.state.menu1;
    var index = _.findIndex(list,function(entry){return entry.id==id});
    
    list[index].status =  (list[index].status == "open" || list[index].status==undefined) ? "close" : 'open';
    
    var newList = JSON.parse(JSON.stringify( list ));
    context.setState({menu1:newList});
    context.updateStatus(context,id,list[index].status);
    
    
    
      };

  
  fetchQueries(that,source){

    axios.get(apiBaseUrl5+"businusquery/queryList/contactUs/all")
    .then(function (response) {
      console.log(response.data);
      //that.setState({menu1:response.data});
      that.fetchRegisteredUsers(that,response.data);
      that.generateExcelData(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }



  generateExcelData = data => {
    
    let excelData = [];
    _.each(data, function(d) {
  
      
      let row = {
      
        queryId: d.queryId,
        name: d.name,
        company: d.company,
        phone:d.phone,
        email: d.email,
        message: d.message,
       
        date: new Date(d.date).toDateString(),
        time: new Date(d.date).toLocaleTimeString()
     
     
      
      }

       
      excelData.push(row);
      console.log(excelData);
    });
    this.setState({ excelData });
  };
  
  getCommaSeparated=(teamMember,name)=>{
    console.log(teamMember)
   let names= _.map(teamMember, function(d){
   return d[name];
   })
   
   return names.join(";")
   }
  
  fetchRegisteredUsers(that,queries){

    axios.get(apiBaseUrl2+"businus/users")
    .then(function (response) {
      console.log(response.data);
      that.setState({users:response.data.Users});
    
       debugger;
      _.each(queries,function(query){
       
        var isExists = _.filter(response.data.Users,function(usr){
          
            return usr. customerEmail === query.email;

        }).length;

       
        query['isRegistered'] = isExists > 0 ? true : false;


      });

        that.setState({menu1:queries});


    })
    .catch(function (error) {
      console.log(error);
    });
  
  }


  updateStatus(that,id,status){

    axios.put(apiBaseUrl5+"businusquery/query/"+id,{status:status})
    .then(function (response) {
      console.log(response.data);
      
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }


  fetchAllMails(that){

    axios.get(apiBaseUrl5 +"businus/allQueryMails/contactus/all"+localStorage.getItem("employeeId"))
    .then(function (response) {
      console.log(response.data);

      var threads = response.data=="" ? [] : _.sortBy(response.data,'date').reverse();
     

      var documents = _.where(threads,{docType:"Document"});
      var proposals = _.where(threads,{docType:"Proposal"});
      var mails = _.where(threads,{docType:"Mail"});
      that.setState({documents});
      that.setState({proposals});
      that.setState({mails});

    })
    .catch(function (error) {
      console.log(error);
    });
  
  }
  render() {
    return (
      <div>
          
     
      <div className="Div">
      
      <div className="row breadcrumb-row">
          <div className="col-md-10"><BreadCrumbs/></div>
          <div className="col-md-2">
            <div >
            <ExcelFile element={<button className=" download-button">Download Data</button>}>
            {this.state.excelData ?
                Object.keys(this.state.excelData[0]).map(d=>  <ExcelColumn label={d} value={d}/>)
              : ''}

                <ExcelSheet data={this.state.mails} name="Mails"> 
                {colformail.map(d=>  <ExcelColumn label={d.label} value={d.id}/>)}
                   
              
                </ExcelSheet>
                <ExcelSheet data={this.state.documents} name="Documents"> 
                {colformail.map(d=>  <ExcelColumn label={d.label} value={d.id}/>)}</ExcelSheet>
                <ExcelSheet data={this.state.proposals} name="Proposals"> 
                {colformail.map(d=>  <ExcelColumn label={d.label} value={d.id}/>)}</ExcelSheet>
                
             
            </ExcelFile></div>
      </div>
      </div>
            <div className="row">
           
              <Row>
                <Col>
                <ReactTable className="myTable" resolveData={data => data.map(row => row)}  data={this.state.menu1} columns={columns} filterable/>
                </Col>
              </Row>
            </div>
          </div>
          </div>
    );
  }
}
