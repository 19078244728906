import React, { Component } from 'react';
import axios from 'axios';
import GSTSidebar from './GSTSidebar';

export default class AddGstCategory extends Component{
    constructor(props){
        super(props)
        this.state={
            pageHeading:'',
            allCategory:[],
            totalCatgory:-1,
        }
        this.validateEntry=this.validateEntry.bind(this)
        this.fetchCategory=this.fetchCategory.bind(this)
        this.saveCategory=this.saveCategory.bind(this)
        this.setType=this.setType.bind(this)
    }

    componentDidMount(){
    var lastUrl = window.location.pathname.split('/').pop()

    this.setType(lastUrl)
    
    }

    setType(lastUrl){

        let page=''
        if(lastUrl==='circular-notification'){
            page='Circular Notification'
          }
          else if(lastUrl==='case-law'){
            page="Case Law"
          }
          else if(lastUrl==='acts'){
            page='Acts'
          }
          else if(lastUrl==='rules'){
            page='Rules'
          }
          this.setState({pageHeading:page})
          this.fetchCategory(page)
    }

    fetchCategory(page){
      let allCategory=[]
      return axios({
        url: 'https://3j0okb7n5h.execute-api.us-east-1.amazonaws.com/dev/businus/legalCategory/list',
        method: 'GET',
        crossDomain: true,
        responseType: 'json', // important
      }).then((response) => {
        for(var i=0;i<response.data.length;i++){
          if(response.data[i].legalType==='GST')
            if(response.data[i].type===page){
              
            allCategory.push(response.data[i])
          }
        }
        this.setState({allCategory:allCategory,totalCatgory:allCategory.length-1})
       
     });
    }

    saveCategory(){
        let law = document.getElementById('law').value.trim()
        let lawDes = document.getElementById('lawDes').value.trim()

        let validate= this.validateEntry(law,lawDes)

      if(law===""){
        alert("Please add the category")
      }
      else if(validate){
          alert("Provided value is already present")
      }

      var data = {
        legalType:"GST",
        type:this.state.pageHeading,
        law:law,
        lawDes:lawDes,
        index:this.state.totalCatgory+1,
      }
      console.log('GST category:',data)
      if(law!=="" && validate===false){
        axios({
        url: 'https://3j0okb7n5h.execute-api.us-east-1.amazonaws.com/dev/businus/legalCategory/add',
        method: 'POST',
        data: data,
      }).then((response) => {
        console.log('resonse_data_dynamo :', response.data)
        if(response.status){
          alert("Category Added")
          window.location.reload()
        }
     });
      }

    }


    validateEntry(law,lawDes){
        let allCategory=this.state.allCategory
        let flag=false
        for(var i=0;i<allCategory.length;i++){
            if(allCategory[i].law===law){
                if(allCategory[i].lawDes===lawDes){
                    flag=true
                    break
                }
            }
        }

        return(flag)
    }
    render(){
        return(
            <div>
            <div className="row">
            <div className="col-md-2"> <GSTSidebar/></div>
            <div className="col-md-10">
                <div className = "container-fluid overflow-auto" style={{margin: "10px", height:'90vh'}}>
        <div style={{margin: "10px"}}><h3>Add {this.state.pageHeading} Category: </h3></div>
        <div className="container-fluid" style={{}}>
        
        <div class="well well-sm">
        <div class="input-group mb-3" style={{width:"80%"}}>
              <span type="text" class="input-group-addon" id="" style={{width:"20%"}}>Column one</span>
              <input type="text" class="form-control" list="lawlist" id="law" placeholder="Heading" autocomplete="off" style={{}} required></input>
              <input type="text" class="form-control" list='lawDeslist' id="lawDes" placeholder="Description" style={{}} autoComplete='off' required></input>
            </div><br/>  




        </div> 
        </div>
        <div ClassName="col-md-4" style={{width : "80%", position:"relative", height:"50px"}}>
            <button type='button' class="btn btn-primary" style={{margin:'10px'}} onClick={this.saveCategory}>Add Category</button>
        </div>

        </div>
        </div>
        </div>
            </div>

        )
    }
}