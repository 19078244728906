import React, { Component } from "react";
import "../../style/panel.css";
import {BrowserRouter, Route} from 'react-router-dom';
import { Link } from "react-router-dom";

import $ from "jquery";

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import listInstantDownload from './listInstantDownload';
import listVetting from './listVetting';
import listTailoring from './listTailoring';

import Askanexpert from './listAskanexpert';

import BreadCrumbs from '../BreadCrumbs';

import Home from '../home';

import Header from "../header";
import pageAskanexpert from "./pageAskanexpert";

import pageInstantDownload from "./pageInstantDownload";
import pageVetting from "./pageVetting";
import pageTailoring from "./pageTailoring";

import Sidebar2 from './Sidebar2';
import Dashboard from './Dashboard'
import AddClientsByButton from "../common/addClientsByButton";


const data = [];
export default class MainApp2 extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []
     
    };
    
  }
  componentDidMount(){
    this.handleLine();
    this.setState({title:this.props.match.params.docType});
  }
  handleLine(){
    
   
    const tab1= this.refs.tab1;
    const sidebar1= this.refs.sidebar1;
    const section= this.refs.section;
   
    
    $(function(){
      $("#tab1").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
        $("#section").toggle();
      });
      });
     
  }
  
  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
      <div className="mainDiv" >
       
       <div className="row">
         <div className="col-md-2">
         <Sidebar2 title={this.state.title}/>
         </div>
         <div className="col-md-10">
         
      
<div className="section">
<Route exact path = "/legal/docs/:docType/ask-an-expert/:id" component = {pageAskanexpert} />

<Route exact path = "/legal/docs/:docType/instant-download/:id" component = {pageInstantDownload} />
<Route exact path = "/legal/docs/:docType/vetting/:id" component = {pageVetting} />
<Route exact path = "/legal/docs/:docType/tailoring/:id" component = {pageTailoring} />


<Route  exact path = "/legal/docs/:docType/vetting" component = {listVetting} />
<Route  exact path = "/legal/docs/:docType/tailoring" component = {listTailoring} />
<Route  exact path = "/legal/docs/:docType/instant-download" component = {listInstantDownload} />

<Route exact path = "/legal/docs/:docType/ask-an-expert" component = {Askanexpert} />
<Route exact path = "/legal/docs/:docType" component = {Dashboard} />
<Route exact path="/legal/docs/:docType/:action/add-client/:id"
                component={AddClientsByButton}
              />

</div>

         </div>
       </div>
      
     </div>

    );
  }
}
