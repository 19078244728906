import React, { Component } from 'react';

import '../style/agency.css';
import reactCSS from 'reactcss'
import axios from 'axios';

import {apiBaseUrl,baseFilePath} from './apiUtils';



import FileBase64 from 'react-file-base64';
import '../animations.css';


import { SketchPicker } from 'react-color';

import '../animations.css';
let context = null;


/**
 * Timeline-Modal
 * It is the code for adding timeline modal in the template
 * @module TimelineModal
 */
class Modal extends Component {
  constructor(props){
    super(); 
    this.state ={
      
      head:"", 
      subhead:"",
      description:"" ,
      backgroundColor:'',
      timelineImage:'',
      color:'',
      descriptionColor:'',
      files:[],
      fileName:""

    };
    this.saveProduct = this.saveProduct.bind(this);
    context = this;
    this.uploadApi = this.uploadApi.bind(this);
    this.hideModal = this.hideModal.bind(this);
   
    
   }
   
   componentDidMount(){
    var product = this.props.product;
    this.setState({head:product.head});
    this.setState({subhead:product.subhead});
    this.setState({timelineImage:product.timelineImage});
    this.setState({description:product.description});
    this.setState({backgroundColor:product.styles.backgroundColor});
    this.setState({color:product.styles.color});
    this.setState({descriptionColor:product.styles.descriptionColor});


   }
   uploadApi(){
    var data = {image:this.state.files.base64,fileName:this.state.files.name}
    axios.post(apiBaseUrl+"/uploadImage",data)
    .then(function (response) {
  
      context.setState({timelineImage:response.data.Location});
     
    })
    .catch(function (error) {
      console.log(error);
    });
  

   }

   getFiles(files){
    this.setState({ files: files })
    this.uploadApi();
  }
  
  onChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);


    console.log(this.state);
  }



   saveProduct(index){
     
      this.props.saveProduct(index,this.state);
      this.props.hideModal(index);
      
    
   }

   hideModal(key){

    this.props.hideModal(key);
   }

   
   toggleColorPicker = (colorPicker) => {
    if(colorPicker=="bg"){
     this.setState({ displayBgColorPicker: !this.state.displayBgColorPicker })
    }
    else if(colorPicker=='color'){
      this.setState({ displayColorPicker: !this.state.displayColorPicker })
 
     }
    else if(colorPicker=='des'){
     this.setState({ displayDesColorPicker: !this.state.displayDesColorPicker })

    }
    else{}
   
 };
 handleChangeComplete = (color) => {
  this.setState({ backgroundColor: color.hex });
};
handleChangeColorComplete = (color) => {
  this.setState({ color: color.hex });
};
handleChangeDesColorComplete = (color) => {
  this.setState({ descriptionColor: color.hex });
};
handleClose = () => {
  this.setState({ displayColorPicker: false });
  this.setState({ displayBgColorPicker: false });
  this.setState({ displayDesColorPicker: false });
};

   
    render(){
      const styles = reactCSS({
        'default': {
          color: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `${this.state.backgroundColor}`,
          },
          color2: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `${this.state.color}`,
          },
          color3: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `${this.state.descriptionColor}`,
          },
          swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
          },
          popover: {
            position: 'absolute',
            zIndex: '2',
          },
          cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          },
        },
      });
  
    return(
this.state.hideModal ? <div></div>  : 


<div className="modal-wrapper">
<div className="container">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <form>
              <div id="contact-form" className="form-container" data-form-container style={{color: 'rgb(46, 125, 50)', background:'white'}}>
              <div className="row modal-back-color">
                <div style={{'marginLeft': '38px'}} onClick={this.props.toggleStyleModal}><h5 className="close-button" style={{marginLeft:'440px'}}>X</h5></div>
                  <div className="form-title text-center">
                    <h5 className="modal-head" style={{padding:'5px 151px 5px 160px'}}> Edit Timeline Modal </h5>
                  </div>
                  
                </div>
                <div className="input-container">
                <div className="row">
                    <div className="col-md-12">
                    <form id="field">
                  {/*  <div className="row modal-row">
                    <div className="col-md-3">
    Image Url:</div> <div className="col-md-9"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.icon} name="icon"/><br/></div></div>*/}
                    
                     <div className="row">
                    <div className="col-md-3">
    backgroundColor: </div> <div className="col-md-9 "> <div style={ styles.swatch } onClick={ ()=>this.toggleColorPicker('bg') }>
          <div style={ styles.color } />
        </div>
        { this.state.displayBgColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ this.handleClose }/>
          <SketchPicker  color={this.state.backgroundColor} onChangeComplete={ this.handleChangeComplete }/><br/>
           </div> : null }
           </div>
           </div>

           <div className="row">
           <div className="col-md-3">
                    <br/> color: </div> <div className="col-md-9 modalrow"><div style={ styles.swatch} onClick={ ()=>this.toggleColorPicker('color') }>
          <div style={ styles.color2 } />
        </div>
        { this.state.displayColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ this.handleClose }/>
          <SketchPicker  color={this.state.color} onChangeComplete={ this.handleChangeColorComplete }/><br/>
        </div> : null }
        </div></div>

        <div className="row">
           <div className="col-md-3">
                    <br/> Description color: </div> <div className="col-md-9 modalrow"><div style={ styles.swatch} onClick={ ()=>this.toggleColorPicker('des') }>
          <div style={ styles.color3 } />
        </div>
        { this.state.displayDesColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ this.handleClose }/>
          <SketchPicker  color={this.state.descriptionColor} onChangeComplete={ this.handleChangeDesColorComplete }/><br/>
        </div> : null }
        </div></div>

        <div className="row modal-row">
                    <div className="col-md-3">
                     Head:</div> <div className="col-md-9"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.head} name="head"/><br/></div></div>
                     <div className="row modal-row">
                    <div className="col-md-3">
                     Timeline Image:</div> <div className="col-md-9"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.timelineImage} name="timelineImage"/>
                     <br/> <FileBase64
        multiple={ false }
        onDone={ this.getFiles.bind(this) } /> </div></div>
                    
                     <div className="row modal-row">
                    <div className="col-md-3">
                     Sub Head:</div> <div className="col-md-9"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.subhead} name="subhead"/><br/></div></div>
                     <div className="row modal-row">
                    <div className="col-md-3">
                     Description:</div> <div className="col-md-9"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.description} name="description"/><br/></div></div>
  
                {/* <button type="button"   className="imagebutton"> Save </button>*/}
                   </form>
                      </div>
                      </div>
                  
                  <div className="row submit-row">
                    <div className="col-md-2"></div><div className="col-md-4">
                    <button type="button" onClick={()=>this.saveProduct(this.props.keyindex)}  className="btn btn-block savebutton submit-form valid">Save</button>
                    </div>
                    <div className="col-md-4">
                    <button type="button" onClick={()=>this.props.hideModal(this.props.keyindex)} className="btn btn-block cancelbutton submit-form valid">Cancel</button>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div></div>
      </div>
    );
  

    }
}

export default Modal;