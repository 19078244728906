import React, { Component } from "react";

import { Link } from "react-router-dom";
import BreadCrumbs from "../../BreadCrumbs";
import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { ChatFeed, Message } from 'react-chat-ui'
import avatar  from "../../../images/user-avatar.jpg";
import SubmitButton from '../../common/SubmitButton';
import Select from 'react-select';
import {validate} from '../../common/Validation';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from 'underscore';
let context = null;
const data = [];
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px ',
    position: 'Absolute',
    zIndex: '30000',
    backgroundColor: 'none',
  }
};

export default class addEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailExists:-1,
      userRole:"User",
      workEx: [{
        previousCompany: "",
        jobTitle: "",
        fromDate: "",
        toDate: "",
        jobDescription: ""
      }],
      education:[{
        schoolName: "",
        degree: "",
        fieldOfStudy: "",
        dateOfCompletion: "",
        additionalNotes: "",
        interests: ""
      }],
      dependents:[{
        name: "",
        relationship: "",
        dateOfBirth: ""
      }],
      canCustomise:false,
      role:'',
      designations:[],
      departments:[],
      organisations:[],
      gender:"male",
      attributesGroup:[],
      count:'',
      employees:[],
      superSliderState:'additional',
      sliderState:1,
      iammarried:false,
      bankName: data.bankName,
      branchName:data.branchName,
      accountNo:data.accountNo,
      accountType:data.accountType,
      ifscCode:data.ifscCode,
      attribute:data.attribute,
      maritalSatus:'',
      profile:"",
      title:'',
      description:'',
      designationTitle:'',
      designationDescription:'',
      departmentTitle:'',
      departmentDescription:'',
      dateofann:'',
      organisation:'',
      isSubmitting:false,
    };
    context = this;
    this.openModal = this.openModal.bind(this);

    this.closeModal = this.closeModal.bind(this);
  }

  changeSliderState(orderNumber) {

    this.setState({ sliderState: orderNumber });

  }
  //function for changing the status button on click to open or close
  handleOnChange = (e) => {
    debugger;
    var value = e.target.value;

    var formName = e.target.name;

    this.setState({ [formName]: value });

    if(formName=="email"){
      this.setState({emailExists:-1});
      this.checkIfEmailAlreadyExists(value);
    }

    if(formName=='maritalStatus' && value=='married')
{
     this.setState({iammarried:true})
     
}
if(formName=='maritalStatus' && value=='Single')
{
  this.setState({iammarried:false})
  
}

if(formName=='role' && value=="customise")
{this.setState({canCustomise:true})
}
if(formName=='role' && value=="admin")
{this.setState({canCustomise:false})
}
if(formName=='role' && value=="employee")
{this.setState({canCustomise:false})
}



    console.log(this.state);
  }


  changeSuperSliderState(name) {

    this.setState({ superSliderState: name });
    if(name==='additional'){
      this.setState({ sliderState: 1 });
    }
    else{
      this.setState({ sliderState: 13 });
    }
  

  }
  handleOnChangePerson = (e, i, stateName) => {

    var list = this.state[stateName];

    list[i][e.target.name] = e.target.value;

    this.setState({ [stateName]: list });




  }

  
  handleOnChangeAttribute = (e,i)=>{
    
    var attributesGroup = this.state.attributesGroup;
   
   

    attributesGroup[i][e.target.name] = e.target.value;

    this.setState({attributesGroup:attributesGroup});




  }


  addNewAttribute = () =>{

    var newAttribute = {
      
      name:"",
      value:"",
      attachment:""
    }

    var attributesGroup = this.state.attributesGroup;
    attributesGroup.push(newAttribute);
    this.setState({attributesGroup:attributesGroup});
 


  }
  
  onMultiSelect = selectedOption => {
    this.setState(
      { assignedOrganisation:selectedOption },
      () => console.log(`Option selected:`, this.state.selectedOption)
    );
  };
  
  onRemove(optionList, removedItem) {
    context.setState({modules:optionList});
  }
  
  fileUploadHandlerForAttachment = (that, docType,i) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });


          
          //that.setState({ [docType]: "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });



          var attributesGroup = that.state.attributesGroup;
   
   

          attributesGroup[i]['attachment'] = "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name
      
          that.setState({attributesGroup:attributesGroup});

          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  addNewContact = (stateName) => {


    var newRow = {}



    if (stateName === 'workEx') {
      newRow = {
        previousCompany: "",
        jobTitle: "",
        fromDate: "",
        toDate: "",
        jobDescription: ""
      }

    } else if (stateName === 'education') {

      newRow = {
        schoolName: "",
        degree: "",
        fieldOfStudy: "",
        dateOfCompletion: "",
        additionalNotes: "",
        interests: ""
      }

    }
    else {
      newRow = {
        name: "",
        relationship: "",
        dateOfBirth: ""
      }
    }


    var list = this.state[stateName];
    list.push(newRow);
    this.setState({ [stateName]: list });



  }
  openModal = (type) =>{
  
    this.setState({ [type]: true });
  }



  closeModal = (type) =>{
    this.setState({ 'organisationModalIsOpen': false });
    this.setState({ 'departmentModalIsOpen': false });
    this.setState({ 'designationModalIsOpen': false });
  }


  componentDidMount() {
    const id = this.props.match.params.id;

    this.fetchQueryById(this, id);
    this.fetchThreadById(this, id);
    this.fetchDesignations(this);
    this.fetchDepartments(this);
    this.markAsRead(this, id);
    this.fetchEmployee(this);
    this.fetchOrganisations(this);
    this.countQueries(this);
  }


  markAsRead(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/read/" +
        id
      )
      .then(function (response) {


      })
      .catch(function (error) {
        console.log(error);
      });
  }


  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(
        apiBaseUrl2+"businus/customer/list/"+localStorage.spId

      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });



          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  fetchThreadById(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/listmail/" +
        id
      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        var threads = response.data == "" ? [] : _.sortBy(response.data, 'date').reverse();
        that.setState({ thread: threads });

        var documents = _.where(threads, { docType: "Document" });
        var proposals = _.where(threads, { docType: "Proposal" });
        var mails = _.where(threads, { docType: "Mail" });
        that.setState({ documents });
        that.setState({ proposals });
        that.setState({ mails });


      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getFiles(files) {

    this.setState({ selectedFile: files[0] });

    this.fileUploadHandler(this, "profile")

  }

  getFileForAttach(files) {
    this.setState({ selectedFile: files[0] });

   

  }

  getFormattedDate(millis) {

    return new Date(millis).toDateString();


  }

  toggleOpen = status => {
    if (status == undefined || status == "open") {
      status = "close";
    } else {
      status = "open";
    }
    //(this.setState({ entry: [...this.state.entry,{status:status} ] });)
    var newEntry = this.state.entry;
    newEntry.status = status;
    this.setState({ entry: newEntry });
    context.updateStatus(context, this.state.entry.id, status);
  };

  changeDocType(type) {

    this.setState({ docType: type });
  }

  addDepartment() {
    var data = context.state;
    axios
      .post(
        apiBaseUrl2+"businus/employee/props/add",
        {

          "departmentId": data.departmentId,
          "title": data.departmentTitle,
          "type":"Department",
          description:data.departmentDescription,
          "by":localStorage.userName,
          "spId":localStorage.spId,

        })
      .then(function (response) {
        debugger;
        alert("Success!");
        context.fetchDepartments(context);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  addOrganisation() {
    
    axios
      .post(
        apiBaseUrl2+"businus/employee/props/add",
        {

          "title": context.state.organisationTitle,
          "type":"organisation",
          description:context.state.organisationDescription

        })
      .then(function (response) {
        debugger;
        alert("Success!");
        context.fetchOrganisations(context);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  addDesignation() {
    var data = context.state;
    axios
      .post(
        apiBaseUrl2+"businus/employee/props/add",
        {

          "designationId":data.designationId,
          "title": data.designationTitle,
          "type":"Designation",
          description:data.designationDescription,
          "by":localStorage.userName,
          "spId":localStorage.spId,
        })
      .then(function (response) {
        debugger;
        alert("Success!");
        context.fetchDesignations(context);
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  checkIfEmailAlreadyExists(email) {
debugger;
    if(!this.validateEmail(email)){

      return;
    }
    
    axios
      .get(
        apiBaseUrl1+"businus/uniqueEmail/employee/"+email)
      .then(function (response) {
       
        if(response.data>0){
          context.setState({emailExists:1});
        }else{
          context.setState({emailExists:0});
        }
        
       
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  sendMail =() =>{
    this.setState({isSubmitting:true});
    var data = context.state;

    if(validate('required',data.firstName,data.lastName,data.email,data.nickName)){

      alert
      ("Please fill all the required fields");
      return;
    }

   

    var workEx= data.workEx
    workEx=_.map(workEx, function(d){
      d.previousCompany=d.previousCompany!=""?d.previousCompany:undefined
      d.jobTitle= d.jobTitle!=""?d.jobTitle:undefined
      d.fromDate= d.fromDate!=""?d.fromDate:undefined
      d.toDate= d.toDate!=""?d.toDate:undefined
      d.jobDescription= d.jobDescription!=""?d.jobDescription:undefined
      return d;
  })





  
    axios
      .post(apiBaseUrl2+"businus/employee/add",
        {

          "employeeId": data.employeeId,
          "dateOfBirth":data.dateOfBirth,
          "firstName": data.firstName,
          "lastName": data.lastName,
          "organisations":"All",
          "email": data.email,
          "nickName": data.nickName,
          "department": data.department,
          "reportingTo": data.reportingTo,
          "sourceOfHire": data.sourceOfHire,
          "seatingLocation": data.seatingLocation,
          "location": data.location,
          "title": data.title,
          "dateOfJoining": data.dateOfJoining,
          "employeeStatus": data.employeeStatus,
          "employeeType": data.employeeType,
          "workEx": workEx,
          "dependents": data.dependents,
          "education": data.education,
          "mobile": data.mobile,
          "otherEmail": data.otherEmail,
          "maritalStatus": data.maritalStatus,
          "address": data.address,
          "tags": data.tags,
          "jobDescription": data.jobDescription,
          "expertise": data.expertise,
          "aboutMe": data.aboutMe,
          "dateOfExit": data.dateOfExit,
          "gender": data.gender,
          "bankName": data.bankName,
          "branchName":data.branchName,
          "accountNo":data.accountNo,
          "accountType":data.accountType,
          "ifscCode":data.ifscCode,
          "micrCode":data.micrCode,
          "attributes":data.attributesGroup,
          "maritalSatus":data.maritalStatus,
          "dateofAnn":data.dateofAnn,
          
          profile:data.profile,
          "by":localStorage.userName ,
          "role":data.role, //admin/employee/others,
          "modules":[

             

          ],
          "spId":localStorage.spId
      
        })
      .then(function (response) {
        debugger;
        alert("Success!");
        context.setState({isSubmitting:'done'});
      })
      .catch(function (error) {
        console.log(error);
        context.setState({isSubmitting:'done'});
      });
  }

  countQueries(){

    axios.get(apiBaseUrl2+"businus/employee/list/"+localStorage.spId)
    .then(function (response) {
      console.log(response.data);

      let allEmp = response.data;
      if(localStorage.userRole!='Admin'){

        allEmp = _.filter(allEmp,function(emp){

          return emp.role!='Admin';
        });
      }
     
      
      context.setState({count:allEmp.length});
     
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }
  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    localStorage.setItem("MAILURL", url);


  }


  fetchDepartments(that,source){

    axios.get(apiBaseUrl2+"businus/employee/props/list/department/"+localStorage.spId)
    .then(function (response) {
      console.log(response.data);
      var departments = response.data;
      
      departments.unshift({title:"Select Department", description:""})
      that.setState({departments:response.data});
      
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }

  fetchDesignations(that,source){

    axios.get(apiBaseUrl2+"businus/employee/props/list/Designation/"+localStorage.spId)
    .then(function (response) {
      console.log(response.data);
      var designations = response.data;
      
      designations.unshift({title:"Select Designation", description:""})
      that.setState({designations:response.data});
    })
    .catch(function (error) {
      console.log(error);
    });
    
  }


  fetchOrganisations(that,source){

    axios.get(apiBaseUrl1+"businus/organisation/list/"+localStorage.spId)
    .then(function (response) {
      console.log(response.data);
      debugger;
      let newOrg = [];
       let organisation = response.data;
       _.each(organisation, function (member) {


        newOrg.push({
          value:member.id,
            label: member.organisationName,
          name: member.organisationName,
          id: member.id,
          logo:member.logo,
          organisationId:member.organisationId,
          organisationEmail:member.organisationEmail
        })





      });

     
       //offline.unshift({organisationId:"",organisationName:"Select Organisation"});
      that.setState({organisation:newOrg});
    
    })
    .catch(function (error) {
      console.log(error);
      
    });
  
  }
  fetchEmployee(that,source){

    axios.get(apiBaseUrl2+"businus/employee/list/"+localStorage.spId)
    .then(function (response) {
      console.log(response.data);
      var employee = response.data;
    employee.unshift({firstName:"Select Employee", description:""})
    that.setState({employees:response.data});
      
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }




  //updating the status button

  render() {
    const {sliderState} = this.state;
    const {superSliderState, entry} = this.state;    
    const active = " active-button"
    const deactive = "";
    return (
      <div className="Div">
      <div className="row breadcrumb-row">
           <div className="col-md-10">
             <BreadCrumbs />
           </div>
           </div>
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
         
          <div className="dedicated-page">
            {this.state.UserLimit == undefined || this.state.count < Number(localStorage.UserLimit) ?
          <div>
          <div className="row-resource-head">
            
                  <h2 className="resource-head">Add an Employee </h2>
                  </div>
            <div className="row ">
              <div className="col-md-12 info-column account-col2">
                <div id="field">
                
                  <div className="container">
                    <div className="row">
                      <h4  className="account-head" > Basic info</h4>
                      <div className="col-md-8">


                      


                        <div className="form-group">
                          <label className="control-label col-md-4">
                            {" "}
                            First Name*:{" "}
                          </label>
                          <div className="col-md-6">
                            <input
                              id="ember301"
                              onChange={this.handleOnChange}
                              name="firstName"
                              value={this.state.firstName}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">
                            {" "}
                            Last Name*:{" "}
                          </label>
                          <div className="col-md-6">
                            <input
                              id="ember301"
                              onChange={this.handleOnChange}
                              name="lastName"
                              value={this.state.lastName}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>

                        

                        <div className="form-group">
                          <label className="control-label col-md-4">
                            {" "}
                            Organisation(s):{" "}
                          </label>
                          <div className="col-md-6">
                            <input
                              id="ember301"
                              onChange={this.handleOnChange}
                              name="lastName"
                              value="All"
                              className="form-control ember-text-field ember-view"
                              type="text"
                              disabled="true"
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-4">
                            {" "}
                            Email ID*:{" "}
                          </label>
                          <div className="col-md-6">
                            <input
                              id="ember301"
                              onChange={this.handleOnChange}
                              name="email"
                              value={this.state.email}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                            <br/>
                            {this.state.emailExists==1 ?  <span style={{color: 'red'}}>
                             Sorry this email is unavailable
                            </span> :
                            (this.state.emailExists==0 ? <span style={{color: 'green'}}>
                            Email available
                           </span>  : '')}
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-4">
                            {" "}
                            Nick Name:{" "}
                          </label>
                          <div className="col-md-6">
                            <input
                              id="ember301"
                              onChange={this.handleOnChange}
                              name="nickName"
                              value={this.state.nickName}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>
                        {localStorage.userRole=='Admin' ? 
                        <div className="form-group">
                          <label className="control-label col-md-4">
                       Role
                          </label>
                          <div className="col-md-6">
                            <select
                              id="ember301"
                              onChange={this.handleOnChange}
                              name="role"
                              value={this.state.role}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            >

                      <option>Admin</option>
                      <option>User</option>
                            </select>
                          </div>
                        </div> : ''}



                      </div>
                      <div class="col-md-4">

                       <img src={this.state.profile!="" ? this.state.profile : avatar}  className="avatar"/>   
                   <span>    <FileBase64
               multiple={true}
               onDone={this.getFiles.bind(this)} >
                 </FileBase64>
</span>
                      </div>
                    </div>



                    <div className="row " style={{marginTop:'40px'}}>
     <div className={superSliderState == 'additional' ? "col-md-2 active1 superoptions" : "col-md-2 superoptions"} >
       <div className=" " onClick={() => this.changeSuperSliderState('additional')} >Additional Info</div>
     </div>

   {/*   <div className={superSliderState == 'access' ? "col-md-2 active1 superoptions" : "col-md-2 superoptions"} >
       <div className="" onClick={() => this.changeSuperSliderState('access')}>Access Controls</div>
     </div>
     <div className={superSliderState == 'reports' ? "col-md-2 active1 superoptions" : "col-md-2 superoptions"} >
       <div className="" onClick={() => this.changeSuperSliderState('reports')}>Reports</div>
     </div> */}

   </div>


{superSliderState==='additional' ? 

   <div className="row " style={{borderTop:'2px solid #4985BD', backgroundColor:'#f7f7f7'}}>
     <div className={sliderState == 1 ? "col-md-1 active2 " : "col-md-1"} >
       <div className="options2 "  onClick={() => this.changeSliderState(1)} ><i className="fa fa-user optionsIcon" style={{fontSize:'12px'}}></i>Personal</div>
     </div>
     <div className={sliderState == 2 ? "col-md-1 active2 " : "col-md-1"} >
       <div className="options5"  onClick={() => this.changeSliderState(2)}><i className="fa fa-briefcase optionsIcon" style={{fontSize:'12px'}}></i>Work</div>
     </div>
     <div className={sliderState == 3 ? "col-md-1 active2 " : "col-md-1"} >
       <div className="options2"  onClick={() => this.changeSliderState(3)}><i className="fa fa-file optionsIcon" style={{fontSize:'12px'}}></i>Summary</div>
     </div>
     <div className={sliderState == 4 ? "col-md-2 active2 " : "col-md-2"} >
       <div className="options3"  onClick={() => this.changeSliderState(4)}><i className="fa fa-suitcase optionsIcon" style={{fontSize:'12px'}}></i>Work Experience</div>
     </div>
     <div className={sliderState == 5 ? "col-md-1 active2 " : "col-md-1"} >
       <div className="options2"  onClick={() => this.changeSliderState(5)}><i className="fa fa-graduation-cap optionsIcon" style={{fontSize:'12px'}}></i>Education</div>
     </div>
     <div className={sliderState == 6 ? "col-md-2 active2 " : "col-md-2"} >
       <div className="options6"  onClick={() => this.changeSliderState(6)}><i className="fa fa-users optionsIcon" style={{fontSize:'12px'}}></i>Dependant</div>
     </div>
     <div className={sliderState == 8 ? "col-md-1 active2 " : "col-md-1"} >
       <div className="options2"  onClick={() => this.changeSliderState(8)}><i className="fa fa-address-card optionsIcon" style={{fontSize:'12px'}}></i>Attributes</div>
     </div>
     <div className={sliderState == 7 ? "col-md-2 active2 " : "col-md-2"} >
       <div className="options3"  onClick={() => this.changeSliderState(7)}><i className="fa fa-university optionsIcon" style={{fontSize:'12px'}}></i>Bank Details</div>
     </div>
    
   </div>

   : superSliderState==='access' ?

   <div className="row " >
     <div className={sliderState == 13 ? "col-md-1 active " : "col-md-1"} style={{ backgroundColor: '#3c80ab' }}>
       <div className="options " onClick={() => this.changeSliderState(13)} > 1</div>
     </div>
     <div className={sliderState == 7 ? "col-md-2 active " : "col-md-2"} style={{ backgroundColor: '#17cfa4' }}>
       <div className="options " onClick={() => this.changeSliderState(7)} > 2</div>
     </div>
     <div className={sliderState == 8 ? "col-md-2 active " : "col-md-2"} style={{ backgroundColor: '#f0a630' }}>
       <div className="options" onClick={() => this.changeSliderState(8)}> 3</div>
     </div>
     <div className={sliderState == 9 ? "col-md-2 active " : "col-md-2"} style={{ backgroundColor: '#ed5353' }}>
       <div className="options" onClick={() => this.changeSliderState(9)}> 4</div>
     </div>
     <div className={sliderState == 10 ? "col-md-2 active " : "col-md-2"} style={{ backgroundColor: '#1ba652' }}>
       <div className="options" onClick={() => this.changeSliderState(10)}> 5</div>
     </div>
     <div className={sliderState == 11 ? "col-md-1 active " : "col-md-1"} style={{ backgroundColor: 'rgb(87, 170, 222)' }}>
       <div className="options" onClick={() => this.changeSliderState(11)}> 6</div>
     </div>
     <div className={sliderState == 12 ? "col-md-2 active " : "col-md-2"} style={{ backgroundColor: '#de57a2' }}>
       <div className="options" onClick={() => this.changeSliderState(12)}> 7</div>
     </div>
   </div>
:''}

{/*--------------------------------------------------------1-------------------------------*/}


{sliderState==2 ?


                    <div className="row">
                      <h4  className="account-head" >Work</h4>
                      <div className="col-md-6">

                      <div className="form-group">
                          <label className="control-label col-md-4">Organisation*:</label>
                          <div className="col-md-6">
                          
                            
                          {this.state.organisation.length > 0 ?
                               <Select
                               value={this.state.assignedOrganisation}
                               onChange={this.onMultiSelect}
                               options={this.state.organisation}
                               isMulti={true}
                             />
                              : '' }

                          </div>
                          {/*<label onClick={()=>this.openModal('organisationModalIsOpen')} style={{padding: '8px'}} className="control-label col-md-2"><i className="fa fa-plus"></i></label>*/}
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-4">Department* :</label>
                          <div className="col-md-6">
                            <select
                              name="department"
                              onChange={this.handleOnChange}
                              value={this.state.department}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            > {this.state.departments.map((dep, i) => (
                            <option>{dep.title}</option> ))}</select>
                          </div>
                          <label onClick={()=>this.openModal('departmentModalIsOpen')} style={{padding: '8px'}} className="control-label col-md-2"><i className="fa fa-plus"></i></label>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">Reporting To :</label>
                          <div className="col-md-7">
                            <select

                              name="reportingTo"
                              onChange={this.handleOnChange}
                              value={this.state.reportingTo}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            >{this.state.employees.map((employee, i) => (
                              <option value={employee.firstName + employee.lastName}>{employee.firstName} {employee.lastName}</option>) )}</select>
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-4">Title* :</label>
                          <div className="col-md-6">
                            <select
                              name="title"
                              onChange={this.handleOnChange}
                              value={this.state.title}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            >
                               {this.state.designations.map((dep, i) => (
                            <option value={dep.title}>{dep.title}</option> ))}
                            </select>
                          </div>
                          <label onClick={()=>this.openModal('designationModalIsOpen')} style={{padding: '8px'}} className="control-label col-md-2"><i className="fa fa-plus"></i></label>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">Source of Hire :</label>
                          <div className="col-md-7">
                           
                               <input
                              name="sourceOfHire"
                              onChange={this.handleOnChange}
                              value={this.state.sourceOfHire}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">Seating Location :</label>
                          <div className="col-md-7">
                            <input
                              name="seatingLocation"
                              onChange={this.handleOnChange}
                              value={this.state.seatingLocation}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>


                      </div>

                      <div className="col-md-6">

                        <div className="form-group">
                          <label className="control-label col-md-4">Location* :</label>
                          <div className="col-md-7">
                          <input
                              name="location"
                              onChange={this.handleOnChange}
                              value={this.state.location}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                           
                          </div>
                        </div>
                        

                        <div className="form-group">
                          <label className="control-label col-md-4">Date of joining :</label>
                          <div className="col-md-7">
                            <input
                              placeholder="dateOfJoining"
                              name="dateOfJoining"
                              type="date"
                              onChange={this.handleOnChange}
                              value={this.state.dateOfJoining}
                              id="ember330"
                              className="form-control ember-text-area ember-view"
                              defaultValue={""}
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-4">Date of Exit :</label>
                          <div className="col-md-7">
                            <input
                              name="dateOfExit"
                              onChange={this.handleOnChange}
                              value={this.state.dateOfExit}
                              className="form-control ember-text-field ember-view"
                              type="date"
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-4">Employee Status* :</label>
                          <div className="col-md-7">
                           
                            <select
                              name="employeeStatus"
                              onChange={this.handleOnChange}
                              value={this.state.employeeStatus}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            >
                               <option>Select</option> 
                            <option>Active</option> 
                            <option>Terminated</option> 
                            <option>Deceased</option> 
                            <option>Resigned</option> 
                            </select>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">Employee Type :</label>
                          <div className="col-md-7">
                           
                            <select
                              name="employeeType"
                              onChange={this.handleOnChange}
                              value={this.state.employeeType}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            >
                              <option>Select</option>  
                            <option>Permanent</option> 
                            <option>On Contract</option> 
                            <option>Temporary</option> 
                            <option>Trainee</option> 
                            </select>
                          </div>
                        </div>

                      </div>
                    </div>

:''}

{sliderState==1 ?
                    <div className="row">
                      <h4  className="account-head">Personal</h4>
                      <div className="col-md-6">

                        <div className="form-group">
                          <label className="control-label col-md-4">Mobile* :</label>
                          <div className="col-md-7">
                            <input
                              name="mobile"
                              onChange={this.handleOnChange}
                              value={this.state.mobile}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">Other Email :</label>
                          <div className="col-md-7">
                            <input
                              name="otherEmail"
                              onChange={this.handleOnChange}
                              value={this.state.otherEmail}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">Date of Birth* :</label>
                          <div className="col-md-7">
                            <input
                              placeholder="dateOfBirth"
                              name="dateOfBirth"
                              type="date"
                              onChange={this.handleOnChange}
                              value={this.state.dateOfBirth}
                              id="ember330"
                              className="form-control ember-text-area ember-view"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">
                            <span

                              className="popovercontainer text-dashed-underline ember-view"
                              data-original-title
                              title
                            >
                              {" "}
                              Marital Status:{" "}
                            </span>{" "}
                          </label>
                          <div className="col-md-8">
                            <div className="radio-inline">
                              <label className="cursor-poiner">
                                <input
                                  name="maritalStatus"


                                  type="radio"
                                  onChange={this.handleOnChange}
                                  defaultValue="Single"
                                />
                                Single:{" "}
                              </label>
                            </div>
                            <div className="radio-inline">
                              <label className="cursor-poiner">
                                {" "}
                                <input
                                  name="maritalStatus"
                                  onChange={this.handleOnChange}
                                  
                                  type="radio"
                                  defaultValue="married"
                                />
                                Married:{" "}
                              </label>
                            </div>
                          </div>
                        </div>
                        {this.state.iammarried==true ? 
                        <div className="form-group">
                          <label className="control-label col-md-4">Date of Anniversary:</label>
                          <div className="col-md-7">
                            <input
                              placeholder="dateOfJoining"
                              name="dateOfAnn"
                              type="date"
                              onChange={this.handleOnChange}
                              value={this.state.dateOfAnn}
                              id="ember330"
                              className="form-control ember-text-area ember-view"
                              defaultValue={""}
                            />
                          </div>
                        </div>

                        :''}



                      </div>

                      <div className="col-md-6">

                        <div className="form-group">
                          <label className="control-label col-md-4">Address* :</label>
                          <div className="col-md-7">
                            <textarea
                              name="address"
                              onChange={this.handleOnChange}
                              value={this.state.address}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            ></textarea>
                          </div>
                        </div>
                     
                        <div className="form-group">
                          <label className="control-label col-md-4">Tags :</label>
                          <div className="col-md-7">
                            <input
                              name="tags"
                              onChange={this.handleOnChange}
                              value={this.state.tags}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group">
      <label className="control-label col-md-4">Gender :</label>
      <div className="col-md-7">
      <select
          name="gender"
          onChange={this.handleOnChange}
          value={this.state.gender}
          className="form-control ember-text-field ember-view"
          type="text"
        >
          <option value="select">Select</option>
         <option value="male">Male</option>
  <option value="female">Female</option>
  <option value="other">Other</option>
 
</select>
      </div>
    </div>


                      </div>
                    </div>


:''}


{sliderState==3 ?
                    <div className="row">
                      <h4  className="account-head">Summary</h4>
                      <div className="col-md-6">

                        <div className="form-group">
                          <label className="control-label col-md-4">Job Description* :</label>
                          <div className="col-md-7">
                            <textarea
                              name="jobDescription"
                              onChange={this.handleOnChange}
                              value={this.state.jobDescription}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            ></textarea>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">Ask me About /  Expertise :</label>
                          <div className="col-md-7">
                            <textarea
                              name="expertise"
                              onChange={this.handleOnChange}
                              value={this.state.expertise}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            ></textarea>
                          </div>
                        </div>




                      </div>
                      <div className="col-md-6">

                        <div className="form-group">
                          <label className="control-label col-md-4">About Me :</label>
                          <div className="col-md-7">
                            <textarea
                              name="aboutMe"
                              onChange={this.handleOnChange}
                              value={this.state.aboutMe}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            ></textarea>
                          </div>
                        </div>
                       
                        


                      </div>
                    </div>

                    :''}

{sliderState==4 ?
                    <div className="row">
                      <h4  className="account-head">Work Experience  <button className="add-button" onClick={() => this.addNewContact('workEx')} > <i className="fa fa-plus "></i></button></h4>
                      <div className="col-md-8">

                        <table className="add-customer-table" style={{ width: "100%" }}>
                          <tr>
                            <th className="table-head">Previous Company</th>
                            <th className="table-head">Job Title</th>

                            <th className="table-head">From Date </th>
                            <th className="table-head">To Date</th>
                            <th className="table-head">Job Description</th>
                          </tr>
                          {this.state.workEx.map((person, i) => (

                            <tr>
                              <td className="table-item">
                                <input name="previousCompany" onChange={(e) => this.handleOnChangePerson(e, i, 'workEx')}
                                  value={this.state.workEx[i].previousCompany} />
                              </td>
                              <td className="table-item"><input name="jobTitle" onChange={(e) => this.handleOnChangePerson(e, i, 'workEx')}
                                value={this.state.workEx[i].jobTitle} /> </td>
                              <td className="table-item"><input name="fromDate" onChange={(e) => this.handleOnChangePerson(e, i, 'workEx')} value={this.state.workEx[i].fromDate} /> </td>
                              <td className="table-item"><input name="toDate" onChange={(e) => this.handleOnChangePerson(e, i, 'workEx')} value={this.state.workEx[i].toDate} /> </td>
                              <td className="table-item"><textarea style={{width:'200px'}} name="jobDescription" onChange={(e) => this.handleOnChangePerson(e, i, 'workEx')} value={this.state.workEx[i].jobDescription}></textarea> </td>
                            </tr>


                          ))}


                        </table></div></div>
:''}
{sliderState==5 ?
                    <div className="row">
                      <h4  className="account-head">Education <button className="add-button" onClick={() => this.addNewContact('education')} > <i className="fa fa-plus "></i></button></h4>
                      <div className="col-md-8">

                        <table className="add-customer-table" style={{ width: "100%" }}>
                          <tr>
                            <th className="table-head">School Name</th>
                            <th className="table-head">Degree/Diploma</th>
                            <th className="table-head">Field(s) of Study</th>
                            <th className="table-head">Date of completion </th>

                            <th className="table-head">Additional Notes</th>
                            <th className="table-head">Interests</th>
                          </tr>
                          {this.state.education.map((person, i) => (

                            <tr>
                              <td className="table-item">
                                <input name="schoolName" onChange={(e) => this.handleOnChangePerson(e, i, 'education')}
                                  value={this.state.education[i].schoolName} />
                              </td>
                              <td className="table-item"><input name="degree" onChange={(e) => this.handleOnChangePerson(e, i, 'education')}
                                value={this.state.education[i].degree} /> </td>
                              <td className="table-item"><input name="fieldOfStudy" onChange={(e) => this.handleOnChangePerson(e, i, 'education')} value={this.state.education[i].fieldOfStudy} /> </td>
                              <td className="table-item"><input name="dateOfCompletion" onChange={(e) => this.handleOnChangePerson(e, i, 'education')} value={this.state.education[i].dateOfCompletion} /> </td>

                              <td className="table-item"><textarea style={{width:'100px'}} name="additionalNotes" onChange={(e) => this.handleOnChangePerson(e, i, 'education')} value={this.state.education[i].additionalNotes}></textarea> </td>
                              <td className="table-item"><textarea style={{width:'100px'}} name="interests" onChange={(e) => this.handleOnChangePerson(e, i, 'education')} value={this.state.education[i].interests}></textarea> </td>
                            </tr>


                          ))}


                        </table></div></div>

:''}


{sliderState==6 ?
                        <div className="row">
                      <h4  className="account-head">Dependent <button className="add-button" onClick={() => this.addNewContact('dependents')} > <i className="fa fa-plus "></i></button></h4>
                      <div className="col-md-8">

                        <table className="add-customer-table" style={{ width: "100%" }}>
                          <tr>
                            <th className="table-head">Name</th>
                            <th className="table-head">Relationship</th>
                          
                            <th className="table-head">Date of Birth </th>

                        
                          </tr>
                          {this.state.dependents.map((person, i) => (

                            <tr>
                           
                              <td className="table-item"><input name="name" onChange={(e) => this.handleOnChangePerson(e, i, 'dependents')}
                                value={this.state.dependents[i].name} /> </td>
                              <td className="table-item"><select className="form-control select-form" name="relationship" placeholder="relationship" onChange={(e) => this.handleOnChangePerson(e,i,'dependents')} value={this.state.dependents[i].relationship}>  
                              <option>Select Relationship</option>
         <option>Father</option>
         <option>Mother</option>
         <option>Daughter/Son</option>
         <option>Wife</option>
         <option>Brother/Sister</option>
       </select></td>
                              <td className="table-item"><input name="dateOfBirth" type="date" onChange={(e) => this.handleOnChangePerson(e, i, 'dependents')} value={this.state.dependents[i].dateOfBirth} /> </td>

                                </tr>


                          ))}


                        </table></div></div>

                        :''}


{sliderState==7 ?   <div className="container row">
  <div className="col-md-12">
    <div className="row" style={{marginBottom:'-20px'}}>
      <label className="control-label add-header col-md-12">
        Banking Details
      </label>
    </div>
    <div className="form-group">
      <label className="control-label col-md-4">Bank Name :</label>
      <div className="col-md-8">
        <input
          name="bankName"
          onChange={this.handleOnChange}
          value={this.state.bankName}
          className="form-control ember-text-field ember-view"
          type="text"
        />
      </div>
    </div>
    <div className="form-group">
      <label className="control-label col-md-4">Branch Name:</label>
      <div className="col-md-8">
        <input
          name="branchName"
          onChange={this.handleOnChange}
          value={this.state.branchName}
          className="form-control ember-text-field ember-view"
          type="text"
        />
      </div>
    </div>

    <div className="form-group">
      <label className="control-label col-md-4">Account No:</label>
      <div className="col-md-8">
        <input
         name="accountNo"
          onChange={this.handleOnChange}
          value={this.state.accountNo}
          id="ember330"
          className="form-control ember-text-field ember-view"
          type="text"
        />
      </div>
    </div>

    <div className="form-group" >
      <label className="control-label col-md-4">Account Type:</label>
      <div className="col-md-6">
       
         <select
          name="accountType"
          onChange={this.handleOnChange}
          value={this.state.accountType}
          className="form-control ember-text-field ember-view"
          type="text"
        >
          <option value="pan">Select One</option>
         <option value="Savings">Savings</option>
  <option value="Current">Current</option>
  <option value="Credit Card">Credit Card A/C</option>
  <option value="Loans">Loans</option>
  <option value="Overdraft">Overdraft</option>
  <option value="Deposit">Deposit</option>
</select>
      </div>
    </div>
    <div className="form-group">
      <label className="control-label col-md-4">IFSC Code :</label>
      <div className="col-md-8">
        <input
          id="ember333"
          className="form-control ember-text-field ember-view"
          name="ifscCode"
          onChange={this.handleOnChange}
          value={this.state.ifscCode}
          type="text"
        />
      </div>
    </div>
    <div className="form-group">
      <label className="control-label col-md-4">MICR Code :</label>
      <div className="col-md-8">
        <input
          id="ember334"
          name="micrCode"
          onChange={this.handleOnChange}
          value={this.state.micrCode}
          className="form-control ember-text-field ember-view"
          type="text"
        />{" "}
      </div>
    </div>
  
  </div>

  
  
</div>      :''}


{sliderState == 8 ?
<div>
  <div className="row" style={{marginTop:'20px'}}>
    <div className="col-md-12">
    <div className="row" style={{marginBottom:'-20px'}}>
      <label className="control-label add-header col-md-12">
        Attributes
      </label>
    </div>
  <div className="text-right" style={{marginRight:'40px'}}>
 <button className="add-button" onClick={this.addNewAttribute} > <i className="fa fa-plus "></i></button>
  </div>
              <table className="add-customer-table" style={{width:"100%"}}>
  <tr>
    <th className="table-head">Attributes</th>
    <th className="table-head">Attribute Value</th>
  
    <th className="table-head">Attribute Attachment </th>
   
  </tr>
  {this.state.attributesGroup.map((person, i) => (
         
         <tr>
            <td className="table-item">
              <select className="form-control select-form" name="name" placeholder="salutation" onChange={(e) => this.handleOnChangeAttribute(e,i)} value={this.state.attributesGroup[i].name}>  
              <option value="select">Select</option>
<option value="pan">PAN</option>
 <option value="Aadhar Card Number" >Aadhar Card Number</option>
 <option value="Authorised Capital" >Authorised Capital</option>
 <option value="Category" >Category</option>
 <option value="CIN no" >CIN no</option>
 <option value="DIN no">DIN no</option>
 <option value="Excise No">Excise No</option>
 <option value="GST No">GST No</option>
 <option value="Incorporation No">Incorporation Date</option>
 <option value="Paid Up Capital">Paid Up Capital</option>
 
 <option value="Pt No.(EC)">Pt No.(EC)</option>
 <option value="Pt No.(RC)">Pt No.(RC)</option>
 <option value="Service Tax No.">Service Tax No.</option>
 <option value="Shop Act Expiry Date.">Shop Act Expiry Date.</option>
 <option value="Shop Act No.">Shop Act No.</option>
 <option value="Shop Act Reg Date.">Shop Act Reg Date.</option>
 <option value="TAN No">TAN No</option>
 <option value="VAT No">VAT No.</option>
 <option value="With Effect From (W.E.F)">With Effect From (W.E.F)</option>

       </select>
       </td>
         <td className="table-item"><input name="value" onChange={(e) => this.handleOnChangeAttribute(e,i)} 
         value={this.state.attributesGroup[i].value}/> </td>
         <td className="table-item">  <div className="col-md-6">
               
               <FileBase64
              multiple={true}
              onDone={this.getFileForAttach.bind(this)}   /></div>
              
              <div className="col-md-5">
              
              <button className="btn btn-primary attachment" onClick={() => this.fileUploadHandlerForAttachment(this, "attributeAttachment",i)}
              value="Select a File">
              <i className="fa fa-paperclip" aria-hidden="true"></i>Attachment
              </button>
                                        </div> </td>
         
         </tr>
         
        
         ))}
 
 
</table></div></div></div> :''}
                  </div>

                  <div className="text-left">
                  <SubmitButton clickHandler={this.sendMail}
                   isSubmitting={this.state.isSubmitting} />
                  </div>
                  {/* this says that if editing state is on then the Function saveAccountDetails will be called */}
                </div>
              </div>
            </div>
            </div>
            :<div><h3 style={{fontSize:'20px',marginLeft:'20px'}}><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>You have used your User Limit.</h3></div>}
          </div>
          
        </div>
        <Modal
isOpen={this.state.departmentModalIsOpen}

onRequestClose={()=>this.closeModal('departmentModalIsOpen')}
style={customStyles}
contentLabel="Add Department"
>
<div className="cross-row text-right"><div style={{marginRight:'-5px'}} onClick={this.closeModal}><i style={{fontSize:'25px',fontWeight:'400'}} className="fa fa-close" aria-hidden="true"></i></div></div>
<div className="header2">Add New Department</div>
<div className="row content1-div  text-left">

<input  className="fieldbox1"  onChange={this.handleOnChange} type="text" value={this.state.departmentTitle} name="departmentTitle" placeholder="Department Title" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif'}}/><br/>
<textarea className="fieldbox1"  onChange={this.handleOnChange} type="text" value={this.state.departmentDescription} name="departmentDescription" placeholder="Description" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif',width:'98%'}}></textarea><br/>
<div className="text-center"> <button  className="btn btn-primary send-button"  onClick={this.addDepartment} placeholder="button">Add Department</button></div>



</div> 

</Modal>
<Modal
isOpen={this.state.designationModalIsOpen}

onRequestClose={()=>this.closeModal('designationModalIsOpen')}
style={customStyles}
contentLabel="Add Department"
>
<div className="cross-row text-right"><div style={{marginRight:'-5px'}} onClick={this.closeModal}><i style={{fontSize:'25px',fontWeight:'400'}} className="fa fa-close" aria-hidden="true"></i></div></div>
<div className="header2">Add New Designation</div>
<div className="row content1-div  text-left">

<input  className="fieldbox1"  onChange={this.handleOnChange} type="text" value={this.state.designationTitle} name="designationTitle" placeholder="Designation Title" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif'}}/><br/>
<textarea className="fieldbox1"  onChange={this.handleOnChange} type="text" value={this.state.designationDescription} name="designationDescription" placeholder="Description" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif',width:'98%'}}></textarea><br/>
<div className="text-center"> <button  className="btn btn-primary send-button"  onClick={this.addDesignation} placeholder="Attachment">Add Designation</button></div>



</div> 

</Modal>
      </div>


    );
  }
}

